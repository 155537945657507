import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DonationType } from '@givebrite/data';

export type DonationTypeAppearance =
  | 'select-list-view'
  | 'flat-list-view'
  | 'radio-list-view'
  | 'toggle-view';
const DEFAULT_APPEARANCE = 'select-list-view';

@Component({
  selector: 'givebrite-donation-types',
  templateUrl: './donation-types.component.html',
  styleUrls: ['./donation-types.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DonationTypesComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DonationTypesComponent,
    },
  ],
})
export class DonationTypesComponent implements ControlValueAccessor, Validator, OnInit {
  /** The Donation Type appearance style. */
  @Input()
  get appearance(): DonationTypeAppearance {
    return this._appearance;
  }

  set appearance(value: DonationTypeAppearance) {
    if (value) {
      this._appearance = value;
    }
  }

  private _appearance: DonationTypeAppearance = DEFAULT_APPEARANCE;

  get selectListAppearance() {
    return this.appearance === 'select-list-view';
  }

  get flatListAppearance() {
    return this.appearance === 'flat-list-view';
  }

  get radioListAppearance() {
    return this.appearance === 'radio-list-view';
  }

  get toggleViewAppearance() {
    return this.appearance === 'toggle-view';
  }

  /** The Donation Type array */
  @Input()
  get donationTypes() {
    return this._donationTypes;
  }

  set donationTypes(value: any) {
    if (value) {
      this._donationTypes = value;
    }
  }

  private _donationTypes;
  private _selectedType;

  /** ACTIVE DONATION TYPE */
  @Input()
  get selectedType() {
    return this._selectedType;
  }

  set selectedType(value: number) {
    this._selectedType = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  // selectedType: number = -1;

  onChange = (type: DonationType) => {};
  onTouched = () => {};

  writeValue(type: DonationType) {
    this.type = type;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const type = control.value;
    if (type === '') {
      return {
        mustNotBeEmpty: {
          type,
        },
      };
    }
  }

  get type() {
    return this._type;
  }

  set type(value: DonationType) {
    this._type = value;
    this.onChange(this.type);
  }

  private _type: DonationType;

  constructor() {}

  ngOnInit(): void {}

  onTypeChange(index: number, type: DonationType) {
    this.selectedType = index;
    this.onChange(type);
  }
}
