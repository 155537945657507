import { Injectable } from '@angular/core';
import { ApiService } from './../../../../api/src/lib/api.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FundraiserService {
  constructor(private http: ApiService) {}

  fundraisers(id?, limit?) {
    return this.http
      .get(`campaign/fundraisers?filter_by=campaign&§campaign_id=${id}&page=${limit}`)
      .pipe(
        map((response) => {
          return {
            fundraisers: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  teams(id?, limit?) {
    return this.http.get(`campaign/teams?filter_by=campaign&campaign_id=${id}&page=${limit}`).pipe(
      map((response) => {
        return {
          teams: response.docs,
          pagination: {
            limit: response.limit,
            pages: response.pages,
            page: response.page,
            total: response.total,
          },
        };
      })
    );
  }

  /**
   * team page
   */
  team(slug?, id?) {
    return this.http.get(`team/${slug}?type=${id}`).pipe(map((response) => response));
  }

  /**
   * fundraiser page
   */
  fundraiser(id, type) {
    return this.http.get(`fundraiser/${id}?type=${type}`).pipe(map((response) => response));
  }

  /**
   * Create team
   */
  createTeam(payload) {
    return this.http.post(`team`, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Get team members
   */
  getTeamMembers(teamId) {
    return this.http.get(`team/members?team_id=${teamId}`).pipe(map((response) => response.docs));
  }

  /**
   * Get team leaderboard
   */
  getTeamLeaderboard(teamId) {
    return this.http.get(`team/leaderboard?team_id=${teamId}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }

  createInvite(teamId, payload) {
    return this.http.post(`team/invites?team_id=${teamId}`, payload);
  }

  /**
   * Get fundraisers
   */
  fundraisersList(
    filter_by = '',
    value = '',
    page = 1,
    limit,
    orderby?,
    search?,
    title_sort?,
    sort_by?
  ) {
    return this.http
      .get(
        `fundraiser?filter_by=${filter_by}&value=${value}&limit=${limit}&page=${page}&orderby=${orderby}&search=${search}&sort_value=${title_sort}&sort_title=${sort_by}`
      )
      .pipe(
        map((response) => {
          return {
            fundraisers: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        }),
        catchError((error) => {
          return of({ error });
        })
      );
  }

  /**
   * Create Fundraiser
   */
  createFundraiser(payload) {
    return this.http.post(`fundraiser/`, payload);
  }

  update(payload) {
    console.log(payload);
    return this.http.put(`fundraiser?fundraiser_id=${payload._id}`, payload);
  }

  joinTeam(teamId: string) {
    return this.http.get(`team/join?team=${teamId}`).pipe(
      map((response) => {
        return response;
      })
      // catchError((error) => {
      //   return of({ error });
      // })
    );
  }

  addOfflineDonation(payload) {
    return this.http.post(`donation/offline `, payload);
  }
}
