import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CampaignSectionModule } from '../campaign-section/campaign-section.module';
import { UserLogoAvatarModule } from '../../user/user-logo-avatar/user-logo-avatar.module';

@NgModule({
  declarations: [OverviewComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    UserLogoAvatarModule,
    MatButtonModule,
    CampaignSectionModule,
  ],
  exports: [OverviewComponent],
})
export class OverviewModule {}
