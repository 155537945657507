import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { Pagination } from '../../../pagination';
import { ImpactService } from '../../impact/impact.service';
import { CampaignRg, ImpactRg } from '../campaign.interface';
import { CampaignServiceRg } from '../campaign.service';
import {
  CreateCampaignRg,
  CreateCampaignSuccess,
  CreateImpact,
  DeleteImpact,
  GetAllCampaigns,
  GetCampaignRg,
  GetCampaignsRg,
  ResetCampaigns,
  SearchCampaigns,
  SetActiveCampaign,
  UpdateCampaignRg,
  UpdateImpact,
} from './campaign.action';

export class CampaignStateModelRg {
  campaigns: CampaignRg[];
  campaign: CampaignRg;
  pagination: Pagination;
  loading: boolean;
  allCampaigns: CampaignRg[];
}

@State<CampaignStateModelRg>({
  name: 'campaigns',
  defaults: {
    campaigns: [],
    campaign: null,
    pagination: null,
    loading: false,
    allCampaigns: [],
  },
})
@Injectable()
export class CampaignStateRg {
  constructor(private campaignService: CampaignServiceRg, private impactService: ImpactService) {}

  @Selector()
  static getCampaigns(state: CampaignStateModelRg) {
    return state.campaigns;
  }

  @Selector()
  static getCampaign(state: CampaignStateModelRg) {
    return state.campaign;
  }

  @Selector()
  static getImpacts(state: CampaignStateModelRg) {
    return state.campaign.impacts;
  }

  @Selector()
  static loading(state: CampaignStateModelRg) {
    return state.loading;
  }

  @Selector()
  static getPagination(state: CampaignStateModelRg) {
    return state.pagination;
  }

  @Selector()
  static getAllCampaign(state: CampaignStateModelRg) {
    return state.allCampaigns;
  }

  @Action(GetCampaignsRg)
  getCampaigns(
    { getState, setState, patchState }: StateContext<CampaignStateModelRg>,
    { charityId }
  ) {
    patchState({ loading: true });

    return this.campaignService.list(charityId).pipe(
      tap(
        (result: any) => {
          const state = getState();
          setState({
            ...state,
            campaigns: result.docs,
            pagination: result.pagination,
            loading: false,
          });
        },
        (error) => {
          setState({
            campaigns: [],
            pagination: null,
            loading: false,
            campaign: null,
            allCampaigns: [],
          });
        }
      )
    );
  }

  @Action(GetCampaignRg)
  getCampaign({ getState, setState, patchState }: StateContext<CampaignStateModelRg>, { slug }) {
    patchState({ loading: true });
    return this.campaignService.get(slug).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          campaign: result,
          loading: false,
        });
      })
    );
  }

  @Action(SearchCampaigns)
  searchCampaign(
    { getState, setState, patchState }: StateContext<CampaignStateModelRg>,
    { limit, page }
  ) {
    patchState({ loading: true });
    return this.campaignService.search({ limit: limit, page: page }).pipe(
      tap((result: any) => {
        const state = getState();
        console.log(result);
        setState({
          ...state,
          campaigns: result.docs,
          // pagination: result.pagination,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateCampaignRg)
  updateCampaign(
    { getState, setState, patchState, dispatch }: StateContext<CampaignStateModelRg>,
    { payload }: UpdateCampaignRg
  ) {
    patchState({ loading: true });
    return this.campaignService.update(payload).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          campaign: result,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateImpact)
  updateImpact(
    { setState, patchState }: StateContext<CampaignStateModelRg>,
    { impact }: UpdateImpact
  ) {
    patchState({ loading: true });
    return this.impactService.update(impact).pipe(
      tap(
        (result) => {
          patchState({ loading: false });

          setState(
            patch({
              campaign: patch({ impacts: updateItem<ImpactRg>((i) => i.id == impact.id, impact) }),
            })
          );
        },
        (error) => {
          patchState({ loading: false });
        }
      )
    );
  }

  @Action(CreateCampaignRg)
  createCampaign(
    { getState, setState, patchState, dispatch }: StateContext<CampaignStateModelRg>,
    { payload }: CreateCampaignRg
  ) {
    patchState({ loading: true });
    return this.campaignService.create(payload).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          campaign: result,
          loading: false,
        });
        dispatch(new CreateCampaignSuccess(result));
      })
    );
  }

  @Action(CreateImpact)
  createImpact(
    { setState, patchState }: StateContext<CampaignStateModelRg>,
    { payload }: CreateImpact
  ) {
    patchState({ loading: true });
    return this.impactService.create(payload).pipe(
      tap((impacts) => {
        patchState({ loading: false });
        setState(
          patch({
            campaign: patch({ impacts: append([payload]) }),
          })
        );
      })
    );
  }

  @Action(SetActiveCampaign)
  setActiveCampaign(
    { setState, patchState }: StateContext<CampaignStateModelRg>,
    { campaign }: SetActiveCampaign
  ) {
    patchState({ campaign: campaign });
  }

  @Action(ResetCampaigns)
  resetCampaigns({ setState }: StateContext<CampaignStateModelRg>) {
    setState({ campaign: null, campaigns: [], loading: false, pagination: null, allCampaigns: [] });
  }

  @Action(DeleteImpact)
  deleteImpact({ setState, patchState }: StateContext<CampaignStateModelRg>, { id }: DeleteImpact) {
    patchState({ loading: true });
    return this.impactService.delete(id).pipe(
      tap(
        () => {
          patchState({ loading: false });
          setState(
            patch({
              campaign: patch({ impacts: removeItem((impact: ImpactRg) => impact._id == id) }),
            })
          );
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(GetAllCampaigns)
  getAllCampaigns(
    { getState, setState, patchState }: StateContext<CampaignStateModelRg>,
    { limit, page, sort, filterBy, value }
  ) {
    patchState({ loading: true });

    return this.campaignService.getAllCampaigns(limit, page, sort, filterBy, value).pipe(
      tap((result: any) => {
        const state = getState();
        setState({
          ...state,
          allCampaigns: result.docs,
          loading: false,
        });
      })
    );
  }
}
