// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  stage: 'local',
  domain: 'skt.local',
  production: false,
  endpoint: 'https://skt-testing.herokuapp.com/v1',
  // endpoint: 'https://campaign-testing-v2.herokuapp.com/v1',
  endpoints: {
    campaign: 'https://skt-testing.herokuapp.com/v1',
    donation: 'https://skt-testing.herokuapp.com/v1',
    charity: 'https://skt-testing.herokuapp.com/v1',
    auth: 'https://skt-testing.herokuapp.com/v1',
    home: '',
    mock: '',
    blogs: '',
    dashboard: 'https://skt-client-dashboard-testing.herokuapp.com/v1',
  },
  app: {
    url: 'http://campaign.skt.local:4200',
  },
  apps: {
    authentication: {
      url: 'http://auth.skt.local:2000',
    },
    donate: {
      url: 'http://donate.skt.local:2001',
    },
    home: {
      url: '',
    },
    dashboard: {
      url: '',
    },
  },
  tinyMiceKey: 'l1wae27o17cgbbfctg39ewi2wjksaz5otudyl79iln2lic2g',
  assetPath: 'assets/',
  x_tenant_id: 'skt-welfare',
  facebookAppId: '209752022926766',
  googleLoginProvider: '84140297884-9p5oq1ofjv0lllolv7u2nqfrjup3feo6.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
