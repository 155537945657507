import { Component, OnInit } from '@angular/core';
import zakatIcon from '@iconify/icons-fa-solid/percent';
import sadaqahIcon from '@iconify-icons/emojione-monotone/letter-s';
import ambulanceIcon from '@iconify/icons-fa-solid/ambulance';
import waterIcon from '@iconify/icons-ion/water';

export type Statistics = { raised: string; title: string; icon: unknown };

@Component({
  selector: 'givebrite-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  stats: Statistics[] = [
    { raised: '£2.5M', title: 'Zakat', icon: zakatIcon },
    { raised: '£4.2M', title: 'Sadaqah', icon: sadaqahIcon },
    { raised: '£3.6M', title: 'Most Needy', icon: ambulanceIcon },
    { raised: '£655K', title: 'Water', icon: waterIcon },
  ];
  constructor() {}

  ngOnInit(): void {}
}
