<div class="campaign-type">
  <h4 class="text-center my-5 font-semibold">Campaign Type</h4>

  <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <div
      *ngFor="let campaignType of types; let i = index"
      fxFlex="30%"
      fxFlex.xs="40%"
      class="m-2"
    >
      <mat-card
        appearance="outlined"
        (click)="selectedType(i)"
        [ngClass]="campaignType.type == type ? 'card-item-shadow' : ''"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content>
          <p
            class="font-bold text-center"
            [ngClass]="campaignType.checked ? 'card-item-text' : ''"
          >
            {{ campaignType.text }}
          </p>

          <givebrite-tick-mark
            [checked]="type == campaignType.type ? true : false"
          ></givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
