<div class="columns-dialog">
  <h1 class="title" mat-dialog-title>Are you sure you want to delete this Address?</h1>
  <div mat-dialog-content>
    <view-card type="flat" nopadding="true" [loading]="loading$">
      <div class="address">
        <strong>{{ address.first_name }} {{ address.last_name }}</strong>
      </div>

      <div mat-line class="card-expiry">
        {{ address.address_line_1 }}, {{ address.town }}, {{ address.postcode }}, {{ address.country }}
      </div>
    </view-card>
  </div>
  <mat-dialog-actions>
    <div class="text-right">
      <button [disabled]="loading$ | async" color="warn" mat-raised-button (click)="deleteAddress()">Delete</button>
      <button color="primary" mat-dialog-close [disabled]="loading$ | async" mat-raised-button class="mr-2">Don't delete</button>
    </div>
  </mat-dialog-actions>
</div>
