<div class="toolbar-ctn py-2">
  <div class="container main-ctn">
    <div class="left-ctn">
      <div class="content">
        <p>Ramadan ends in</p>
        <p class="mt-px md:mt-5">Never miss Laylatur Qadr Again</p>
      </div>
    </div>

    <div fxLayout="row" class="right-ctn">
      <givebrite-countdown-timer></givebrite-countdown-timer>
      <a
        [href]="ramadhanUrl"
        mat-raised-button
        [style.backgroundColor]="buttoncolor + ' !important'"
        class="autogiving"
      >
        Automated Giving
      </a>
    </div>
  </div>
</div>
