<div class="invite-team mt-6 md:my-24" *ngIf="team$ | async as team">
  <div class="md:container px-5" fxLayout="column">
    <div fxLayout="column" fxLayout.gt-sm="row">
      <div
        fxFlexOrder="1"
        fxFlexOrder.gt-sm="1"
        fxLayout="column"
        class="w-100 md:w-2/3"
      >
        <givebrite-invite-cover
          [cover]="team.cover[0].thumbnail"
          [logo]="team?.logo"
          [name]="team.name"
        ></givebrite-invite-cover>
      </div>
      <div
        fxFlexOrder="2"
        fxFlexOrder.gt-sm="2"
        fxLayoutAlign="center center"
        fxLayout="column"
        fxLayout.sm="column"
        class="w-100 md:w-1/3"
      >
        <givebrite-invite-details
          [name]="team.name"
          [fundraisers]="team.statistics.fundraisers"
          [raised]="team.statistics.raised"
          [endDate]="team?.dates?.end_date"
          [profileImage]="team.user?.profile_image"
          [firstName]="team.user?.first_name"
          [lastName]="team.user?.last_name"
          [slug]="team.slug"
          [id]="team._id"
          [currency]="team.currency"
        ></givebrite-invite-details>
      </div>
    </div>
  </div>
</div>
