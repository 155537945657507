import { Injectable } from '@angular/core';
import {
  CreateDonationCartSession,
  DonationCartState,
  GetDonationCartItem,
  SetCartId,
} from '@givebrite/data';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CheckoutCartService {
  sizes = ['small', 'large', 'share'];
  public selectedAnimalSize = 'large';
  // public cart: Cart;
  public donationCart: any;
  public isTab = false;

  @Select(DonationCartState.getDonationCart) cart$: Observable<any>;

  constructor(
    private store: Store,
    private cookies: CookieService,
  ) {}

  /**
   * IS ADDED TO CART
   * @description
   * @param item
   * @returns
   */
  isAddedToCart(item: any) {
    return this.donationCart.items.find(
      (cartItem) =>
        cartItem?.inventory?._id === (this.isTab ? item.inventory?._id : item._id) &&
        cartItem.size ===
          (this.isTab
            ? item.size
            : this.selectedAnimalSize === 'share'
            ? 'share'
            : item.animal?.size),
    );
  }

  /**
   * GET CART QUANTITY
   * @description
   * @param item
   * @returns
   */
  getCartQuantity(item): number {
    return this.isAddedToCart(item) ? this.isAddedToCart(item).qty : 0;
  }

  /**
   * CREATE CART
   * @description
   * @param cartIdName
   * @returns
   */
  async createCart(cartIdName: string) {
    try {
      const store = await this.store.dispatch(new CreateDonationCartSession()).toPromise();
      if (store && store?.donationCart && store?.donationCart?.cartId) {
        const cartId = store.donationCart.cartId;
        this.cookies.put(cartIdName, cartId);
        return cartId;
      } else {
        throw new Error('Cart ID not present in store');
      }
    } catch (error) {
      // console.error(error);
    }
  }

  /**
   * GET CARTID
   * @description
   * @param cartId
   */
  getCart(cartId: string): void {
    this.store.dispatch(new SetCartId(cartId));
    this.store.dispatch(new GetDonationCartItem(cartId));
  }
}
