import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { UserLogoAvatarModule } from 'libs/core/src/lib/user/user-logo-avatar/user-logo-avatar.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CircularFundraisingProgressModule } from '../circular-fundraising-progress/circular-fundraising-progress.module';
// import { LeaderboardCommonModule } from '../leaderboard-common/leaderboard-common.module';
import { FundraiserLeaderboardCardComponent } from './fundraiser-leaderboard-card.component';
import { OrdinalModule } from 'libs/theming/src/lib/pipes/ordinal/ordinal.module';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [FundraiserLeaderboardCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    IconModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    OrdinalModule,
    TruncateStringModule,
    UserLogoAvatarModule,
    // LeaderboardCommonModule,
    NgxSkeletonLoaderModule,
    CircularFundraisingProgressModule,
  ],
  exports: [FundraiserLeaderboardCardComponent],
})
export class FundraiserLeaderboardCardModule {}
