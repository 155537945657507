import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { GivebriteService } from './../app/givebrite.service';

@Injectable({ providedIn: 'root' })
export class LandingGuard implements CanActivate {
  constructor(
    private router: Router,
    private givebritService: GivebriteService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userType = this.givebritService.getUserType;
    console.log(userType);
    if (!userType) {
      // logged in so return false as user doesnt need to login
      //   this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      console.log('can activate');
      return true;
    }

    console.log('cant activate');

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/home'], { queryParams: { user_type: userType } });
    return false;
  }
}
