<div fxLayout="column" fxLayoutGap="30px" class="w-1/3">
  <!-- <givebrite-file-upload [options]="options" (uploaded)="uploaded($event)" type="button" fxFlex></givebrite-file-upload> -->
  <div class="preview" fxFlex>
    <mat-card appearance="outlined" class="slide-card pt-0">
      <div class="card-image" [style.background-image]="cover">
        <video playsinline autoplay loop muted style="min-width: 100%; min-height: 100%">
          <source type="video/mp4" src="{{ cover }}" />
        </video>
        <img
          class="grunge"
          src="https://s3.eu-west-2.amazonaws.com/s3.givebrite.com/rg/grunge.png"
        />
      </div>
      <mat-card-content style="padding-bottom: 10px" class="text-center">
        <h2>{{ campaign?.name }}</h2>
        <p class="text-sm pt-1">{{ campaign?.description }}</p>
        <div class="row mt-3">
          <div class="col-md-10 col-12 mx-auto">
            <button color="primary" class="w-full btn-large" mat-raised-button mat-button>
              Give Now
            </button>
            <progress-bar [raised]="100"></progress-bar>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
