import { Charity } from '../charity';
import { Auth } from './store/auth.interface';

export interface Authenticate {
  email: string;
  token?: string;
}
export interface TextAuthenticate {
  mobile: any;
  token?: string;
}

export interface User {
  tokens: {
    access_token: string;
  };
  role: 'charity' | 'user' | 'admin';
  charity: Charity;
  verified: boolean;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  _id?: string;
  auth: Auth,
}
export interface CreateUser {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  optin: boolean;
  mobile: string;
  token?: string;
}
export interface CreateUserForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  type: string;
  role: string;
  charityId?: string;
}

export interface SocialUser {
  email: string;
  provider: string;
  provider_id: any;
  profile_image: string;
  first_name: string;
  last_name: string;
}
export interface NewPassword {
  password: string;
  verify_password: string;
  token: string;
  email: string;
}

export interface Followers {
  follower: {
    email: string;
    first_name: string;
    last_name: string;
    profile_image: string;
    verified: boolean;
    _id: string;
  };
}
