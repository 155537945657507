import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'libs/api/src/lib/api.service';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GivebriteService {
  constructor(private http: ApiService, private cookies: CookieService) {}

  getProduct(params?) {
    return this.http.get(`home/products`).pipe(
      map((response) => {
        return response.docs;
      })
    );
  }

  setUserType(userType: string) {
    return of(this.cookies.put('user_type', userType));
  }

  get getUserType(): string {
    return this.cookies.get('user_type');
  }

  stats() {
    return this.http.get(`home/statistics`).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
