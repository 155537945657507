import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import {
  AppStateRg,
  GetPaymentMethods,
  SetDefaultPaymentMethod,
  Subscription,
  SubscriptionState,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'givebrite-update-subscription',
  templateUrl: './update-subscription.component.html',
  styleUrls: ['./update-subscription.component.scss'],
})
export class UpdateSubscriptionComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) userLoading$;
  @Select(SubscriptionState.loading) subscriptionLoading$;
  @Select(AppStateRg.errors) errors$;

  form: FormGroup;
  default_payment_method;

  constructor(
    private store: Store,
    private dialog: MatDialogRef<UpdateSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public subscription: Subscription,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      default: [],
      token: ['', Validators.required],
    });

    if (this.subscription.default_payment_method)
      this.default_payment_method = this.subscription.default_payment_method.id;
  }

  paymentAdded(payment_method_id) {
    this.default_payment_method = payment_method_id;
  }

  paymentUpdated(payment_method_id) {
    this.default_payment_method = payment_method_id;
  }

  ngOnInit() {
    this.store.dispatch(new GetPaymentMethods({ user_id: +this.subscription.user.id }));
  }

  update() {
    this.store
      .dispatch(new SetDefaultPaymentMethod(+this.subscription.id, this.default_payment_method))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialog.close();
      });
  }

  get loading$() {
    return combineLatest(this.userLoading$, this.subscriptionLoading$, (one, two) => {
      return one || two;
    });
  }

  ngOnDestroy(): void {}
}
