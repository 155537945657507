import { Cart, CartItem } from '../cart.interface';
//import { CreateDonationDto } from "../donation.dto";

export class GetCart {
  static readonly type = '[Cart] Get';
  constructor() {}
}

export class AddCartItem {
  static readonly type = '[Cart] Add Cart Item';
  constructor(private item: CartItem) {}
}

export class LoadCart {
  static readonly type = '[Cart] Load cart';
  constructor(private cart) {}
}
export class CartCheckout {
  static readonly type = '[Cart] Checkout';
  constructor(private donation) {}
}

export class UpdateCart {
  static readonly type = '[Cart] Update Cart';
  constructor(public cart: Cart) {}
}

export class UpdateCartItem {
  static readonly type = '[Cart] Update Cart Item';
  constructor(private item: CartItem) {}
}

export class RemoveCartItem {
  static readonly type = '[Cart] Remove Cart Item';
  constructor(private item: CartItem) {}
}

export class SaveCart {
  static readonly type = '[Cart] Remove Cart Item';
  constructor(private id: number) {}
}

export class ClearCart {
  static readonly type = '[Cart] Clear Cart';
}

export class UpdateCartTotal {
  static readonly type = '[Cart] Update Cart Total';
}
