import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-donation-info',
  templateUrl: './donation-info.component.html',
  styleUrls: ['./donation-info.component.scss'],
})
export class DonationInfoComponent implements OnInit {
  @Input() infoClass;
  @Input() title;

  constructor() {}

  ngOnInit(): void {}
}
