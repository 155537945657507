import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeleteAddressDialogComponent } from "./delete-address-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ViewCardModule } from "../../../view-templates/view-card/view-card.module";

@NgModule({
    declarations: [DeleteAddressDialogComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, ViewCardModule],
    exports: [DeleteAddressDialogComponent]
})
export class DeleteAddressDialogModule {}
