<div class="w-full h-full social-login-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="w-full" gdColumns="1fr 1fr" gdGap="10px">
    <button
      type="button"
      (click)="signInWithGoogle()"
      color="primary"
      mat-raised-button
      [disabled]="loading$ | async"
      class="btn-large w-full sign-in-google"
    >
      <mat-icon [icIcon]="googleIcon"></mat-icon>
    </button>

    <button [disabled]="loading$ | async" (click)="signInWithFacebook()" class="btn-large sign-in-facebook" mat-raised-button>
      <mat-icon [icIcon]="facebookIcon"></mat-icon>&nbsp;
    </button>
  </div>
</div>
