import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ApiModule } from './api.module';

@Injectable({
  providedIn: ApiModule,
})
export class JwtService {
  cookieDomain = isDevMode() ? 'givebrite.local' : 'givebrite.com';
  isBrowser;
  constructor(@Inject(PLATFORM_ID) private platformId: any, private _cookieService: CookieService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  getToken(): any {
    this._cookieService.get('jwt');
  }

  saveToken(token: string) {
    if (this.isBrowser) window.localStorage['jwtToken'] = token;
    this._cookieService.put('jwt', token, { domain: this.cookieDomain });
  }

  saveAdminToken(token: any) {
    if (this.isBrowser) window.localStorage['adminToken'] = JSON.stringify(token);
    // this._cookieService.putObject("admin", JSON.stringify(token));
  }

  getAdminToken() {
    return window.localStorage['adminToken'];
  }

  destroyToken() {
    if (this.isBrowser) {
      window.localStorage.removeItem('jwtToken');
      window.localStorage.removeItem('adminToken');
    }
    this._cookieService.removeAll();
  }
}
