<div class="w-full h-full" [ngClass]="{container: !dialog}" fxLayout="column" fxLayoutAlign="center center">
  <div [@fadeInUp] class="overflow-hidden w-full" [class.hidden]="recovering">
    <div class="text-center mt-4">
      <h2 class="title m-0">Reset Password</h2>
      <h4 class="body-2 text-secondary m-0 mb-4">Enter your email for password recovery.</h4>
      <display-message [error]="error"></display-message>
    </div>

    <div [formGroup]="form" fxLayout="column" fxLayoutGap="16px">
      <div fxFlex="auto" fxLayout="column" class="mt-6">
        <mat-form-field fxFlex="grow">
          <mat-label>E-Mail</mat-label>

          <mat-icon  color="primary" [icIcon]="icMail" class="mr-2" matPrefix></mat-icon>
          <input formControlName="email" matInput required />
          <mat-error *ngIf="form.get('email').hasError('required')">
            We can't recover your password, without your email.
          </mat-error>
        </mat-form-field>
      </div>

      <button
        [disabled]="form.invalid || (loading$ | async)"
        class="btn-large"
        (click)="send()"
        color="primary"
        mat-raised-button
        type="button"
      >
        Get Recovery Link
      </button>
    </div>
  </div>

  <div [@fadeInUp] class="overflow-hidden w-full" fxLayoutAlign="center center" fxLayout="column" *ngIf="recovering && !resetted">
    <div class="text-center mt-4">
      <h2 class="title m-0">Check your email for instruction</h2>
      <h4 class="body-2 text-secondary m-0">
        After resetting your password, you can come back to this window and you'll be logged in!.
      </h4>
    </div>
    <div class="my-6">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div [@fadeInUp] class="overflow-hidden w-full" fxLayoutAlign="center center" fxLayout="column" *ngIf="resetted">
    <div class="text-center mt-4">
      <h2 class="title m-0">Your password has been reset</h2>
      <h4 class="body-2 text-secondary m-0">You've automatically been logged in!.</h4>
    </div>
    <div class="my-6">
      <span class="icon-container">
        <mat-icon color="primary" size="40px" [icIcon]="icCheck"></mat-icon>
      </span>
    </div>

    <button (click)="continue()" mat-raised-button color="primary" type="button">
      Continue <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>

  <button *ngIf="!resetted" (click)="backToLogin()" class="mt-6" mat-raised-button type="button">
    <!-- <mat-icon>keyboard_backspace</mat-icon> Back to login -->
  </button>
</div>
