import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import close from '@iconify/icons-ic/close';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { GivebriteConfig } from 'libs/data/src/lib/app/givebrite.interface';
import { GivebriteState } from 'libs/data/src/lib/app/store/givebrite.state';
import { OfflineDonation } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { AddOfflineDonations } from 'libs/data/src/lib/campaign/store/fundraiser.actions';
import { FundraiserState } from 'libs/data/src/lib/campaign/store/fundraiser.state';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'givebrite-offline-donation-dialog',
  templateUrl: './offline-donation-dialog.component.html',
  styleUrls: ['./offline-donation-dialog.component.scss'],
})
export class OfflineDonationDialogComponent implements OnInit {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;
  @Select(FundraiserState.OfflineDonation)
  offlineDonation$: Observable<OfflineDonation>;

  close: unknown = close;
  paymentMethods: { name: string }[] = [{ name: 'Cash' }, { name: 'Bank Transfer' }];
  loading = false;
  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<OfflineDonationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private store: Store,
    private toastr: ToastrService // private fundraiserService: FundraiserService, // private teamService: TeamService, // private campaignService: CampaignService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      charity: [this.data._charityId, [Validators.required]],
      campaign: [this.data._campaignId, [Validators.required]],
      amount: ['', [Validators.required]],
      currency: [this.store.selectSnapshot(GivebriteState.config).currency],
      is_anonymous: [false],
      is_giftaid: [false],
      comment: [],
      payment_method: [''],
      billing: this.fb.group({
        building_name_number: [null],
        address_line_1: [null, [this.requiredIfValidator(() => this.form.get('is_giftaid').value)]],
        address_line_2: [null],
        postcode: [null, [this.requiredIfValidator(() => this.form.get('is_giftaid').value)]],
        state: [''],
        country: ['GB', Validators.required],
      }),
    });

    this.form
      .get('is_giftaid')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.form.get('billing.address_line_1').updateValueAndValidity();
          this.form.get('billing.postcode').updateValueAndValidity();
        } else {
          this.form.get('billing.address_line_1').clearValidators();
          this.form.get('billing.postcode').clearValidators();
          this.form.get('billing.address_line_1').updateValueAndValidity();
          this.form.get('billing.postcode').updateValueAndValidity();
        }
      });
  }

  /**
   * @description
   * CLOSE DIALOG
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * @description
   * ADD RECORD
   */
  addRecord() {
    this.loading = true;
    const payload = {
      ...this.form.value,
      ...{ comment: [this.form.get('comment').value || ''] },
      // ...{ [this.data.page]: this.data._charityId },
    };

    this.store
      .dispatch(new AddOfflineDonations(payload))
      .pipe(untilDestroyed(this))
      .subscribe(
        (store) => {
          this.toastr.success('Offline donation added successfully!');
          this.closeDialog();
        },
        (error) => {
          this.toastr.error(error[0]);
          this.loading = false;
        }
      );
  }

  /**
   * @description
   * VALIDATOR TO CHECK REQUIRED FIELD
   */
  requiredIfValidator(predicate) {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  /**
   * @description
   * RESET AND ADD NEW RECORD
   */
  addAnotherRecord() {
    this.form.reset();
    this.ngOnInit();
  }
}
