import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GivebriteConfig, Product } from '../givebrite.interface';
import { GetProduct, GetStatisticsHome, Loading, SetLocale, SetUserType } from './givebrite.actions';
import { GivebriteService } from './../givebrite.service';
import { tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';

export class GivebriteStateModel {
  loading?: boolean;
  error: any;
  config: GivebriteConfig;
  products?: Product[];
  form: any;
  stats: any;
}

@State<GivebriteStateModel>({
  name: 'givebrite',
  defaults: {
    loading: false,
    error: null,
    config: {
      country: 'gb',
      language: 'en-gb',
      currency: 'GBP',
      user_type: null,
    },
    products: [],
    form: null,
    stats: null,
  },
})
@Injectable()
export class GivebriteState {
  constructor(private service: GivebriteService) {}

  @Selector()
  static getStatistics(state: GivebriteStateModel) {
    return state.stats;
  }

  @Selector()
  static errors(state: GivebriteStateModel) {
    return state.error;
  }

  @Selector()
  static config(state: GivebriteStateModel): GivebriteConfig {
    return state.config;
  }

  @Selector()
  static currency(state: GivebriteStateModel) {
    return state.config.currency;
  }

  @Selector()
  static country(state: GivebriteStateModel) {
    return state.config.country;
  }

  @Selector()
  static loading(state: GivebriteStateModel) {
    return state.loading;
  }

  @Selector()
  static products(state: GivebriteStateModel) {
    return state.products;
  }

  @Action(SetLocale)
  setLocal({ patchState }: StateContext<GivebriteStateModel>, { locale }) {
    patchState({
      config: {
        country: locale.country,
        language: locale.language,
        currency: locale.currency,
        user_type: locale.user_type,
      },
    });
  }

  @Action(Loading)
  loading({ patchState }: StateContext<GivebriteStateModel>, { loading }) {
    patchState({
      loading: loading,
    });
  }

  @Action(SetUserType)
  setUserType(
    { patchState, getState, dispatch }: StateContext<GivebriteStateModel>,
    { user_type }
  ) {
    patchState({
      config: { ...getState().config, user_type: user_type },
    });

    return this.service.setUserType(user_type);

    // dispatch(new Navigate(['/home']));
  }

  @Action(GetProduct)
  getProduct(
    { patchState, getState, setState }: StateContext<GivebriteStateModel>,
    action: GetProduct
  ) {
    patchState({ loading: true });
    return this.service.getProduct(action.limit).pipe(
      tap(
        (result: Product[]) => {
          const state = getState();
          patchState({
            products: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            products: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetStatisticsHome)
  getStatistics({ patchState }: StateContext<GivebriteStateModel>, action: GetProduct) {
    patchState({ loading: true });
    return this.service.stats().pipe(
      tap(
        (results) => {
          patchState({
            stats: results,
            loading: false,
          });
        },
        () => {
          // Error
          patchState({
            loading: false,
          });
        }
      )
    );
  }
}
