import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "givebrite-logo-card",
  templateUrl: "./logo-card.component.html",
  styleUrls: ["./logo-card.component.scss"]
})
export class LogoCardComponent implements OnInit {
  @Input() logo: string;
  loaded: boolean;
  constructor() {}

  ngOnInit() {}
}
