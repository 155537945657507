import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { CampaignRg, ImpactRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-campaign-impact-list',
  templateUrl: './campaign-impact-list.component.html',
  styleUrls: ['./campaign-impact-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignImpactListComponent implements OnInit, AfterViewInit {
  _impacts: ImpactRg[];
  @Input() days = 30;
  @Input() preview: ImpactRg;
  @Input() campaign: CampaignRg;
  @Input() currency: string;
  slideConfig = {
    centerMode: true,
    slidesToShow: 2,
    centerPadding: '40px',
    arrows: true,
    infinite: true,
    variableWidth: true,
    waitForAnimate: false,
    focusOnSelect: true,
  };

  @Input() selected;
  @Input() set impacts(impacts: ImpactRg[]) {
    if (impacts) {
      if (this.selected) {
        impacts = impacts.slice().sort((a, b) => (a.amount !== this.selected ? 1 : -1));
      }
      console.log(impacts);
      this._impacts = [
        ...impacts,
        {
          amount: 50,
          custom: true,
          details: 'Enter a custom amount',
        },
      ];

      if (impacts.length) {
        if (impacts.length > 3) {
          this.slideConfig.slidesToShow = 3;
        } else {
          this.slideConfig.slidesToShow = 1;
        }
      }
    }
  }
  @Output() select = new EventEmitter();
  @ViewChild('customAmountField') customAmountField: MatInput;

  constructor(private cd: ChangeDetectorRef) {}

  get _currency() {
    if (this.campaign) {
      return this.campaign.currency;
    } else {
      return this.currency;
    }
  }

  afterChange(e) {
    console.log(e);

    const el = document.querySelector('.slick-current-clone-animate');
    if (el) el.classList.remove('slick-current-clone-animate');
    const impact = this._impacts[e.currentSlide];
    this.select.next(impact);
  }

  set customAmountChange(amount) {
    console.log('custom amount chosen');
    this.select.next({ amount: +amount });
  }

  selectAllContent($event) {
    $event.target.select();
  }

  beforeChange(e) {
    let direction,
      slideCountZeroBased = e.slick.slideCount - 1;

    if (e.nextSlide == e.currentSlide) {
      direction = 'same';
    } else if (Math.abs(e.nextSlide - e.currentSlide) == 1) {
      direction = e.nextSlide - e.currentSlide > 0 ? 'right' : 'left';
    } else {
      direction = e.nextSlide - e.currentSlide > 0 ? 'left' : 'right';
    }

    if (direction == 'right') {
      document
        .querySelector(
          '.slick-cloned[data-slick-index="' + (e.nextSlide + slideCountZeroBased + 1) + '"]'
        )
        .classList.add('slick-current-clone-animate');
    }

    if (direction == 'left') {
      /*document
        .querySelector('.slick-cloned[data-slick-index="' + (e.nextSlide - slideCountZeroBased - 1) + '"]')
        .classList.add("slick-current-clone-animate");*/

      document
        .querySelector('.slick-current')
        .previousElementSibling.classList.add('slick-current-clone-animate', 'slick-current');
    }

    //console.log($('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]');
    /*// Add a temp CSS class for the slide animation (.slick-current-clone-animate)
    if (direction == 'right') {
        $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $carousel).addClass('slick-current-clone-animate');
    }

    if (direction == 'left') {
        $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $carousel).addClass('slick-current-clone-animate');
    }*/
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  trackByFn(index, item) {
    return index;
  }
}
