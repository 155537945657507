<div class="teams mt-8" fxLayout="column" fxFlexAlign="center">
  <h2 class="font-semibold py-6 text-center md:text-left" [class.pb-0]="cardViewAppearance">
    Teams
    <span *ngIf="(teams$ | async)?.length" class="badge">{{
      (teamsPagination$ | async)?.total
    }}</span>
  </h2>

  <h3 *ngIf="cardViewAppearance" class="text-center sm:text-left">
    Sign up and join our thousands of people raising for the cause they care
  </h3>

  <!-- List View -->
  <div *ngIf="listViewAppearance">
    <div
      fxFlex
      fxLayout="row wrap"
      fxLayoutGap="1rem grid"
      class="my-6"
      *ngIf="(teams$ | async)?.length && teams$ | async as teams; else noTeams"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex="49"
        fxFlex.gt-xs="20"
        class="mb-4 sm:mb-8"
        *ngFor="let team of teams"
      >
        <div fxLayout="row" fxLayoutAlign="center center" class="team-avatar-container">
          <div *ngFor="let item of teamList" class="team-avatar">
            <givebrite-user-logo-avatar
              [logo]="item?.profile_image"
              [name]="item?.first_name + ' ' + item?.last_name"
              [border]="true"
              width="86px"
            ></givebrite-user-logo-avatar>
          </div>
        </div>

        <div class="mt-4">
          <h3 class="font-semibold m-0-c">
            {{ team?.user?.first_name + ' ' + team?.user?.last_name }}
          </h3>
          <h3 class="m-0-c">
            {{ team?.statistics?.raised | currency: team.currency:'symbol':'1.0-1' }}
          </h3>
          <p class="text-center">{{ team?.statistics?.donors }} supporters</p>
        </div>
      </div>
    </div>
  </div>

  <!-- CARD VIEW -->
  <div *ngIf="cardViewAppearance">
    <div
      fxLayout="column"
      class="my-6 mb-0 sm:mb-6"
      *ngIf="(teams$ | async)?.length && teams$ | async as teams; else noTeams"
    >
      <mat-card appearance="outlined" class="mat-elevation-z0 p-6 rounded-lg mb-3" *ngFor="let team of teams">
        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="5px" fxLayoutAlign="start">
            <givebrite-user-logo-avatar
              [logo]="team.user?.profile_image"
              [name]="team.user.first_name + ' ' + team?.user?.last_name"
              [border]="true"
              width="55px"
            ></givebrite-user-logo-avatar>

            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="70%"
              class="ml-2 mx-width-none"
            >
              <h3 class="font-semibold cursor-pointer mb-1" (click)="teamPage(team.slug)">
                {{ team?.name | titlecase }}
              </h3>

              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <span class="sub-title">
                  {{ team?.statistics?.raised | currency: team.currency:'symbol':'1.0-1' }} raised
                </span>
                <span class="mx-2">•</span>
                <span class="sub-title">{{ team?.social?.supporters }} supporters</span>
                <span class="mx-2">•</span>
                <span class="sub-title">{{ team?.statistics?.donors }} members</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <ng-template #noTeams>No Teams to show</ng-template>

  <h4
    class="font-medium cursor-pointer"
    fxLayout="row"
    fxLayoutAlign="center center"
    [class.disabled]="loading"
    *ngIf="!maxTeamsLimitReached"
    (click)="moreTeams()"
  >
    Load More <mat-icon>expand_more</mat-icon>
  </h4>
</div>
