import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Subscription, SubscriptionState } from 'libs/data/src/lib/ramadhan-giving';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-cancel-subscription-dialog',
  templateUrl: './cancel-subscription-dialog.component.html',
  styleUrls: ['./cancel-subscription-dialog.component.scss'],
})
export class CancelSubscriptionDialogComponent implements OnInit, OnDestroy {
  @Select(SubscriptionState.loading) loading$: Observable<boolean>;
  cancel_at_period_end = 0;
  subscription: Subscription;

  constructor(
    private dialog: MatDialogRef<CancelSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Subscription
  ) {
    this.subscription = this.data;
  }

  ngOnInit() {}

  cancel() {
    this.dialog.close({ cancel: true, cancel_at_period_end: this.cancel_at_period_end });
  }

  dont_cancel() {
    this.dialog.close({ cancel: false });
  }

  // cancel() {
  //   //this.dialog.close({cancel: true, cancel_at_period_end: +this.cancel_at_period_end});
  //   this.store
  //     .dispatch(
  //       new CancelSubscription(
  //         +this.subscription.id,
  //         +this.cancel_at_period_end
  //       )
  //     )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       cancelled => {
  //         this.dialog.close();
  //       },
  //       error => {}
  //     );
  // }

  ngOnDestroy(): void {}
}
