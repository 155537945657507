import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroHeaderComponent } from './hero-header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { LottieModule } from 'ngx-lottie';
import { ContainerModule } from 'libs/theming/src/lib/directives/container/container.module';

@NgModule({
  declarations: [HeroHeaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    ContainerModule,
    RouterModule,
    LottieModule,
    NgxFlickingModule,
  ],
  exports: [HeroHeaderComponent],
})
export class HeroHeaderModule {}
