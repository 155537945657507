import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[responsive]',
})
export class ResponsiveDirective {
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() format: string = 'jpg';
  @Input() src: string;
  @Input() fit: string = 'max';
  @Input() key = 'ATwlgNAAsQsyfsNEw94Cgz';

  constructor(private el: ElementRef) {
    //https://cdn.filestackcontent.com/{{environment.fileStackApiKey}}/resize=width:100,height:100,fit:crop/{{campaign.cover}}
  }

  ngOnInit() {
    this.el.nativeElement.src = `https://cdn.filestackcontent.com/${this.key}/output=format:${this.format},quality:70/resize=`;
    this.width ? (this.el.nativeElement.src += `width:${this.width?.replace(/\D/g, '')},`) : '';
    this.height ? (this.el.nativeElement.src += `height:${this.height?.replace(/\D/g, '')},`) : '';
    this.el.nativeElement.src += `fit:${this.fit}/${this.src}`;
  }
}
