import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { slugify } from '../../../utils';

@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignDetailsComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignDetailsComponent,
    },
  ],
})
export class CampaignDetailsComponent implements OnInit {
  todayDate: Date;
  has_impacts;
  has_phases;
  has_sponsors;

  details: {
    name: string;
    checked: boolean;
    title: string;
    text: string;
    btnText: string;
  }[];

  @Input() form: FormGroup;
  @Input() environment;

  constructor(private fb: FormBuilder) {
    const now = new Date();
    this.todayDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  }

  // updated() {
  //   this.store.dispatch(
  //     new UpdateFormValue({
  //       path: 'campaign.form',
  //       value: this.form.value,
  //     })
  //   );
  //   console.log('updated');
  // }

  ngOnInit() {
    // this.form.get('impacts').valueChanges.subscribe((v) => {
    //   console.log(v);
    // });

    this.form
      .get('name')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((name) => {
        if (!this.form.get('slug').touched) {
          this.form.get('slug').setValue(slugify(name));
        }
      });
    // this.form.get('impacts').setValue([{ name: 'test', amount: 'test' }]);
  }

  get type() {
    return this.form.get('type').value;
  }
}
