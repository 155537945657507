<givebrite-toast
  [error]="error$ | async"
  (clearError)="onError()"
></givebrite-toast>

<div
  ngFileDrop
  [options]="options"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="drop-zone center"
  *ngIf="!originalFile"
>
  <!-- DROP ZONE -->
  <div fxLayout="column" fxLayoutAlign="center center" class="h-full uploader">
    <mat-icon class="material-icons-outlined">file_upload</mat-icon>

    <p>Drag image here or</p>
    <label
      class="upload-button my-3 mat-raised-button btn-md mat-button-base mat-primary px-3"
    >
      <input
        type="file"
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        multiple
      />
      <span>BROWSE</span>
    </label>

    <p class="subtext">Supports: JPEG, JPG, PNG</p>
  </div>
  <!-- END FILE DROP ZONE -->
</div>

<givebrite-image-cropper-dialog
  *ngIf="originalFile"
  [ratio]="ratio"
  [imageChangedEvent]="originalFile"
  (cropped)="imageCropped($event)"
></givebrite-image-cropper-dialog>
