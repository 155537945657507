import { Component, Input, OnInit } from '@angular/core';
import grade from '@iconify/icons-ic/round-grade';
import star from '@iconify/icons-ic/star-border';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { GetCampaignDonations } from 'libs/data/src/lib/campaign/store/campaign.actions';
import { CampaignState } from 'libs/data/src/lib/campaign/store/campaign.state';
import { GetFundraiserDonations } from 'libs/data/src/lib/campaign/store/fundraiser.actions';
import { FundraiserState } from 'libs/data/src/lib/campaign/store/fundraiser.state';
import { GetTeamDonations } from 'libs/data/src/lib/campaign/store/team.actions';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { Donation } from 'libs/data/src/lib/donation/donation.interface';
import { Pagination } from 'libs/data/src/lib/pagination/pagination.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type DonationItemAppearance = 'list-view' | 'card-view' | 'words-view';
const DEFAULT_APPEARANCE = 'list-view';

@UntilDestroy()
@Component({
  selector: 'givebrite-campaigns-donation',
  templateUrl: './campaign-donations.component.html',
  styleUrls: ['./campaign-donations.component.scss'],
})
export class CampaignDonationsComponent implements OnInit {
  @Select(CampaignState.pagination) pagination$: Observable<Pagination>;
  @Select(FundraiserState.donationPagination)
  fundraiserPagination$: Observable<Pagination>;
  @Select(TeamState.pagination) teamPagination$: Observable<Pagination>;

  @Input() page: string;
  @Input() set campaign(campaign: Campaign) {
    if (campaign) {
      this.loading$.next(true);
      this._campaign = campaign;
      this.store
        .dispatch(new GetCampaignDonations(this.loadMoreLimit, campaign._id, this.currentPage))
        .subscribe(
          (store) => {
            this.donations = store.campaign.donations;
            this.loading = false;
            this.loading$.next(false);
          },
          (error) => {
            this.loading$.next(false);
          }
        );
    }
  }

  @Input() set fundraiser(fundraiser) {
    if (fundraiser) {
      this.loading$.next(true);
      this._fundraiser = fundraiser;
      this._campaign = fundraiser.campaign;
      this.store
        .dispatch(new GetFundraiserDonations(this.loadMoreLimit, fundraiser._id, this.currentPage))
        .subscribe(
          (store) => {
            this.donations = store.fundraiser.donations;
            this.loading = false;
            this.loading$.next(false);
          },
          (error) => {
            this.loading$.next(false);
          }
        );
    }
  }

  @Input() set team(team) {
    if (team) {
      this.loading$.next(true);
      this._team = team;
      this._campaign = team.campaign;
      this.store
        .dispatch(new GetTeamDonations(team._id, this.loadMoreLimit, this.currentPage))
        .subscribe(
          (store) => {
            this.donations = store.team.donations;
            this.loading = false;
            this.loading$.next(false);
          },
          (error) => {
            this.loading$.next(false);
          }
        );
    }
  }

  /** The Donation Item appearance style. */
  @Input()
  get appearance(): DonationItemAppearance {
    return this._appearance;
  }

  set appearance(value: DonationItemAppearance) {
    if (value) {
      this._appearance = value;
    }
  }

  private _appearance: DonationItemAppearance = DEFAULT_APPEARANCE;

  get listView(): boolean {
    return this.appearance == DEFAULT_APPEARANCE;
  }

  get cardView(): boolean {
    return this.appearance == 'card-view';
  }

  get wordsView(): boolean {
    return this.appearance == 'words-view';
  }

  icons = {
    grade: grade,
    star: star,
  };

  donations: Donation[] = [];
  _campaign: Campaign;
  _fundraiser: Fundraiser;
  _team: Team;
  loadMoreLimit = 10;
  limit: number;
  total: number;
  currentPage = 1;
  loading = false;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.paginationType();
    // this.donations = [
    //   {
    //     _id: 1,
    //     user: {
    //       profile_image:
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    //       profile_completed: true,
    //     },
    //     billing: {
    //       country: 'eu',
    //       title: 'Anonymous',
    //       first_name: 'John',
    //       last_name: 'dev',
    //       postcode: '123124',
    //       building_name_number: 'house no 123',
    //     },
    //     charity: {
    //       name: 'nim',
    //       slug: '123',
    //       _id: '123124',
    //     },
    //     campaign: {
    //       name: 'my campaign',
    //       slug: 'my slug',
    //       _id: '432523',
    //     },
    //     is_giftaid: true,
    //     anonymous: true,
    //     comment: 'hello',
    //     currency: 'USD',
    //     amount: 124,
    //     created_at: '',
    //     giftAmount: 124,
    //     tipAmount: 12,
    //     totalAmount: 150,
    //     first_name: 'john',
    //     last_name: 'dev',
    //     is_anonymous: true,
    //     offline: true,
    //     mysqlID: 123124,
    //     fees: 1324,
    //     frequency: 'one-off',
    //   },
    //   {
    //     _id: 1,
    //     user: {
    //       profile_image:
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    //       profile_completed: true,
    //     },
    //     billing: {
    //       country: 'eu',
    //       title: 'Anonymous',
    //       first_name: 'John',
    //       last_name: 'dev',
    //       postcode: '123124',
    //       building_name_number: 'house no 123',
    //     },
    //     charity: {
    //       name: 'nim',
    //       slug: '123',
    //       _id: '123124',
    //     },
    //     campaign: {
    //       name: 'my campaign',
    //       slug: 'my slug',
    //       _id: '432523',
    //     },
    //     is_giftaid: true,
    //     anonymous: false,
    //     comment: 'hello',
    //     currency: 'USD',
    //     amount: 124,
    //     created_at: '',
    //     giftAmount: 124,
    //     tipAmount: 12,
    //     totalAmount: 150,
    //     first_name: 'john',
    //     last_name: 'dev',
    //     is_anonymous: false,
    //     offline: false,
    //     mysqlID: 123124,
    //     fees: 1324,
    //     frequency: 'one-off',
    //   },
    //   {
    //     _id: 1,
    //     user: {
    //       profile_image:
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    //       profile_completed: true,
    //     },
    //     billing: {
    //       country: 'eu',
    //       title: 'Anonymous',
    //       first_name: 'John',
    //       last_name: 'dev',
    //       postcode: '123124',
    //       building_name_number: 'house no 123',
    //     },
    //     charity: {
    //       name: 'nim',
    //       slug: '123',
    //       _id: '123124',
    //     },
    //     campaign: {
    //       name: 'my campaign',
    //       slug: 'my slug',
    //       _id: '432523',
    //     },
    //     is_giftaid: true,
    //     anonymous: true,
    //     comment: 'hello',
    //     currency: 'USD',
    //     amount: 124,
    //     created_at: '',
    //     giftAmount: 124,
    //     tipAmount: 12,
    //     totalAmount: 150,
    //     first_name: 'john',
    //     last_name: 'dev',
    //     is_anonymous: true,
    //     offline: true,
    //     mysqlID: 123124,
    //     fees: 1324,
    //     frequency: 'one-off',
    //   },
    //   {
    //     _id: 1,
    //     user: {
    //       profile_image:
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    //       profile_completed: true,
    //     },
    //     billing: {
    //       country: 'eu',
    //       title: 'Anonymous',
    //       first_name: 'John',
    //       last_name: 'dev',
    //       postcode: '123124',
    //       building_name_number: 'house no 123',
    //     },
    //     charity: {
    //       name: 'nim',
    //       slug: '123',
    //       _id: '123124',
    //     },
    //     campaign: {
    //       name: 'my campaign',
    //       slug: 'my slug',
    //       _id: '432523',
    //     },
    //     is_giftaid: true,
    //     anonymous: true,
    //     comment: 'hello',
    //     currency: 'USD',
    //     amount: 124,
    //     created_at: '',
    //     giftAmount: 124,
    //     tipAmount: 12,
    //     totalAmount: 150,
    //     first_name: 'john',
    //     last_name: 'dev',
    //     is_anonymous: true,
    //     offline: true,
    //     mysqlID: 123124,
    //     fees: 1324,
    //     frequency: 'one-off',
    //   },
    //   {
    //     _id: 1,
    //     user: {
    //       profile_image:
    //         'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png',
    //       profile_completed: true,
    //     },
    //     billing: {
    //       country: 'eu',
    //       title: 'Anonymous',
    //       first_name: 'John',
    //       last_name: 'dev',
    //       postcode: '123124',
    //       building_name_number: 'house no 123',
    //     },
    //     charity: {
    //       name: 'nim',
    //       slug: '123',
    //       _id: '123124',
    //     },
    //     campaign: {
    //       name: 'my campaign',
    //       slug: 'my slug',
    //       _id: '432523',
    //     },
    //     is_giftaid: true,
    //     anonymous: true,
    //     comment: 'hello',
    //     currency: 'USD',
    //     amount: 124,
    //     created_at: '',
    //     giftAmount: 124,
    //     tipAmount: 12,
    //     totalAmount: 150,
    //     first_name: 'john',
    //     last_name: 'dev',
    //     is_anonymous: true,
    //     offline: true,
    //     mysqlID: 123124,
    //     fees: 1324,
    //     frequency: 'one-off',
    //   },
    // ];
  }

  get maxDonationLimitReached(): boolean {
    return this.total <= this.limit;
  }

  /**
   * @param load:number  - Number of additional donations to load
   *
   */

  loadMoreDonations(load = 10): void {
    this.loadMoreLimit = this.limit + load;
    this.currentPage = 1;
    this.loading = true;
    if (!this.maxDonationLimitReached) {
      this.getCurrentPage();
    }
  }

  /**
   * @description
   * GET CURRENT PAGE BASED UPON PAGE TYPE
   */
  getCurrentPage(): void {
    switch (this.page) {
      case 'campaign':
        this.campaign = this._campaign;
        break;
      case 'fundraiser':
        this.fundraiser = this._fundraiser;
        break;
      case 'team':
        this.team = this._team;
        break;
    }
  }

  /**
   * @description
   * PAGINATION
   * @param pagination
   */
  pagination(pagination: Observable<Pagination>): void {
    pagination.pipe(debounceTime(200), untilDestroyed(this)).subscribe((pagination) => {
      if (pagination) {
        this.limit = pagination.limit;
        this.total = pagination.total;
        this.currentPage = pagination.page;
      }
    });
  }

  /**
   * @description
   * CALL PAGINATION BASED UPON PAGE TYPE
   */
  paginationType(): void {
    switch (this.page) {
      case 'campaign':
        this.pagination(this.pagination$);
        break;
      case 'fundraiser':
        this.pagination(this.fundraiserPagination$);
        break;
      case 'team':
        this.pagination(this.teamPagination$);
        break;
    }
  }
}
