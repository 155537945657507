import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CampaignImpactListComponent } from './campaign-impact-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [CampaignImpactListComponent],
  imports: [CommonModule, FlexLayoutModule, MatCardModule, MatInputModule, MatFormFieldModule],
  exports: [CampaignImpactListComponent],
})
export class CampaignImpactListModule {}
