import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'givebrite-user-logo-avatar',
  templateUrl: './user-logo-avatar.component.html',
  styleUrls: ['./user-logo-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserLogoAvatarComponent implements OnInit {
  @Input() name;
  @Input() logo;
  @Input() theme;
  @Input() border;
  @Input() width = '50px';
  @Input() radius = '9999px';
  @Input() shadow;
  @Input() profileImage;
  @Input() backgroundColor = 'white';

  constructor() {}

  get cleanWidth() {
    return this.width.replace(/px/g, '');
  }

  ngOnInit(): void {}
}
