<div class="placeholder mat-elevation-z2 text-center p-2">
  <ngx-skeleton-loader
    [animation]="false"
    [theme]="{
      width: '100%',
      height: '30px',
      'margin-bottom': '5px',
      display: 'block'
    }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    [animation]="false"
    [theme]="{
      width: '100%',
      height: '10px',
      'margin-bottom': '5px',
      display: 'block'
    }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    [animation]="false"
    [theme]="{
      width: '80%',
      height: '10px',
      'margin-bottom': '5px',
      display: 'block'
    }"
  ></ngx-skeleton-loader>
  <p class="text-center mt-3">
    <ng-content select="[campaign-add-actions]"></ng-content>
  </p>
</div>
