import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'apps/ramadhan-giving/src/environments/environment';
import { interval, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'givebrite-ramadhan-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class RamadhanCountdownComponent {
  @Output() onAutomate: EventEmitter<boolean> = new EventEmitter<boolean>();

  _options = {
    start_date: '',
    end_date: '',
    cta_title: 'Automate',
    logo: '',
    app_first_name: 'Ramadhan',
    app_last_name: 'Giving',
    live_date: '',
    background: null,
  };

  @Input() set options(options) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  distance: false;
  days;
  hours;
  minutes;
  seconds;
  started: boolean;
  environment = environment;

  public timeLeft$: Observable<any>;
  constructor() {
    this.timeLeft$ = interval(1000).pipe(
      map((x) => this.calcDateDiff(this.startOrEndOfRamadhan())),
      shareReplay(1)
    );
  }

  ngOnInit(): void {}

  calcDateDiff(endDay: Date = new Date()) {
    const countDownDate = endDay.getTime();
    // console.log(countDownDate);
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const daysToDday = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hoursToDday = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesToDday = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsToDday = Math.floor((distance % (1000 * 60)) / 1000);
    // console.log({ secondsToDday, minutesToDday, hoursToDday, daysToDday });
    return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
  }

  startOrEndOfRamadhan() {
    const start = new Date(`${this.startDate}`.replace(/-/g, '/'));
    const end = new Date(`${this.endDate}`.replace(/-/g, '/'));
    const now = new Date();
    if (now.getTime() > start.getTime()) {
      this.started = true;
      return end;
    } else {
      return start;
    }
  }

  automate() {
    this.onAutomate.emit(true);
  }

  get startDate() {
    return this._options.start_date;
  }

  get endDate() {
    return this._options.end_date;
  }

  get ctaTitle() {
    return this._options.cta_title;
  }

  get logo() {
    return this._options.logo;
  }

  get appFirstName() {
    return this._options.app_first_name;
  }

  get appLastName() {
    return this._options.app_last_name;
  }

  get liveDate() {
    return this._options.live_date;
  }

  get background() {
    return this._options.background;
  }
}
