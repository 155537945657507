<div
  fxLayout="column"
  [class]="type"
  [ngClass]="{ less80pcnt: (raised / _target) * 100 < 80, hideGivers: hideGivers }"
>
  <div>
    <mat-progress-bar
      color="primary"
      mode="determinate"
      [value]="(raised / _target) * 100"
    ></mat-progress-bar>
  </div>
  <div class="givers mt-3" fxLayout="row">
    <div fxFlex>
      <strong>{{ raised }}</strong> Givers
    </div>
    <div *ngIf="!hideGivers" class="text-right">
      <strong
        ><span>{{ _target }}</span> Givers</strong
      >
    </div>
  </div>
</div>
