import { CheckoutService } from './../../../../../../apps/donate/src/app/checkout/checkout.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { Select } from '@ngxs/store';
import { GivebriteConfig, GivebriteState } from '@givebrite/data';
import { Observable } from 'rxjs';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
export const MAX_IMPACTS = 3;
export const MIN_IMPACTS = 2;
export const DEFAULT_IMPACTS: AmountSliderInputImpact[] = [
  {
    amount: 10,
  },
  {
    amount: 50,
  },
  {
    amount: 100,
    // default: true,
  },
  {}, // Other
];
export const STEP = 1;

// import { Required } from '@givebrite/core';

/**
 * @Abihinav NOTES
 * this component is such a mess, it is a shared module but uses a service from an app making it completely redundant
 *
 */
@UntilDestroy()
@Component({
  selector: 'givebrite-impact-slider', // givebrite-slider was a very generic name and does not describe the module
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImpactSliderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ImpactSliderComponent,
    },
  ],
})
export class ImpactSliderComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  // Shows/hides slider
  hide_slider: boolean;

  // Step interval
  step: number = STEP;

  // Selected step index
  stepIndex: number = 0;

  // Takes a default amount
  @Input() default: number;

  // Default amount index used for mat-slider
  defaultIndex: number = 0;

  selectedImpact: AmountSliderInputImpact;
  @ViewChild('customAmountInput') customAmountInput: ElementRef;

  // Amount
  _amount: number;

  impactAmount: number;
  impactTitle: string;

  _impacts: AmountSliderInputImpact[] = [];

  // Input text box
  form = this.fb.group({
    amount: [],
  });

  /**
   * @description
   *
   * Takes in impact values and validates against minimum and maximum values
   *
   * @param impacts A required array of impacts
   * only unique amounts
   *
   * @todo: Make required @Input, check angular material for reference
   */
  @Input()
  set impacts(impacts: AmountSliderInputImpact[]) {
    // console.log(impacts);
    if (impacts && impacts?.length) {
      // Filter unique impacts (one amount allowed)
      const unique = [];
      impacts.map((x) =>
        unique.filter((a) => a.amount == x.amount).length > 0 ? null : unique.push(x)
      );

      // sort impacts by amount
      unique.sort((a, b) => a.amount - b.amount);

      // limit to max impacts
      unique.length = unique.length <= MAX_IMPACTS ? unique.length : MAX_IMPACTS;

      // Set local variable with unique impacts
      this._impacts = unique;

      // For other amount appended to end of slider
      this._impacts.push({});
    } else {
      // Use default Impacts
      // console.log('setting default impacts');
      this._impacts = DEFAULT_IMPACTS;

      // Use default
      // set default index based on default value from array || default input || default to first
      this.defaultIndex = this._impacts.findIndex((i) => i.default);
      if (this.defaultIndex == -1) this.defaultIndex = 0;

      // convert index to amount value from impacts array
      this.selectedImpact = this._impacts[this.defaultIndex];

      // convert index to amount value from impacts array
      this._amount = +this.selectedImpact?.amount;
    }
  }

  // required variable
  @Input()
  set currency(currency) {
    // console.log(currency);
    this._currency = currency;
    // Force slider to recall display with ##HACK
    this.step = this.step + 1;
  }
  _currency: string;

  @Input() selectedAmount: number;

  // Outputs chosen impact
  @Output() impact: EventEmitter<any> = new EventEmitter<any>();

  onChange = (amount: number) => {};
  onTouched = () => {};

  writeValue(goal: number) {
    this.amount = goal;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const amount = control.value;
    if (amount <= 0) {
      return {
        mustBePositive: {
          amount,
        },
      };
    }
  }

  constructor(
    private checkoutService: CheckoutService,
    private currencyPipe: CurrencyPipe,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Detect changes in custom amount and update amount
    this.form
      .get('amount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((amount) => {
        this._amount = amount;
        this.onChange(+this._amount);
      });

    this.impactTitle = this.route.snapshot.queryParams.title;
    this.impactAmount = +this.route.snapshot.queryParams.amount;
  }

  ngAfterViewInit() {
    const amountFromQuery = +this.route.snapshot.queryParams.amount;
    const selectedAmount = +this.selectedAmount;

    if (amountFromQuery) {
      const index = this._impacts
        .map((impact) => impact.amount)
        .indexOf(amountFromQuery ? amountFromQuery : selectedAmount);
      this.onInputChange(
        { value: index == -1 ? 3 : index },
        true,
        amountFromQuery ? amountFromQuery : selectedAmount
      );
    }
  }

  get cart() {
    return this.checkoutService.cart;
  }

  /**
   *
   * @returns amount Formatted selected amount
   */
  displayFn = () => {
    // const amount =  ? this.selectedImpact.amount : this.customAmount;
    return this.selectedImpact?.amount
      ? this.currencyPipe.transform(this.selectedImpact?.amount, this._currency, 'symbol', '1.0-0')
      : 'Other';
  };

  onInputChange($event: any, query = false, queryAmount?) {
    this.step = STEP;
    this.stepIndex = +$event.value;
    this.selectedImpact = query ? { amount: queryAmount } : this._impacts[this.stepIndex];

    if (this.selectedImpact?.amount === 0) {
      // console.log('focus on input');
      // Other amount, focus on custom input textbox
      this.customAmountInput.nativeElement.focus();
    } else {
      this.amount = this.selectedImpact?.amount;
    }

    this.impact.emit(this.selectedImpact);
  }

  decreaseAmount() {
    this._amount = this.amount - 1;
    this.amount = this._amount;
    this.onChange(+this._amount);
  }

  increaseAmount() {
    this._amount = this.amount + 1;
    this.amount = this._amount;
    this.onChange(+this._amount);
  }

  set amount(amount: number) {
    // amount set from form
    if (amount) {
      // Find index matching amount
      const index = this._impacts.findIndex((i) => i.amount == amount);

      // Not found, set other
      if (index == -1) {
        this.stepIndex = this._impacts.length - 1;
      } else {
        this.stepIndex = index;
      }

      this.selectedImpact = this._impacts[this.stepIndex];
      this._amount = amount;
    } else {
    }

    this.form.get('amount').setValue(+this._amount, { emitEvent: false });
    this.onChange(+this._amount);
  }

  get amount() {
    return this._amount;
  }

  get customAmount() {
    return this.form.get('amount').value;
  }

  set customAmount(amount) {
    this.form.get('amount').setValue(amount);
  }
}

export interface AmountSliderInputImpact {
  amount?: number;
  name?: string;
  default?: boolean;
}
