<div class="campaign-editor pb-3">
  <form [formGroup]="editorForm">
    <!-- <p class="pb-2">Description</p> -->
    <div
      class="ngxEditor"
      [ngStyle]="{
        border: editorForm.invalid && editorForm.get('content').touched ? '1px solid red' : ''
      }"
    >
      <editor
        apiKey="l1wae27o17cgbbfctg39ewi2wjksaz5otudyl79iln2lic2g"
        formControlName="content"
        cloudChannel="6-dev"
        [init]="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar_mode: 'floating',
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
        }"
        (onSelectionChange)="description($event)"
      ></editor>
    </div>
  </form>
</div>
