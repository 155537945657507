import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaderboardComponent } from './leaderboard.component';
import { LeaderboardItemComponent } from './leaderboard-item/leaderboard-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ProgressBarModule } from './../progress-bar/progress-bar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '../core.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LogoCardModule } from '../view-templates/logo-card/logo-card.module';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

// import { TruncatePipeModule } from '@givebrite/theming';

@NgModule({
  declarations: [LeaderboardItemComponent, LeaderboardComponent],
  imports: [
    CommonModule,
    CoreModule,
    TruncateStringModule,
    // TruncatePipeModule,
    MatButtonModule,
    ProgressBarModule,
    MatTabsModule,
    FlexLayoutModule,
    MatIconModule,
    MatCardModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    LogoCardModule,
  ],
  exports: [LeaderboardComponent, LeaderboardItemComponent],
})
export class LeaderboardModule {}
