import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ProductItem } from '../product.interface';
import { ProductService } from '../product.service';
import { GetProducts } from './product.action';
import { tap, map } from 'rxjs/operators';
import { asapScheduler, Scheduler, Observable } from 'rxjs';
import { patch, append, insertItem, updateItem, iif, removeItem } from '@ngxs/store/operators';

export class ProductStateModel {
  products: ProductItem[];
  loading?: boolean;
}

@State<ProductStateModel>({
  name: 'product',
  defaults: {
    products: [],
    loading: false,
  },
})
export class ProductState {
  constructor(private productService: ProductService) {}

  @Selector()
  static getProducts(state: ProductStateModel) {
    return state.products;
  }

  @Action(GetProducts)
  getProducts({ setState, patchState }: StateContext<ProductStateModel>, { charityId, type }) {
    patchState({ loading: true });
    return this.productService.list({ charity_id: charityId, type: type }).pipe(
      tap((result) => {
        setState({
          products: result,
          loading: false,
        });
      })
    );
  }
}
