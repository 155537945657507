import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'campaign-add-phases-dialod',
  templateUrl: './campaign-add-phases-dialog.component.html',
  styleUrls: ['./campaign-add-phases.component.scss'],
})
export class CampaignAddPhasesDialogComponent implements OnInit {
  form: FormGroup = this.fb.group({
    name: [],
    amount: [],
  });
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CampaignAddPhasesDialogComponent>
  ) {}

  ngOnInit(): void {}

  add() {
    if (this.form.valid) {
      this.dialogRef.close(this.form);
    }
  }
}
