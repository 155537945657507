<div
  class="choose-custom-impacts"
  *ngIf="!(fundraiser?.status == 'ended' || team?.status == 'ended' || campaign?.status == 'ended')"
>
  <!-- <h2 class="mb-5">Choose your impact</h2> -->

  <ngx-flicking #defultImpact [options]="{ bound: true, align: 'prev', autoResize: true }">
    <mat-card
      appearance="outlined"
      class="mat-elevation-z3 flicking-panel mb-4 cursor-pointer"
      flicking-panel
      *ngFor="let impact of impacts; let index = index"
      [class.active]="index === selectedImpact"
      (click)="onImpactChange(impact, index)"
    >
      <mat-card-content class="h-full text-center" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="uppercase font-bold mb-0">
          {{ impact?.amount | currency: currency:'symbol':'1.0-0' }}
        </h3>
      </mat-card-content>
    </mat-card>
  </ngx-flicking>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.xs="10px">
    <givebrite-donate-button
      [charity]="charity"
      [campaign]="campaign"
      [impact]="impact"
      [fundraiser]="fundraiser"
      [team]="team"
      [isImpact]="true"
      [environment]="environment"
      showIcon="true"
      button="raised"
      fxFlex="48"
      fxFlex.xs="68"
      size="large"
      customClass="sm:font-bold uppercase"
    ></givebrite-donate-button>

    <button
      fxFlex="48"
      fxFlex.xs="32"
      mat-raised-button
      class="btn-xl shareBtn uppercase sm:font-bold"
      (click)="openShareDialog()"
    >
      Share
    </button>
  </div>
</div>
