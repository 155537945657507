import { Injectable } from '@angular/core';
import { catchError, findIndex, map } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { ApiService } from '@givebrite/api';
import { CharityService } from '../charity';
import * as moment from 'moment';
import list from '@iconify/icons-ic/trending-up';
import { AuthService } from '../user';
import { environment } from 'apps/dashboard/src/environments/environment';
// import { environment } from 'apps/charities/skt/dashboard/src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: ApiService,
    private charityService: CharityService,
    private authService: AuthService
  ) {}

  campaignType = [
    {
      value: 'p2p',
      name: 'Peer-to-Peer Campaign',
    },

    {
      value: 'crowdfunded',
      name: 'Own Campaign',
    },
  ];
  priceType = [
    {
      value: 'fixedPrice',
      name: 'Fixed Price',
    },

    {
      value: 'openPrice',
      name: 'Open Price',
    },
  ];

  dateFilter = [
    {
      lable: 'All',
      value: 'Latest',
    },
    {
      lable: 'Today',
      value: 'Today',
    },
    {
      lable: 'Last 7 days',
      value: 'Last 7 days',
    },
    {
      lable: 'Last 4 weeks',
      value: 'Last 4 weeks',
    },
    {
      lable: 'Last 3 months',
      value: 'Last 3 months',
    },
    {
      lable: 'Last 6 months',
      value: 'Last 6 months',
    },
    {
      lable: 'Last 12 months',
      value: 'Last 12 months',
    },
  ];
  navlink: any = [
    {
      link: 'overview',
      caption: 'All campaigns',
      icon: list,
    },
    {
      link: 'ongoing',
      caption: 'Ongoing Campaigns',
    },
    {
      link: 'charity',
      caption: 'Charity campaigns',
    },
    {
      link: 'individual',
      caption: 'Individual campaigns',
    },
    {
      link: 'expired',
      caption: 'Expired campaigns',
    },
    // {
    //   link: 'sadaqah-jariah',
    //   caption: 'Sadaqah jariah campaign'
    // },
    // {
    //   link: 'emergency',
    //   caption: 'Emergency campaign'
    // }
  ];
  donationTypes: any = [
    {
      name: 'General',
      display: 'General',
      code: 'General',
      default: true,
    },
    {
      name: 'Sadaqah',
      display: 'Sadaqah',
      code: 'Sadaqah',
      default: false,
    },
    {
      name: 'Lillah',
      display: 'Lillah',
      code: 'Lillah',
      default: false,
    },
    {
      name: 'Zakat',
      display: 'Zakat',
      code: 'zakat',
      default: false,
    },
  ];

  donationTableFilters: any = [
    {
      link: 'recent',
      caption: 'Recent donations',
      icon: list,
    },
    {
      link: 'live-report',
      caption: 'Live Reports',
    },
    {
      link: 'campaign',
      caption: 'Campaign report',
    },
    {
      link: 'all',
      caption: 'All Donations',
    },
    {
      link: 'recurring',
      caption: 'Recurring donation',
    },
    {
      link: 'offline',
      caption: 'Offline donation',
    },
  ];
  userTableFilters: any = [
    {
      link: 'all',
      caption: 'All Users',
      icon: list,
    },
    {
      link: 'registered',
      caption: 'Registered Users',
      icon: list,
    },
    {
      link: 'active',
      caption: 'Active Users',
      icon: list,
    },
    {
      link: 'disabled',
      caption: 'Disabled Users',
      icon: list,
    }
  ];

  fundraisersAndTeamsFilter: any = [
    {
      link: 'fundraisers',
      caption: 'Fundraisers',
    },
    {
      link: 'teams',
      caption: 'Teams',
    },
  ];

  countries = [
    { currency: 'AFN', name: 'Afghanistan', code: 'AF' },
    { currency: 'EUR', name: 'Åland Islands', code: 'AX' },
    { currency: 'ALL', name: 'Albania', code: 'AL' },
    { currency: 'DZD', name: 'Algeria', code: 'DZ' },
    { currency: 'USD', name: 'American Samoa', code: 'AS' },
    { currency: 'EUR', name: 'AndorrA', code: 'AD' },
    { currency: 'AOA', name: 'Angola', code: 'AO' },
    { currency: 'XCD', name: 'Anguilla', code: 'AI' },
    { currency: '', name: 'Antarctica', code: 'AQ' },
    { currency: 'XCD', name: 'Antigua and Barbuda', code: 'AG' },
    { currency: 'ARS', name: 'Argentina', code: 'AR' },
    { currency: 'AMD', name: 'Armenia', code: 'AM' },
    { currency: 'AWG', name: 'Aruba', code: 'AW' },
    { currency: 'AUD', name: 'Australia', code: 'AU' },
    { currency: 'EUR', name: 'Austria', code: 'AT' },
    { currency: 'AZN', name: 'Azerbaijan', code: 'AZ' },
    { currency: 'BSD', name: 'Bahamas', code: 'BS' },
    { currency: 'BHD', name: 'Bahrain', code: 'BH' },
    { currency: 'BDT', name: 'Bangladesh', code: 'BD' },
    { currency: 'BBD', name: 'Barbados', code: 'BB' },
    { currency: 'BYR', name: 'Belarus', code: 'BY' },
    { currency: 'EUR', name: 'Belgium', code: 'BE' },
    { currency: 'BZD', name: 'Belize', code: 'BZ' },
    { currency: 'XOF', name: 'Benin', code: 'BJ' },
    { currency: 'BMD', name: 'Bermuda', code: 'BM' },
    { currency: 'BTN', name: 'Bhutan', code: 'BT' },
    { currency: 'BOB', name: 'Bolivia', code: 'BO' },
    { currency: 'BAM', name: 'Bosnia and Herzegovina', code: 'BA' },
    { currency: 'BWP', name: 'Botswana', code: 'BW' },
    { currency: 'NOK', name: 'Bouvet Island', code: 'BV' },
    { currency: 'BRL', name: 'Brazil', code: 'BR' },
    { currency: 'USD', name: 'British Indian Ocean Territory', code: 'IO' },
    { currency: 'BND', name: 'Brunei Darussalam', code: 'BN' },
    { currency: 'BGN', name: 'Bulgaria', code: 'BG' },
    { currency: 'XOF', name: 'Burkina Faso', code: 'BF' },
    // { currency: "BIF", name: "Burundi", code: "BI" },
    { currency: 'KHR', name: 'Cambodia', code: 'KH' },
    { currency: 'XAF', name: 'Cameroon', code: 'CM' },
    { currency: 'CAD', name: 'Canada', code: 'CA' },
    { currency: 'CVE', name: 'Cape Verde', code: 'CV' },
    { currency: 'KYD', name: 'Cayman Islands', code: 'KY' },
    { currency: 'XAF', name: 'Central African Republic', code: 'CF' },
    { currency: 'XAF', name: 'Chad', code: 'TD' },
    { currency: 'CLP', name: 'Chile', code: 'CL' },
    { currency: 'CNY', name: 'China', code: 'CN' },
    { currency: 'AUD', name: 'Christmas Island', code: 'CX' },
    { currency: 'AUD', name: 'Cocos (Keeling) Islands', code: 'CC' },
    { currency: 'COP', name: 'Colombia', code: 'CO' },
    // { currency: "KMF", name: "Comoros", code: "KM" },
    { currency: 'XAF', name: 'Congo', code: 'CG' },
    { currency: 'CDF', name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { currency: 'NZD', name: 'Cook Islands', code: 'CK' },
    { currency: 'CRC', name: 'Costa Rica', code: 'CR' },
    { currency: 'XOF', name: "Cote D'Ivoire", code: 'CI' },
    { currency: 'HRK', name: 'Croatia', code: 'HR' },
    { currency: 'CUP', name: 'Cuba', code: 'CU' },
    { currency: 'EUR', name: 'Cyprus', code: 'CY' },
    { currency: 'CZK', name: 'Czech Republic', code: 'CZ' },
    { currency: 'DKK', name: 'Denmark', code: 'DK' },
    // { currency: "DJF", name: "Djibouti", code: "DJ" },
    { currency: 'XCD', name: 'Dominica', code: 'DM' },
    { currency: 'DOP', name: 'Dominican Republic', code: 'DO' },
    { currency: 'USD', name: 'Ecuador', code: 'EC' },
    { currency: 'EGP', name: 'Egypt', code: 'EG' },
    { currency: 'USD', name: 'El Salvador', code: 'SV' },
    { currency: 'XAF', name: 'Equatorial Guinea', code: 'GQ' },
    { currency: 'ERN', name: 'Eritrea', code: 'ER' },
    { currency: 'EUR', name: 'Estonia', code: 'EE' },
    { currency: 'ETB', name: 'Ethiopia', code: 'ET' },
    { currency: 'FKP', name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { currency: 'DKK', name: 'Faroe Islands', code: 'FO' },
    { currency: 'FJD', name: 'Fiji', code: 'FJ' },
    { currency: 'EUR', name: 'Finland', code: 'FI' },
    { currency: 'EUR', name: 'France', code: 'FR' },
    { currency: 'EUR', name: 'French Guiana', code: 'GF' },
    { currency: 'XPF', name: 'French Polynesia', code: 'PF' },
    { currency: 'EUR', name: 'French Southern Territories', code: 'TF' },
    { currency: 'XAF', name: 'Gabon', code: 'GA' },
    { currency: 'GMD', name: 'Gambia', code: 'GM' },
    { currency: 'GEL', name: 'Georgia', code: 'GE' },
    { currency: 'EUR', name: 'Germany', code: 'DE' },
    { currency: 'GHS', name: 'Ghana', code: 'GH' },
    { currency: 'GIP', name: 'Gibraltar', code: 'GI' },
    { currency: 'EUR', name: 'Greece', code: 'GR' },
    { currency: 'DKK', name: 'Greenland', code: 'GL' },
    { currency: 'XCD', name: 'Grenada', code: 'GD' },
    { currency: 'EUR', name: 'Guadeloupe', code: 'GP' },
    { currency: 'USD', name: 'Guam', code: 'GU' },
    { currency: 'GTQ', name: 'Guatemala', code: 'GT' },
    { currency: 'GBP', name: 'Guernsey', code: 'GG' },
    { currency: 'GNF', name: 'Guinea', code: 'GN' },
    { currency: 'XOF', name: 'Guinea-Bissau', code: 'GW' },
    { currency: 'GYD', name: 'Guyana', code: 'GY' },
    { currency: 'HTG', name: 'Haiti', code: 'HT' },
    { currency: 'AUD', name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { currency: 'EUR', name: 'Holy See (Vatican City State)', code: 'VA' },
    { currency: 'HNL', name: 'Honduras', code: 'HN' },
    { currency: 'HKD', name: 'Hong Kong', code: 'HK' },
    { currency: 'HUF', name: 'Hungary', code: 'HU' },
    { currency: 'ISK', name: 'Iceland', code: 'IS' },
    { currency: 'INR', name: 'India', code: 'IN' },
    { currency: 'IDR', name: 'Indonesia', code: 'ID' },
    { currency: 'IRR', name: 'Iran, Islamic Republic Of', code: 'IR' },
    { currency: 'IQD', name: 'Iraq', code: 'IQ' },
    { currency: 'EUR', name: 'Ireland', code: 'IE' },
    { currency: 'GBP', name: 'Isle of Man', code: 'IM' },
    { currency: 'ILS', name: 'Israel', code: 'IL' },
    { currency: 'EUR', name: 'Italy', code: 'IT' },
    { currency: 'JMD', name: 'Jamaica', code: 'JM' },
    { currency: 'JPY', name: 'Japan', code: 'JP' },
    { currency: 'GBP', name: 'Jersey', code: 'JE' },
    { currency: 'JOD', name: 'Jordan', code: 'JO' },
    { currency: 'KZT', name: 'Kazakhstan', code: 'KZ' },
    { currency: 'KES', name: 'Kenya', code: 'KE' },
    { currency: 'AUD', name: 'Kiribati', code: 'KI' },
    { currency: 'KPW', name: "Korea, Democratic People'S Republic of", code: 'KP' },
    // { currency: "KRW", name: "Korea, Republic of", code: "KR" },
    { currency: 'KWD', name: 'Kuwait', code: 'KW' },
    { currency: 'KGS', name: 'Kyrgyzstan', code: 'KG' },
    { currency: 'LAK', name: "Lao People'S Democratic Republic", code: 'LA' },
    { currency: 'EUR', name: 'Latvia', code: 'LV' },
    { currency: 'LBP', name: 'Lebanon', code: 'LB' },
    { currency: 'LSL', name: 'Lesotho', code: 'LS' },
    { currency: 'LRD', name: 'Liberia', code: 'LR' },
    { currency: 'LYD', name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { currency: 'CHF', name: 'Liechtenstein', code: 'LI' },
    { currency: 'LTL', name: 'Lithuania', code: 'LT' },
    { currency: 'EUR', name: 'Luxembourg', code: 'LU' },
    { currency: 'MOP', name: 'Macao', code: 'MO' },
    { currency: 'MKD', name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { currency: 'MGA', name: 'Madagascar', code: 'MG' },
    { currency: 'MWK', name: 'Malawi', code: 'MW' },
    { currency: 'MYR', name: 'Malaysia', code: 'MY' },
    { currency: 'MVR', name: 'Maldives', code: 'MV' },
    { currency: 'XOF', name: 'Mali', code: 'ML' },
    { currency: 'EUR', name: 'Malta', code: 'MT' },
    { currency: 'USD', name: 'Marshall Islands', code: 'MH' },
    { currency: 'EUR', name: 'Martinique', code: 'MQ' },
    { currency: 'MRO', name: 'Mauritania', code: 'MR' },
    { currency: 'MUR', name: 'Mauritius', code: 'MU' },
    { currency: 'EUR', name: 'Mayotte', code: 'YT' },
    { currency: 'MXN', name: 'Mexico', code: 'MX' },
    { currency: 'USD', name: 'Micronesia, Federated States of', code: 'FM' },
    { currency: 'MDL', name: 'Moldova, Republic of', code: 'MD' },
    { currency: 'EUR', name: 'Monaco', code: 'MC' },
    { currency: 'MNT', name: 'Mongolia', code: 'MN' },
    { currency: 'XCD', name: 'Montserrat', code: 'MS' },
    { currency: 'MAD', name: 'Morocco', code: 'MA' },
    { currency: 'MZN', name: 'Mozambique', code: 'MZ' },
    { currency: 'MMK', name: 'Myanmar', code: 'MM' },
    { currency: 'NAD', name: 'Namibia', code: 'NA' },
    { currency: 'AUD', name: 'Nauru', code: 'NR' },
    { currency: 'NPR', name: 'Nepal', code: 'NP' },
    { currency: 'EUR', name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { currency: 'XPF', name: 'New Caledonia', code: 'NC' },
    { currency: 'NZD', name: 'New Zealand', code: 'NZ' },
    { currency: 'NIO', name: 'Nicaragua', code: 'NI' },
    { currency: 'XOF', name: 'Niger', code: 'NE' },
    { currency: 'NGN', name: 'Nigeria', code: 'NG' },
    { currency: 'NZD', name: 'Niue', code: 'NU' },
    { currency: 'AUD', name: 'Norfolk Island', code: 'NF' },
    { currency: 'USD', name: 'Northern Mariana Islands', code: 'MP' },
    { currency: 'NOK', name: 'Norway', code: 'NO' },
    { currency: 'OMR', name: 'Oman', code: 'OM' },
    { currency: 'PKR', name: 'Pakistan', code: 'PK' },
    { currency: 'USD', name: 'Palau', code: 'PW' },
    { currency: 'ILS', name: 'Palestinian Territory, Occupied', code: 'PS' },
    { currency: 'PAB', name: 'Panama', code: 'PA' },
    { currency: 'PGK', name: 'Papua New Guinea', code: 'PG' },
    { currency: 'PYG', name: 'Paraguay', code: 'PY' },
    { currency: 'PEN', name: 'Peru', code: 'PE' },
    { currency: 'PHP', name: 'Philippines', code: 'PH' },
    { currency: 'NZD', name: 'Pitcairn', code: 'PN' },
    { currency: 'PLN', name: 'Poland', code: 'PL' },
    { currency: 'EUR', name: 'Portugal', code: 'PT' },
    { currency: 'USD', name: 'Puerto Rico', code: 'PR' },
    { currency: 'QAR', name: 'Qatar', code: 'QA' },
    { currency: 'EUR', name: 'Reunion', code: 'RE' },
    { currency: 'RON', name: 'Romania', code: 'RO' },
    { currency: 'RUB', name: 'Russian Federation', code: 'RU' },
    { currency: 'RWF', name: 'RWANDA', code: 'RW' },
    { currency: 'SHP', name: 'Saint Helena', code: 'SH' },
    { currency: 'XCD', name: 'Saint Kitts and Nevis', code: 'KN' },
    { currency: 'XCD', name: 'Saint Lucia', code: 'LC' },
    { currency: 'EUR', name: 'Saint Pierre and Miquelon', code: 'PM' },
    { currency: 'XCD', name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { currency: 'WST', name: 'Samoa', code: 'WS' },
    { currency: 'EUR', name: 'San Marino', code: 'SM' },
    { currency: 'STD', name: 'Sao Tome and Principe', code: 'ST' },
    { currency: 'SAR', name: 'Saudi Arabia', code: 'SA' },
    { currency: 'XOF', name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { currency: 'SCR', name: 'Seychelles', code: 'SC' },
    { currency: 'SLL', name: 'Sierra Leone', code: 'SL' },
    { currency: 'SGD', name: 'Singapore', code: 'SG' },
    { currency: 'EUR', name: 'Slovakia', code: 'SK' },
    { currency: 'EUR', name: 'Slovenia', code: 'SI' },
    { currency: 'SBD', name: 'Solomon Islands', code: 'SB' },
    { currency: 'SOS', name: 'Somalia', code: 'SO' },
    { currency: 'ZAR', name: 'South Africa', code: 'ZA' },
    { currency: 'GBP', name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { currency: 'EUR', name: 'Spain', code: 'ES' },
    { currency: 'LKR', name: 'Sri Lanka', code: 'LK' },
    { currency: 'SDG', name: 'Sudan', code: 'SD' },
    { currency: 'SRD', name: 'Suriname', code: 'SR' },
    { currency: 'NOK', name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { currency: 'SZL', name: 'Swaziland', code: 'SZ' },
    { currency: 'SEK', name: 'Sweden', code: 'SE' },
    { currency: 'CHF', name: 'Switzerland', code: 'CH' },
    { currency: 'SYP', name: 'Syrian Arab Republic', code: 'SY' },
    { currency: 'TWD', name: 'Taiwan, Province of China', code: 'TW' },
    { currency: 'TJS', name: 'Tajikistan', code: 'TJ' },
    { currency: 'TZS', name: 'Tanzania, United Republic of', code: 'TZ' },
    { currency: 'THB', name: 'Thailand', code: 'TH' },
    { currency: 'USD', name: 'Timor-Leste', code: 'TL' },
    { currency: 'XOF', name: 'Togo', code: 'TG' },
    { currency: 'NZD', name: 'Tokelau', code: 'TK' },
    { currency: 'TOP', name: 'Tonga', code: 'TO' },
    { currency: 'TTD', name: 'Trinidad and Tobago', code: 'TT' },
    { currency: 'TND', name: 'Tunisia', code: 'TN' },
    { currency: 'TRY', name: 'Turkey', code: 'TR' },
    { currency: 'TMT', name: 'Turkmenistan', code: 'TM' },
    { currency: 'USD', name: 'Turks and Caicos Islands', code: 'TC' },
    { currency: 'AUD', name: 'Tuvalu', code: 'TV' },
    { currency: 'UGX', name: 'Uganda', code: 'UG' },
    { currency: 'UAH', name: 'Ukraine', code: 'UA' },
    { currency: 'AED', name: 'United Arab Emirates', code: 'AE' },
    { currency: 'GBP', name: 'United Kingdom', code: 'GB' },
    { currency: 'USD', name: 'United States', code: 'US' },
    { currency: 'USD', name: 'United States Minor Outlying Islands', code: 'UM' },
    { currency: 'UYU', name: 'Uruguay', code: 'UY' },
    { currency: 'UZS', name: 'Uzbekistan', code: 'UZ' },
    // { currency: "VUV", name: "Vanuatu", code: "VU" },
    { currency: 'VEF', name: 'Venezuela', code: 'VE' },
    { currency: 'VND', name: 'Viet Nam', code: 'VN' },
    { currency: 'USD', name: 'Virgin Islands, British', code: 'VG' },
    { currency: 'USD', name: 'Virgin Islands, U.S.', code: 'VI' },
    { currency: 'XPF', name: 'Wallis and Futuna', code: 'WF' },
    { currency: 'MAD', name: 'Western Sahara', code: 'EH' },
    { currency: 'YER', name: 'Yemen', code: 'YE' },
    { currency: 'ZMK', name: 'Zambia', code: 'ZM' },
    { currency: 'ZWL', name: 'Zimbabwe', code: 'ZW' },
  ];

  projectNavlink: any = [
    {
      link: 'overview',
      caption: 'All Projects',
      icon: list,
    },
    {
      link: 'active',
      caption: 'Projects',
    },
    {
      link: 'appeal',
      caption: 'Active Appeals',
    },
  ];
  charitiesNavlink: any = [
    {
      link: 'overview',
      caption: 'Top Charities',
      icon: list,
    },
    {
      link: 'active',
      caption: 'Active Charities',
    },
    {
      link: 'disable',
      caption: 'Disabled Charities',
    },
    {
      link: 'pending',
      caption: 'Pending Verification',
    },
  ];
  ramadhangivingNavlink: any = [
    {
      link: 'overview',
      caption: 'overview',
      icon: list,
    },
    // {
    //   link: 'subscriptions',
    //   caption: 'Subscriptions',
    //   icon: list,
    // },
    // {
    //   link: 'settings',
    //   caption: 'Settings',
    //   icon: list,
    // },
    // {
    //   link: 'campaign-reports',
    //   caption: 'Campaign report',
    // },
    // {
    //   link: 'all-donations',
    //   caption: 'All Donations',
    // },
    // {
    //   link: 'recurring-donation',
    //   caption: 'Recurring Donation',
    // },
    // {
    //   link: 'offline-donation',
    //   caption: 'Offline Donation',
    // },
  ];

  campaignIdSubject = new BehaviorSubject<string>('');
  campaignId$ = this.campaignIdSubject.asObservable();
  isAuthenticatedSuper = this.authService.isSuper;
  //count exprired day from given date
  countExpiredDay(date: any) {
    if (!date) {
      return 'Ongoing';
    }
    let day = parseInt(
      ((new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)).toFixed(0)
    );
    
    if(day > 31){
      if(Math.floor(day/30) > 12){
        return `Ending in  ${Math.floor((day/30)/12)} year`;
      }else {
        return `Ending in  ${Math.floor(day/30)}  month`;
      }
    }
    else if (day <= 31 && day >= 0) {
      return `Ending in  ${day}  days`;
    } else if (day < 0) {
      day = Math.abs( day );
      if(day > 31){
        if(Math.floor(day/30) > 12){
          return `Expired ${Math.floor((day/30)/12)} year ago`;
        }else {
          return `Expired ${Math.floor(day/30)}  month ago`;
        }
      }
      else if (day <= 31 && day >= 0) {
        return `Expired ${day}  days ago`;
      }
    } else {
      return `Expired`;
    }
    return `Expired`;
  }

  //dashboard apis

  getCampaignTypaName(value) {
    return this.campaignType.find((data) => (data.value = value))?.name;
  }

  getDashboardCampaigns(
    filter_by,
    search,
    page,
    limit,
    sort_value,
    sort_title,
    from_date,
    to_date,
    value
  ) {
    let setID='';
    if(filter_by != 'user'){
      setID = `&charity_id=${value ? value : this.charityService.getCharityIdFromToken()}`
    }else{
      setID = `&value=${value}&charity_id=`
    }
    return this.http
      .get(
        `dashboard/campaign?page=${page}&limit=${limit}&search=${search}&filter_by=${filter_by}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}`+setID
      )
      .pipe(
        map((response) => {
          return {
            campaigns: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  getDashboardStatistics() {
    return this.http
      .get(`dashboard/overview?charity_id=${this.charityService.getCharityIdFromToken()}`)
      .pipe(
        map((response) => {
          return response[0];
        })
      );
  }

  create(payload) {
    return this.http
      .post(`dashboard/campaign?charity_id=${this.charityService.getCharityIdFromToken()}`, payload)
      .pipe(
        map((response) => {
          return { campaign: response };
        })
      );
  }

  getCampaign(slug) {
    return this.http
      .get(`dashboard/campaign/${slug}?charity_id=${this.charityService.getCharityIdFromToken()}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  edit(slug, payload) {
    return this.http
      .put(
        `dashboard/campaign/${slug}?charity_id=${this.charityService.getCharityIdFromToken()}`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  fundraisers(
    id,
    filter_by = 'charity',
    search = '',
    page = 1,
    limit = 10,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    let filter_id = id || this.charityService.getCharityIdFromToken();
    return this.http
      .get(
        `dashboard/fundraisers?filter_by=${filter_by}&value=${filter_id}&search=${search}&page=${page}&limit=${limit}&sort_title=${sort_title}&sort_value=${sort_value}&from_date=${from_date}&to_date=${to_date}&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          let fundraisers = response.docs.map((fundraiser) => {
            let name = {
              first_name: fundraiser.user ? fundraiser.user.first_name : fundraiser.first_name,
              last_name: fundraiser.user ? fundraiser.user.last_name : fundraiser.last_name,
            };
            return { ...fundraiser, ...name };
          });

          return {
            fundraisers: fundraisers,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
        };
      })
    );
  }

  teams(
    id,
    filter_by = 'charity',
    search = '',
    page = 1,
    limit = 10,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    let filter_id = id || this.charityService.getCharityIdFromToken();
    return this.http
      .get(
        `dashboard/teams?filter_by=${filter_by}&value=${filter_id}&search=${search}&page=${page}&limit=${limit}&sort_title=${sort_title}&sort_value=${sort_value}&from_date=${from_date}&to_date=${to_date}&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          let teams = response.docs.map((fundraiser) => {
            let name = {
              first_name: fundraiser.user ? fundraiser.user.first_name : fundraiser.first_name,
              last_name: fundraiser.user ? fundraiser.user.last_name : fundraiser.last_name,
            };
            return { ...fundraiser, ...name };
          });

          return {
            teams: teams,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  donations(
    id,
    filter_by = 'charity',
    type = '',
    search = '',
    page = 1,
    limit = 10,
    // title_sort = 1,
    // date_sort = 1,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    let filter_id = id || this.charityService.getCharityIdFromToken();
    let charityid = filter_by == 'charity' ? id : this.charityService.getCharityIdFromToken();
    if(!filter_id || filter_id == null){
      filter_by = '';
    }
    return this.http
      .get(
        `dashboard/donations?filter_by=${filter_by}&value=${filter_id}&type=${type}&search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&charity_id=${charityid}`
      )
      .pipe(
        map((response) => {
          return {
            donations: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  subscriptionsList(
    id,
    filter_by = 'charity',
    type = '',
    search = '',
    page = 1,
    limit = 10,
    // title_sort = 1,
    // date_sort = 1,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    let filter_id = id || this.charityService.getCharityIdFromToken();
    let charityid = filter_by == 'charity' ? id : this.charityService.getCharityIdFromToken();
    if(filter_by == 'user'){
      return this.http
      .get(
        `dashboard/subscription?filter_by=${filter_by}&value=${filter_id}&type=${type}&search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&charity_id=${charityid}`
      )
      .pipe(
        map((response) => {
          return {
            subscriptions: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
    } else {
    return this.http
      .get(
        `dashboard/charity/subscriptions/${charityid}?filter_by=${filter_by}&value=${filter_id}&type=${type}&search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&charity_id=${charityid}`
      )
      .pipe(
        map((response) => {
          return {
            subscriptions: response.result.docs,
            pagination: {
              limit: response.result.limit,
              pages: response.result.pages,
              page: response.result.page,
              total: response.result.total,
            },
          };
        })
      );
    }
  }

  subscriptionsRGList(
    id,
    filter_by = 'charity',
    type = '',
    search = '',
    page = 1,
    limit = 10,
    // title_sort = 1,
    // date_sort = 1,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    let filter_id = id || this.charityService.getCharityIdFromToken();
    let charityid = filter_by == 'charity' ? id : this.charityService.getCharityIdFromToken();
    return this.http
      .get(
        `dashboard/subscription?filter_by=${filter_by}&value=${filter_id}&type=${type}&search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&charity_id=${charityid}`)
      .pipe(
        map((response) => {
          return {
            subscriptions: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  addOfflineDonation(payload) {
    payload = {
      ...payload,
      charity: this.charityService.getCharityIdFromToken(),
    };
    return this.http
      .post(`dashboard/donations/offline?charity_id=${this.charityService.getCharityIdFromToken()}`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  moveFundraiserToTeam(payload) {
    return this.http
      .patch(`dashboard/fundraisers/move/team/${payload.fundraiser_id}?team=${payload.team_id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  moveFundraiserToCampaign(payload) {
    return this.http
      .patch(`dashboard/fundraisers/move/campaign/${payload.fundraiser_id}?campaign=${payload.campaign_id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  moveDonationToFundraiser(payload) {
    return this.http
      .patch(`dashboard/donations/move/fundraiser/${payload.donation_id}?fundraiser=${payload.fundraiser_id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  moveDonationToTeam(payload) {
    return this.http
      .patch(`dashboard/donations/move/team/${payload.donation_id}?team=${payload.team_id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  checkStockFile(payload) {
    return this.http
      .post(`dashboard/inventory/file/statistics`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  uploadStockFile(payload) {
    return this.http
      .post(`dashboard/inventory/upload`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  uploadLogoFile(id, payload) {
    return this.http
      .post(`dashboard/apps/logo/${id}`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFundraiser(slug) {
    return this.http.get(
      `dashboard/fundraisers/${slug}?type=_id&charity_id=${this.charityService.getCharityIdFromToken()}`
    );
  }

  editFundraisers(slug, payload) {
    const charity_id = this.charityService.getCharityIdFromToken() || payload.charity;
    return this.http
      .put(`dashboard/fundraisers?fundraiser_id=${slug}&charity_id=${charity_id}`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editFundraiser(id, payload) {
    const charity_id = this.charityService.getCharityIdFromToken() || payload.charity;
    return this.http
      .put(`dashboard/fundraisers?fundraiser_id=${id}&charity_id=${charity_id}`, payload)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addFundraiser(payload) {
    const id = this.charityService.getCharityIdFromToken() || payload.charity;
    return this.http.post(`dashboard/fundraisers?charity_id=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  comments(
    id,
    filter_by = 'campaign',
    search = '',
    page = 1,
    limit = 10,
    title_sort = 1,
    date_sort = 1,
    from_date,
    to_date,
    comment_type
  ) {
    return this.http
      .get(
        `dashboard/donations/comment?filter_by=${filter_by}&value=${id}&search=${search}&page=${page}&limit=${limit}&title_sort=${title_sort}&date_sort=${date_sort}&from_date=${from_date}&to_date=${to_date}&comment_type=${comment_type}&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          return {
            comments: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  addReply(id, msg) {
    return this.http.post(`donation/reply?comment=${id}`, msg).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateReply(id, payload = '') {
    return this.http.put(`dashboard/donations/reply?reply=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteReply(id, payload = '') {
    return this.http.put(`dashboard/donations/comment/reply?reply=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateComment(id, payload = '') {
    return this.http.put(`donation/comment?id=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteComment(id, payload = '') {
    return this.http.put(`dashboard/donations/comment?comment=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getTeam(id) {
    return this.http
      .get(
        `dashboard/teams/${id}?type=_id&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editTeam(id, payload) {
    const charity_id = this.charityService.getCharityIdFromToken() || payload.charity;
    return this.http.put(`dashboard/teams?teams_id=${id}&charity_id=${charity_id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addTeam(payload) {
    const id = this.charityService.getCharityIdFromToken() || payload.charity;
    return this.http.post(`dashboard/teams?charity_id=${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getFollowers(search = '') {
    return this.http
      .get(
        `dashboard/charity/followers?charity=${this.charityService.getCharityIdFromToken()}&search=${search}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  toggleChange(event): any {
    switch (event) {
      case 'Today':
        return {
          from_date: moment().format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
      case 'Last 7 days':
        return {
          from_date: moment().subtract(7, 'd').format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
      case 'Last 4 weeks':
        return {
          from_date: moment().subtract(4, 'weeks').format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
      case 'Last 3 months':
        return {
          from_date: moment().subtract(3, 'month').format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
      case 'Last 6 months':
        return {
          from_date: moment().subtract(6, 'month').format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
      case 'Last 12 months':
        return {
          from_date: moment().subtract(1, 'y').format('yyyy-MM-DD'),
          to_date: moment().format('yyyy-MM-DD'),
        };
    }
  }

  export(payload) {
    return this.http
      .post(
        `dashboard/donations/csv?filter_by=${payload.filter_by}&value=${payload.value}`,
        payload
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  exportFundraiser(payload) {
    return this.http.post(`dashboard/fundraisers/csv?_id=${payload.value}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  exportStatus(id, type) {
    return this.http.get(`dashboard/${type}/csv?id=${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDonationsStatistics() {
    return this.http.get(`dashboard/donations/statistics`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getUserStatistics() {
    return this.http.get(`dashboard/user/counts`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  
  getUsersStatistics() {
    return this.http.get(`dashboard/user/statistics`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getRamadhanGivingStatistics() {
    return this.http
      .get(`dashboard/charity/statistics`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDonationsStatisticsAll() {
    return this.http
      .get(`dashboard/app/statistics`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getQGStatistics() {
    return this.http.get(`dashboard/charity/statistics`);
  }

  getAnimalsList() {
    return this.http.get(`dashboard/inventory/statistics`);
  }

  getAnimalsByCountry(countryCode) {
    return this.http.get(`dashboard/inventory/country?id=${countryCode}`);
  }

  getStocksList(
    search = '',
    page = 1,
    limit = 10,
    sort_value,
    sort_title,
    from_date,
    to_date,
    animal
) {
  return this.http
    .get(
      `dashboard/inventory?search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&animal=${animal}&charity_id=${this.charityService.getCharityIdFromToken()}`
    ).pipe(
      map((response) => {
        return {
          stocks: response.docs,
          pagination: {
            limit: response.limit,
            pages: response.pages,
            page: response.page,
            total: response.total,
          },
        };
      })
    );
  }

  getStockById(stockId) {
    return this.http.get(`dashboard/inventory/${stockId}`);
  }
  

  resetDashboardUser(id, payload) {
    return this.http.patch(`dashboard/user/reset/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  resetPasswordLinkDashboardUser(email) {
    return this.http.post(`dashboard/user/send_reset_password_link`, {email:email,redirect_uri: encodeURIComponent(environment.app.url)}).pipe(
      map((response) => {
        return response;
      })
    );
  }


  addStock(payload) {
    return this.http.post(`dashboard/inventory`, payload);
  }

  updateStock(id, payload) {
    return this.http.put(`dashboard/inventory/${id}`, payload);
  }

  getDashboardQGDonations(
    search = '',
    page = 1,
    limit = 10,
    sort_value,
    sort_title,
    from_date,
    to_date
  ) {
    return this.http
      .get(
        `dashboard/donations?search=${search}&page=${page}&limit=${limit}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          return {
            donations: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  exportQGDonations(payload) {
    return this.http.post(`dashboard/donations/csv`, payload);
  }
  exportQGStock(payload) {
    return this.http.post(`dashboard/inventory/csv`, payload);
  }
  exportRGDonations(payload) {
    return this.http.post(
      `dashboard/donations/csv`,
      payload
    );
  }
  exportRGSubscriptions(payload) {
    const charity_id = this.charityService.getCharityIdFromToken() || payload.charity;

    return this.http.post(
      `dashboard/subscription/csv?filter_by=charity&value=${charity_id}`,
      payload
    );
  }
  exportProjectCSV(payload) {
    return this.http.post(`dashboard/project/csv`, payload);
  }
  exportAppealCSV(payload) {
    return this.http.post(`dashboard/appeal/csv`, payload);
  }

  //Project CRUD
  createProject(payload) {
    return this.http.post(`dashboard/project`, payload).pipe(
      map((response) => {
        return { project: response };
      })
    );
  }
  
  createProjectAppeal(payload) {
    return this.http.post(`dashboard/appeal`, payload).pipe(
      map((response) => {
        return { projectAppeals: response };
      })
    );
  }

  getProject(id) {
    return this.http.get(`dashboard/project/${id}?type=_id`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getAppeal(id) {
    return this.http.get(`dashboard/appeal/${id}?type=_id`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  syncDashboardProject(id, wp_id) {
    return this.http.get(`dashboard/project/wp/${wp_id}?project_id=${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  syncDashboardAppeal(id, wp_id) {
    return this.http.get(`dashboard/appeal/wp/${wp_id}?appeal_id=${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  
  getProjectAppeal(id) {
    return this.http.get(`dashboard/appeal?filter_by=project&value=${id}`).pipe(
      map((response) => {
        return response.docs;
      })
    );
  }
  getAllAppeal(filter_by, search, page, limit, sort_value, sort_title, from_date, to_date, status) {
    return this.http.get(`dashboard/appeal?page=${page}&limit=${limit}&search=${search}&filter_by=${filter_by}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&status=${status}&charity_id=${this.charityService.getCharityIdFromToken()}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editProject(id, payload) {
    return this.http.put(`dashboard/project/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editProjectAppeal(id, payload) {
    return this.http.put(`dashboard/appeal/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteProject(id) {
    return this.http.delete(`dashboard/project/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  
  deleteProjectAppeal(id, payload = '') {
    return this.http.put(`dashboard/appeal/deactivate/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDashboardProjects(filter_by, search, page, limit, sort_value, sort_title, from_date, to_date, status) {
    return this.http
      .get(
        `dashboard/project?page=${page}&limit=${limit}&search=${search}&filter_by=${filter_by}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&status=${status}&charity_id=${this.charityService.getCharityIdFromToken()}`
      )
      .pipe(
        map((response) => {
          return {
            projects: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  // Project category CRUD
  createProjectCategory(payload) {
    return this.http.post(`dashboard/category`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  removeProjectCauses(payload) {
    const id = this.charityService.getCharityIdFromToken();
    return this.http.delete(`dashboard/project/causes?charity_id=` + id, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  createProjectCauses(payload) {
    const id = this.charityService.getCharityIdFromToken();
    return this.http.post(`dashboard/project/causes?charity_id=` + id, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  removeDashboardUser(id) {
    return this.http.delete(`dashboard/user/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  enableDashboardUser(id) {
    return this.http.patch(`dashboard/user/enable/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  editDashboardDonation(id, payload) {
    return this.http.patch(`dashboard/donations/edit/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  refundDashboardDonation(id, payload) {
    return this.http.post(`dashboard/donations/refund/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  editDashboardSubscription(id, payload) {
    return this.http.patch(`dashboard/subscription/edit/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  createDashboardUser(payload) {
    return this.http.post(`dashboard/user`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editDashboardUser(id, payload) {
    return this.http.patch(`dashboard/user/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }


  getProjectCategory(id) {
    return this.http.get(`dashboard/category/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getDonationById(id) {
    return this.http.get(`dashboard/donations/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getUserById(id) {
    return this.http.get(`dashboard/user/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getSubscriptionById(id) {
    return this.http.get(`dashboard/subscription/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editProjectCategory(id, payload) {
    return this.http.put(`dashboard/category/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  editDashboardCharity(id, payload) {
    return this.http.put(`dashboard/charity/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }

getDashboardProjectscategories( search, page, limit, sort_value, sort_title, from_date, to_date) {
  return this.http
    .get(
      `dashboard/category?page=${page}&limit=${limit}&search=${search}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}`
    ).pipe(
      map((response) => {
        return {
          projectcategory: response.docs,
          pagination: {
            limit: response.limit,
            pages: response.pages,
            page: response.page,
            total: response.total,
          },
        };
      })
    );
  }
  getDashboardUsers(filter_by, page, limit, value, search, sort_value, sort_title, from_date, to_date, status) {
    return this.http
      .get(`dashboard/user?page=${page}&limit=${limit}&filter_by=${filter_by}&value=${value}&search=${search}&sort_value=${sort_value}&sort_title=${sort_title}&from_date=${from_date}&to_date=${to_date}&status=${status}`)
      .pipe(
        map((response) => {
          return {
            users: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  getDonationsTypes() {
    return this.http.get(`dashboard/campaign/donation_types`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getDonationTypes() {
    return this.http.get(`dashboard/appeal/options`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getAllDashboardProjects() {
    return this.http.get(`dashboard/project`).pipe(
      map((response) => {
        return {
          projects: response.docs,
        };
      })
    );
  }
  getDashboardUnVerifiedCharitiesCount() {
    return this.http.get(`dashboard/charity/list?verified=false`).pipe(
      map((response) => {
        return response.total;
      })
    );
  }

  updateAppSettings(id, payload) {
    return this.http.patch(`dashboard/apps/${id}`, payload).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getCharityCounts() {
    return this.http.get(`dashboard/charity/all/count`);
  }

  getCampaignStatistics() {
    return this.http.get(`dashboard/campaign/all/count`);
  }
  getAppSettings() {
    return this.http.get(`dashboard/apps`);
  }

  getCharities(
    page,
    limit,
    search,
    from_date,
    to_date,
    sort_title,
    sort_value,
    verified,
    active,
    is_featured
  ) {
    return this.http
      .get(
        `dashboard/charity/list?page=${page}&limit=${limit}&search=${search}&from_date=${from_date}&to_date=${to_date}&sort_title=${sort_title}&sort_value=${sort_value}&is_featured=${is_featured}&active=${active}&verified=${verified}`
      )
      .pipe(
        map((response) => {
          return {
            charities: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }
}
