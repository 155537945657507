import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignSelectorRoutingModule } from './campaign-selector-routing.module';
import { CampaignSelectorComponent } from './campaign-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { TickMarkModule } from '../../tick-mark/tick-mark.module';
import { CampaignCardModule } from '../../campaign-card/campaign-card.module';
import { FilterSearchModule } from 'libs/theming/src/lib/pipes/filter-search/filter-search.module';

@NgModule({
  declarations: [CampaignSelectorComponent],
  imports: [
    CommonModule,
    CampaignSelectorRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    FilterSearchModule,
    TickMarkModule,
    NgxFlickingModule,
    CampaignCardModule,
  ],
  exports: [CampaignSelectorComponent],
})
export class CampaignSelectorModule {}
