// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stage: 'local',
  domain: 'rg.local',
  logo: 'https://s3-eu-west-1.amazonaws.com/ngx-gb/site/logo-large.png',
  app_id: 2,
  stripe: {
    pk: 'pk_test_Ij5gXIIt3n7DTi5GKwkyIDE4',
  },
  facebookAppId: '209752022926766',
  googleLoginProvider: '84140297884-9p5oq1ofjv0lllolv7u2nqfrjup3feo6.apps.googleusercontent.com',
  path: 'localhost:4200',
  // apiUrl: " https://ramadhangiving-api.herokuapp.com/v1",
  // apiUrl: "https://ramadhangivingapi-staging.herokuapp.com/v1",
  apiUrl: 'https://rg-client-testing.herokuapp.com/v1',
  // apiUrl: "https://nestdev.herokuapp.com/v1",
  fileStackApiKey: 'ATwlgNAAsQsyfsNEw94Cgz',
  environment: 'localhost',
  googleTagManagerId: 'GTM-NPBLPL4',
  endpoint: 'https://rg-client-testing.herokuapp.com/v1',
  endpoints: {
    campaign: 'https://rg-client-testing.herokuapp.com/v1',
    donation: 'https://rg-client-testing.herokuapp.com/v1',
    charity: 'https://rg-client-testing.herokuapp.com/v1',
    auth: 'https://authentication-testing-v2.herokuapp.com/v1',
    home: 'https://authentication-testing-v2.herokuapp.com/v1',
    dashboard: 'https://authentication-testing-v2.herokuapp.com/v1',
  },
  app: {
    url: 'http://rg.local:4200',
  },
  apps: {
    authentication: {
      url: 'http://auth.givebrite.local:2000',
    },
    donate: {
      url: 'http://donate.givebrite.local:2001',
    },
    home: {
      url: 'http://beta.givebrite.local:1000',
    },
    dashboard: {
      url: 'http://dashboard.givebrite.local:2002',
    },
  },
  addressLookup: {
    key: 'mejrvVU6PEuT-Smpo7Xr6w30268',
  },
  assetPath: 'assets',
  pixelId: '225434343113453',
  x_tenant_id: 'ramadhan-giving',
  charitySignup: 'https://dashboard.givebrite.com/onboarding/signup/register',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
