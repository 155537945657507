<!-- <div
  fxLayout="column"
  (click)="itemClicked()"
  fxLayoutAlign="center center"
  [ngClass]="{ first: position == 1, _odd: position % 2 }"
  class="leaderboard-item px-3"
>
  <givebrite-logo-card [logo]="_avatar"></givebrite-logo-card>
  <div fxFlex fxLayout="column" class="mx-2">
    <h3>{{ text | truncate: (!(isDesktop$ | async) ? 30 : 100) | titlecase }}</h3>
    <div *ngIf="description" class="text-xs" [innerHTML]="removeHTML(description)"></div>
    <progress-bar class="progress" *ngIf="count >= 0" [raised]="count"></progress-bar>
    <div *ngIf="count == undefined" class="sm:hidden position">{{ raised | currency: currency:"symbol" }}</div>
  </div>
  <div *ngIf="raised" class="hidden sm:flex position" fxFlex="100px">{{ raised | currency: currency:"symbol" }}</div>
</div> -->

<div
  (click)="itemClicked()"
  [ngClass]="{ first: position == 1, _odd: position % 2 }"
  class="leaderboard-item w-full"
  fxLayout="column"
>
  <mat-card appearance="outlined" class="leaderboard-item-card" gdColumn="1fr 4fr">
    <!-- <div fxFlex="20px" class="hidden sm:flex position mr-2">{{ position }}</div> -->
    <!-- <div class="w-full"> -->
    <div
      class="avatar-container mat-elevation-z2 my-2"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div *ngIf="!loaded" class="spinner-wrapper px-3">
        <mat-progress-spinner
          diameter="15"
          mode="indeterminate"
          color="primary"
        ></mat-progress-spinner>
      </div>
      <a [class.hidden]="!loaded"
        ><img
          (load)="loaded = true"
          class="charity-avatar"
          responsive
          height="50"
          fit="max"
          [src]="_avatar"
      /></a>
    </div>

    <div [class.sm:mb-0]="!count">
      {{ text | truncate: (!(isDesktop$ | async) ? 30 : 100) | titlecase }}
    </div>
    <!-- <mat-card-subtitle>
        <div *ngIf="description" class="text-xs" [innerHTML]="removeHTML(description)"></div>
        <progress-bar *ngIf="count >= 0" [raised]="count"></progress-bar>
        <div *ngIf="count == undefined" class="sm:hidden position">{{ raised | currency: currency:"symbol" }}</div>
      </mat-card-subtitle> -->
    <!-- </div> -->
    <div class="hidden sm:flex position" fxFlex="100px">
      {{ raised | currency: currency:'symbol' }}
    </div>
  </mat-card>
</div>
