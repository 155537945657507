<!-- <ngx-slick-carousel fxLayoutAlign="center center" fxLayout="row" #slickModal="slick-carousel" [config]="slideConfig"> -->

<ng-container *ngIf="partners?.length">
  <h2 class="py-5 mb-0 text-center font-bold" id="giveto4">Give to 4</h2>

  <div gdGap="15px" gdColumns="1fr 1fr" class="mb-4">
    <div
      class="partner-container mat-elevation-z2 text-center p-5 mb-3"
      [style.background-color]="charity?.logo?.white ? charity?.theme?.primary : 'white'"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngFor="let charity of partners"
    >
      <div class="logo-container">
        <!-- <h1 class="mb-2">Give to {{ charity?.partners?.length }} Charities in one go</h1> -->
        <div
          fxLayout="row wrap"
          fxLayoutAlign="space-between center"
          gdGap="16px"
          gdColumns="1fr 1fr"
        >
          <givebrite-charity-logo
            class="mb-2"
            size="50px"
            *ngFor="let charity of charity?.partners"
            [charity]="charity"
          ></givebrite-charity-logo>
        </div>
      </div>

      <button
        routerLink="/{{ charity?.slug }}"
        class="mt-2"
        queryParamsHandling="merge"
        mat-stroked-button
      >
        Give Now
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="charities?.length">
  <h2 class="py-5 mb-0 text-center font-bold" id="charities">Solo Charities</h2>

  <div gdGap="15px" gdColumns="1fr 1fr">
    <givebrite-charity-showcase-item
      *ngFor="let charity of charities"
      [charity]="charity"
    ></givebrite-charity-showcase-item>
  </div>
</ng-container>

<!-- <div gdGap="16px" gdColumns="auto">
  <givebrite-charity-showcase-item
    [charity]="charity"
    *ngFor="let charity of charities.slice(0, 2)"
  ></givebrite-charity-showcase-item>
</div>

<div gdGap="16px" gdColumns="1fr 1fr" class="mt-4">
  <givebrite-charity-showcase-item
    [charity]="charity"
    *ngFor="let charity of charities.slice(2)"
  ></givebrite-charity-showcase-item>
</div> -->

<!--  -->
<!-- </ngx-slick-carousel> -->

<!-- {{ charities | json }} -->
