export class GetCampaignTeams {
  static readonly type = '[Campaigns] Get Campaign Teams';
  constructor(public campaignId: string = '7868768dasdgad', public limit: number = 10) {}
}

export class GetCampaignTeamSingle {
  static readonly type = '[Campaigns] Get Campaign Teams Single';
  constructor(public slug: string, public id: any = 'slug') {}
}
export class CreateTeam {
  static readonly type = '[Campaigns] Create Team';
  constructor(public payload: any) {}
}

export class GetTeamMembers {
  static readonly type = '[Campaigns] Get Team Members';
  constructor(public teamId: string) {}
}

export class GetTeamLeaderboard {
  static readonly type = '[Campaigns] Get Team Leaderboard';
  constructor(public teamId: string) {}
}

export class CreateInvite {
  static readonly type = '[Campaigns] Create Invite';
  constructor(public teamId: string, public payload: any) {}
}

export class JoinTeam {
  static readonly type = '[Campaigns] Join Team';
  constructor(public teamId: string) {}
}

export class UpdateTeam {
  static readonly type = '[Team] Update Team';
  constructor(public payload) {}
}

export class GetTeamDonations {
  static readonly type = '[Campaigns] Get Team Donations';
  constructor(
    public teamId: string,
    public limit: number,
    public page: number,
    public from_date?,
    public to_date?
  ) {}
}
