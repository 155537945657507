<div class="fundraise-options-dialog">
  <givebrite-close-icon
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="end"
    (onClose)="closeDialog()"
  ></givebrite-close-icon>

  <mat-dialog-content>
    <h1>Fundraise for this campaign</h1>
    <p class="mt-4">
      Challenge your peers and help your favorite charities and fundraisers raise much needed funds
      for those in need
    </p>

    <div class="w-full mt-8" fxLayout="column" fxLayoutAlign="center">
      <div
        *ngFor="let item of fundraiseOptions; last as last"
        fxFlex="100%"
        class="mb-4"
        [hidden]="last && !(teamsPagination$ | async)?.total"
      >
        <a (click)="navigate(item.linkName)">
          <div
            class="fundraise-options-dialog-body p-6 cursor-pointer"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon>{{ item.icon }}</mat-icon>
              </div>
              <h4 class="text-lg ml-4">{{ item.name }}</h4>
            </div>

            <mat-icon class="navigate">keyboard_backspace</mat-icon>
          </div>
        </a>
      </div>
    </div>
  </mat-dialog-content>
</div>
