import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Cart, CartItem } from './../cart.interface';
import {
  GetCart,
  AddCartItem,
  RemoveCartItem,
  ClearCart,
  UpdateCartItem,
  UpdateCartTotal,
  UpdateCart,
  LoadCart,
  CartCheckout,
} from './cart.action';
//import { DonationService } from "../donation.service";
import { tap, map } from 'rxjs/operators';
import { asapScheduler, Scheduler, Observable } from 'rxjs';
import { patch, append, insertItem, updateItem, iif, removeItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { CartService } from '../cart.service';

export class CartStateModel {
  items: CartItem[];
  loading?: boolean;
  tip: number;
  service: number;
  total: number;
  subtotal: number;
  charity: string;
  currency: string;
  donation?: any;
}

@Injectable()
@State<CartStateModel>({
  name: 'cart',
  defaults: {
    items: [],
    loading: false,
    service: 0,
    tip: 0,
    total: 0,
    subtotal: 0,
    currency: 'GBP',
    charity: null,
    donation: null,
  },
})
export class CartState {
  constructor(private cartService: CartService) {}

  @Selector()
  static getCart(state: CartStateModel) {
    return state;
  }

  @Selector()
  static donation(state: CartStateModel) {
    return state.donation;
  }

  @Selector()
  static getCurrency(state: CartStateModel) {
    return state.currency;
  }

  @Selector()
  static getQuantity(state: CartStateModel): number {
    return +state.items.reduce((total, item) => total + item.qty, 0);
  }

  @Selector()
  static cartTotal(state: CartStateModel) {
    const { items } = state;

    return items.reduce((total, item) => total + item.amount * item.qty, 0);
  }

  @Selector()
  static getItems(state: CartStateModel) {
    return state.items;
  }

  @Selector()
  static loading(state: CartStateModel) {
    return state.loading;
  }

  @Action(AddCartItem)
  addCartItem(
    { setState, patchState, dispatch, getState }: StateContext<CartStateModel>,
    { item }
  ) {
    // Check if atleast one of the items in basket returns an existing cartItem
    if (
      getState().items.some((cartItem) => cartItem._id === item._id && cartItem.size == item.size)
    ) {
      // Loop through exsting items and update the qty
      getState().items.forEach((cartItem) => {
        if (cartItem._id === item._id && cartItem.size == item.size) {
          const updatedCartItem = {
            ...cartItem,
            qty: cartItem.qty + 1,
            total_shares: (cartItem.qty + 1) * cartItem.shares,
          };
          dispatch(new UpdateCartItem(updatedCartItem));
        }
      });
    } else {
      console.log(item);
      setState(
        patch({
          items: insertItem(item),
        })
      );
    }
    dispatch(new UpdateCartTotal());
  }

  @Action(LoadCart)
  loadTarget({ setState, patchState, dispatch, getState }: StateContext<CartStateModel>, { cart }) {
    // Check if atleast one of the items in basket returns an existing cartItem
    if (cart) {
      patchState({
        items: cart.items,
        subtotal: cart.subtotal,
        total: cart.total,
      });
      // dispatch(new UpdateCartTotal());
    }
  }

  @Action(CartCheckout)
  cartCheckout(
    { setState, patchState, dispatch, getState }: StateContext<CartStateModel>,
    { donation }
  ) {
    // Check if atleast one of the items in basket returns an existing cartItem
    return this.cartService.checkout(donation).pipe(
      tap((result) => {
        patchState({
          donation: result,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateCartTotal)
  updateCartTotal({ patchState, getState }: StateContext<CartStateModel>) {
    // let total = 0;
    const total = getState().items.reduce((total, item) => total + item.amount * item.qty, 0);

    // getState().items.forEach(item => {
    //   total = +item.amount;
    // });
    patchState({
      subtotal: total,
      total: total + getState().tip + getState().service,
    });
  }

  @Action(UpdateCartItem)
  updateCartItem({ setState, dispatch }: StateContext<CartStateModel>, { item }) {
    //patchState({ loading: true });

    setState(
      patch({
        items: updateItem(
          (cartItem) => cartItem._id == item._id && cartItem.size == item.size,
          item
        ),
      })
    );

    dispatch(new UpdateCartTotal());
  }

  @Action(UpdateCart)
  updateCart(ctx: StateContext<CartStateModel>, { cart }: UpdateCart) {
    //patchState({ loading: true });
    let state = ctx.getState();
    ctx.patchState({
      ...cart,
    });
    ctx.dispatch(new UpdateCartTotal());
  }

  @Action(RemoveCartItem)
  removeCartItem(
    { setState, patchState, getState, dispatch }: StateContext<CartStateModel>,
    { item }
  ) {
    patchState({ loading: true });

    if (
      getState().items.some((cartItem) => cartItem._id === item._id && cartItem.size == item.size)
    ) {
      getState().items.forEach((cartItem) => {
        if (cartItem._id === item._id && cartItem.size == item.size) {
          if (cartItem.qty > 1) {
            const updatedCartItem = {
              ...cartItem,
              qty: cartItem.qty - 1,
              total_shares: (cartItem.qty - 1) * cartItem.shares,
            };
            dispatch(new UpdateCartItem(updatedCartItem));
          } else {
            setState(
              patch({
                items: removeItem(
                  (cartItem: any) => cartItem._id == item._id && cartItem.size == item.size
                ),
                // items: removeItem(cartItem => cartItem == item)
              })
            );
          }
        }
      });
    } else {
    }
    dispatch(new UpdateCartTotal());
  }

  @Action(ClearCart)
  clearCart({ setState, patchState }: StateContext<CartStateModel>) {
    setState({
      items: [],
      loading: false,
      tip: 0,
      service: 0,
      subtotal: 0,
      total: 0,
      currency: 'GBP',
      charity: null,
    });
  }
}

function insertOrUpdate(cartItem: CartItem) {
  return iif<CartItem[]>(
    (items) => {
      console.log(items);
      return items.some((item) => item._id === cartItem._id);
    },
    updateItem<CartItem>((item) => item._id === cartItem._id, patch(cartItem)),
    insertItem<CartItem>(cartItem)
  );
}
