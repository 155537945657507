<div class="columns-dialog">
    <h1 class="title" mat-dialog-title>Update subscription</h1>
    <display-message *ngIf="errors$ | async" type="error">
        <strong>Card declined! </strong>
        {{ (errors$ | async)?.message }}
    </display-message>

    <div mat-dialog-content class="mt-6 bg-gray-100 -mx-8 px-3" >
        <list-payment-methods 
            (added)="paymentAdded($event)" 
            (updated)="paymentUpdated($event)" 
            [default]="subscription.default_payment_method?.payment_method_id" 
            [id]="subscription.id" 
            object="subscription">
        </list-payment-methods>
    </div>

    <mat-dialog-actions align="end">
        <div class="text-right">
            <button color="warn" mat-dialog-close [disabled]="loading$ | async" mat-raised-button class="mr-2">Cancel</button>
            <button [disabled]="(loading$ | async) ||  !default_payment_method || default_payment_method == subscription.default_payment_method?.id" color="primary" mat-raised-button (click)="update()">Update Default Payment</button>
        </div>
    </mat-dialog-actions>

</div>