import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxStripeModule } from 'ngx-stripe';
import { ViewCardModule } from '../view-templates/view-card/view-card.module';
import { StripeCardElement } from './card/card.component';

@NgModule({
  declarations: [StripeCardElement],
  imports: [
    CommonModule,
    NgxStripeModule,
    MatInputModule,
    FormsModule,
    FlexLayoutModule,
    ViewCardModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [StripeCardElement],
})
export class StripeModule {}
