import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoUploaderComponent } from './video-uploader.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
  declarations: [VideoUploaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    YouTubePlayerModule,
  ],
  exports: [VideoUploaderComponent],
})
export class VideoUploaderModule {}
