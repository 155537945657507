import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { UserLogoAvatarModule } from '../../user/user-logo-avatar/user-logo-avatar.module';
import { InviteCoverModule } from './invite-cover/invite-cover.module';
import { InviteDetailsModule } from './invite-details/invite-details.module';
import { InviteTeamRoutingModule } from './invite-team-routing.module';
import { InviteTeamComponent } from './invite-team.component';

@NgModule({
  declarations: [InviteTeamComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    InviteTeamRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatButtonModule,
    UserLogoAvatarModule,
    InviteCoverModule,
    InviteDetailsModule,
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: ToastrService, useClass: ToastrService }],
  exports: [InviteTeamComponent],
})
export class InviteTeamModule {}
