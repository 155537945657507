import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
// import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

// import {
//   AbstractControl,
//   ControlValueAccessor,
//   NG_VALIDATORS,
//   NG_VALUE_ACCESSOR,
//   Validator,
//   ValidationErrors,
// } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
// import {
//   UploadOutput,
//   UploadInput,
//   UploadFile,
//   humanizeBytes,
//   UploaderOptions,
// } from 'ngx-uploader';
// import { Store } from '@ngxs/store';
// import {
//   AuthService,
//   UploadedFile,
//   UploadSingleFile,
//   UpdateSingleFile,
//   UploadUserMedia,
// } from '@givebrite/data';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from 'apps/campaigns/src/environments/environment';
// import { CropperPosition, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
// import { MatDialog } from '@angular/material/dialog';
// import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
// import { timeStamp } from 'node:console';
// import { MediaUploaderComponent } from './media-uploader/media-uploader.component';

// interface UploadFileWithPreview extends UploadFile {
//   imagePreview?: string;
// }

// let apiLoaded = false;
@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-cover-uploader',
  templateUrl: './campaign-cover-uploader.component.html',
  styleUrls: ['./campaign-cover-uploader.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     multi: true,
  //     useExisting: CampaignCoverUploaderComponent,
  //   },
  //   {
  //     provide: NG_VALIDATORS,
  //     multi: true,
  //     useExisting: CampaignCoverUploaderComponent,
  //   },
  // ],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignCoverUploaderComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  // @Output() images: EventEmitter<any> = new EventEmitter<any>();
  // @Input() image;
  // @Input() charity;
  // @Input() carousel: boolean = true;
  // @Input() type: string = 'Campaign';
  // files: {
  //   id: string;
  //   type: string;
  //   thumbnail: string;
  //   image?: {
  //     cropped: UploadedFile;
  //     original: UploadedFile;
  //     cropperPosition: CropperPosition;
  //   };
  //   video?: {};
  // }[] = [];
  // index: number = 0;
  // maxIndex: number;
  // cover: Array<any> = [];
  // imageList: Array<any> = [];
  // file;
  // imageChangedEvent: any = '';
  // originalFile: any = '';
  // croppedImage: any = '';
  // cropperPosition;
  // // NGXS FORM
  // onChange = (cover: Array<any>) => {};
  // onTouched = () => {};
  // touched = false;
  // disabled = false;
  // constructor(
  //   private store: Store,
  //   private auth: AuthService,
  //   private dialog: MatDialog
  // ) {
  //   this.options = { concurrency: 100, maxUploads: 9999, maxFileSize: 1000000 };
  //   this.files = []; // local uploading files array
  //   this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  //   this.humanizeBytes = humanizeBytes;
  //   if (!apiLoaded) {
  //     // This code loads the IFrame Player API code asynchronously, according to the instructions at
  //     // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     document.body.appendChild(tag);
  //     apiLoaded = true;
  //   }
  // }
  // ngOnInit(): void {}
  // writeValue(files: Array<any>) {
  //   // this.cover = cover;
  //   // if (this.cover) {
  //   //   this.files = this.imageList = [...this.cover];
  //   //   const ids = this.cover.map((item) => item.id);
  //   //   this.maxIndex = ids ? Math.max(...ids) : 0;
  //   // }
  //   // console.log(files);
  //   if (files) {
  //     this.files = files;
  //   }
  // }
  // registerOnChange(onChange: any) {
  //   this.onChange = onChange;
  // }
  // registerOnTouched(onTouched: any) {
  //   this.onTouched = onTouched;
  // }
  // validate(control: AbstractControl): ValidationErrors | null {
  //   return null;
  //   // if (cover?.length <= 0) {
  //   //   return {
  //   //     mustNotBeEmpty: {
  //   //       cover,
  //   //     },
  //   //   };
  //   // }
  // }
  // options: UploaderOptions;
  // formData: FormData;
  // _files: UploadFileWithPreview[] = [];
  // uploadInput: EventEmitter<UploadInput>;
  // humanizeBytes: Function;
  // dragOver: boolean;
  // onUploadOutput(output: UploadOutput): void {
  //   console.log(output);
  //   this._files = [];
  //   switch (output.type) {
  //     case 'allAddedToQueue':
  //       // uncomment this if you want to auto upload files when added
  //       // const event: UploadInput = {
  //       //   type: 'uploadAll',
  //       //   url: '/upload',
  //       //   method: 'POST',
  //       //   data: { foo: 'bar' }
  //       // };
  //       // this.uploadInput.emit(event);
  //       // const event: UploadInput = {
  //       //   type: 'uploadAll',
  //       //   url: `${environment.endpoints.charity}/charity/documents/upload?charity_id=${this.charity}`,
  //       //   method: 'POST',
  //       //   file: output.file,
  //       //   headers: { Authorization: `Bearer ${this.auth.jwt}` },
  //       // };
  //       // this.uploadInput.emit(event);
  //       break;
  //     case 'addedToQueue':
  //       if (typeof output.file !== 'undefined') {
  //         // this.http
  //         //   .post(
  //         //     `${environment.endpoints.charity}/charity/documents/upload?charity_id=${this.charity}`,
  //         //     { file: output.file.nativeFile }
  //         //   )
  //         //   .subscribe((re) => {
  //         //     console.log(re);
  //         //   });
  //         // this.store.dispatch(
  //         //   new UploadSingleFile({ file: output.file.nativeFile }, this.charity)
  //         // );
  //         this.previewImage(output.file).then((response) => {
  //           let file: any = Object.assign(output.file, {
  //             imagePreview: response,
  //           });
  //           // this._files.push(file);
  //           this.file = file;
  //         });
  //         console.log(this.file);
  //         // this._files.push(output.file);
  //       }
  //       break;
  //     case 'uploading':
  //       if (typeof output.file !== 'undefined') {
  //         // update current data in files array for uploading file
  //         // const index = this._files.findIndex(
  //         //   (file) =>
  //         //     typeof output.file !== 'undefined' && file.id === output.file.id
  //         // );
  //         // this._files[index] = output.file;
  //         this.file = output.file;
  //       }
  //       break;
  //     case 'removed':
  //       // remove file from array when removed
  //       const removeItem = (items, i) =>
  //         items.slice(0, i - 1).concat(items.slice(i, items.length));
  //       this.files = removeItem(this.files, output);
  //       break;
  //     case 'dragOver':
  //       this.dragOver = true;
  //       break;
  //     case 'dragOut':
  //     case 'drop':
  //       this.dragOver = false;
  //       break;
  //     case 'done':
  //       // The file is downloaded
  //       // this.onChange(output.file.response.url);
  //       // this.images.emit(['']);
  //       this.files = [...this.files, output.file.response.url];
  //       this.file = undefined;
  //       this.onChange(this.files);
  //       break;
  //   }
  // }
  // _activeFile = 0;
  // get activeFile(): { type: string } {
  //   return this.files[this._activeFile];
  // }
  // previewImage(file: UploadFile) {
  //   const fileReader = new FileReader();
  //   return new Promise((resolve) => {
  //     fileReader.readAsDataURL(file.nativeFile);
  //     fileReader.onload = function (e: any) {
  //       resolve(e.target.result);
  //     };
  //   });
  // }
  // cancelUpload(id: string): void {
  //   this.uploadInput.emit({ type: 'cancel', id: id });
  // }
  // removeFile(index): void {
  //   this.uploadInput.emit({ type: 'remove', id: index });
  //   this.file = undefined;
  // }
  // removeAllFiles(): void {
  //   this.uploadInput.emit({ type: 'removeAll' });
  // }
  // fileChangeEvent(event: any): void {
  //   if (event.target.files.length) {
  //     this.originalFile = event.target.files[0];
  //     this.imageChangedEvent = event;
  //     this.openCropper();
  //   }
  // }
  // openCropper(file?) {
  //   this.dialog
  //     .open(ImageCropperDialogComponent, {
  //       data: {
  //         imageChangedEvent: this.imageChangedEvent,
  //         file: file,
  //         dialog: true,
  //       },
  //       width: '50%',
  //     })
  //     .afterClosed()
  //     .subscribe((response) => {
  //       // Campaign from old site, requires re-uploading as old proportions
  //       if (file.is_old) {
  //         console.log(response);
  //         let actions = new UploadSingleFile(
  //           this.DataToFormData(response.croppedImage.base64, true),
  //           this.charity
  //         );
  //         if (!this.auth.hasCharity) {
  //           actions = new UploadUserMedia(
  //             response.croppedImage.base64,
  //             this.charity
  //           );
  //         }
  //         this.store.dispatch(actions).subscribe((store) => {
  //           let cropped = null;
  //           let original = null;
  //           if (this.auth.hasCharity) {
  //             cropped = store.charity.file;
  //           } else {
  //             cropped = store.account.auth.file;
  //           }
  //           const updatedFile = {
  //             ...file,
  //             thumbnail: cropped.url,
  //             image: {
  //               ...file.image,
  //               cropped: cropped,
  //               cropperPosition: response.croppedImage.cropperPosition,
  //             },
  //           };
  //           console.log(updatedFile);
  //           var foundIndex = this.files.findIndex((x) => x.id == file.id);
  //           const newFiles = [...this.files];
  //           newFiles[foundIndex] = updatedFile;
  //           this.files = newFiles;
  //           this.file = undefined;
  //           this.croppedImage = undefined;
  //           this.onChange(this.files);
  //         });
  //         return;
  //       }
  //       if (response) {
  //         this.cropperPosition = response.croppedImage.cropperPosition;
  //         this.croppedImage = response.croppedImage.base64;
  //         if (response.file) {
  //           // Update
  //           this.uploadUpdatedCroppedImage(response.file);
  //         } else {
  //           // Create
  //           // this.uploadImages();
  //         }
  //       } else {
  //         this.file = undefined;
  //       }
  //     });
  // }
  // uploadUpdatedCroppedImage(file) {
  //   const croppedFormData = this.DataToFormData(this.croppedImage, true);
  //   this.store
  //     .dispatch(
  //       new UpdateSingleFile(
  //         croppedFormData,
  //         this.charity,
  //         file.image.cropped._id
  //       )
  //     )
  //     .subscribe((store) => {
  //       const cropped = store.charity.file;
  //       const updatedFile = {
  //         ...file,
  //         thumbnail: cropped.url,
  //         image: {
  //           ...file.image,
  //           cropped: cropped,
  //           cropperPosition: this.cropperPosition,
  //         },
  //       };
  //       console.log(file);
  //       var foundIndex = this.files.findIndex((x) => x.id == file.id);
  //       const newFiles = [...this.files];
  //       newFiles[foundIndex] = updatedFile;
  //       this.files = newFiles;
  //       this.file = undefined;
  //       this.croppedImage = undefined;
  //       this.onChange(this.files);
  //     });
  // }
  // uploading: boolean;
  // uploadImages(image) {
  //   this.uploading = true;
  //   // const croppedFormData = this.DataToFormData(this.croppedImage, true);
  //   // const originalFormData = this.DataToFormData(this.originalFile);
  //   let actions = [
  //     new UploadSingleFile(image.cropped.formData, this.charity),
  //     new UploadSingleFile(image.original.formData, this.charity),
  //   ];
  //   if (!this.auth.hasCharity) {
  //     actions = [
  //       new UploadUserMedia(image.cropped.formData, this.charity),
  //       new UploadUserMedia(image.original.formData, this.charity),
  //     ];
  //   }
  //   this.store.dispatch(actions).subscribe(
  //     (store) => {
  //       let cropped = null;
  //       let original = null;
  //       if (this.auth.hasCharity) {
  //         cropped = store[0].charity.file;
  //         original = store[1].charity.file;
  //       } else {
  //         cropped = store[0].account.auth.file;
  //         original = store[1].account.auth.file;
  //       }
  //       this.setFile(
  //         original,
  //         { ...cropped, url: cropped.url + '?ts=reload' },
  //         image.cropperPosition
  //       );
  //       this.file = undefined;
  //       this.croppedImage = undefined;
  //       this.uploading = false;
  //       this.onChange(this.files);
  //     },
  //     (error) => {
  //       this.file = undefined;
  //       this.croppedImage = undefined;
  //       this.uploading = false;
  //     }
  //   );
  // }
  // setFile(original, cropped, cropperPosition) {
  //   this.files = [
  //     ...this.files,
  //     {
  //       id: original._id,
  //       type: 'image',
  //       thumbnail: cropped.url,
  //       image: {
  //         original: original,
  //         cropped: cropped,
  //         cropperPosition: cropperPosition,
  //       },
  //     },
  //   ];
  //   this._activeFile = this.files.length - 1;
  // }
  // remove(i) {
  //   if (i == this._activeFile && i > 0) this._activeFile -= 1;
  //   const files = [...this.files];
  //   files.splice(i, 1);
  //   this.files = files;
  //   this.onChange(this.files);
  // }
  // DataToFormData(dataURI: string, isBase64: boolean = false) {
  //   const file = isBase64 ? this.DataURIToBlob(dataURI) : dataURI;
  //   let formData: FormData = new FormData();
  //   formData.append('file', file, 'file.png');
  //   return formData;
  // }
  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',');
  //   const byteString =
  //     splitDataURI[0].indexOf('base64') >= 0
  //       ? atob(splitDataURI[1])
  //       : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i);
  //   return new Blob([ia], { type: mimeString });
  // }
  // openMediaUpload() {
  //   this.dialog
  //     .open(MediaUploaderComponent, {
  //       // width: '70%',
  //       panelClass: 'fullscreen-panel',
  //     })
  //     .afterClosed()
  //     .subscribe((media) => {
  //       console.log(media);
  //       if (media) {
  //         if (media.type == 'image') {
  //           this.file = media.file;
  //           this.uploadImages(media);
  //         } else if (media.type == 'video') {
  //           this.files = [
  //             ...this.files,
  //             {
  //               id: media.video.id,
  //               thumbnail: media.video.thumbnail,
  //               type: 'video',
  //               video: media.video,
  //             },
  //           ];
  //           this._activeFile = this.files.length - 1;
  //           this.onChange(this.files);
  //         }
  //       } else {
  //         console.log(media);
  //       }
  //     });
  // }
}
