import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';

@Component({
  selector: 'givebrite-campaign-user-dashboard',
  templateUrl: './campaign-user-dashboard.component.html',
  styleUrls: ['./campaign-user-dashboard.component.scss'],
})
export class CampaignUserDashboardComponent implements OnInit {
  // page = this.route.firstChild.data.page;
  page;
  navlink: any = [
    {
      link: 'fundraisers',
      caption: 'Fundraisers',
    },
    {
      link: 'campaigns',
      caption: 'Campaigns',
    },
    {
      link: 'donations',
      caption: 'Donations',
    },
  ];

  constructor(private route: ActivatedRoute, private store: Store) {
    route.url.subscribe(() => {
      this.page = route.snapshot.firstChild.data.page;
    });
    // const charity = !!this.store.selectSnapshot(UserState.charity);
    // if (charity) {
    //   this.navlink.push({
    //     link: 'campaigns',
    //     caption: 'Campaigns',
    //   });
    // }
  }

  get active() {
    return this.route.firstChild.snapshot.url[0].path;
  }

  get currentIndex() {
    return this.navlink.findIndex((step) => step.link == this.active);
  }

  ngOnInit(): void {}
}
