<div class="content top-padding charitys-content" role="main">
  <div class="container px-4 sm:px-8">
    <div class="d-block w-md-75 mx-md-auto charity-section py-4">
      <div class="charity-heading text-center mt-4">
        <h4>Select a charity</h4>
      </div>
      <div class="seacrch-bar mx-md-5 px-md-5 text-center">
        <div class="form-group">
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <input
            matInput
            type="text"
            class="control-form"
            (input)="charitieFilter($event.target)"
            placeholder="Search a charity"
          />
          <span><i class="fas fa-search"></i></span>
        </div>
      </div>

      <div class="charity-boxs mx-md-5 px-md-5">
        <div class="row">
          <div *ngFor="let item of filtercontent; let i = index" class="mb-2">
            <mat-card
              appearance="outlined"
              (click)="onSingleCharity(item?.slug)"
              fxLayout="row"
              fxlayoutAlign="start center"
              class="cursor-pointer p-4"
            >
              <mat-container class="w-full">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="25%" fxLayout="row" fxLayoutAlign="start center">
                    <givebrite-charity-logo
                      [charity]="item"
                      width="70px"
                      fxShow.xs
                      fxHide
                    ></givebrite-charity-logo>

                    <givebrite-charity-logo [charity]="item" fxHide.xs></givebrite-charity-logo>
                  </div>

                  <div class="ml-2" fxFlex="75%">
                    <h2>{{ item.name | titlecase | truncate: 30 }}</h2>
                    <div>
                      <givebrite-progress-bar
                        color="accent"
                        class="text-left"
                        [raised]="item?.donation"
                        [options]="{
                          top: false,
                          bottom: true,
                          type: {
                            thin: false,
                            fat: true
                          }
                        }"
                      ></givebrite-progress-bar>
                    </div>
                  </div>
                </div>
              </mat-container>
            </mat-card>

            <!--  -->
          </div>
        </div>

        <div *ngIf="loading$ | async" error="We couldn't match that charity">
          <mat-card appearance="outlined" class="p-6 mb-2" *ngFor="let card of [].constructor(4)">
            <ngx-skeleton-loader></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '90%' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ width: '100%' }"></ngx-skeleton-loader>
          </mat-card>
        </div>

        <display-message
          *ngIf="!filtercontent.length && !(loading$ | async)"
          error="We couldn't match that charity"
        ></display-message>
      </div>
    </div>
  </div>
</div>
