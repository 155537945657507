import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import ccVisa from '@iconify/icons-logos/visaelectron';
import ccMastercard from '@iconify/icons-logos/mastercard';
import { FormGroup, FormBuilder } from '@angular/forms';
import icEdit from '@iconify/icons-ic/baseline-edit';
import icDelete from '@iconify/icons-ic/delete';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  Address,
  DetachPaymentMethod,
  PaymentMethod,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { UserState } from 'libs/data/src/lib/user/store/user.state';

@Component({
  selector: 'list-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.getPaymentMethods) payment_methods$;
  @Select(UserStateRg.getSetupIntent) intent$;
  @Select(UserStateRg.loading) loading$;
  @Select(UserState.user) user$;

  icons = {
    visa: ccVisa,
    mastercard: ccMastercard,
  };

  icEdit = icEdit;
  icDelete = icDelete;

  @Input() options = {
    addCard: false,
    editCard: false,
    deleteCard: false,
    type: 'flat',
    padding: true,
  };
  @Input() form: FormGroup = this.fb.group({
    id: [],
    payment_method_id: [],
    default: [],
  });
  @Input() id: number;
  @Input() address: Address;
  @Input() object: string;
  @Input() set default(d: string) {
    this.form.get('payment_method_id').setValue(d);
  }
  @Output() updated = new EventEmitter();
  @Output() added = new EventEmitter();

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        // this.store.dispatch(new GetPaymentMethods());
      }
    });
  }

  detach(payment_method) {
    this.store
      .dispatch(new DetachPaymentMethod(payment_method.id))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.form.get('payment_method_id').value == payment_method.payment_method_id) {
          this.default = null;
          this.form.get('payment_method_id').setValue(null);
        }
      });
  }

  paymentUpdated(payment) {
    this.updated.emit(payment);
  }

  paymentAdded(payment_method_id) {
    // console.log(payment_method_id);
    this.payment_methods$
      .pipe(untilDestroyed(this))
      .subscribe((payment_methods: PaymentMethod[]) => {
        const payment = payment_methods.find((payment_method) => {
          return payment_method_id == payment_method.payment_method_id;
        });

        this.added.emit(payment);
      });

    this.form.get('payment_method_id').setValue(payment_method_id);
  }

  get selectedPaymentMethod() {
    return this.form.get('payment_method_id').value;
  }

  brandIcon(brand) {
    return this.icons[brand];
  }

  ngOnDestroy(): void {}
}
