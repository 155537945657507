import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@givebrite/theming';
import { QurbaniGivingLayoutComponent } from './layout/qurbani-giving-layout.component';
import { NotFoundComponent } from './404/not-found.component';
// import { MetaGuard } from "@ngx-meta/core";

const childrenRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

const routes: Routes = [
  { path: '404', component: NotFoundComponent },
  // {
  //   path: "user",
  //   component: QurbaniGivingLayoutComponent,
  //   loadChildren: () => import("@givebrite/core").then(m => m.UserModule)
  // },
  {
    path: '',
    // canActivateChild: [MetaGuard],
    component: QurbaniGivingLayoutComponent,
    children: childrenRoutes,
  },
  // { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      // relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class QurbaniGivingRoutingModule {}
