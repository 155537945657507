<div
  class="headline py-14 relative text-center md:text-left my-0"
  fxLayout="column"
>
  <div class="container">
    <h1 class="headline-title">
      <ng-content select="[headline-title]"></ng-content>
    </h1>
    <h2 class="text-base pb-2.5 sm:text-2xl">
      <ng-content select="[headline-subtitle]"></ng-content>
    </h2>
    <div class="mt-3 pb-12">
      <ng-content select="[headline-footer]"></ng-content>
    </div>
  </div>

  <div class="absolute left-8 bottom-8 md:left-24">
    <img src="assets/images/vector-line.svg" />
  </div>

  <div class="absolute right-8 bottom-8 md:right-24">
    <img src="assets/images/vector-line.svg" />
  </div>
</div>
