<!-- {{ charity?.logo.white | json}} -->
<div
  class="logo-container p-2 mat-elevation-z6"
  [ngStyle]="styling"
  fxLayoutAlign="center center"
  [class.border]="border"
  [style.background-color]="charity?.logo.white ? charity?.theme.primary : 'white'"
>
  <givebrite-logo-card
    [logo]="charity?.logo.white ? charity?.logo.white : charity?.logo.color"
  ></givebrite-logo-card>
  <!-- <img [alt]="'Ramadhan Giving with' + charity?.name" [src]="charity?.logo.white ? charity?.logo.white : charity?.logo.color" class="logo" /> -->
</div>
