import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { CampaignStepperComponent } from './campaign-stepper.component';

@NgModule({
  declarations: [CampaignStepperComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [CampaignStepperComponent],
})
export class CampaignStepperModule {}
