<div class="sticky-wrapper">
  <div class="summary-card text-center relative">
    <div [fxHide]="loading">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.6rem">
        <div class="logo-avaatar-container" fxHide fxShow.xs *ngIf="showLogo">
          <givebrite-user-logo-avatar
            [logo]="logo || defaultLogo"
            [border]="false"
            width="85px"
          ></givebrite-user-logo-avatar>
        </div>

        <h1 *ngIf="name" class="text-center">
          {{ name | titlecase }}
        </h1>

        <h2 *ngIf="raised >= 0 && showRaised" class="raised">
          {{ raised | currency: currency:'symbol' }}
        </h2>

        <div class="w-full">
          <givebrite-progress-bar
            [currency]="currency"
            [raised]="raised"
            [goal]="goal"
            height="6px"
            [options]="{
              amount: raised,
              active: 'font-size: 40px;line-height: 47px;color: #2A3742;font-weight: 600;',
              topLeft:
                'raised of' + ' ' + (goal | currency: currency:'symbol':'1.0-0') + ' ' + 'goals',
              topRight: '',
              bottomLeft: supporters + ' ' + 'supporters',
              bottomRight: ''
            }"
          ></givebrite-progress-bar>
        </div>

        <div class="actions w-full" fxLayout="column">
          <ng-content select="[gb-summary-card-actions]" *ngIf="!team?.can_edit"></ng-content>

          <div fxLayout="column" *ngIf="!hideButtons">
            <a
              *ngIf="showFundraiseButton"
              (click)="fundraise()"
              [disabled]="disabled"
              mat-raised-button
              class="btn-xl mb-2 w-full fundraise"
              >Fundraise for us</a
            >

            <!-- <a
              *ngIf="showSupportButton"
              href="{{ buildUrl }}"
              mat-raised-button
              [disabled]="campaignEnded"
              class="btn-xl donate w-full"
              >Donate</a
            > -->

            <a
              *ngIf="showShareButton"
              mat-raised-button
              color="primary"
              class="btn-xl mt-2 w-full share font-bold"
              (click)="openShareDialog()"
              >Share</a
            >

            <givebrite-donate-button
              class="mt-4"
              *ngIf="showDonateButton"
              [team]="team"
              [campaign]="campaign"
              [fundraiser]="fundraiser"
              [charity]="charity"
              [isEnded]="isEnded"
              [environment]="environment"
              button="raised"
              showIcon="true"
              customClass="font-bold"
            ></givebrite-donate-button>

            <givebrite-donation-info
              infoClass="primary pt-4 font-medium"
              title="100% donation policy"
            ></givebrite-donation-info>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="mt-5" *ngIf="zakat">
          <img src="assets/images/z-sign.svg" class="mr-1" />
          <p>This campaign is zakat eligible</p>
        </div>

        <p class="text-center text-base py-3" *ngIf="start_date && !isEnded">
          Started On {{ start_date | date: 'EE, MMMM d, y' }}
        </p>
      </div>

      <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0.6rem" class="mt-6">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          *ngIf="showCampaignCreaterName"
        >
          <givebrite-user-logo-avatar
            [logo]="campaign?.user?.profile_image"
            [name]="campaign?.user?.first_name + ' ' + campaign?.user?.last_name"
            [border]="false"
            width="48px"
          ></givebrite-user-logo-avatar>

          <div *ngIf="showCampaignCreaterName">
            <p class="campaign-by text-left">Campaign by</p>
            <h4 class="font-semibold">
              {{ campaign?.user?.first_name + ' ' + campaign?.user?.last_name }}
            </h4>
          </div>
        </div>

        <mat-divider *ngIf="showCampaignCreaterName"></mat-divider>

        <!-- TOP DONATIONS -->
        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="donors">
          <img src="assets/trending.svg" width="48px" />

          <h4>{{ donors }} persons have donated on this campaign in the last 24 hours</h4>
        </div>

        <mat-divider *ngIf="donors"></mat-divider> -->

        <givebrite-trending-donations
          [page]="page"
          limit="10"
          [campaignId]="campaign?._id"
          [fundraiserId]="fundraiser?._id"
          [teamId]="team?._id"
        ></givebrite-trending-donations>

        <!-- CTA -->
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="mt-8">
          <a
            mat-stroked-button
            fxLayout="row"
            fxLayoutAlign="center center"
            color="primary"
            class="btn-md font-medium"
            fxFlex="30%"
            (click)="openDonationListDialog(page)"
            >See all</a
          >

          <a
            mat-stroked-button
            fxLayout="row"
            fxLayoutAlign="center center"
            color="secondary"
            class="btn-md fundraiser-cta font-medium"
            fxFlex="70%"
            (click)="fundraise()"
          >
            <mat-icon class="mr-1">stars</mat-icon>
            Fundraise for us</a
          >
        </div>
      </div>
    </div>

    <div [class.hidden]="!loading">
      <ngx-skeleton-loader
        animation="progress-dark"
        [theme]="{
          width: '100%',
          height: '15px',
          clear: 'left',
          'border-radius': '50px'
        }"
      ></ngx-skeleton-loader>

      <ngx-skeleton-loader
        [theme]="{
          width: '100%',
          height: '15px',
          clear: 'left',
          'border-radius': '50px'
        }"
        animation="progress-dark"
      ></ngx-skeleton-loader>

      <ngx-skeleton-loader
        animation="progress-dark"
        [theme]="{
          width: '100%',
          height: '60px',
          'border-radius': '50px',
          'margin-bottom': '5px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        animation="progress-dark"
        [theme]="{
          width: '100%',
          height: '60px',
          'border-radius': '50px',
          margin: 0
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <givebrite-campaign-fundraiser-card
    [fxHide]="!showSummaryCard"
    class="block"
  ></givebrite-campaign-fundraiser-card>
</div>
