import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import {
  CampaignRg,
  CampaignStateRg,
  CharityRg,
  CharityStateRg,
  Subscription,
  SubscriptionState,
} from 'libs/data/src/lib/ramadhan-giving';
import { Observable } from 'rxjs';

@Component({
  selector: 'leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit, OnDestroy {
  @Select(CharityStateRg.getCharities) charities$: Observable<CharityRg[]>;
  @Select(CampaignStateRg.getCampaigns) campaigns$: Observable<CampaignRg[]>;
  @Select(SubscriptionState.getSubscriptionList) subscriptions$: Observable<Subscription[]>;
  @Output() clicked: EventEmitter<CharityRg> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  itemClicked(charity: CharityRg) {
    this.clicked.emit(charity);
  }
}
