<div class="campaign-appeal-selector">
  <h4 class="text-center mb-5 font-semibold">Select a appeal</h4>

  <form class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Select a appeal</mat-label>
      <mat-select
        placeholder="select a appeal"
        [(ngModel)]="selectedAppealId"
        name="appeal"
        required
      >
        <mat-option [value]="appeal._id" *ngFor="let appeal of appeals; let i = index">{{
          appeal.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
