<div class="theme-colors">
  <div fxLayout="row" fxLayout.xs="column">
    <p class="mb-3 sm:mb-0" *ngIf="!team">Color scheme</p>

    <div
      class="cursor-pointer sm:w-4/6"
      fxLayout="row"
      fxLayoutAlign="start"
      fxLayoutGap="20px"
      [ngStyle]="{ 'margin-left': team ? ' 34px' : 'auto' }"
    >
      <div *ngFor="let color of themes; let i = index" class="relative">
        <div (click)="selectedColor(color.theme)">
          <div
            class="color"
            [ngClass]="color.theme"
            [class.active]="color.theme == theme"
          ></div>
        </div>
        <mat-icon color="primary" class="checkicon" *ngIf="theme == color.theme"
          >done</mat-icon
        >
      </div>
    </div>
  </div>
</div>
