import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidationErrors,
} from '@angular/forms';

enum CharityTypes {
  OWN = 'ownCampaign',
  EXISTING = 'existingCampaign',
  createTeam = 'createTeam',
  existingTeam = 'existingTeam',
}

let charityType;
@Component({
  selector: 'givebrite-campaign-type',
  templateUrl: './campaign-type.component.html',
  styleUrls: ['./campaign-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignTypeComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignTypeComponent,
    },
  ],
})
export class CampaignTypeComponent implements ControlValueAccessor, Validator, OnInit {
  @Output() selected = new EventEmitter();
  type: string;

  types: Array<{ id: number; text: string; checked: boolean; type: string }> = [
    { id: 1, text: 'Leaderboard Campaign', checked: false, type: 'p2p' },
    {
      id: 2,
      text: 'Standalone Campaign',
      checked: false,
      type: 'standalone',
    },
    // { id: 3, text: 'Create a Team', checked: false, type: 'createTeam' },
    // { id: 4, text: 'Join Existing Team', checked: false, type: 'existingTeam' },
  ];

  onChange = (type: any) => {};

  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: any) {
    this.type = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const type = control.value;

    if (type === '') {
      return {
        mustNotBeEmpty: {
          type,
        },
      };
    }
  }

  get charityType() {
    return charityType;
  }

  get CharityTypes() {
    return CharityTypes;
  }

  /**
   * @description
   * SELECT CHAIRTY
   * @param index index
   */
  selectedType(index) {
    this.types.forEach((item, idx) => {
      item.checked = idx == index;
      if (item.checked) {
        this.type = item.type;
        this.onChange(this.type);
      }
    });
  }
}
