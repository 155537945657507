import { ApiService } from '@givebrite/api';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SktService {
  constructor(private http: ApiService) {}

  appeal(id, type: string) {
    return this.http.get(`appeal/${id}?type=${type}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  appeals(filter_by?, value?, is_featured?) {
    return this.http
      .get(`appeal?filter_by=${filter_by}&value=${value}${`&is_featured=${is_featured ? 1 : 0}`}`)
      .pipe(
        map((response) => {
          return response.docs;
        })
      );
  }

  project(id: string, type: string) {
    return this.http.get(`project/${id}?type=${type}`);
  }

  projects(is_featured?) {
    return this.http.get(`project${is_featured ? `?is_featured=${is_featured}` : ''}`).pipe(
      map((response) => {
        return response.docs;
      })
    );
  }

  getMerchantSessionKey() {
    return this.http.get(`donation/merchantSessionKey`).pipe(map((response) => response));
  }

  sagePayDonation(payload) {
    return this.http.post(`donation/sagepay`, payload);
  }

  sagePay3dDonation(payload) {
    return this.http.post(`donation/sagepay3ds`, payload);
  }

  getVoluntaryOptions(type) {
    return this.http.get(`donation/options?type=${type}`).pipe(map((response) => response));
  }
}
