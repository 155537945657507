<div class="fundraisers-card relative">
  <mat-card appearance="outlined" fxLayout="column" fxLayoutAlign="center center">
    <div class="profile relative">
      <givebrite-logo-card
      [logo]="fundraiser.user.profile_image"
      ></givebrite-logo-card>
      <mat-icon class="done-icon">done</mat-icon>
    </div>
   <h3 class="pt-8">{{fundraiser.user.first_name}} {{fundraiser.user.last_name}}</h3>
  </mat-card>
  <div class="star" *ngIf="index==0">
    <mat-icon>star</mat-icon> Top Fundraiser
  </div>   
  <div class="star" *ngIf="index==1">
    <mat-icon>star_outline</mat-icon> Raising Star
  </div>   
  </div>
  