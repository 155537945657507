import { Pipe, PipeTransform } from '@angular/core';
/*
 * Convert an amount into a tip amount based on percentage
 */
@Pipe({ name: 'tip' })
export class TipPipe implements PipeTransform {
  transform(amount: number, percentage): number {
    return (amount * percentage) / 100;
  }
}
