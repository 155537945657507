import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharityLogoAvatarComponent } from './charity-logo-avatar.component';
import { LogoCardModule } from './../../../../../theming/src/lib/components/logo-card/logo-card.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CharityLogoAvatarComponent],
  imports: [CommonModule, LogoCardModule, FlexLayoutModule],
  exports: [CharityLogoAvatarComponent],
})
export class CharityLogoAvatarModule {}
