import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'givebrite-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverviewComponent implements OnInit {
  @Input() set description(description) {
    if (description) {
      this._description = description;
      this.isCollapsed = description.length > 200;
    }
  }

  _description: string;
  isCollapsed: boolean;

  constructor() {}

  ngOnInit(): void {}
}
