import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'givebrite-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Output() onSearchResult: EventEmitter<string> = new EventEmitter<string>();
  @Input() search = '';

  constructor() {}

  ngOnInit(): void {}

  searchResult(search) {
    console.log(search);
  }
}
