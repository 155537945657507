<div class="most-donated-causes mt-6 mb-10">
  <h2 class="font-bold text-center py-5 mb-0">Most Donated Causes</h2>

  <ngx-flicking
    [options]="{ bound: true, align: 'prev', autoResize: true }"
    class="causes-scroll-container px-4"
  >
    <mat-card
      appearance="outlined"
      class="mat-elevation-z8 flicking-panel mb-4 mr-2"
      flicking-panel
      *ngFor="let list of mostDonatedList; let index = index"
    >
      <mat-card-content
        class="h-full text-center"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <p class="mb-0 font-bold">
          {{ list?.title | titlecase }}
        </p>

        <mat-icon
          fxLayout="row"
          fxLayoutAlign="center center"
          [icIcon]="list.icon"
          color="accent"
        ></mat-icon>
      </mat-card-content>
    </mat-card>
  </ngx-flicking>
</div>
