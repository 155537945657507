import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardNumberComponent,
  StripeService,
} from 'ngx-stripe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'stripe-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
})
export class StripeCardElement implements AfterViewInit, OnDestroy {
  defaultOptns: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#87bbfd' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        lineHeight: '20px',
        fontWeight: 400,
        fontFamily: 'Lato',
        fontSize: 'inherit',
        iconColor: '#979692',
        color: '#ffffff',
        '::placeholder': {
          color: '#efefef',
        },
      },
    },
  };

  // @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  @ViewChild(StripeCardCvcComponent) cvv: StripeCardCvcComponent;
  @ViewChild(StripeCardExpiryComponent) expiry: StripeCardExpiryComponent;

  @Input()
  private set options(optionsIn: StripeCardElementOptions) {
    // this.options$.next(optionsIn);
    this.defaultOptns = optionsIn;
  }

  @Input()
  public set elementsOptions(optionsIn: StripeElementsOptions) {
    this.elementsOptions$.next(optionsIn);
  }

  constructor(private stripeService: StripeService, private fb: FormBuilder) {}

  @Output() public change = new EventEmitter<{
    card: StripeCardNumberComponent;
    event: StripeCardElementChangeEvent;
    cvv?: StripeCardCvcComponent;
    expiry?: StripeCardExpiryComponent;
  }>();
  // @Output() public complete = new EventEmitter<{ card: any; element: StripeElement }>();
  @Output() public error = new EventEmitter<any>();
  // @Input() public elementTypes: Array<ElementType> = ["cardNumber", "cardExpiry", "cardCvc"];

  // @ViewChild("card", { static: false })

  // private card?: ElementRef;
  // private cardElement?: StripeElement;
  // private elements?: Array<StripeElement>;
  // private options$ = new BehaviorSubject<StripeCardElementOptions>(this.defaultOptns);
  private elementsOptions$ = new BehaviorSubject<StripeElementsOptions>({ locale: 'en' });
  paymentRequestAvailable;

  public ngAfterViewInit() {
    // Init Stripe elements
    // const elements$: Observable<Elements> = this.elementsOptions$.asObservable().pipe(
    //   switchMap(options => {
    //     if (Object.keys(options).length > 0) {
    //       return this.stripeService.elements(options);
    //     }
    //     return this.stripeService.elements();
    //   })
    // );
    // let complete = {};
    // observableCombineLatest(elements$, this.options$.asObservable().pipe(filter(options => Boolean(options))))
    //   .pipe(untilDestroyed(this))
    //   .subscribe(([elements, options]) => {
    //     this.elements = [];
    //     var completedElements = {};
    //     // If card exists
    //     if (this.card) {
    //       for (const type of this.elementTypes) {
    //         completedElements[type] = false;
    //         const element = elements.create(type, options);
    //         complete = {
    //           ...complete,
    //           [type]: element
    //         };
    //         if (["card", "cardNumber"].indexOf(type) !== -1) {
    //           this.cardElement = element;
    //         }
    //         const mountTo = this.card.nativeElement.querySelector(`.${type}`);
    //         element.mount(mountTo);
    //         var savedErrors = {};
    //         element.on("change", changedCard => {
    //           let isComplete = changedCard.complete;
    //           completedElements[type] = changedCard.complete;
    //           for (const key in complete) {
    //             if (complete) {
    //               const value = complete[key];
    //               if (key !== changedCard.elementType && !value._complete) {
    //                 isComplete = false;
    //               }
    //             }
    //           }
    //           this.change.emit({
    //             card: changedCard,
    //             elements: this.elements,
    //             type: changedCard.elementType,
    //             complete: isComplete,
    //             element: element
    //           } as any);
    //           if (isComplete) {
    //             /*this.complete.emit({
    //               card: changedCard,
    //               elements: this.elements,
    //               type: changedCard.elementType,
    //               complete: isComplete,
    //               element: element
    //             } as any);*/
    //           }
    //           if (changedCard.error) {
    //             //this.error.emit(changedCard.error);
    //           }
    //           if (changedCard.error) {
    //             this.error.emit(changedCard.error);
    //             savedErrors[type] = changedCard.error;
    //           } else {
    //             savedErrors[type] = null;
    //             this.error.emit(null);
    //             //ctx.errors[idx] = null;
    //             // Loop over the saved errors and find the first one, if any.
    //             var nextError = Object.keys(savedErrors)
    //               .sort()
    //               .reduce(function(maybeFoundError, key) {
    //                 return maybeFoundError || savedErrors[key];
    //               }, null);
    //             if (nextError) {
    //               // Now that they've fixed the current error, show another one.
    //               this.error.emit(nextError);
    //             } else {
    //               // The user fixed the last error; no more errors
    //               this.complete.emit({
    //                 card: changedCard,
    //                 elements: this.elements,
    //                 type: changedCard.elementType,
    //                 complete: isComplete,
    //                 element: element
    //               } as any);
    //             }
    //           }
    //         });
    //         this.elements = [...this.elements, element];
    //       }
    //     }
    //   });
  }

  // public getCard(): StripeElement | undefined {
  //   return this.cardElement;
  // }

  onChange(ev: StripeCardElementChangeEvent) {
    this.change.emit({
      event: ev,
      card: this.card,
    });
  }

  label(value): any {
    switch (value) {
      case 'cardNumber':
        return 'Card Number';
      case 'cardExpiry':
        return 'Expiry Date';
      case 'cardCvc':
        return 'CVC Number';
    }
  }

  cardUpdated(result: any) {
    // console.log(this.card, this.cvv, this.expiry);
    this.change.emit({
      event: result,
      card: this.card,
      cvv: this.cvv,
      expiry: this.expiry,
    });

    if (result.error) {
      this.error = result.error.message;
    } else {
      this.error = undefined;
    }
  }

  ngOnDestroy(): void {}
}
