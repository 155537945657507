import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Page } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import {
  GetCampaignFundraisers,
  GetFundraisers,
} from 'libs/data/src/lib/campaign/store/fundraiser.actions';
import {
  FundraiserState,
  FundraiserStateModel,
} from 'libs/data/src/lib/campaign/store/fundraiser.state';
import { Pagination } from 'libs/data/src/lib/pagination/pagination.interface';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

type FundraiserAppearance = 'list-view' | 'carousel-view' | 'card-view';
const DEFAULT_APPEARANCE = 'list-view';

@UntilDestroy()
@Component({
  selector: 'givebrite-fundraisers',
  templateUrl: './fundraisers.component.html',
  styleUrls: ['./fundraisers.component.scss'],
})
export class FundraisersComponent implements OnInit {
  @Select(FundraiserState.pagination)
  fundraisersPagination$: Observable<Pagination>;

  @Select(FundraiserState.fundraisers)
  fundraisers$: Observable<Fundraiser[]>;

  @Select(FundraiserState.spinner) spinner$: Observable<boolean>;
  @Select(FundraiserState.topFundraiserLoader) topFundraiserLoader$: Observable<boolean>;
  @Select(FundraiserState) state$: Observable<FundraiserStateModel>;

  @ViewChild('fundraiserInput') fundraiserInput: ElementRef;

  /** SET APPEARANCE */

  @Input() set appearance(appearance: FundraiserAppearance) {
    if (appearance) {
      this._appearance = appearance;
    }
  }

  get appearance(): FundraiserAppearance {
    return this._appearance;
  }

  /** SET ID */

  @Input() set id(id: string) {
    if (id) {
      this._id = id;
    }
  }

  get id(): string {
    return this._id;
  }

  /** CURRENT PAGE */
  @Input() set page(page: Page) {
    if (page) {
      this._page = page;
    }
  }

  get page() {
    return this._page;
  }

  @Input() showTopLeaderboard: boolean;

  limit: number;
  total: number;
  loading = false;
  searchFundraiser = '';
  topFundraisers: Fundraiser[] = [];
  private _appearance: FundraiserAppearance = DEFAULT_APPEARANCE;
  private _id: string;
  private _page: Page = 'campaign';

  constructor(private store: Store) {
    this.fundraisersPagination$.pipe(untilDestroyed(this)).subscribe((pagination) => {
      if (pagination) {
        this.limit = pagination.limit;
        this.total = pagination.total;
      }
    });

    this.state$.pipe(untilDestroyed(this)).subscribe((state) => {
      if (state) {
        const fundraisers = state.fundraisers;
        const pagination = state.pagination;

        if (pagination?.page == 1 && !this.topFundraisers.length) {
          if (fundraisers.length >= 4) {
            const list = fundraisers.slice(0, 3);
            [list[0], list[1]] = [list[1], list[0]];
            this.topFundraisers = [...list];
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.getFundraiser(this.page, this.searchFundraiser);
  }

  get maxFundraisersLimitReached(): boolean {
    return this.total <= this.limit;
  }

  get listViewAppearance(): boolean {
    return this.appearance === 'list-view';
  }

  get carouselViewAppearance(): boolean {
    return this.appearance === 'carousel-view';
  }

  get cardViewAppearance(): boolean {
    return this.appearance === 'card-view';
  }

  get campaign(): string {
    return 'campaign';
  }

  get fundraiser(): string {
    return 'fundraiser';
  }

  get team(): string {
    return 'team';
  }

  /**
   * @description
   * GET FUNDRAISER
   * @param page
   * @param search
   */
  getFundraiser(page: string, search: string): void {
    switch (page) {
      case this.campaign:
        this.store.dispatch(new GetCampaignFundraisers(this.campaign, this.id, 13, search));
        break;
      case this.team:
        this.store.dispatch(new GetFundraisers(this.team, this.id, 1, 13, '', search));
        break;
      default:
        this.store.dispatch(new GetFundraisers(this.campaign, this.id, 1, 10, 'desc', search));
        break;
    }
  }

  /**
   * @description
   * LOAD MORE FUNDRAISER
   * @param load:number  - Number of additional fundraisers to load
   */

  moreFundraisers(load = 10): void {
    this.loading = true;
    if (!this.maxFundraisersLimitReached) {
      this.store
        .dispatch(
          new GetCampaignFundraisers(
            this.page == 'team' ? 'team' : 'campaign',
            this.id,
            this.limit + load
          )
        )
        .subscribe(() => {
          this.loading = false;
        });
    }
  }

  ngAfterViewInit() {
    fromEvent(this.fundraiserInput?.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => {
          if (res.length == 0) {
            this.getFundraiser(this.page, this.searchFundraiser);
          }
          return res;
        }),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text) => {
        this.getFundraiser(this.page, this.searchFundraiser);
      });
  }
}
