import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Loading } from '@givebrite/data';
import { Store } from '@ngxs/store';

@Component({
  selector: 'error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.scss'],
})
export class ErrorNotFoundComponent implements OnInit {
  environment;
  constructor(private store: Store, private activatedRoute: ActivatedRoute) {
    this.store.dispatch(new Loading(false));
    this.environment = { ...this.activatedRoute.snapshot.data?.environment };
  }

  ngOnInit(): void {}
}
