import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CampaignPaymentComponent } from "./campaign-payment.component";

@NgModule({
    declarations: [CampaignPaymentComponent],
    imports: [CommonModule],
    exports: [CampaignPaymentComponent]
})
export class CampaignPaymentModule {}
