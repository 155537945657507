export interface DonationCartItem {
  project: {
    _id: string;
    slug?: string;
    name: string;
  };
  appeal: {
    _id: string;
    name: string;
    project?: {
      _id: string;
      name: string;
    };
  };
  impact: {
    _id: string;
    name: string;
    amount: number;
    qty?: number;
  };
  contribution?: {
    _id?: string;
    name?: string;
  };
  donation_type: string;
  price_type: string;
  amount: number;
  frequency: string;
  qty: number;
  _id?: string; // itemId
  quickDonation?: boolean;
  checked?: boolean;
}
