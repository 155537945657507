<div class="sm:w-1/2 mx-auto py-9 h-full" fxLayout="column" fxLayoutAlign="center center">
  <div [@fadeInUp] class="overflow-hidden w-full" [class.hidden]="resetted">
    <div class="text-center mt-4">
      <h2 class="title m-0">Reset Password</h2>
      <h4 class="body-2 text-secondary m-0">Enter your new password.</h4>
    </div>

    <div [formGroup]="form" class="p-6" fxLayout="column" fxLayoutGap="16px">
      <div fxFlex="auto" fxLayout="column">
        <display-message [error]="error" class="mb-4"></display-message>

        <mat-form-field fxFlex="grow">
          <mat-label>New Password</mat-label>

          <mat-icon color="primary" [icIcon]="icSecure" class="mr-2" matPrefix></mat-icon>
          <input formControlName="password" matInput type="password" required />
          <mat-error *ngIf="form.get('password').hasError('minlength')">
            Password needs to be at least 6 characters.
          </mat-error>
        </mat-form-field>
      </div>

      <button
        [disabled]="form.invalid || (loading$ | async)"
        class="btn-large"
        (click)="reset()"
        color="primary"
        mat-raised-button
        type="button"
      >
        Update Password
      </button>
    </div>
  </div>

  <div [@fadeInUp] class="overflow-hidden w-full" fxLayoutAlign="center center" fxLayout="column" *ngIf="resetted">
    <div class="text-center mt-4">
      <h2 class="title m-0">Your password has been reset</h2>
      <h4 class="body-2 text-secondary m-0">You've automatically been logged in!.</h4>
    </div>
    <div class="my-6">
      <span class="icon-container">
        <mat-icon color="primary" size="40px" [icIcon]="icCheck"></mat-icon>
      </span>
    </div>
  </div>
</div>