<div class="sadaqah-jariyah-container mt-10">
  <img src="assets/images/sadaqah-jariyah.svg" class="mx-auto" />

  <h2>“Surely to Allah we belong and to Him we will (all) return.” -[2:156]</h2>

  <h4 class="mt-10 text-lg sm:text-2xl">In loving memory of</h4>

  <form fxLayoutAlign="center center" class="w-full mt-4">
    <mat-form-field fxFlex="75%" fxFlex.xs="100%">
      <input matInput placeholder="Enter Name" required />
    </mat-form-field>
  </form>

  <h2>I want to raise for...</h2>

  <!-- Loops through the default list and displays them as clickable boxes -->
  <div class="w-full mt-5" fxLayout="row wrap" fxLayoutAlign="center center">
    <div
      *ngFor="let item of sadaqahJariyahList; last as isLast; let i = index"
      fxFlex="{{ isLast ? '90%' : '28%' }}"
      class="m-2"
    >
      <mat-card
        appearance="outlined"
        (click)="selectedList(i, item)"
        [ngClass]="item.checked ? 'card-item-shadow' : ''"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
        [ngStyle]="{ height: isLast ? '70px' : '' }"
      >
        <mat-card-content>
          <img src="{{ item.img }}" class="mx-auto mb-5" *ngIf="!isLast" />
          <p
            class="font-bold text-center"
            [ngClass]="item.checked ? 'card-item-text' : ''"
          >
            {{ item.name }}
          </p>

          <givebrite-tick-mark [checked]="item.checked"></givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
