import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CreateInvite } from 'libs/data/src/lib/campaign/store/team.actions';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { GetFollowers } from 'libs/data/src/lib/user/store/auth.actions';
import { AuthState } from 'libs/data/src/lib/user/store/auth.state';
import { Followers } from 'libs/data/src/lib/user/user.interface';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'givebrite-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss'],
})
export class SearchUsersComponent implements OnInit {
  @Select(AuthState.followers) followers$: Observable<Followers[]>;
  @Select(TeamState.createdTeam) team$: Observable<Team>;
  @Select(TeamState.inviteRes) inviteRes$: Observable<Team>;

  followers: Followers[] = [];
  allFollowers: Followers[] = [];
  selectedFollowers: Array<string> = [];
  connectionUserFormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SearchUsersComponent>,
    private store: Store,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetFollowers());
    this.getFollowers();

    this.connectionUserFormControl.valueChanges
      .pipe(
        startWith([]),
        map((value) => this.filterVal(value))
      )
      .subscribe((response) => {
        if (response) this.followers = response;
      });
  }

  filterVal(value) {
    if (this.allFollowers) {
      return this.allFollowers.filter((user) =>
        user.follower.first_name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  /**
   * get Followers list
   */
  getFollowers() {
    this.followers$.pipe(untilDestroyed(this)).subscribe((follower) => {
      if (follower) {
        this.followers = follower;
        this.allFollowers = follower;
      }
    });
  }

  /**
   * add / remove selected users
   * @param event event
   * @param id teamId
   */
  selectedUser(event, id) {
    if (event.source.checked) {
      this.selectedFollowers.push(id);
      this.selectedFollowers = [...new Set(this.selectedFollowers)];
    } else if (!event.source.checked) {
      this.selectedFollowers = this.selectedFollowers.filter((followerId) => {
        return followerId != id;
      });
    }
  }

  /**
   * invite members to the team
   */
  invite() {
    this.team$.pipe(untilDestroyed(this)).subscribe((response) => {
      if (response) {
        this.store.dispatch(
          new CreateInvite(response._id, {
            users: [...this.selectedFollowers],
          })
        );

        this.inviteRes$.pipe(untilDestroyed(this)).subscribe((response: any) => {
          if (response.code == 200) {
            this.toastr.success(response.msg);
            this.dialogRef.close({ confirmation: true });
          }
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
