<div class="all-app-cta">
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="cta-item">
    <h4 fxFlex="60%">Have you given your Qurbani yet?</h4>
    <a [href]="qg" target="_blank" mat-raised-button color="primary" class="qurbani" fxFlex="40%">
      Click here <mat-icon class="ml-2">keyboard_backspace</mat-icon>
    </a>
  </div>

  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="cta-item mt-4">
    <h4 fxFlex="60%">Have you automated your giving for the best 10 days of the year?</h4>
    <a [href]="qg" target="_blank" mat-raised-button color="primary" class="dhg" fxFlex="40%">
      Click here <mat-icon class="ml-2">keyboard_backspace</mat-icon>
    </a>
  </div> -->

  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    fxLayout.xs="column wrap"
    fxLayoutAlign.xs="center center"
    class="cta-item"
  >
    <h4 fxFlex="70%" fxFlex.xs="100%" class="pr-1">
      Have you automated your donations for dhulhijjah? Automate now!
    </h4>
    <a
      [href]="dhg"
      target="_blank"
      mat-raised-button
      color="primary"
      class="dhg rounded-full mt-4 sm:mt-0"
      fxFlex="30%"
      fxFlex.xs="100%"
    >
      <span fxLayout="row" fxLayoutAlign="center center"
        >Click here <mat-icon class="ml-2">keyboard_backspace</mat-icon></span
      >
    </a>
  </div>
</div>
