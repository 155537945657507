<div class="slider cursor-pointer w-full">
  <ngx-flicking #impact [options]="{ bound: true, align: 'prev', autoResize: true }">
    <mat-card
      appearance="outlined"
      class="mat-elevation-z8 p-0 flicking-panel mb-3 mr-4"
      flicking-panel
      *ngFor="let item of sliderItem; let i = index"
      [ngStyle]="{ 'background-color': item.bgcolor }"
    >
      <mat-card-content class="h-full text-center" fxLayout="column" fxLayoutAlign="center center">
        <div class="slider-image" fxLayout="column" fxLayoutAlign="center center">
          <img src="{{ item.image }}" alt="" />
        </div>
        <p class="my-4">{{ item.text }}</p>
        <button
          routerLink="/morecharities"
          class="rounded-full px-5"
          mat-button
          [ngStyle]="{ color: item.bgcolor }"
        >
          {{ item.button }}
        </button>
      </mat-card-content>
    </mat-card>
  </ngx-flicking>
</div>
