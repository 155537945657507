<div class="offline-donation">
  <givebrite-close-icon
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="end"
    (onClose)="closeDialog()"
  ></givebrite-close-icon>

  <mat-dialog-content>
    <h3 class="text-center font-normal">Add offline donation</h3>

    <form [formGroup]="form" class="pt-4">
      <!-- FIRST NAME -->
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <p class="mb-2">First Name</p>

        <mat-form-field class="w-full form-outline">
          <mat-label>First Name</mat-label>
          <input
            matInput
            #first_name
            placeholder="Enter first name of the donor"
            required
            formControlName="first_name"
          />

          <mat-error *ngIf="form.get('first_name').invalid">
            Please enter a valid first name
          </mat-error>
        </mat-form-field>
      </div>

      <!-- LAST NAME -->
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <p class="mb-2">Last Name</p>

        <mat-form-field class="w-full form-outline">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            #last_name
            placeholder="Enter last name of the donor"
            required
            formControlName="last_name"
          />

          <mat-error *ngIf="form.get('last_name').invalid">
            Please enter a valid last name
          </mat-error>
        </mat-form-field>
      </div>

      <!-- DONATION AMOUNT -->
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <p class="mb-2">Donation Amount</p>

        <mat-form-field class="w-full form-outline">
          <div matPrefix class="amount">
            {{ (config$ | async).currency | currencySymbol }}
            <span> {{ (config$ | async).currency }}</span>
          </div>

          <input
            #amount
            matInput
            type="number"
            placeholder="Enter donation amount"
            required
            formControlName="amount"
            class="setamount"
          />

          <mat-error *ngIf="form.get('amount').invalid"> Please enter a valid amount </mat-error>
        </mat-form-field>
      </div>

      <!-- PAYMENT METHOD -->
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <p class="mb-2">Payment Method</p>

        <mat-form-field class="w-full form-outline form-select-outline">
          <mat-select
            placeholder="Select a payment method"
            panelClass="selectOptions"
            formControlName="payment_method"
          >
            <mat-option *ngFor="let method of paymentMethods" [value]="method.name">
              {{ method?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- DONOR MESSAGE -->
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <p class="mb-2">Message From The Donor (Optional)</p>

        <mat-form-field class="w-full form-outline">
          <mat-label>Enter a Message from the donor</mat-label>

          <textarea matInput rows="5" formControlName="comment"></textarea>
        </mat-form-field>
      </div>

      <!-- ANONYMOUS DONATION -->
      <div gdColumns="90% 10%">
        <div fxLayout="row">
          <p>Anonymous Donation</p>
        </div>
        <mat-slide-toggle formControlName="is_anonymous" color="primary"></mat-slide-toggle>
      </div>

      <mat-divider class="my-2"></mat-divider>

      <!-- CLAIM GIFTED AID -->
      <div gdColumns="90% 10%" [class.pb-4]="!form.controls['is_giftaid'].value">
        <div fxLayout="row">
          <p>Claim Gifted Aid</p>
        </div>
        <mat-slide-toggle formControlName="is_giftaid" color="primary"></mat-slide-toggle>
      </div>

      <ng-container formGroupName="billing" *ngIf="form.controls['is_giftaid'].value">
        <!-- HOUSE NAME NUMBER -->
        <div fxLayout="column" fxLayoutAlign="space-between start" class="pt-2">
          <p class="mb-2">House Name Number</p>

          <mat-form-field class="w-full form-outline">
            <mat-label>Enter house name of the donor</mat-label>
            <input
              matInput
              placeholder="Enter house name of the donor"
              required
              formControlName="address_line_1"
              #address_line_1
            />

            <mat-error *ngIf="form.get('billing.address_line_1').invalid">
              Please enter a valid house name
            </mat-error>
          </mat-form-field>
        </div>

        <!-- POSTCODE -->
        <div fxLayout="column" fxLayoutAlign="space-between start">
          <p class="mb-2">Postcode</p>

          <mat-form-field class="w-full form-outline">
            <mat-label>Enter the postcode of the donor</mat-label>
            <input
              matInput
              placeholder="Enter the postcode of the donor"
              required
              formControlName="postcode"
              #postcode
            />

            <mat-error *ngIf="form.get('billing.postcode').invalid">
              Please enter a valid postcode
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <div fxLayout="column" fxLayoutAlign="center center" class="pb-8">
        <button
          mat-raised-button
          color="primary"
          class="w-full cursor-pointer"
          (click)="addRecord()"
          [disabled]="!form.valid || loading"
          [class.disabled]="!form.valid || loading"
        >
          <mat-icon>add</mat-icon>
          Add record
        </button>

        <h4 class="pt-3 text-center cursor-pointer" (click)="addAnotherRecord()">
          Add record and create another
        </h4>
      </div>
    </form>
  </mat-dialog-content>
</div>
