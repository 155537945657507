import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { DeleteAddress, UserAddress, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

@Component({
  selector: 'delete-address-dialog',
  templateUrl: './delete-address-dialog.component.html',
  styleUrls: ['./delete-address-dialog.component.scss'],
})
export class DeleteAddressDialogComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;

  constructor(
    private snackbar: MatSnackBar,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public address: UserAddress,
    private dialog: MatDialogRef<DeleteAddressDialogComponent>
  ) {}

  ngOnInit() {}

  deleteAddress() {
    this.store
      .dispatch(new DeleteAddress(this.address.id))
      .pipe(untilDestroyed(this))
      .subscribe(
        (delted) => {
          this.snackbar.open('Address Deleted', 'Close', { duration: 3000, panelClass: 'error' });
          this.dialog.close();
        },
        (error) => {
          this.snackbar.open('Error deleting Address', 'Close', {
            duration: 3000,
            panelClass: 'error',
          });
        }
      );
  }

  ngOnDestroy() {}
}
