<div class="container  text-center pb-4">
  <div class="justify-content-center">
    <img src="https://s3-eu-west-1.amazonaws.com/ngx-gb/site/404.png" />
  </div>

  <div class="message">
    <h1 class="my-4">Oops sorry. Something went wrong.</h1>
    <p class="mb-3">You may have mistyped the address or the page may have moved</p>
    <a routerLink="/" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon> Back to home page</a>
  </div>
</div>
