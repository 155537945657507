import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrequencySelectorComponent } from './frequency-selector.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [FrequencySelectorComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
  ],
  exports: [FrequencySelectorComponent],
})
export class FrequencySelectorModule {}
