import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { createCampaign } from '@givebrite/theming';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { ShareDialogComponent } from '../../campaigns/dialogs/share-dialog/share-dialog.component';
import { FundraiseOptionsDialogComponent } from '../../campaigns/dialogs/fundraise-options-dialog/fundraise-options-dialog.component';
import { DonationsListDialogComponent } from '../campaign-donations/donations-list-dialog/donations-list-dialog.component';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { Team } from 'libs/data/src/lib/campaign/team.interface';

// enum pages {
//   team = 'team',
//   fundraiser = 'fundraiser',
// }

// let pageType;

type Options = {
  showSummaryCard: boolean;
  hideButtons: boolean;
  showLogo: boolean;
  showRaised: boolean;
  showCampaignCreaterName: boolean;
  buttons: {
    share: boolean;
    support: boolean;
    fundraise: boolean;
    donate: boolean;
  };
};

@UntilDestroy()
@Component({
  selector: 'givebrite-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit {
  @Input() page: string;
  @Input() logo: string;
  @Input() name: string;
  @Input() raised: number;
  @Input() charity: Charity;
  @Input() campaign: Campaign;
  @Input() currency: string;
  @Input() goal: number;
  @Input() donors: number;
  @Input() slug: string;
  @Input() zakat: boolean;
  @Input() end_date: string;
  @Input() start_date: string;
  @Input() campaignId: string;
  @Input() fundraiser: Fundraiser;
  @Input() team: Team;
  @Input() isEnded: boolean;
  @Input() supporters: number;
  @Input() environment;
  @Input() set options(options: Options) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  @Output() donateButtonClicked;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading = true;
  createCampaign = createCampaign;
  donatePath;
  ready: boolean;
  defaultLogo = 'https://gb-downloads.s3.eu-west-1.amazonaws.com/assets/gb_v3/gb_placeholder.png';
  _options: Options = {
    showSummaryCard: true,
    hideButtons: false,
    showLogo: false,
    showRaised: false,
    showCampaignCreaterName: false,
    buttons: {
      share: true,
      support: true,
      fundraise: true,
      donate: true,
    },
  };

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loading$.next(false);
    this.loading$.pipe(untilDestroyed(this), debounceTime(300)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  get daysRemaining() {
    const campaign_end_date = moment(this.end_date);
    return this.end_date ? 'and ' + campaign_end_date.diff(moment(), 'days') + ' days left' : '';
  }

  get campaignEnded(): boolean {
    return false;
  }

  get showSummaryCard(): boolean {
    return this._options.showSummaryCard;
  }

  get showLogo(): boolean {
    return this._options.showLogo;
  }

  get hideButtons(): boolean {
    return this._options.hideButtons;
  }

  get showShareButton(): boolean {
    return this._options.buttons.share;
  }

  get showSupportButton(): boolean {
    return this._options.buttons.support;
  }

  get showFundraiseButton(): boolean {
    return this._options.buttons.fundraise && this.campaign.status !== 'ended';
  }

  get showDonateButton(): boolean {
    return this._options.buttons.donate;
  }

  get showCampaignCreaterName(): boolean {
    return this._options.showCampaignCreaterName;
  }

  get showRaised(): boolean {
    return this._options.showRaised;
  }

  get dateDifference(): number {
    const startDate = moment([this.start_date]);
    const endDate = moment([this.end_date]);
    return startDate.diff(endDate, 'days');
  }

  /**
   * @description
   * OPEN SHARE DIALOG
   */
  openShareDialog(): void {
    this.dialog.open(ShareDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      data: {
        environment: this.environment,
      },
    });
  }

  /**
   * @description
   * OPEN FUNSRAISE OPTION DIALOG
   */
  fundraise(): void {
    this.dialog.open(FundraiseOptionsDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      data: { campaignId: this.campaignId },
    });
  }

  /**
   * @description
   * OPEN DONATION LIST DIALOG
   */
  openDonationListDialog(page: string): void {
    this.dialog.open(DonationsListDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      data: {
        activePage: page,
        campaign: this.campaign,
        fundraiser: this.fundraiser,
        team: this.team,
      },
    });
  }
}
