import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddSponsorsComponent } from './campaign-add-sponsors.component';
import { CampaignAddSponsorsDialogModule } from './campaign-add-sponsors-dialog.module';
import { CampaignAddSectionPlaceholderModule } from './../campaign-add-section-placeholder/campaign-add-section-placeholder.module';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CampaignAddSponsorsComponent],
  imports: [
    CommonModule,
    CampaignAddSectionPlaceholderModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    CampaignAddSponsorsDialogModule,
    MatDialogModule,
    FlexLayoutModule,
  ],
  exports: [CampaignAddSponsorsComponent],
})
export class CampaignAddSponsorsModule {}
