import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import facebookIcon from '@iconify/icons-fa-brands/facebook-f';
import googleIcon from '@iconify/icons-fa-brands/google';
import { Select, Store } from '@ngxs/store';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import {
  SocialLoginRg,
  SocialUserRg,
  UserRg,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { Observable } from 'rxjs';

@Component({
  selector: 'user-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  @Output() authenticated: EventEmitter<UserRg> = new EventEmitter();
  @Output() error: EventEmitter<string[]> = new EventEmitter();
  googleIcon = googleIcon;
  facebookIcon = facebookIcon;

  constructor(
    private router: Router,
    private store: Store,
    private cd: ChangeDetectorRef,
    private authService: SocialAuthService
  ) {}

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      if (user) {
        const payload: SocialUserRg = {
          first_name: user.firstName,
          last_name: user.lastName,
          provider_id: user.id,
          profile_image: user.photoUrl,
          email: user.email,
          provider: user.provider,
        };

        this.store.dispatch(new SocialLoginRg(payload)).subscribe(
          (response) => {
            console.log(response.user.user);
            this.authenticated.emit(response.user.user);
            this.authService.signOut();
          },
          (error) => {
            console.log(error);
            this.error.emit(error);
          }
        );
      }
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy() {}
}
