import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { CampaignManagerComponent } from './campaign-manager.component';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { CampaignMenuModule } from '../campaign-menu/campaign-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CampaignBasicFormModule } from './campaign-basic/campaign-basic-form.module';
import { CampaignMediaModule } from './campaign-media/campaign-media.module';
import { CampaignContentModule } from './campaign-content/campaign-content.module';
import { ViewCardModule } from '../../view-templates/view-card/view-card.module';
import { CampaignPaymentModule } from './campaign-payment/campaign-payment.module';
import { CampaignLaunchModule } from './campaign-launch/campaign-launch.module';
import { CampaignImpactsModule } from './campaign-impacts/campaign-impacts.module';
import { ContainerModule } from 'libs/theming/src/lib/directives/container/container.module';

@NgModule({
  declarations: [
    // CampaignManagerComponent
  ],

  imports: [
    CommonModule,
    IconModule,
    MatSidenavModule,
    MatIconModule,
    // CampaignMenuModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ContainerModule,
    CampaignBasicFormModule,
    CampaignContentModule,
    CampaignMediaModule,
    CampaignPaymentModule,
    CampaignLaunchModule,
    CampaignImpactsModule,
    ViewCardModule,
    MatButtonModule,
  ],
  exports: [
    //CampaignManagerComponent
  ],
})
export class CampaignManagerModule {}
