import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-campaign-media',
  templateUrl: './campaign-media.component.html',
  styleUrls: ['./campaign-media.component.scss'],
})
export class CampaignMediaComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() campaign: CampaignRg;
  options = {
    accept: ['image/*', 'video/*'],
    maxFiles: 1,
    transformations: { crop: { aspectRatio: 100 / 200, force: true } },
  };

  constructor(private fb: FormBuilder, private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.form.addControl('cover', new FormControl(null, Validators.required));
  }

  get cover() {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${this.form.get('cover').value})`);
  }

  uploaded(file) {
    this.form.get('cover').setValue(file.url);
  }
}
