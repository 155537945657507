import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import close from '@iconify/icons-ic/close';
import { Select } from '@ngxs/store';
import { FundraiseOptions } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Pagination } from 'libs/data/src/lib/pagination/pagination.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-fundraise-options-dialog',
  templateUrl: './fundraise-options-dialog.component.html',
  styleUrls: ['./fundraise-options-dialog.component.scss'],
})
export class FundraiseOptionsDialogComponent implements OnInit {
  @Select(TeamState.pagination) teamsPagination$: Observable<Pagination>;
  closeIcon: unknown = close;

  fundraiseOptions: FundraiseOptions[] = [
    { name: 'Create a page', icon: 'language', linkName: 'page' },
    { name: 'Create a team', icon: 'group_add', linkName: 'createTeam' },
    { name: 'Join a team', icon: 'group', linkName: 'joinTeam' },
  ];

  constructor(
    private dialogRef: MatDialogRef<FundraiseOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {}

  ngOnInit(): void {}

  /**
   * NAVIGATE
   * @description
   * @param linkName URL NAME
   */
  navigate(linkName: string): void {
    if (linkName == 'page') {
      this.router.navigate([`/fundraiser/create/${this.data.campaignId}`]);
    } else if (linkName == 'createTeam') {
      this.router.navigate([`/team/create/${this.data.campaignId}`]);
    } else if (linkName == 'joinTeam') {
      this.router.navigate([`/team/join/${this.data.campaignId}`]);
    }
    this.closeDialog();
  }

  /**
   * @description
   * CLOSE DIALOG
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
