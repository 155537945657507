import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopLeaderboardComponent } from './top-leaderboard.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserLogoAvatarModule } from 'libs/core/src/lib/user/user-logo-avatar/user-logo-avatar.module';

@NgModule({
  declarations: [TopLeaderboardComponent],
  imports: [CommonModule, FlexLayoutModule, UserLogoAvatarModule],
  exports: [TopLeaderboardComponent],
})
export class TopLeaderboardModule {}
