import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignCharitySelectorComponent } from './campaign-charity-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterSearchModule } from 'libs/theming/src/lib/pipes/filter-search/filter-search.module';
import { TickMarkModule } from '../../tick-mark/tick-mark.module';
import { CharityLogoAvatarModule } from '../../../charity/charity-logo-avatar/charity-logo-avatar.module';

@NgModule({
  declarations: [CampaignCharitySelectorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    FilterSearchModule,
    TickMarkModule,
    CharityLogoAvatarModule,
  ],
  exports: [CampaignCharitySelectorComponent],
})
export class CampaignCharitySelectorModule {}
