import { Injectable } from '@angular/core';
import { ApiService } from './../../../../api/src/lib/api.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private http: ApiService) {}

  update(payload) {
    console.log(payload);
    return this.http.put(`team?team_id=${payload._id}`, payload);
  }
}
