import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginConfirmationComponent } from './login-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: LoginConfirmationComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginConfirmationRoutingModule {}
