import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { AddBillingWizardComponent } from './add-billing-wizard.component';
import { CountryListModule } from './../../country-list/country-list.module';
import { ViewCardModule } from './../../view-templates/view-card/view-card.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@abhinavakhil/iconify-angular';

@NgModule({
  declarations: [AddBillingWizardComponent],
  imports: [
    CommonModule,
    MatStepperModule,
    ViewCardModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    CountryListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    FlexLayoutModule,
    MatIconModule,
    IconModule,
  ],
  exports: [AddBillingWizardComponent],
})
export class AddBillingWizardModule {}
