<div fxLayoutAlign="center center" class="relative cover-container">
  <img [src]="cover" class="cover" />

  <div class="cover-logo">
    <givebrite-user-logo-avatar
      [logo]="logo"
      [name]="name"
      [border]="false"
      width="120px"
      fxHide.xs
    ></givebrite-user-logo-avatar>

    <givebrite-user-logo-avatar
      [logo]="logo"
      [name]="name"
      [border]="false"
      width="85px"
      fxHide
      fxShow.xs
    ></givebrite-user-logo-avatar>
  </div>
</div>
