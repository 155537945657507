import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurVolunteersFundraisersCardComponent } from './our-volunteers-fundraisers-card.component';
import { MatCardModule } from '@angular/material/card';
import { LogoCardModule } from 'libs/theming/src/lib/components/logo-card/logo-card.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    OurVolunteersFundraisersCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    LogoCardModule,
    MatIconModule,
    FlexLayoutModule
  ],
  exports: [
    OurVolunteersFundraisersCardComponent
  ]
})
export class OurVolunteersFundraisersCardModule { }
