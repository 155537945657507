import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { GivebriteConfig } from 'libs/data/src/lib/app/givebrite.interface';
import { GivebriteState } from 'libs/data/src/lib/app/store/givebrite.state';
import { DonationCartItem } from 'libs/data/src/lib/donation-cart/donation-cart.interface';
import { DonationCartState } from 'libs/data/src/lib/donation-cart/store/donation-cart.state';
import { Observable } from 'rxjs';

export interface Options {
  shadow: string;
}

@UntilDestroy()
@Component({
  selector: 'givebrite-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;
  @Select(DonationCartState.loading) loader$: Observable<boolean>;
  @Select(DonationCartState.getDonationItems) cartItems$: Observable<DonationCartItem[]>;

  @Output()
  onIncreaseQuantity: EventEmitter<DonationCartItem> = new EventEmitter<DonationCartItem>();
  @Output()
  onDecreaseQuantity: EventEmitter<DonationCartItem> = new EventEmitter<DonationCartItem>();
  @Output() onDeleteFromCart: EventEmitter<DonationCartItem> = new EventEmitter<DonationCartItem>();

  @Input() environment;

  /** SET STYLE */
  @Input()
  get options(): Options {
    return this._options;
  }

  set options(config: Options) {
    if (config) {
      this._options = config;
    }
  }

  private _options: Options;

  cartItems: DonationCartItem[];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.cartItems$.pipe(untilDestroyed(this)).subscribe((cartItems) => {
      if (cartItems) this.cartItems = [...cartItems].filter((cart) => !cart.contribution);
    });
  }

  /**
   * @description
   * INCREASE QUANITTY OF CART
   */
  increaseQuantity(item: DonationCartItem): void {
    this.onIncreaseQuantity.emit(item);
  }

  /**
   * @description
   * DECREASE QUANITTY OF CART
   */
  decreaseQuantity(item: DonationCartItem): void {
    this.onDecreaseQuantity.emit(item);
  }

  /**
   * @description
   * DELETE ITEM FROM THE CART
   */
  deleteFromCart(item: DonationCartItem): void {
    this.onDeleteFromCart.emit(item);
  }

  goBack() {
    this.router.navigate(['/donation']);
  }

  get shadow(): string {
    return this.options?.shadow;
  }
}
