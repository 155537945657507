import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'country'
})
export class CountryNamePipe implements PipeTransform {
  _countries = [
    { currency: "AFN", name: "Afghanistan", code: "AF" },
    { currency: "EUR", name: "Åland Islands", code: "AX" },
    { currency: "ALL", name: "Albania", code: "AL" },
    { currency: "DZD", name: "Algeria", code: "DZ" },
    { currency: "USD", name: "American Samoa", code: "AS" },
    { currency: "EUR", name: "AndorrA", code: "AD" },
    { currency: "AOA", name: "Angola", code: "AO" },
    { currency: "XCD", name: "Anguilla", code: "AI" },
    { currency: "", name: "Antarctica", code: "AQ" },
    { currency: "XCD", name: "Antigua and Barbuda", code: "AG" },
    { currency: "ARS", name: "Argentina", code: "AR" },
    { currency: "AMD", name: "Armenia", code: "AM" },
    { currency: "AWG", name: "Aruba", code: "AW" },
    { currency: "AUD", name: "Australia", code: "AU" },
    { currency: "EUR", name: "Austria", code: "AT" },
    { currency: "AZN", name: "Azerbaijan", code: "AZ" },
    { currency: "BSD", name: "Bahamas", code: "BS" },
    { currency: "BHD", name: "Bahrain", code: "BH" },
    { currency: "BDT", name: "Bangladesh", code: "BD" },
    { currency: "BBD", name: "Barbados", code: "BB" },
    { currency: "BYR", name: "Belarus", code: "BY" },
    { currency: "EUR", name: "Belgium", code: "BE" },
    { currency: "BZD", name: "Belize", code: "BZ" },
    { currency: "XOF", name: "Benin", code: "BJ" },
    { currency: "BMD", name: "Bermuda", code: "BM" },
    { currency: "BTN", name: "Bhutan", code: "BT" },
    { currency: "BOB", name: "Bolivia", code: "BO" },
    { currency: "BAM", name: "Bosnia and Herzegovina", code: "BA" },
    { currency: "BWP", name: "Botswana", code: "BW" },
    { currency: "NOK", name: "Bouvet Island", code: "BV" },
    { currency: "BRL", name: "Brazil", code: "BR" },
    { currency: "USD", name: "British Indian Ocean Territory", code: "IO" },
    { currency: "BND", name: "Brunei Darussalam", code: "BN" },
    { currency: "BGN", name: "Bulgaria", code: "BG" },
    { currency: "XOF", name: "Burkina Faso", code: "BF" },
    // { currency: "BIF", name: "Burundi", code: "BI" },
    { currency: "KHR", name: "Cambodia", code: "KH" },
    { currency: "XAF", name: "Cameroon", code: "CM" },
    { currency: "CAD", name: "Canada", code: "CA" },
    { currency: "CVE", name: "Cape Verde", code: "CV" },
    { currency: "KYD", name: "Cayman Islands", code: "KY" },
    { currency: "XAF", name: "Central African Republic", code: "CF" },
    { currency: "XAF", name: "Chad", code: "TD" },
    { currency: "CLP", name: "Chile", code: "CL" },
    { currency: "CNY", name: "China", code: "CN" },
    { currency: "AUD", name: "Christmas Island", code: "CX" },
    { currency: "AUD", name: "Cocos (Keeling) Islands", code: "CC" },
    { currency: "COP", name: "Colombia", code: "CO" },
    // { currency: "KMF", name: "Comoros", code: "KM" },
    { currency: "XAF", name: "Congo", code: "CG" },
    { currency: "CDF", name: "Congo, The Democratic Republic of the", code: "CD" },
    { currency: "NZD", name: "Cook Islands", code: "CK" },
    { currency: "CRC", name: "Costa Rica", code: "CR" },
    { currency: "XOF", name: "Cote D'Ivoire", code: "CI" },
    { currency: "HRK", name: "Croatia", code: "HR" },
    { currency: "CUP", name: "Cuba", code: "CU" },
    { currency: "EUR", name: "Cyprus", code: "CY" },
    { currency: "CZK", name: "Czech Republic", code: "CZ" },
    { currency: "DKK", name: "Denmark", code: "DK" },
    // { currency: "DJF", name: "Djibouti", code: "DJ" },
    { currency: "XCD", name: "Dominica", code: "DM" },
    { currency: "DOP", name: "Dominican Republic", code: "DO" },
    { currency: "USD", name: "Ecuador", code: "EC" },
    { currency: "EGP", name: "Egypt", code: "EG" },
    { currency: "USD", name: "El Salvador", code: "SV" },
    { currency: "XAF", name: "Equatorial Guinea", code: "GQ" },
    { currency: "ERN", name: "Eritrea", code: "ER" },
    { currency: "EUR", name: "Estonia", code: "EE" },
    { currency: "ETB", name: "Ethiopia", code: "ET" },
    { currency: "FKP", name: "Falkland Islands (Malvinas)", code: "FK" },
    { currency: "DKK", name: "Faroe Islands", code: "FO" },
    { currency: "FJD", name: "Fiji", code: "FJ" },
    { currency: "EUR", name: "Finland", code: "FI" },
    { currency: "EUR", name: "France", code: "FR" },
    { currency: "EUR", name: "French Guiana", code: "GF" },
    { currency: "XPF", name: "French Polynesia", code: "PF" },
    { currency: "EUR", name: "French Southern Territories", code: "TF" },
    { currency: "XAF", name: "Gabon", code: "GA" },
    { currency: "GMD", name: "Gambia", code: "GM" },
    { currency: "GEL", name: "Georgia", code: "GE" },
    { currency: "EUR", name: "Germany", code: "DE" },
    { currency: "GHS", name: "Ghana", code: "GH" },
    { currency: "GIP", name: "Gibraltar", code: "GI" },
    { currency: "EUR", name: "Greece", code: "GR" },
    { currency: "DKK", name: "Greenland", code: "GL" },
    { currency: "XCD", name: "Grenada", code: "GD" },
    { currency: "EUR", name: "Guadeloupe", code: "GP" },
    { currency: "USD", name: "Guam", code: "GU" },
    { currency: "GTQ", name: "Guatemala", code: "GT" },
    { currency: "GBP", name: "Guernsey", code: "GG" },
    { currency: "GNF", name: "Guinea", code: "GN" },
    { currency: "XOF", name: "Guinea-Bissau", code: "GW" },
    { currency: "GYD", name: "Guyana", code: "GY" },
    { currency: "HTG", name: "Haiti", code: "HT" },
    { currency: "AUD", name: "Heard Island and Mcdonald Islands", code: "HM" },
    { currency: "EUR", name: "Holy See (Vatican City State)", code: "VA" },
    { currency: "HNL", name: "Honduras", code: "HN" },
    { currency: "HKD", name: "Hong Kong", code: "HK" },
    { currency: "HUF", name: "Hungary", code: "HU" },
    { currency: "ISK", name: "Iceland", code: "IS" },
    { currency: "INR", name: "India", code: "IN" },
    { currency: "IDR", name: "Indonesia", code: "ID" },
    { currency: "IRR", name: "Iran, Islamic Republic Of", code: "IR" },
    { currency: "IQD", name: "Iraq", code: "IQ" },
    { currency: "EUR", name: "Ireland", code: "IE" },
    { currency: "GBP", name: "Isle of Man", code: "IM" },
    { currency: "ILS", name: "Israel", code: "IL" },
    { currency: "EUR", name: "Italy", code: "IT" },
    { currency: "JMD", name: "Jamaica", code: "JM" },
    { currency: "JPY", name: "Japan", code: "JP" },
    { currency: "GBP", name: "Jersey", code: "JE" },
    { currency: "JOD", name: "Jordan", code: "JO" },
    { currency: "KZT", name: "Kazakhstan", code: "KZ" },
    { currency: "KES", name: "Kenya", code: "KE" },
    { currency: "AUD", name: "Kiribati", code: "KI" },
    { currency: "KPW", name: "Korea, Democratic People'S Republic of", code: "KP" },
    // { currency: "KRW", name: "Korea, Republic of", code: "KR" },
    { currency: "KWD", name: "Kuwait", code: "KW" },
    { currency: "KGS", name: "Kyrgyzstan", code: "KG" },
    { currency: "LAK", name: "Lao People'S Democratic Republic", code: "LA" },
    { currency: "EUR", name: "Latvia", code: "LV" },
    { currency: "LBP", name: "Lebanon", code: "LB" },
    { currency: "LSL", name: "Lesotho", code: "LS" },
    { currency: "LRD", name: "Liberia", code: "LR" },
    { currency: "LYD", name: "Libyan Arab Jamahiriya", code: "LY" },
    { currency: "CHF", name: "Liechtenstein", code: "LI" },
    { currency: "LTL", name: "Lithuania", code: "LT" },
    { currency: "EUR", name: "Luxembourg", code: "LU" },
    { currency: "MOP", name: "Macao", code: "MO" },
    { currency: "MKD", name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { currency: "MGA", name: "Madagascar", code: "MG" },
    { currency: "MWK", name: "Malawi", code: "MW" },
    { currency: "MYR", name: "Malaysia", code: "MY" },
    { currency: "MVR", name: "Maldives", code: "MV" },
    { currency: "XOF", name: "Mali", code: "ML" },
    { currency: "EUR", name: "Malta", code: "MT" },
    { currency: "USD", name: "Marshall Islands", code: "MH" },
    { currency: "EUR", name: "Martinique", code: "MQ" },
    { currency: "MRO", name: "Mauritania", code: "MR" },
    { currency: "MUR", name: "Mauritius", code: "MU" },
    { currency: "EUR", name: "Mayotte", code: "YT" },
    { currency: "MXN", name: "Mexico", code: "MX" },
    { currency: "USD", name: "Micronesia, Federated States of", code: "FM" },
    { currency: "MDL", name: "Moldova, Republic of", code: "MD" },
    { currency: "EUR", name: "Monaco", code: "MC" },
    { currency: "MNT", name: "Mongolia", code: "MN" },
    { currency: "XCD", name: "Montserrat", code: "MS" },
    { currency: "MAD", name: "Morocco", code: "MA" },
    { currency: "MZN", name: "Mozambique", code: "MZ" },
    { currency: "MMK", name: "Myanmar", code: "MM" },
    { currency: "NAD", name: "Namibia", code: "NA" },
    { currency: "AUD", name: "Nauru", code: "NR" },
    { currency: "NPR", name: "Nepal", code: "NP" },
    { currency: "EUR", name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { currency: "XPF", name: "New Caledonia", code: "NC" },
    { currency: "NZD", name: "New Zealand", code: "NZ" },
    { currency: "NIO", name: "Nicaragua", code: "NI" },
    { currency: "XOF", name: "Niger", code: "NE" },
    { currency: "NGN", name: "Nigeria", code: "NG" },
    { currency: "NZD", name: "Niue", code: "NU" },
    { currency: "AUD", name: "Norfolk Island", code: "NF" },
    { currency: "USD", name: "Northern Mariana Islands", code: "MP" },
    { currency: "NOK", name: "Norway", code: "NO" },
    { currency: "OMR", name: "Oman", code: "OM" },
    { currency: "PKR", name: "Pakistan", code: "PK" },
    { currency: "USD", name: "Palau", code: "PW" },
    { currency: "ILS", name: "Palestinian Territory, Occupied", code: "PS" },
    { currency: "PAB", name: "Panama", code: "PA" },
    { currency: "PGK", name: "Papua New Guinea", code: "PG" },
    { currency: "PYG", name: "Paraguay", code: "PY" },
    { currency: "PEN", name: "Peru", code: "PE" },
    { currency: "PHP", name: "Philippines", code: "PH" },
    { currency: "NZD", name: "Pitcairn", code: "PN" },
    { currency: "PLN", name: "Poland", code: "PL" },
    { currency: "EUR", name: "Portugal", code: "PT" },
    { currency: "USD", name: "Puerto Rico", code: "PR" },
    { currency: "QAR", name: "Qatar", code: "QA" },
    { currency: "EUR", name: "Reunion", code: "RE" },
    { currency: "RON", name: "Romania", code: "RO" },
    { currency: "RUB", name: "Russian Federation", code: "RU" },
    { currency: "RWF", name: "RWANDA", code: "RW" },
    { currency: "SHP", name: "Saint Helena", code: "SH" },
    { currency: "XCD", name: "Saint Kitts and Nevis", code: "KN" },
    { currency: "XCD", name: "Saint Lucia", code: "LC" },
    { currency: "EUR", name: "Saint Pierre and Miquelon", code: "PM" },
    { currency: "XCD", name: "Saint Vincent and the Grenadines", code: "VC" },
    { currency: "WST", name: "Samoa", code: "WS" },
    { currency: "EUR", name: "San Marino", code: "SM" },
    { currency: "STD", name: "Sao Tome and Principe", code: "ST" },
    { currency: "SAR", name: "Saudi Arabia", code: "SA" },
    { currency: "XOF", name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { currency: "SCR", name: "Seychelles", code: "SC" },
    { currency: "SLL", name: "Sierra Leone", code: "SL" },
    { currency: "SGD", name: "Singapore", code: "SG" },
    { currency: "EUR", name: "Slovakia", code: "SK" },
    { currency: "EUR", name: "Slovenia", code: "SI" },
    { currency: "SBD", name: "Solomon Islands", code: "SB" },
    { currency: "SOS", name: "Somalia", code: "SO" },
    { currency: "ZAR", name: "South Africa", code: "ZA" },
    { currency: "GBP", name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { currency: "EUR", name: "Spain", code: "ES" },
    { currency: "LKR", name: "Sri Lanka", code: "LK" },
    { currency: "SDG", name: "Sudan", code: "SD" },
    { currency: "SRD", name: "Suriname", code: "SR" },
    { currency: "NOK", name: "Svalbard and Jan Mayen", code: "SJ" },
    { currency: "SZL", name: "Swaziland", code: "SZ" },
    { currency: "SEK", name: "Sweden", code: "SE" },
    { currency: "CHF", name: "Switzerland", code: "CH" },
    { currency: "SYP", name: "Syrian Arab Republic", code: "SY" },
    { currency: "TWD", name: "Taiwan, Province of China", code: "TW" },
    { currency: "TJS", name: "Tajikistan", code: "TJ" },
    { currency: "TZS", name: "Tanzania, United Republic of", code: "TZ" },
    { currency: "THB", name: "Thailand", code: "TH" },
    { currency: "USD", name: "Timor-Leste", code: "TL" },
    { currency: "XOF", name: "Togo", code: "TG" },
    { currency: "NZD", name: "Tokelau", code: "TK" },
    { currency: "TOP", name: "Tonga", code: "TO" },
    { currency: "TTD", name: "Trinidad and Tobago", code: "TT" },
    { currency: "TND", name: "Tunisia", code: "TN" },
    { currency: "TRY", name: "Turkey", code: "TR" },
    { currency: "TMT", name: "Turkmenistan", code: "TM" },
    { currency: "USD", name: "Turks and Caicos Islands", code: "TC" },
    { currency: "AUD", name: "Tuvalu", code: "TV" },
    { currency: "UGX", name: "Uganda", code: "UG" },
    { currency: "UAH", name: "Ukraine", code: "UA" },
    { currency: "AED", name: "United Arab Emirates", code: "AE" },
    { currency: "GBP", name: "United Kingdom", code: "GB" },
    { currency: "USD", name: "United States", code: "US" },
    { currency: "USD", name: "United States Minor Outlying Islands", code: "UM" },
    { currency: "UYU", name: "Uruguay", code: "UY" },
    { currency: "UZS", name: "Uzbekistan", code: "UZ" },
    // { currency: "VUV", name: "Vanuatu", code: "VU" },
    { currency: "VEF", name: "Venezuela", code: "VE" },
    { currency: "VND", name: "Viet Nam", code: "VN" },
    { currency: "USD", name: "Virgin Islands, British", code: "VG" },
    { currency: "USD", name: "Virgin Islands, U.S.", code: "VI" },
    { currency: "XPF", name: "Wallis and Futuna", code: "WF" },
    { currency: "MAD", name: "Western Sahara", code: "EH" },
    { currency: "YER", name: "Yemen", code: "YE" },
    { currency: "ZMK", name: "Zambia", code: "ZM" },
    { currency: "ZWL", name: "Zimbabwe", code: "ZW" }
  ];
  transform(code): any {
    return this.getCountryName(code)?.name;
  }

  getCountryName(code) {
    return this._countries.find(c => c.code == code);
  }
}
