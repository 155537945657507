<div class="campaign-card relative mb-5" [ngStyle]="{ width: width }">
  <!-- <mat-card (click)="goToCampaign()" class="p-0" > -->
  <div class="thumbnail">
    <img
      [class.hidden]="!thumbnailLoaded"
      (load)="thumbnailLoaded = true"
      [src]="cover"
      [alt]="_campaign?.name"
      responsive
      fit="crop"
      width="200"
      height="130"
    />
    <ngx-skeleton-loader
      *ngIf="!thumbnailLoaded"
      [theme]="{ width: '100%', height: '169px', 'margin-bottom': 0 }"
    ></ngx-skeleton-loader>
  </div>

  <!-- <mat-card-content class="py-6 px-4 sm:px-6"> -->
  <div *ngIf="!_campaign">
    <ngx-skeleton-loader></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '90%' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '80%' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '90%' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '60%' }"></ngx-skeleton-loader>
  </div>

  <div class="campaign-card-content" *ngIf="_campaign" fxLayout="column" fxLayoutGap="15px">
    <span class="card-badge-icon absolute rounded-2xl w-11 right-2 top-2.5" *ngIf="!hide">
      <p class="text-badge text-center">{{ percentage | number: '1.0-0' }}%</p>
    </span>

    <span class="absolute rounded-2xl w-11 right-2 top-2.5" *ngIf="hide">
      <givebrite-tick-mark [checked]="_campaign?.checked"></givebrite-tick-mark>
    </span>

    <h2>
      {{ _campaign?.name | titlecase | truncate: 35 }}
    </h2>
    <!-- <img src="assets/images/z-sign.svg" *ngIf="_campaign?.settings.is_zakat" /> -->

    <givebrite-progress-bar
      [raised]="_campaign.statistics.raised"
      [currency]="_campaign.currency"
    ></givebrite-progress-bar>

    <!-- <h5>{{ _campaign?.excerpt }}</h5> -->

    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="1rem">
      <a
        fxFlex="60%"
        mat-raised-button
        class="rounded-full btn-md"
        color="primary"
        href="{{ donatePath }}/{{ _campaign?.charity.slug }}?campaign={{ _campaign?._id }}"
      >
        Donate
      </a>
      <div class="days-remainining">{{ daysRemaining }}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="left center">
      <!-- <givebrite-charity-logo-avatar
        [charity]="_campaign?.charity"
        [color]="true"
        width="30"
        *ngIf="!hide"
        radius="50px"
      ></givebrite-charity-logo-avatar> -->
      <div class="pl-3 charity-name">
        {{ _campaign?.charity.name | truncate: 31 }}
      </div>
    </div>

    <!-- <a fxFlex color="secondary" mat-stroked-button> Share </a> -->
  </div>

  <!-- </mat-card-content> -->
  <!-- </mat-card> -->
</div>
