import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
@Component({
  selector: "display-message",
  templateUrl: "./display-message.component.html",
  styleUrls: ["./display-message.component.scss"]
})
export class DisplayMessageComponent
  implements OnInit, AfterViewInit {
  constructor() {}

  @Input() error;
  @Input() type: string = "error";
  @Input() heading: string;
  @ViewChild("content", { static: true }) content: ElementRef;

  ngOnInit() {}

  ngAfterViewInit() {}

  get class() {     
    return {
      "error": this.type == "error",
      "success": this.type == "success",
      hidden:
        this.content.nativeElement.innerHTML.trim() == undefined || !this.error
    };
  }
}
