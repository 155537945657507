<div class="how-it-works">
  <h2 class="font-bold text-center pt-1 pb-5 mb-0">How it works</h2>

  <!-- <p class="text-center">
    Ramadhan Giving is an automated giving platform where donors can select giving everyday of
    Ramadhan or the last 10 nights of Ramadhan, this being the most auspicious time of the year.
  </p> -->

  <!-- <div class="relative">
    <div class="vertical-border"></div>
    <mat-card class="mat-elevation-z8" *ngFor="let step of steps" class="my-8">
      <mat-card-content fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [icIcon]="step.icon" color="accent"></mat-icon>
        <h4 class="w-full text-center">{{ step.title }}</h4>
      </mat-card-content>
    </mat-card>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-raised-button
        color="primary"
        (click)="navigateToCharities()"
        class="w-full btn-md"
      >
        Choose from 100+ Charities <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div> -->

  <div fxLayout="row wrap" fxLayoutGap="0.75rem grid" fxLayoutGap.gt-xs="1rem grid">
    <!-- <div class="vertical-border"></div> -->
    <div *ngFor="let step of steps" fxFlex="50%">
      <mat-card appearance="outlined" class="mat-elevation-z0">
        <mat-card-content
          class="h-full text-center"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <mat-icon [icIcon]="step.icon" color="accent"></mat-icon>
          <h4 class="w-full text-center">{{ step.title }}</h4>
        </mat-card-content>
      </mat-card>
    </div>
    <!--
    <div fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-raised-button
        color="primary"
        (click)="navigateToCharities()"
        class="w-full btn-md"
      >
        Choose from 100+ Charities <mat-icon>chevron_right</mat-icon>
      </button>
    </div> -->
  </div>
</div>
