import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'apps/home/src/environments/environment';

@Component({
  selector: 'givebrite-countdown-toolbar',
  templateUrl: './countdown-toolbar.component.html',
  styleUrls: ['./countdown-toolbar.component.scss']
})
export class CountdownToolbarComponent implements OnInit {
  @Input() buttoncolor = '#02a95c';

  ramadhanUrl = environment.apps.ramadhan.url;
  constructor() { }
    
  ngOnInit(): void {}

}