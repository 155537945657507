<div class="search">
  <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-form-field class="w-full">
      <input
        matInput
        (ngModelChange)="searchResult($event)"
        placeholder="Search for charities"
        [(ngModel)]="search"
        name="search"
      />
      <mat-icon matSuffix style="color: #c9d4dc">search</mat-icon>
    </mat-form-field>
  </div>
</div>
