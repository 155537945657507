<div class="team-leaderboard" *ngIf="teamLeaderboards$ | async as Leaderboards">
  <h2>Top Team Members</h2>

  <ngx-flicking
    *ngIf="Leaderboards?.length; else noTeamMembers"
    [options]="{ bound: true, align: 'prev' }"
    class="sm:overflow-x-scroll"
  >
    <mat-card
      appearance="outlined"
      class="mat-elevation-z8"
      *ngFor="let leaderboard of Leaderboards"
      flicking-panel
    >
      <mat-card-content
        class="text-center"
        fxLayout="column"
        fxLayoutAlign="start center"
        *ngIf="leaderboard?.data"
      >
        <h3>{{ leaderboard.type }}</h3>

        <div class="py-3">
          <givebrite-user-logo-avatar
            [logo]="leaderboard.data.user.profile_image"
            [name]="
              leaderboard.data.user.first_name +
              ' ' +
              leaderboard.data.user.last_name
            "
            [border]="false"
            width="100px"
            [shadow]="true"
          ></givebrite-user-logo-avatar>
        </div>

        <h4 class="font-bold">
          {{
            leaderboard.data.user.first_name +
              ' ' +
              leaderboard.data.user.last_name | titlecase
          }}
        </h4>
        <p>
          {{
            leaderboard.data.statistics.raised
              | currency: leaderboard.data.statistics.currency:'symbol':'1.0-0'
          }}
          raised
        </p>
      </mat-card-content>
    </mat-card>
  </ngx-flicking>

  <ng-template #noTeamMembers>
    <div class="text-center mb-3 sm:text-left">
      We are waiting for more donations
    </div>

    <div>
      <ngx-flicking
        [options]="{ bound: true, align: 'prev' }"
        class="sm:overflow-x-scroll"
      >
        <mat-card
          appearance="outlined"
          fxLayoutAlign="center center"
          class="mat-elevation-z8 loader"
          *ngFor="let leaderboard of leaderboards"
          flicking-panel
        >
          <mat-card-content>
            <h3 class="title">{{ leaderboard.name }}</h3>
          </mat-card-content>
        </mat-card>
      </ngx-flicking>
    </div>
  </ng-template>
</div>
