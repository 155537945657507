<div class="trending-donations">
  <ng-container
    *ngFor="let donation of trendingDonations.slice(0, 3); first as isFirst; last as isLast"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      class="my-2"
      [class.mt-0]="isFirst"
    >
      <givebrite-user-logo-avatar
        [logo]="donation?.is_anonymous ? null : donation?.user?.profile_image"
        [name]="donation?.first_name + ' ' + donation?.last_name"
        [border]="false"
        width="48px"
      ></givebrite-user-logo-avatar>

      <div fxLayout="column" fxLayoutAlign="center start">
        <h4 class="mb-1">
          {{
            donation?.is_anonymous
              ? 'Anonymous'
              : (donation?.first_name + ' ' + donation?.last_name | titlecase)
          }}
        </h4>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <p class="font-semibold">
            {{ donation.amount | currency: donation.currency:'symbol':'1.0-1' }}
          </p>
          <p class="mx-2">•</p>
          <p>{{ donation.created_at | dateAgo }}</p>
        </div>
      </div>
    </div>

    <mat-divider class="mt-3" *ngIf="!isLast"></mat-divider>
  </ng-container>
</div>
