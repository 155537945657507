<div class="giftaid-consent">
  <p>
    Yes, I want to Gift Aid any donations made to
    {{ label }} now, in the future and in the past four years.
  </p>
  <p>
    I am a UK taxpayer and I understand that if I pay less Income and /or Capital Gains Tax than the
    amount of Gift Aid claimed on all my donations in the relevant tax year, it is my responsibility
    to pay any difference. The Gift Aid claimed will be used to help fund the whole of {{ label }}’s
    work.
    <span><a (click)="showMore()" class="cursor-pointer">Read more about gift aid.</a></span>
  </p>

  <p *ngIf="hideText">
    Gift Aid is reclaimed by givebrite from the tax you pay for the relevant tax year. (If you pay
    Income Tax at the higher rate and want to receive the additional tax relief due, you should
    include your Gift Aid donations on your Self-Assessment return or ask HMRC to adjust your tax
    code.) Please contact us if you want to cancel or amend this declaration, if you no longer pay
    sufficient Income and / or Capital Gains Tax or if your address changes. We are required to hold
    your current home address to identify you as a UK taxpayer; if we do not have this we may be
    unable to claim the Gift Aid on your donations.
  </p>

  <!-- <p>
    Please note we can only claim Gift Aid on behalf of individuals.
    <span
      ><a href="#"
        >To pay in money you've raised or collected for {{ label }}, please use this form</a
      ></span
    >.
  </p> -->
</div>
