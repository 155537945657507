<div class="campaign-selector">
  <h4 class="text-center my-5 font-semibold">{{ name }}</h4>

  <form fxLayoutAlign="center center" *ngIf="campaigns.length">
    <mat-form-field>
      <input matInput placeholder="Search campaign" [(ngModel)]="search" name="search" autofocus />
      <mat-icon matSuffix style="color: #c9d4dc">search</mat-icon>
    </mat-form-field>
  </form>

  <!-- team -->
  <!-- <div
    class="w-full"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    *ngIf="type == 'team'"
  >
    <div
      *ngFor="let item of team | filterSearch: search; let i = index"
      fxFlex="28%"
      fxFlex.xs="30%"
      class="m-2"
    >
      <mat-card
        (click)="selectedType(i, team)"
        [ngClass]="item.checked ? 'card-item-shadow' : ''"
        class="mat-elevation-z3 p-0 cursor-pointer rounded overflow-hidden"
        fxLayoutAlign="center center"
      >
        <mat-card-content>
          <div class="overlay">
            <img src="{{ item.cover }}" />
          </div>
          <span
            *ngIf="type == 'team'"
            class="absolute top-12 font-bold text-xs"
            >{{ item.name | titlecase }}</span
          >

          <p class="absolute bottom-0 text-xs w-full" *ngIf="type == 'team'">
            {{ replaceHtml(item.description) }}
          </p>

          <givebrite-tick-mark [checked]="item.checked"></givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
  </div> -->

  <!-- campaign -->
  <div *ngIf="type == 'campaign'">
    <ngx-flicking
      *ngIf="campaigns.length"
      [options]="{ bound: true, align: 'prev' }"
      class="sm:overflow-x-scroll"
    >
      <campaign-card
        flicking-panel
        class="mr-3"
        width="200px"
        *ngFor="let campaign of campaigns"
        [campaign]="campaign"
        [hide]="true"
      ></campaign-card>
    </ngx-flicking>

    <div class="text-center" *ngIf="!campaigns.length">No campaigns found</div>
  </div>

  <!-- <div fxLayoutAlign="center" fxLayout="row" class="my-4">
    <button mat-stroked-button color="primary">Load More</button>
  </div> -->
</div>
