import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountInputComponent } from './amount-input.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencySymbolModule } from 'libs/theming/src/lib/pipes/currency-symbol/currency-symbol.module';
import { MatInputModule } from '@angular/material/input';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [AmountInputComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    CurrencySymbolModule,
    MatInputModule,
    MatFormFieldModule,
    NgxsFormPluginModule,
  ],
  exports: [AmountInputComponent],
})
export class AmountInputModule {}
