import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsComponent } from './teams.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [TeamsComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatCardModule, UserLogoAvatarModule],
  exports: [TeamsComponent],
})
export class TeamsModule {}
