import { getCurrencySymbol } from '@angular/common';
import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';
import { EventEmitter } from 'events';
import { CampaignRg, CampaignStateRg } from 'libs/data/src/lib/ramadhan-giving';
// import { Currencies } from "libs/core/src/lib/injectables/currencies";
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-campaign-basic-form',
  templateUrl: './campaign-basic-form.component.html',
  styleUrls: ['./campaign-basic-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignBasicFormComponent implements OnInit {
  @Input() campaign: CampaignRg;
  @Output() update = new EventEmitter();

  @Select(CampaignStateRg.getCampaign) campaign$: Observable<CampaignRg>;
  @Input() form: FormGroup;

  constructor(
    private fb: FormBuilder // private currencyService: Currencies
  ) {}

  ngOnInit() {
    /*this.form = this.fb.group({
      id: [],
      has_location: [0, Validators.required],
      location: [""],
      name: ["", Validators.required],
      slug: [""],
      goal: ["", [Validators.required, Validators.max(99999999)]],
      hide_goal: [0, Validators.required],
      start: [],
      start_time: ["10:30"],
      is_end_date: [1, Validators.required],
      moment_end: [null, Validators.required],
      end_type: [30],
      end: [null, Validators.required],
      end_time: ["10:30"],
      type: ["recurring", Validators.required],
      currency: ["GBP"],
      charity_search: [""],
      charity_count: [],
      charities: [],
      theme: {
        primary: [],
        secondary: []
      }
    });*/
  }

  get currencies() {
    return '';
    //this.currencyService.getCurrencies();
  }

  save() {
    this.update.emit(null);
  }

  get symbol() {
    return getCurrencySymbol(this.form.get('currency').value, 'narrow');
  }
}
