<view-card [loading]="loading" [title]="'Details'">
    <div class="flex flex-wrap -mx-1 details-table overflow-hidden">
        <div class="w-1/2 overflow-hidden flex-wrap flex">
           
            <table class="w-full text-left table-collapse">
                <thead>
                    <tr>
                        <th colspan="2" class="text-sm border-b border-gray-100  font-semibold text-gray-700 p-2">Donor Info</th> 
                    </tr>
                </thead> 

                <tbody class="align-baseline text-xs">
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Email</td> 
                        <td class="p-2 whitespace-pre">{{ user?.email }}</td>
                    </tr>
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">ID</td> 
                        <td class="p-2 whitespace-pre">{{ user?.id }}</td>
                    </tr>
                </tbody>
            </table>
        
        </div>

        <div class="w-1/2 overflow-hidden flex-wrap flex">
          
            <table class="w-full text-left table-collapse">
                <thead>
                    <tr>
                        <th colspan="2" class="text-sm border-b border-gray-100  font-semibold text-gray-700 p-2">Billing Info</th> 
                    </tr>
                </thead> 

                <tbody class="align-baseline text-xs">
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Address Line 1</td> 
                        <td class="p-2 whitespace-pre">{{ user?.billing?.address_line_1 }}</td>
                    </tr>
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Address Line 2</td> 
                        <td class="p-2 whitespace-pre">{{ user?.billing?.address_line_2 }}</td>
                    </tr>
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Town</td> 
                        <td class="p-2 whitespace-pre">{{ user?.billing?.town }}</td>
                    </tr>
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Postcode</td> 
                        <td class="p-2 whitespace-pre">{{ user?.billing?.postcode }}</td>
                    </tr>
                    <tr>
                        <td class="p-2 text-gray-500 whitespace-no-wrap">Country</td> 
                        <td class="p-2 whitespace-pre">{{ user?.billing?.country }}</td>
                    </tr>
                        
                        
                </tbody>
            </table>
        
        </div>

    </div>
</view-card>

