import { Component, Input, OnInit } from '@angular/core';
import { Charity } from '@givebrite/data';

@Component({
  selector: 'givebrite-charity-logo',
  templateUrl: './charity-logo.component.html',
  styleUrls: ['./charity-logo.component.scss'],
})
export class CharityLogoComponent implements OnInit {
  @Input() charity: Charity;
  @Input() width;
  @Input() showDetail: boolean = false;

  constructor() {}

  ngOnInit() {}
}
