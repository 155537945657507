import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GivebriteState } from 'libs/data/src/lib/app/store/givebrite.state';
import { GivebriteConfig } from 'libs/data/src/lib/app/givebrite.interface';

const AMOUNT_SELECTOR_CLASS_PAIR: { selector: string; classes: string[] }[] = [
  {
    selector: 'hide-currency',
    classes: ['hide-currency'],
  },
];

@Component({
  selector: `givebrite-amount-input, givebrite-amount-input[hide-currency]`,
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AmountInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AmountInputComponent,
    },
  ],
})
export class AmountInputComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;

  /** The label for the amount input*/
  @Input()
  get label(): string {
    return this._label;
  }

  set label(value: string) {
    if (value) {
      this._label = value;
    }
  }

  private _label: string;

  @Input() font: string;

  elementRef: ElementRef;
  _amount: number;

  onChange = (amount: number) => {};
  onTouched = () => {};

  writeValue(amount: number) {
    this.amount = amount;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const amount = control.value;
    if (amount <= 0) {
      return {
        mustBePositive: {
          amount,
        },
      };
    }
  }

  set amount(amount: number) {
    this._amount = amount;
    this.onChange(this.amount);
  }

  get amount() {
    return this._amount;
  }

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  /** Gets whether the given html lement has one of the given attributes. */
  private _hasHostAttributes(elementRef: ElementRef, ...attributes: string[]) {
    return attributes.some((attribute) => elementRef.nativeElement.hasAttribute(attribute));
  }

  ngAfterViewInit() {
    const classList = this.elementRef.nativeElement.children[0]?.classList;
    const selectedAttribute = this.elementRef.nativeElement.querySelector('#currency');
    this.addClassAttributes(classList, selectedAttribute);
  }

  /** Add the class attributes to the given html element*/
  addClassAttributes(classList, selectedAttribute) {
    for (const pair of AMOUNT_SELECTOR_CLASS_PAIR) {
      if (this._hasHostAttributes(this.elementRef, pair.selector)) {
        pair.classes.forEach((className: string) => {
          classList.add(className);
          selectedAttribute.classList.add('hide-currency-container');
        });
      }
    }
  }

  ngOnInit(): void {}
}
