<mat-card
  appearance="outlined"
  class="mat-elevation-z0 p-6 rounded-lg leaderboard-fundraiser-card mb-3"
  *ngIf="fundraiser"
>
  <!-- [class.highlight]="currentFundraiser?._id == fundraiser?._id" -->
  <mat-card-content>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="5px" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="5px" fxLayoutAlign="start">
        <givebrite-user-logo-avatar
          [logo]="fundraiser?.user?.profile_image"
          [name]="fundraiser.user?.first_name + ' ' + fundraiser?.user.last_name"
          [border]="true"
          width="55px"
        ></givebrite-user-logo-avatar>

        <div fxLayout="column" class="mx-width-none" fxLayoutAlign="center start" fxFlex="70%">
          <div class="ml-2">
            <h3 fxLayout="row" (click)="fundraiserPage(fundraiser.slug)" class="mb-0">
              <span class="title cursor-pointer"
                >{{ fundraiser.user.first_name + ' ' + fundraiser.user.last_name | titlecase }}
              </span>
              <mat-icon inline color="primary" *ngIf="fundraiser.user?.profile_completed"
                >check_circle_outline</mat-icon
              >
            </h3>

            <div
              class="leaderboard-position font-normal py-1"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <img src="assets/orange-crown.svg" width="13px" class="mr-1" />
              {{ index + 1 | ordinal }}
            </div>

            <h4 class="font-normal">
              {{ fundraiser.statistics.raised | currency: fundraiser.currency:'symbol':'1.0-1' }}
              <!-- of
            <span class="goal">{{
              fundraiser.goal | currency: fundraiser.currency:'symbol':'1.0-1'
            }}</span> -->
            </h4>
          </div>
        </div>
      </div>

      <givebrite-circular-fundraising-progress
        [fundraiser]="fundraiser"
      ></givebrite-circular-fundraising-progress>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="!fundraiser">
  <mat-card appearance="outlined" class="mat-elevation-z8 p-4 rounded-lg leaderboard-fundraiser-card">
    <ngx-skeleton-loader></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '90%' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ width: '100%' }"></ngx-skeleton-loader>
  </mat-card>
</div>
