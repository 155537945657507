<gb-campaign-section class="overview">
  <div class="overview-description">
    <div
      [innerHTML]="_description"
      class="description"
      [ngClass]="{
        collapsed: isCollapsed,
        hasLength: _description?.length > 200
      }"
    ></div>

    <div fxLayout="row" fxLayoutAlign="center">
      <button
        mat-stroked-button
        color="primary"
        class="read-more w-full showmore"
        (click)="isCollapsed = !isCollapsed"
      >
        <ng-container *ngIf="isCollapsed">Read more</ng-container>
        <ng-container *ngIf="!isCollapsed">Show less</ng-container>
      </button>
    </div>
  </div>
</gb-campaign-section>
