<div
  *ngIf="dialog"
  mat-dialog-title
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <h1>Crop Image</h1>

  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="row">
  <div fxFlex="80%" [attr.mat-dialog-content]="dialog">
    <image-cropper
      class="imageCropper"
      [cropper]="cropperPosition"
      [resizeToWidth]="1280"
      [resizeToHeight]="720"
      [imageURL]="_imageFileUrl"
      [imageFile]="_imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="ratio"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</div>
<div *ngIf="dialog" mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="crop()">Crop Image</button>
</div>
