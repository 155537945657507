import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

let apiLoaded = false;

@Component({
  selector: 'givebrite-video-uploader',
  templateUrl: './video-uploader.component.html',
  styleUrls: ['./video-uploader.component.scss'],
})
export class VideoUploaderComponent implements OnInit {
  url;
  safeUrl: SafeResourceUrl;
  video: {
    type?: string;
    id?: string;
    url?: SafeResourceUrl;
    thumbnail?: string;
  };
  @Output() added: EventEmitter<any> = new EventEmitter();

  constructor(private _sanitizer: DomSanitizer, private http: HttpClient) {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  ngOnInit(): void {}

  // createVideo (url, width, height) {
  //   // Returns an iframe of the video with the specified URL.
  //   var videoObj = this.parseVideo(url);
  //   var $iframe = $('<iframe>', { width: width, height: height });
  //   $iframe.attr('frameborder', 0);
  //   if (videoObj.type == 'youtube') {
  //       $iframe.attr('src', '//www.youtube.com/embed/' + videoObj.id);
  // } else if (videoObj.type == 'vimeo') {
  //     $iframe.attr('src', '//player.vimeo.com/video/' + videoObj.id);
  // }
  //   return $iframe;
  // }

  // get video(): { type?: string; id?: string; url?: SafeResourceUrl } {
  //   if (!this.url) return;
  //   const videoObj = this.parseVideo(this.url);
  //   if (videoObj) {
  //     // console.log(videoObj);
  //     if (videoObj.type == 'youtube') {
  //       return videoObj;
  //       // return this._sanitizer.bypassSecurityTrustResourceUrl(
  //       //   'https://www.youtube.com/embed/' + videoObj.id
  //       // );
  //     } else if (videoObj.type == 'vimeo') {
  //       this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
  //         '//player.vimeo.com/video/' + videoObj.id
  //       );
  //       console.log('vimeo');
  //       return {
  //         ...videoObj,
  //       };
  //     }
  //   }
  // }

  parseVideo(url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    );

    if (RegExp.$3.indexOf('youtu') > -1) {
      var type = 'youtube';
      if (!RegExp.$6 || RegExp.$6.length != 11) {
        // Invalid URL
        return undefined;
      }
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      var type = 'vimeo';
    }

    return {
      type: type,
      id: RegExp.$6,
    };
  }

  validYoutubeUrl(url) {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }

  valueChanges(e) {
    this.url = e.target.value;
    console.log(this.url);
    if (this.url) {
      const videoObj = this.parseVideo(this.url);
      if (videoObj) {
        // console.log(videoObj);
        if (videoObj.type == 'youtube') {
          this.video = {
            ...videoObj,
            thumbnail:
              'https://i3.ytimg.com/vi/' + videoObj.id + '/maxresdefault.jpg',
          };
          this.videoAdded();
        } else if (videoObj.type == 'vimeo') {
          this.http
            .get('http://vimeo.com/api/v2/video/' + videoObj.id + '.json')
            .subscribe((video) => {
              console.log(video);
            });

          this.video = {
            thumbnail: '',
            ...videoObj,
            url: this._sanitizer.bypassSecurityTrustResourceUrl(
              'https://player.vimeo.com/video/' + videoObj.id
            ),
          };
          this.videoAdded();
        }
      }
    } else {
      this.video = undefined;
    }
  }

  getVideoThumbnail(url, cb) {
    // Obtains the video's thumbnail and passed it back to a callback function.
    // if (videoObj.type == 'youtube') {
    //   cb('
    // } else if (videoObj.type == 'vimeo') {
    //     // Requires jQuery
    // }
  }

  videoAdded() {
    console.log('emitting');
    this.added.emit(this.video);
  }
}
