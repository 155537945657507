<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
  <div class="title">
    <span [style]="active">{{
      amount
        ? (amount | currency: currency:'symbol':'1.0-0')
        : (0 | currency: currency:'symbol':'1.0-0')
    }}</span>
    <span class="ml-2 sub-title font-medium">{{ topLeft ? topLeft : '' }}</span>
  </div>

  <div class="goal title">
    {{ topRight ? (topRight | currency: currency:'symbol':'1.0-0') + ' ' + 'goal' : '' }}
  </div>
</div>

<div class="givebrite-progress-bar">
  <mat-progress-bar
    color="primary"
    [mode]="mode"
    [value]="percentage"
    class="campaign-progress-bar"
    [style.height]="height"
  >
  </mat-progress-bar>
</div>

<div class="mt-4" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="sub-title">{{ bottomLeft ? bottomLeft : '' }}</div>
  <div class="sub-title">{{ bottomRight ? bottomRight : '' }}</div>
</div>
