import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'givebrite-tip-dialog',
  templateUrl: './tip-dialog.component.html',
  styleUrls: ['./tip-dialog.component.scss'],
})
export class TipDialogComponent implements OnInit {
  constructor(
    private dialog: MatDialogRef<TipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  onChange() {
    this.dialog.close();
  }

  closeDialog() {
    this.dialog.close();
  }
}
