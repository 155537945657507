import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { UserService } from '../user.service';
import {
  LoginSuccess,
  LoginFailure,
  LoginToken,
  VerifyLoginToken,
  Logout,
  Register,
  RegisterFailure,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordFailure,
  SocialLogin,
  SocialComplete,
  RemoveError,
  SetNewPassword,
  SetNewPasswordSuccess,
  EmailVerification,
  EmailVerificationSuccess,
  EmailVerificationFailure,
  GetUserProfile,
  GetFollowers,
  UploadUserMedia,
  UpdateUserProfile,
  UploadUserProfileMedia,
  Login,
  RemoveAuthSuccessMessage,
  CheckEmailFound,
  SetNewError,
  Newsletter,
  CheckMobileFound,
  LoginText,
  LoginWithCharity,
  LoginWithCharitySuccess,
  BackToSuperAdmin,
} from './auth.actions';
import { AuthService } from '../auth.service';
import { Populate } from './user.actions';
import { RouterState, Navigate } from '@ngxs/router-plugin';
import { UserStateModel } from './user.state';
import { CreateUser, Followers } from '@givebrite/data';
import { ActivatedRoute, Router } from '@angular/router';

export interface AuthStateModel {
  authenticated: boolean;
  loading: boolean;
  error: string;
  successMessage: string;
  role: string;
  user: CreateUser;
  followers: Followers[];
  file: any;
  isEmailExist: boolean;
  isMobileExist: boolean;
  userFile: any;
  email: string;
}

const authStateDefaults: AuthStateModel = {
  authenticated: false,
  role: 'user',
  loading: false,
  error: null,
  successMessage: null,
  user: null,
  followers: [],
  file: null,
  isEmailExist: null,
  isMobileExist: false,
  userFile: null,
  email: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: authStateDefaults,
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @Selector()
  static errors(state: AuthStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: AuthStateModel) {
    return state.loading;
  }

  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static followers(state: AuthStateModel) {
    return state.followers;
  }

  @Selector()
  static successMessage(state: AuthStateModel) {
    return state.successMessage;
  }

  @Selector()
  static isEmailExist(state: AuthStateModel) {
    return state.isEmailExist;
  }

  @Selector()
  static newsletter(state: AuthStateModel) {
    return state.email;
  }
  @Selector()
  static isMobileExist(state: AuthStateModel) {
    return state.isMobileExist;
  }

  @Action(Login)
  login({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.login(payload).pipe(
      map((user) => dispatch(new LoginSuccess(user))),
      catchError((error) => {
        if (
          error[0] === 'Your password has expired. Please check your email for password reset Link.'
        ) {
          dispatch(
            new Navigate(
              ['/signin/reset-password-instruction'],
              { email: payload?.email },
              { queryParamsHandling: 'merge' }
            )
          );
        }
        return dispatch(new RegisterFailure(error));
      })
    );
  }
  
  
  @Action(LoginWithCharity)
  loginWithCharity({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.loginWithCharity(payload).pipe(
      map((user) => dispatch(new LoginWithCharitySuccess(user))),
      catchError((error) => {
        if (
          error[0] === 'Your password has expired. Please check your email for password reset Link.'
        ) {
          dispatch(
            new Navigate(
              ['/signin/reset-password-instruction'],
              { email: payload?.email },
              { queryParamsHandling: 'merge' }
            )
          );
        }
        return dispatch(new RegisterFailure(error));
      })
    );
  }

  @Action(LoginToken)
  loginToken({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.loginToken(payload).pipe(
      map((res: any) => {
        patchState({ loading: false });
        dispatch(
          new Navigate(
            ['/signin/login-confirmation'],
            { email: payload.email },
            { queryParamsHandling: 'merge' }
          )
        );
      }),
      catchError((error) => {
        return dispatch(new LoginFailure(error));
      })
    );
  }

  @Action(VerifyLoginToken)
  verifyLoginToken({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.verifyLoginToken(payload).pipe(
      map((user) => dispatch(new LoginSuccess(user))),
      catchError((error) => {
        patchState({ loading: false, error: error });
        return dispatch(new Navigate(['/'], {}, { queryParamsHandling: 'preserve' }));
        // return dispatch(new LoginFailure(error));
      })
    );
  }

  @Action(Register)
  register({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.register(payload).pipe(
      map((user) => dispatch(new LoginSuccess(user))),
      catchError((error) => {
        return dispatch(new LoginFailure(error));
      })
    );
  }

  @Action(ResetPassword)
  reset({ patchState, dispatch }: StateContext<AuthStateModel>, { payload, resendEmail }) {
    patchState({ loading: true });
    return this.authService.reset(payload).pipe(
      tap((user) => {
        if (resendEmail === false) {
          dispatch(new ResetPasswordSuccess(payload));
        } else {
          patchState({
            loading: false,
            error: null,
            successMessage: 'Email Resent Successfully.',
          });
        }
      }),
      catchError((error) => {
        return dispatch(new ResetPasswordFailure(error));
      })
    );
  }

  @Action(LoginSuccess)
  loginSuccess({ dispatch, patchState }: StateContext<AuthStateModel>, action: LoginSuccess) {
    patchState({
      error: null,
      loading: false,
    });

    // Save the JWT in the cookies
    this.authService.setJwt(action.payload.accessToken);

    // Populate the user state with user data and navigate back to root which will redirect the user
    dispatch(new Populate()).subscribe(() => {
      // window.location.href = 'http://givebrite.local:4200';
      // window.location.href = this.route.snapshot.queryParams.returnUrl;
      dispatch(new Navigate(['/'], {}, { queryParamsHandling: 'preserve' }));
    });
    // dispatch(new Navigate(givebrite']));
  }
  @Action(LoginWithCharitySuccess)
  loginWithCharitySuccess({ dispatch, patchState }: StateContext<AuthStateModel>, action: LoginWithCharitySuccess) {
    patchState({
      error: null,
      loading: false,
    });

    // Save the JWT in the cookies
    this.authService.setJwtSuper(this.authService.jwt);
    this.authService.setJwt(action.payload.result.accessToken);

    dispatch(new Populate()).subscribe(() => {
      window.location.reload();
    });
  }
  @Action(BackToSuperAdmin)
  backToSuperAdmin({ dispatch, patchState }: StateContext<AuthStateModel>) {
    patchState({
      error: null,
      loading: false,
    });

    // Save the JWT in the cookies
    this.authService.setJwt(this.authService.jwtSuper);
    this.authService.removeJwtSuper()
    window.location.reload();
  }

  @Action(ResetPasswordSuccess)
  resetPasswordSuccess(
    { dispatch, patchState }: StateContext<AuthStateModel>,
    action: ResetPasswordSuccess
  ) {
    patchState({
      error: null,
      loading: false,
    });
    dispatch(
      new Navigate(
        ['/signin/reset-confirmation'],
        { email: action?.payload?.email },
        { queryParamsHandling: 'merge' }
      )
    );
  }

  @Action(LoginFailure)
  loginFailure({ patchState }: StateContext<AuthStateModel>, action: LoginFailure) {
    patchState({
      error: action.payload[0],
      loading: false,
    });
  }

  @Action(ResetPasswordFailure)
  resetPasswordFailure({ patchState }: StateContext<AuthStateModel>, action: ResetPasswordFailure) {
    patchState({
      error: action.payload[0],
      loading: false,
    });
  }

  @Action(RegisterFailure)
  registerFailure({ patchState }: StateContext<AuthStateModel>, action: RegisterFailure) {
    patchState({
      error: action.payload[0],
      loading: false,
    });
  }

  @Action(Populate)
  populate({ patchState, dispatch }: StateContext<AuthStateModel>, action: LoginFailure) {
    if (!this.authService.authenticated) {
      return dispatch(new Logout());
    }
    return patchState({
      authenticated: this.authService.authenticated,
      role: this.authService.userRole,
    });
  }

  @Action(Logout)
  logout({ patchState }: StateContext<AuthStateModel>) {
    this.authService.logout();

    patchState({
      loading: false,
      authenticated: false,
    });
  }

  @Action(SocialComplete)
  socialComplete({ patchState, dispatch }: StateContext<AuthStateModel>, { credentials }) {
    patchState({ loading: true });
    return this.authService.socialComplete(credentials).pipe(
      map((user) => dispatch(new LoginSuccess(user))),
      catchError((error) => {
        return dispatch(new RegisterFailure(error));
      })
    );
  }

  @Action(SocialLogin)
  socialLogin({ patchState, dispatch }: StateContext<UserStateModel>, { credentials }) {
    patchState({ loading: true });
    return this.authService.socialLogin(credentials).pipe(
      tap((response) => {
        if (!response.accessToken) {
          credentials = {
            ...credentials,
            token: response.token,
          };
          patchState({
            loading: false,
            user: credentials,
          });
          return dispatch(
            new Navigate(['/signin/register'], {}, { queryParamsHandling: 'preserve' })
          );
        } else {
          return dispatch(new LoginSuccess(response));
        }
      }),
      catchError((error) => {
        return dispatch(new RegisterFailure(error));
      })
    );
  }

  @Action(SetNewError)
  setNewError({ patchState }: StateContext<AuthStateModel>, { error }) {
    patchState({
      error: error,
    });
  }

  @Action(RemoveError)
  removeError({ patchState }: StateContext<AuthStateModel>) {
    patchState({
      error: null,
    });
  }

  @Action(SetNewPassword)
  setNewPassword({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.setNewPassword(payload).pipe(
      tap((user) => dispatch(new LoginSuccess(user))),
      tap(() => dispatch(new SetNewPasswordSuccess())),
      catchError((error) => {
        return dispatch(new ResetPasswordFailure(error));
      })
    );
  }

  @Action(SetNewPasswordSuccess)
  setNewPasswordSuccess({ dispatch, patchState }: StateContext<AuthStateModel>) {
    patchState({
      error: null,
      loading: false,
    });
    dispatch(
      new Navigate(['/signin/forgot-confirmation'], {}, { queryParamsHandling: 'preserve' })
    );
  }

  @Action(EmailVerification)
  emailVerification({ dispatch, patchState }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.verifyEmail(payload).pipe(
      map((data) => {
        return dispatch(new EmailVerificationSuccess(data));
      }),
      catchError((error) => {
        return dispatch(new EmailVerificationFailure(error));
      })
    );
  }

  @Action(EmailVerificationSuccess)
  emailVerificationSuccess(
    { dispatch, patchState }: StateContext<AuthStateModel>,
    action: EmailVerificationSuccess
  ) {
    patchState({
      error: null,
      loading: false,
    });
    this.authService.setJwt(action.payload.accessToken);
    dispatch(new Populate()).subscribe(() => {
      dispatch(new Navigate(['/'], {}, { queryParamsHandling: 'preserve' }));
    });
  }

  @Action(EmailVerificationFailure)
  emailVerificationFailure(
    { patchState }: StateContext<AuthStateModel>,
    action: EmailVerificationFailure
  ) {
    patchState({
      error: action.payload && action.payload[0],
      loading: false,
    });
    setTimeout(() => {
      patchState({
        error: null,
        loading: false,
      });
    }, 4000);
  }

  @Action(GetUserProfile)
  getUserProfile({ getState, setState, patchState }: StateContext<AuthStateModel>, { id }) {
    patchState({ loading: true });
    return this.authService.getUserProfile().pipe(
      tap((result: { user: any; error: any }) => {
        if (result.error == undefined) {
          const state = getState();
          setState({
            ...state,
            user: result.user,
            loading: false,
            error: null,
          });
        } else {
          patchState({
            user: null,
            loading: false,
            error: result.error,
          });
        }
      })
    );
  }

  @Action(GetFollowers)
  getUserFollowers({ patchState }: StateContext<AuthStateModel>) {
    patchState({ loading: true });
    return this.authService.getUserFollowers().pipe(
      tap((result: Followers[]) => {
        patchState({
          followers: result,
          loading: false,
        });
      })
    );
  }

  @Action(UploadUserMedia)
  upload(
    { getState, setState, patchState }: StateContext<AuthStateModel>,
    { file, charityId }: UploadUserMedia
  ) {
    patchState({ loading: true });
    return this.authService.uploadMedia(file, charityId).pipe(
      tap((result) => {
        patchState({
          file: result,
          loading: false,
        });
      })
    );
  }

  @Action(UploadUserProfileMedia)
  uploadProfile(
    { patchState }: StateContext<AuthStateModel>,
    { file, userId }: UploadUserProfileMedia
  ) {
    patchState({ loading: true });
    return this.authService.uploadUserMedia(file, userId).pipe(
      tap((result) => {
        patchState({
          userFile: result,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateUserProfile)
  updateProfile({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.updateUserProfile(payload).pipe(
      tap(
        (user) => {
          patchState({
            loading: false,
          });
          this.authService.setJwt(user.accessToken);
          dispatch(new Populate());
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }

  @Action(RemoveAuthSuccessMessage)
  removeSuccessMessage({ patchState }: StateContext<AuthStateModel>) {
    patchState({
      successMessage: null,
    });
  }

  @Action(CheckEmailFound)
  checkEmailFound({ patchState }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.checkEmailFound(payload).pipe(
      tap(
        (response) => {
          patchState({
            isEmailExist: response,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(Newsletter)
  newsletter({ patchState }: StateContext<AuthStateModel>, { email }) {
    patchState({ loading: true });
    return this.authService.newsletter(email).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            email: result,
          });
        },
        (error) => {
          patchState({
            loading: false,
            email: error,
          });
        }
      )
    );
  }
  @Action(CheckMobileFound)
  CheckMobileFound({ patchState }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.checkPhoneFound(payload).pipe(
      tap(
        (response) => {
          patchState({
            
            isMobileExist: true,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            isMobileExist:false,
            loading: false,
          });
        }
      )
    );
  }

  @Action(LoginText)
  logintext({ patchState, dispatch }: StateContext<AuthStateModel>, { payload }) {
    patchState({ loading: true });
    return this.authService.logintext(payload).pipe(
      map((user) => dispatch(new LoginSuccess(user))),
      catchError((error) => {
        if (
          error[0] === 'Your password has expired. Please check your email for password reset Link.'
        ) {
          dispatch(
            new Navigate(
              ['/signin/reset-password-instruction'],
              { email: payload?.email },
              { queryParamsHandling: 'merge' }
            )
          );
        }
        return dispatch(new RegisterFailure(error));
      })
    );
  }

}
