import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { MediaUploaderComponent } from 'libs/core/src/lib/campaigns/campaign-cover-uploader/media-uploader/media-uploader.component';
import { UploadSingleFile } from 'libs/data/src/lib/charity/store/charity.actions';
import { AuthService } from 'libs/data/src/lib/user/auth.service';
import { UploadUserMedia, UploadUserProfileMedia } from 'libs/data/src/lib/user/store/auth.actions';
import { UploadFile } from 'ngx-uploader';

interface UploadFileWithPreview extends UploadFile {
  imagePreview?: string;
}

@UntilDestroy()
@Component({
  selector: 'givebrite-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AvatarUploaderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AvatarUploaderComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarUploaderComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() charity;
  @Input() userId;
  @Input() type = 'Campaign';
  file: string; // FILE URL
  // NGXS FORM
  onChange = (cover: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor(private store: Store, private auth: AuthService, private dialog: MatDialog) {}

  ngOnInit(): void {}

  writeValue(file: string) {
    if (file) {
      this.file = file;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  uploading: boolean;
  upload(image) {
    this.uploading = true;
    let action;

    //1) CHARITY
    if (this.charity) {
      action = new UploadSingleFile(image.cropped.formData, this.charity);

      if (!this.auth.hasCharity) {
        action = new UploadUserMedia(image.cropped.formData, this.charity);
      }
    }
    // 2) USER
    else {
      action = new UploadUserProfileMedia(image.cropped.formData, this.userId);
    }

    this.store.dispatch(action).subscribe((store) => {
      if (this.auth.hasCharity) {
        this.file = this.charity ? store.charity.file.url : store.account.auth.userFile.url;
      } else {
        this.file = this.charity ? store.account.auth.file.url : store.account.auth.userFile.url;
      }

      this.uploading = false;
      this.onChange(this.file);
    });
  }

  remove() {
    this.file = undefined;
    this.onChange(this.file);
  }

  openMediaUpload() {
    this.dialog
      .open(MediaUploaderComponent, {
        width: '70%',
        data: { type: 'avatar', only: true },
      })
      .afterClosed()
      .subscribe((media) => {
        console.log(media);
        if (media) {
          if (media.type == 'image') {
            // this.file = media.file;
            this.upload(media);
          }
        } else {
          console.log(media);
        }
      });
  }
}
