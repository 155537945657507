import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Steppper } from '../create.interface';

@Component({
  selector: 'givebrite-campaign-stepper[steps]',
  templateUrl: './campaign-stepper.component.html',
  styleUrls: ['./campaign-stepper.component.scss'],
})
export class CampaignStepperComponent implements OnInit {
  @Input() steps: Steppper[];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  get active() {
    return this.route.firstChild.snapshot.url[0].path;
  }

  get currentIndex() {
    return this.steps.findIndex((step) => step.path == this.active);
  }

  navigate(path) {
    // this.router.navigate([`/create/${path}`]);
  }
}
