<div
  class="campaign-donation"
  *ngIf="{ results: donations, loader: loading$ | async } as donationList"
>
  <!-- LOADER -->
  <ng-container *ngIf="donationList.loader">
    <givebrite-donation-item-list
      *ngFor="let donation of [].constructor(10)"
      [donation]="donation"
    ></givebrite-donation-item-list>
  </ng-container>

  <!-- NOT FOUND -->
  <p *ngIf="!donationList.loader && !donationList.results?.length" class="py-4">
    No donation to show
  </p>

  <!-- DONATION LIST -->
  <ng-container *ngIf="!donationList.loader">
    <div
      *ngFor="let donation of donationList.results; let i = index; let last = last"
      class="donation-items"
    >
      <!-- DONATION LIST VIEW -->
      <givebrite-donation-item-list
        *ngIf="listView"
        [last]="last"
        [donation]="donation"
      ></givebrite-donation-item-list>

      <!-- DONATION CARD VIEW -->
      <givebrite-donation-item-card
        *ngIf="cardView"
        [last]="last"
        [donation]="donation"
      ></givebrite-donation-item-card>

      <!-- DONATION WORDS SUPPORT -->
      <givebrite-donation-words-support
        *ngIf="wordsView"
        [last]="last"
        [donation]="donation"
      ></givebrite-donation-words-support>

      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="mt-4"
        *ngIf="i == donationList.results.length - 1"
      >
        <button
          mat-stroked-button
          color="primary"
          class="w-full"
          *ngIf="!maxDonationLimitReached"
          [class.disabled]="loading"
          (click)="loadMoreDonations()"
        >
          {{ loading ? 'loading...' : 'Load More' }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
