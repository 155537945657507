import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignDetailsEditorComponent } from './campaign-details-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [CampaignDetailsEditorComponent],
  imports: [CommonModule, EditorModule, ReactiveFormsModule, FormsModule],
  exports: [CampaignDetailsEditorComponent],
})
export class CampaignDetailsEditorModule {}
