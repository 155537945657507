import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { GetCampaignDonations } from 'libs/data/src/lib/campaign/store/campaign.actions';
import { GetFundraiserDonations } from 'libs/data/src/lib/campaign/store/fundraiser.actions';
import { GetTeamDonations } from 'libs/data/src/lib/campaign/store/team.actions';
import { Donation } from 'libs/data/src/lib/donation/donation.interface';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'givebrite-trending-donations',
  templateUrl: './trending-donations.component.html',
  styleUrls: ['./trending-donations.component.scss'],
})
export class TrendingDonationsComponent implements OnInit {
  @Input() page: string;
  @Input() limit: number;
  @Input() fundraiserId: string;
  @Input() campaignId: string;
  @Input() teamId: string;

  trendingDonations: Donation[] = [];
  loading = false;
  public DATE_FORMAT = 'yyyy-MM-DD';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getTrendingDonations(this.page);
  }

  get todaysDate() {
    return moment().format(this.DATE_FORMAT);
  }

  get lastOneDate() {
    return moment().subtract(1, 'd').format(this.DATE_FORMAT);
  }

  /**
   * @description
   * GET TRENDING DONATIONS BASED UPON THE PAGE (campaign/fundraiser/team)
   * @param page
   */
  getTrendingDonations(page: string): void {
    switch (page) {
      case 'fundraiser':
        this.store
          .dispatch(
            new GetFundraiserDonations(
              this.limit,
              this.fundraiserId,
              1,
              this.todaysDate,
              this.lastOneDate
            )
          )
          .pipe(untilDestroyed(this))
          .subscribe((store) => {
            this.trendingDonations = [...store.fundraiser.donations];
          });
        break;

      case 'team':
        this.store
          .dispatch(
            new GetTeamDonations(this.teamId, this.limit, 1, this.todaysDate, this.lastOneDate)
          )
          .subscribe((store) => {
            this.trendingDonations = [...store.team.donations];
          });
        break;

      default:
        this.store
          .dispatch(
            new GetCampaignDonations(
              this.limit,
              this.campaignId,
              1,
              this.todaysDate,
              this.lastOneDate
            )
          )
          .pipe(untilDestroyed(this))
          .subscribe((store) => {
            this.trendingDonations = [...store.campaign.donations];
          });
        break;
    }
  }
}
