import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { DashboardState } from './dashboard.state';

@NgModule({
  declarations: [],
    imports: [
      CommonModule,
      NgxsModule.forFeature([DashboardState])
    ],
})
export class DashboardStoreModule {}
