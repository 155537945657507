<div class="campaign-details mt-10">
  <!-- <div class="mb-5">
    <givebrite-theme-colors [form]="form"></givebrite-theme-colors>
  </div> -->

  <form [formGroup]="form">
    <!-- CAMPAIGN NAME -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
      <p class="mb-2 sm:mt-4">Campaign Name</p>

      <mat-form-field class="w-full sm:w-4/6">
        <mat-label>Campaign Name</mat-label>
        <input matInput placeholder="Campaign Name" required formControlName="name" />
      </mat-form-field>
    </div>

    <!-- CAMPAIGN URL -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
      <p class="mb-2 sm:mt-4">Campaign URL</p>

      <gb-campaign-url
        class="w-full sm:w-4/6"
        formControlName="slug"
        [link]="'https://campaigns.givebrite.com/'"
      ></gb-campaign-url>
    </div>

    <!-- CAMPAIGN AMOUNT -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
      <p class="mb-2 sm:mt-4">Amount</p>

      <givebrite-campaign-amount
        class="w-full sm:w-4/6"
        [impacts]="false"
        formControlName="goal"
      ></givebrite-campaign-amount>
    </div>

    <!-- CAMPAIGN DESCRIPTION -->
    <givebrite-campaign-details-editor
      formControlName="description"
      [environment]="environment"
    ></givebrite-campaign-details-editor>

    <div>
      <div class="mt-3 campaign-settings mat-elevation-z4" fxLayout="column">
        <campaign-slide-toggle
          class="campaign-setting"
          [formControl]="form.get('settings').get('is_end_date')"
        >
          <div>Enable End Date</div>
          <!-- END DATE -->
          <div
            slide-toggle-section
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="space-between start"
            formGroupName="dates"
          >
            <p class="mb-2 sm:mt-4">End Date</p>

            <mat-form-field class="w-full sm:w-4/6">
              <mat-label>Choose A End Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [min]="todayDate"
                formControlName="end_date"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </campaign-slide-toggle>

        <ng-container formGroupName="settings">
          <!-- Zakat -->
          <campaign-slide-toggle class="campaign-setting" formControlName="is_zakat"
            >Enable Zakat
          </campaign-slide-toggle>

          <!-- Sadaqah Jariyah -->
          <campaign-slide-toggle class="campaign-setting" formControlName="is_sadaqah_jariyah"
            >Enable Sadaqah Jariyah
          </campaign-slide-toggle>

          <!-- Team Fundraising -->
          <campaign-slide-toggle
            *ngIf="type == 'p2p'"
            class="campaign-setting"
            formControlName="is_teams"
            >Enable Team Fundraising
          </campaign-slide-toggle>

          <!-- Team Fundraising -->
          <!-- <campaign-slide-toggle
          class="campaign-setting last"
          formControlName="is_auctions"
          >Enable Auctions
        </campaign-slide-toggle> -->

          <!-- Impacts -->

          <!-- <campaign-slide-toggle class="campaign-setting last" formControlName="is_impacts">
            <div slide-toggle-content>Enable Impacts</div>
            <givebrite-campaign-add-impacts
              slide-toggle-section
              [form]="form"
            ></givebrite-campaign-add-impacts>
          </campaign-slide-toggle> -->
        </ng-container>

        <!-- Phased Fundraising -->
        <!-- <campaign-slide-toggle
          class="campaign-setting"
          formControlName="is_phases"
          >Phased Fundraising
          <givebrite-campaign-add-phases
            slide-toggle-section
            [form]="form"
          ></givebrite-campaign-add-phases>
        </campaign-slide-toggle> -->

        <!-- Team Sponsors -->
        <!-- <campaign-slide-toggle
          class="campaign-setting last"
          formControlName="is_sponsors"
          >Enable Sponsors
          <givebrite-campaign-add-sponsors
            slide-toggle-section
            [form]="form"
          ></givebrite-campaign-add-sponsors>
        </campaign-slide-toggle> -->
      </div>

      <div class="p-6"></div>
    </div>

    <!-- CAMPAIGN THEME -->
    <!--
    <div class="mat-elevation-z3 preview mx-auto rounded py-6 mt-5">
      <h4 fxLayoutAlign="center center">See Preview</h4>
    </div> -->
  </form>
</div>
