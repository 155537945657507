import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Donation } from '../../donation';
import { Pagination } from '../../pagination/pagination.interface';
import { defaultPaginationState } from '../../pagination/pagination.state';
import { CampaignService } from '../campaign.service';
import { Fundraiser, OfflineDonation } from '../fundraiser.interface';
import { FundraiserService } from '../fundraiser.service';
import {
  CreateFundraiser,
  GetCampaignFundraiser,
  GetCampaignFundraisers,
  GetFundraisers,
  UpdateFundraiser,
  GetFundraiserDonations,
  AddOfflineDonations,
  GetFundraisersForSearch,
} from './fundraiser.actions';

export class FundraiserStateModel {
  loading?: boolean;
  error?: any;
  pagination: Pagination;
  donationPagination: Pagination;
  list: Fundraiser[];
  fundraisers: Fundraiser[];
  fundraiser: CreateFundraiser;
  campaignFundraiser: Fundraiser;
  donations: Donation[];
  offlineDonation: OfflineDonation;
  spinner: boolean;
  topFundraiserLoader?: boolean;
  fundraiserDonatePagination: Pagination;
  campaignFundraisersPagination: Pagination;
  fundraisersPagination: Pagination;
}

@State<FundraiserStateModel>({
  name: 'fundraiser',
  defaults: {
    loading: true,
    error: null,
    pagination: defaultPaginationState(),
    donationPagination: defaultPaginationState(),
    list: [],
    fundraisers: [],
    fundraiser: null,
    campaignFundraiser: null,
    donations: [],
    offlineDonation: null,
    spinner: false,
    topFundraiserLoader: false,
    fundraiserDonatePagination: null,
    campaignFundraisersPagination: null,
    fundraisersPagination: null,
  },
})
@Injectable()
export class FundraiserState {
  constructor(
    private campaignService: CampaignService,
    private fundraiserService: FundraiserService
  ) {}

  @Selector()
  static pagination(state: FundraiserStateModel) {
    return state.pagination;
  }

  @Selector()
  static donationPagination(state: FundraiserStateModel) {
    return state.donationPagination;
  }

  @Selector()
  static loading(state: FundraiserStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: FundraiserStateModel) {
    return state.error;
  }

  @Selector()
  static fundraisers(state: FundraiserStateModel) {
    return state.fundraisers;
  }
  @Selector()
  static fundraisersList(state: FundraiserStateModel) {
    return state.list;
  }

  @Selector()
  static fundraiser(state: FundraiserStateModel) {
    return state.campaignFundraiser;
  }

  @Selector()
  static OfflineDonation(state: FundraiserStateModel) {
    return state.offlineDonation;
  }

  @Selector()
  static spinner(state: FundraiserStateModel) {
    return state.spinner;
  }

  @Selector()
  static topFundraiserLoader(state: FundraiserStateModel) {
    return state.topFundraiserLoader;
  }

  @Selector()
  static fundraiserDonatePagination(state: FundraiserStateModel) {
    return state.fundraiserDonatePagination;
  }

  @Selector()
  static campaignFundraisersPagination(state: FundraiserStateModel) {
    return state.campaignFundraisersPagination;
  }

  @Selector()
  static fundraisersPagination(state: FundraiserStateModel) {
    return state.fundraisersPagination;
  }

  @Action(GetCampaignFundraisers)
  getFundraisers(
    { patchState, dispatch }: StateContext<FundraiserStateModel>,
    { filter_by, campaignId, limit, search }
  ) {
    patchState({ loading: true, spinner: true, topFundraiserLoader: true });
    return this.campaignService.fundraisers(filter_by, campaignId, limit, search).pipe(
      tap(
        (result: { fundraisers: Fundraiser[]; pagination: Pagination }) => {
          patchState({
            fundraisers: result.fundraisers,
            loading: false,
            campaignFundraisersPagination: result.pagination,
            pagination: result.pagination,
            spinner: false,
            topFundraiserLoader: false,
          });
          // dispatch(new AddPagination(result.pagination));
        },
        (error) => {
          patchState({
            fundraisers: [],
            loading: false,
            campaignFundraisersPagination: null,
            pagination: null,
            spinner: false,
            topFundraiserLoader: false,
          });
        }
      )
    );
  }

  @Action(GetFundraisers)
  getFundraisersList(
    { getState, setState, patchState }: StateContext<FundraiserStateModel>,
    { filter_by, value, page, limit, orderby, search, title_sort, sort_by }
  ) {
    patchState({ loading: true, spinner: true, topFundraiserLoader: true });
    return this.fundraiserService
      .fundraisersList(filter_by, value, page, limit, orderby, search, title_sort, sort_by)
      .pipe(
        tap((result: { fundraisers: Fundraiser[]; pagination: Pagination }) => {
          const state = getState();
          setState({
            ...state,
            loading: false,
            fundraisers: result.fundraisers,
            fundraisersPagination: result.pagination,
            pagination: result.pagination,
            spinner: false,
            topFundraiserLoader: false,
          });
        })
      );
  }

  /**
   * create fundraiser
   */
  @Action(CreateFundraiser)
  createFundraiser({ patchState }: StateContext<FundraiserStateModel>, { payload }) {
    patchState({ loading: true });
    return this.fundraiserService.createFundraiser(payload).pipe(
      tap((response: CreateFundraiser) => {
        patchState({ loading: false, fundraiser: response });
      })
    );
  }

  /**
   * Update fundraiser
   */
  @Action(UpdateFundraiser)
  updateFundraiser({ patchState }: StateContext<FundraiserStateModel>, { payload }) {
    patchState({ loading: true });
    return this.fundraiserService.update(payload).pipe(
      tap((response: UpdateFundraiser) => {
        patchState({ loading: false, fundraiser: response });
      })
    );
  }

  /**
   *  fundraiser Donations
   */
  @Action(GetFundraiserDonations)
  getFundraiserDonations(
    { patchState }: StateContext<FundraiserStateModel>,
    { limit, fundraiserId, page, from_date, to_date }
  ) {
    patchState({ loading: true });
    return this.campaignService
      .donations(fundraiserId, 'fundraiser', limit, page, from_date, to_date)
      .pipe(
        tap(
          (result: { donations: Donation[]; pagination: Pagination }) => {
            patchState({
              donations: result.donations,
              fundraiserDonatePagination: result.pagination,
              donationPagination: result.pagination,
              loading: false,
            });
          },
          (error) => {
            patchState({
              donations: [],
              loading: false,
            });
          }
        )
      );
  }

  /**
   * fundraiser page
   */

  @Action(GetCampaignFundraiser)
  getFundraiser({ patchState }: StateContext<FundraiserStateModel>, { id, type }) {
    patchState({ loading: true });
    return this.fundraiserService.fundraiser(id, type).pipe(
      tap((result: Fundraiser) => {
        patchState({
          campaignFundraiser: result,
          loading: false,
        });
      })
    );
  }

  /**
   * Add Offline Donation
   */
  @Action(AddOfflineDonations)
  addOfflineDonation({ patchState }: StateContext<FundraiserStateModel>, { payload }) {
    patchState({ loading: true });
    return this.fundraiserService.addOfflineDonation(payload).pipe(
      tap((result: OfflineDonation) => {
        patchState({
          offlineDonation: result,
          loading: false,
        });
      })
    );
  }

  @Action(GetFundraisersForSearch)
  getFundraisersForSearch(
    { getState, setState, patchState }: StateContext<FundraiserStateModel>,
    { filter_by, value, page, limit, orderby, search, title_sort, sort_by }
  ) {
    patchState({ loading: true });
    return this.fundraiserService
      .fundraisersList(filter_by, value, page, limit, orderby, search, title_sort, sort_by)
      .pipe(
        tap((result: { fundraisers: Fundraiser[]; pagination: Pagination; error }) => {
          if (result.error == undefined) {
            let fundraisers: Fundraiser[] = result.fundraisers;
            // if (page > 1) {
            //   fundraisers = getState().list.concat(result.fundraisers);
            // }
            // const state = getState();
            patchState({
              // ...state,
              loading: false,
              list: fundraisers,
              pagination: result.pagination,
              error: null,
            });
          } else {
            patchState({
              list: [],
              loading: false,
              pagination: null,
              error: result.error,
            });
          }
        })
      );
  }
}
