import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginSuccess } from '@givebrite/data';
import { Store } from '@ngxs/store';

import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'user-login',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetCookieComponent implements OnInit {
  constructor(
    private cookies: CookieService,
    @Inject('environment') private environment,
    private route: ActivatedRoute,
    private store: Store
  ) {
    console.log('setting cookie');
    const query = this.route.snapshot.queryParams;
    if (query.jwt) {
      this.cookies.put('jwt', query.jwt, {
        domain: this.environment.domain,
      });
      this.store.dispatch(new LoginSuccess({ accessToken: query.jwt }));
    }
  }

  ngOnInit() {
    // this.stateError$.pipe(
    // filter((data) => data != null))
    // .subscribe((error) => {
    //   this.snackbar.open(error, "OK", {
    //      duration: 10000
    //    });
    // })
  }
}
