import { Component, OnInit } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'givebrite-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
 
  distance: false;
  days;
  hours;
  minutes;
  seconds;
  started: boolean;
  ramadhancountdownborder = 'https://gb-downloads.s3.eu-west-1.amazonaws.com/assets/ramadhancountdownborder.png';

  public timeLeft$: Observable<any>;
  constructor() {
    this.timeLeft$ = interval(1000).pipe(
      map((x) => this.calcDateDiff(this.startOrEndOfRamadhan())),
      shareReplay(1)
    );
  }

  ngOnInit(): void {}

  calcDateDiff(endDay: Date = new Date()) {
    const countDownDate = endDay.getTime();
    // console.log(countDownDate);
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const daysToDday = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hoursToDday = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesToDday = Math.floor(
      (distance % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsToDday = Math.floor((distance % (1000 * 60)) / 1000);
    // console.log({ secondsToDday, minutesToDday, hoursToDday, daysToDday });
    return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
  }

  startOrEndOfRamadhan() {
    let start = new Date('2022-04-01 20:15:00 '.replace(/-/g, '/'));
    let end = new Date('2022-05-01 21:15:00 '.replace(/-/g, '/'));
    let now = new Date();
    if (now.getTime() > start.getTime()) {
      this.started = true;
      return end;
    } else {
      return start;
    }
  }
}