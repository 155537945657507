import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
// import {
//   UploadOutput,
//   UploadInput,
//   UploadFile,
//   humanizeBytes,
//   UploaderOptions,
// } from 'ngx-uploader';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'apps/campaigns/src/environments/environment';
import { CropperPosition, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { timeStamp } from 'node:console';
import { MediaUploaderComponent } from '../campaign-cover-uploader/media-uploader/media-uploader.component';
import { AuthService, UploadUserMedia } from 'libs/data/src/lib/user';
import { UploadSingleFile } from 'libs/data/src/lib/charity';

// interface UploadFileWithPreview extends UploadFile {
//   imagePreview?: string;
// }

let apiLoaded = false;
@UntilDestroy()
@Component({
  selector: 'givebrite-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AvatarUploaderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AvatarUploaderComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarUploaderComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() charity;
  @Input() userId;
  @Input() width = 100;
  @Input() type: string = 'Campaign';
  file: string; // FILE URL
  // NGXS FORM
  onChange = (cover: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor(
    private store: Store,
    private auth: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  writeValue(file: string) {
    if (file) {
      this.file = file;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  uploading: boolean;
  upload(image) {
    this.uploading = true;
    let action = new UploadSingleFile(image.cropped.formData, this.charity);
    if (!this.auth.hasCharity) {
      action = new UploadUserMedia(image.cropped.formData, this.charity);
    }

    this.store.dispatch(action).subscribe((store) => {
      if (this.auth.hasCharity) {
        this.file = store.charity.file.url;
      } else {
        this.file = store.account.auth.file.url;
      }

      this.uploading = false;
      this.onChange(this.file);
    });
  }

  remove() {
    this.file = undefined;
    this.onChange(this.file);
  }

  openMediaUpload() {
    this.dialog
      .open(MediaUploaderComponent, {
        width: '70%',
        data: { type: 'avatar', only: true },
      })
      .afterClosed()
      .subscribe((media) => {
        console.log(media);
        if (media) {
          if (media.type == 'image') {
            // this.file = media.file;
            this.upload(media);
          }
        } else {
          console.log(media);
        }
      });
  }
}
