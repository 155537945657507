import { Injectable } from '@angular/core';
import { CampaignRg } from '../campaign';
import { UserRg } from '../user';
import { CharityRg } from '../charity';
import { PaymentMethod } from '../user/user.interface';

export enum DonationFields {
  subscription_id = 'id',
  donation_id = 'donation_id',
  is_recurring_donation_id = 'is_recurring_donation_id',
  recurring_donation_id = 'recurring_donation_id',
  amount = 'amount',
  is_gift_aid = 'is_gift_aid',
  gift_aid_amount = 'gift_aid_amount',
  date = 'date',
  time = 'time',
  is_offline = 'is_offline',
  is_zakat = 'is_zakat',
  is_anonymous = 'is_anonymous',
  is_guest = 'is_guest',
  status = 'status',
  comments = 'comments',

  // Recurring Fields
  period = 'period',

  // Payment Fields
  charged_amount = 'charged_amount',
  currency = 'currency',
  gateway = 'gateway',
  transaction_id = 'transaction_id',
  charged = 'final_amount',

  // Donor Fields
  donor_id = 'donor_id',
  full_name = 'full_name',
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
  optin = 'optin',
  address_line_1 = 'address_line_1',
  address_line_2 = 'address_line_2',
  town = 'town',
  postcode = 'postcode',
  country = 'country',

  // Campaign Fields
  campaign_id = 'campaign_id',
  fundraiser_id = 'fundraiser_id',
  team_id = 'team_id',
  campaign_name = 'campaign_name',
  fundraiser_name = 'fundraiser_name',
  team_name = 'team_name',

  // Additional
  donation_type = 'donation_type',
  impact_id = 'impact_id',
  impact_name = 'impact_name',
  browser = 'browser',
  viewport = 'viewport',

  // Fees
  fees_covered = 'fees_covered',
  platform_fee = 'platform_fee',
  gateway_fee = 'gateway_fee',
  fees = 'fees',
}

export interface DatabaseField {
  label: string;
  name: string;
  property?: string;
  default?: boolean;
  type?: string;
}

export interface DatabaseFieldsGroup {
  name: string;
  columns: DatabaseField[];
}

const subscription_fields = {
  name: 'Subscription',
  columns: [
    {
      label: 'Subscription ID',
      name: DonationFields.subscription_id,
      property: '_id',
      default: true,
      type: 'link',
    },
    {
      label: 'Amount',
      name: DonationFields.amount,
      property: 'amount',
      default: true,
      type: 'amount',
    },
    {
      label: 'Gift Aid Amount',
      name: DonationFields.is_gift_aid,
      property: 'is_gift_aid',
      type: 'amount',
    },
    {
      label: 'Date',
      name: DonationFields.date,
      property: 'created_at',
      default: true,
      type: 'date',
    },
    { label: 'Time', name: DonationFields.time, property: 'created_at', type: 'time' },
    { label: 'Is Zakat', name: DonationFields.is_zakat, property: 'is_zakat', type: 'text' },
    {
      label: 'Anonymous',
      name: DonationFields.is_anonymous,
      property: 'is_anonymous',
      type: 'text',
    },
    {
      label: 'Status',
      name: DonationFields.status,
      property: 'status',
      default: true,
      type: 'badge',
    },
  ],
};

const payment_fields = {
  name: 'Payment information',
  columns: [
    {
      label: 'Charged Amount',
      name: DonationFields.charged,
      property: 'final_amount',
      type: 'amount',
    },
    {
      label: 'Currency',
      name: DonationFields.currency,
      property: 'currency',
      default: false,
      type: 'text',
    },
    {
      label: 'Payment Gateway',
      name: DonationFields.gateway,
      property: 'gateway.name',
      default: false,
      type: 'text',
    },
  ],
};

const donor_fields = {
  name: 'Donors',
  columns: [
    { label: 'Donor ID', name: DonationFields.donor_id, property: 'user.id', type: 'text' },
    {
      label: 'Full Name',
      name: DonationFields.full_name,
      property: 'name',
      default: false,
      type: 'full_name',
    },
    { label: 'First Name', name: DonationFields.first_name, property: 'first_name', type: 'text' },
    { label: 'Last Name', name: DonationFields.last_name, property: 'last_name', type: 'text' },
    {
      label: 'Email Address',
      name: DonationFields.email,
      property: 'user.email',
      default: true,
      type: 'text',
    },
    { label: 'Opt-In', name: DonationFields.optin, property: 'user.opt_in', type: 'text' },
    {
      label: 'Address Line 1',
      name: DonationFields.address_line_1,
      property: 'billing.address_line_1',
      type: 'text',
    },
    {
      label: 'Address line 1',
      name: DonationFields.address_line_2,
      property: 'billing.address_line_2',
      type: 'text',
    },
    { label: 'Town', name: DonationFields.town, property: 'billing.town', type: 'text' },
    {
      label: 'Zip/Postcode',
      name: DonationFields.postcode,
      property: 'billing.postcode',
      type: 'text',
    },
    { label: 'Country', name: DonationFields.country, property: 'billing.country', type: 'text' },
  ],
};

const campaign_fields = {
  name: 'Campaigns',
  columns: [
    {
      label: 'Campaign ID',
      name: DonationFields.campaign_id,
      property: 'campaign.id',
      type: 'text',
    },
    //{label: 'Fundraiser ID', name: 'fundraiser_id', property: 'campaign.fundraiser_id', 'type': 'text'},
    //{label: 'Team ID', name: 'team_id', property: 'campaign.team_id', 'type': 'text'},
    {
      label: 'Campaign Name',
      name: DonationFields.campaign_name,
      property: 'campaign.name',
      default: false,
      type: 'text',
    },
    // {label: 'Fundraiser Name', name: 'fundraiser_name', property: 'campaign.fundraiser_name', 'type': 'text'},
    //{label: 'Team Name', name: 'team_name', property: 'campaign.team_name', 'type': 'text'},
  ],
};

const address_fields = {
  name: 'More Data',
  columns: [
    { label: 'Browser', name: DonationFields.browser, property: 'browser.browser', type: 'text' },
    {
      label: 'Mobile or Desktop',
      name: DonationFields.viewport,
      property: 'browser.viewport',
      type: 'text',
    },
  ],
};
const fee_fields = {
  name: 'Fee Information',
  columns: [
    {
      label: 'Fees Covered',
      name: DonationFields.fees_covered,
      property: 'is_fee_paid',
      type: 'text',
    },
    {
      label: 'Platform Fee',
      name: DonationFields.platform_fee,
      property: 'platform_charge',
      type: 'amount',
    },
    {
      label: 'Payment Gateway Fee',
      name: DonationFields.gateway_fee,
      property: 'gateway_charge',
      type: 'amount',
    },
    {
      label: 'Total Fees',
      name: DonationFields.fees,
      property: 'fees.fee_amount',
      type: 'amount',
      default: true,
    },
  ],
};

export const FIELDS: DatabaseFieldsGroup[] = [
  subscription_fields,
  donor_fields,
  campaign_fields,
  address_fields,
  fee_fields,
  payment_fields,
];

const ALL_SUBSCRIPTION_FIELDS = FIELDS.reduce(
  (result, category) => result.concat(category.columns),
  []
);

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFields {
  getFields(): DatabaseFieldsGroup[] {
    return ALL_SUBSCRIPTION_FIELDS;
  }

  getFieldGroups(): DatabaseFieldsGroup[] {
    return FIELDS;
  }

  getDefaultFields() {
    return ALL_SUBSCRIPTION_FIELDS.filter((c) => {
      return c.default;
    });
  }

  getItemByField(name: string): DatabaseField {
    return ALL_SUBSCRIPTION_FIELDS.find((i) => {
      return i.name === name;
    });
  }
}

export interface Subscription {
  _id?: string;
  id?: string;
  campaign?: CampaignRg;
  charity?: CharityRg;
  user_id?: string;
  amount?: number;
  is_giftaid?: boolean;
  currency?: string;
  frequency?: string;
  period?: string;
  date?: {
    date?: string;
  };
  user?: UserRg;
  gateway?: PaymentMethod;
  billing?: any;
  recurring?: number;
  status?: string;
  default_payment_method?: PaymentMethod;
  total?: number;
  days?: number;
  start_date?: string;
  end_date?: string;
  interval?: number;
  currrency?: string;
  subscription_type?: {
    type: string;
    name?: string;
    email: string;
  };
  payment_method_id?: number;
  fees?: {
    fee_percentage?: number;
    fee_amount?: number;
  };
  type?: string;
  title?: string;
  payments?: {
    paid?: number;
  };
  final_amount?: number;
  donation_type_id?: number;
  is_zakat?: number;
  payment_method?: PaymentMethod;
}
