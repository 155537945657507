import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Select } from '@ngxs/store';
import { GivebriteConfig } from 'libs/data/src/lib/app/givebrite.interface';
import { GivebriteState } from 'libs/data/src/lib/app/store/givebrite.state';
import { DonationState } from 'libs/data/src/lib/donation/store/donation.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-impacts',
  templateUrl: './impacts.component.html',
  styleUrls: ['./impacts.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImpactsComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ImpactsComponent,
    },
  ],
})
export class ImpactsComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() first;
  @Input() second;
  @Input() last;
  @Input() otherAmount: number;

  constructor() {}

  @Output() passAutoGivingToggle = new EventEmitter<boolean>();
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;
  @Select(DonationState.DonationFlowForm) donation$: Observable<any>;
  impactsToggle = false;
  impactsSelected = 0;
  _amount: number;

  onChange = (amount: number) => {};
  onTouched = () => {};

  writeValue(amount: number) {
    this.amount = amount;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const amount = control.value;
    if (amount <= 0) {
      return {
        mustBePositive: {
          amount,
        },
      };
    }
  }

  set amount(amount: number) {
    this._amount = amount;
    this.impactsSelected = this.impacts.indexOf(this.amount);
    this.onChange(this.amount);
  }

  get amount() {
    return this._amount;
  }

  impacts: Array<any> = [10, 20, 30, 40];

  ngOnInit(): void {}

  onAutoGiving(change: MatSlideToggleChange) {
    this.passAutoGivingToggle.emit(change.checked);
  }

  onAmountChange(i: number) {
    this.amount = this.impacts[i];
  }

  /**
   * Impacts list
   * @param amount
   * @returns impact for the currently selected amount
   */
  impactsList(amount: number) {
    const impacts = {
      [this.first.amount]: this.first.impact,
      [this.second.amount]: this.second.impact,
      [this.last.amount]: this.last.impact,
    };
    return impacts[amount];
  }

  /**
   * get the impacts title based upon the selected amount
   * @param amount
   * @returns
   */
  getImpacts(amount: number) {
    let title;
    !this.otherAmount ? (title = this.impactsList(amount)) : (title = '');
    return title;
  }
}
