import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CharityState } from './charity.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([CharityState]),
    // NgxsStoragePluginModule.forRoot({ key: 'charity' }),
  ],
})
export class CharityStoreModule {}
