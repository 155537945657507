import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxFlickingComponent } from '@egjs/ngx-flicking';
import { donationFlow } from '@givebrite/theming';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { GetCampaignImpacts } from 'libs/data/src/lib/campaign/store/campaign.actions';
import { CampaignState } from 'libs/data/src/lib/campaign/store/campaign.state';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'givebrite-choose-impacts[environment]',
  templateUrl: './choose-impacts.component.html',
  styleUrls: ['./choose-impacts.component.scss'],
})
export class ChooseImpactsComponent implements OnInit {
  @Select(CampaignState.getImpacts) impacts$: Observable<Campaign[]>;
  @Select(CampaignState.getCampaign) campaign$: Observable<Campaign>;

  @Input() campaign: Campaign;
  @Input() currency: string;
  @Input() fundraiser: Fundraiser;
  @Input() charity: Charity;
  @Input() team: Team;
  @Input() environment;
  @Input() impacts = [];

  @ViewChild('impact') impact: NgxFlickingComponent;

  currentIndex = 0;
  donationFlow = donationFlow;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.store
      .dispatch(new GetCampaignImpacts(this.campaign._id))
      .pipe(untilDestroyed(this))
      .subscribe(
        (store) => {
          this.impacts = store.campaign.impacts.impacts;
        },
        (error) => {
          this.impacts = [
            {
              amount: 20,
              title: 'Give a donation of',
              currency: this.campaign.currency,
            },
            {
              amount: 50,
              title: 'Give a donation of',
              currency: this.campaign.currency,
            },
          ];
        }
      );
  }

  /**
   * @description
   * REDIRECT TO THE DONATE PAGE WITH THE DONATE AMOUNT
   * @param amount
   * @param id
   */
  giveNow(amount: number, id: string): string {
    return `${this.environment.apps.donate.url}/campaign/${this.campaign.slug}?impact=${id}&amount=${amount}`;
  }

  /**
   * @description
   * MOVES THE SLIDER TO A DIFFERENT PANEL
   * @param index : THE SLIDE TO MOVE TO
   */
  move(index: number): void {
    this.impact.moveTo(index);
  }

  ngAfterViewInit() {
    // Change the index of the current active panel
    // this.impact.ready.subscribe(() => {
    //   this.impact.willChange.subscribe((e) => {
    //     this.currentIndex = e.index;
    //   });
    // });
  }
}
