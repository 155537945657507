<div class="campaign-setting" gdColumns="90% 10%">
  <div fxLayout="row">
    <div class="icon mr-2">
      <ng-content select="[slide-toggle-icon]"></ng-content>
    </div>
    <div fxFlex class="title">
      <ng-content></ng-content
      ><ng-content select="[slide-toggle-help]"></ng-content>
    </div>
  </div>
  <mat-slide-toggle
    [checked]="enabled"
    (change)="toggled($event)"
    color="primary"
  ></mat-slide-toggle>
</div>

<div [class.disabled]="!enabled">
  <div class="content py-3">
    <ng-content select="[slide-toggle-section]"></ng-content>
  </div>
</div>
