import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '@givebrite/api';

@Injectable({
  providedIn: 'root',
})
export class DonationCartService {
  constructor(private http: ApiService) {}

  /**
   * CREATE SESSION
   */
  createCartSession() {
    return this.http.post(`donation/cart/sesson`).pipe(
      map(
        (response) => {
          return response;
        },
        catchError((error) => {
          return error;
        })
      )
    );
  }

  /**
   * ADD TO CART
   */
  addToCart(cartId, payload) {
    return this.http.post(`donation/cart?cart_id=${cartId}`, payload).pipe(
      map(
        (response) => {
          return response;
        },
        catchError((error) => {
          return error;
        })
      )
    );
  }

  /**
   * GET CART WITH ID
   * @param cartId
   * @returns
   */
  getCart(cartId) {
    return this.http.get(`donation/cart/${cartId}`).pipe(
      map(
        (response) => response,
        catchError((error) => error)
      )
    );
  }

  /**
   * UPDATE CART
   * @param cartId
   * @param itemId
   * @returns
   */
  updateCart(cartId, itemId, payload) {
    return this.http.patch(`donation/cart/${cartId}?item_id=${itemId}`, payload).pipe(
      map(
        (response) => response,
        catchError((error) => error)
      )
    );
  }

  /**
   * DELETE CART
   * @param cartId
   * @param itemId
   * @returns
   */
  deleteCart(cartId, itemId) {
    return this.http.delete(`donation/cart/${cartId}?item_id=${itemId}`).pipe(
      map(
        (response) => response,
        catchError((error) => error)
      )
    );
  }
}
