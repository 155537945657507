<div class="container account-campaigns py-9">
  <givebrite-profile-overview></givebrite-profile-overview>
  <h1 class="my-5 mt-10">My Dashboard</h1>
  <mat-nav-list class="campaign-header mb-3" fxLayout="row">
    <ng-container *ngFor="let link of navlink">
      <div
        [ngClass]="link.link === active ? 'active' : 'inactive'"
        class="link"
      >
        <a mat-list-item routerLink="/account/{{ link.link }}">
          {{ link.caption }}
        </a>
      </div>
    </ng-container>
  </mat-nav-list>

  <router-outlet></router-outlet>
</div>
