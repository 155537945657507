import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { AddressListItemModule } from './address-list-item/address-list-item.module';
import { AddressListComponent } from './address-list.component';

@NgModule({
  declarations: [AddressListComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    FlexLayoutModule,
    AddressListItemModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [AddressListComponent],
})
export class AddressListModule {}
