import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { Globe, UserPlus, Users } from 'angular-feather/icons';
import { CloseIconModule } from '../../../utils/close-icon/close-icon.module';
import { FundraiseOptionsDialogComponent } from './fundraise-options-dialog.component';

const icons = {
  Globe,
  Users,
  UserPlus,
};

@NgModule({
  declarations: [FundraiseOptionsDialogComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    IconModule,
    MatButtonModule,
    // FeatherModule.pick(icons),
    // FeatherModule,
    CloseIconModule,
    MatIconModule,
    MatDialogModule,
  ],
  exports: [FundraiseOptionsDialogComponent],
})
export class FundraiseOptionsDialogModule {}
