<div class="avatar-container" fxLayout="row" fxLayoutAlign="center center">
  <div *ngIf="!loaded" class="spinner-wrapper px-3">
    <mat-progress-spinner diameter="15" mode="indeterminate" color="primary"></mat-progress-spinner>
  </div>
  <a [class.hidden]="!loaded"
    ><img
      (load)="loaded = true"
      [width]="width"
      [height]="height"
      class="charity-avatar"
      fit="max"
      [src]="logo"
  /></a>
</div>
