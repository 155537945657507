import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Donation } from '../../donation';
import { Pagination } from '../../pagination/pagination.interface';
import { defaultPaginationState } from '../../pagination/pagination.state';
import { CampaignService } from '../campaign.service';
import { FundraiserService } from '../fundraiser.service';
import { Leaderboard, Team } from '../team.interface';
import { TeamService } from '../team.service';
import { GetCampaignTeams } from './campaign.actions';
import {
  CreateInvite,
  CreateTeam,
  GetCampaignTeamSingle,
  GetTeamDonations,
  GetTeamLeaderboard,
  GetTeamMembers,
  JoinTeam,
  UpdateTeam,
} from './team.actions';

export class TeamStateModel {
  loading?: boolean;
  error?: any;
  pagination: Pagination;
  list: Team[];
  teamsMembers: Team[];
  team: Team;
  inviteRes: any;
  campaignTeam: Team;
  leaderboard: Leaderboard[];
  joinTeam: Team;
  searchSpinner: boolean;
  donations: Donation[];
  topTeamLoader?: boolean;
  teamDonatePagination: Pagination;
  teamPagination: Pagination;
}

@State<TeamStateModel>({
  name: 'team',
  defaults: {
    loading: false,
    error: null,
    pagination: defaultPaginationState(),
    list: [],
    teamsMembers: [],
    team: null,
    inviteRes: null,
    campaignTeam: null,
    leaderboard: [],
    joinTeam: null,
    searchSpinner: false,
    donations: [],
    topTeamLoader: false,
    teamDonatePagination: null,
    teamPagination: null,
  },
})
@Injectable()
export class TeamState {
  constructor(
    private campaignService: CampaignService,
    private fundraiserService: FundraiserService,
    private teamService: TeamService
  ) {}

  @Selector()
  static pagination(state: TeamStateModel) {
    return state.pagination;
  }

  @Selector()
  static loading(state: TeamStateModel) {
    return state.loading;
  }

  @Selector()
  static teams(state: TeamStateModel) {
    return state.list;
  }

  @Selector()
  static members(state: TeamStateModel) {
    return state.teamsMembers;
  }

  @Selector()
  static createdTeam(state: TeamStateModel) {
    return state.team;
  }

  @Selector()
  static inviteRes(state: TeamStateModel) {
    return state.inviteRes;
  }

  @Selector()
  static campaignTeam(state: TeamStateModel) {
    return state.campaignTeam;
  }

  @Selector()
  static teamLeaderboard(state: TeamStateModel) {
    return state.leaderboard;
  }

  @Selector()
  static joinTeam(state: TeamStateModel) {
    return state.joinTeam;
  }

  @Selector()
  static spinner(state: TeamStateModel) {
    return state.searchSpinner;
  }

  @Selector()
  static topTeamLoader(state: TeamStateModel) {
    return state.topTeamLoader;
  }

  @Selector()
  static teamDonatePagination(state: TeamStateModel) {
    return state.teamDonatePagination;
  }

  @Selector()
  static teamPagination(state: TeamStateModel) {
    return state.teamPagination;
  }

  @Action(GetCampaignTeams)
  getTeams({ patchState, dispatch }: StateContext<TeamStateModel>, { campaignId, limit, search }) {
    patchState({ loading: true, searchSpinner: true, topTeamLoader: true });
    return this.campaignService.teams(campaignId, limit, search).pipe(
      tap(
        (result: { teams: Team[]; pagination: Pagination }) => {
          patchState({
            list: result.teams,
            teamPagination: result.pagination,
            pagination: result.pagination,
            loading: false,
            searchSpinner: false,
            topTeamLoader: false,
          });
        },
        () => {
          patchState({
            list: [],
            loading: false,
            teamPagination: null,
            pagination: null,
            searchSpinner: false,
            topTeamLoader: false,
          });
        }
      )
    );
  }

  /**
   * get single team
   */
  @Action(GetCampaignTeamSingle)
  getTeam({ patchState }: StateContext<TeamStateModel>, { slug, id }) {
    patchState({ loading: true });
    return this.fundraiserService.team(slug, id).pipe(
      tap((result: Team) => {
        patchState({
          campaignTeam: result,
          loading: false,
        });
      })
    );
  }

  /**
   * create team
   */
  @Action(CreateTeam)
  createTeam({ patchState, dispatch }: StateContext<TeamStateModel>, { payload }) {
    patchState({ loading: true });
    return this.fundraiserService.createTeam(payload).pipe(
      tap((response: Team) => {
        patchState({ loading: false, team: response });
        return dispatch(new GetTeamMembers(response?._id));
      })
    );
  }

  /**
   * create team
   */
  @Action(UpdateTeam)
  updateTeam({ patchState, dispatch }: StateContext<TeamStateModel>, { payload }) {
    patchState({ loading: true });
    return this.teamService.update(payload).pipe(
      tap((response: Team) => {
        patchState({ loading: false, team: response });
        // return dispatch(new GetTeamMembers(response?._id));
      })
    );
  }

  /**
   * get team members
   */
  @Action(GetTeamMembers)
  getMembers({ patchState }: StateContext<TeamStateModel>, { teamId }) {
    patchState({ loading: true });
    return this.fundraiserService.getTeamMembers(teamId).pipe(
      tap((result: Team[]) => {
        patchState({
          teamsMembers: result,
          loading: false,
        });
      })
    );
  }

  /**
   * get team leaderboard
   */
  @Action(GetTeamLeaderboard)
  getLeaderboard({ patchState }: StateContext<TeamStateModel>, { teamId }) {
    patchState({ loading: true });
    return this.fundraiserService.getTeamLeaderboard(teamId).pipe(
      tap((result: Leaderboard[]) => {
        patchState({
          leaderboard: result,
          loading: false,
        });
      })
    );
  }

  /**
   * createInvite
   */
  @Action(CreateInvite)
  createInvite({ patchState }: StateContext<TeamStateModel>, { teamId, payload }) {
    patchState({ loading: true });
    return this.fundraiserService.createInvite(teamId, payload).pipe(
      tap((result: any) => {
        patchState({
          loading: false,
          inviteRes: result,
        });
      })
    );
  }

  /**
   * join team
   */
  @Action(JoinTeam)
  joinTeam({ patchState }: StateContext<TeamStateModel>, { teamId }) {
    patchState({ loading: true });
    return this.fundraiserService.joinTeam(teamId).pipe(
      tap((result: any) => {
        patchState({
          loading: false,
          joinTeam: result,
        });
      })
    );
  }

  /**
   *  team Donations
   */
  @Action(GetTeamDonations)
  getTeamDonations(
    { patchState }: StateContext<TeamStateModel>,
    { teamId, limit, page, from_date, to_date }
  ) {
    patchState({ loading: true });
    return this.campaignService.donations(teamId, 'team', limit, page, from_date, to_date).pipe(
      tap(
        (result: { donations: Donation[]; pagination: Pagination }) => {
          patchState({
            donations: result.donations,
            teamDonatePagination: result.pagination,
            pagination: result.pagination,
            loading: false,
          });
        },
        (error) => {
          patchState({
            donations: [],
            loading: false,
          });
        }
      )
    );
  }
}
