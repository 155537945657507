import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { NgxFlickingComponent, Plugin } from '@egjs/ngx-flicking';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'givebrite-hero-header',
  templateUrl: './hero-header.component.html',
  styleUrls: ['./hero-header.component.scss'],
})
export class HeroHeaderComponent implements OnInit {
  plugins: Plugin[] = [
    new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: true }),
    new Arrow(),
  ];

  isBrowser = false;
  loading = false;
  @ViewChild('hero') hero: NgxFlickingComponent;

  _cover = [
    // {
    //   image: 'qg-hero.png',
    //   type: 'qg',
    //   title: '<span>Sheep</span> Qurbani Prices',
    //   cta1: 'Give Now',
    //   link: 'https://qurbanigiving.com/',
    // },
    {
      image: 'dhg-hero.png',
      type: 'dhg',
      title: '<span class="dhg-text">10 Days</span> better than ramadhan',
      cta1: 'Donate Now',
      link: 'https://dhulhijjahgiving.com/',
    },
    {
      image: '',
      type: 'gb',
      title: '<span>GB</span> Qurbani Prices',
      cta1: 'Browse',
      link: 'https://givebrite.com/',
    },
    // {
    //   image: 'qg-hero.png',
    //   type: 'eid',
    //   title: '<span class="dhg-text">Eid </span> Mubarak',
    //   cta1: '',
    //   link: '',
    // },
  ];

  options: AnimationOptions = {
    path: 'assets/balloon-celebration.json',
  };

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {}
}
