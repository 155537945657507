import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignCardComponent } from './campaign-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { ProgressBarModule } from '../../campaigns/progress-bar/progress-bar.module';
import { CharityLogoAvatarModule } from '../../charity/charity-logo-avatar/charity-logo-avatar.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LogoCardModule } from './../../../../../theming/src/lib/components/logo-card/logo-card.module';
import { TickMarkModule } from '../tick-mark/tick-mark.module';
import { ResponsiveAssetsModule } from './../../directives/responsive.module';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [CampaignCardComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    ProgressBarModule,
    MatCardModule,
    CharityLogoAvatarModule,
    NgxSkeletonLoaderModule,
    LogoCardModule,
    TickMarkModule,
    ResponsiveAssetsModule,
    TruncateStringModule,
  ],
  exports: [CampaignCardComponent],
})
export class CampaignCardModule {}
