import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { GetProjects, Projects } from '@givebrite/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-project-selector',
  templateUrl: './campaign-project-selector.component.html',
  styleUrls: ['./campaign-project-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignProjectSelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignProjectSelectorComponent,
    },
  ],
})
export class CampaignProjectSelectorComponent implements OnInit {
  projects: Projects[];
  // selectedProjectId = '';

  onChange = (projectValue: string) => {};
  onTouched = () => {};

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getProjects();
  }

  writeValue(projectId: string) {
    this.selectedProjectId = projectId;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const projectId = control.value;

    if (projectId === '') {
      return {
        mustNotBeEmpty: {
          projectId,
        },
      };
    }
  }

  /**
   * @description
   * GET PROJECTS
   */
  getProjects(): void {
    this.store
      .dispatch(new GetProjects())
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        const projects = response?.skt?.projects;
        if (projects) {
          this.projects = projects;
        } else {
          throw new Error('No projects available!');
        }
      });
  }

  get selectedProjectId() {
    return this._selectedProjectId;
  }

  set selectedProjectId(value: string) {
    this._selectedProjectId = value;
    this.onChange(this.selectedProjectId);
  }

  private _selectedProjectId = '';

  /**
   * SETS THE ACTIVE PROJECT
   * @param Project Id
   */
  // selectedProject(projectId: string) {
  //   console.log('projectId', projectId);
  //   this.selectedProjectId = projectId;
  //   this.onChange(projectId);
  // }
}
