import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'givebrite-campaign-details-editor',
  templateUrl: './campaign-details-editor.component.html',
  styleUrls: ['./campaign-details-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignDetailsEditorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignDetailsEditorComponent,
    },
  ],
})
export class CampaignDetailsEditorComponent implements OnInit, OnDestroy {
  @Input() form: FormControl;
  @Input() environment;

  onChange = (body: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  // _body: string;

  editorForm = new FormGroup({
    content: new FormControl(''),
  });

  constructor() {}

  ngOnInit(): void {}

  /**
   * get text editor updated value and patch it to Ngrx form description control
   */
  description(e) {
    // const schema = this.editorSchema;
    // const html = toHTML(this.editorForm.get('content').value);
    // console.log(html);
    this.body = this._body;
  }

  get _body() {
    return this.editorForm.get('content').value;
  }

  set _body(body) {
    this.editorForm.get('content').setValue(body);
  }

  set body(body: string) {
    // this._body = body;
    this.onChange(this.body);
  }

  get body() {
    return this._body;
  }

  writeValue(body: string) {
    console.log(body);
    this._body = body;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
    // const goal = control.value;
    // if (goal <= 0) {
    //   return {
    //     mustBePositive: {
    //       goal,
    //     },
    //   };
    // }
  }

  ngOnDestroy() {}
}
