import { CurrencyPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GivebriteConfig, GivebriteState } from '@givebrite/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CheckoutService } from '../../../../../../apps/donate/src/app/checkout/checkout.service';
export const MAX_IMPACTS = 3;
export const MIN_IMPACTS = 2;
export const DEFAULT_IMPACTS: AmountSliderInputImpact[] = [
  {
    amount: 10,
  },
  {
    amount: 50,
  },
  {
    amount: 100,
    // default: true,
  },
  {}, // Other
];
export const STEP = 1;

export type ProjectAppearanceType = 'slider-view' | 'amount-list-view';
const DEFAULT_APPEARANCE = 'slider-view';

// import { Required } from '@givebrite/core';

/**
 * @Abihinav NOTES
 * this component is such a mess, it is a shared module but uses a service from an app making it completely redundant
 *
 */
@UntilDestroy()
@Component({
  selector: 'givebrite-impact-slider-skt', // givebrite-slider was a very generic name and does not describe the module
  templateUrl: './slider-skt.component.html',
  styleUrls: ['./slider-skt.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImpactSliderSktComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ImpactSliderSktComponent,
    },
  ],
})
export class ImpactSliderSktComponent implements OnInit, ControlValueAccessor {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;

  @Input()
  get appearance(): ProjectAppearanceType {
    return this._appearance;
  }

  set appearance(value: ProjectAppearanceType) {
    if (value) {
      this._appearance = value;
    }
  }

  private _appearance: ProjectAppearanceType = DEFAULT_APPEARANCE;

  // Shows/hides slider
  hide_slider: boolean;

  // Step interval
  step: number = STEP;

  // Selected step index
  stepIndex = 0;

  // Takes a default amount
  @Input() default: number;

  // Default amount index used for mat-slider
  defaultIndex = 0;

  selectedImpact: AmountSliderInputImpact;
  @ViewChild('customAmountInput') customAmountInput: ElementRef;

  // Amount
  _amount: number;

  _impacts: AmountSliderInputImpact[] = [];

  // Input text box
  form = this.fb.group({
    amount: [],
  });

  /**
   * @description
   *
   * Takes in impact values and validates against minimum and maximum values
   *
   * @param impacts A required array of impacts
   * only unique amounts
   *
   * @todo: Make required @Input, check angular material for reference
   */
  @Input()
  set impacts(impacts: AmountSliderInputImpact[]) {
    // console.log(impacts);
    if (impacts?.length) {
      // Remove null values
      impacts = impacts?.filter((impact) => impact);

      // Filter unique impacts (one amount allowed)
      const unique = [];
      impacts?.map((x) =>
        unique?.filter((a) => a?.amount == x?.amount)?.length > 0 ? null : unique?.push(x)
      );

      // sort impacts by amount
      unique?.sort((a, b) => a?.amount - b?.amount);

      // limit to max impacts
      unique.length = unique.length <= MAX_IMPACTS ? unique.length : MAX_IMPACTS;

      // Set local variable with unique impacts
      this._impacts = unique;

      // For other amount appended to end of slider
      this._impacts?.push({});
    } else {
      // Use default Impacts
      // console.log('setting default impacts');
      this._impacts = DEFAULT_IMPACTS;

      // Use default
      // set default index based on default value from array || default input || default to first
      this.defaultIndex = this._impacts?.findIndex((i) => i?.default);
      if (this.defaultIndex == -1) this.defaultIndex = 0;

      // convert index to amount value from impacts array
      this.selectedImpact = this._impacts[this.defaultIndex];

      // convert index to amount value from impacts array
      this._amount = +this.selectedImpact?.amount;
    }
  }

  // required variable
  @Input()
  currency: string;

  @Input() fixedPriceType: string;

  // Outputs chosen impact
  @Output() impact: EventEmitter<any> = new EventEmitter<any>();

  onChange = (amount: number) => {};
  onTouched = () => {};

  writeValue(goal: number) {
    this.amount = goal;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const amount = control.value;
    if (amount <= 0) {
      return {
        mustBePositive: {
          amount,
        },
      };
    }
  }

  constructor(
    private checkoutService: CheckoutService,
    private currencyPipe: CurrencyPipe,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Detect changes in custom amount and update amount

    // console.log('setting default impacts');
    // this._impacts = DEFAULT_IMPACTS;

    // Use default
    // set default index based on default value from array || default input || default to first
    this.defaultIndex = this._impacts.findIndex((i) => i?.default);
    if (this.defaultIndex == -1) this.defaultIndex = 0;

    // convert index to amount value from impacts array
    this.selectedImpact = this._impacts[this.defaultIndex];

    // convert index to amount value from impacts array
    this._amount = +this.selectedImpact.amount;

    this.form
      .get('amount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((amount) => {
        this._amount = amount;
        this.onChange(+this._amount);
      });

    // If amount in querym then update the amount & the active state
    if (this.route.snapshot?.queryParams?.amount && !this.route.snapshot?.queryParams?.redirect) {
      const index = this._impacts
        .map((impact) => impact.amount)
        .indexOf(+this.route.snapshot.queryParams.amount);
      this.onInputChange({ value: index == -1 ? 3 : index });
    }
  }

  get cart() {
    return this.checkoutService.cart;
  }

  /**
   *
   * @returns amount Formatted selected amount
   */
  displayFn = () => {
    // const amount =  ? this.selectedImpact.amount : this.customAmount;
    return this.selectedImpact.amount
      ? this.currencyPipe.transform(this.selectedImpact.amount, this.currency, 'symbol', '1.0-0')
      : '';
  };

  onInputChange($event: any) {
    this.stepIndex = +$event.value;
    this.selectedImpact = this._impacts[this.stepIndex];

    if (this.selectedImpact.amount == 0) {
      // console.log('focus on input');
      // Other amount, focus on custom input textbox
      this.customAmountInput.nativeElement.focus();
    } else {
      this.amount = this.selectedImpact.amount;
    }

    this.impact.emit(this.selectedImpact);
  }

  other() {
    this.impact.emit('other');
  }

  set amount(amount: number) {
    // amount set from form
    if (amount) {
      // Find index matching amount
      const index = this._impacts.findIndex((i) => i.amount == amount);

      // Not found, set other
      if (index == -1) this.stepIndex = this._impacts.length - 1;

      this.selectedImpact = this._impacts[this.stepIndex];
      this._amount = amount;
    } else {
    }

    this.form.get('amount').setValue(+this._amount, { emitEvent: false });
    this.onChange(+this._amount);
  }

  get amount() {
    return this._amount;
  }

  get customAmount() {
    return this.form.get('amount').value;
  }

  set customAmount(amount) {
    this.form.get('amount').setValue(amount);
  }

  get amountListView() {
    return this.appearance === 'amount-list-view';
  }

  get sliderView() {
    return this.appearance === 'slider-view';
  }
}

export interface AmountSliderInputImpact {
  amount?: number;
  name?: string;
  default?: boolean;
}
