<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  class="charity-verified text-center py-5 sm:py-24 px-5"
>
  <img src="https://gb-downloads.s3.eu-west-1.amazonaws.com/11_404_Error.png" />
  <h1 class="text-xl sm:text-3xl">Page not found!</h1>
  <h3 class="text-dark">We can't seem to find the page you are looking for</h3>
  <h3 mat-raised-button color="primary">Here are some helpful links:</h3>
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px" class="leading-loose">
    <a [href]="environment?.apps?.home?.url">Home</a>
    <a [href]="environment?.apps?.dashboard?.url">Dashboard</a>
    <a href="https://beta.givebrite.com/search/charities">Search</a>
    <!-- <a href="">Browse campaigns</a>
    <a href="">Support center</a> -->
  </div>
</div>
