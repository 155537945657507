<div
  class="logo-container p-2 mat-elevation-z3 relative"
  fxLayoutAlign="center center"
  [style.background-color]="charity?.logo?.white ? charity?.theme?.primary : 'white'"
  [ngStyle]="{
    width: width,
    height: width
  }"
>
  <givebrite-logo-card
    [logo]="charity?.logo?.white ? charity?.logo?.white : charity?.logo?.color"
    width="100"
  ></givebrite-logo-card>
  <!-- <img [alt]="'Ramadhan Giving with' + charity?.name" [src]="charity?.logo.white ? charity?.logo.white : charity?.logo.color" class="logo" /> -->

  <div class="absolute logo-detail" *ngIf="showDetail">
    <p
      class="text-center"
      [style.background-color]="charity?.logo?.white ? charity?.theme?.primary : 'white'"
    >
      From {{ charity?.stats | currency: charity?.default_currency : 'symbol' }}
    </p>
  </div>
</div>
