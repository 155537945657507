import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsComponent } from './statistics.component';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [StatisticsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    IconModule,
    MatButtonModule,
  ],
  exports: [StatisticsComponent],
})
export class StatisticsModule {}
