import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { GivebriteState } from './givebrite.state';

let production = false;

let developmentModules = [];

if (!production) {
  developmentModules = [NgxsReduxDevtoolsPluginModule.forRoot()];
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot([GivebriteState], {
      developmentMode: production,
      selectorOptions: { suppressErrors: false },
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['givebrite', 'account', 'donationCart'],
    }),
    ...developmentModules,
  ],
})
export class GivebriteStoreModule {
  static forRoot(params): ModuleWithProviders<GivebriteStoreModule> {
    production = params.production;
    return {
      ngModule: GivebriteStoreModule,
      providers: [{ provide: 'production', useValue: params.production }],
    };
  }

  // constructor(@Inject('memberData') private memberData: any) {}
}
