import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CloseIconModule } from '../../../utils/close-icon/close-icon.module';
import { ShareDialogComponent } from './share-dialog.component';
@NgModule({
  declarations: [ShareDialogComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    IconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    ClipboardModule,
    CloseIconModule,
  ],
  exports: [ShareDialogComponent],
  providers: [{ provide: ToastrService, useClass: ToastrService }],
})
export class ShareDialogModule {}
