export interface CampaignRg {
  _id?: string;
  id: number;
  name: string;
  unregistered: {
    charities: [{}];
  };
  registered: any;
  slug?: string;
  default_currency?: string;
  theme?: {
    primary: string;
    secondary: string;
  };
  address: {
    postcode?: string;
  };
  logo: string;
  description: string;
  //connected: Payment[],
  //subscribed: Plan,
  block: string;
  currency?: string;
  regno?: number;
  step?: number;
  default_card_provider?: number;
  opted_seperate_billing: number;
  gocardless_mandate: any;
  is_ramadhan_giving: boolean;
  impacts: ImpactRg[];
}

export interface ImpactRg {
  name?: string;
  id?: number;
  _id?: string;
  amount: number;
  details?: string;
  currency?: string;
  custom?: boolean;
}
