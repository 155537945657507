<div fxLayout="column" class="countdown" *ngIf="(timeLeft$ | async) && (timeLeft$ |async) as timeLeft">
    <div fxLayout="row" fxLayoutGap="15px">
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="relative countdown-bi" [ngStyle]="{'background-image': 'url(' + ramadhancountdownborder + ')'}">
                <div class="countdown-text">{{timeLeft.daysToDday}}</div>
            </div>
            <p>
                Days
            </p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="relative countdown-bi" [ngStyle]="{'background-image': 'url(' + ramadhancountdownborder + ')'}">
                <div class="countdown-text">{{timeLeft.hoursToDday}}</div>
            </div>
            <p>
                Hours
            </p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="relative countdown-bi" [ngStyle]="{'background-image': 'url(' + ramadhancountdownborder + ')'}">
                <div class="countdown-text">{{timeLeft.minutesToDday}}</div>
            </div>
            <p>
                Minutes
            </p>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
            <div class="relative countdown-bi" [ngStyle]="{'background-image': 'url(' + ramadhancountdownborder + ')'}">
                <div class="countdown-text">{{timeLeft.secondsToDday}}</div>
            </div>  
            <p>
                Seconds
            </p>
        </div>

    </div>
</div>