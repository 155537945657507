import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryNamePipe } from './country-name.pipe';


@NgModule({
  declarations: [CountryNamePipe],
  imports: [
    CommonModule
  ],
  exports: [
    CountryNamePipe
  ] 
})
export class CountryNamePipeModule{}
