export const Campaigns: {
  id: number;
  cover: string;
  checked: boolean;
  name: string;
}[] = [
  {
    id: 5,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
  {
    id: 6,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
  {
    id: 7,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
  {
    id: 8,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
  {
    id: 9,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
  {
    id: 10,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    name: 'This would be a long title for this charity...',
  },
];

export const Team: {
  id: number;
  cover: string;
  checked: boolean;
  team: string;
  name: string;
}[] = [
  {
    id: 5,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Australia',
  },
  {
    id: 6,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Manchesters United',
  },
  {
    id: 7,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Manchesters United',
  },
  {
    id: 8,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Manchesters United',
  },
  {
    id: 9,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Manchesters United',
  },
  {
    id: 10,
    cover:
      'https://www.cscbroward.org/sites/default/files/styles/blog_/public/2019-08/All%20Children%20Develop%20at%20their%20Own%20Pace.JPG?h=33334fe2&itok=adHi3i7m',
    checked: false,
    team: 'This would be a long title for this charity...',
    name: 'Team - Manchesters United',
  },
];
