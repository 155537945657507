import { Charity } from '../charity';
import { User } from '../user';
import { DonationType } from '../donation';

export interface Campaign {
  dates: {
    start_date: string;
    end_date: string;
  };
  statistics: {
    average: number;
    raised: number;
    donors: number;
    offline: number;
  };
  social: {
    supporters: number;
    shares: number;
    likes: number;
  };
  settings: {
    is_auctions: boolean;
    is_impacts: boolean;
    is_phases: boolean;
    is_sponsors: boolean;
    is_teams: boolean;
    is_zakat: boolean;
    donation_type?: any[];
    template?: {
      name?: string;
    };
  };
  _id: string;
  name: string;
  slug: string;
  excerpt: string;
  goal: number;
  currency: string;
  description: string;
  donation_types: DonationType[];
  charity: {
    logo: {
      white: string;
      color: string;
    };
    theme: {
      primary: string;
      secondary: string;
    };
    statistics: {
      donations: number;
      subscriptions: number;
      donation_count: number;
      raised: number;
      canceled: number;
      campaigns: number;
    };
    social: {
      followers: number;
      shares: number;
      likes: number;
    };
    settings?: {
      pixel_id?: string;
    };
    _id: string;
    slug: string;
    name: string;
  };
  type: string;
  status?: string;

  impacts?: [
    {
      amount: number;
      currency: string;
      donations_recieved: number;
      name: string;
      title?: string;
      _id?: string;
    }
  ];
  cover: [
    {
      id: string;
      type: 'image' | 'video';
      thumbnail?: string;
      video?: {
        type: 'vimeo' | 'youtube';
        thumbnail: 'string';
        id: string;
      };
      is_old: boolean;
      image?: {
        cropped: {
          url: string;
          _id: string;
        };
        original: {
          url: string;
          _id: string;
        };
        cropperPosition: {
          x1: number;
          x2: number;
          y1: number;
          y2: number;
        };
      };
    }
  ];
  created_at: Date;
  zakat: boolean;
  logo: string;
  can_edit: boolean;
  theme: {
    primary: string;
    secondary: string;
  };
  thumbnail?: string;
  user?: User;
}

export interface CampaignStatistics {
  raised_amount: number;
  currency: string;
  donors: number;
  campaigns: number;
}

export interface CampaignUpdates {
  campaign: string;
  created_at: string;
  description: string;
  excerpt: string;
  thumbnail: string;
  title: string;
  _id: string;
}

export interface CampaignPhases {
  _id: number;
  title: string;
  excerpt: string;
  thumbnail: string;
  amount: {
    raised: number;
    goal: number;
  };
  created_at: string;
}

export interface CreateCampaignDto {
  goal: number;
  currency: string;
  charity: string;
  inspiration?: [];
  type: 'p2p' | 'online';
  end_date: string;
  description: string;
  // zakat: boolean;
  // has_impacts: boolean;
  // impacts: [
  //   {
  //     title: string;
  //     amount: number;
  //   }
  // ];
  // has_phases: boolean;
  // phases: [
  //   {
  //     title: string;
  //     amount: number;
  //   }
  // ];

  // has_sponsers: boolean;
  // sponsers: [
  //   {
  //     logo: string;
  //     name: string;
  //     uri: string;
  //   }
  // ];

  // enable_teams: boolean;
  // enable_auctions: boolean;
  // theme: {
  //   primary: string;
  //   secondary: string;
  // };
}

export interface CampaignCreateForm {
  goal: number;
  currency: string;
  charity: string;
  inspiration: [];
  team: string;
  cover: string;
  name: string;
  end_date: string;
  description: string;
  zakat: boolean;
  has_impacts: boolean;
  impacts: [
    {
      title: string;
      amount: number;
    }
  ];
  has_phases: boolean;
  phases: [
    {
      title: string;
      amount: number;
    }
  ];

  has_sponsers: boolean;
  sponsers: [
    {
      logo: string;
      name: string;
      uri: string;
    }
  ];

  enable_teams: boolean;
  enable_auctions: boolean;
  theme: {
    primary: string;
    secondary: string;
  };
}

export interface CampaignBlog {
  _id: string;
  user: {
    _id: string;
    first_name: string;
    last_name: string;
  };
  category: string;
  title: string;
  description: string;
  image: string;
  thumbnail: string;
  created_at: Date;
  __v: number;
}

export interface CampaignTeam {
  amount: {
    raised: number;
    currency: string;
    donors: number;
  };
  social: {
    supporters: number;
    shares: number;
    likes: number;
  };
  active: boolean;
  _id: string;
  goal: number;
  currency: string;
  charity: string;
  campaign: string;
  cover: string;
  name: string;
  slug: string;
  end_date: string;
  description: string;
  theme: {
    primary: string;
    secondary: string;
  };
  user: string;
  created_at: string;
  __v: number;
}

export type Page = 'campaign' | 'fundraiser' | 'team';
