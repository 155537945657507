import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'givebrite-charity-showcase-item',
  templateUrl: './charity-showcase-item.component.html',
  styleUrls: ['./charity-showcase-item.component.scss'],
})
export class CharityShowcaseItemComponent implements OnInit {
  @Input() charity;
  loaded: boolean;
  constructor() {}

  ngOnInit() {}
}
