import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QurbaniGivingToolbarUserComponent } from './qurbani-giving-toolbar-user.component';
import { ToolbarUserModule } from '@givebrite/theming';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { QurbaniGivingToolbarUserDropdownComponent } from './qurbani-giving-toolbar-user-dropdown/qurbani-giving-toolbar-user-dropdown.component';
//import { ColorFadeModule } from '../../pipes/color/color-fade.module';

@NgModule({
  declarations: [QurbaniGivingToolbarUserComponent, QurbaniGivingToolbarUserDropdownComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    //RelativeDateTimeModule,
    RouterModule,
    MatTooltipModule,
    IconModule,
    //ColorFadeModule,
    MatDialogModule,
    ToolbarUserModule,
  ],
  exports: [QurbaniGivingToolbarUserComponent],
})
export class QurbaniGivingToolbarUserModule {}
