import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoginDialogComponent } from '@givebrite/core';
import { Select } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
// import { UserState } from '../../user';
import { UserRg, UserStateRg } from '../user';

@Injectable({
  providedIn: 'root',
})
/*
export class AuthDialogGuard implements CanActivate {
  isServer: boolean;
  isBrowser: boolean;

  @Select(UserState.authenticated) authenticated$;

  constructor(private dialog: MatDialog, private router: Router, private user: UserService, @Inject(PLATFORM_ID) platformId) {
    this.isServer = isPlatformServer(platformId);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticated$.pipe(
      map(authed => {
        if (!authed) {
          this.dialog
            .open(LoginDialogComponent, {
              disableClose: false,
              closeOnNavigation: false,
              width: "100%",
              height: "800px",
              maxWidth: "100vw"
            })
            .afterClosed()
            .subscribe(user => {
              return true;
            });
        } //this.router.navigate(["user", "login"]);

        return authed;
      })
    );
  }
}
*/

@Injectable({
  providedIn: 'root',
})
export class AuthDialogGuard implements CanActivate {
  @Select(UserStateRg.getUser) user$;
  @Select(UserStateRg.authenticated) authenticated$;
  // @Select(UserState.user) user$;
  constructor(private router: Router, private dialog: MatDialog) {}

  /** Prompts the user for authentication */
  public prompt(data = 'signIn'): Promise<UserRg> {
    //return this.dialog.open<LoginComponent,loginAction, User>(LoginComponent, { data })
    return this.dialog
      .open(LoginDialogComponent, {
        panelClass: ['transparent', 'fullscreen-panel'],
        disableClose: false,
        closeOnNavigation: false,
        maxWidth: '100vw',
        maxHeight: '100vh',
      })
      .afterClosed()
      .toPromise();
  }

  /** Performs the user authentication prompting the user when neeed or resolving to the current authenticated user otherwise */
  public authenticate(action = 'signIn'): Promise<UserRg> {
    return this.user$
      .pipe(
        take(1),
        switchMap((user) => (!user ? this.prompt(action) : of(user)))
      )
      .toPromise();
  }

  // Implements single route user authentication guarding
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Gets the authorization mode when specified
    const mode = route.queryParamMap.get('authMode') || 'signIn';
    // Prompts the user for authentication
    return this.authenticate().then((user) => !!user);
  }
}
