import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayMessageComponent } from './display-message.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [DisplayMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [DisplayMessageComponent]
})
export class DisplayMessageModule { }
