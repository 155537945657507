<ng-container *ngIf="user$ | async">
  <div
    #originRef
    (click)="showPopover(originRef)"
    [class.active]="dropdownOpen"
    class="user"
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    matRipple
  >
    <div class="user-name" fxHide.xs>{{ (user$ | async)?.first_name }} {{ (user$ | async)?.last_name }}</div>
    <div class="user-image" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon [icIcon]="icPerson"></mat-icon>
    </div>
  </div>
</ng-container>

<div *ngIf="!(user$ | async)" class="user-login" fxLayout="row" fxLayoutAlign="end center">
  <div class="user-login-button"><a routerLink="/user/login" mat-raised-button color="primary">Login</a></div>
</div>
