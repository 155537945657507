import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserServiceRg, UserStateRg } from '../user';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardRg implements CanActivate {
  isServer: boolean;
  isBrowser: boolean;

  @Select(UserStateRg.authenticated) authenticated$;

  constructor(
    private router: Router,
    private user: UserServiceRg,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.isServer = isPlatformServer(platformId);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticated$.pipe(
      map((authed) => {
        if (!authed) this.router.navigate(['user', 'login']);
        return authed;
      })
    );
  }

  /*canAdminActivate (
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {

		let ctx = this;
			if (!this.auth.authenticated()){
				this.auth.redirectUrl = state.url;
				this.router.navigate(['/login']);
				return false;
			}
		return true;
	}*/
}
/*
@Injectable()
export class AdminAuthGuard implements CanActivate, OnDestroy {

	_onDestroy : Subject<void> = new Subject<void>();
	isServer: boolean;

	constructor(
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		@Inject(PLATFORM_ID) platformId
	) {

		this.isServer = isPlatformServer(platformId);
	}

	canActivate (
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {


		if (this.auth.authenticated()){

			if(this.isServer){
				return true;
			}

			this.store.select('user')
			.takeUntil(this._onDestroy)
			.subscribe(store => {
				if(store.payload.user){
					let organisation = store.payload.user.selected_charity;
					if(!organisation){
						this.router.navigate(['/']);
						return false;
						// logged in but has no charity
					} else {
						/
	      				return true;
					}
				}
			})


		} else {
			this.auth.redirectUrl = state.url;
			this.router.navigate(['/login']);
			return false;
		}

		return true;
    }


	ngOnDestroy(){
		this._onDestroy.next();
		this._onDestroy.complete();


}}*/
