import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { JwtService } from 'libs/api/src/lib/jwt.service';
import { map } from 'rxjs/operators';
import { Address, PaymentMethod, SocialUserRg, UserRg } from './user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserServiceRg {
  constructor(private http: ApiService, private jwt: JwtService, private httpClient: HttpClient) {}

  postcode(postcode: string) {
    return this.httpClient.get(
      `https://api.getAddress.io/find/${postcode}?expand=true&api-key=mejrvVU6PEuT-Smpo7Xr6w30268`
    );
  }

  login(credentials) {
    return this.http.post('auth/login', credentials);
  }

  loginToken(data) {
    return this.http.post('auth/login_token', data);
  }

  verifyLoginToken(data) {
    return this.http.post('auth/verify_login_token', data);
  }

  socialComplete(socialUser: SocialUserRg) {
    return this.http.post('auth/social_complete', socialUser).pipe(
      map((response) => {
        return response;
      })
    );
  }

  payment_methods(params?) {
    return this.http.get('user/payments', params).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  deletePaymentMethod(paymentMethod: PaymentMethod) {
    return this.http
      .delete(
        `user/card?_id=${paymentMethod._id}&fingerprint=${paymentMethod.fingerprint}&payment_method_id=${paymentMethod.payment_method_id}`,
        paymentMethod
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  get authenticated(): boolean {
    return !!this.token;
  }

  get() {
    return this.http.get('auth/profile').pipe(
      map((user) => {
        return user.user;
      })
    );
  }

  update(user: UserRg) {
    return this.http.put('user', user).pipe(
      map((user) => {
        return user.user;
      })
    );
  }

  exists(email: string) {
    return this.http.get('user/find', { email: email }).pipe(
      map((user) => {
        return user;
      })
    );
  }

  authorise(token) {
    // Save JWT sent from server in localstorage
    console.log(token);
    this.jwt.saveToken(token);
    // if (user.type == "admin") {
    //   this.jwt.saveAdminToken(user);
    // }
  }

  loginAsCharity(email: string) {
    return this.http.post('user/login_as', { email: email }).pipe(
      map((response) => {
        return response.user;
      })
    );
  }

  get token() {
    return this.jwt.getToken();
  }

  createSetupIntent(params) {
    return this.http.post('user/intents/setup/create', params).pipe(
      map((secret) => {
        return secret;
      })
    );
  }

  createPaymentIntent(params) {
    return this.http.post('user/intents/payment/create', params).pipe(
      map((secret) => {
        return secret;
      })
    );
  }

  confirmSetupIntent(params) {
    return this.http.post('user/intents/setup/capture', params);
  }

  populate() {
    //if(this.isBrowser){

    if (this.token) {
      this.get().subscribe(
        (user) => {
          this.authorise(user);
        },
        (error) => {
          this.logout();
        }
      );
    } else {
      this.logout();
    }
  }

  logout() {
    this.jwt.destroyToken();
  }

  getAddresses(id?: number) {
    return this.http.get(`user/address`).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  addAddress(payload) {
    return this.http.post(`user/address`, payload).pipe(
      map((response) => {
        return response.user.address;
      })
    );
  }

  updateAddress(address: Address) {
    return this.http.put(`user/address/${address.id}`, address).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  deleteAddress(id: number) {
    return this.http.delete(`user/address/${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // register(payload) {
  //   return this.http.post(`auth/register`, payload)
  // }
  register(data) {
    return this.http.post('auth/verify_register_token', data);
  }

  socialLogin(socialUser: SocialUserRg) {
    return this.http.post('auth/social_register', socialUser).pipe(
      map((response) => {
        return response;
      })
    );
  }

  socialRegister(socialUser: SocialUserRg) {
    console.log(socialUser);
    return this.http.post('auth/social_complete', socialUser).pipe(
      map((response) => {
        return response;
      })
    );
  }

  forgot(email: string) {
    return this.http.post(`auth/reset_password`, email).pipe(
      map((response) => {
        return response;
      })
    );
  }

  reset(payload) {
    return this.http.put('auth/password', payload).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUserSubscriptions(limit, page, filter, sort, column = '') {
    return this.http.get(
      `subscription/list?limit=${limit}&page=${page}&filter=${filter}&sort=${sort}&column=${column}&after=2019-04-23`
    );
  }

  cancel(id: number, cancel_at_period_end = 0) {
    return this.http.delete(`subscription/${id}?cancel_at_period_end=${cancel_at_period_end}`);
  }
}
