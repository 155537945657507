import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-all-app-cta',
  templateUrl: './all-app-cta.component.html',
  styleUrls: ['./all-app-cta.component.scss']
})

export class AllAppCtaComponent implements OnInit {
  qg = 'https://qurbanigiving.com/';
  dhg = 'https://dhulhijjahgiving.com/';

  constructor() {}

  ngOnInit(): void {}
}
