import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaUploaderComponent } from './media-uploader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { UploaderDropZoneModule } from './../..//campaign-cover-uploader/uploader-drop-zone/uploader-drop-zone.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { ImageHelperComponent } from './image-helper/image-helper.component';
import { VideoHelperComponent } from './video-helper/video-helper.component';
import { VideoUploaderModule } from './video-uploader/video-uploader.module';

@NgModule({
  declarations: [MediaUploaderComponent, ImageHelperComponent, VideoHelperComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    FlexLayoutModule,
    // UploaderDropZoneModule,
    MatButtonModule,
    MatTabsModule,
    VideoUploaderModule,
  ],
  exports: [MediaUploaderComponent],
})
export class MediaUploaderModule {}
