import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { JoinTeam } from 'libs/data/src/lib/campaign/store/team.actions';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'givebrite-invite-details',
  templateUrl: './invite-details.component.html',
  styleUrls: ['./invite-details.component.scss'],
})
export class InviteDetailsComponent implements OnInit {
  @Input() name;
  @Input() fundraisers;
  @Input() raised;
  @Input() endDate;
  @Input() profileImage;
  @Input() firstName;
  @Input() lastName;
  @Input() slug;
  @Input() id;
  @Input() currency;

  @Select(TeamState.joinTeam)
  joinTeam$: Observable<Team>;

  constructor(private router: Router, private store: Store, private toastr: ToastrService) {}

  ngOnInit(): void {}

  joinTeam(slug) {
    this.store.dispatch(new JoinTeam(this.id)).subscribe(
      (store: any) => {
        this.router.navigate([`/fundraiser/create/${this.id}`], {
          // queryParams: { team: this.team._id },
        });
      },
      (err) => {
        this.toastr.error(err[0]);
      }
    );
  }

  teamPage(slug) {
    this.router.navigate([`team`], { queryParams: { team: slug } });
  }
}
