<div class="login-container">
  <div mat-dialog-content>
    <!-- <h1  class="mb-3">Login or Register</h1> -->
    <button mat-dialog-close mat-button><mat-icon inline="true">arrow_back</mat-icon> Back</button>
    <div class="login-title" *ngIf="">
      <p>Discover the world's number one Ramadhan Giving platform</p>
    </div>
    <div>
      <display-message type="success" [error]="error"></display-message>
      <user-login
        [dialog]="true"
        [email]="email"
        (email)="email = $event"
        (forgot)="forgotPassword($event)"
        *ngIf="type == 'login'"
        (authenticated)="loggedIn($event)"
      ></user-login>
      <user-register
        [email]="email"
        (email)="email = $event"
        (exists)="loginExistingUser($event)"
        *ngIf="type == 'register'"
        (authenticated)="loggedIn($event)"
      ></user-register>
      <forgot-password
        (authenticated)="loggedIn($event)"
        (email)="email = $event"
        [email]="email"
        *ngIf="type == 'forgot'"
        (login)="move('login')"
      ></forgot-password>
    </div>
  </div>

  <div mat-dialog-actions>
    <div class="w-full h-full pb-5" fxLayout="column" fxLayoutAlign="center center">
      <!-- <p *ngIf="type == 'register'" class="link text-center">
        Already have an account?<br />
        <a href="javascript:{}" (click)="move('login')">Sign in here</a>
      </p> -->
      <p *ngIf="type == 'login'" class="link text-center">
        Don't have an account?<br />
        <a href="javascript:{}" (click)="move('register')">Register here</a>
      </p>
    </div>
  </div>
</div>
