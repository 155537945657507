import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { JwtService } from '../../../../api/src/lib/jwt.service';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from './window.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';
import { Store } from '@ngxs/store';
import { Logout } from './store/auth.actions';
import { Roles } from './user.constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRef,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = this.authenticationService.authenticated;

    console.log('auth guard, user is authed:', authenticated);

    if (authenticated) {
      // console.log('jwt:', this.authenticationService.jwt);
      // logged in so return false as user doesnt need to login
      // this.router.navigate(['/'], {
      //   queryParams: { returnUrl: route.queryParams.returnUrl },
      // });
      // window.location.href = route.queryParams.continue;
      // console.log('already logged in');
      return true;
    }

    const redirect = getRedirectUrl(route, state);

    if (isPlatformBrowser(this.platformId)) {
      console.log('auth guard browser, redirecting to:', redirect);
      window.location.href = redirect;
    } else {
      // console.log('redirect to auth');
      // this.response.location(redirect);
      // this.response.status(301);
      console.log('auth guard servier, redirecting to:', redirect);

      this.router.navigate(['/'], { queryParams: { redirect: redirect } });
    }
    return false;

    // console.log('authguard, server, returning false');

    // else {
    //   window.location.href = redirect;
    // }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    // return false;
  }
}

@Injectable({ providedIn: 'root' })
export class CharityAuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private authenticationService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;

    let browser = isPlatformBrowser(this.platformId);
    if (browser) {
      const authenticated = this.authenticationService.authenticated;
      const role = this.authenticationService.userRole;

      const redirect = getRedirectUrl(route, state);
      if (authenticated) {
        if (role == Roles.admin || role == Roles.editor || role == Roles.superAdmin) {
          return true;
        } else {
          this.router.navigate(['/unauthorised']);
          return false;
        }
      } else {
        console.log('not unauthorised');

        // not logged in so redirect to login page with the return url
        // Logout user to avoid redirect loop
        // this.router.navigate([], { queryParams: { returnUrl: state.url } });

        // this.store.dispatch(new Logout());

        if (isPlatformBrowser(this.platformId)) {
          window.location.href = redirect;
        } else {
          this.response.location(redirect);
        }

        return false;
      }
    } else {
      return true;
    }
    // return false;
  }
}

function getEnvironment(route) {
  // const data = {
  //   ...route.parent.data,
  //   ...route.parent.parent.data,
  //   ...route.parent.parent.parent.data,
  //   ...route.parent.parent.parent.parent.data,
  //   ...route.data,
  // };

  const data = route.root.firstChild.data;
  return data.environment ? data.environment : route.data.environment;
}

function getRedirectUrl(route, state) {
  let environment = getEnvironment(route);
  console.log(environment.apps.authentication.url);
  return (
    environment.apps.authentication.url +
    '/signin?returnUrl=' +
    encodeURIComponent(environment.app.url + state.url)
  );
}
