import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import icMail from '@iconify/icons-ic/twotone-mail';
import icCheck from '@iconify/icons-ic/check';
import { Store, Select } from '@ngxs/store';
import { Observable, fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';
import { JwtService } from '@givebrite/api';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ForgotPasswordRg, GetUser, UserRg, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { fadeInUp400ms } from 'libs/theming/src/lib/animations/fade-in-up.animation';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInUp400ms],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  @Input() set email(email) {
    if (email) {
      this.form.get('email').setValue(email);
    }
  }
  @Input() dialog: boolean;
  @Output() login: EventEmitter<UserRg> = new EventEmitter();
  @Output() authenticated: EventEmitter<UserRg> = new EventEmitter();
  @Select(UserStateRg.getUser) user$: Observable<UserRg>;
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  error: string;
  icMail = icMail;
  icCheck = icCheck;
  recovering: boolean;
  resetted: boolean;
  user: UserRg;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private jwt: JwtService,
    private router: Router
  ) {}

  ngOnInit() {}

  send() {
    this.store.dispatch(new ForgotPasswordRg(this.form.value)).subscribe(
      (response) => {
        this.recovering = true;
        const source$ = fromEvent(window, 'storage');
        source$
          .pipe(delay(500))
          .pipe(untilDestroyed(this))
          .subscribe((ldata) => {
            console.log(this.jwt.getToken());

            this.store.dispatch(new GetUser()).subscribe((store) => {
              this.resetted = true;
              this.user = store.user.user;
            });

            /*if(action){
          if(action === STATUS.login){
            this.store.dispatch(new Logout());
            this.status = STATUS.logout;
          } else {
            this.store.dispatch(new Login());
            this.status = STATUS.login;
          }
        } else {
          console.log("Nothing added yet");
        }*/
          });
      },
      (error) => {
        this.error = 'Something went wrong, please try again';
      }
    );
  }

  backToLogin() {
    this.login.emit();
    if (!this.dialog) {
      this.router.navigateByUrl('/user/login');
    }
  }

  continue() {
    this.authenticated.emit(this.user);
  }

  ngOnDestroy() {}
}
