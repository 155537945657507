import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Arrow, AutoPlay, Pagination } from '@egjs/flicking-plugins';
import { NgxFlickingComponent, Plugin } from '@egjs/ngx-flicking';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HeroDialogComponent } from '../hero/hero-dialog/hero-dialog.component';

@UntilDestroy()
@Component({
  selector: 'givebrite-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnInit, AfterViewInit {
  _cover = [];
  @Input() set cover(cover) {
    if (cover) {
      this._cover = cover;
    }
  }

  /** HEIGHT OF THE HERO SECTION */
  @Input()
  get height() {
    return this._height;
  }

  set height(height: string) {
    if (height) {
      this._height = height;
    }
  }

  private _height = '800';

  /** STYLE OPTIONS */
  @Input()
  get options() {
    return this._options;
  }

  set options(options: any) {
    if (options) {
      this._options = options;
    }
  }

  private _options: any;

  @ViewChild('hero') hero: NgxFlickingComponent;
  innerWidth = 375;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading: boolean = true;
  currentIndex: number = 0;

  plugins: Plugin[] = [
    new AutoPlay({ duration: 5000, direction: 'NEXT', stopOnHover: true }),
    new Arrow(),
    new Pagination({ type: 'bullet' }),
  ];

  constructor(private dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }

    this.loading$.next(false);
    this.loading$.pipe(untilDestroyed(this), debounceTime(300)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  /** OPEN HERO DIALOG */
  openDialog(id) {
    this.dialog.open(HeroDialogComponent, {
      data: { videoURL: `https://www.youtube.com/embed/${id}` },
      panelClass: 'video-dialog-panel',
    });
  }

  ngAfterViewInit() {
    // Change the index of the current active panel
    // this.hero.ready.subscribe(() => {
    //   this.hero.willChange.subscribe((e) => {
    //     this.currentIndex = e.index;
    //   });
    // });
  }
}
