import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { Appeal, Project } from './../skt.interface';
import { SktService } from './../skt.service';
import {
  GetAppeal,
  GetAppeals,
  GetMerchantSessionKey,
  GetProject,
  GetProjects,
  GetVoluntaryOptions,
  ResetMsk,
  SagePay3dDonation,
  SagePayDonation,
} from './skt.actions';

export class SktStateModel {
  appeal: Appeal;
  appeals: Appeal[];
  loading?: boolean;
  total: number;
  msk: string;
  project: Project;
  projects: Project[];
  sagePayDonation: any;
  sagePay3dDonation: any;
  voluntaryOptions: [];
}

@Injectable()
@State<SktStateModel>({
  name: 'skt',
  defaults: {
    appeal: null,
    appeals: [],
    loading: false,
    total: 0,
    msk: null,
    project: null,
    projects: null,
    sagePayDonation: null,
    sagePay3dDonation: null,
    voluntaryOptions: [],
  },
})
export class SktState {
  constructor(private sktService: SktService) {}

  @Selector()
  static getProject(state: SktStateModel) {
    return state.appeal;
  }

  @Selector()
  static getAppeal(state: SktStateModel) {
    return state.appeal;
  }

  @Selector()
  static getAppeals(state: SktStateModel) {
    return state.appeals;
  }

  @Selector()
  static getProjects(state: SktStateModel) {
    return state.projects;
  }

  @Selector()
  static loading(state: SktStateModel) {
    return state.loading;
  }

  @Selector()
  static msk(state: SktStateModel) {
    return state.msk;
  }

  @Selector()
  static sagePayDonation(state: SktStateModel) {
    return state.sagePayDonation;
  }

  @Selector()
  static getVoluntaryOptions(state: SktStateModel) {
    return state.voluntaryOptions;
  }

  @Action(GetAppeal)
  getAppeal({ patchState }: StateContext<SktStateModel>, { id, type }) {
    patchState({ loading: true });
    return this.sktService.appeal(id, type).pipe(
      tap(
        (result: Appeal) => {
          patchState({
            appeal: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            appeal: null,
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetAppeals)
  getAppeals({ patchState }: StateContext<SktStateModel>, { filter_by, value, is_featured }) {
    patchState({ loading: true });
    return this.sktService.appeals(filter_by, value, is_featured).pipe(
      tap(
        (result: Appeal[]) => {
          patchState({
            appeals: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            appeals: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetProject)
  getProject({ patchState }: StateContext<SktStateModel>, { id, type }) {
    patchState({ loading: true });
    return this.sktService.project(id, type).pipe(
      tap(
        (result: Project) => {
          patchState({
            project: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            project: null,
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetProjects)
  getProjects({ patchState }: StateContext<SktStateModel>, { is_featured }) {
    patchState({ loading: true });
    return this.sktService.projects(is_featured).pipe(
      tap(
        (result: Project[]) => {
          patchState({
            projects: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            projects: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetMerchantSessionKey)
  getMerchantSessionKey({ patchState }: StateContext<SktStateModel>) {
    patchState({ loading: true });
    return this.sktService.getMerchantSessionKey().pipe(
      tap(
        (result: any) => {
          patchState({
            msk: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            msk: null,
            loading: false,
          });
        }
      )
    );
  }

  @Action(SagePayDonation)
  createSagePayDonation({ patchState }: StateContext<SktStateModel>, { payload }) {
    patchState({ loading: true });
    return this.sktService.sagePayDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            sagePayDonation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }

  @Action(SagePay3dDonation)
  createSagePay3dDonation({ patchState }: StateContext<SktStateModel>, { payload }) {
    patchState({ loading: true });
    return this.sktService.sagePay3dDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            sagePay3dDonation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }

  @Action(ResetMsk)
  resetMsk(ctx: StateContext<SktStateModel>) {
    ctx.setState(
      patch({
        msk: null,
      })
    );
  }

  @Action(GetVoluntaryOptions)
  getVoluntaryOptions({ patchState }: StateContext<SktStateModel>, { contribution }) {
    patchState({ loading: true });
    return this.sktService.getVoluntaryOptions(contribution).pipe(
      tap(
        (result) => {
          patchState({
            voluntaryOptions: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            voluntaryOptions: [],
            loading: false,
          });
        }
      )
    );
  }
}
