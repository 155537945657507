<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <h1>{{ title }}</h1>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-tab-group [selectedIndex]="selectedTabIndex">
    <mat-tab [disabled]="type !== 'image' && only" label="Image">
      <ng-template mat-tab-label>
        <span (click)="type = 'image'">
          <mat-icon class="material-icons-outlined">insert_photo</mat-icon>
          Image</span
        >
      </ng-template>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2rem" class="pt-3">
        <!-- <div fxFlex="20%" fxLayout="column">
          <givebrite-image-helper></givebrite-image-helper>
        </div> -->
        <div fxFlex="100">
          <givebrite-uploader-drop-zone
            [ratio]="16 / 9"
            [events]="events$"
            (image)="imageAdded($event)"
          ></givebrite-uploader-drop-zone>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="type !== 'image' && only" label="Video">
      <ng-template mat-tab-label>
        <span (click)="type = 'video'">
          <mat-icon class="video-tab-icon">play_circle</mat-icon>
          Video</span
        ></ng-template
      >

      <div fxLayout="row" fxLayoutGap="2rem" class="pt-3">
        <!-- <div fxFlex="20%" fxLayout="column">
          <givebrite-video-helper></givebrite-video-helper>
        </div> -->
        <div fxFlex="100">
          <givebrite-video-uploader
            (added)="videoAdded($event)"
          ></givebrite-video-uploader>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="type == 'avatar'" label="Avatar">
      <ng-template mat-tab-label>
        <span (click)="type = 'avatar'">
          <mat-icon class="video-tab-icon">person</mat-icon>
          Avatar</span
        ></ng-template
      >

      <div class="pt-3 w-full">
        <givebrite-uploader-drop-zone
          [ratio]="1 / 1"
          [events]="events$"
          (image)="imageAdded($event)"
        ></givebrite-uploader-drop-zone>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions fxLayout="row" class="text-right">
  <div fxFlex></div>
  <button mat-dialog-close mat-button>Cancel</button>
  <button *ngIf="image" mat-button (click)="removeImage()" color="primary">
    Remove
  </button>
  <button
    *ngIf="type == 'image'"
    (click)="insertImage()"
    [disabled]="!image"
    mat-raised-button
    color="primary"
  >
    Insert Image
  </button>
  <button
    *ngIf="type == 'video'"
    (click)="insertVideo()"
    mat-raised-button
    [disabled]="!video"
    color="primary"
  >
    Insert Video
  </button>

  <button
    *ngIf="type == 'avatar'"
    (click)="insertImage()"
    mat-raised-button
    color="primary"
  >
    Choose AVatar
  </button>
</div>
