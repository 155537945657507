<div class="campaign-amount" [class.hasImpacts]="impacts">
  <h1 *ngIf="impacts" class="text-center my-6">I want to raise...</h1>

  <!-- Loops through the default fundraising amounts and displays them as clickable boxes -->
  <div class="mb-3" fxLayout="row wrap" fxLayoutAlign="center center">
    <div
      fxFlex="calc(50% - 0.5rem)"
      class="mb-3"
      [class.mr-3]="i % 2 == 0"
      [class.hidden]="!impacts"
      *ngFor="let amount of defaultAmounts; last as isLast; let i = index"
    >
      <mat-card
        appearance="outlined"
        (click)="goal = amount"
        [ngClass]="goal == amount ? 'card-item-shadow' : ''"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content>
          <p [ngClass]="goal == amount ? 'card-item-text' : ''">
            {{ amount | currency: (config$ | async).currency:'symbol' }}
          </p>

          <givebrite-tick-mark [checked]="goal == amount"></givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
      <mat-form-field class="w-full">
        <input matInput type="number" placeholder="Fundraising goal" [(ngModel)]="goal" />
        <span style="top: 0" matPrefix *ngIf="config$ | async as config">
          {{ currencySymbol(config.currency) }}
        </span>
      </mat-form-field>
    </div>
  </div>
</div>
