import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QurbaniGivingToolbarComponent } from './qurbani-giving-toolbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { ToolbarNotificationsModule } from '@givebrite/theming';
import { ToolbarUserModule } from '@givebrite/theming';
import { ToolbarSearchModule } from '@givebrite/theming';
// import { IconModule } from "@givebrite/theming";
import { NavigationModule } from '@givebrite/theming';
// import { RouterModule } from "@givebrite/theming";
import { NavigationItemModule } from '@givebrite/theming';
import { MegaMenuModule } from '@givebrite/theming';
import { ContainerModule } from '@givebrite/theming';
import { QurbaniGivingToolbarUserModule } from '../toolbar-user/qurbani-giving-toolbar-user.module';
import { IconModule } from '@abhinavakhil/iconify-angular';

@NgModule({
  declarations: [QurbaniGivingToolbarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    ToolbarNotificationsModule,
    QurbaniGivingToolbarUserModule,
    ToolbarSearchModule,
    IconModule,
    NavigationModule,
    // RouterModule,
    NavigationItemModule,
    MegaMenuModule,
    ContainerModule,
  ],
  exports: [QurbaniGivingToolbarComponent],
})
export class QurbaniGivingToolbarModule {}
