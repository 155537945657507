<div class="hero relative">
  <ngx-skeleton-loader
    [class.hidden]="!loading"
    [theme]="{
      width: '100%',
      height: height,
      'max-height': height,
      'margin-bottom': '-10px',
      'border-radius': 8 + 'px'
    }"
  ></ngx-skeleton-loader>

  <ngx-flicking
    #hero
    class="z-0"
    [options]="{
      bound: true,
      align: 'prev',
      autoResize: true,
      autoPlay: true
    }"
    [plugins]="plugins"
    [fxHide]="loading"
  >
    <div
      flicking-panel
      class="flicking-panel w-full relative"
      *ngFor="let slide of _cover; let index = index"
    >
      <img
        *ngIf="slide?.type === 'image'"
        responsive
        [style.height]="height"
        [style.borderRadius]="options?.borderRadius"
        [height]="height"
        fit="max"
        [src]="slide?.image?.cropped?.url || slide?.image?.original?.url"
      />
      <img *ngIf="slide?.type === 'video'" [src]="slide?.thumbnail" />
      <!-- Clicking this opens a video dialog with embedded media -->
      <button
        *ngIf="slide?.type === 'video'"
        mat-fab
        aria-label="Play video"
        (click)="openDialog(slide?.video?.id)"
      >
        <mat-icon class="material-icons-outlined">play_arrow</mat-icon>
      </button>
    </div>
    <span in-viewport class="flicking-arrow-prev"></span>
    <span in-viewport class="flicking-arrow-next"></span>
    <div in-viewport class="flicking-pagination"></div>
  </ngx-flicking>

  <!-- <div fxLayoutAlign="center center" fxLayoutGap="10px" class="navigation-dots">
    <a
      *ngFor="let slide of (campaign$ | async).cover; let index = index"
      class="dot"
      (click)="move(index)"
      [class.active]="index == currentIndex"
    ></a>
  </div> -->
</div>
