export class GetCharitiesRg {
  static readonly type = '[Charities] List';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public filter: string = '',
    public sort: string = 'asc',
    public column: string = '',
    public featured: number = 0
  ) {}
}

export class GetCharitiesStories {
  static readonly type = '[Charities] List Stories';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public filter: string = '',
    public sort: string = 'asc',
    public column: string = '',
    public featured: number = 0
  ) {}
}

export class GetCharityById {
  static readonly type = '[Charities] Get Charity By Id';
  constructor(public id: string) {}
}

export class UpdateCharity {
  static readonly type = '[Charities] Update Charity By Id';
  constructor(
    public id,
    public payload: { campaigns?: string[]; logo_white?: string; is_featured?: boolean }
  ) {}
}

export class GetCharityLeaderboard {
  static readonly type = '[Charities] Get Leaderboard';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'asc',
    public column: string = ''
  ) {}
}

export class ResetCharity {
  static readonly type = '[Charities] Reset';
}
