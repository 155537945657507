import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteCoverComponent } from './invite-cover.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';

@NgModule({
  declarations: [InviteCoverComponent],
  imports: [CommonModule, FlexLayoutModule, UserLogoAvatarModule],
  exports: [InviteCoverComponent],
})
export class InviteCoverModule {}
