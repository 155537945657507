import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopTeamComponent } from './top-team.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { UserLogoAvatarModule } from 'libs/core/src/lib/user/user-logo-avatar/user-logo-avatar.module';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [TopTeamComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    UserLogoAvatarModule,
    MatCardModule,
    MatButtonModule,
    TruncateStringModule,
  ],
  exports: [TopTeamComponent],
})
export class TopTeamModule {}
