import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { Authenticate, User } from './user.interface';
import { CookieService } from 'ngx-cookie';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: ApiService,
    private cookies: CookieService,
    private store: Store
  ) {}

  login(data: Authenticate) {
    return this.http.post('user/login', data);
  }

  /**
   *  Return JWT from cookies
   **/
  get jwt() {
    return this.cookies.get('jwt');
  }

  /**
   * Populate the store with the logged in user based on JWT token
   */
  get userFromJwt(): User {
    return this.jwt ? jwt_decode(this.jwt) : null;
    // let user$: Subject<User> = new Subject();
    // if (jwt) {
    //   // Decode the JWT using the built in Javascript method
    //   let decoded: User = JSON.parse(atob(jwt.split('.')[1]));
    //   console.log(decoded);
    //   user$.next(decoded);
    // }
    // return user$;
  }
}
