<div class="frequency-selector">
  <mat-button-toggle-group
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="my-4 mt-6"
    [(ngModel)]="frequency"
  >
    <mat-button-toggle value="one-off" fxFlex="50">SINGLE PAYMENT</mat-button-toggle>
    <mat-button-toggle value="month" fxFlex="50">REGULAR PAYMENT</mat-button-toggle>
  </mat-button-toggle-group>
</div>
