import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  GetDashboardStatistics,
  GetDashboardCampaigns,
  CreateDashboardCampaign,
  GetDashboardCampaign,
  EditDashboardCampaign,
  GetDashboardCampaignTeams,
  GetDashboardCampaignFundraisers,
  GetDashboardCampaignDonations,
  AddOfflineDonation,
  EndCampaign,
  GetFundraiser,
  EndDeleteFundraiser,
  GetComments,
  AddReply,
  DeleteReply,
  UpdateReply,
  GetTeam,
  DeleteTeam,
  AddFundraiser,
  GetCharityFollowers,
  EditDashboardFundraiser,
  EditTeam,
  AddTeam,
  DeleteComment,
  UpdateComment,
  ExportDonations,
  ExportStatus,
  GetDonationsStatistics,
  RemoveDashBoardError,
  RemoveSuccessMessage,
  ExportFundraisers,
  GetQGStatistics,
  GetAnimalsList,
  AddStock,
  UpdateStock,
  GetStocksList,
  GetStockById,
  GetAnimalsByCountry,
  GetDashboardQGDonations,
  ExportQGDonations,
  CreateDashboardProject,
  GetDashboardProject,
  EditDashboardProject,
  GetProjectCategories,
  EditProjectCategory,
  GetProjectCategory,
  CreateProjectCategory,
  GetDashboardProjects,
  GetDashboardUnVerifiedCharitiesCount,
  GetCharityCounts,
  GetCampaignsStatistics,
  GetDonationsStatisticsAll,
  GetRamadhanGivingStatistics,
  GetDashboardRGSubscriptions,
  GetAllDashboardProjects,
  CreateProjectCauses,
  RemoveProjectCauses,
  GetDonationById,
  EditDashboardDonation,
  GetUserStatistics,
  MoveFundraiserToTeam,
  MoveFundraiserToCampaign,
  MoveDonationToFundraiser,
  MoveDonationToTeam,
  GetSubscriptionById,
  EditDashboardSubscription,
  RefundDashboardDonation,
  UploadStockFile,
  CheckStockFile,
  GetAppSettings,
  UpdateAppSettings,
  UploadLogoFile,
  DeleteProject,
  GetDashboardUsers,
  CreateDashboardUser,
  EditDashboardUser,
  GetDashboardCharities,
  EditCharity,
  GetUsersStatistics,
  GetUserById,
  GetDashboardProjectAppeal,
  DeleteProjectAppeal,
  CreateDashboardProjectAppeal,
  EditDashboardProjectAppeal,
  GetDashboardAllAppeal,
  EnableDashboardUser,
  RemoveDashboardUser,
  ResetPasswordLinkDashboardUser,
  ResetDashboardUser,
  GetDashboardCampaignSubscriptions,
  ExportQGStock,
  ExportRGDonations,
  ExportRGSubscriptions,
  ExportAppealCSV,
  ExportProjectCSV,
  GetDashboardAppeal,
  SyncDashboardAppeal,
  SyncDashboardProject,
  GetDonationsTypes,
} from './dashboard.actions';
import { DashboardService } from '../dashboard.service';
import {
  Animal,
  DashboardStatistics,
  DonationsStatistics,
  IQurbaniStatistics,
  QurbaniDonation,
  Stock,
  UsersStatistics,
} from '../dashboard.interface';
import { Campaign, Fundraiser, Team } from '../../campaign';
import { Pagination } from '../../pagination';
import { Donation } from '../../donation';
import { DashboardFollowers } from '..';
import { Projects_ } from '../../project/project.interface';

export class DashboardStateModel {
  loading?: boolean;
  error: any;
  successMessage: string;
  dashboardStatistics: DashboardStatistics;
  donationsStatistics: DonationsStatistics;
  ramadhanGivingStatistics: DonationsStatistics;
  donationsStatisticsAll: DonationsStatistics;
  // usersStatistics: UsersStatistics;
  qurbaniStatistics: IQurbaniStatistics;
  campaign: Campaign;
  campaigns: Campaign[];
  pagination: Pagination;
  fundraiser: Fundraiser;
  fundraisers: {
    list: Fundraiser[];
    pagination: Pagination;
  };
  donations: {
    list: Donation[];
    pagination: Pagination;
  };
  subscriptions: {
    list: Donation[];
    pagination: Pagination;
  };
  teams: {
    list: Team[];
    pagination: Pagination;
  };
  comments: {
    list: any[];
    pagination: Pagination;
  };
  team: Team;
  followers: DashboardFollowers;
  user;
  donation:any;
  subscription:any;
  export: any;
  exportFundraiser: any;
  exportStatus: any;
  animalsList: Animal[];
  stockStatistic: any;
  stocksList: {
    list: Stock[];
    pagination: Pagination;
  };
  specificStock: Stock;
  qurbaniDonations: {
    list: QurbaniDonation[];
    pagination: Pagination;
  };
  project: Projects_;
  projects: Projects_[];
  projectsAll: Projects_[];
  projectCategory: any;
  projectCategorys: any;
  projectAppeals: any;
  appeals: any;
  appeal: any;
  donationTypes: any;
  unVerifiedCharityCount: any;
  countStatistics: any;
  usersStatistics: any;
  campaignStatistics: any;
  charities: {
    list: any;
    pagination: Pagination;
  };
  searchedCharities: {
    list: any;
    pagination: Pagination;
  };
  users: {
    list: any;
    pagination: Pagination;
  };
  appsSettings: any;
  metaData: any;
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    loading: false,
    successMessage: null,
    error: null,
    dashboardStatistics: null,
    donationsStatistics: null,
    ramadhanGivingStatistics: null,
    donationsStatisticsAll: null,
    usersStatistics: null,
    qurbaniStatistics: null,
    campaigns: [],
    pagination: null,
    campaign: null,
    fundraiser: null,
    fundraisers: null,
    donations: null,
    subscriptions: null,
    teams: null,
    comments: null,
    team: null,
    followers: null,
    user: null,
    donation: null,
    subscription: null,
    export: null,
    exportFundraiser: null,
    exportStatus: null,
    animalsList: null,
    stocksList: null,
    specificStock: null,
    stockStatistic: null,
    qurbaniDonations: null,
    project: null,
    appeal: null,
    projects: [],
    projectsAll: [],
    projectAppeals: [],
    appeals:[],
    projectCategory: null,
    projectCategorys: [],
    donationTypes: null,
    charities: null,
    searchedCharities: null,
    users: null,
    unVerifiedCharityCount: 0,
    countStatistics: null,
    campaignStatistics: null,
    appsSettings:null,
    metaData:null,
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Selector()
  static getCampaigns(state: DashboardStateModel) {
    return state.campaigns;
  }

  @Selector()
  static dashboardCampaignsStatistics(state: DashboardStateModel) {
    return state.dashboardStatistics;
  }

  @Selector()
  static pagination(state: DashboardStateModel) {
    return state.pagination;
  }

  @Selector()
  static loading(state: DashboardStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: DashboardStateModel) {
    return state.error;
  }

  @Selector()
  static successMessage(state: DashboardStateModel) {
    return state.successMessage;
  }

  @Selector()
  static fundraiser(state: DashboardStateModel) {
    return state.fundraiser;
  }
  @Selector()
  static team(state: DashboardStateModel) {
    return state.team;
  }

  @Selector()
  static campaign(state: DashboardStateModel) {
    return state.campaign;
  }

  @Selector()
  static donations(state: DashboardStateModel) {
    return state.donations;
  }
  @Selector()
  static donation(state: DashboardStateModel) {
    return state.donation;
  }
  @Selector()
  static subscriptionData(state: DashboardStateModel) {
    return state.subscription;
  }
  @Selector()
  static subscriptions(state: DashboardStateModel) {
    return state.subscriptions;
  }

  @Selector()
  static donationsStats(state: DashboardStateModel) {
    return state.donationsStatistics;
  }
  @Selector()
  static usersStats(state: DashboardStateModel) {
    return state.usersStatistics;
  }
  @Selector()
  static donationsStatsistics(state: DashboardStateModel) {
    return state.donationsStatisticsAll;
  }

  @Selector()
  static comments(state: DashboardStateModel) {
    return state.comments;
  }

  @Selector()
  static fundraisers(state: DashboardStateModel) {
    return state.fundraisers;
  }
  @Selector()
  static teams(state: DashboardStateModel) {
    return state.teams;
  }
  @Selector()
  static followers(state: DashboardStateModel) {
    return state.followers;
  }

  @Selector()
  static export(state: DashboardStateModel) {
    return state.export;
  }

  @Selector()
  static qurbaniStats(state: DashboardStateModel) {
    return state.qurbaniStatistics;
  }
  @Selector()
  static ramadhanGivingStat(state: DashboardStateModel) {
    return state.ramadhanGivingStatistics;
  }

  @Selector()
  static animalsList(state: DashboardStateModel) {
    return state.animalsList;
  }

  @Selector()
  static stocksList(state: DashboardStateModel) {
    return state.stocksList;
  }

  @Selector()
  static specificStock(state: DashboardStateModel) {
    return state.specificStock;
  }

  @Selector()
  static qurbaniDonations(state: DashboardStateModel) {
    return state.qurbaniDonations;
  }

  @Selector()
  static countStatistics(state: DashboardStateModel) {
    return state.countStatistics;
  }
  @Selector()
  static campaignStatistics(state: DashboardStateModel) {
    return state.campaignStatistics;
  }

  //Project
  @Selector()
  static project(state: DashboardStateModel) {
    return state.project;
  }
  @Selector()
  static appeal(state: DashboardStateModel) {
    return state.appeal;
  }
  @Selector()
  static getProjects(state: DashboardStateModel) {
    return state.projects;
  }
  @Selector()
  static getProjectsAll(state: DashboardStateModel) {
    return state.projectsAll;
  }

  @Selector()
  static projectCategory(state: DashboardStateModel) {
    return state.projectCategory;
  }
  @Selector()
  static projectAppeals(state: DashboardStateModel) {
    return state.projectAppeals;
  }
  @Selector()
  static allAppeals(state: DashboardStateModel) {
    return state.appeals;
  }
  @Selector()
  static getDashboardProjectscategories(state: DashboardStateModel) {
    return state.projectCategorys;
  }
  @Selector()
  static getDonationTypes(state: DashboardStateModel) {
    return state.donationTypes;
  }
  @Selector()
  static getUnVerifiedCharityCount(state: DashboardStateModel) {
    return state.unVerifiedCharityCount;
  }

  //Charities
  @Selector()
  static getSearchedCharities(state: DashboardStateModel) {
    return state.searchedCharities;
  }
  @Selector()
  static getCharities(state: DashboardStateModel) {
    return state.charities;
  }
  @Selector()
  static getUsers(state: DashboardStateModel) {
    return state.users;
  }
  @Selector()
  static getUser(state: DashboardStateModel) {
    return state.user;
  }
  @Selector()
  static getAppsSetting(state: DashboardStateModel) {
    return state.appsSettings;
  }

  @Action(GetDashboardStatistics)
  getDashboardStatistics({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getDashboardStatistics().pipe(
      tap(
        (result: DashboardStatistics) => {
          patchState({
            dashboardStatistics: result,
          });
        },
        (error) => {
          patchState({
            dashboardStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(GetDashboardCampaigns)
  getDashboardCampaigns(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { filter_by, search, page, limit, sort_value, sort_title, from_date, to_date, value }
  ) {
    patchState({ loading: true, campaigns: [] });
    return this.dashboardService
      .getDashboardCampaigns(
        filter_by,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date,
        value
      )
      .pipe(
        tap(
          (result: { campaigns: Campaign[]; pagination: Pagination }) => {
            patchState({
              campaigns: result.campaigns,
              pagination: result.pagination,
              loading: false,
            });
          },
          (error) => {
            patchState({
              campaigns: [],
              loading: false,
              error: error,
              pagination: null,
            });
          }
        )
      );
  }

  @Action(CreateDashboardCampaign)
  createDashboardCampaign({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.create(payload).pipe(
      tap(
        (result: { campaign: Campaign }) => {
          patchState({ loading: false, campaign: result.campaign });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetDashboardCampaign)
  getDashboardCampaign({ patchState }: StateContext<DashboardStateModel>, { slug }) {
    patchState({ loading: true });
    return this.dashboardService.getCampaign(slug).pipe(
      tap(
        (result: Campaign) => {
          patchState({
            campaign: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(EditDashboardCampaign)
  editDashboardCampaign(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { slug, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.edit(slug, payload).pipe(
      tap(
        (result: Campaign) => {
          patchState({
            loading: false,
            campaign: result,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardCampaignDonations)
  getDashboardCampaignDonations(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    {
      id,
      filter_by,
      type,
      search,
      page,
      limit,
      // title_sort,
      // date_sort,
      sort_value,
      sort_title,
      from_date,
      to_date,
    }
  ) {
    patchState({ loading: true, donations: null});
    return this.dashboardService
      .donations(
        id,
        filter_by,
        type,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date
      )
      .pipe(
        tap(
          (result: { donations: Donation[]; pagination: Pagination }) => {
            patchState({
              donations: {
                list: result.donations,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              donations: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(GetDashboardCampaignSubscriptions)
  getDashboardCampaignSubscriptions(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    {
      id,
      filter_by,
      type,
      search,
      page,
      limit,
      // title_sort,
      // date_sort,
      sort_value,
      sort_title,
      from_date,
      to_date,
    }
  ) {
    patchState({ loading: true });
    return this.dashboardService
      .subscriptionsList(
        id,
        filter_by,
        type,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date
      )
      .pipe(
        tap(
          (result: { subscriptions: Donation[]; pagination: Pagination }) => {
            patchState({
              subscriptions: {
                list: result.subscriptions,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              subscriptions: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(GetDashboardRGSubscriptions)
  getDashboardRGSubscriptions(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    {
      id,
      filter_by,
      type,
      search,
      page,
      limit,
      // title_sort,
      // date_sort,
      sort_value,
      sort_title,
      from_date,
      to_date,
    }
  ) {
    patchState({ loading: true });
    return this.dashboardService
      .subscriptionsRGList(
        id,
        filter_by,
        type,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date
      )
      .pipe(
        tap(
          (result: { subscriptions: Donation[]; pagination: Pagination }) => {
            patchState({
              subscriptions: {
                list: result.subscriptions,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              subscriptions: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(GetDonationsStatistics)
  getDonationsStatistics({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getDonationsStatistics().pipe(
      tap(
        (result) => {
          patchState({
            donationsStatistics: result[0],
          });
        },
        (error) => {
          patchState({
            donationsStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  
  @Action(GetUserStatistics)
  getUserStatistics({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getUserStatistics().pipe(
      tap(
        (result) => {
          patchState({
            usersStatistics: result,
          });
        },
        (error) => {
          patchState({
            usersStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetRamadhanGivingStatistics)
  getRamadhanGivingStatistics({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getRamadhanGivingStatistics().pipe(
      tap(
        (result) => {
          patchState({
            ramadhanGivingStatistics: result.statistics,
          });
        },
        (error) => {
          patchState({
            ramadhanGivingStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDonationsStatisticsAll)
  getDonationsStatisticsAll({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getDonationsStatisticsAll().pipe(
      tap(
        (result) => {
          patchState({
            donationsStatisticsAll: result.statistics,
          });
        },
        (error) => {
          patchState({
            donationsStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetUsersStatistics)
  getUsersStatistics({ patchState }: StateContext<DashboardStateModel>) {
    return this.dashboardService.getUserStatistics().pipe(
      tap(
        (result) => {
          patchState({
            usersStatistics: result,
          });
        },
        (error) => {
          patchState({
            usersStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardCampaignFundraisers)
  getDashboardCampaignFundraisers(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, filter_by, search, page, limit, sort_value, sort_title, from_date, to_date }
  ) {
    patchState({ fundraisers: null, loading: true });
    return this.dashboardService
      .fundraisers(id, filter_by, search, page, limit, sort_value, sort_title, from_date, to_date)
      .pipe(
        tap(
          (result: { fundraisers: Fundraiser[]; pagination: Pagination }) => {
            patchState({
              fundraisers: {
                list: result.fundraisers,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              fundraisers: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(GetDashboardCampaignTeams)
  getDashboardCampaignTeams(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, filter_by, search, page, limit, sort_value, sort_title, from_date, to_date }
  ) {
    patchState({ loading: true, teams: null });
    return this.dashboardService
      .teams(id, filter_by, search, page, limit, sort_value, sort_title, from_date, to_date)
      .pipe(
        tap(
          (result: { teams: Team[]; pagination: Pagination }) => {
            patchState({
              teams: {
                list: result.teams,
                pagination: result.pagination,
              },
              pagination: result.pagination,
              loading: false,
            });
          },
          (error) => {
            patchState({
              teams: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(AddOfflineDonation)
  addOfflineDonation({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    // let campaignId = getState().campaign._id;
    // payload = {
    //   ...payload,
    //   campaign: campaignId,
    // };
    return this.dashboardService.addOfflineDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'Offline donation added successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }
 
  @Action(MoveFundraiserToTeam)
  moveFundraiserToTeam({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.moveFundraiserToTeam(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'fundraiser move to team successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(MoveFundraiserToCampaign)
  moveFundraiserToCampaign({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.moveFundraiserToCampaign(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'fundraiser move to campaign successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(MoveDonationToFundraiser)
  moveDonationToFundraiser({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.moveDonationToFundraiser(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'donation move to fundraiser successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }
  
  @Action(MoveDonationToTeam)
  moveDonationToTeam({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.moveDonationToTeam(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'donation move to Team successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(CheckStockFile)
  checkStockFile({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.checkStockFile(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            stockStatistic: result,
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }
  @Action(UploadStockFile)
  uploadStockFile({ patchState, getState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.uploadStockFile(payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            successMessage: 'Import livestock data successfully.',
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }
  @Action(UploadLogoFile)
  uploadLogoFile({ patchState, getState }: StateContext<DashboardStateModel>, {id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.uploadLogoFile(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            metaData: result,
          });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(EndCampaign)
  endCampaign({ patchState, getState }: StateContext<DashboardStateModel>, { slug, payload }) {
    return this.dashboardService.edit(slug, payload).pipe(
      tap(
        (result) => {
          patchState({ campaign: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }

  @Action(GetFundraiser)
  getFundraiser({ patchState }: StateContext<DashboardStateModel>, { slug }) {
    patchState({ loading: true });
    return this.dashboardService.getFundraiser(slug).pipe(
      tap(
        (result: Fundraiser) => {
          patchState({
            fundraiser: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(EndDeleteFundraiser)
  endDeleteFundraiser(
    { patchState, getState }: StateContext<DashboardStateModel>,
    { slug, payload }
  ) {
    return this.dashboardService.editFundraisers(slug, payload).pipe(
      tap(
        (result) => {
          patchState({ fundraiser: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }

  @Action(GetComments)
  getComments(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, filter_by, search, page, limit, title_sort, date_sort, from_date, to_date, comment_type }
  ) {
    patchState({ loading: true, comments: null });
    return this.dashboardService
      .comments(
        id,
        filter_by,
        search,
        page,
        limit,
        title_sort,
        date_sort,
        from_date,
        to_date,
        comment_type
      )
      .pipe(
        tap(
          (result: { comments: Donation[]; pagination: Pagination }) => {
            patchState({
              comments: {
                list: result.comments,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              comments: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(AddReply)
  addReply({ patchState, getState }: StateContext<DashboardStateModel>, { id, message }) {
    return this.dashboardService.addReply(id, message).pipe(
      tap(
        (result) => {
          let commentsData: any = getState().comments.list;
          let pagination: any = getState().comments.pagination;
          let listData = commentsData.map((data) => {
            return {
              ...data,
              comment: data.comment.map((comment, i) => {
                if (comment._id == result._id) {
                  return result;
                }
                return comment;
              }),
            };
          });
          patchState({
            comments: {
              list: listData,
              pagination: pagination,
            },
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(DeleteReply)
  deleteReply({ patchState, getState }: StateContext<DashboardStateModel>, { id }) {
    return this.dashboardService.deleteReply(id).pipe(
      tap(
        (result) => {
          let commentsData: any = getState().comments.list;
          let pagination: any = getState().comments.pagination;
          let DataNew = [];
          let listData = commentsData.map((data) => {
            let commentNew = [];
            DataNew = data.comment.map((comment) => {
              commentNew = comment.reply.filter((reply: any) => reply._id != id);
              return {
                ...comment,
                reply: commentNew,
              };
            });
            return {
              ...data,
              comment: DataNew,
            };
          });
          patchState({
            comments: {
              list: listData,
              pagination: pagination,
            },
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(DeleteComment)
  deleteComment({ patchState, getState }: StateContext<DashboardStateModel>, { id, payload }) {
    return this.dashboardService.deleteComment(id, payload).pipe(
      tap(
        (result) => {
          let commentsData: any = getState().comments.list;
          let pagination: any = getState().comments.pagination;
          let listData = commentsData.map((data) => {
            let latestCommentList = data?.comment?.filter((comment) => comment?._id !== id);
            return { ...data, comment: latestCommentList };
          });
          patchState({
            comments: {
              list: listData,
              pagination: pagination,
            },
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(UpdateReply)
  updateReply({ patchState, getState }: StateContext<DashboardStateModel>, { id, payload }) {
    return this.dashboardService.updateReply(id, payload).pipe(
      tap(
        (result) => {
          let commentsData: any = getState().comments.list;
          let pagination: any = getState().comments.pagination;
          let listData = commentsData.map((data) => {
            return {
              ...data,
              comment: data.comment.map((comment, i) => {
                if (comment._id == result._id) {
                  return result;
                }
                return comment;
              }),
            };
          });
          patchState({
            comments: {
              list: listData,
              pagination: pagination,
            },
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(UpdateComment)
  updateComment({ patchState, getState }: StateContext<DashboardStateModel>, { id, payload }) {
    return this.dashboardService.updateComment(id, payload).pipe(
      tap(
        (result) => {
          let commentsData: any = getState().comments.list;
          let pagination: any = getState().comments.pagination;
          let listData = commentsData.map((data) => {
            return {
              ...data,
              comment: data.comment.map((comment) => {
                if (comment._id == id) {
                  return {
                    ...comment,
                    message: payload.message,
                  };
                }
                return comment;
              }),
            };
          });
          patchState({
            comments: {
              list: listData,
              pagination: pagination,
            },
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(GetTeam)
  getTeam({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getTeam(id).pipe(
      tap(
        (result: Team) => {
          patchState({
            team: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(DeleteTeam)
  deleteTeam({ patchState }: StateContext<DashboardStateModel>, { slug, payload }) {
    return this.dashboardService.editTeam(slug, payload).pipe(
      tap(
        (result) => {
          patchState({ team: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }

  @Action(EditTeam)
  editTeam({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.editTeam(id, payload).pipe(
      tap(
        (result) => {},
        (error) => {}
      )
    );
  }
  @Action(AddTeam)
  addTeam({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.addTeam(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false, team: result });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetCharityFollowers)
  getCharityFollowers({ patchState, dispatch }: StateContext<DashboardStateModel>, { search }) {
    patchState({ loading: true, donations: null });
    return this.dashboardService.getFollowers(search).pipe(
      tap(
        (result) => {
          //check follower is empty
          // result = result.filter((data) => data.follower != null);
          patchState({
            user: result,
            loading: false,
          });
        },
        () => {
          patchState({
            user: null,
            loading: false,
            // error:error
          });
        }
      )
    );
  }

  @Action(AddFundraiser)
  addFundraiser({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.addFundraiser(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false, fundraiser: result });
        },
        (error) => {
          patchState({ loading: false, error: error[0] });
        }
      )
    );
  }

  @Action(EditDashboardFundraiser)
  editDashboardFundraiser(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.editFundraiser(id, payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false, successMessage: 'Fundraiser edited successfully.' });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(ExportDonations)
  export({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.export(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }

  @Action(ExportFundraisers)
  exportFundraiser({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportFundraiser(payload).pipe(
      tap(
        (result) => {
          patchState({ exportFundraiser: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }

  @Action(ExportStatus)
  exportStatus({ patchState }: StateContext<DashboardStateModel>, { id, type }) {
    return this.dashboardService.exportStatus(id, type).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error, export: error });
        }
      )
    );
  }

  @Action(RemoveDashBoardError)
  removeDashBoardError({ patchState }: StateContext<DashboardStateModel>) {
    patchState({
      error: null,
    });
  }

  @Action(RemoveSuccessMessage)
  removeSuccessMessage({ patchState }: StateContext<DashboardStateModel>) {
    patchState({
      successMessage: null,
    });
  }

  @Action(GetQGStatistics)
  getQGStatistics({ patchState }: StateContext<DashboardStateModel>) {
    patchState({ loading: true });
    return this.dashboardService.getQGStatistics().pipe(
      tap(
        (result) => {
          patchState({
            qurbaniStatistics: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            qurbaniStatistics: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetAnimalsList)
  getAnimalsList({ patchState }: StateContext<DashboardStateModel>) {
    patchState({ animalsList: null, loading: true });
    return this.dashboardService.getAnimalsList().pipe(
      tap(
        (result) => {
          patchState({
            animalsList: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            animalsList: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetAnimalsByCountry)
  getAnimalsByCountry({ patchState }: StateContext<DashboardStateModel>, { countryCode }) {
    patchState({ loading: true });

    return this.dashboardService.getAnimalsByCountry(countryCode).pipe(
      tap(
        (response) => {
          let modifyResponse = response.map((animal) => {
            return {
              _id: animal?.animal?._id,
              size: animal?.animal?.size,
              name: animal?.animal?.name,
              image: animal?.animal?.image,
              sold: animal?.stock?.sold,
              total: animal?.stock?.total,
            };
          });
          patchState({
            animalsList: modifyResponse,
            loading: false,
          });
        },
        (error) => {
          patchState({
            animalsList: [],
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetStocksList)
  getStocksList({ patchState }: StateContext<DashboardStateModel>, { search, page, limit, sort_value, sort_title, from_date, to_date, animal }) {
    patchState({ loading: true });
    return this.dashboardService.getStocksList(search, page, limit, sort_value, sort_title, from_date, to_date, animal).pipe(
      tap(
        (result) => {
          patchState({
            stocksList: {
              list: result.stocks,
              pagination: result.pagination,
            },
            loading: false,
          });
        },
        (error) => {
          patchState({
            stocksList: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetStockById)
  getStockById({ patchState }: StateContext<DashboardStateModel>, { stockId }) {
    patchState({ loading: true });
    return this.dashboardService.getStockById(stockId).pipe(
      tap(
        (result) => {
          patchState({
            specificStock: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            specificStock: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(AddStock)
  addStock({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.addStock(payload).pipe(
      tap(
        (result) => {
          console.log('result', result);
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(UpdateStock)
  updateStock({ patchState }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.updateStock(id, payload).pipe(
      tap(
        (result) => {
          console.log('result', result);
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardQGDonations)
  getDashboardQGDonations(
    { patchState }: StateContext<DashboardStateModel>,
    { search, page, limit, sort_value, sort_title, from_date, to_date }
  ) {
    patchState({ loading: true, qurbaniDonations: null });

    return this.dashboardService
      .getDashboardQGDonations(search, page, limit, sort_value, sort_title, from_date, to_date)
      .pipe(
        tap(
          (result) => {
            patchState({
              qurbaniDonations: {
                list: result.donations,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              qurbaniDonations: null,
              loading: false,
              // error:error
            });
          }
        )
      );
  }

  @Action(ExportQGDonations)
  exportQGDonations({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportQGDonations(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  @Action(ExportProjectCSV)
  exportProjectCSV({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportProjectCSV(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  @Action(ExportAppealCSV)
  exportAppealCSVk({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportAppealCSV(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  @Action(ExportQGStock)
  exportQGStock({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportQGStock(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  @Action(ExportRGDonations)
  exportRGDonations({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportRGDonations(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  @Action(ExportRGSubscriptions)
  exportRGSubscriptions({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    return this.dashboardService.exportRGSubscriptions(payload).pipe(
      tap(
        (result) => {
          patchState({ export: result });
        },
        (error) => {
          patchState({ error: error });
        }
      )
    );
  }
  // Project CRUD
  @Action(CreateDashboardProject)
  createDashboardProject({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.createProject(payload).pipe(
      tap(
        (result: { project: Projects_ }) => {
          patchState({ loading: false, project: result.project });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }


  @Action(CreateDashboardProjectAppeal)
  createDashboardProjectAppeal({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.createProjectAppeal(payload).pipe(
      tap(
        (result: { projectAppeals: any }) => {
          patchState({ loading: false });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetDashboardProject)
  getDashboardProject({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getProject(id).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            project: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(SyncDashboardProject)
  syncDashboardProject({ patchState }: StateContext<DashboardStateModel>, { id, wp_id }) {
    patchState({ loading: true });
    return this.dashboardService.syncDashboardProject(id, wp_id).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            project: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  
  @Action(SyncDashboardAppeal)
  syncDashboardAppeal({ patchState }: StateContext<DashboardStateModel>, { id, wp_id }) {
    patchState({ loading: true });
    return this.dashboardService.syncDashboardAppeal(id, wp_id).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            appeal: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardAppeal)
  getDashboardAppeal({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getAppeal(id).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            appeal: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  
  @Action(GetDashboardProjectAppeal)
  getDashboardProjectAppeal({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getProjectAppeal(id).pipe(
      tap(
        (result: any) => {
          patchState({
            projectAppeals: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardAllAppeal)
  getDashboardAllAppeal({ patchState, dispatch }: StateContext<DashboardStateModel>,
    { filter_by, search, page, limit, sort_value, sort_title, from_date, to_date, status }) {
    patchState({ loading: true });
    return this.dashboardService.getAllAppeal(
      filter_by,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date,
        status
    ).pipe(
      tap(
        (result: any) => {
          patchState({
            appeals: result.docs,
            pagination: {
              limit: result.limit,
              pages: result.pages,
              page: result.page,
              total: result.total,
            },
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(EditDashboardProject)
  editDashboardProject(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.editProject(id, payload).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            loading: false,
            project: result,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  
  @Action(EditDashboardProjectAppeal)
  editDashboardProjectAppeal(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.editProjectAppeal(id, payload).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            loading: false
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(DeleteProject)
  deleteProject({ patchState, getState }: StateContext<DashboardStateModel>, { id }) {
    return this.dashboardService.deleteProject(id).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            project: result,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(DeleteProjectAppeal)
  deleteProjectAppeal({ patchState, getState }: StateContext<DashboardStateModel>, { id }) {
    return this.dashboardService.deleteProjectAppeal(id).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardProjects)
  getDashboardProjects(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { filter_by, search, page, limit, sort_value, sort_title, from_date, to_date, status }
  ) {
    patchState({ loading: true, projects: [] });
    return this.dashboardService
      .getDashboardProjects(
        filter_by,
        search,
        page,
        limit,
        sort_value,
        sort_title,
        from_date,
        to_date,
        status
      )
      .pipe(
        tap(
          (result: { projects: Projects_[]; pagination: Pagination }) => {
            patchState({
              projects: result.projects,
              pagination: result.pagination,
              loading: false,
            });
          },
          (error) => {
            patchState({
              projects: [],
              loading: false,
              error: error,
              pagination: null,
            });
          }
        )
      );
  }

  @Action(GetAllDashboardProjects)
  getAllDashboardProjects({ patchState, dispatch }: StateContext<DashboardStateModel>) {
    patchState({ loading: true });
    return this.dashboardService.getAllDashboardProjects().pipe(
      tap(
        (result) => {
          patchState({
            projectsAll: result.projects,
            loading: false,
          });
        },
        (error) => {
          patchState({
            projectsAll: [],
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  // Project category CRUD
  @Action(CreateProjectCategory)
  createProjectCategory({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.createProjectCategory(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false, projectCategory: result });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetProjectCategory)
  getProjectCategory({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getProjectCategory(id).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            projectCategory: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  
  @Action(GetDonationById)
  getDonationById({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getDonationById(id).pipe(
      tap(
        (result) => {
          patchState({
            donation: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false
          });
        }
      )
    );
  }

  @Action(GetUserById)
  getUserById({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getUserById(id).pipe(
      tap(
        (result) => {
          patchState({
            user: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false
          });
        }
      )
    );
  }
  @Action(GetSubscriptionById)
  getSubscriptionById({ patchState }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.getSubscriptionById(id).pipe(
      tap(
        (result) => {
          patchState({
            subscription: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false
          });
        }
      )
    );
  }

  @Action(EditProjectCategory)
  editProjectCategory(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.editProjectCategory(id, payload).pipe(
      tap(
        (result: Projects_) => {
          patchState({
            loading: false,
            projectCategory: result,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetProjectCategories)
  getProjectCategories(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { search, page, limit, sort_value, sort_title, from_date, to_date}
  ) {
    patchState({ loading: true, projects: [] });
    return this.dashboardService
      .getDashboardProjectscategories(
        search, page, limit, sort_value, sort_title, from_date, to_date
      )
      .pipe(
        tap(
          (result: { projectcategory: Projects_[]; pagination: Pagination }) => {
            patchState({
              projectCategorys: result.projectcategory,
              pagination: result.pagination,
              loading: false,
            });
          },
          (error) => {
            patchState({
              projectCategorys: [],
              loading: false,
              error: error,
              pagination: null,
            });
          }
        )
      );
  }

  @Action(GetDashboardUsers)
  getDashboardUsers(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { filter_by, page, limit, value, search, sort_value, sort_title, from_date, to_date, status }
  ) {
    patchState({ loading: true, users: null });
    return this.dashboardService.getDashboardUsers(filter_by, page, limit, value, search, sort_value, sort_title, from_date, to_date, status).pipe(
      tap(
        (result) => {
          patchState({
            users: {
              list: result.users,
              pagination: result.pagination,
            },
            loading: false,
          });
        },
        (error) => {
          patchState({
            users: null,
            loading: false,
            error: error,
            pagination: null,
          });
        }
      )
    );
  }

  @Action(GetDonationsTypes)
  getDonationsTypes({ patchState }: StateContext<DashboardStateModel>) {
    patchState({ loading: true });
    return this.dashboardService.getDonationsTypes().pipe(
      tap(
        (result) => {
          patchState({
            donationTypes: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(GetDashboardUnVerifiedCharitiesCount)
  getDashboardUnVerifiedCharitiesCount({ patchState }: StateContext<DashboardStateModel>) {
    patchState({ loading: true });
    return this.dashboardService.getDashboardUnVerifiedCharitiesCount().pipe(
      tap(
        (result) => {
          patchState({
            unVerifiedCharityCount: result,
            loading: false,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetDashboardCharities)
  getDashboardCharities(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { page, limit, search,from_date,to_date,sort_title,sort_value,verified,active, is_featured }
  ) {
    patchState({ loading: true, charities:null});
    return this.dashboardService
      .getCharities(page, limit, search,from_date,to_date,sort_title,sort_value,verified,active, is_featured)
      .pipe(
        tap(
          (result) => {
            patchState({
              charities:{
                list: result.charities,
                pagination: result.pagination,
              },
              loading: false,
            });
          },
          (error) => {
            patchState({
              charities: null,
              loading: false,
              error: error,
            });
          }
        )
      );
  }

  @Action(GetCampaignsStatistics)
  getCampaignStatistics(
    { dispatch, getState, setState, patchState }: StateContext<DashboardStateModel>,
    { slug, params }
  ) {
    patchState({ loading: true });
    return this.dashboardService.getCampaignStatistics().pipe(
      tap(
        (result) => {
          patchState({
            campaignStatistics: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }
  @Action(GetAppSettings)
  getAppSettings(
    { dispatch, getState, setState, patchState }: StateContext<DashboardStateModel>,
    { slug, params }
  ) {
    patchState({ loading: true });
    return this.dashboardService.getAppSettings().pipe(
      tap(
        (result) => {
          patchState({
            appsSettings: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
        )
      );
    }

  @Action(CreateDashboardUser)
  createDashboardUser({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.createDashboardUser(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetCharityCounts)
  getCharityCounts(
    { dispatch, getState, setState, patchState }: StateContext<DashboardStateModel>,
    { slug, params }
  ) {
    patchState({ loading: true });
    return this.dashboardService.getCharityCounts().pipe(
      tap(
        (result) => {
          patchState({
            countStatistics: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }

  @Action(EditCharity)
  editDashboardCharity(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.editDashboardCharity(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(UpdateAppSettings)
  updateAppSettings(
    { patchState, dispatch }: StateContext<DashboardStateModel>,
    { id, payload }
  ) {
    patchState({ loading: true });
    return this.dashboardService.updateAppSettings(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(CreateProjectCauses)
  createProjectCauses({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.createProjectCauses(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false, projects: result });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }
  @Action(RemoveProjectCauses)
  removeProjectCauses({ patchState }: StateContext<DashboardStateModel>, { payload }) {
    patchState({ loading: true });
    return this.dashboardService.removeProjectCauses(payload).pipe(
      tap(
        (result) => {
          patchState({ loading: false });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(EditDashboardUser)
  editDashboardUser({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.editDashboardUser(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(ResetDashboardUser)
  resetDashboardUser({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.resetDashboardUser(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(ResetPasswordLinkDashboardUser)
  resetPasswordLinkDashboardUser({ patchState, dispatch }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.resetPasswordLinkDashboardUser(id).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(RemoveDashboardUser)
  removeDashboardUser({ patchState, dispatch }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.removeDashboardUser(id).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(EnableDashboardUser)
  enableDashboardUser({ patchState, dispatch }: StateContext<DashboardStateModel>, { id }) {
    patchState({ loading: true });
    return this.dashboardService.enableDashboardUser(id).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(EditDashboardDonation)
  editDashboardDonation({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.editDashboardDonation(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }
  @Action(RefundDashboardDonation)
  refundDashboardDonation({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.refundDashboardDonation(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(EditDashboardSubscription)
  editDashboardSubscription({ patchState, dispatch }: StateContext<DashboardStateModel>, { id, payload }) {
    patchState({ loading: true });
    return this.dashboardService.editDashboardSubscription(id, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
            error: error,
          });
        }
      )
    );
  }

}


