<div class="campaign-card-mobile relative mb-5">
  <mat-card
    appearance="outlined"
    class="mat-elevation-z5 p-0 rounded-lg"
    [ngStyle]="{ width: width }"
  >
    <div fxLayout="row" fxLayoutAling="center center" fxLayoutGap="10px">
      <img
        [class.hidden]="!thumbnailLoaded"
        (load)="thumbnailLoaded = true"
        [src]="campaign?.thumbnail"
        [alt]="campaign?.name"
        class="rounded-lg thumbnail"
      />

      <img
        src="assets/images/z-sign.svg"
        class="absolute zakat"
        *ngIf="campaign.settings.is_zakat"
      />

      <div
        fxLayout="column"
        fxLayoutAling="center center"
        fxLayoutGap="10px"
        class="w-full py-2.5"
      >
        <h3>
          {{ campaign?.name | titlecase }}
        </h3>

        <div style="width: 95%">
          <givebrite-progress-bar
            [raised]="campaign.statistics.raised"
            [goal]="campaign.goal"
            [currency]="campaign.currency"
            [isMobile]="true"
          ></givebrite-progress-bar>
        </div>
      </div>
    </div>
  </mat-card>
</div>
