import { Component, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ControlValueAccessor,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'givebrite-campaign-inspiration',
  templateUrl: './campaign-inspiration.component.html',
  styleUrls: ['./campaign-inspiration.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignInspirationComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignInspirationComponent,
    },
  ],
})
export class CampaignInspirationComponent implements OnInit, ControlValueAccessor, Validator {
  touched = false;
  disabled = false;
  type: string;

  onChange = (type: string) => {};

  onTouched = () => {};

  writeValue(value: string) {
    this.type = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const type = control.value;

    if (type === '') {
      return {
        mustNotBeEmpty: {
          type,
        },
      };
    }
  }

  inspirationList: Array<{ id: number; text: string; checked: boolean; type: string }> = [
    { id: 5, text: 'Sadaqah Jariyah ', checked: false, type: 'sadaqahjariyah' },
    { id: 6, text: 'Feeling Motivated ', checked: false, type: 'motivation' },
    { id: 7, text: 'My Birthday', checked: false, type: 'birthday' },
    { id: 8, text: 'In Memory', checked: false, type: 'memory' },
  ];

  constructor() {}

  ngOnInit(): void {}

  /**
   * @description
   * SELECTED CAMPAIGN TYPE
   * @param index index
   */
  selectedType(index) {
    this.inspirationList.forEach((item, idx) => {
      item.checked = idx == index;
      if (item.checked) {
        this.type = item.type;
        this.onChange(this.type);
      }
    });
  }
}
