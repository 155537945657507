import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { OfflineDonationDialogModule } from '../dialogs/offline-donation-dialog/offline-donation-dialog.module';
import { CampaignUserDashboardRoutingModule } from './campaign-user-dashboard-routing.module';
import { CampaignUserDashboardComponent } from './campaign-user-dashboard.component';
import { ProfileOverviewModule } from './profile-overview/profile-overview.module';

@NgModule({
  declarations: [CampaignUserDashboardComponent],
  imports: [
    CommonModule,
    CampaignUserDashboardRoutingModule,
    RouterModule,
    MatTabsModule,
    MatListModule,
    FlexLayoutModule,
    ProfileOverviewModule,
    ToastrModule.forRoot(),
    OfflineDonationDialogModule,
  ],
  providers: [{ provide: ToastrService, useClass: ToastrService }],
})
export class CampaignUserDashboardModule {}
