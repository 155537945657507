import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from 'libs/data/src/lib/ramadhan-giving';
// import { GeocodeService } from '@givebrite/data/services';
// import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'add-edit-address',
  templateUrl: './add-edit-address.component.html',
  styleUrls: ['./add-edit-address.component.scss'],
})
export class AddEditAddressComponent implements OnInit, OnDestroy {
  @Output() added: EventEmitter<Address> = new EventEmitter();
  @Output() updated: EventEmitter<Address> = new EventEmitter();
  @Output() form: EventEmitter<FormGroup> = new EventEmitter();
  appearance = 'legacy';
  @Input() address: Address;
  titles = ['Mr', 'Mrs', 'Miss', 'Dr'];
  addressForm: FormGroup = this.fb.group({
    id: [],
    country: [],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    //mobile: ['', this.PhoneNumberValidator()],
    postcode: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$'
        ),
      ],
    ],
    title: ['Mr', Validators.required],
    building_name_number: ['', Validators.required],
    street_name: ['', Validators.required],
    address_line_1: [],
    address_line_2: [],
    town: [],
    county: [],
    is_default: [false],
  });
  @Input() autocomplete: boolean;
  selectedAddress: Address;

  constructor(private fb: FormBuilder) {}

  // onAutocompleteSelected(result: PlaceResult) {
  //   const address = this.geo.parseAddress(result);
  //   this.selectedAddress = address;

  //   console.log(address, result);
  //   if (address.town) {
  //     this.addressForm.get("town").patchValue(address.town.long);
  //   }

  //   if (address.country) {
  //     this.addressForm.get("country").patchValue(address.country.short);
  //   }

  //   if (address.county) {
  //     this.addressForm.get("county").patchValue(address.county.long);
  //   }

  //   if (address.streetName) {
  //     this.addressForm.get("address_line_1").patchValue(address.streetName);
  //   }
  //   if (address.streetNumber) {
  //     this.addressForm.get("address_line_1").setValue(address.streetNumber);
  //   }
  //   if (address.postalCode) {
  //     this.addressForm.get("postcode").patchValue(address.postalCode);
  //   }
  // }

  onLocationSelected(event) {}

  ngOnInit() {
    //this.form.emit(this.addressForm);

    this.addressForm.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.form.emit(this.addressForm);
    });

    if (this.address) {
      console.log(this.address);
      this.addressForm.patchValue(this.address);
    }
  }
  ngOnDestroy() {}
}
