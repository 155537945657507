import {
  UserRg,
  UserAddress,
  UserRegistrationFields,
  SocialUserRg,
  Household,
  Card,
  PaymentMethod,
} from '../user.interface';

export class GetPostcode {
  static readonly type = '[User] Get Postcode';
}

export class GetUser {
  static readonly type = '[User] Get';
}

export class ResetUser {
  static readonly type = '[User] Reset';
}

export class UpdateUser {
  static readonly type = '[User] Update';
  constructor(public user: UserRg) {}
}

export class GetCharityRg {
  static readonly type = '[User] Charity';
  constructor(public id: any) {}
}

export class GetAddresses {
  static readonly type = '[User] Get List of User Addresses';
  constructor(public user_id?: number) {}
}

export class GetAddress {
  static readonly type = '[User] Get User Address';
  constructor(public id: number) {}
}

export class AddAddress {
  static readonly type = '[User] Add User Address';
  constructor(public payload: any) {}
}

export class UpdateAddress {
  static readonly type = '[User] Update User Address';
  constructor(public address: UserAddress) {}
}

export class MarkAddressAsDefault {
  static readonly type = '[User] Mark User Address as Default';
  constructor(public address: UserAddress) {}
}

export class MarkPaymentMethodAsDefault {
  static readonly type = '[User] Mark Payment Method as Default';
  constructor(public card: Card) {}
}

export class DeleteAddress {
  static readonly type = '[User] Delete User Address';
  constructor(public id: number) {}
}

export class DeleteUserPaymentMethod {
  static readonly type = '[User] Delete User Payment Method';
  constructor(public paymentMethod: PaymentMethod) {}
}

export class LoginRg {
  static readonly type = '[User] Login';
  constructor(public credentials: any) {}
}

export class LoginTokenRg {
  static readonly type = '[User] Login Token';
  constructor(public payload: any) {}
}
export class VerifyLoginTokenRg {
  static readonly type = '[User] Verify Login Token';
  constructor(public payload: any) {}
}

export class ForgotPasswordRg {
  static readonly type = '[User] ForgotP assword';
  constructor(public email: any) {}
}

export class GetUserSubscriptions {
  static readonly type = '[Subscriptions] Get User Subscriptions';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'desc',
    public column: string = '',
    public download: number = 0
  ) {}
}

export class ResetPasswordRg {
  static readonly type = '[User] Reset assword';
  constructor(public payload: { token: string; password: string }) {}
}

export class Authorise {
  static readonly type = '[User] Authorise';
  constructor(public user) {}
}

export class LoginAsCharity {
  static readonly type = '[User] Login As Charity';
  constructor(public email) {}
}

export class RegisterRg {
  static readonly type = '[User] Register';
  constructor(public payload: UserRegistrationFields) {}
}

export class LogoutRg {
  static readonly type = '[User] Logout';
}

export class SocialLoginRg {
  static readonly type = '[User] Social Login';
  constructor(public credentials: SocialUserRg) {}
}
export class SocialCompleteRg {
  static readonly type = '[User] Social Complete';
  constructor(public credentials: SocialUserRg) {}
}

export class SocialRegister {
  static readonly type = '[User] Social Register';
  constructor(public credentials: SocialUserRg) {}
}

export class Analytics {
  static readonly type = '[User] Analytics';
  constructor(public type: any) {}
}

export class GetPaymentMethods {
  static readonly type = '[User] Payment Methods';
  constructor(public params?: { user_id: number }) {}
}

export class DetachPaymentMethod {
  static readonly type = '[User] Detach Payment Method';
  constructor(public id: number) {}
}

export class CreateSetupIntent {
  static readonly type = '[User] Create Setup Intent';
  constructor(public params) {}
}

export class ConfirmSetupIntent {
  static readonly type = '[User] Confirm Setup Intent';
  constructor(public params) {}
}

export class CreatePaymentIntentRg {
  static readonly type = '[User] Create Payment Intent';
  constructor(public params) {}
}

export class ExpireSetupIntent {
  static readonly type = '[User] Expire Setup Intent';
}

export class LoadingRg {
  static readonly type = '[User] Loading';
  constructor(public loading: boolean) {}
}

export class UserExists {
  static readonly type = '[App] User Exists';
  constructor(public email: string) {}
}

export class SaveHousehold {
  static readonly type = '[App] Save User Househoold';
  constructor(public household: Household) {}
}

export class CancelUserSubscription {
  static readonly type = '[User] Cancel Subs';
  constructor(public id: number, public cancel_at_period_end: number = 0) {}
}
