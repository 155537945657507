import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { HomeRoutingModule } from '../../home/home-routing.module';
import { HeaderComponent } from './header.component';
import { HeroHeaderModule } from '@givebrite/core';

@NgModule({
  declarations: [HeaderComponent],
  imports: [HomeRoutingModule, FlexLayoutModule, MatButtonModule, HeroHeaderModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
