<div class="statistics my-6">
  <h2 class="font-bold text-center py-5 mb-0">What we have done over the years?</h2>

  <div gdGap="15px" gdColumns="1fr 1fr">
    <mat-card appearance="outlined" class="mat-elevation-z5 w-full" *ngFor="let stat of stats">
      <mat-card-content fxLayout="column" fxLayoutAlign="center center" class="mt-5 h-full">
        <mat-icon
          fxLayout="row"
          fxLayoutAlign="center center"
          [icIcon]="stat.icon"
          color="accent"
          class="mb-4"
          inline="true"
        ></mat-icon>
        <h4 class="font-bold mb-2">{{ stat.raised }}</h4>
        <p class="mb-0">{{ stat.title | titlecase }}</p>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- <p class="mt-6 text-center">Find out how your donations helped.</p>

  <div fxLayout="row" fxLayoutAlign="center center" class="my-4">
    <button mat-button-raised color="accent" class="btn-md">See Report</button>
  </div> -->
</div>
