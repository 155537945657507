import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { map } from 'rxjs/operators';
import { DonationRg, CreateDonationDto } from './donation.interface';

@Injectable({
  providedIn: 'root',
})
export class DonationServiceRg {
  constructor(private http: ApiService) {}

  get(id: number) {
    interface SingleDonationResponse {
      code: number;
      donation: DonationRg;
    }
    return this.http.get(`donation/${id}`).pipe(
      map((response: SingleDonationResponse) => {
        return response.donation;
      })
    );
  }

  list() {}

  refund(id: number) {
    return this.http.get(`donation/${id}/refund`);
  }

  /* Updates donation*/
  update(payload: CreateDonationDto) {
    return this.http.put('donation', payload).pipe(
      map((res) => {
        return res.donation;
      })
    );
  }

  /* Creates draft donation*/
  create(payload: CreateDonationDto) {
    return this.http.post('donation', payload).pipe(
      map((res) => {
        return res.donation;
      })
    );
  }

  /* Creates draft donation*/
  markSuccess(id: number) {
    return this.http.post('payment/success', { donation_id: id }).pipe();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CharityDonationService {
  constructor(private http: ApiService) {}

  list(
    charityId: number,
    limit = 10,
    page = 1,
    filter: {} = null,
    sort = 'asc',
    column = '',
    download = 0
  ) {
    return this.http
      .get(
        `subscription/charity/donations?limit=${limit}&?page=${page}&?filter=${filter}&?sort=${sort}&?column=${column}&?download=${download}`
      )
      .pipe(
        map((donations) => {
          return donations;
        })
      );
  }

  recurringDonations(
    donationId,
    limit = 10,
    page = 1,
    filter: {} = null,
    sort = 'asc',
    column = '',
    download = 0
  ) {
    return this.http
      .get(
        `recurring/${donationId}/donations?limit=${limit}&?page=${page}&?filter=${filter}&?sort=${sort}&?column=${column}`
      )
      .pipe(
        map((donations) => {
          return donations;
        })
      );
  }
}
