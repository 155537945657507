<div
  *ngIf="logo != null && logo != ''; else avatar"
  class="user-logo-avatar-container logo-container"
  [style.border-radius]="radius"
  fxLayoutAlign="center center"
  [style.background-color]="backgroundColor"
  [class.has_border]="border"
  [style.width]="width"
  [style.height]="width"
  [class.mat-elevation-z8]="shadow"
  fxLayout="row"
>
  <givebrite-logo-card [width]="width" [height]="width" [logo]="logo"></givebrite-logo-card>
</div>
<ng-template #avatar>
  <!-- <ngx-avatar
    [name]="name"
    [src]="profileImage"
    [size]="cleanWidth"
    [round]="true"
    initialsSize="3"
  >
  </ngx-avatar> -->
</ng-template>
