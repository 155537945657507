import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { EditProfileDialogComponent } from 'apps/campaigns/src/app/account/edit-profile-dialog/edit-profile-dialog.component';
// import { EditProfileDialogComponent } from "../../../../../../../../apps/campaigns/src/app/account/edit-profile-dialog/edit-profile-dialog.component";

@Component({
  selector: 'givebrite-toolbar-user-profile',
  templateUrl: './toolbar-user-profile.component.html',
  styleUrls: ['./toolbar-user-profile.component.scss'],
})
export class ToolbarUserProfileComponent implements OnInit {
  @Input() name;
  @Input() profileImage;
  @Input() user;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  edit(user) {
    // this.dialog.open(EditProfileDialogComponent, {
    //   panelClass: ['full-window-dialog', 'transparent-container'],
    //   maxWidth: '800px',
    //   data: { user },
    // });
  }
}
