import { Component, Input, OnInit } from '@angular/core';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-top-team',
  templateUrl: './top-team.component.html',
  styleUrls: ['./top-team.component.scss'],
})
export class TopTeamComponent implements OnInit {
  @Input() teams$: Observable<Team[]>;
  @Input() page;

  constructor() {}

  ngOnInit(): void {}
}
