<h1 class="title" mat-dialog-title>Add Address</h1>
<view-card mat-dialog-content type="flat" nopadding="true" [loading]="loading$">
  <add-edit-address (form)="formUpdated($event)" [address]="address"></add-edit-address>
</view-card>
<div mat-dialog-actions>
  <div class="text-right">
    <button [disabled]="loading$ | async" mat-raised-button mat-dialog-close class="mr-2">Close</button>
    <button [disabled]="(loading$ | async) || !form?.valid" color="primary" mat-raised-button (click)="addUpdateAddress()">
      <mat-progress-spinner
        *ngIf="loading$ | async"
        diameter="20"
        [ngClass]="['mat-spinner-white']"
        color="white"
        mode="indeterminate"
      ></mat-progress-spinner>
      <ng-container *ngIf="address">Update Address</ng-container>
      <ng-container *ngIf="!address">Add Address</ng-container>
    </button>
  </div>
</div>
