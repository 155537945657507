import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {
  ConfigService,
  StyleService,
  Style,
  // ConfigName,
  NavigationService,
} from '@givebrite/theming';

// Icons
import icLayers from '@iconify/icons-ic/twotone-layers';
import icHome from '@iconify/icons-ic/baseline-home';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
// import { User, Charity, Subscription } from "@givebrite/data/models";
// import { UserState, SubscriptionState } from "@givebrite/data/store";
import { Router, NavigationEnd } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
// import { RollbarService } from "@givebrite/api";
import Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { Charity, CharityState, User, UserState } from '@givebrite/data';
import { ApiService } from '@givebrite/api';
import { CookieService } from 'ngx-cookie';
import { CheckoutCartService } from './home/charities/checkout/checkout-cart/checkout-cart.service';
import { Meta, Title } from '@angular/platform-browser';
import { PixelService } from 'ngx-multi-pixel';
import { responsive } from '../../../../libs/core/src/lib/utils/utlis';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'givebrite-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'qurbani-giving';
  @Select(UserState.user) user$: Observable<User>;
  @Select(CharityState.getCharity) charity$: Observable<Charity>;
  // @Select(SubscriptionState.getSubscription) subscription$: Observable<Subscription>;
  isBrowser: boolean;

  constructor(
    private config: ConfigService,
    private styleService: StyleService,
    private checkoutCartService: CheckoutCartService,
    private navigationService: NavigationService,
    private router: Router,
    private cookies: CookieService,
    private api: ApiService,
    private pixel: PixelService,
    private titleService: Title,
    private metaService: Meta,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.api.endpoints = environment.endpoints;
    this.styleService.setStyle(Style.givebrite);

    // this.rollbar.configure({ logLevel: "error", payload: { environment: environment.environment } });

    // this.config.setConfig(ConfigName.ikaros);
    this.config.updateConfig({
      sidenav: { title: 'Qurbani Giving', imageUrl: '' },
      navbar: { position: 'in-toolbar' },
      layout: 'vertical',
    });

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        //this.gtmService.pushTag(gtmTag);
      }
    });

    // this.styleService.setStyle(Style.green);

    this.navigationService.items = [
      {
        type: 'link',
        label: 'Home',
        route: '/',
        icon: icHome,
      },

      {
        type: 'link',
        label: 'Dashboard',
        route: '/dashboard',
        icon: icLayers,
      },
    ];

    // this.user$.pipe(untilDestroyed(this)).subscribe(user => {
    //   if (user) {
    //     this.rollbar.configure({ payload: { person: { id: user.id, email: user.email } } });
    //   }
    // });

    // this.subscription$.pipe(untilDestroyed(this)).subscribe(subscription => {
    //   if (subscription) {
    //     this.rollbar.configure({ payload: { sessionId: subscription.id } });
    //   }
    // });
    if (this.isBrowser) {
      this.loadDonationCart();
    }
  }

  ngOnInit() {
    this.setMetaData();

    // Set pixelID
    this.pixel.initialize([environment.pixelId]);
  }

  /**
   * @description
   * LOAD DONATION CART
   */
  loadDonationCart() {
    this.router.events.pipe(untilDestroyed(this)).subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        const cartId = this.cookies.get('cart_id');

        if (cartId) {
          this.checkoutCartService.getCart(cartId);
        } else {
          const cartId = await this.checkoutCartService.createCart('cart_id');
          this.checkoutCartService.getCart(cartId);
        }
      }
    });
  }

  /**
   * @description
   * Qurbani Giving
   */
  setMetaData() {
    this.titleService.setTitle('Qurbani Giving' + ' | Give Brite');
    this.metaService.addTags([
      { name: 'keywords', content: 'Qurbani Giving' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Qurbani Giving' + ' | Givebrite' },
      { property: 'og:site_name', content: 'Qurbani Giving' },
      {
        property: 'og:description',
        content: `Giving Qurbani The Seamless Way | GIVE NOW`,
      },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:image',
        content: responsive(
          'https://gb-downloads.s3.eu-west-1.amazonaws.com/assets/footer-banner2-gb-2023.png',
          1200,
          630,
          'crop',
        ),
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:title',
        content: 'Qurbani Giving' + ' | Give Brite',
      },
      {
        name: 'twitter:description',
        content: `Giving Qurbani The Seamless Way | GIVE NOW`,
      },
      { charset: 'UTF-8' },
    ]);
  }

  ngOnDestroy() {}
}
