import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetCharitiesRg,
  GetCharityLeaderboard,
  GetCharityById,
  UpdateCharity,
  GetCharitiesStories,
  ResetCharity,
} from './charity.action';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CharityRg } from '../charity.interface';
import { Pagination } from '../../../pagination';
import { CharityServiceRg } from '../charity.service';
import { CampaignRg } from '../../campaign';

export class CharityStateModelRg {
  charities: CharityRg[];
  charity: CharityRg;
  charitiesStories: CharityRg[];
  campaigns: CampaignRg[];
  pagination: Pagination;
  loading: boolean;
  stats: any;
}

@State<CharityStateModelRg>({
  name: 'charities',
  defaults: {
    charities: [],
    campaigns: [],
    charitiesStories: null,
    charity: null,
    pagination: null,
    loading: false,
    stats: null,
  },
})
@Injectable()
export class CharityStateRg {
  constructor(private charityService: CharityServiceRg) {}

  @Selector()
  static getCharities(state: CharityStateModelRg) {
    return state.charities;
  }

  @Selector()
  static getCampaigns(state: CharityStateModelRg) {
    return state.campaigns;
  }

  @Selector()
  static getCharity(state: CharityStateModelRg) {
    return state.charity;
  }

  @Selector()
  static getCharityStories(state: CharityStateModelRg) {
    return state.charitiesStories;
  }

  @Selector()
  static getStats(state: CharityStateModelRg) {
    return state.stats;
  }

  @Selector()
  static loading(state: CharityStateModelRg) {
    return state.loading;
  }

  @Selector()
  static getPagination(state: CharityStateModelRg) {
    return state.pagination;
  }

  @Action(GetCharitiesRg)
  getCharities(
    { getState, setState, patchState }: StateContext<CharityStateModelRg>,
    { limit, page, filter, sort, column, featured }
  ) {
    patchState({ loading: true });

    return this.charityService.list(limit, page, filter, sort, column, featured).pipe(
      map((charities) => charities.docs),
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          charities: result,
          pagination: result.pagination,
          loading: false,
        });
      })
    );
  }

  @Action(GetCharitiesStories)
  getCharitieStories(
    { getState, setState, patchState }: StateContext<CharityStateModelRg>,
    { limit, page, filter, sort, column, featured }
  ) {
    patchState({ loading: true });

    return this.charityService.list(limit, page, filter, sort, column, featured).pipe(
      map((charities) => charities.docs),
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          charitiesStories: result,
          pagination: result.pagination,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateCharity)
  updateCharity({ patchState }: StateContext<CharityStateModelRg>, { id, payload }) {
    patchState({ loading: true });

    return this.charityService.update(id, payload).pipe(
      map((charity) => charity),
      tap((result) => {
        patchState({
          charity: result,
          campaigns: result.campaigns,
          loading: false,
        });
      })
    );
  }

  @Action(GetCharityLeaderboard)
  getCharityLeaderboard(
    { getState, setState, patchState }: StateContext<CharityStateModelRg>,
    { limit, page, filter, sort, column }: GetCharityLeaderboard
  ) {
    patchState({ loading: true });

    return this.charityService.leaderboard(limit, page, filter, sort, column).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          charities: result.data,
          pagination: result.pagination,
          stats: result.stats,
          loading: false,
        });
      })
    );
  }

  @Action(GetCharityById)
  getCharity({ patchState, getState, setState }: StateContext<CharityStateModelRg>, { id }) {
    patchState({ loading: true });

    return this.charityService.get(id).pipe(
      tap(
        (charity) => {
          patchState({
            loading: false,
            campaigns: charity.campaigns,
            charity: charity,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(ResetCharity)
  resetCharity({ patchState }: StateContext<CharityStateModelRg>) {
    patchState({ charity: null });
  }

  // /*
  // @Action(GetCharity)
  // getCampaign({ getState, setState, patchState }: StateContext<CampaignStateModel>, { slug }) {
  //   patchState({ loading: true });
  //   return this.campaignService.get(slug).pipe(
  //     tap(result => {
  //       const state = getState();
  //       setState({
  //         ...state,
  //         campaign: result,
  //         loading: false
  //       });
  //     })
  //   );
  // }*/
}
