import { Component, Input, OnInit } from '@angular/core';
import { Fundraiser } from '@givebrite/data';

@Component({
  selector: 'our-volunteers-fundraisers-card',
  templateUrl: './our-volunteers-fundraisers-card.component.html',
  styleUrls: ['./our-volunteers-fundraisers-card.component.scss']
})
export class OurVolunteersFundraisersCardComponent implements OnInit {
  @Input() fundraiser: Fundraiser;
  /**
   * @index For Displaing Top Fundraiser &  Raising Star
   */
  @Input() index;
  constructor() { }
  ngOnInit(): void {
    
  }

}
