import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignProjectSelectorComponent } from './campaign-project-selector.component';
import { SearchModule } from '../search/search.module';
import { MatCardModule } from '@angular/material/card';
import { TickMarkModule } from '../../tick-mark/tick-mark.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SktStoreModule } from '@givebrite/data';

@NgModule({
  declarations: [CampaignProjectSelectorComponent],
  imports: [
    CommonModule,
    SearchModule,
    FlexLayoutModule,
    // MatIconModule,
    // MatButtonModule,
    MatSelectModule,
    MatCardModule,
    // TickMarkModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  exports: [CampaignProjectSelectorComponent],
})
export class CampaignProjectSelectorModule {}
