<gb-campaign-section>
  <div class="campaign-impacts-card" *ngIf="impacts.length">
    <h2 class="mb-5">Choose your impact</h2>

    <ngx-flicking #impact [options]="{ bound: true, align: 'prev', autoResize: true }">
      <mat-card
        appearance="outlined"
        class="mat-elevation-z8 p-0 flicking-panel w-11/12 mb-3"
        flicking-panel
        *ngFor="let impact of impacts; let index = index"
      >
        <mat-card-content
          class="h-full text-center"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <h4 *ngIf="impact?.title || impact.name" class="my-3 text-lg font-bold;">
            {{
              impact?.title
                ? (impact?.title.slice(0, 35) | uppercase)
                : (impact?.name.slice(0, 35) | uppercase)
            }}
          </h4>
          <h3 class="text-5xl">
            {{ impact?.amount | currency: currency:'symbol':'1.0-0' }}
          </h3>

          <!-- <a
            [href]="giveNow(impact?.amount, impact?._id)"
            mat-raised-button
            color="warn"
            class="btn-md mt-2 give text-base"
            >Give Now</a
          > -->

          <givebrite-donate-button
            [charity]="charity"
            [campaign]="campaign"
            [impact]="impact"
            [isImpact]="true"
            [fundraiser]="fundraiser"
            [team]="team"
            [environment]="environment"
          ></givebrite-donate-button>
        </mat-card-content>
      </mat-card>
    </ngx-flicking>
  </div>
</gb-campaign-section>
