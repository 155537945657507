<div class="campaign-project-selector">
  <!-- <h4 class="text-center my-5 font-semibold">Select a Project</h4> -->

  <!-- <givebrite-search></givebrite-search> -->

  <!-- Loops through the default projects and displays them as clickable boxes -->
  <form class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <!-- <div *ngIf="!projects?.length">
      No projects found
      <span *ngIf="search">matching your search "{{ search }}"</span>
    </div>

    <div *ngFor="let project of projects; let i = index" fxFlex="28%" class="m-2 text-center">
      <mat-card
        (click)="selectedProject(project._id)"
        [class.cart-item-shadow]="project._id == selectedProjectId"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content class="h-full">
          <p>{{ project.name }}</p>

          <givebrite-tick-mark [checked]="project._id == selectedProjectId"> </givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div> -->

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Select a project</mat-label>
      <mat-select
        placeholder="select a project"
        [(ngModel)]="selectedProjectId"
        name="project"
        required
      >
        <mat-option [value]="project._id" *ngFor="let project of projects; let i = index">{{
          project.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div *ngIf="pagination$ | async as pagination" fxLayoutAlign="center" fxLayout="row">
      <mat-card
        *ngIf="showLoadMore(pagination)"
        (click)="loadMore()"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content class="text-center">
          <mat-icon>refresh</mat-icon>
          <div>Load More</div>
        </mat-card-content>
      </mat-card>
    </div> -->
  </form>
</div>
