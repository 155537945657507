import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'givebrite-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'gb-campaign-progress',
  },
})
export class ProgressBarComponent implements OnInit {
  mode: ProgressBarMode = 'determinate';

  @Input() currency: string;
  @Input() raised: number;
  @Input() goal: number;
  @Input() isMobile: boolean;

  _options = {
    top: true,
    bottom: false,
    type: {
      thin: true,
      fat: false,
    },
  };

  @Input() set options(options) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  constructor() {}

  ngOnInit(): void {}

  get percentage() {
    return (this.raised / this.goal) * 100;
  }

  get top() {
    return this._options.top;
  }

  get bottom() {
    return this._options.bottom;
  }

  get thin() {
    return this._options.type.thin;
  }

  get fat() {
    return this._options.type.fat;
  }

  get target() {
    return this.raised < 100 ? 100 : Math.ceil(this.raised / 10) * 10 + 20;
  }
}
