<div class="profile-overview my-3">
  <div
    class="w-full"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    *ngIf="user$ | async as user"
  >
    <div fxLayout="row" class="w-full">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <givebrite-user-logo-avatar
          [name]="user?.first_name + ' ' + user.last_name"
          [border]="true"
          [profileImage]="user?.profile_image"
          width="60px"
          class="mat-elevation-z8 rounded-full z-10"
        ></givebrite-user-logo-avatar>

        <div>
          <div>
            <ng-container>
              <h4 class="font-semibold mb-0 text-base sm:text-2xl">
                {{ 'Salam,' + ' ' + user.first_name + ' ' + user.last_name + '👋🏽' | titlecase }}
              </h4>
              <p class="mb-0" fxHide.xs>Welcome to your dashboard</p>
              <div fxHide.gt-xs class="actions" fxLayout="row" fxLayoutGap="20px">
                <a mat-stroked-button class="w-full btn-xs" (click)="editProfile(user)"
                  >Edit Profile</a
                >
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div fxHide.xs class="actions" fxLayout="row" fxLayoutGap="20px">
      <a mat-stroked-button class="w-full btn-xs" (click)="editProfile(user)">Edit Profile</a>
    </div>
  </div>
</div>
