import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { JwtService } from 'libs/api/src/lib/jwt.service';
import { catchError, map, tap } from 'rxjs/operators';
import { CharityRg } from '../../charity';
import { CharityServiceRg } from '../../charity';
import { Subscription, SubscriptionStateModel } from '../../subscription';
import { Card, Household, PaymentMethod, UserRg, UserAddress } from '../user.interface';
import { UserServiceRg } from '../user.service';
import {
  AddAddress,
  Analytics,
  Authorise,
  CancelUserSubscription,
  ConfirmSetupIntent,
  CreatePaymentIntentRg,
  CreateSetupIntent,
  DeleteAddress,
  DeleteUserPaymentMethod,
  ExpireSetupIntent,
  ForgotPasswordRg,
  GetAddresses,
  GetCharityRg,
  GetPaymentMethods,
  GetUser,
  GetUserSubscriptions,
  LoadingRg,
  LoginRg,
  LoginAsCharity,
  LoginTokenRg,
  LogoutRg,
  MarkAddressAsDefault,
  MarkPaymentMethodAsDefault,
  RegisterRg,
  ResetPasswordRg,
  ResetUser,
  SaveHousehold,
  SocialCompleteRg,
  SocialLoginRg,
  SocialRegister,
  UpdateAddress,
  UpdateUser,
  UserExists,
  VerifyLoginTokenRg,
} from './user.action';

export class UserStateModelRg {
  user: UserRg;
  charity?: CharityRg;
  loading: boolean;
  authenticated: boolean;
  dashboard?: any;
  intent?: {};
  payment_methods?: PaymentMethod[];
  subscriptions?: Subscription[];
  addresses: UserAddress[];
  selectedAddress?: UserAddress;
  selectedPaymentMethod?: Card;
  household?: Household;
  error?: any;
}

@State<UserStateModelRg>({
  name: 'user',
  defaults: {
    user: null,
    charity: null,
    loading: false,
    authenticated: false,
    // Note: All the property below are not being used
    dashboard: [],
    intent: null,
    payment_methods: [],
    subscriptions: [],
    addresses: [],
    selectedAddress: null,
    selectedPaymentMethod: null,
    household: { name: 'My Household', adults: 1, children: 0 },
    error: null,
  },
})
@Injectable()
export class UserStateRg {
  constructor(
    private userService: UserServiceRg,
    // private dashboardService: DashboardService,
    private charityService: CharityServiceRg,
    private jwt: JwtService,
    private router: Router
  ) {}

  @Selector()
  static isAuthenticated(state: UserStateModelRg): boolean {
    return !!state.authenticated;
  }

  @Selector()
  static getUser(state: UserStateModelRg) {
    return state.user;
  }

  @Selector()
  static getHousehold(state: UserStateModelRg) {
    return state.household;
  }

  @Selector()
  static donationTypes(state: UserStateModelRg) {
    return state.charity.donation_types;
  }

  @Selector()
  static getSubscriptions(state: UserStateModelRg) {
    return state.subscriptions;
  }

  @Selector()
  static getCharity(state: UserStateModelRg) {
    return state.charity;
  }

  @Selector()
  static getPaymentMethods(state: UserStateModelRg) {
    return state.payment_methods;
  }

  @Selector()
  static getDefaultPaymentMethod(state: UserStateModelRg) {
    return state.payment_methods.find((pm) => pm.is_default);
  }

  @Selector()
  static authenticated(state: UserStateModelRg) {
    return state.authenticated;
  }

  @Selector()
  static loading(state: UserStateModelRg) {
    return state.loading;
  }

  @Selector()
  static error(state: UserStateModelRg) {
    return state.error;
  }

  @Selector()
  static getSetupIntent(state: UserStateModelRg) {
    return state.intent;
  }

  @Selector()
  static getPaymentIntent(state: UserStateModelRg) {
    return state.intent;
  }

  @Selector()
  static getAddresses(state: UserStateModelRg) {
    return state.addresses;
  }

  @Selector()
  static getSelectedAddress(state: UserStateModelRg) {
    return state.selectedAddress;
  }

  @Selector()
  static getSelectedPaymentMethod(state: UserStateModelRg) {
    return state.selectedPaymentMethod;
  }

  @Action(ResetUser)
  reset({ patchState }: StateContext<UserStateModelRg>) {
    patchState({
      loading: false,
    });
  }

  @Action(LogoutRg)
  logout({ setState }: StateContext<UserStateModelRg>) {
    this.jwt.destroyToken();
    setState({
      loading: false,
      user: null,
      authenticated: false,
      addresses: [],
      payment_methods: [],
      selectedAddress: null,
    });
  }

  // @Action(Logout)
  // logout({ patchState, getState, setState, dispatch }: StateContext<UserStateModelRg>, {postcode} ) {
  //   return this.userService.postcode(postcode).pipe(
  //     tap(
  //       user => {
  //         dispatch(new Authorise(user));
  //       },
  //       error => {
  //         patchState({ loading: false });
  //         return error;
  //       }
  //     )
  //   );
  // }

  @Action(ForgotPasswordRg)
  forgotPassword(
    { patchState, getState, setState }: StateContext<UserStateModelRg>,
    { email }: ForgotPasswordRg
  ) {
    patchState({ loading: true });
    return this.userService.forgot(email).pipe(
      tap(
        (response) => {
          patchState({ loading: false });
          console.log(response);
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(ResetPasswordRg)
  resetPassword(
    { patchState, getState, setState, dispatch }: StateContext<UserStateModelRg>,
    { payload }: ResetPasswordRg
  ) {
    patchState({ loading: true });
    return this.userService.reset(payload).pipe(
      tap(
        (user) => {
          dispatch(new Authorise(user));
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(LoginRg)
  login({ patchState, getState, setState }: StateContext<UserStateModelRg>, { credentials }) {
    patchState({ loading: true });

    return this.userService.login(credentials).pipe(
      map((response) => {
        return response;
      }),
      tap(
        (user: any) => {
          this.userService.authorise(user.token);

          patchState({
            loading: false,
            user: user.user,
            addresses: user.user.address,
            payment_methods: user.user.cards,
            authenticated: true,
            charity: user.user.charity,
            selectedAddress: user.user.address.find((address) => address.is_default),
            selectedPaymentMethod: user.user.cards.find((card) => card.is_default),
          });

          if (user.selected_charity) {
            patchState({ charity: user.selected_charity });
          }
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(LoginTokenRg)
  loginToken({ patchState, dispatch }: StateContext<UserStateModelRg>, { payload }) {
    patchState({ loading: true });
    return this.userService.loginToken(payload).pipe(
      tap((res: any) => {
        patchState({ loading: false });
      })
    );
  }

  @Action(VerifyLoginTokenRg)
  verifyLoginToken({ patchState, dispatch }: StateContext<UserStateModelRg>, { payload }) {
    patchState({ loading: true });
    return this.userService.verifyLoginToken(payload).pipe(
      map((user) => {
        dispatch(new Authorise(user));
        this.router.navigate(['/']);
      }),
      catchError((error) => {
        patchState({ loading: false, error: error });
        return this.router.navigate(['/user/login'], { queryParams: { email: payload.email } });
      })
    );
  }

  @Action(SocialCompleteRg)
  socialComplete({ patchState, dispatch }: StateContext<UserStateModelRg>, { credentials }) {
    patchState({ loading: true });
    return this.userService
      .socialComplete(credentials)
      .pipe
      // map((user) =>),
      // catchError((error) => {
      //   return ));
      // })
      ();
  }

  @Action(GetUserSubscriptions)
  getUserSubscription(
    { patchState }: StateContext<SubscriptionStateModel>,
    { limit, page, filter, sort, column }
  ) {
    patchState({ loading: true });
    return this.userService.getUserSubscriptions(limit, page, filter, sort, column).pipe(
      map((result: any) => result.docs),
      tap(
        (result) => {
          patchState({
            subscriptions: result,
            loading: false,
          });
        },
        (error) => {
          patchState({ loading: false, subscriptions: [] });
          return error;
        }
      )
    );
  }

  @Action(Authorise)
  authorise({ patchState }: StateContext<UserStateModelRg>, { user }) {
    this.userService.authorise(user.token);
    patchState({
      loading: false,
      user: user.user,
      addresses: user.user.address,
      payment_methods: user.user.cards,
      authenticated: true,
      charity: null,
      selectedAddress: user.user.address.find((address) => address.is_default),
      selectedPaymentMethod: user.user.cards.find((card) => card.is_default),
    });

    if (user.selected_charity) {
      patchState({ charity: user.selected_charity });
    }
  }

  @Action(LoginAsCharity)
  loginAsCharity(
    { patchState, getState, setState, dispatch }: StateContext<UserStateModelRg>,
    { email }
  ) {
    return this.userService.loginAsCharity(email).pipe(
      tap(
        (user) => {
          dispatch(new Authorise(user));
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(SocialLoginRg)
  socialLogin({ patchState, dispatch }: StateContext<UserStateModelRg>, { credentials }) {
    patchState({ loading: true });

    return this.userService.socialLogin(credentials).pipe(
      map((response) => {
        return response;
      }),
      tap(
        (response: any) => {
          if (response.code == 200) {
            // patchState({
            //   loading: false,
            //   user: response.user,
            //   authenticated: true
            // });
            // return this.userService.authorise(response.user);
            dispatch(new Authorise(response));
            this.router.navigate(['/']);

            // new Navigate(['/']);

            /*if (response.user.selected_charity) {
              patchState({ charity: response.user.selected_charity });
            }*/
          } else if (response.code == 201) {
            const data = {
              ...credentials,
              token: response.token,
            };
            patchState({
              loading: false,
              user: data,
            });
            this.router.navigate(['/user/register']);

            // return dispatch(
            //   new SocialRegister({
            //     ...credentials,
            //     token: response.token,
            //   })
            // );
          }
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(SocialRegister)
  SocialRegister(
    { patchState, dispatch, getState, setState }: StateContext<UserStateModelRg>,
    { credentials }
  ) {
    patchState({ loading: true });

    return this.userService.socialRegister(credentials).pipe(
      map((response: any) => {
        return response.user;
      }),
      tap(
        (user: UserRg) => {
          this.userService.authorise(user.token);
          dispatch(new Authorise(user.user));
          // patchState({
          //   loading: false,
          //   user: user.user,
          //   authenticated: true
          // });
          this.router.navigate(['/']);
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(RegisterRg)
  register(
    { patchState, getState, setState }: StateContext<UserStateModelRg>,
    { payload }: RegisterRg
  ) {
    patchState({ loading: true });

    return this.userService.register(payload).pipe(
      map((response) => {
        this.router.navigate(['/']);
        return response;
      }),
      tap(
        (user: UserRg) => {
          this.userService.authorise(user.token);

          patchState({
            loading: false,
            user: user.user,
            authenticated: true,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(GetUser)
  getUser({ patchState, getState, setState, dispatch }: StateContext<UserStateModelRg>) {
    const state = getState();
    patchState({ loading: true });

    return this.userService.get().pipe(
      tap(
        (user: any) => {
          setState({
            ...state,
            loading: false,
            authenticated: true,
            user: user,
          });

          if (user.selected_charity) {
            patchState({ charity: user.selected_charity });
          }
        },
        (error) => {
          patchState({ loading: false });
          dispatch(new LogoutRg());
          return error;
        }
      )
    );
    //}
  }

  @Action(UserExists)
  exists(
    { patchState, setState, dispatch }: StateContext<UserStateModelRg>,
    { email }: UserExists
  ) {
    patchState({ loading: true });

    return this.userService.exists(email).pipe(
      tap(
        (user) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
    //}
  }

  @Action(UpdateUser)
  updateUser({ patchState, dispatch }: StateContext<UserStateModelRg>, { user }: UpdateUser) {
    patchState({ loading: true });
    return this.userService.update(user).pipe(
      tap(
        (user: UserRg) => {
          patchState({
            loading: false,
            user: user,
          });
        },
        (error) => {
          patchState({ loading: false });
          dispatch(new LogoutRg());
          return error;
        }
      )
    );
    //}
  }

  @Action(GetAddresses)
  getAddress({ patchState }: StateContext<UserStateModelRg>) {
    patchState({ loading: true });

    return this.userService.getAddresses().pipe(
      tap(
        (addresses: UserAddress[]) => {
          patchState({
            addresses: addresses,
            selectedAddress: addresses.find((address) => address.is_default),
            loading: false,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
    //}
  }

  @Action(UpdateAddress)
  updateAddress({ patchState, getState }: StateContext<UserStateModelRg>, { address }) {
    patchState({ loading: true });

    return this.userService.updateAddress(address).pipe(
      tap(
        (addresses: any) => {
          const state = getState();

          patchState({
            loading: false,
            addresses: addresses,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
    //}
  }

  @Action(MarkAddressAsDefault)
  markAddressAsDefault({ dispatch, patchState }: StateContext<UserStateModelRg>, { address }) {
    // dispatch(new UpdateAddress(address));
    patchState({
      loading: false,
      selectedAddress: address,
    });
  }

  @Action(AddAddress)
  addAddress({ patchState, getState }: StateContext<UserStateModelRg>, { payload }) {
    patchState({ loading: true });
    const state = getState();
    return this.userService.addAddress(payload).pipe(
      tap(
        (address: any) => {
          console.log(address);
          patchState({
            loading: false,
            selectedAddress: address.find((address) => address.is_default),
            addresses: address,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
    //}
  }

  @Action(DeleteAddress)
  deleteAddress({ patchState, getState }: StateContext<UserStateModelRg>, { id }) {
    patchState({ loading: true });
    const state = getState();
    return this.userService.deleteAddress(id).pipe(
      tap(
        () => {
          const filteredAddresses = state.addresses.filter((item) => item.id !== id);

          patchState({
            loading: false,
            addresses: filteredAddresses,
          });
        },
        (error) => {
          patchState({ loading: false });
        }
      )
    );
  }

  @Action(DeleteUserPaymentMethod)
  deleteUserPaymentMethod(
    { patchState, getState }: StateContext<UserStateModelRg>,
    { paymentMethod }
  ) {
    patchState({ loading: true });
    const state = getState();
    return this.userService.deletePaymentMethod(paymentMethod).pipe(
      tap(
        () => {
          const filteredPaymentMethods = state.payment_methods.filter(
            (item) => item._id !== paymentMethod._id
          );

          patchState({
            loading: false,
            payment_methods: filteredPaymentMethods,
          });
        },
        (error) => {
          patchState({ loading: false });
        }
      )
    );
  }

  @Action(GetPaymentMethods)
  getPaymentMethods(
    { patchState, getState, setState }: StateContext<UserStateModelRg>,
    { params }
  ) {
    patchState({ loading: true });

    return this.userService.payment_methods(params).pipe(
      tap(
        (payment_methods: PaymentMethod[]) => {
          patchState({
            loading: false,
            payment_methods: payment_methods,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(GetCharityRg)
  getCharity({ patchState, getState, setState }: StateContext<UserStateModelRg>, { id }) {
    patchState({ loading: true });

    return this.charityService.get(id).pipe(
      tap(
        (charity) => {
          patchState({
            loading: false,
            charity: charity,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(Analytics)
  getAnalytics({ patchState, getState, setState }: StateContext<UserStateModelRg>, { type }) {
    const state = getState();
    return;
    // return this.dashboardService.analytics(state.charity.id, type).pipe(
    //   tap((analytics) => {
    //     const m = new Map([[type, analytics]]);
    //     patchState({ dashboard: m });
    //   })
    // );
  }

  @Action(CreateSetupIntent)
  createSetupIntent({ patchState }: StateContext<UserStateModelRg>, { params }) {
    patchState({ loading: true });

    return this.userService.createSetupIntent(params).pipe(
      tap(
        (response) => {
          patchState({
            intent: response.data,
            // loading: false
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(ConfirmSetupIntent)
  confirmSetupIntent({ patchState }: StateContext<UserStateModelRg>, { params }) {
    patchState({ loading: true });
    console.log(params);
    return this.userService.confirmSetupIntent(params).pipe(
      tap(
        (response) => {
          patchState({
            loading: false,
            payment_methods: response.user.cards,
            selectedPaymentMethod: response.user.cards.find((card) => card.is_default),
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(MarkPaymentMethodAsDefault)
  markPaymentMethodAsDefault({ patchState }: StateContext<UserStateModelRg>, { card }) {
    // dispatch(new UpdateAddress(address));
    patchState({
      loading: false,
      selectedPaymentMethod: card,
    });
  }

  @Action(ExpireSetupIntent)
  expireSetupIntent({ patchState }: StateContext<UserStateModelRg>, { params }) {
    patchState({ intent: null });
  }

  @Action(SaveHousehold)
  saveHousehold({ patchState }: StateContext<UserStateModelRg>, { household }: SaveHousehold) {
    patchState({ household: household });
  }

  @Action(CreatePaymentIntentRg)
  createPaymentIntent({ patchState }: StateContext<UserStateModelRg>, { params }) {
    patchState({ loading: true });
    return this.userService.createPaymentIntent(params).pipe(
      tap(
        (response: any) => {
          console.log(response);
          patchState({
            intent: { client_secret: response.gateway_response.client_secret },
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(LoadingRg)
  loading({ patchState }: StateContext<UserStateModelRg>, { loading }) {
    patchState({ loading: loading });
  }

  @Action(CancelUserSubscription)
  cancelSubscription(
    { getState, setState, patchState, dispatch }: StateContext<SubscriptionStateModel>,
    { id, cancel_at_period_end }
  ) {
    patchState({ loading: true });
    console.log(id);
    return this.userService.cancel(id, cancel_at_period_end).pipe(
      tap(
        (result: any) => {
          console.log(result);
          setState(
            patch({
              subscriptions: updateItem<Subscription>(
                (sub) => sub._id === result._id,
                patch({ gateway: result.gateway })
              ),
            })
          );
        },
        (error) => {
          console.log(error);
          patchState({ loading: false });
          return error;
        }
      )
    );
  }
}
