import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { CharityRg, CharityStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-charity-showcase',
  templateUrl: './charity-showcase.component.html',
  styleUrls: ['./charity-showcase.component.scss'],
})
export class CharityShowcaseComponent implements OnInit, OnDestroy {
  @Select(CharityStateRg.getCharities) charities$: Observable<CharityRg[]>;
  slideConfig = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 5,
    speed: 500,
    arrows: true,
    infinite: false,
    variableWidth: true,
    waitForAnimate: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '0',
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '0',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          focusOnSelect: true,
          edgeFriction: 0.1,
        },
      },
    ],
  };

  charities = [];
  partners = [];
  pots = [];

  featured = ['muslim-aid', 'penny-appeal', 'nim', 'uwt'];
  constructor() {
    function shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }

      return array;
    }

    this.charities$.pipe(untilDestroyed(this)).subscribe((charities) => {
      const partners = [...charities];
      const soloCharities = [...charities];
      this.pots = charities;

      // PARTNERS
      this.partners = shuffle(
        partners
          .filter((c) => c?.partners?.length > 0)
          .sort((a: any, b: any) => {
            return a?.slug - b?.slug;
          })
      );

      // SOLO CHARITIES
      this.charities = shuffle(
        soloCharities
          .filter((c) => !c?.partners?.length)
          .sort((a, b) => {
            return a?.is_featured - b?.is_featured;
          })
      );

      // this.pots = charities
      // .filter(c => {
      //   return c.partner_programme
      // })
    });
  }

  ngOnInit() {}
  ngOnDestroy() {}
}
