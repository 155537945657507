<div
  class="avatar-container mat-elevation-z2 text-center mr-3 p-4"
  [style.background-color]="charity?.logo.white ? charity?.theme.primary : 'white'"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div
    class="logo-container"
    [class.partner]="charity.partner_programme"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <ng-container *ngIf="!charity.partner_programme">
      <div *ngIf="!loaded" class="spinner-wrapper px-3">
        <mat-progress-spinner
          diameter="15"
          mode="indeterminate"
          color="primary"
        ></mat-progress-spinner>
      </div>
      <a [class.hidden]="!loaded" routerLink="/{{ charity.slug }}"
        ><img
          (load)="loaded = true"
          class="charity-avatar"
          height="250"
          fit="max"
          [src]="charity?.logo.white ? charity?.logo.white : charity?.logo.color"
      /></a>
      <!-- <givebrite-charity-logo class="mr-2 mb-2" size="50px" *ngFor="let charity of charity.partners" [charity]="charity"></givebrite-charity-logo> -->
    </ng-container>

    <ng-container *ngIf="charity.partner_programme">
      <!-- <h1 class="mb-4">Give to 6</h1> -->
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <givebrite-charity-logo
          class="mr-2 mb-2"
          size="50px"
          *ngFor="let charity of charity.partners"
          [charity]="charity"
        ></givebrite-charity-logo>
      </div>
    </ng-container>
  </div>

  <!-- <p>Automate your donations with {{ charity.name }}</p> -->
  <button
    *ngIf="!charity.partner_programme"
    class="mt-2 mat-elevation-z3"
    queryParamsHandling="merge"
    routerLink="/{{ charity.slug }}"
    mat-stroked-button
  >
    Give Now
  </button>
  <button
    *ngIf="charity.partner_programme"
    class="mt-2 mat-elevation-z3"
    queryParamsHandling="merge"
    routerLink="/{{ charity.slug }}"
    mat-stroked-button
  >
    Give to 6
  </button>
</div>
