import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSearch } from './filter-search.pipe';

@NgModule({
  declarations: [FilterSearch],
  imports: [CommonModule],
  exports: [FilterSearch],
})
export class FilterSearchModule {}
