import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import icMoon from '@iconify/icons-ic/baseline-brightness-3';
import icHeart from '@iconify/icons-ic/favorite';
import icFavorite from '@iconify/icons-ic/language';
import icTrack from '@iconify/icons-ic/trending-up';

@Component({
  selector: 'givebrite-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
  icHeart = icHeart;
  icMoon = icMoon;
  icTrack = icTrack;
  icFavorite = icFavorite;

  steps = [
    { title: 'Select a charity', icon: icHeart },
    { title: 'Select a cause', icon: icFavorite },
    { title: 'Select your split', icon: icTrack },
    { title: 'Automate', icon: icMoon },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToCharities() {
    this.router.navigate(['/charities']);
  }
}
