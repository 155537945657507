<mat-card
  appearance="outlined"
  [style.background]="background"
  [style.color]="color"
  [style.border]="'1px solid' + borderColor"
  [style.boxShadow]="shadow"
  class="my-4 sm:p-8"
>
  <mat-card-content>
    <div fxLayout="column" fxLayoutAlign="center center">
      <img [src]="environment.assetPath + imageSrc" class="mt-4" />

      <h3 class="my-8 text-center">
        If you are a UK taxpayer the value of your gift can increase by 25% at no extra cost to you!
      </h3>

      <div fxLayout="row" fxLayoutAlign="center center">
        <h2>{{ amount | currency: currency:'symbol':'1.2-2' }}</h2>
        <mat-icon class="mx-2">arrow_forward</mat-icon>
        <h2>{{ amount + giftAidPercent | currency: currency:'symbol':'1.2-2' }}</h2>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-slide-toggle [(ngModel)]="is_giftaid"></mat-slide-toggle>
        <h5 class="mt-4 ml-2">
          Yes, I would like
          <strong
            ><span> {{ title }}</span></strong
          >
          to receive Gift Aid on my donation
        </h5>
      </div>
    </div>
  </mat-card-content>
</mat-card>
