import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonorDetailsComponent } from './donor-details.component';
import { ViewCardModule } from '../view-card/view-card.module';



@NgModule({
  declarations: [DonorDetailsComponent],
  imports: [
    CommonModule,
    ViewCardModule
  ],
  exports: [DonorDetailsComponent]
})
export class DonorDetailsModule { }
