import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'campaign-add-section-placeholder',
  templateUrl: './campaign-add-section-placeholder.component.html',
  styleUrls: ['./campaign-add-section-placeholder.component.scss'],
})
export class CampaignAddSectionComponent implements OnInit {
  @Input() type;
  @Output() add: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  buttonClicked() {
    this.add.emit();
  }
}
