export interface CartItem {
  _id?: string;
  inventory?: string;
  item?: any;
  qty?: number;
  shares?: number;
  size?: string;
  amount?: number;
  metadata?: any;
  total_shares?: number;
}

export interface Cart {
  items?: CartItem[];
  total?: number;
  subtotal?: number;
  currency?: string;
  tip?: number;
  service?: number;
  charity?: string;
}
