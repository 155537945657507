<div
  [class.boxed]="isBoxed$ | async"
  [class.horizontal-layout]="!(isLayoutVertical$ | async)"
  [class.is-mobile]="!(isDesktop$ | async)"
  [class.vertical-layout]="isLayoutVertical$ | async"
  [class.has-fixed-footer]="(isFooterFixed$ | async) && isFooterVisible$ | async"
  [class.has-footer]="isFooterVisible$ | async"
  [class.toolbar-fixed]="isToolbarFixed$ | async"
  [class.toolbar-hidden]="isFooterVisible$ | async"
  [class.sidenav-collapsed]="sidenavCollapsed$ | async"
  [class.content-container]="containerEnabled$ | async"
  [class.with-search]="searchOpen$ | async"
  class="page-container mat-typography"
  fxLayout="column"
  fxFlexFill
>
  <vex-progress-bar></vex-progress-bar>

  <vex-search></vex-search>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      [disableClose]="!ltLg2$ | async"
      [fixedInViewport]="ltLg2$ | async"
      [mode]="(ltLg2$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'"
      [opened]="(!ltLg2$ | async) && !(isLayoutVertical$ | async)"
      class="sidenav"
      position="end"
    >
      <ng-container *ngTemplateOutlet="sidenavRef"></ng-container>
    </mat-sidenav>
  </mat-sidenav-container>

  <!-- <mat-sidenav-content class="sidenav-content"> -->
  <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

  <main class="content" [fxHide]="sidenavOpen$ | async">
    <router-outlet></router-outlet>
  </main>

  <ng-container *ngTemplateOutlet="footerRef"></ng-container>
  <!-- </mat-sidenav-content> -->
  <!-- </mat-sidenav-container> -->
</div>
