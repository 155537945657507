export interface ProductItem {
  id: number;
  name?: string;
  country?: string;
  currency?: string;
  price?: number;
  no_of_shares?: number;
  type?: string;
  charity_id?: number;
  created_at?: string;
}
