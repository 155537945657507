import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subscription } from './subscription.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  subscription: Subject<Subscription> = new Subject<Subscription>();

  constructor(private http: ApiService) {}

  get(id: number) {
    // interface SingleSubscriptionResponse {
    //   code: number;
    //   pagination: Pagination;
    //   data: Subscription;
    // }

    return this.http.get(`subscription/${id}`).pipe(
      map((response) => {
        this.subscription.next(response);
        return response;
      })
    );
    //.subscribe(subscription => this.subscription.next(subscription));
  }

  list(
    charityId,
    limit = 10,
    page = 1,
    filter: {} = { status: 'active' },
    sort = 'asc',
    column = '',
    download = 0
  ) {
    interface SubscriptionListResponse {
      code: number;
      data: Subscription[];
    }
    return this.http.get(
      `subscription/charity/list?limit=${limit}&page=${page}&filter=${filter}&sort=${sort}&column=${column}&after=2019-04-23`
    );
  }

  leaderboard(limit = 10, page = 1, filter: {} = { status: 'active' }, sort = 'asc', column = '') {
    return this.http.get(
      `subscription/leaderboard?limit=${limit}&page=${page}&filter=${filter}&sort=${sort}&column=${column}&after=2019-04-23`
    );
  }

  cancel(id, cancel_at_period_end = 0) {
    return this.http.delete(`subscription/${id}?cancel_at_period_end=${cancel_at_period_end}`);
  }

  create(payload: any) {
    return this.http.post(`subscription/create`, payload);
  }

  update(payload: any) {
    return this.http.put(`subscription/${payload._id}`, payload);
  }

  confirm(payload: any) {
    return this.http.post(`subscription/confirm/${payload._id}`, payload);
  }

  setDefaultPaymentMethod(id, payment_method_id) {
    return this.http.put(`subscription/${id}/payment_method`, {
      payment_method_id: payment_method_id,
    });
  }

  statistics() {
    return this.http.get(`app/statistics`);
  }

  settings() {
    return this.http.get(`app/settings`);
  }
}
