import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
// import { CardSectionModule } from './../../../../../../apps/charities/skt/donate/src/app/checkout/shared/card-section/card-section.module';
import { DonationTypesComponent } from './donation-types.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
@NgModule({
  declarations: [DonationTypesComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatCardModule,
    MatRadioModule,
    MatSelectModule,
    // CardSectionModule,
    FlexLayoutModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  exports: [DonationTypesComponent],
})
export class DonationTypesModule {}
