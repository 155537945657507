import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { CreateUser, SocialUser, Authenticate } from './user.interface';
import { CookieService } from 'ngx-cookie';
import { catchError, map, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { authentication } from '@givebrite/theming';
import { ActivatedRoute } from '@angular/router';
import { NewPassword } from '@givebrite/data';
import { of } from 'rxjs';
import { isDevMode } from '@angular/core';
import { Charity } from '../charity';
import { User } from '.';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public cookieDomain; // = isDevMode() ? 'givebrite.local' : 'givebrite.com';
  isBrowser: boolean;
  constructor(
    private http: ApiService,
    private cookies: CookieService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  login(data: User) {
    return this.http.post('auth/login', data);
  }

  loginWithCharity(data: any) {
    return this.http.post('dashboard/charity/auth/login/'+data.charity);
  }

  loginToken(data: Authenticate) {
    return this.http.post('auth/login_token', data);
  }

  verifyLoginToken(data: Authenticate) {
    return this.http.post('auth/verify_login_token', data);
  }

  register(data: CreateUser) {
    return this.http.post('auth/register', data);
    // return this.http.post('auth/verify_register_token', data);
  }

  verifyEmail(data) {
    return this.http.put('auth/verify_email', data);
  }

  reset(data: CreateUser) {
    return this.http.post('auth/reset_password', data);
  }

  setNewPassword(data: NewPassword) {
    return this.http.put('auth/password', data);
  }

  /**
   *  Return JWT from cookies
   * */
  get jwt() {
    return this.cookies.get('jwt');
  }
  get jwtSuper() {
    return this.cookies.get('jwtSuper');
  }

  setJwt(token) {
    console.log(this.cookieDomain);
    return this.cookies.put('jwt', token, { domain: this.cookieDomain });
  }
  
  setJwtSuper(token) {
    console.log(this.cookieDomain);
    return this.cookies.put('jwtSuper', token, { domain: this.cookieDomain });
  }

  removeJwtSuper(){
    if (this.jwtSuper)
      this.cookies.remove('jwtSuper', {
        domain: this.cookieDomain,
      });
  }

  logout() {
    if (this.jwt)
      this.cookies.remove('jwt', {
        domain: this.cookieDomain,
      });
    if (this.jwtSuper)
      this.cookies.remove('jwtSuper', {
        domain: this.cookieDomain,
      });
  }

  /**
   *
   * @returns Returns true if a JWT token is present
   */
  get authenticated(): boolean {
    return this.isBrowser && !!this.jwt;
  }
  get isSuper(): boolean {
    return this.isBrowser && !!this.jwtSuper;
  }

  /**
   *
   * @returns Returns true if a JWT token is present
   */
  get userRole(): string {
    return this.userService.userFromJwt ? this.userService.userFromJwt.role : 'user';
  }

  get hasCharity(): boolean {
    return this.userService.userFromJwt ? !!this.userService.userFromJwt.charity : false;
  }

  get userCharity(): Charity {
    return this.userService.userFromJwt.charity;
  }

  //chech user role for charity user
  get charityUser() {
    return this.authenticated && (this.userRole == 'admin' || this.userRole == 'editor');
  }

  get charitysteps() {
    return this.userService.userFromJwt && this.userService.userFromJwt.charity?.step;
  }
  socialLogin(socialUser: SocialUser) {
    return this.http.post('auth/social_register', socialUser).pipe(
      map((response) => {
        return response;
      })
    );
  }
  socialComplete(socialUser: SocialUser) {
    return this.http.post('auth/social_complete', socialUser).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserProfile() {
    return this.http.get('auth/profile').pipe(
      map((response) => {
        return { user: response };
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }

  /**

   * redirect to auth page
   * @returns url
   */
  getRedirectUrl() {
    return (window.location.href = authentication + '?continue=' + window.location.href);
  }

  searchUser(name = '') {
    let nameUrl = '';
    if (name) {
      nameUrl = `name=${name}&`;
    }
    return this.http.get(`auth/filter?value=${name}`);
  }

  getUserFollowers() {
    return this.http.get('auth/followers').pipe(
      map((response) => {
        return response.docs;
      })
    );
  }

  uploadMedia(file, charityId) {
    return this.http.post(`auth/user/file?charityId=${charityId}`, file).pipe();
  }

  uploadUserMedia(file, userId) {
    return this.http.post(`auth/user/file?userId=${userId}`, file).pipe();
  }

  /**
   * follow chairty / user
   * @param follow count
   * @param userId userId
   * @returns response
   */
  followMe(follow: number, userId?: string) {
    const decoded = JSON.parse(atob(this.jwt.split('.')[1]));
    console.log(decoded);

    return this.http.get(`auth/follow?user=${userId ? userId : decoded._id}&follow=${follow}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }

  toggleCharityFollow(follow: number, charityId?: string) {
    return this.http.get(`charity/follow?charity=${charityId}&follow=${follow}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }

  updateUserProfile(payload) {
    return this.http.put('auth/profile', payload).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }

  checkEmailFound(payload) {
    return this.http.post('auth/user/checkmail', payload).pipe(
      map((response) => {
        return response === "User Exist" ? true : false;
      })
    )
  }
  
  newsletter(email: string) {
    return this.http.post('auth/newsletters', email).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of({ error });
      })
    );
  }
  checkPhoneFound(payload) {
    return this.http.post('auth/text_login_request', payload).pipe(
      map((response) => {
        return response;
      })
    )
  }

  logintext(data: User) {
    return this.http.post('auth/login_via_text', data);
  }
}
