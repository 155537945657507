<view-card [loading]="loading" [type]="'header'">

    <div  gdColumns="2fr 1fr" fxFlex gdGap="20px" >

    <div gdColumn>
      <ng-container *ngIf="subscription">
          <div>
            <ic-icon [icIcon]="counterclockwiseArrowsButton" inline="true" class="mr-2"></ic-icon>
            <span>Subscription</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
              <div fxLayoutAlign="start baseline">
                <h2 @fadeInRight class="block">{{ subscription?.amount | currency:subscription?.currency:'symbol'}}</h2> 
                <span class="ml-1">{{ subscription?.currency }}</span> <span class="ml-1"> / {{ subscription?.frequency }}</span>
              </div>
              <div *ngIf="subscription" class="ml-2 inline-block {{ status }} text-xs px-2 py-1 rounded-full uppercase font-semibold tracking-wide">{{ subscription?.status }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="donation">
            <div>
              <ic-icon [icIcon]="icPayment" inline="true" class="mr-2"></ic-icon>
              <span>Donation</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutAlign="start baseline">
                  <h2 @fadeInRight class="block">{{ donation?.amount | currency:donation?.currency:'symbol'}}</h2> 
                  <span class="ml-1">{{ donation?.currency }}</span>
                </div>
                <div *ngIf="donation" class="ml-2 inline-block {{ donationStatus }} text-xs px-2 py-1 rounded-full uppercase font-semibold tracking-wide">{{ donation?.status === 1 ? "Paid" : "Unpaid" }}</div>
            </div>
        </ng-container>

      </div>
      <div gdColumn>
          <ng-content></ng-content>
      </div>

      </div>
      
</view-card>