<div class="header" role="Header">
  <givebrite-hero-header></givebrite-hero-header>
  <div class="container header-body">
    <div fxLayout="row">
      <div class="col-md-2 col-12 text-center">
        <div class="d-inline-block align-items-start justify-content-start logo">
          <a [routerLink]="['/']" class="d-flex w-100">
            <img src="https://s3.givebrite.com/qurbanigiving/logo.png" alt="" />
          </a>
        </div>
      </div>
      <div fxFlex></div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <a class="rounded-full cta-primary" mat-raised-button color="primary" (click)="navigate()"
          >Bismillah</a
        >
        <a [routerLink]="['/']" class="get-started">Get Started</a>
      </div>
    </div>
  </div>

  <!-- <div class="banner">
    <h2 class="m-0 text-center font-medium">
      This Qurbani Portal has now closed. Contact your local charity to arrange your Qurbani
    </h2>
  </div> -->
</div>
