import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardRg } from 'libs/data/src/lib/ramadhan-giving/auth/auth-guard.service';
import { NoAuthGuard } from 'libs/data/src/lib/ramadhan-giving/auth/no-auth-guard.service';
// import { AuthGuardRg, NoAuthGuard } from 'libs/data/src/lib/ramadhan-giving';
import { LoginComponent } from './login.component';
// import { AuthGuard } from "./../../services/auth-guard.service";
// import { NoAuthGuard } from "./../../services/no-auth-guard.service";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [NoAuthGuard, AuthGuardRg],
})
export class LoginRoutingModule {}
