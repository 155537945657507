import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewHeaderComponent } from './view-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ViewCardModule } from '../view-card/view-card.module';
import { PageLayoutModule } from 'libs/theming/src/lib/components/page-layout/page-layout.module';

@NgModule({
  declarations: [ViewHeaderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    IconModule,
    MatMenuModule,
    MatDialogModule,
    FlexLayoutModule,
    PageLayoutModule,
    ViewCardModule,
  ],
  exports: [ViewHeaderComponent],
})
export class ViewHeaderModule {}
