import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { SocialLoginComponent } from './social-login.component';

@NgModule({
  declarations: [SocialLoginComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule, IconModule, MatButtonModule],
  exports: [SocialLoginComponent],
})
export class UserSocialLoginModule {}
