import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'apps/charities/skt/campaign/src/environments/environment';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import moment from 'moment';

@Component({
  selector: 'givebrite-donate-button',
  templateUrl: './donate-button.component.html',
  styleUrls: ['./donate-button.component.scss'],
})
export class DonateButtonComponent implements OnInit {
  @Input() isImpact;
  @Input() environment = environment;

  donateUrl = '';

  _charity: Charity;
  @Input() set charity(charity) {
    if (charity) {
      this._charity = charity;

      this.initButton();
    }
  }

  _campaign: Campaign;
  @Input() set campaign(campaign) {
    if (campaign) {
      this._campaign = campaign;
      if (this._campaign) {
        this.initButton();
      }
    }
  }

  _impact;
  @Input() set impact(impact) {
    if (impact) {
      this._impact = impact;
      this.initButton();
    }
  }

  _fundraiser;
  @Input() set fundraiser(fundraiser) {
    if (fundraiser) {
      this._fundraiser = fundraiser;
      this.initButton();
    }
  }

  _team;
  @Input() set team(team) {
    if (team) {
      this._team = team;
      this.initButton();
    }
  }

  @Input() disabled;
  disabledText: string;
  @Input() button: 'raised' | 'stroked' = 'raised';
  @Input() size: 'normal' | 'medium' | 'large' = 'large';
  @Input() isEnded: boolean;
  @Input() showIcon = false;
  @Input() customClass: boolean;

  constructor() {}

  ngOnInit(): void {}

  initButton() {
    this.buildUrl();
    this.disableButton();
  }

  disableButton() {
    let campaign;

    if (this._fundraiser?.status) {
      if (this._fundraiser.status == 'ended') {
        this.disabled = true;
        this.disabledText = `Fundraiser ended`;
        if (this._fundraiser.campaign) {
          campaign = this._fundraiser.campaign;
        }
      }
    }

    if (this._team?.status) {
      if (this._team.status == 'ended') {
        this.disabled = true;
        this.disabledText = `Team ended`;
        if (this._team.campaign) {
          campaign = this._team.campaign;
        }
      }
    }

    if (this._campaign) {
      campaign = this._campaign;
    }

    if (campaign?.status) {
      if (campaign.status == 'ended') {
        this.disabled = true;
        this.disabledText = `Campaign ended`;
      }
    }
  }

  disableButtonIfExpired(date, text) {
    if (moment(date).isBefore()) {
      this.disabled = true;
      this.disabledText = `${text} ended`;
    }
  }

  buildUrl() {
    // this.donateUrl = this.environment.apps.donate.url;
    const env = this.environment.apps.donate.url;

    if (this._campaign) {
      this.donateUrl = env + '/campaign/' + this._campaign.slug;
    }

    if (this._team) {
      this.donateUrl = env + '/team/' + this._team.slug;
    }

    if (this._fundraiser) {
      this.donateUrl = env + '/fundraiser/' + this._fundraiser.slug;
    }

    if (this._impact) {
      if (this._campaign) {
        this.donateUrl =
          env + '/campaign/' + this._campaign.slug + '?amount=' + this.impactNameAndId;
      } else if (this._team) {
        this.donateUrl = env + '/team/' + this._team.slug + '?amount=' + this.impactNameAndId;
      } else if (this._fundraiser) {
        this.donateUrl =
          env + '/fundraiser/' + this._fundraiser.slug + '?amount=' + this.impactNameAndId;
      }
    }
  }

  get impactNameAndId() {
    return (
      this._impact.amount +
      (this._impact.title ? '&title=' + this._impact.title : '') +
      (this._impact._id ? '&impact=' + this._impact._id : '')
    );
  }
}
