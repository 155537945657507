import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

export interface Options {
  background: string;
  color: string;
  borderColor: string;
  shadow: string;
}
@Component({
  selector: 'givebrite-gitftaid-checkout-card',
  templateUrl: './gitftaid-checkout-card.component.html',
  styleUrls: ['./gitftaid-checkout-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GitftaidCheckoutCardComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: GitftaidCheckoutCardComponent,
    },
  ],
})
export class GitftaidCheckoutCardComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() environment;
  @Input() imageSrc: string;
  @Input() title: string;
  @Input() amount: number;
  @Input() giftAidPercent: number;
  @Input() currency: string;
  @Input()
  get options(): Options {
    return this._options;
  }

  set options(option: Options) {
    if (option) {
      this._options = option;
    }
  }

  private _options: Options;

  onChange = (is_giftaid) => {};
  onTouched = () => {};

  writeValue(is_giftaid: boolean) {
    this.is_giftaid = is_giftaid;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const is_giftaid = control.value;
    return is_giftaid;
  }

  get is_giftaid(): boolean {
    return this._is_giftaid;
  }

  set is_giftaid(value: boolean) {
    this._is_giftaid = value;
    this.onChange(this.is_giftaid);
  }

  private _is_giftaid: boolean;

  constructor() {}

  ngOnInit(): void {}

  get background(): string {
    return this.options.background;
  }

  get color(): string {
    return this.options.color;
  }

  get borderColor(): string {
    return this.options.borderColor;
  }

  get shadow(): string {
    return this.options.shadow;
  }
}
