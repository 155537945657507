import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveDirective } from './responsive.directive';

// export function playerFactory() {
//   return import('lottie-web');
// }

@NgModule({
  declarations: [ResponsiveDirective],
  imports: [CommonModule],
  exports: [ResponsiveDirective],
})
export class ResponsiveAssetsModule {}
