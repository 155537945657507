import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';

import { CampaignAddPhasesDialogComponent } from './campaign-add-phases-dialog.component';

@Component({
  selector: 'givebrite-campaign-add-phases',
  templateUrl: './campaign-add-phases.component.html',
  styleUrls: ['./campaign-add-phases.component.scss'],
})
export class CampaignAddPhasesComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit(): void {}

  add() {
    let dialog = this.dialog.open(CampaignAddPhasesDialogComponent);
    dialog.afterClosed().subscribe((form: FormGroup) => {
      if (form) {
        this.updated.emit();
        this._phases = form.value;
        // this.store.dispatch(
        //   new UpdateFormValue({
        //     value: form.value,
        //     path: 'campaign.form',
        //   })
        // );
      }
    });
  }

  set _phases(phase) {
    this._phases.setValue([...this.phases, phase]);
  }

  /**
   * Returns the impacts from the form
   */
  get _phases(): FormControl {
    return <FormControl>this.form.get('phases');
  }

  get currency() {
    return this.form.get('currency').value;
  }

  /**
   * Returns impacts as an array
   */
  get phases() {
    return this._phases.value;
  }
}
