<div class="campaign-cover mt-5">
  <!-- <div
    class="cursor-pointer relative"
    [class.uploaded]="files.length"
    [class.uploading]="uploading"
  >
    <h3 class="font-bold">Upload a cover Image for your {{ type }}</h3>
    <p>Image size should be 1240 x 720 pixels (16:9)</p>

    <ng-container *ngIf="files.length"> </ng-container>

    <div class="center mt-5">
      <div
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        fxLayout="column"
        fxLayoutAlign="center center"
        class="drop-zone relative"
      >
        Image preview with edit actions -->
  <!-- <ng-container *ngIf="activeFile?.type == 'image'">
          <img class="preview-image" [src]="activeFile.image?.cropped?.url" />
          <img
            *ngIf="!activeFile.image.cropped"
            class="preview-image requires-cropping"
            [src]="activeFile.image?.original?.url"
          />

          <div class="recrop" *ngIf="!activeFile.image.cropped">
            This image needs to be cropped properly, edit the image or delete
          </div>

          <div class="absolute top-3 right-3 actions">
            <button
              mat-raised-button
              color="warn"
              class="btn-xs mr-3"
              (click)="remove(activeFile)"
            >
              <mat-icon>remove</mat-icon> Delete
            </button>

            <button
              mat-raised-button
              color="primary"
              class="btn-xs"
              (click)="openCropper(activeFile)"
            >
              <mat-icon>crop</mat-icon> Edit
            </button>
          </div>
        </ng-container> -->

  <!-- Vimeo / Youtube Preview -->
  <!-- <ng-container *ngIf="activeFile?.type == 'video'">
          <youtube-player
            *ngIf="activeFile.video.type == 'youtube'"
            [videoId]="activeFile.video?.id"
            height="250px"
            width="540px"
          ></youtube-player>

          <iframe
            *ngIf="activeFile?.type == 'vimeo'"
            width="540px"
            height="250px"
            [src]="activeFile.url"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </ng-container> -->

  <!-- Drop Zone -->
  <!-- <div
          *ngIf="!files.length"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="1rem"
          class="h-full uploader"
        >
           <mat-icon class="material-icons-outlined">file_upload</mat-icon> -->
  <!-- <h4>
            <p>
              <button
                (click)="openMediaUpload()"
                mat-raised-button
                color="primary"
                class="btn-md"
              >
                CHOOSE COVER
              </button>
            </p>
          </h4> -->

  <!-- <p class="subtext">Supports: JPEG, JPG, PNG</p> -->
  <!-- </div>
      </div>
    </div>   -->

  <!-- Loops through the Selected/dragged images and display them for preview -->
  <!-- <div style="overflow: scroll">
      <div
        style="width: 540px"
        fxLayout="row"
        fxLayoutAlign="left center"
        *ngIf="carousel"
      >
        <div
          class="mt-5 preview relative"
          (click)="_activeFile = i"
          [class.activeFile]="activeFile.id == item.id"
          [ngStyle]="{ 'background-image': 'url(' + item.thumbnail + ')' }"
          *ngFor="let item of files; let i = index"
        >
           <img  class="mat-elevation-z3 preview-img" /> -->
  <!-- <button
            mat-raised-button
            color="warn"
            class="absolute bottom-1 right-1 btn-xs remove"
            (click)="remove(i)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div> -->

  <!-- <div *ngIf="file" class="mt-5 uploading preview relative">
          <div class="progress">
            <mat-spinner diameter="20"></mat-spinner>
            Uploading...
          </div>
          <img [src]="file.imagePreview" />
          <div class="overlay"></div> -->
  <!-- <img  class="mat-elevation-z3 preview-img" /> -->
  <!-- </div> -->

  <!-- <div
          class="add mt-5 preview"
          [class.show]="files.length <= 3 && !!!file"
          fxLayoutAlign="center center"
        >
          <button (click)="openMediaUpload()"><mat-icon>add</mat-icon></button>
        </div> -->

  <!-- <div
          class="add mt-5 preview show"
          style="background-color: #efefef"
          *ngFor="let item of [].constructor(3 - files.length); let i = index"
        ></div>
      </div>
    </div>
  </div> -->

  <!-- {{ files | json }} -->
</div>
