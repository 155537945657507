import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import icEdit from '@iconify/icons-ic/baseline-edit';
import icCancel from '@iconify/icons-ic/outline-cancel';
import icDefault from '@iconify/icons-ic/star';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import { Store } from '@ngxs/store';
import { MarkAddressAsDefault, UserAddress } from 'libs/data/src/lib/ramadhan-giving';
import { AddEditAddressDialogComponent } from '../../dialogs/add-edit-address-dialog/add-edit-address-dialog.component';
import { DeleteAddressDialogComponent } from '../../dialogs/delete-address-dialog/delete-address-dialog.component';
import { AddressListOptions } from '../address-list.component';

@Component({
  selector: 'address-list-item',
  templateUrl: './address-list-item.component.html',
  styleUrls: ['./address-list-item.component.scss'],
})
export class AddressListItemComponent implements OnInit {
  @Input() address: UserAddress;
  @Input() options: AddressListOptions;
  @Input() selected: string;
  @Output() change: EventEmitter<UserAddress> = new EventEmitter();

  icEdit = icEdit;
  icMoreVert = icMoreVert;
  icCancel = icCancel;
  icDefault = icDefault;

  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit() {}

  addressSelected(address: UserAddress) {
    this.change.emit(address);
  }

  addAddress() {
    this.dialog.open(AddEditAddressDialogComponent, { width: '600px' });
  }

  editAddress(address) {
    this.dialog.open(AddEditAddressDialogComponent, { data: address });
  }

  deleteAddress(address: UserAddress) {
    this.dialog.open(DeleteAddressDialogComponent, { width: '400px', data: address });
  }

  defaultAddress(address: UserAddress) {
    this.store
      .dispatch(new MarkAddressAsDefault({ _id: address._id, is_default: 1 }))
      .subscribe((response) => {
        this.addressSelected(address);
      });
  }
}
