import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GetTeamLeaderboard } from 'libs/data/src/lib/campaign/store/team.actions';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Leaderboard } from 'libs/data/src/lib/campaign/team.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-team-leaderboard',
  templateUrl: './team-leaderboard.component.html',
  styleUrls: ['./team-leaderboard.component.scss'],
})
export class TeamLeaderboardComponent implements OnInit {
  @Input() id: string;

  leaderboards = [
    { id: '1', name: 'Week winner' },
    { id: '2', name: 'Most raised' },
    { id: '3', name: 'Most donors' },
    { id: '4', name: 'Most activity' },
  ];

  @Select(TeamState.teamLeaderboard) teamLeaderboards$: Observable<Leaderboard[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new GetTeamLeaderboard(this.id));
  }
}
