import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

enum Fields {
  _id = "_id",
  price = "price",
  share_price = "share_price",
  animal = "animal",
  country = "country",
  stock = "stock",
  sold = "sold"
}

export interface QurbaniInventoryItemField {
  label: string;
  name: string;
  property?: string;
  default?: boolean;
  type?: string;
}

export interface QurbaniInventoryItemFieldsGroup {
  name: string;
  columns: QurbaniInventoryItemField[];
}

const ONE_OFF = "one_off";

const FIELD_GROUPS = {
  [ONE_OFF]: "One off donations"
};

const fields = {
  name: "Inventory",
  columns: [
    { label: "Stock ID", name: Fields._id, property: "_id", default: false, type: "text" },
    { label: "Price", name: Fields.price, property: "price", default: true, type: "amount" },
    {
      label: "Country",
      name: Fields.country,
      property: "country",
      default: true,
      type: "countries"
    },
    { label: "Animal", name: Fields.animal, property: "animal.name", default: true, type: "text" },
    { label: "Stock", name: Fields.stock, property: "stock.total", default: true, type: "number" },
    { label: "Sold", name: Fields.sold, property: "stock.sold", default: true, type: "number" }
  ]
};

const FIELDS: { [key: string]: QurbaniInventoryItemFieldsGroup[] } = {
  [ONE_OFF]: [fields]
};

const ALL_ONE_OFF_FIELDS = FIELDS[ONE_OFF].reduce(
  (result, category) => result.concat(category.columns),
  []
);

@Injectable({
  providedIn: "root"
})
export class QurbaniFields {
  public fields$;

  getFields(FIELD_GROUP = ONE_OFF): QurbaniInventoryItemFieldsGroup[] {
    return FIELDS[FIELD_GROUP];
  }

  getSelectedFields(): QurbaniInventoryItemField[] {
    return this.fields$;
  }

  getDefaultFields() {
    return (this.fields$ = ALL_ONE_OFF_FIELDS.filter(c => {
      return c.default;
    }));
  }

  getAllFields(FIELD_GROUPS): QurbaniInventoryItemFieldsGroup[] {
    return FIELDS[FIELD_GROUPS].reduce((result, category) => result.concat(category.columns), []);
  }

  // setFields(fields: string[]) {
  //   this.fields$ = fields.map(property => {
  //     return this.getItemByColumn(property);
  //   });
  // }

  // getItemByColumn(name: string): DonationField {
  //   return ALL_ONE_OFF_FIELDS.find(i => {
  //     return i.name === name;
  //   });
  // }

  // getCategoryById(name: string): DonationFieldsGroup {
  //   return ALL_GROUPS.find(c => c.name == name);
  // }

  getDefaultColumns(): QurbaniInventoryItemField[] {
    return ALL_ONE_OFF_FIELDS.filter(c => c.default);
  }
}
