import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

export type Options = {
  amount: number;
  active: string;
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
};

@Component({
  selector: 'givebrite-progress-bar',
  templateUrl: './gb-progress-bar.component.html',
  styleUrls: ['./gb-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GbProgressBarComponent implements OnInit {
  mode: ProgressBarMode = 'determinate';

  @Input() currency: string;
  @Input() raised: number;
  @Input() goal: number;
  @Input() height: number;

  @Input()
  get options(): Options {
    return this._options;
  }

  set options(options: Options) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  _options: Options = {
    amount: 0,
    active: '',
    topLeft: '',
    topRight: '',
    bottomLeft: '',
    bottomRight: '',
  };

  constructor() {}

  ngOnInit(): void {}

  get percentage() {
    return (this.raised / this.goal) * 100;
  }

  get amount() {
    return this.options.amount;
  }

  get active() {
    return this.options.active;
  }

  get topLeft() {
    return this.options.topLeft;
  }

  get topRight() {
    return this.options.topRight;
  }

  get bottomLeft() {
    return this.options.bottomLeft;
  }

  get bottomRight() {
    return this.options.bottomRight;
  }
}
