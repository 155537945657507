import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddEditAddressComponent } from "./add-edit-address.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CountryListModule } from "../../country-list/country-list.module";

@NgModule({
  declarations: [AddEditAddressComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CountryListModule,
    MatSnackBarModule
  ],
  exports: [AddEditAddressComponent]
})
export class AddEditAddressModule {}
