import { MatDividerModule } from '@angular/material/divider';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipDialogComponent } from './tip-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TipSelectorModule } from '../tip-selector/tip-selector.module';
import { CloseIconModule } from '../../../utils';

@NgModule({
  declarations: [TipDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    CloseIconModule,
    MatDividerModule,
    FlexLayoutModule,
    TipSelectorModule,
  ],
  exports: [TipDialogComponent],
})
export class TipDialogModule {}
