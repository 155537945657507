<div class="mb-2 amounts" [class.primary]="isMobile" fxLayout="row" *ngIf="top">
  <div class="raised">
    <span>{{ raised | currency: currency:'symbol' }} Raised</span>
  </div>
  <div fxFlex></div>
  <div class="goal">{{ goal | currency: currency:'symbol' }}</div>
</div>

<div class="givebrite-progress-bar">
  <mat-progress-bar
    [mode]="mode"
    [value]="(raised / target) * 100"
    class="card-progress-bar"
    [class.fat]="fat"
  >
  </mat-progress-bar>
</div>

<div class="mt-1" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="bottom">
  <div class="title">{{ raised }} Givers</div>
  <div class="title">{{ target }} Givers</div>
</div>
