import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';

import { AddPagination } from './pagination.actions';

/** default state */
export const defaultPaginationState = (): PaginationStateModel => {
  return {
    limit: null,
    total: null,
    page: null,
    pages: null,
  };
};
export interface PaginationStateModel {
  limit: number;
  total: number;
  page: number;
  pages: number;
}

const paginationStateDefaults: PaginationStateModel = {
  limit: null,
  total: null,
  page: null,
  pages: null,
};

@State<PaginationStateModel>({
  name: 'pagination',
  defaults: paginationStateDefaults,
})
@Injectable()
export class PaginationState {
  constructor() {}

  @Selector()
  static pagination(state: PaginationStateModel) {
    return state;
  }

  @Action(AddPagination)
  addPagination(
    { patchState }: StateContext<PaginationStateModel>,
    { pagination }
  ) {
    patchState(pagination);
  }
}
