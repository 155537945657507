<div class="invite-details py-20 md:py-0">
  <h2 class="text-center">{{ name | titlecase }}</h2>
  <h4 class="title text-center">
    {{ fundraisers }} Members |
    {{ raised | currency: currency:'symbol':'1.0-0' }} Raised
    <!--  in
    {{ endDate }} days -->
  </h4>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="ml-0 mt-10 mb-2 w-full"
    fxLayoutGap="20px"
  >
    <button mat-raised-button color="primary" fxFlex (click)="joinTeam(slug)">
      Join Team
    </button>

    <button mat-stroked-button (click)="teamPage(slug)" color="primary" fxFlex>
      Team profile
    </button>
  </div>

  <!-- <div fxLayout="column" fxLayoutAlign="start center" class="text-center mt-10">
    <p class="mb-2">Invited by</p>

    <div fxLayout="row" fxLayoutAlign="start center">
      <givebrite-user-logo-avatar
        [logo]="profileImage"
        [name]="firstName + ' ' + lastName"
        [border]="true"
        width="30px"
      ></givebrite-user-logo-avatar>

      <h4 class="ml-2 sub-title">
        {{ firstName + ' ' + lastName | titlecase }}
      </h4>
    </div>
  </div> -->
</div>
