import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import { CharityState } from 'libs/data/src/lib/charity/store/charity.state';
import { Observable } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'givebrite-tip-selector',
  templateUrl: './tip-selector.component.html',
  styleUrls: ['./tip-selector.component.scss'],
})
export class TipSelectorComponent implements OnInit {
  @Select(CharityState.getCharity) charity$: Observable<Charity>;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() currency;
  @Input() form: FormGroup;

  tipPercentageValues = [8, 4, 2];
  otherTipAmount: boolean;

  constructor() {}

  ngOnInit(): void {}

  /**
   * @description
   * SET TIP
   * @param percentage
   */
  setTipPercentage(percentage: number) {
    this.form.get('fees').patchValue({
      amount: (percentage / 100) * this.amount,
      fee_percentage: percentage,
      tip: (percentage / 100) * this.amount,
      is_fee_covered: percentage > 0 ? true : false,
    });
    this.change.emit();
  }

  /**
   * @description
   * SET TIP WHEN OTHER IS SELECTED
   * @param amount
   */
  setOtherTip(amount) {
    this.form.get('fees').patchValue(
      {
        // amount: +feeAmount,
        fee_percentage: (amount / this.amount) * 100,
        tip: amount,
        is_fee_covered: amount > 0 ? true : false,
      },
      { emitEvent: false }
    );
  }

  get feesForm() {
    return this.form.get('fees');
  }

  get fees() {
    return this.feesForm.value;
  }

  get selectedFees() {
    return this.form.get('fees').get('amount').value;
  }

  get amount() {
    return this.form.get('amount').value;
  }

  get tipAmount() {
    return this.fees.amount;
  }

  get selectedPercentage() {
    return this.fees.fee_percentage;
  }
}
