<mat-list role="list" class="addresses">
  <mat-radio-group [value]="selected" aria-label="Select an address">
    <mat-list-item *ngIf="!(addresses$ | async)?.length" role="listitem" class="add-new-card last">
      <span>You do not have any saved addresses.</span>
    </mat-list-item>

    <mat-list-item role="listitem" class="add-new-address last mb-3" *ngIf="options.addAddress">
      <a (click)="addAddress()" mat-stroked-button color="primary" class="w-full border uppercase py-2 px-9"
        ><mat-icon [icIcon]="icAdd" size="18px"></mat-icon><span class="font-bold">Add new address</span></a
      >
    </mat-list-item>

    <address-list-item
      [selected]="selected"
      role="listitem"
      *ngFor="let address of addresses$ | async; let last = last"
      [options]="options"
      (change)="addressSelected($event)"
      [address]="address"
    ></address-list-item>
  </mat-radio-group>
</mat-list>
<!-- 
<mat-form-field class="w-full" appearance="legacy">
  <mat-label>Address</mat-label>
  <mat-select>
    <mat-option *ngFor="let address of addresses$ | async; let last = last" [value]="address.id">
      {{ address.address_line_1 }}<br />
      {{ address.town }} {{ address.postcode }}, {{ address.country }}
    </mat-option>
  </mat-select>
</mat-form-field> -->
