import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
// import { Donation } from '../../../../models/src/lib/donation/donation.model'
// import { Pagination } from '../../../../models/src/lib/donation/pagination.model'
// import { SubscriptionService } from '../../../../services/src/lib/subscription/subscription.service'
import { map, tap, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Pagination } from '@givebrite/data';
import { QurbaniState } from './store/qurbani.state';
import { QurbaniInventoryItem } from './qurbani.interface';
import { GetQurbaniInventory } from './store/qurbani.action';

@Injectable({
  providedIn: 'root',
})
export class QurbaniDataSource implements DataSource<QurbaniInventoryItem> {
  @Select(QurbaniState.getInventory) inventory$: Observable<QurbaniInventoryItem[]>;
  @Select(QurbaniState.getPagination) pagination$: Observable<Pagination>;
  @Select(QurbaniState.loading) loading$: Observable<boolean>;

  charityId: string;
  pageSize: number = 10;
  pageIndex: number = 1;
  filter = { status: 'active' };
  sortDirection: string = '';
  sortColumn: string = '';
  download: number = 0;

  constructor(private store: Store) {}

  connect(collectionViewer: CollectionViewer): Observable<QurbaniInventoryItem[]> {
    return this.inventory$;
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  list() {
    this.store.dispatch(new GetQurbaniInventory(this.charityId));

    // , this.pageSize, this.pageIndex, JSON.stringify(this.filter), this.sortDirection, this.sortColumn, this.download));
  }

  paginate() {
    this.list();
  }
}
