import { Component, OnInit, Input } from '@angular/core';
import counterclockwiseArrowsButton from '@iconify/icons-emojione-monotone/counterclockwise-arrows-button';
import icPayment from '@iconify/icons-ic/baseline-payment';
import { DonationRg, Subscription } from 'libs/data/src/lib/ramadhan-giving';
import { Observable } from 'rxjs';

@Component({
  selector: 'view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
})
export class ViewHeaderComponent implements OnInit {
  counterclockwiseArrowsButton = counterclockwiseArrowsButton;
  icPayment = icPayment;

  constructor() {}
  @Input() subscription: Subscription;
  @Input() donation: DonationRg;
  @Input() loading: Observable<boolean>;

  ngOnInit() {}

  get status() {
    return this.subscription.status == 'canceled'
      ? 'bg-red-500 text-white'
      : 'bg-teal-200 text-teal-800';
  }

  get donationStatus() {
    return this.donation.status == 3 ? 'bg-red-500 text-white' : 'bg-teal-200 text-teal-800';
  }
}
