import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { LottieDialogComponent } from '../donate/lottie-dialog/lottie-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private dialog: MatDialog) {}

  /** LOTTIE DIALOG
   * DATA - { LOADER: 'LOADER BOOLEAN', PATH: 'PATH OF THE ANIMATION'}
   */
  lottieDialog(loader, path) {
    this.dialog.open(LottieDialogComponent, {
      data: { loader, path },
      disableClose: true,
      panelClass: 'lottie-dialog',
    });
  }
}
