import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-campaign-payment',
  templateUrl: './campaign-payment.component.html',
  styleUrls: ['./campaign-payment.component.scss']
})
export class CampaignPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
