import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

export interface FrequencySecondLabel {
  name: string;
  value: string;
}

@Component({
  selector: 'givebrite-frequency-selector',
  templateUrl: './frequency-selector.component.html',
  styleUrls: ['./frequency-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FrequencySelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FrequencySelectorComponent,
    },
  ],
})
export class FrequencySelectorComponent implements ControlValueAccessor, Validator, OnInit {
  onChange = (frequency: string) => {};
  onTouched = () => {};

  writeValue(frequency: string) {
    this.frequency = frequency;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const frequency = control.value;
    if (frequency === '') {
      return {
        mustNotBeEmpty: {
          frequency,
        },
      };
    }
  }

  get frequency() {
    return this._frequency;
  }

  set frequency(value: string) {
    this._frequency = value;
    this.onChange(this.frequency);
  }

  private _frequency: string;

  constructor() {}

  ngOnInit(): void {}
}
