<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxFlex="49"
  fxFlex.gt-xs="20"
  class="mb-4 sm:mb-8"
  [class.carousel]="view === 'carousel-view'"
>
  <givebrite-user-logo-avatar
    [logo]="fundraiser?.user?.profile_image"
    [name]="fundraiser?.user?.first_name + ' ' + fundraiser?.user?.last_name"
    [border]="true"
    width="86px"
  ></givebrite-user-logo-avatar>

  <div class="mt-4">
    <h3 class="font-semibold cursor-pointer" (click)="fundraiserPage(fundraiser.slug)">
      {{ fundraiser?.user?.first_name + ' ' + fundraiser?.user?.last_name }}
    </h3>
    <h4 class="text-center mb-1">
      {{ fundraiser?.statistics?.raised | currency: fundraiser.currency:'symbol':'1.0-1' }}
    </h4>
    <p class="text-center">{{ fundraiser?.statistics?.donors }} supporters</p>
  </div>
</div>
