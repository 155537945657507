import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignListComponent } from './campaign-list.component';
// import { DonationTableModule } from "../../donation-table/donation-table.module";

@NgModule({
  declarations: [CampaignListComponent],
  imports: [
    CommonModule,
    //  DonationTableModule
  ],
})
export class CampaignListModule {}
