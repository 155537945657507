import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logout } from '@givebrite/data';
import { Store } from '@ngxs/store';
import { AuthService } from 'libs/data/src/lib/user/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { TenantService } from './tenant.service';
// import Rollbar from "rollbar";

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    //   @Inject("environment")
    // private env, private injector: Injector,
    private jwt: JwtService,
    private auth: AuthService,
    private tenant: TenantService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute // @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.jwt ? this.auth.jwt : null;

    if (token) {
      request = request.clone({
        headers: request.headers
          // .set('Content-Type', 'multipart/form-data')
          .set('Authorization', 'Bearer ' + token),
        // .set('Accept', 'application/json'),
        // params: request.params.set("app", this.app_id)
      });
    } else {
      request = request.clone({
        headers: request.headers,
        // .set("Content-Type", "application/json")
        // .set('Accept', 'application/json'),
      });
    }

    if (this.tenant.getTenant()) {
        request = request.clone({
          headers: request.headers.append('X-Tenant-ID', this.tenant.getTenant()),
        });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // const rollbar = this.injector.get(RollbarService);
        // this.store.dispatch(new Logout())
        let errorMessage = '';

        if (error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            this.store.dispatch(new Logout()).subscribe(() => {
              //   // console.log('redirecting')
              //   // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              //   // this.router.onSameUrlNavigation = 'reload';
              //   // this.router.navigateByUrl(this.router.url).then(a => {
              //   // console.log('redirected')
              //    // gets current route
              //   // }, error => {
              //   //   console.log('error')
              //   // })
              //   // let redirect = this.route.snapshot['_routerState'].url;
              //   // this.rollbar.error('An unauthorized server response was intercepted', {url: redirect, ...request})
              //   // this.router.navigate(['user','login'], {queryParams:{'redirectURL': redirect}});
              this.router.navigate(['/'], {
                queryParams: { error: '401' },
                queryParamsHandling: 'merge',
              });
            });
          } else {
            // this.rollbar.error('Serve response error', request);
            // this.toastr.error(error.error.error);
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }

        // if (error.error) {
        //   // client-side error
        // console.log(error);

        //   errorMessage = `Error: ${error.error.errors}`;
        // }
        // else {
        //   // server-side error

        // }
        console.log(errorMessage);
        //rollbar.error(errorMessage)
        return throwError(error);
      })
    );
  }
}
