import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { UserActionToolbarComponent } from './user-action-toolbar.component';
import { OfflineDonationDialogModule } from '../dialogs/offline-donation-dialog/offline-donation-dialog.module';
import { ContainerModule } from 'libs/theming/src/lib/directives/container/container.module';

@NgModule({
  declarations: [UserActionToolbarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    ContainerModule,
    RouterModule,
    OfflineDonationDialogModule,
  ],
  exports: [UserActionToolbarComponent],
})
export class UserActionToolbarModule {}
