import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { UserService } from '../user.service';
import { AuthState } from './auth.state';
import { Populate } from './user.actions';
import { User } from '../user.interface';
import { Logout } from './auth.actions';
import { Charity } from '../../charity';
import { Auth } from './auth.interface';

export interface UserStateModel {
  loading: boolean;
  user: User;
  charity?: Charity;
  auth: Auth,
}

const userStateDefaults: UserStateModel = {
  loading: false,
  user: null,
  charity: null,
  auth: null
};

@State({
  name: 'account',
  children: [AuthState],
  defaults: userStateDefaults,
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  static user(state: UserStateModel) {
    return state.user;
  }

  @Selector()
  static charity(state: UserStateModel) {
    return state.user ? state.user.charity : null;
  }

  @Selector()
  static auth(state: UserStateModel) {
    return state.user ? state.user.auth : null;
  }

  @Action(Populate)
  populate({ patchState }: StateContext<UserStateModel>) {
    const user = this.userService.userFromJwt;
    if (user) {
      patchState({
        user: user,
        charity: user.charity,
        auth: user.auth
      });
    }
    // this.userService.populate().subscribe((decoded) => {
    //   console.log(decoded);
    //   patchState({
    //     user: decoded,
    //   });
    // });
    // let user  =
  }

  @Action(Logout)
  logout({ patchState }: StateContext<UserStateModel>) {
    patchState({
      user: null,
      charity: null,
      auth: null
    });
  }
}
