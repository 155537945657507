<!-- NOTE:
     AMOUNT COMPONENT CAN BE USED IN 2 FORMATS, WITH SELECTORS:
     A) WITH CURRENCY & INPUT, (SELECTOR: <givebrite-amount></givebrite-amount>)
     B) WITHOUT CURRENCY I.E ONLY INPUT (SELECTOR: <givebrite-amount hide-currency></givebrite-amount>)
-->

<mat-form-field appearance="outline" class="w-full amount-form">
  <mat-label class="spacing" *ngIf="label">{{ label }}</mat-label>
  <div matPrefix class="amount" id="currency">
    {{ (config$ | async).currency | currencySymbol }}
    <span> {{ (config$ | async).currency }}</span>
  </div>
  <input
    matInput
    placeholder="0.00"
    type="number"
    step="0.01"
    [(ngModel)]="amount"
    class="spacing"
    [style.fontSize]="font"
  />
</mat-form-field>
