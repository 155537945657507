import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import { Team } from 'libs/data/src/lib/campaign/team.interface';

@Component({
  selector: 'givebrite-choose-custom-impacts',
  templateUrl: './choose-custom-impacts.component.html',
  styleUrls: ['./choose-custom-impacts.component.scss'],
})
export class ChooseCustomImpactsComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() currency: string;
  @Input() fundraiser: Fundraiser;
  @Input() charity: Charity;
  @Input() team: Team;
  @Input() environment;

  selectedImpact = 0;

  form: FormGroup = this.fb.group({
    impact: [],
  });

  @Input()
  set impacts(impacts) {
    if (impacts?.length) {
      this.selectedImpact = 0;
      this._impacts = impacts;
      this.form.get('impact').setValue(impacts[0]);
    }
  }

  get impacts() {
    return this._impacts;
  }

  private _impacts;

  constructor(private dialog: MatDialog, private fb: FormBuilder) {}

  ngOnInit(): void {}

  /**
   * @description
   * IMPACT CHANGE
   * @param impact
   * @param index
   */
  onImpactChange(impact, index: number) {
    this.selectedImpact = index;
    this.form.get('impact').setValue(impact);
  }

  /**
   * @description
   * OPEN SHARE DIALOG
   */
  openShareDialog() {
    this.dialog.open(ShareDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      data: {
        environment: this.environment,
      },
    });
  }

  get impact() {
    return this.form.get('impact').value;
  }
}
