<div class="text-center add-impacts" fxLayout="column" fxLayoutGap="15px">
  <div
    fxFlex="62px"
    fxLayout="row"
    fxLayoutAlign=" center"
    class="impact mat-elevation-z2 text-center px-4"
    *ngFor="let impact of impacts; let index = index"
  >
    <p fxFlex title class="font-semibold text-left">{{ impact.name | truncate: 5 }}</p>
    <p fxFlex subtitle class="text-left">{{ impact.amount | currency: currency:'symbol' }}</p>
    <button
      mat-stroked-button
      color="primary"
      class="btn-xs w-16"
      (click)="edit(impact, index)"
    >
      Edit
    </button>
  </div>

  <!-- <campaign-add-section-placeholder fxFlex="80px">
    <div campaign-add-actions>
      <button
        (click)="add()"
        class="btn-sm w-full"
        mat-raised-button
        color="primary"
      >
        <mat-icon>add</mat-icon> Add
      </button>
    </div>
  </campaign-add-section-placeholder> -->

  <div class="impact mat-elevation-z2 px-4 skeleton-loader">
    <ngx-skeleton-loader
      [animation]="false"
      [theme]="{
        width: '80%',
        height: '24px',
        display: 'block',
        'margin-bottom': '0'
      }"
    ></ngx-skeleton-loader>

    <div class="text-right">
      <button
        mat-stroked-button
        color="primary"
        (click)="add()"
        class="leading-6"
      >
        Add Impact
      </button>
    </div>
  </div>
</div>
