<div
  class="checkout-cart"
  *ngIf="{
    cartItems: cartItems,
    loader: loader$ | async
  } as cart"
>
  <!-- CART ITEM -->
  <div *ngIf="cart?.cartItems?.length">
    <mat-card
      appearance="outlined"
      *ngFor="let cartItem of cart?.cartItems"
      class="cursor-pointer"
      fxLayout="column"
      fxLayoutAlign="center"
      [style.boxShadow]="shadow"
      [class.singleItem]="cart?.cartItems.length == 1"
    >
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start">
          <h3 class="capitalize mb-1 font-medium">{{ cartItem.appeal?.name }}</h3>
          <p class="mb-0 font-normal">{{ cartItem?.donation_type }}</p>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="pt-2">
            <h4 class="mb-0 amount font-medium" fxLayout="row" fxLayoutAlign="start center">
              <!-- {{
                cartItem.amount * cartItem.qty
                  | currency: (config$ | async).currency:'symbol':'1.0-0'
              }} -->
              {{ cartItem.amount | currency: (config$ | async).currency:'symbol':'1.2-2' }}
            </h4>

            <!-- FIXED PRICE -->
            <div
              fxLayout="row"
              fxLayoutAlign="center"
              *ngIf="cartItem.price_type === 'fixedPrice'; else openPrice"
            >
              <mat-icon
                color="primary"
                class="remove-icon"
                *ngIf="cartItem.qty > 1"
                (click)="decreaseQuantity(cartItem)"
                >remove</mat-icon
              >

              <button
                mat-raised-button
                color="primary"
                class="font-normal"
                (click)="deleteFromCart(cartItem)"
                *ngIf="cartItem.qty === 1"
              >
                <!-- <mat-icon class="deleteIcon">delete</mat-icon> -->
                Remove
              </button>

              <h4 class="text-center font-medium mb-0 item-qty">
                {{ cartItem.qty }}
              </h4>

              <mat-icon color="primary" (click)="increaseQuantity(cartItem)">add</mat-icon>
            </div>

            <!-- OPEN PRICE -->
            <ng-template #openPrice>
              <div fxLayout="row" fxLayoutAlign="center">
                <button
                  mat-raised-button
                  color="primary"
                  class="font-normal remove-open-price"
                  (click)="deleteFromCart(cartItem)"
                >
                  Remove
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- LOADER -->
  <!-- <div *ngIf="cart.loader">
    <givebrite-lottie-loader [environment]="environment"></givebrite-lottie-loader>
  </div> -->

  <!-- <ng-container>
    <mat-card
      *ngFor="let cartItem of [].constructor(3)"
      fxLayout="column"
      fxLayoutAlign="center"
      [style.boxShadow]="shadow"
    >
      <mat-card-content fxLayout="column">
        <ngx-skeleton-loader
          [theme]="{ width: '70%', 'margin-bottom': '0px' }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ width: '40%' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="w-full"
          [theme]="{ width: '100%', 'margin-bottom': '0px' }"
        ></ngx-skeleton-loader>
      </mat-card-content>
    </mat-card>
  </ng-container> -->

  <!-- NOT FOUND -->
  <h4 *ngIf="!cart.loader && !cart?.cartItems?.length" class="text-center capitalize mt-16 empty">
    Your cart is empty, <a (click)="goBack()" class="underline cursor-pointer">Add items</a>
  </h4>
</div>
