import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: "root"
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: CanComponentDeactivate) {
    return true;
    console.log(component, this.dialog.openDialogs);
    // return component.canDeactivate ? component.canDeactivate() : true;
    if (this.dialog.openDialogs.length) {
      this.dialog.closeAll();
      return false;
    } else {
      return true;
    }
  }
}
