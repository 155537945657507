import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignTypeRoutingModule } from './campaign-type-routing.module';
import { CampaignTypeComponent } from './campaign-type.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { CampaignInspirationModule } from '../campaign-inspiration/campaign-inspiration.module';
import { CampaignSelectorModule } from '../campaign-selector/campaign-selector.module';
import { TickMarkModule } from '../../tick-mark/tick-mark.module';

@NgModule({
  declarations: [CampaignTypeComponent],
  imports: [
    CommonModule,
    CampaignTypeRoutingModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    TickMarkModule,
    CampaignInspirationModule,
    CampaignSelectorModule,
  ],
  exports: [CampaignTypeComponent],
})
export class CampaignTypeModule {}
