import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddImpactsComponent } from './campaign-add-impacts.component';
import { CampaignAddImpactsDialogModule } from './campaign-add-impacts-dialog.module';
import { CampaignAddSectionPlaceholderModule } from './../campaign-add-section-placeholder/campaign-add-section-placeholder.module';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [CampaignAddImpactsComponent],
  imports: [
    CommonModule,
    CampaignAddSectionPlaceholderModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    CampaignAddImpactsDialogModule,
    MatDialogModule,
    FlexLayoutModule,
    TruncateStringModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [CampaignAddImpactsComponent],
})
export class CampaignAddImpactsModule {}
