<div class="quick-action-mobile p-4" [class.dontshow]="!showToolbar" [class.show]="showToolbar">
  <div class="quick-action-container sm:container">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" class="quick-action-body">
      <button fxFlex mat-stroked-button color="primary" routerLink="{{ editLink }}">
        Edit my {{ page }}
      </button>

      <a fxFlex mat-raised-button color="primary" class="secondary" (click)="addOfflineDonation()">
        Add Offline Donation
      </a>
    </div>
  </div>
</div>
