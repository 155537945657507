import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'campaign-add-sponsors-dialod',
  templateUrl: './campaign-add-sponsors-dialog.component.html',
  styleUrls: ['./campaign-add-sponsors.component.scss'],
})
export class CampaignAddSponsorsDialogComponent implements OnInit {
  form: FormGroup = this.fb.group({
    name: [],
    amount: [],
  });
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CampaignAddSponsorsDialogComponent>
  ) {}

  ngOnInit(): void {}

  add() {
    if (this.form.valid) {
      this.dialogRef.close(this.form);
    }
  }
}
