import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@givebrite/data';
import facebookIcon from '@iconify/icons-fa-brands/facebook';
import googleIcon from '@iconify/icons-flat-color-icons/google';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Select, Store } from '@ngxs/store';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import {
  LoginTokenRg,
  SocialLoginRg,
  SocialUserRg,
  UserRg,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { fadeInUp400ms } from 'libs/theming/src/lib/animations/fade-in-up.animation';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'user-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  @Select(UserStateRg.error) stateError$: Observable<any>;
  @Output() authenticated: EventEmitter<UserRg> = new EventEmitter();
  @Output() forgot: EventEmitter<void> = new EventEmitter();
  @Input() dialog: boolean;
  @Input() social = true;
  @Input() register = true;
  @ViewChild('email', { static: true }) emailInput: ElementRef;
  @Input() set email(email) {
    if (email) {
      this.form.get('email').setValue(email);
    }
  }

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  submitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  error$: BehaviorSubject<string> = new BehaviorSubject('');

  inputType = 'password';
  visible = false;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  googleIcon = googleIcon;
  facebookIcon = facebookIcon;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private authService: SocialAuthService,
    private auth: AuthService
  ) {
    if (this.auth.authenticated) {
      this.router.navigate(['/'], {
        queryParams: { return: this.router.url },
      });
    }
  }

  ngOnInit() {
    // this.stateError$.pipe(
    // filter((data) => data != null))
    // .subscribe((error) => {
    //   this.snackbar.open(error, "OK", {
    //      duration: 10000
    //    });
    // })
  }

  send() {
    this.store
      .dispatch(new LoginTokenRg(this.form.value))
      .pipe(untilDestroyed(this))
      .subscribe(
        (user) => {
          this.router.navigate(['/user/login-confirmation'], {
            queryParams: { email: this.form.get('email').value },
          });

          // this.userLoggedIn(user);
        },
        (error) => {
          this.snackbar.open(error, 'OK', {
            duration: 10000,
          });
        }
      );
  }

  userLoggedIn(state) {
    // Check if authenticate @Ouput is being subscribed in which case defer login handling to parent component
    if (this.authenticated.observers.length) {
      this.authenticated.emit(state);
    } else {
      const params = this.route.snapshot.queryParams;
      if (params.redirectURL) {
        this.router.navigateByUrl(params.redirectURL);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.socialRegisterApi(user);
    });
  }
  signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.socialRegisterApi(user);
    });
  }

  socialRegisterApi(user: any) {
    const payload: SocialUserRg = {
      first_name: user.firstName,
      last_name: user.lastName,
      provider_id: user.id,
      profile_image: user.photoUrl,
      email: user.email,
      provider: user.provider,
    };
    this.store.dispatch(new SocialLoginRg(payload));
  }
  socialAuthenticated(user) {
    this.authenticated.emit(user);
  }

  ngOnDestroy() {}

  // forgotPassword() {
  //   this.forgot.emit(this.form.get("email").value);
  //   if(!this.dialog){
  //     this.router.navigate(["/user/forgot"]);
  //   }
  // }

  setError(error) {
    console.log(error);
  }
}
