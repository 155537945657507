import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RollbarService } from '@givebrite/api';
import { Actions, Select, Store } from '@ngxs/store';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import {
  CharityStateRg,
  LocationState,
  SaveSubscriptionState,
  SubscriptionState,
  UserAddress,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Rollbar from 'rollbar';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { AllValidationErrors, getFormValidationErrors } from '@givebrite/theming';

@UntilDestroy()
@Component({
  selector: 'add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.getSetupIntent) intent$: Observable<string>;
  @Select(SubscriptionState.getSubscription) subscription$: Observable<any>;
  @Select(CharityStateRg.getCharity) charity$: Observable<any>;
  // @ViewChild(StripeCardComponent) cardElement: StripeCardComponent;
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  location = this.store.selectSnapshot(LocationState.country);
  titles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'];

  billing = this.fb.group({
    name: [],
    building_name_number: ['', Validators.required],
    postcode: ['', Validators.required],
    country: [this.location.code],
    title: ['Mr', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    address_line_1: ['', Validators.required],
    town_or_city: ['', Validators.required],
    street_name: [],
    address_line_2: [],
    // mobile: ["", [Validators.required]]
  });

  loading$ = new BehaviorSubject(false);

  // @Select(CharityState.getCharity) charity$: Observable<Charity>;

  @Input() address: UserAddress;
  @Input() id: number;
  @Input() object: string = 'subscription' || 'donation';
  @Output() cancel = new EventEmitter();
  @Output() added = new EventEmitter();
  @Output() error: EventEmitter<PaymentError> = new EventEmitter();

  // element: StripeElement;
  // card: StripeElement;
  @Input() cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#fff',
        color: 'white',
        fontWeight: 500,
        fontFamily: 'Muli, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
  };

  stripe;
  appearance = 'legacy';
  // card: StripeCardComponent;
  error$: BehaviorSubject<PaymentError> = new BehaviorSubject(null);
  complete = false;
  intent;

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  subscription: any;
  stripeAccount;
  isBrowser: boolean;
  postAddress: any;
  predictions = new BehaviorSubject([]);
  postCodeloading$ = new BehaviorSubject(false);

  constructor(
    private _stripe: StripeService,
    @Inject('environment') private env,
    private store: Store,
    private snackbar: MatSnackBar,
    @Inject(RollbarService) private rollbar: Rollbar,
    private actions$: Actions,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddPaymentMethodComponent>,
    @Inject('environment') private environment,
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.subscription = data.subscription;
    //this._stripe.changeKey(this.env.stripe.pk);

    // this._stripe.setKey(this.env.stripe.pk);

    this.error$.pipe(untilDestroyed(this)).subscribe((error) => {
      this.error.emit(error);
    });

    this.subscription$.pipe(untilDestroyed(this)).subscribe((subscription) => {
      if (subscription) {
        this.subscription = subscription;
        this.billing.patchValue(subscription.billing, { emitEvent: false });
      }
    });

    this.charity$.pipe(untilDestroyed(this)).subscribe((charity) => {
      if (charity) {
        if (!charity.bypass_connect) {
          this.stripeAccount = charity.gateways?.stripe?.account_id;
          // REMOVED FOR NOW
          // this._stripe.setKey(env.stripe.pk, {
          //   stripeAccount: this.stripeAccount
          // });
        }
      }
    });
  }

  ngOnInit() {
    this.intent$.pipe(untilDestroyed(this)).subscribe((intent) => {
      this.intent = intent;
    });

    // this is a reference to the stripe elements object
    this.stripe = this._stripe.getInstance();

    this.billing
      .get('postcode')
      .valueChanges.pipe(untilDestroyed(this), debounceTime(200))
      .subscribe((postcode) => {
        this.lookupPostcode();
      });
  }

  onPay() {
    if (this.billing.valid) {
      this.loading$.next(true);

      // If card payment create payment method or else navigate to summay page
      this.createPaymentMethod().subscribe((result) => {
        if (result.paymentMethod) {
          // this.form
          //   .get('payment')
          //   .get('token')
          //   .setValue(result.paymentMethod);

          // console.log(this.subscription);
          // console.log({ ...this.subscription, billing: this.billing.value });

          this.store
            .dispatch(
              new SaveSubscriptionState({
                ...this.subscription,
                ...{ billing: this.billing.value },
              })
            )
            .pipe(untilDestroyed(this))
            .subscribe(
              () => {
                this.notify();
                this.added.emit(result.paymentMethod);
                // this.dialogRef.close(result.paymentMethod);
              },
              () => {
                this.error$.next({ message: 'There was an error saving your billing details' });
                this.loading$.next(false);
              }
            );

          // Confirm donation and navigate to next page
          // this.confirm();
        } else if (result.error) {
          // Error creating the token
          // this.loading$.next(false);
          this.error$.next({ message: result.error.message });
          this.loading$.next(false);

          // console.log(result.error);
        }
      });
    } else {
      this.loading$.next(false);
      this.findFormErrors();
    }

    // this.createToken().subscribe((result) => {
    //   if (result.token) {
    //     this.form.get('payment').get('token').setValue(result.token);
    //     // Confirm donation and navigate to next page
    //     this.confirm();
    //   } else if (result.error) {
    //     // Error creating the token
    //     this.loading$.next(false);

    //     // Need to handle error
    //   }
    // });
  }

  createPaymentMethod() {
    const billing = this.billing.value;

    return this._stripe.createPaymentMethod({
      type: 'card',
      card: this.card?.element,
      billing_details: {
        address: {
          line1: billing.building_name_number,
          postal_code: billing.postcode,
          country: billing.country,
        },
        email: billing.email,
      },
    });
  }

  cancelAdd() {
    this.cancel.emit();
    this.dialogRef.close();
  }

  /**
   * Set error message
   * @param error
   */
  setErrorMessage(error) {
    this.error$.next(error);
  }

  /**
   * Create setup intent and card token
   *
   * @returns
   * @memberof AddPaymentMethodComponent
   */
  // addPaymentMethod() {
  //   this.store.dispatch(new Loading(true));
  //   this.store.dispatch(new ResetErrors());

  //   // Check for existing intent
  //   if (this.intent) {
  //     this.createStripeToken();
  //     return;
  //   }

  //   // Create a new intent
  //   this.store
  //     .dispatch(
  //       new CreateSetupIntent({
  //         usage: "on_session",
  //         id: this.id,
  //         object: this.object
  //       })
  //     )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       store => {
  //         if (store.user.intent) {
  //           this.intent = store.user.intent;
  //           // Create a new card token
  //           this.createStripeToken();
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //         this.rollbar.error(error);
  //         this.error$.next(error);
  //       }
  //     );
  // }

  /**
   * Create a card token
   *
   * @memberof AddPaymentMethodComponent
   */
  // createStripeToken() {

  //   var el = this;
  //   this._stripe
  //     .createPaymentMethod({
  //       type: "card",
  //       card: this.card.element,
  //       billing_details: {
  //         name: this.address.first_name + " " + this.address.last_name,
  //         address: {
  //           line1: this.address.address_line_1,
  //           line2: `${this.address.address_line_2}`,
  //           city: this.address.town,
  //           state: "",
  //           postal_code: this.address.postcode,
  //           country: `${this.address.country}`
  //         }
  //       }
  //     })

  //     // .then(result => {

  //     //   if (result.paymentMethod) {
  //     //         this.capturePaymentMethod(result.paymentMethod.id);
  //     //         //this.processCardPayment(card.token);
  //     //   } else if (result.error) {
  //     //       /*
  //     //       code: "card_declined"
  //     //       decline_code: "card_not_supported"
  //     //       doc_url: "https://stripe.com/docs/error-codes/card-declined"
  //     //       message: "Your card is not supported."
  //     //       param: "number"
  //     //       type: "card_error"
  //     //       */
  //     //       this.rollbar.error(result.error);
  //     //       this.store.dispatch(new Loading(false));
  //     //       this.error$.next(this.formatErrorMessage(result.error.message));
  //     //     }

  //     // })

  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       result => {
  //         if (result.paymentMethod) {
  //           this.capturePaymentMethod(result.paymentMethod.id);
  //           //this.processCardPayment(card.token);
  //         } else if (result.error) {
  //           /*
  //             code: "card_declined"
  //             decline_code: "card_not_supported"
  //             doc_url: "https://stripe.com/docs/error-codes/card-declined"
  //             message: "Your card is not supported."
  //             param: "number"
  //             type: "card_error"
  //             */
  //           this.rollbar.error(result.error);
  //           this.store.dispatch(new Loading(false));
  //           this.error$.next(this.formatErrorMessage(result.error.message));
  //         }

  //         // console.log(card)
  //         // if (card.token) {
  //         //   this.confirmPaymentMethod(card.token);
  //         //   //this.processCardPayment(card.token);
  //         // } else if (card.error) {
  //         //   /*
  //         //   code: "card_declined"
  //         //   decline_code: "card_not_supported"
  //         //   doc_url: "https://stripe.com/docs/error-codes/card-declined"
  //         //   message: "Your card is not supported."
  //         //   param: "number"
  //         //   type: "card_error"
  //         //   */
  //         //   this.rollbar.error(card.error);
  //         //   this.store.dispatch(new Loading(false));
  //         //   this.error$.next(this.formatErrorMessage(card.error.message));
  //         // }
  //       },
  //       error => {
  //         this.error$.next(this.formatErrorMessage(error));
  //         // this.rollbar.error(error);
  //       }
  //     );
  // }

  formatErrorMessage(error) {
    return {
      message: error,
    };
  }

  /**
   * @description
   * FIND FORM VALIDATION ERROR
   * @returns
   */
  findFormErrors() {
    const errors: AllValidationErrors[] = getFormValidationErrors(this.billing.controls);

    if (errors) {
      let errorMessage = '';
      const errorMessages: string[] = [];

      // LOOP THROUGH ERROR MESSAGES AND REMOVE '_' WITH ' ' & RETURN THE FORMCONTROL

      errors.forEach((error) => {
        if (
          error.control_name.includes('building') ||
          error.control_name.includes('postcode') ||
          error.control_name.includes('address') ||
          error.control_name.includes('town')
        ) {
          errorMessages.push('Address');
        } else {
          const form = `${error.control_name?.split('_').join(' ')}`;
          error.control_name = form;
          errorMessages.push(
            error.control_name.charAt(0).toUpperCase() + error.control_name.slice(1)
          );
        }
      });

      // ERROR MESSAGE STRING
      errorMessage = [...new Set(errorMessages)].join(', ');

      // DISPLAY ERROR MESSAGE
      this.error$.next({ message: `${errorMessage} is required!` });
    }
    return;
  }

  /**
   * Emit results to output
   *
   * @param {*} result
   * @memberof AddPaymentMethodComponent
   */
  cardUpdated(result) {
    // this.card = result.card;
    this.complete = result.event?.complete;
    this.error$.next(null);
  }

  // processCardPayment(token) {

  //   this.stripe
  //     .confirmCardPayment(this.intent.client_secret, {
  //       payment_method: {
  //         card: {
  //           token: token.id
  //         }
  //       }
  //     })
  //     .then(function (result) {
  //       console.log(result);

  //       // if (result.error) {
  //       //   // Show error to your customer (e.g., insufficient funds)
  //       //   //console.log(result.error.message);
  //       //   el.paymentFailed(result.error.message);
  //       // } else {
  //       //   // The payment has been processed!
  //       //   if (result.paymentIntent.status === "succeeded") {
  //       //     // Show a success message to your customer
  //       //     // There's a risk of the customer closing the window before callback
  //       //     // execution. Set up a webhook or plugin to listen for the
  //       //     // payment_intent.succeeded event that handles any business critical
  //       //     // post-payment actions.
  //       //     el.confirmPayment();
  //       //   }
  //       // }
  //     });
  // }

  /**
   * Add a payment method to the user
   *
   * @param {*} token
   * @memberof AddPaymentMethodComponent
   */
  // capturePaymentMethod(payment_method_id) {
  //   this.store
  //     .dispatch(
  //       new ConfirmSetupIntent({
  //         payment_method_id: payment_method_id,
  //         setupintent_id: this.intent.id,
  //         address_id: this.address._id,
  //         usage: "off-session",
  //         default: true
  //       })
  //     )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       store => {
  //         console.log(store);
  //         this.expireSetupIntent();

  //         // const payment_method = store.user.payment_methods.find(
  //         //   pm => pm.payment_method_id == result.setupIntent.payment_method
  //         // );
  //         // this.notify();
  //         this.added.emit(payment_method_id);
  //       },
  //       error => {
  //         console.log(error);
  //         this.expireSetupIntent();
  //         this.rollbar.error(error);
  //         this.error$.next(this.formatErrorMessage(error));
  //       }
  //     );

  //   // this.actions$.pipe(ofActionSuccessful(ConfirmSetupIntent))
  //   // .pipe(untilDestroyed(this))
  //   // .subscribe((pm) => {
  //   //   console.log(pm)
  //   //   this.expireSetupIntent();
  //   //   this.added.emit(pm);
  //   // });

  //   // this.actions$.pipe(ofActionErrored(ConfirmSetupIntent))
  //   // .pipe(untilDestroyed(this))
  //   // .subscribe((error) => {
  //   //   this.expireSetupIntent();
  //   //   this.rollbar.error(error);
  //   //   this.error$.next(this.formatErrorMessage(error));
  //   // });
  // }

  /**
   * Add a payment method to the user
   *
   * @param {*} token
   * @memberof AddPaymentMethodComponent
   */
  // confirmPaymentMethod(token) {
  //   console.log(token);
  //   this.stripe
  //     .confirmCardSetup(this.intent.client_secret, {
  //       payment_method: {
  //         card: {
  //           token: token.id
  //         }
  //       }
  //     })
  //     .then(result => {
  //       // Expire setup intent as it's been used
  //       this.expireSetupIntent();

  //       if (result.error) {
  //         this.store.dispatch(new Loading(false));
  //         //this.store.dispatch(new SetError(result.error));
  //         console.log(result.error);
  //         this.rollbar.error(result.error);
  //         this.error$.next(this.formatErrorMessage(result.error.message));
  //         // Display error.message in your UI.
  //       } else {
  //         this.store
  //           .dispatch(
  //             new ConfirmSetupIntent({
  //               payment_method_id: result.setupIntent.payment_method,
  //               setupintent_id: result.setupIntent.id,
  //               address_id: this.address._id,
  //               usage: "off-session"
  //             })
  //           )
  //           .pipe(untilDestroyed(this))
  //           .subscribe(
  //             store => {
  //               const payment_method = store.user.payment_methods.find(
  //                 pm => pm.payment_method_id == result.setupIntent.payment_method
  //               );
  //               this.notify();
  //               this.added.emit(payment_method);
  //             },
  //             error => {
  //               console.log(error);
  //               // this.rollbar.error(error);
  //               this.error$.next(this.formatErrorMessage(error));
  //             }
  //           );
  //       }
  //     });
  // }

  /**
   * Confirm to the user
   *
   * @memberof AddPaymentMethodComponent
   */
  notify() {
    this.snackbar.open('Payment Method Added', 'Confirm', {
      panelClass: 'success',
      duration: 4000,
    });
  }

  // expireSetupIntent() {
  //   this.intent = null;
  //   this.store.dispatch(new ExpireSetupIntent());
  // }

  lookupPostcode() {
    const search =
      this.billing.get('country').value === 'GB' ? this.billing.get('postcode').value : '';

    if (search.length > 1) {
      this.postCodeloading$.next(true);
      const path = `https://api.getAddress.io/find/${search}?api-key=${this.environment.addressLookup.key}&expand=true`;

      this.http
        .get(path)
        .pipe(
          untilDestroyed(this),
          debounceTime(1000),
          catchError((e) => {
            this.postCodeloading$.next(false);
            this.postAddress = '';
            this.predictions.next(null);
            return throwError(e.error.error);
          })
        )
        .subscribe(
          (response: { addresses: [] }) => {
            this.postCodeloading$.next(false);
            this.postAddress = response;
            this.predictions.next(response.addresses);
          },
          () => {}
        );
    } else {
      this.predictions.next([]);
    }
  }

  displayFn(address): string {
    return address ? address : '';
  }

  selected(event: MatAutocompleteSelectedEvent) {
    // this.completeAddress(event.option.value.id);
    const response = event.option.value;

    if (response) {
      // this.toggleAddress(true, true);
      this.billing.patchValue({
        postcode: this.postAddress?.postcode,
        building_name_number: response.building_number
          ? response.building_number
          : response.building_name,
        street_name: response.thoroughfare,
        address_line_1: response.line_1,
        address_line_2: response.line_2,
        country: response.country,
        town_or_city: response.town_or_city,
      });
    }
  }

  ngOnDestroy(): void {}
}

export interface PaymentError {
  message: string;
}
