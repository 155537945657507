import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DhulHijjahervice {
  public DEFAULT_CURRENCY = 'GBP';
  public SUBSCRIPTION_START_TIME = '12:00:00';
  public DATE_FORMAT = 'DD-MM-yyyy';
  public DATETIME_FORMAT = 'DD-MM-yyyy HH:mm:ss';
  public TODAY = moment(new Date(), this.DATE_FORMAT);
  _START = '19-06-2023 12:00:00';
  public START_DATE = moment(this._START, this.DATE_FORMAT);
  constructor(private http: ApiService) {}

  get START() {
    return moment(this._START, this.DATETIME_FORMAT).isBefore(moment(), 'days')
      ? moment().format(this.DATETIME_FORMAT)
      : this._START;
  }

  get END() {
    return '28-06-2023 12:00:00';
  }

  /**
   * Check to see if DHG has started
   */
  get dhulHijjahStarted() {
    return '19-06-2023' + this.SUBSCRIPTION_START_TIME;
    // this.getDHGDayStartEnd()?.start?.isSameOrBefore(moment());
  }

  get remainingNights(): any {
    if (this.dhulHijjahStarted) {
      const addDays = 1;
      return Math.ceil(
        moment(this.END, this.DATE_FORMAT).diff(this.START_DATE, 'days', true) + addDays
      );
    }
  }

  days(startDate?, interval = 1) {
    console.log(
      Math.floor(
        moment(this.END, this.DATETIME_FORMAT).diff(
          moment(startDate ? startDate : this.START, this.DATETIME_FORMAT),
          'days'
        )
      )
    );
    return (
      Math.floor(
        moment(this.END, this.DATETIME_FORMAT).diff(
          moment(startDate ? startDate : this.START, this.DATETIME_FORMAT),
          'days'
        )
      ) /
        (interval || 1) +
      1
    );
  }

  ramadhanGregorianCalendar(year = 1442, month = 7) {
    return this.http
      .get(`hijriCalendarByAddress?month=${month}&year=${year}&address=`)
      .pipe(map((res) => res.data));

    return this.http.get(`/api/hToGCalendar/${month}/${year}`).pipe(map((res) => res.data));
  }

  prayerTimesByAddressDate(longitude: number, latitude: number, date?: string, timezone?: string) {
    return this.http
      .get(
        `aladhan/timingsByAddress/${moment(date).format(
          'DD-MM-YYYY'
        )}?address=${latitude},${longitude}&method=2&school=1&timezonestring=` //${params.timezone}
      )
      .pipe(map((res) => res.data));
  }

  prayerTimesByAddressMonth(address, month = 8, year?: number) {
    if (!year) {
      year = +new Intl.DateTimeFormat('ar-TN-u-ca-islamic', { year: 'numeric' }).format(Date.now());
    }
    return this.http
      .get(`hijriCalendarByAddress?month=${month}&year=${year}&address=${address}`)
      .pipe(map((res) => res.data));
  }
}
