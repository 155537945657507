import { Injectable } from '@angular/core';
import { JwtService } from '@givebrite/api';
import { ApiService } from 'libs/api/src/lib/api.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { CreateCharity, CreateCharityUser } from './charity.interface';
import jwt_decode from 'jwt-decode';
import { UserService } from '../user';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CharityService {
  //for store decoded token data
  charityData;
  constructor(
    private http: ApiService,
    private jwtService: JwtService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * subject for search result page
   */
  _searchSubject = new BehaviorSubject<any>({ search: '', filterBy: '' });
  searchSubject$ = this._searchSubject.asObservable().pipe(
    tap((response) => {
      const search = response?.search ? response?.search : '';
      if (search || search == '') {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { search_query: search },
        });
      }
    }),
  );

  charityUserSignup(data: CreateCharityUser) {
    return this.http.post('charity/register', data);
  }

  /**
   * charity sigup
   */
  charitySignup(data: CreateCharity) {
    return this.http.post('charity/identify', data);
  }

  /**
   * Get Unregistred Charities
   */
  getUnregistredCharities(filter_by = 'name', search = '', page = 1, limit = 10, featured?) {
    return this.http.get(
      `charity/list?filter_by=${filter_by}&value=${search}&page=${page}&limit=${limit}&is_featured=${featured}`,
    );
  }

  /**
   * Get  Charities
   */
  getCharities(filter_by = 'name', search = '', page = 1, limit = 5, title_sort?, sort_by?) {
    return this.http.get(
      `charity/list?filter_by=${filter_by}&value=${search}&page=${page}&limit=${limit}&sort_value=${title_sort}&sort_title=${sort_by}`,
    );
  }

  /**
   * Get single charity
   */
  single(slug: string, params = { type: '_id' }) {
    return this.http.get(`charity/${slug}?type=${params.type}`);
  }

  /**
   * @param slug
   * Get Fundraisers By Charity
   */
  getFundraisersByCharityId(slug: string) {
    //need to updata _id to charity_id
    return this.http.get(`fundraiser?filter_by=charity&value=${slug}`);
  }

  reSendCharityVerification() {
    return this.http
      .get(`charity/resend_verification?charity_id=${this.getCharityIdFromToken()}`)
      .pipe(
        catchError((error) => {
          return of({ error: error[0] });
        }),
      );
  }

  getPaymentGateways(charityId) {
    return this.http.get(`charity/gateways?charity_id=${charityId}`);
  }
  /**
   * connect payment gateway api
   */
  connectPaymentGateway(data) {
    return this.http.post('payment/gateway/connect', data);
  }

  updateGateway(data, gatewayId) {
    return this.http.put(
      `charity/gateway?charity_id=${this.getCharityIdFromToken()}&_id=${gatewayId}`,
      data,
    );
  }

  /**
   * Charity verification document submit
   */

  // verificationDocumentSubmit(doc) {
  //   return this.http.post('charity/documents', doc);
  // }

  goCardLessConnect() {
    return this.http.get(`charity/gocardless-connect?charity_id=${this.getCharityIdFromToken()}`);
  }

  goCardLessComplete(redirect_flow_id, token) {
    return this.http.get(
      `charity/gocardless-complete?charity_id=${this.getCharityIdFromToken()}&redirect_flow_id=${redirect_flow_id}&token=${token}`,
    );
  }

  /**
   * @returns Auto debit Flag
   */

  getAutodebitFlag() {
    return this.http.get(`charity/details?charity_id=${this.getCharityIdFromToken()}`).pipe(
      map((res) => {
        return res.subscription.autodebit;
      }),
    );
  }

  updateCharity(data) {
    return this.http.put(`charity?charity_id=${this.getCharityIdFromToken()}`, data);
  }

  /**
   * file upload api call
   */
  // fileUpload(file) {
  //   return this.http.post(`charity/files/upload`, file);
  // }

  /**
   * file upload api call
   */
  singleUpload(file, charityId) {
    return this.http.post(`charity/file?charity_id=${charityId}`, file);
  }

  /**
   * file upload api call
   */
  updateSingleUpload(file, charityId, fileId) {
    return this.http.put(`charity/file?charity_id=${charityId}&file_id=${fileId}`, file);
  }

  fileUpload(image) {
    return this.http
      .post(`charity/documents/upload?charity_id=${this.getCharityIdFromToken()}`, image)
      .pipe(
        catchError((error) => {
          return of({ error: error[0] });
        }),
      );
  }
  /**
   * doc upload api call
   */
  docUpload(image) {
    return this.http
      .post(`charity/document/upload?charity_id=${this.getCharityIdFromToken()}`, image)
      .pipe(
        catchError((error) => {
          return of({ error: error[0] });
        }),
      );
  }

  /**
   * Return all charity user and
   */
  getUser(name = '') {
    let nameUrl = '';
    if (name) {
      nameUrl = `name=${name}&`;
    }
    return this.http.get(`charity/users?${nameUrl}charity_id=${this.getCharityIdFromToken()}`);
  }
  /**
   * invite user by email
   */
  addCharityUser(email: string) {
    return this.http.get(
      `charity/user/invite?email=${email}&charity_id=${this.getCharityIdFromToken()}`,
    );
  }

  /**
   *
   * @param slug
   * Get Campaign By Charity
   */
  getCampaignsByCharityId(filter_by = 'charity', search = '', page = 1, limit = 5) {
    var url: string = '';
    if (search) {
      url = `?filter_by=${filter_by}&value=${search}&page=${page}&limit=${limit}`;
    }
    return this.http.get(`campaign${url}`).pipe(
      map((response) => {
        return {
          campaigns: response.docs,
          pagination: {
            limit: response.limit,
            pages: response.pages,
            page: response.page,
            total: response.total,
          },
        };
      }),
      catchError((error) => {
        return of({ error });
      }),
    );
  }
  /*
   * @param slug
   * Get Ongoing Events By Charity
   */
  getEventsByCharityId(id: string, page = 1, limit = 10) {
    return this.http.get(`events?slug=${id}&page=${page}&limit=${limit}`).pipe(
      map((response) => {
        return {
          events: response.docs,
          pagination: {
            limit: response.limit,
            pages: response.pages,
            page: response.page,
            total: response.total,
          },
        };
      }),
      catchError((error) => {
        return of({ error });
      }),
    );
  }

  getCharityPlans() {
    return this.http.get(`charity/plans?charity_id=${this.getCharityIdFromToken()}`);
  }

  /**
   * @returns Charity Plans
   */

  subscribePlan(plan) {
    return this.http.post(`charity/plan/subscribe?charity_id=${this.getCharityIdFromToken()}`, {
      plan: plan,
    });
  }

  list(params) {
    return this.http.get(`charity/list`, params).pipe(
      map((response) => {
        return {
          charities: response.docs,
          pagination: {
            limit: response.limit,
            page: response.page,
            pages: response.pages,
            total: response.total,
          },
        };
      }),
    );
  }

  campaign(charity_id: string, limit: number) {
    return this.http
      .get(`campaign?type=charity&_id=${charity_id}&search=&created_at=&limit=${limit}`)
      .pipe(
        map((response) => {
          return response.docs;
        }),
      );
  }

  fundraiser(user_id: string, limit: number) {
    return this.http
      .get(`campaign?type=user&_id=${user_id}&search=&created_at=&limit=${limit}`)
      .pipe(
        map((response) => {
          return response.docs;
        }),
      );
  }

  getGalleryImageByCharityId(id: string) {
    return this.http.get(`charity/gallery?charity_id=${id}`).pipe(
      map((response) => {
        return response.Contents.map((data: any) => {
          return `https://gb-downloads.s3.amazonaws.com/${data.Key}`;
        });
      }),
    );
  }

  connectCharityGateway(charityId, gatewayName, data) {
    return this.http.post(`charity/connect/${gatewayName}?charity_id=${charityId}`, data).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getCharityIdFromToken() {
    return this.userService.userFromJwt?.charity?._id || '';
  }

  getCharitiesForApproval(page, limit) {
    return this.http.get(`dashboard/charity?page=${page}&limit=${limit}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getSingleCharityForApproval(charityId) {
    return this.http.get(`dashboard/charity/${charityId}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  verifyCharity(charityId, type) {
    return this.http.post(`dashboard/charity/${charityId}?type=${type}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  downloadCharityDocument(documentLink) {
    return this.http.get(`dashboard/presigned-url?file=${documentLink}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }
}
