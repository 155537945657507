import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddEditAddressDialogComponent } from "./add-edit-address-dialog.component";
import { AddEditAddressModule } from "../../add-edit-address/add-edit-address.module";
import { AddEditAddressComponent } from "../../add-edit-address/add-edit-address.component";
import { ViewCardModule } from "../../../view-templates/view-card/view-card.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
    declarations: [AddEditAddressDialogComponent],
    imports: [CommonModule, AddEditAddressModule, ViewCardModule, MatProgressSpinnerModule, MatButtonModule, MatDialogModule],
    exports: [AddEditAddressDialogComponent]
})
export class AddEditAddressDialogModule {}
