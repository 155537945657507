<mat-form-field
  [class.white]="colour == 'white'"
  shouldLabelFloat="false"
  [appearance]="appearance"
  class="countries w-full"
  [formGroup]="form"
>
  <mat-select [disabled]="disabled" (selectionChange)="changed($event)" placeholder="Choose country" [formControl]="form_control">
    <mat-option [value]="country.code" *ngFor="let country of countries">
      {{ country.name }}
      <span *ngIf="currency">{{ 0 | currency: country?.currency:"symbol-narrow" | slice: 0:1 }} &nbsp;</span>
    </mat-option>
  </mat-select>
</mat-form-field>
