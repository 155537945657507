import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { CancelSubscriptionDialogComponent } from './cancel-subscription-dialog.component';

@NgModule({
  declarations: [CancelSubscriptionDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatIconModule,
    IconModule,
  ],
  exports: [CancelSubscriptionDialogComponent],
})
export class CancelSubscriptionDialogModule {}
