import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { ReplaySubject } from 'rxjs';
import { Charity } from '../../charity';

@Injectable({
  providedIn: 'root',
})
export class CharityServiceRg {
  constructor(private http: ApiService) {}

  private readonly _charity: ReplaySubject<Charity> = new ReplaySubject<Charity>();
  readonly charity$ = this._charity.asObservable();

  /**
   * @param  {any} params
   */
  get(slug: string) {
    return this.http.get(`charity/${slug}`);
  }

  /**
   * @param  {any} params
   */
  update(slug: string, payload: any) {
    return this.http.put(`charity?slug=${slug}`, payload);
  }

  /**
   * Get chariyies
   */
  list(limit, page, filter, sort, column, featured) {
    return this.http.get(
      `charity/list?limit=${limit}&page=${page}&filter_by=${filter}&sort=${sort}&column=${column}&is_featured=${featured}`
    );
  }

  /**
   * Get leaderboard
   */
  leaderboard(limit, page, filter, sort, column) {
    return this.http.get(
      `charities/leaderboard?limit=${limit}&page=${page}&filter=${JSON.stringify(
        filter
      )}&sort=${sort}&column=${column}`
    );
  }

  donationTypes() {
    return this.http.get(`/donation/types`);
  }
}
