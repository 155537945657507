<div class="content top-padding home-content" role="main">
  <div class="countries-container my-4 mb-12 -mt-6 sm:mb-20">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="countries-boxs text-center"
    >
      <ng-container *ngIf="stats$ | async">
        <div *ngFor="let stat of statTypes; let i = index" class="countrie-box">
          <div fxLayoutAlign="center center" class="box">
            <span>{{ (stats$ | async)[stat] | thousand: (stats$ | async)[stat] }}</span>
          </div>
          <p>{{ stat | titlecase }}</p>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="pt-4 pt-md-5 home-slider px-4 sm:px-0">
    <app-slider></app-slider>
  </div>

  <div class="pt-4 pt-md-5 charity-section">
    <app-charity #section id="home-charities"></app-charity>
  </div>

  <div class="charities-btn mt-4" fxLayoutAlign="center center">
    <div
      class="charities-btn-inner"
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <a
        mat-raised-button
        class="w-full rounded-full relative more-charities-btn"
        [routerLink]="['/morecharities']"
      >
        <span fxLayout="row" fxLayoutAlign="center center" class="btn-arrow"
          >Browse more charities <mat-icon class="arrow-icon-right">chevron_right</mat-icon>
        </span></a
      >
      <!-- <a mat-raised-button color="warn" class="btn-large round" [routerLink]='["/charities"]' >Get Started <mat-icon>chevron_right</mat-icon></a> -->
    </div>
  </div>

  <div class="d-block text-center">
    <div class="d-inline-block accordion-inner">
      <app-accordions></app-accordions>
    </div>
  </div>
</div>
