// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  x_tenant_id: 'givebrite',
  domain: 'givebrite.local',
  // endpoint: 'https://backend-v2-dashboard-p2ozvbp48.herokuapp.com/v1',
  endpoint: 'https://dashboard-testing-v2.herokuapp.com/v1',
  endpoints: {
    // dashboard: 'https://backend-v2-dashboard-p2ozvbp48.herokuapp.com/v1',
    dashboard: 'https://dashboard-testing-v2.herokuapp.com/v1',
    campaign: 'https://backend-v2-campaign-njbltgavwu.herokuapp.com/v1',
    // auth: 'https://backend-v2-authenticati-zh2wkk.herokuapp.com/v1',
    auth: 'https://authentication-testing-v2.herokuapp.com/v1',
    // donation: 'https://backend-v2-payment-t53pfizcs0u.herokuapp.com/v1',
    donation: 'https://payment-testing-v2.herokuapp.com/v1',
    // charity: 'https://backend-v2-charity-gmzckkn3gax.herokuapp.com/v1',
    charity: 'https://charity-testing-v2.herokuapp.com/v1',
  },
  x_tenant_ids: {
    donation: 'givebrite',
    ramadhan_giving: 'ramadhan-giving',
  },
  stripe: {
    pk:
      'pk_test_51JUQwTSECgaVNPybehqYLj3LDWQZNWgPrkYBYSy0qslnZyRJDNvvur0dBhDTPu34ydXMcA8IBm08cr9edId0cfkV007E7HdFbq',
    client: 'ca_8dv9VAJpI3w7m4IIEqrv6CWmGVV56OqM',
  },
  app: {
    url: 'http://dashboard.givebrite.local:2002',
  },
  apps: {
    authentication: {
      url: 'http://auth.givebrite.local:2000',
    },
    home: {
      url: 'http://beta.givebrite.local:1000',
    },
    campaign: {
      url: 'http://campaigns.givebrite.local:4300',
    },
  },
  paypal: {
    url: 'www.sandbox.paypal.com',
    client: 'ASP1FLWb8lvdbJwh6Z55EdrEusCz1yazM1CbVU1sT5lxymK31Fi1d-YdMgsXR1sErFz1m2k7PlzECi03',
  },
  assetPath: 'assets',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
