import * as Moment from 'moment';

// just an interface for type safety.
export interface RamadhanTimes {
  iftar: string;
  suhoor: string;
  hijriDay: number;
}

export interface RamadhanPrayerTimes {
  timings: any;
  date: any;
  meta: any;
}

export interface RamadhanStartEnd {
  start: Moment.Moment;
  end: Moment.Moment;
  suhoor: Moment.Moment;
  hijriDay: number;
}

export interface Country {
  code;
  name?;
  currency?;
}
