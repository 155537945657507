import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RamadhanCountdownComponent } from './countdown.component';
import { CountdownModule } from 'ngx-countdown';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [RamadhanCountdownComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, CountdownModule],
  exports: [RamadhanCountdownComponent],
})
export class RamadhanCountdownModule {}
