<mat-nav-list class="charities-header" fxLayout="row">
    <ng-container *ngFor="let link of navlink">
      <div [ngClass]="link.link === active ? 'active' : 'inactive'" class="link">
        <a mat-list-item routerLink="./" [queryParams]="{ filterBy: link.link }">
          <ng-container *ngIf="link.link === 'overview'; else lable">
            <mat-icon [icIcon]="link.icon"></mat-icon>
          </ng-container>
          <ng-template #lable>
            {{ link.caption }}
          </ng-template>
        </a>
      </div>
    </ng-container>
  </mat-nav-list>
  