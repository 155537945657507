import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AmountModule } from '../amount/amount.module';
import { ImpactSliderComponent } from './slider.component';

@NgModule({
  declarations: [ImpactSliderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatSliderModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    AmountModule,
    ReactiveFormsModule,
  ],
  exports: [ImpactSliderComponent],
  providers: [CurrencyPipe],
})
export class SliderModule {}
