import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import icPerson from '@iconify/icons-ic/twotone-person';
import { Select } from '@ngxs/store';
import { UserRg, UserServiceRg, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { UserState } from 'libs/data/src/lib/user/store/user.state';
import { User } from 'libs/data/src/lib/user/user.interface';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserComponent implements OnInit, OnDestroy {
  @Select(UserState.user) user$: Observable<User>;
  dropdownOpen: boolean;
  icPerson = icPerson;

  constructor(
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    public store: UserServiceRg
  ) {}

  ngOnInit() {}

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {}
}
