<!-- <div class="login-container w-full h-full my-12 container" fxLayout="column" fxLayoutAlign="center center">
  <div @fadeInUp class="overflow-hidden w-full">
    <form [formGroup]="form">
      <div fxLayout="column">
        <div fxFlex="auto" fxLayout="column">
          <h2>Continue with Ramadhan Giving</h2>
          <display-message class="mb-2" [error]="error$ | async"></display-message>
          <h4 class="pl-1 pb-2">Email address</h4>
          <mat-form-field fxFlex="grow">
            <mat-label>Enter your email</mat-label>
            <input formControlName="email" #email matInput required autocomplete="off" />
            <mat-error *ngIf="form.get('email').hasError('required')">We need an email address to log you in</mat-error>
            <mat-error *ngIf="form.get('email').hasError('email')">Please enter a valid email address</mat-error>
          </mat-form-field> -->
<!-- <mat-form-field fxFlex="grow">
            <mat-label>Password</mat-label>
            <input [type]="inputType" #password formControlName="password" matInput required />
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
              <mat-icon color="primary" *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
              <mat-icon color="primary" *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-hint>Click the eye to toggle visibility</mat-hint>
            <mat-error *ngIf="form.get('password').hasError('required')">We need a password to log you in</mat-error>
          </mat-form-field> -->
<!-- </div> -->

<!-- <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox *ngIf="" class="caption" color="primary">Remember Me</mat-checkbox>
          <a href="javascript:{}" (click)="forgotPassword()" class="caption">Forgot Password?</a>
        </div> -->

<!-- <button
          type="submit"
          [disabled]="!form.valid || (loading$ | async)"
          (click)="send()"
          color="primary"
          class="btn-large"
          mat-raised-button
          type="submit"
        >
        Continue
        </button> -->

<!-- <h4 class="or-div relative py-10 text-center">Or</h4>

        <div fxLayoutGap="30px" fxLayoutAlign="center center">
          <button (click)="signInWithGoogle()" >
                <img src="https://gb-downloads.s3.eu-west-1.amazonaws.com/ramadhan_giving_logos/g-icon.png">
          </button> -->
<!-- <button>
                <img src="https://gb-downloads.s3.eu-west-1.amazonaws.com/ramadhan_giving_logos/a-icon.png">
          </button> -->
<!-- <button (click)="signInWithFacebook()" >
                <img src="https://gb-downloads.s3.eu-west-1.amazonaws.com/ramadhan_giving_logos/f-icon.png">
          </button>

        </div> -->

<!-- <p *ngIf="" class="text-secondary text-center">
          <a [routerLink]="['/register']">Create account</a>
        </p> -->
<!-- </div>
    </form>
  </div>
</div> -->
<div class="text-center my-8">Redirecting...</div>
