import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name',
})
export class NamePipe implements PipeTransform {
  transform(
    value: {
      first_name?: string;
      last_name?: string;
      user?: { first_name: string; last_name: string };
    },
    ...args: unknown[]
  ): unknown {
    return value.first_name
      ? value.first_name + ' ' + value.last_name
      : value.user
      ? value.user.first_name + ' ' + value.user.last_name
      : 'Fundraiser';
  }
}
