<!-- 
  <div class="stripe-card-add" #card fxLayout="column">
 >

    <div *ngFor="let type of elementTypes">
      <label for="{{ type }}">{{ label(type) }}</label>
      <div [ngClass]="[type, 'input']"></div>
    </div>
    <p class="disclaimer mt-2">
      By adding the card you are agreeing for us to save it for future payments. We do not store your card information. Our
      payment processor is fully PCI compliant
    </p>
  </div> -->

<!--div class="w-full">
  <h2 *ngIf="!paymentRequestAvailable">Enter your Card Details</h2>
  <h2 *ngIf="paymentRequestAvailable">
    Or enter your card details
  </h2>
  <div class="w-full" id="stripe-paymentRequest">
  </div>
</div-->

<!-- <ngx-stripe-card
  (change)="onChange($event)"
  containerClass="card"
  [options]="defaultOptns"
  [elementsOptions]="elementsOptions"
></ngx-stripe-card>  -->
<div ngxStripeCardGroup [elementsOptions]="elementsOptions">
  <div class="stripe-element">
    <ngx-stripe-card-number
      [options]="cardOptions"
      (change)="cardUpdated($event)"
    ></ngx-stripe-card-number>
  </div>

  <!-- <div fxLayout="row" fxLayoutGap="10px" class="mt-2">
  <mat-icon [icIcon]="icCards.visa"></mat-icon>
  <mat-icon [icIcon]="icCards.mastercard"></mat-icon>
  <mat-icon [icIcon]="icCards.discover"></mat-icon>
  <mat-icon [icIcon]="icCards.amex"></mat-icon>
</div> -->
  <div fxLayout="row" fxLayoutGap="10px" class="pt-6">
    <div class="w-full stripe-element">
      <ngx-stripe-card-expiry
        [options]="cardOptions"
        (change)="cardUpdated($event)"
      ></ngx-stripe-card-expiry>
    </div>
    <div class="w-full stripe-element">
      <ngx-stripe-card-cvc
        [options]="cardOptions"
        (change)="cardUpdated($event)"
      ></ngx-stripe-card-cvc>
    </div>
  </div>
</div>

<div class="stripe-card-add" fxLayout="column">
  <!-- <div
  ngxStripeCardGroup

>
  <label for="card-number">Card Number</label>
  <ngx-stripe-card-number containerClass="card-number input" [options]="defaultOptns"></ngx-stripe-card-number>
  <label for="card-number">Card Number</label>
  <ngx-stripe-card-expiry [options]="defaultOptns"></ngx-stripe-card-expiry>
  <ngx-stripe-card-cvc [options]="defaultOptns"></ngx-stripe-card-cvc>
  <button type="submit" (click)="pay()">
    PAY
  </button>
</div> -->
</div>
