import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CharitiesComponent } from './charities/charities.component';
import { CharitysharesComponent } from './charityshares/charityshares.component';
import { HomeComponent } from './home.component';
import { MorecharitiesComponent } from './morecharities/morecharities.component';
import { ThankyouComponent } from './thankyou/thankyou.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { num: 1 } },
  { path: 'morecharities', component: MorecharitiesComponent, data: { num: 2 } },
  { path: 'charityshares', component: CharitysharesComponent, data: { num: 2 } },
  // { path: "charities", component: CharitiesComponent,data: { num: 3}  },
  // { path: "charities/:id", component: CharitiesComponent,data: { num: 3}  },
  {
    path: '',
    loadChildren: () => import('./charities/charities.module').then((m) => m.CharitiesModule),
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./thankyou/thankyou.module').then((m) => m.ThankyouModule),
    data: { num: 4 },
  },
  {
    path: ':id',
    loadChildren: () => import('./individual/individual.module').then((m) => m.IndividualModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
