import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export const countries = [
  { currency: 'AFN', name: 'Afghanistan', code: 'AF' },
  { currency: 'EUR', name: 'Aland Islands', code: 'AX' },
  { currency: 'ALL', name: 'Albania', code: 'AL' },
  { currency: 'DZD', name: 'Algeria', code: 'DZ' },
  { currency: 'USD', name: 'American Samoa', code: 'AS' },
  { currency: 'EUR', name: 'AndorrA', code: 'AD' },
  { currency: 'AOA', name: 'Angola', code: 'AO' },
  { currency: 'XCD', name: 'Anguilla', code: 'AI' },
  { currency: 'XCD', name: 'Antigua and Barbuda', code: 'AG' },
  { currency: 'ARS', name: 'Argentina', code: 'AR' },
  { currency: 'AMD', name: 'Armenia', code: 'AM' },
  { currency: 'AWG', name: 'Aruba', code: 'AW' },
  { currency: 'AUD', name: 'Australia', code: 'AU' },
  { currency: 'EUR', name: 'Austria', code: 'AT' },
  { currency: 'AZN', name: 'Azerbaijan', code: 'AZ' },
  { currency: 'BSD', name: 'Bahamas', code: 'BS' },
  { currency: 'BDT', name: 'Bangladesh', code: 'BD' },
  { currency: 'BBD', name: 'Barbados', code: 'BB' },
  { currency: 'EUR', name: 'Belgium', code: 'BE' },
  { currency: 'BZD', name: 'Belize', code: 'BZ' },
  { currency: 'XOF', name: 'Benin', code: 'BJ' },
  { currency: 'BMD', name: 'Bermuda', code: 'BM' },
  { currency: 'BOB', name: 'Bolivia', code: 'BO' },
  { currency: 'BAM', name: 'Bosnia and Herzegovina', code: 'BA' },
  { currency: 'BWP', name: 'Botswana', code: 'BW' },
  { currency: 'NOK', name: 'Bouvet Island', code: 'BV' },
  { currency: 'BRL', name: 'Brazil', code: 'BR' },
  { currency: 'USD', name: 'British Indian Ocean Territory', code: 'IO' },
  { currency: 'BND', name: 'Brunei Darussalam', code: 'BN' },
  { currency: 'BGN', name: 'Bulgaria', code: 'BG' },
  { currency: 'XOF', name: 'Burkina Faso', code: 'BF' },
  { currency: 'BIF', name: 'Burundi', code: 'BI' },
  { currency: 'KHR', name: 'Cambodia', code: 'KH' },
  // { currency: "XAF", name: "Cameroon", code: "CM" },
  { currency: 'CAD', name: 'Canada', code: 'CA' },
  { currency: 'CVE', name: 'Cape Verde', code: 'CV' },
  { currency: 'KYD', name: 'Cayman Islands', code: 'KY' },
  { currency: 'XAF', name: 'Central African Republic', code: 'CF' },
  { currency: 'XAF', name: 'Chad', code: 'TD' },
  // { currency: "CLP", name: "Chile", code: "CL" },
  { currency: 'CNY', name: 'China', code: 'CN' },
  { currency: 'AUD', name: 'Christmas Island', code: 'CX' },
  { currency: 'AUD', name: 'Cocos (Keeling) Islands', code: 'CC' },
  { currency: 'COP', name: 'Colombia', code: 'CO' },
  { currency: 'KMF', name: 'Comoros', code: 'KM' },
  { currency: 'XAF', name: 'Congo', code: 'CG' },
  { currency: 'CDF', name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { currency: 'NZD', name: 'Cook Islands', code: 'CK' },
  { currency: 'CRC', name: 'Costa Rica', code: 'CR' },
  // { currency: "XOF", name: "Cote D'Ivoire", code: "CI" },
  { currency: 'HRK', name: 'Croatia', code: 'HR' },
  { currency: 'EUR', name: 'Cyprus', code: 'CY' },
  { currency: 'CZK', name: 'Czech Republic', code: 'CZ' },
  { currency: 'DKK', name: 'Denmark', code: 'DK' },
  { currency: 'DJF', name: 'Djibouti', code: 'DJ' },
  { currency: 'XCD', name: 'Dominica', code: 'DM' },
  { currency: 'DOP', name: 'Dominican Republic', code: 'DO' },
  { currency: 'USD', name: 'Ecuador', code: 'EC' },
  { currency: 'EGP', name: 'Egypt', code: 'EG' },
  { currency: 'USD', name: 'El Salvador', code: 'SV' },
  { currency: 'XAF', name: 'Equatorial Guinea', code: 'GQ' },
  { currency: 'EUR', name: 'Estonia', code: 'EE' },
  { currency: 'ETB', name: 'Ethiopia', code: 'ET' },
  { currency: 'FKP', name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { currency: 'DKK', name: 'Faroe Islands', code: 'FO' },
  { currency: 'FJD', name: 'Fiji', code: 'FJ' },
  { currency: 'EUR', name: 'Finland', code: 'FI' },
  { currency: 'EUR', name: 'France', code: 'FR' },
  { currency: 'EUR', name: 'French Guiana', code: 'GF' },
  // { currency: "XPF", name: "French Polynesia", code: "PF" },
  { currency: 'EUR', name: 'French Southern Territories', code: 'TF' },
  { currency: 'XAF', name: 'Gabon', code: 'GA' },
  { currency: 'GMD', name: 'Gambia', code: 'GM' },
  { currency: 'GEL', name: 'Georgia', code: 'GE' },
  { currency: 'EUR', name: 'Germany', code: 'DE' },
  { currency: 'GIP', name: 'Gibraltar', code: 'GI' },
  { currency: 'EUR', name: 'Greece', code: 'GR' },
  { currency: 'DKK', name: 'Greenland', code: 'GL' },
  { currency: 'XCD', name: 'Grenada', code: 'GD' },
  { currency: 'EUR', name: 'Guadeloupe', code: 'GP' },
  { currency: 'USD', name: 'Guam', code: 'GU' },
  { currency: 'GTQ', name: 'Guatemala', code: 'GT' },
  { currency: 'GBP', name: 'Guernsey', code: 'GG' },
  // { currency: "GNF", name: "Guinea", code: "GN" },
  { currency: 'XOF', name: 'Guinea-Bissau', code: 'GW' },
  { currency: 'GYD', name: 'Guyana', code: 'GY' },
  { currency: 'HTG', name: 'Haiti', code: 'HT' },
  { currency: 'AUD', name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { currency: 'EUR', name: 'Holy See (Vatican City State)', code: 'VA' },
  { currency: 'HNL', name: 'Honduras', code: 'HN' },
  { currency: 'HKD', name: 'Hong Kong', code: 'HK' },
  { currency: 'HUF', name: 'Hungary', code: 'HU' },
  { currency: 'ISK', name: 'Iceland', code: 'IS' },
  { currency: 'INR', name: 'India', code: 'IN' },
  { currency: 'IDR', name: 'Indonesia', code: 'ID' },
  { currency: 'EUR', name: 'Ireland', code: 'IE' },
  { currency: 'GBP', name: 'Isle of Man', code: 'IM' },
  { currency: 'ILS', name: 'Israel', code: 'IL' },
  { currency: 'EUR', name: 'Italy', code: 'IT' },
  { currency: 'JMD', name: 'Jamaica', code: 'JM' },
  // { currency: "JPY", name: "Japan", code: "JP" },
  { currency: 'GBP', name: 'Jersey', code: 'JE' },
  { currency: 'KZT', name: 'Kazakhstan', code: 'KZ' },
  { currency: 'KES', name: 'Kenya', code: 'KE' },
  { currency: 'AUD', name: 'Kiribati', code: 'KI' },
  { currency: 'KRW', name: 'Korea, Republic of', code: 'KR' },
  { currency: 'KGS', name: 'Kyrgyzstan', code: 'KG' },
  { currency: 'LAK', name: "Lao People'S Democratic Republic", code: 'LA' },
  { currency: 'EUR', name: 'Latvia', code: 'LV' },
  { currency: 'LBP', name: 'Lebanon', code: 'LB' },
  { currency: 'LSL', name: 'Lesotho', code: 'LS' },
  { currency: 'LRD', name: 'Liberia', code: 'LR' },
  { currency: 'CHF', name: 'Liechtenstein', code: 'LI' },
  { currency: 'EUR', name: 'Luxembourg', code: 'LU' },
  { currency: 'MOP', name: 'Macao', code: 'MO' },
  { currency: 'MKD', name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  // { currency: "MGA", name: "Madagascar", code: "MG" },
  { currency: 'MWK', name: 'Malawi', code: 'MW' },
  { currency: 'MYR', name: 'Malaysia', code: 'MY' },
  { currency: 'MVR', name: 'Maldives', code: 'MV' },
  { currency: 'XOF', name: 'Mali', code: 'ML' },
  { currency: 'EUR', name: 'Malta', code: 'MT' },
  { currency: 'USD', name: 'Marshall Islands', code: 'MH' },
  { currency: 'EUR', name: 'Martinique', code: 'MQ' },
  { currency: 'MRO', name: 'Mauritania', code: 'MR' },
  { currency: 'MUR', name: 'Mauritius', code: 'MU' },
  { currency: 'EUR', name: 'Mayotte', code: 'YT' },
  { currency: 'MXN', name: 'Mexico', code: 'MX' },
  { currency: 'USD', name: 'Micronesia, Federated States of', code: 'FM' },
  { currency: 'MDL', name: 'Moldova, Republic of', code: 'MD' },
  { currency: 'EUR', name: 'Monaco', code: 'MC' },
  { currency: 'MNT', name: 'Mongolia', code: 'MN' },
  { currency: 'XCD', name: 'Montserrat', code: 'MS' },
  { currency: 'MAD', name: 'Morocco', code: 'MA' },
  { currency: 'MZN', name: 'Mozambique', code: 'MZ' },
  { currency: 'MMK', name: 'Myanmar', code: 'MM' },
  { currency: 'NAD', name: 'Namibia', code: 'NA' },
  { currency: 'AUD', name: 'Nauru', code: 'NR' },
  { currency: 'NPR', name: 'Nepal', code: 'NP' },
  { currency: 'EUR', name: 'Netherlands', code: 'NL' },
  { currency: 'XPF', name: 'New Caledonia', code: 'NC' },
  { currency: 'NZD', name: 'New Zealand', code: 'NZ' },
  { currency: 'NIO', name: 'Nicaragua', code: 'NI' },
  { currency: 'XOF', name: 'Niger', code: 'NE' },
  { currency: 'NGN', name: 'Nigeria', code: 'NG' },
  { currency: 'NZD', name: 'Niue', code: 'NU' },
  { currency: 'AUD', name: 'Norfolk Island', code: 'NF' },
  { currency: 'USD', name: 'Northern Mariana Islands', code: 'MP' },
  { currency: 'NOK', name: 'Norway', code: 'NO' },
  { currency: 'PKR', name: 'Pakistan', code: 'PK' },
  { currency: 'USD', name: 'Palau', code: 'PW' },
  { currency: 'ILS', name: 'Palestinian Territory, Occupied', code: 'PS' },
  { currency: 'PAB', name: 'Panama', code: 'PA' },
  { currency: 'PGK', name: 'Papua New Guinea', code: 'PG' },
  // { currency: "PYG", name: "Paraguay", code: "PY" },
  { currency: 'PEN', name: 'Peru', code: 'PE' },
  { currency: 'PHP', name: 'Philippines', code: 'PH' },
  { currency: 'NZD', name: 'Pitcairn', code: 'PN' },
  { currency: 'PLN', name: 'Poland', code: 'PL' },
  { currency: 'EUR', name: 'Portugal', code: 'PT' },
  { currency: 'USD', name: 'Puerto Rico', code: 'PR' },
  { currency: 'QAR', name: 'Qatar', code: 'QA' },
  { currency: 'EUR', name: 'Reunion', code: 'RE' },
  { currency: 'RON', name: 'Romania', code: 'RO' },
  { currency: 'RUB', name: 'Russian Federation', code: 'RU' },
  // { currency: "RWF", name: "RWANDA", code: "RW" },
  { currency: 'SHP', name: 'Saint Helena', code: 'SH' },
  { currency: 'XCD', name: 'Saint Kitts and Nevis', code: 'KN' },
  { currency: 'XCD', name: 'Saint Lucia', code: 'LC' },
  { currency: 'EUR', name: 'Saint Pierre and Miquelon', code: 'PM' },
  { currency: 'XCD', name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { currency: 'WST', name: 'Samoa', code: 'WS' },
  { currency: 'EUR', name: 'San Marino', code: 'SM' },
  { currency: 'STD', name: 'Sao Tome and Principe', code: 'ST' },
  { currency: 'SAR', name: 'Saudi Arabia', code: 'SA' },
  { currency: 'XOF', name: 'Senegal', code: 'SN' },
  { currency: 'SCR', name: 'Seychelles', code: 'SC' },
  { currency: 'SLL', name: 'Sierra Leone', code: 'SL' },
  { currency: 'SGD', name: 'Singapore', code: 'SG' },
  { currency: 'EUR', name: 'Slovakia', code: 'SK' },
  { currency: 'EUR', name: 'Slovenia', code: 'SI' },
  { currency: 'SBD', name: 'Solomon Islands', code: 'SB' },
  { currency: 'SOS', name: 'Somalia', code: 'SO' },
  { currency: 'ZAR', name: 'South Africa', code: 'ZA' },
  { currency: 'GBP', name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { currency: 'EUR', name: 'Spain', code: 'ES' },
  { currency: 'LKR', name: 'Sri Lanka', code: 'LK' },
  { currency: 'SRD', name: 'Suriname', code: 'SR' },
  { currency: 'NOK', name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { currency: 'SZL', name: 'Swaziland', code: 'SZ' },
  { currency: 'SEK', name: 'Sweden', code: 'SE' },
  { currency: 'CHF', name: 'Switzerland', code: 'CH' },
  { currency: 'TWD', name: 'Taiwan, Province of China', code: 'TW' },
  { currency: 'TJS', name: 'Tajikistan', code: 'TJ' },
  { currency: 'TZS', name: 'Tanzania, United Republic of', code: 'TZ' },
  { currency: 'THB', name: 'Thailand', code: 'TH' },
  { currency: 'USD', name: 'Timor-Leste', code: 'TL' },
  { currency: 'XOF', name: 'Togo', code: 'TG' },
  { currency: 'NZD', name: 'Tokelau', code: 'TK' },
  { currency: 'TOP', name: 'Tonga', code: 'TO' },
  { currency: 'TTD', name: 'Trinidad and Tobago', code: 'TT' },
  { currency: 'TRY', name: 'Turkey', code: 'TR' },
  { currency: 'USD', name: 'Turks and Caicos Islands', code: 'TC' },
  { currency: 'AUD', name: 'Tuvalu', code: 'TV' },
  // { currency: "UGX", name: "Uganda", code: "UG" },
  { currency: 'UAH', name: 'Ukraine', code: 'UA' },
  { currency: 'AED', name: 'United Arab Emirates', code: 'AE' },
  { currency: 'GBP', name: 'United Kingdom', code: 'GB' },
  { currency: 'USD', name: 'United States', code: 'US' },
  { currency: 'USD', name: 'United States Minor Outlying Islands', code: 'UM' },
  { currency: 'UYU', name: 'Uruguay', code: 'UY' },
  { currency: 'UZS', name: 'Uzbekistan', code: 'UZ' },
  { currency: 'VUV', name: 'Vanuatu', code: 'VU' },
  // { currency: "VND", name: "Viet Nam", code: "VN" },
  { currency: 'USD', name: 'Virgin Islands, British', code: 'VG' },
  { currency: 'USD', name: 'Virgin Islands, U.S.', code: 'VI' },
  { currency: 'XPF', name: 'Wallis and Futuna', code: 'WF' },
  { currency: 'MAD', name: 'Western Sahara', code: 'EH' },
  { currency: 'YER', name: 'Yemen', code: 'YE' },
];

@Component({
  selector: 'country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CountryListComponent implements OnInit {
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() control: any;
  @Input() form: any;
  @Input() currency: boolean;
  @Input() selected: string;
  @Input() disabled: boolean;
  @Input() appearance = 'legacy';
  @Input() colour;

  _countries = [
    { currency: 'AFN', name: 'Afghanistan', code: 'AF' },
    { currency: 'EUR', name: 'Åland Islands', code: 'AX' },
    { currency: 'ALL', name: 'Albania', code: 'AL' },
    { currency: 'DZD', name: 'Algeria', code: 'DZ' },
    { currency: 'USD', name: 'American Samoa', code: 'AS' },
    { currency: 'EUR', name: 'AndorrA', code: 'AD' },
    { currency: 'AOA', name: 'Angola', code: 'AO' },
    { currency: 'XCD', name: 'Anguilla', code: 'AI' },
    { currency: '', name: 'Antarctica', code: 'AQ' },
    { currency: 'XCD', name: 'Antigua and Barbuda', code: 'AG' },
    { currency: 'ARS', name: 'Argentina', code: 'AR' },
    { currency: 'AMD', name: 'Armenia', code: 'AM' },
    { currency: 'AWG', name: 'Aruba', code: 'AW' },
    { currency: 'AUD', name: 'Australia', code: 'AU' },
    { currency: 'EUR', name: 'Austria', code: 'AT' },
    { currency: 'AZN', name: 'Azerbaijan', code: 'AZ' },
    { currency: 'BSD', name: 'Bahamas', code: 'BS' },
    { currency: 'BHD', name: 'Bahrain', code: 'BH' },
    { currency: 'BDT', name: 'Bangladesh', code: 'BD' },
    { currency: 'BBD', name: 'Barbados', code: 'BB' },
    { currency: 'BYR', name: 'Belarus', code: 'BY' },
    { currency: 'EUR', name: 'Belgium', code: 'BE' },
    { currency: 'BZD', name: 'Belize', code: 'BZ' },
    { currency: 'XOF', name: 'Benin', code: 'BJ' },
    { currency: 'BMD', name: 'Bermuda', code: 'BM' },
    { currency: 'BTN', name: 'Bhutan', code: 'BT' },
    { currency: 'BOB', name: 'Bolivia', code: 'BO' },
    { currency: 'BAM', name: 'Bosnia and Herzegovina', code: 'BA' },
    { currency: 'BWP', name: 'Botswana', code: 'BW' },
    { currency: 'NOK', name: 'Bouvet Island', code: 'BV' },
    { currency: 'BRL', name: 'Brazil', code: 'BR' },
    { currency: 'USD', name: 'British Indian Ocean Territory', code: 'IO' },
    { currency: 'BND', name: 'Brunei Darussalam', code: 'BN' },
    { currency: 'BGN', name: 'Bulgaria', code: 'BG' },
    { currency: 'XOF', name: 'Burkina Faso', code: 'BF' },
    // { currency: "BIF", name: "Burundi", code: "BI" },
    { currency: 'KHR', name: 'Cambodia', code: 'KH' },
    { currency: 'XAF', name: 'Cameroon', code: 'CM' },
    { currency: 'CAD', name: 'Canada', code: 'CA' },
    { currency: 'CVE', name: 'Cape Verde', code: 'CV' },
    { currency: 'KYD', name: 'Cayman Islands', code: 'KY' },
    { currency: 'XAF', name: 'Central African Republic', code: 'CF' },
    { currency: 'XAF', name: 'Chad', code: 'TD' },
    { currency: 'CLP', name: 'Chile', code: 'CL' },
    { currency: 'CNY', name: 'China', code: 'CN' },
    { currency: 'AUD', name: 'Christmas Island', code: 'CX' },
    { currency: 'AUD', name: 'Cocos (Keeling) Islands', code: 'CC' },
    { currency: 'COP', name: 'Colombia', code: 'CO' },
    // { currency: "KMF", name: "Comoros", code: "KM" },
    { currency: 'XAF', name: 'Congo', code: 'CG' },
    { currency: 'CDF', name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { currency: 'NZD', name: 'Cook Islands', code: 'CK' },
    { currency: 'CRC', name: 'Costa Rica', code: 'CR' },
    { currency: 'XOF', name: "Cote D'Ivoire", code: 'CI' },
    { currency: 'HRK', name: 'Croatia', code: 'HR' },
    { currency: 'CUP', name: 'Cuba', code: 'CU' },
    { currency: 'EUR', name: 'Cyprus', code: 'CY' },
    { currency: 'CZK', name: 'Czech Republic', code: 'CZ' },
    { currency: 'DKK', name: 'Denmark', code: 'DK' },
    // { currency: "DJF", name: "Djibouti", code: "DJ" },
    { currency: 'XCD', name: 'Dominica', code: 'DM' },
    { currency: 'DOP', name: 'Dominican Republic', code: 'DO' },
    { currency: 'USD', name: 'Ecuador', code: 'EC' },
    { currency: 'EGP', name: 'Egypt', code: 'EG' },
    { currency: 'USD', name: 'El Salvador', code: 'SV' },
    { currency: 'XAF', name: 'Equatorial Guinea', code: 'GQ' },
    { currency: 'ERN', name: 'Eritrea', code: 'ER' },
    { currency: 'EUR', name: 'Estonia', code: 'EE' },
    { currency: 'ETB', name: 'Ethiopia', code: 'ET' },
    { currency: 'FKP', name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { currency: 'DKK', name: 'Faroe Islands', code: 'FO' },
    { currency: 'FJD', name: 'Fiji', code: 'FJ' },
    { currency: 'EUR', name: 'Finland', code: 'FI' },
    { currency: 'EUR', name: 'France', code: 'FR' },
    { currency: 'EUR', name: 'French Guiana', code: 'GF' },
    { currency: 'XPF', name: 'French Polynesia', code: 'PF' },
    { currency: 'EUR', name: 'French Southern Territories', code: 'TF' },
    { currency: 'XAF', name: 'Gabon', code: 'GA' },
    { currency: 'GMD', name: 'Gambia', code: 'GM' },
    { currency: 'GEL', name: 'Georgia', code: 'GE' },
    { currency: 'EUR', name: 'Germany', code: 'DE' },
    { currency: 'GHS', name: 'Ghana', code: 'GH' },
    { currency: 'GIP', name: 'Gibraltar', code: 'GI' },
    { currency: 'EUR', name: 'Greece', code: 'GR' },
    { currency: 'DKK', name: 'Greenland', code: 'GL' },
    { currency: 'XCD', name: 'Grenada', code: 'GD' },
    { currency: 'EUR', name: 'Guadeloupe', code: 'GP' },
    { currency: 'USD', name: 'Guam', code: 'GU' },
    { currency: 'GTQ', name: 'Guatemala', code: 'GT' },
    { currency: 'GBP', name: 'Guernsey', code: 'GG' },
    { currency: 'GNF', name: 'Guinea', code: 'GN' },
    { currency: 'XOF', name: 'Guinea-Bissau', code: 'GW' },
    { currency: 'GYD', name: 'Guyana', code: 'GY' },
    { currency: 'HTG', name: 'Haiti', code: 'HT' },
    { currency: 'AUD', name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { currency: 'EUR', name: 'Holy See (Vatican City State)', code: 'VA' },
    { currency: 'HNL', name: 'Honduras', code: 'HN' },
    { currency: 'HKD', name: 'Hong Kong', code: 'HK' },
    { currency: 'HUF', name: 'Hungary', code: 'HU' },
    { currency: 'ISK', name: 'Iceland', code: 'IS' },
    { currency: 'INR', name: 'India', code: 'IN' },
    { currency: 'IDR', name: 'Indonesia', code: 'ID' },
    { currency: 'IRR', name: 'Iran, Islamic Republic Of', code: 'IR' },
    { currency: 'IQD', name: 'Iraq', code: 'IQ' },
    { currency: 'EUR', name: 'Ireland', code: 'IE' },
    { currency: 'GBP', name: 'Isle of Man', code: 'IM' },
    { currency: 'ILS', name: 'Israel', code: 'IL' },
    { currency: 'EUR', name: 'Italy', code: 'IT' },
    { currency: 'JMD', name: 'Jamaica', code: 'JM' },
    { currency: 'JPY', name: 'Japan', code: 'JP' },
    { currency: 'GBP', name: 'Jersey', code: 'JE' },
    { currency: 'JOD', name: 'Jordan', code: 'JO' },
    { currency: 'KZT', name: 'Kazakhstan', code: 'KZ' },
    { currency: 'KES', name: 'Kenya', code: 'KE' },
    { currency: 'AUD', name: 'Kiribati', code: 'KI' },
    { currency: 'KPW', name: "Korea, Democratic People'S Republic of", code: 'KP' },
    // { currency: "KRW", name: "Korea, Republic of", code: "KR" },
    { currency: 'KWD', name: 'Kuwait', code: 'KW' },
    { currency: 'KGS', name: 'Kyrgyzstan', code: 'KG' },
    { currency: 'LAK', name: "Lao People'S Democratic Republic", code: 'LA' },
    { currency: 'EUR', name: 'Latvia', code: 'LV' },
    { currency: 'LBP', name: 'Lebanon', code: 'LB' },
    { currency: 'LSL', name: 'Lesotho', code: 'LS' },
    { currency: 'LRD', name: 'Liberia', code: 'LR' },
    { currency: 'LYD', name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { currency: 'CHF', name: 'Liechtenstein', code: 'LI' },
    { currency: 'LTL', name: 'Lithuania', code: 'LT' },
    { currency: 'EUR', name: 'Luxembourg', code: 'LU' },
    { currency: 'MOP', name: 'Macao', code: 'MO' },
    { currency: 'MKD', name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { currency: 'MGA', name: 'Madagascar', code: 'MG' },
    { currency: 'MWK', name: 'Malawi', code: 'MW' },
    { currency: 'MYR', name: 'Malaysia', code: 'MY' },
    { currency: 'MVR', name: 'Maldives', code: 'MV' },
    { currency: 'XOF', name: 'Mali', code: 'ML' },
    { currency: 'EUR', name: 'Malta', code: 'MT' },
    { currency: 'USD', name: 'Marshall Islands', code: 'MH' },
    { currency: 'EUR', name: 'Martinique', code: 'MQ' },
    { currency: 'MRO', name: 'Mauritania', code: 'MR' },
    { currency: 'MUR', name: 'Mauritius', code: 'MU' },
    { currency: 'EUR', name: 'Mayotte', code: 'YT' },
    { currency: 'MXN', name: 'Mexico', code: 'MX' },
    { currency: 'USD', name: 'Micronesia, Federated States of', code: 'FM' },
    { currency: 'MDL', name: 'Moldova, Republic of', code: 'MD' },
    { currency: 'EUR', name: 'Monaco', code: 'MC' },
    { currency: 'MNT', name: 'Mongolia', code: 'MN' },
    { currency: 'XCD', name: 'Montserrat', code: 'MS' },
    { currency: 'MAD', name: 'Morocco', code: 'MA' },
    { currency: 'MZN', name: 'Mozambique', code: 'MZ' },
    { currency: 'MMK', name: 'Myanmar', code: 'MM' },
    { currency: 'NAD', name: 'Namibia', code: 'NA' },
    { currency: 'AUD', name: 'Nauru', code: 'NR' },
    { currency: 'NPR', name: 'Nepal', code: 'NP' },
    { currency: 'EUR', name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { currency: 'XPF', name: 'New Caledonia', code: 'NC' },
    { currency: 'NZD', name: 'New Zealand', code: 'NZ' },
    { currency: 'NIO', name: 'Nicaragua', code: 'NI' },
    { currency: 'XOF', name: 'Niger', code: 'NE' },
    { currency: 'NGN', name: 'Nigeria', code: 'NG' },
    { currency: 'NZD', name: 'Niue', code: 'NU' },
    { currency: 'AUD', name: 'Norfolk Island', code: 'NF' },
    { currency: 'USD', name: 'Northern Mariana Islands', code: 'MP' },
    { currency: 'NOK', name: 'Norway', code: 'NO' },
    { currency: 'OMR', name: 'Oman', code: 'OM' },
    { currency: 'PKR', name: 'Pakistan', code: 'PK' },
    { currency: 'USD', name: 'Palau', code: 'PW' },
    { currency: 'ILS', name: 'Palestinian Territory, Occupied', code: 'PS' },
    { currency: 'PAB', name: 'Panama', code: 'PA' },
    { currency: 'PGK', name: 'Papua New Guinea', code: 'PG' },
    { currency: 'PYG', name: 'Paraguay', code: 'PY' },
    { currency: 'PEN', name: 'Peru', code: 'PE' },
    { currency: 'PHP', name: 'Philippines', code: 'PH' },
    { currency: 'NZD', name: 'Pitcairn', code: 'PN' },
    { currency: 'PLN', name: 'Poland', code: 'PL' },
    { currency: 'EUR', name: 'Portugal', code: 'PT' },
    { currency: 'USD', name: 'Puerto Rico', code: 'PR' },
    { currency: 'QAR', name: 'Qatar', code: 'QA' },
    { currency: 'EUR', name: 'Reunion', code: 'RE' },
    { currency: 'RON', name: 'Romania', code: 'RO' },
    { currency: 'RUB', name: 'Russian Federation', code: 'RU' },
    { currency: 'RWF', name: 'RWANDA', code: 'RW' },
    { currency: 'SHP', name: 'Saint Helena', code: 'SH' },
    { currency: 'XCD', name: 'Saint Kitts and Nevis', code: 'KN' },
    { currency: 'XCD', name: 'Saint Lucia', code: 'LC' },
    { currency: 'EUR', name: 'Saint Pierre and Miquelon', code: 'PM' },
    { currency: 'XCD', name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { currency: 'WST', name: 'Samoa', code: 'WS' },
    { currency: 'EUR', name: 'San Marino', code: 'SM' },
    { currency: 'STD', name: 'Sao Tome and Principe', code: 'ST' },
    { currency: 'SAR', name: 'Saudi Arabia', code: 'SA' },
    { currency: 'XOF', name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { currency: 'SCR', name: 'Seychelles', code: 'SC' },
    { currency: 'SLL', name: 'Sierra Leone', code: 'SL' },
    { currency: 'SGD', name: 'Singapore', code: 'SG' },
    { currency: 'EUR', name: 'Slovakia', code: 'SK' },
    { currency: 'EUR', name: 'Slovenia', code: 'SI' },
    { currency: 'SBD', name: 'Solomon Islands', code: 'SB' },
    { currency: 'SOS', name: 'Somalia', code: 'SO' },
    { currency: 'ZAR', name: 'South Africa', code: 'ZA' },
    { currency: 'GBP', name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { currency: 'EUR', name: 'Spain', code: 'ES' },
    { currency: 'LKR', name: 'Sri Lanka', code: 'LK' },
    { currency: 'SDG', name: 'Sudan', code: 'SD' },
    { currency: 'SRD', name: 'Suriname', code: 'SR' },
    { currency: 'NOK', name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { currency: 'SZL', name: 'Swaziland', code: 'SZ' },
    { currency: 'SEK', name: 'Sweden', code: 'SE' },
    { currency: 'CHF', name: 'Switzerland', code: 'CH' },
    { currency: 'SYP', name: 'Syrian Arab Republic', code: 'SY' },
    { currency: 'TWD', name: 'Taiwan, Province of China', code: 'TW' },
    { currency: 'TJS', name: 'Tajikistan', code: 'TJ' },
    { currency: 'TZS', name: 'Tanzania, United Republic of', code: 'TZ' },
    { currency: 'THB', name: 'Thailand', code: 'TH' },
    { currency: 'USD', name: 'Timor-Leste', code: 'TL' },
    { currency: 'XOF', name: 'Togo', code: 'TG' },
    { currency: 'NZD', name: 'Tokelau', code: 'TK' },
    { currency: 'TOP', name: 'Tonga', code: 'TO' },
    { currency: 'TTD', name: 'Trinidad and Tobago', code: 'TT' },
    { currency: 'TND', name: 'Tunisia', code: 'TN' },
    { currency: 'TRY', name: 'Turkey', code: 'TR' },
    { currency: 'TMT', name: 'Turkmenistan', code: 'TM' },
    { currency: 'USD', name: 'Turks and Caicos Islands', code: 'TC' },
    { currency: 'AUD', name: 'Tuvalu', code: 'TV' },
    { currency: 'UGX', name: 'Uganda', code: 'UG' },
    { currency: 'UAH', name: 'Ukraine', code: 'UA' },
    { currency: 'AED', name: 'United Arab Emirates', code: 'AE' },
    { currency: 'GBP', name: 'United Kingdom', code: 'GB' },
    { currency: 'USD', name: 'United States', code: 'US' },
    { currency: 'USD', name: 'United States Minor Outlying Islands', code: 'UM' },
    { currency: 'UYU', name: 'Uruguay', code: 'UY' },
    { currency: 'UZS', name: 'Uzbekistan', code: 'UZ' },
    // { currency: "VUV", name: "Vanuatu", code: "VU" },
    { currency: 'VEF', name: 'Venezuela', code: 'VE' },
    { currency: 'VND', name: 'Viet Nam', code: 'VN' },
    { currency: 'USD', name: 'Virgin Islands, British', code: 'VG' },
    { currency: 'USD', name: 'Virgin Islands, U.S.', code: 'VI' },
    { currency: 'XPF', name: 'Wallis and Futuna', code: 'WF' },
    { currency: 'MAD', name: 'Western Sahara', code: 'EH' },
    { currency: 'YER', name: 'Yemen', code: 'YE' },
    { currency: 'ZMK', name: 'Zambia', code: 'ZM' },
    { currency: 'ZWL', name: 'Zimbabwe', code: 'ZW' },
  ];

  stripe = [
    { code: 'GBP', min: 0.2 },
    { code: 'USD', min: 0.2 },
    { code: 'EUR', min: 0.2 },
    { code: 'AED', min: 0.2 },
    { code: 'AFN', min: 0.2 },
    { code: 'ALL', min: 0.2 },
    { code: 'AMD', min: 0.2 },
    { code: 'ANG', min: 0.2 },
    { code: 'AOA', min: 0.2 },
    { code: 'ARS', min: 0.2 },
    { code: 'AUD', min: 0.2 },
    { code: 'AWG', min: 0.2 },
    { code: 'AZN', min: 0.2 },
    { code: 'BAM', min: 0.2 },
    { code: 'BBD', min: 0.2 },
    { code: 'BDT', min: 0.2 },
    { code: 'BGN', min: 0.2 },
    { code: 'BIF', min: 0.2 },
    { code: 'BMD', min: 0.2 },
    { code: 'BND', min: 0.2 },
    { code: 'BOB', min: 0.2 },
    { code: 'BRL', min: 0.2 },
    { code: 'BSD', min: 0.2 },
    { code: 'BWP', min: 0.2 },
    { code: 'BZD', min: 0.2 },
    { code: 'CAD', min: 0.2 },
    { code: 'CDF', min: 0.2 },
    { code: 'CHF', min: 0.2 },
    { code: 'CLP', min: 0.2 },
    { code: 'CNY', min: 0.2 },
    { code: 'COP', min: 0.2 },
    { code: 'CRC', min: 0.2 },
    { code: 'CVE', min: 0.2 },
    { code: 'CZK', min: 0.2 },
    { code: 'DJF', min: 0.2 },
    { code: 'DKK', min: 0.2 },
    { code: 'DOP', min: 0.2 },
    { code: 'DZD', min: 0.2 },
    { code: 'EGP', min: 0.2 },
    { code: 'ETB', min: 0.2 },
    { code: 'FJD', min: 0.2 },
    { code: 'FKP', min: 0.2 },
    { code: 'GEL', min: 0.2 },
    { code: 'GIP', min: 0.2 },
    { code: 'GMD', min: 0.2 },
    { code: 'GNF', min: 0.2 },
    { code: 'GTQ', min: 0.2 },
    { code: 'GYD', min: 0.2 },
    { code: 'HKD', min: 0.2 },
    { code: 'HNL', min: 0.2 },
    { code: 'HRK', min: 0.2 },
    { code: 'HTG', min: 0.2 },
    { code: 'HUF', min: 0.2 },
    { code: 'IDR', min: 0.2 },
    { code: 'ILS', min: 0.2 },
    { code: 'INR', min: 0.2 },
    { code: 'ISK', min: 0.2 },
    { code: 'JMD', min: 0.2 },
    { code: 'JPY', min: 0.2 },
    { code: 'KES', min: 0.2 },
    { code: 'KGS', min: 0.2 },
    { code: 'KHR', min: 0.2 },
    { code: 'KMF', min: 0.2 },
    { code: 'KRW', min: 0.2 },
    { code: 'KYD', min: 0.2 },
    { code: 'KZT', min: 0.2 },
    { code: 'LAK', min: 0.2 },
    { code: 'LBP', min: 0.2 },
    { code: 'LKR', min: 0.2 },
    { code: 'LRD', min: 0.2 },
    { code: 'LSL', min: 0.2 },
    { code: 'MAD', min: 0.2 },
    { code: 'MDL', min: 0.2 },
    { code: 'MGA', min: 0.2 },
    { code: 'MKD', min: 0.2 },
    { code: 'MMK', min: 0.2 },
    { code: 'MNT', min: 0.2 },
    { code: 'MOP', min: 0.2 },
    { code: 'MRO', min: 0.2 },
    { code: 'MUR', min: 0.2 },
    { code: 'MVR', min: 0.2 },
    { code: 'MWK', min: 0.2 },
    { code: 'MXN', min: 0.2 },
    { code: 'MYR', min: 0.2 },
    { code: 'MZN', min: 0.2 },
    { code: 'NAD', min: 0.2 },
    { code: 'NGN', min: 0.2 },
    { code: 'NIO', min: 0.2 },
    { code: 'NOK', min: 0.2 },
    { code: 'NPR', min: 0.2 },
    { code: 'NZD', min: 0.2 },
    { code: 'PAB', min: 0.2 },
    { code: 'PEN', min: 0.2 },
    { code: 'PGK', min: 0.2 },
    { code: 'PHP', min: 0.2 },
    { code: 'PKR', min: 0.2 },
    { code: 'PLN', min: 0.2 },
    { code: 'PYG', min: 0.2 },
    { code: 'QAR', min: 0.2 },
    { code: 'RON', min: 0.2 },
    { code: 'RSD', min: 0.2 },
    { code: 'RUB', min: 0.2 },
    { code: 'RWF', min: 0.2 },
    { code: 'SAR', min: 0.2 },
    { code: 'SBD', min: 0.2 },
    { code: 'SCR', min: 0.2 },
    { code: 'SEK', min: 0.2 },
    { code: 'SGD', min: 0.2 },
    { code: 'SHP', min: 0.2 },
    { code: 'SLL', min: 0.2 },
    { code: 'SOS', min: 0.2 },
    { code: 'SRD', min: 0.2 },
    { code: 'STD', min: 0.2 },
    { code: 'SVC', min: 0.2 },
    { code: 'SZL', min: 0.2 },
    { code: 'THB', min: 0.2 },
    { code: 'TJS', min: 0.2 },
    { code: 'TOP', min: 0.2 },
    { code: 'TRY', min: 0.2 },
    { code: 'TTD', min: 0.2 },
    { code: 'TWD', min: 0.2 },
    { code: 'TZS', min: 0.2 },
    { code: 'UAH', min: 0.2 },
    { code: 'UGX', min: 0.2 },
    { code: 'UYU', min: 0.2 },
    { code: 'UZS', min: 0.2 },
    { code: 'VND', min: 0.2 },
    { code: 'VUV', min: 0.2 },
    { code: 'WST', min: 0.2 },
    { code: 'XAF', min: 0.2 },
    { code: 'XCD', min: 0.2 },
    { code: 'XOF', min: 0.2 },
    { code: 'XPF', min: 0.2 },
    { code: 'YER', min: 0.2 },
    { code: 'ZAR', min: 0.2 },
    { code: 'ZMW', min: 0.2 },
  ];

  countries = countries;
  //c = this.countries.filter(c => this.stripe.find(s => s.code == c.currency));

  constructor() {}

  ngOnInit() {}
  get form_control(): FormControl {
    return this.form.get('country') ? this.form.get('country') : this.control ? this.control : null;
  }

  get countryList() {
    // Add currency to object
    let a = this.countries;
    console.log(a);
    return [];
  }

  defaultCurrency(code) {
    return countries[code];
  }

  changed(select) {
    //let country = this.stripeCountries.find(country => country.id == select.value);
    this.change.emit(this.countries.find((c) => c.code == select.value));
  }
}
