import { DonationStep } from '../../donation/donation.interface';

export class GetSubscriptions {
  static readonly type = '[Subscriptions] Get';
  constructor(
    public charityId: string,
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'asc',
    public column: string = '',
    public download: number = 0
  ) {}
}

export class GetSubscriptionLeaderboard {
  static readonly type = '[Subscriptions] Get Subscriptions Leaderboard';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'desc',
    public column: string = ''
  ) {}
}

export class GetSubscription {
  static readonly type = '[Subscription] Get';
  constructor(public id: string) {}
}

export class GetSettings {
  static readonly type = '[Subscription] Settings';
}

export class CancelSubscription {
  static readonly type = '[Subscription] Cancel';
  constructor(public id: number, public cancel_at_period_end: number = 0) {}
}

export class SetDefaultPaymentMethod {
  static readonly type = '[Subscription] Set Default Payment Method';
  constructor(public id: number, public payment_method_id: number) {}
}

export class CreateSubscription {
  static readonly type = '[Subscription] Create';
  constructor(public payload: any) {}
}

export class CreateSubscriptionDraft {
  static readonly type = '[Subscription] Create Draft';
  constructor(public payload: any) {}
}

export class UpdateSubscription {
  static readonly type = '[Subscription] Update';
  constructor(public payload: any) {}
}

export class ConfirmSubscription {
  static readonly type = '[Subscription] Confirm';
  constructor(public payload: any) {}
}

export class SaveSubscriptionState {
  static readonly type = '[App] Save Subscription State';
  constructor(public subscription: any) {}
}

export class SaveSteps {
  static readonly type = '[App] Save Steps';
  constructor(public steps: DonationStep[]) {}
}

export class UpdateStep {
  static readonly type = '[App] Update Step';
  constructor(public updatedStep: DonationStep) {}
}

export class ResetSubscription {
  static readonly type = '[App] Reset Subscription';
  constructor(public clearSub: boolean = false) {}
}

export class GetStatistics {
  static readonly type = '[Subscription] Statistics';
  constructor() {}
}
