<div fxLayout="column" fxLayoutGap="1rem">
  <div class="preview" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="!video">
      <mat-icon>play_circle</mat-icon>
      <p>No preview available</p>
    </ng-container>
    <youtube-player
      *ngIf="video?.type == 'youtube'"
      [videoId]="video?.id"
      height="350px"
      width="100%"
    ></youtube-player>

    <iframe
      *ngIf="video?.type == 'vimeo'"
      width="100%"
      height="350"
      [src]="video.url"
      frameborder="0"
      allowfullscreen
    ></iframe>

  </div>
  <div>
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Enter link of video</mat-label>
      <input
        (keyup)="valueChanges($event)"
        matInput
        placeholder="Enter link of video"
      />
      <mat-icon matSuffix>insert_link</mat-icon>
      <mat-hint>YouTube or Vimeo Link</mat-hint>
    </mat-form-field>
  </div>
</div>
