import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { InviteDetailsComponent } from './invite-details.component';
import { RouterModule } from '@angular/router';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';

@NgModule({
  declarations: [InviteDetailsComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, UserLogoAvatarModule, RouterModule],
  exports: [InviteDetailsComponent],
})
export class InviteDetailsModule {}
