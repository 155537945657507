import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { pt } from './prayerTimes';
import { RamadhanPrayerTimes } from './ramadhan.interface';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getUserPosition(): Observable<any> {
    return Observable.create((observer) => {
      const onSuccess: PositionCallback = (pos: any) => {
        observer.next(pos);
      };

      const onError: PositionErrorCallback = (error) => {
        observer.error(error);
        console.log(error);
      };

      //const watcher: number =
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
      //return () => {
      //  navigator.geolocation.clearWatch(watcher);
      //};
    });
  }

  prayerTimesByAddressMonth(
    lat,
    long,
    month = 8,
    year?: number
  ): Observable<RamadhanPrayerTimes[]> {
    // return of(pt);
    // if (!year) {
    //   year = +new Intl.DateTimeFormat('ar-TN-u-ca-islamic', { year: 'numeric' }).format(Date.now());
    //   console.log(year);
    // }
    return this.http
      .get<{ data }>(
        `https://gb.api.aladhan.com/v1/hijriCalendar/1444/9?latitude=${lat}&longitude=${long}`
      )
      .pipe(
        map((res) => {
          var x = res.data;
          var y = x.slice();
          y.unshift(x[0]);
          y.unshift(x[0]);

          // x[0].date.gregorian.date = "asf";
          y[0] = {
            date: {
              gregorian: {
                date: '22-03-2023',
                day: '22',
                designation: '',
                abbreviated: 'AD',
                expanded: 'Anno Domini',
                format: 'DD-MM-YYYY',
                month: '',
                en: 'March',
                number: 4,
                weekday: '',
                year: '2023',
              },
              hijri: {
                day: '01',
              },
            },
            timings: {
              Asr: '04:02 (BST)',
              Dhuhr: '06:09 (BST)',
              Fajr: '04:26 (BST)',
              Imsak: '04:16 (BST)',
              Isha: '22:01 (BST)',
              Maghrib: '18:33 (BST)',
              Midnight: '01:18 (BST)',
              Sunrise: '06:09 (BST)',
              Sunset: '18:33 (BST)',
            },
          };
          y[1] = {
            date: {
              gregorian: {
                date: '23-03-2022',
                day: '23',
                designation: '',
                abbreviated: 'AD',
                expanded: 'Anno Domini',
                format: 'DD-MM-YYYY',
                month: '',
                en: 'March',
                number: 4,
                weekday: '',
                year: '2023',
              },
              hijri: {
                day: '02',
              },
            },
            timings: {
              Asr: '04:02 (BST)',
              Dhuhr: '06:09 (BST)',
              Fajr: '04:26 (BST)',
              Imsak: '04:16 (BST)',
              Isha: '22:01 (BST)',
              Maghrib: '18:33 (BST)',
              Midnight: '01:18 (BST)',
              Sunrise: '06:09 (BST)',
              Sunset: '18:33 (BST)',
            },
          };

          // x[1].date.gregorian.date = "asf";

          // console.log(new0Index);
          // console.log(x[0].date.gregorian.date);
          // console.log(x[1].date.gregorian.date);
          // console.log(x[2].date.gregorian.date);
          console.log(y);
          // x[0].date.gregorian.dax = "02";
          // x[0].date.readable = "02 Apr 2022";
          y.splice(x.length, 1);
          y.splice(x.length, 1);

          y.forEach(function (a, index) {
            y[index].date.hijri.day = index + 1;
          });
          console.log(y);
          return y;

          return res.data;
        })
      );

    // .pipe(
    //   map((r) => {

    //   }),
    //   catchError((error) => {
    //     return of(pt);
    //   })
    // );
    // return this.http
    //   .get(`hijriCalendarByAddress?month=${month}&year=${year}&address=${address}`)
    //   .pipe(map(res => res.data));
  }
}
