import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charityshares',
  templateUrl: './charityshares.component.html',
  styleUrls: ['./charityshares.component.scss']
})
export class CharitysharesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
