import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { DonationCartState } from './donation-cart.state';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([DonationCartState])],
})
export class DonationCartStoreModule {}
