import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'gb-campaign-theme-colors',
  templateUrl: './campaign-theme-colors.component.html',
  styleUrls: ['./campaign-theme-colors.component.scss'],
})
export class CampaignThemeColorsComponent implements OnInit {
  @Input() form: FormControl;
  @Input() team;

  themes: { theme: string }[] = [{ theme: 'default' }];

  // theme: string = '';

  get _theme() {
    return this.form.get('theme');
  }

  get theme() {
    return this.primary;
  }
  set theme(theme) {
    this._theme.get('primary').setValue(theme);
  }

  get primary() {
    return this._theme.get('primary').value;
  }

  constructor() {}

  ngOnInit(): void {}

  /**
   * select theme(color) based upon index
   * @param index index
   */
  selectedColor(theme) {
    this.theme = theme;
  }
}
