import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import close from '@iconify/icons-ic/close';
@Component({
  selector: 'givebrite-hero-dialog',
  templateUrl: './hero-dialog.component.html',
  styleUrls: ['./hero-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroDialogComponent implements OnInit {
  videoUrl;
  close = close;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<HeroDialogComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.getVideo(this.data.videoURL);
  }

  ngOnInit(): void {}

  /**
   * GET VIDEO
   * @param videoURL
   */
  getVideo(videoURL) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoURL);
  }

  /**
   * CLOSE DIALOG
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
