import { Component, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'campaign-slide-toggle',
  templateUrl: './campaign-slide-toggle.component.html',
  styleUrls: ['./campaign-slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignSlideToggleComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignSlideToggleComponent,
    },
  ],
})
export class CampaignSlideToggleComponent
  implements OnInit, ControlValueAccessor, Validator {
  _enabled: boolean = false; // Store the state in a seperate paramater
  onChange = (enabled: boolean) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  @Input() items = [];

  constructor() {}

  ngOnInit(): void {}

  /**
   * When the mat slide toggle is toggled
   */
  toggled(state: MatSlideToggleChange) {
    this.enabled = state.checked;
  }

  writeValue(enabled) {
    console.log(enabled);
    this.enabled = !!enabled;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // const enabled = control.value;
    // if (enabled <= 0) {
    //   return {
    //     mustBePositive: {
    //       enabled,
    //     },
    //   };
    // }
    return null;
  }

  set enabled(enabled: boolean) {
    this._enabled = enabled;
    this.onChange(this.enabled);
  }

  get enabled() {
    return this._enabled;
  }
}
