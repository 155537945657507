import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TipPipe } from '../tip.pipe';
import { TipSelectorComponent } from './tip-selector.component';
import { CurrencySymbolModule } from 'libs/theming/src/lib/pipes/currency-symbol/currency-symbol.module';

@NgModule({
  declarations: [TipSelectorComponent, TipPipe],
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CurrencySymbolModule,
  ],
  exports: [TipSelectorComponent],
  providers: [],
})
export class TipSelectorModule {}
