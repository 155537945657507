import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddPhasesComponent } from './campaign-add-phases.component';
import { CampaignAddPhasesDialogModule } from './campaign-add-phases-dialog.module';
import { CampaignAddSectionPlaceholderModule } from './../campaign-add-section-placeholder/campaign-add-section-placeholder.module';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CampaignAddPhasesComponent],
  imports: [
    CommonModule,
    CampaignAddSectionPlaceholderModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    CampaignAddPhasesDialogModule,
    MatDialogModule,
    FlexLayoutModule,
  ],
  exports: [CampaignAddPhasesComponent],
})
export class CampaignAddPhasesModule {}
