import { CampaignRg, ImpactRg } from '../campaign.interface';

export class GetCampaignsRg {
  static readonly type = '[Campaigns] List';
  constructor(
    public charityId?: any,
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'asc',
    public column: string = '',
    public download: number = 0
  ) {}
}

export class SearchCampaigns {
  static readonly type = '[Campaigns] Search';
  constructor(
    public limit: number = 100,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'asc',
    public column: string = '',
    public download: number = 0
  ) {}
}

export class GetCampaignRg {
  static readonly type = '[Campaign] Get';
  constructor(public slug: string) {}
}

export class UpdateCampaignRg {
  static readonly type = '[Campaign] Update';
  constructor(public payload: CampaignRg) {}
}

export class CreateCampaignRg {
  static readonly type = '[Campaign] Create';
  constructor(public payload: CampaignRg) {}
}

export class CreateCampaignSuccess {
  static readonly type = '[Campaign] Create Success';
  constructor(public campaign: CampaignRg) {}
}

export class SetActiveCampaign {
  static readonly type = '[Campaign] Set Active Campaign';
  constructor(public campaign: CampaignRg) {}
}

export class UpdateImpact {
  static readonly type = '[Impact] Update';
  constructor(public impact: ImpactRg) {}
}

export class CreateImpact {
  static readonly type = '[Impact] Create';
  constructor(public payload: any) {}
}

export class DeleteImpact {
  static readonly type = '[Impact] Delete';
  constructor(public id: string) {}
}

export class ResetCampaigns {
  static readonly type = '[Campaigns] Reset';
}

export class GetAllCampaigns {
  static readonly type = '[ALL Campaigns] List';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public sort: string = 'asc',
    public filterBy: string = 'is_featured',
    public value: string = ''
  ) {}
}
