<div class="toolbar w-full " fxLayout="row" fxLayoutAlign="start center" vexContainer>
  <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
    <mat-icon [icIcon]="icMenu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <h1 class="title ltr:pl-4 rtl:pr-4 m-0 select-none">
      <img style="height: 40px;" src="https://s3.eu-west-2.amazonaws.com/s3.givebrite.com/qg/qg.png" />
    </h1>
  </a>
  <!-- 
  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [fxHide]="mobileQuery"
    class="px-gutter"
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div> -->

  <span fxFlex></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1">
      <qg-toolbar-user></qg-toolbar-user>
    </div>
  </div>
</div>
