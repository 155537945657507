import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilestackService } from '@filestack/angular';

@Component({
  selector: 'givebrite-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  constructor(
    @Inject('environment') private environment,
    private filestackService: FilestackService
  ) {}

  @Input() options: Object = {
    accept: 'image/*',
    maxFiles: 1,
    transformations: { crop: { aspectRatio: 200 / 67, force: true } },
  };
  @Input() buttonText: string;
  @Input() dimension: string;
  @Input() parent: FormGroup;
  @Input() name: any;
  @Input() text: string;
  @Input() preview = false;
  @Input() background: string;
  @Input() type: 'all' | 'drop' | 'button' = 'all';
  @Output() uploaded: EventEmitter<any> = new EventEmitter();

  public image: string;
  private fs: any;
  public control;
  apiKey = this.environment.filestack;
  file: any;

  showPreview() {
    return this.control.value && this.preview;
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }

  selectImage() {
    this.fs.pick(this.options).then((result) => {
      const file = result.filesUploaded[0];
      const handle = result.filesUploaded[0].handle;
      this.parent.controls[this.name].setValue(file.url);
    });
  }

  removeImage() {
    this.parent.controls[this.name].reset();
  }

  uploadFile() {
    const picker = this.filestackService
      .picker(this.options)
      .open()
      .then((result) => {
        console.log(result);
        this.uploaded.emit(result);
      });
    //this.filestackService.upload(this.file).subscribe(res => console.log(res));
  }

  onUploadSuccess(event) {
    const file = event.filesUploaded.length > 1 ? event.filesUploaded : event.filesUploaded[0];
    this.uploaded.emit(file);
  }

  onUploadError(event) {}

  ngOnInit() {
    //this.filestackService.init(this.apiKey);
    //this.fs = filestack.default.init(environment.fileStackApiKey);
    //this.control = this.parent.controls[this.name];
  }
}
