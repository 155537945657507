<div fxLayout="row" class="campaign-stepper" fxLayoutAlign="center center">
  <div
    fxLayout="column"
    *ngFor="let step of steps; let i = index"
    fxLayoutAlign="center center"
    class="step"
    fxFlex="0 1 100px"
    [class.active]="step.path == active"
    [class.inactive]="currentIndex < i"
    [class.complete]="currentIndex > i"
  >
    <button
      [disabled]="currentIndex < i"
      (click)="navigate(step.path)"
      class="step-button"
      mat-mini-fab
      color="primary"
    >
      <mat-icon *ngIf="currentIndex > i">done</mat-icon>
      <ng-container *ngIf="currentIndex == i || currentIndex < i">{{
        i + 1
      }}</ng-container>
    </button>

    {{ step.name }}
  </div>
</div>
