import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApiModule,
  // HttpErrorInterceptor,
  //  rollbarFactory, RollbarService
} from '@givebrite/api';
import { CookieModule } from 'ngx-cookie';
// import { ResponsiveDirective } from './directives/responsive.directive';
// import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApiModule,
    //  PipesModule,
    CookieModule.forRoot(),
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    ApiModule,
    // PipesModule,
    // ResponsiveDirective,
    CookieModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true,
    // },
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory },
  ],
  declarations: [
    // ResponsiveDirective
  ],
})
export class CoreModule {}
