import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroComponent } from './hero.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeroDialogModule } from './hero-dialog/hero-dialog.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ResponsiveAssetsModule } from '../../directives/responsive.module';

@NgModule({
  declarations: [HeroComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NgxFlickingModule,
    HeroDialogModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    ResponsiveAssetsModule,
  ],
  exports: [HeroComponent],
})
export class HeroModule {}
