import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AddAddress, Address, UpdateAddress, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-add-edit-address-dialog',
  templateUrl: './add-edit-address-dialog.component.html',
  styleUrls: ['./add-edit-address-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditAddressDialogComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  address: Address;
  form: FormGroup;

  constructor(
    private store: Store,
    private dialog: MatDialogRef<AddEditAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Address,
    private snackbar: MatSnackBar
  ) {
    this.address = data;
  }

  dont_cancel() {
    this.dialog.close({ cancel: false });
  }

  formUpdated(form: FormGroup) {
    this.form = form;
    console.log(form.value);
  }

  addUpdateAddress() {
    if (this.form) {
      const action = this.address
        ? new UpdateAddress(this.form.value)
        : new AddAddress(this.form.value);
      this.store
        .dispatch(action)
        .pipe(untilDestroyed(this))
        .subscribe(
          (store) => {
            console.log(store, store.user.address);
            this.dialog.close(this.form.value);
          },
          (error) => {
            this.snackbar.open('There was an error adding address');
          }
        );
    }
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
