export interface GivebriteConfig {
  currency: string;
  country: string;
  language: string;
  user_type?: 'charity' | 'browsing' | 'fundraiser' | 'supporter';
}

export interface Product {
  _id?: string;
  name: string;
  thumbnail: string;
  description: string;
  url: string;
}
