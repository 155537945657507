import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { ResetModule } from '../forgot-password/reset/reset.module';
import { LoginModule } from '../login/login.module';
import { SetCookieModule } from '../set-cookie/set-cookie.module';
import { LogoutComponent } from './logout/logout.component';
import { UserRoutingModule } from './user-routing.module';

@NgModule({
  declarations: [LogoutComponent],
  imports: [
    CommonModule,
    LoginModule,
    ResetModule,
    ForgotPasswordModule,
    IconModule,
    MatIconModule,
    UserRoutingModule,
    SetCookieModule,
  ],
})
export class UserModule {}
