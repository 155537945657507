import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GitftaidCheckoutCardComponent } from './gitftaid-checkout-card.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GitftaidCheckoutCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
  exports: [GitftaidCheckoutCardComponent],
})
export class GitftaidCheckoutCardModule {}
