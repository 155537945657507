<div [ngClass]="class" class="toast" #content>
  <div class="h-full items-center flex">
    <div>
      <button class="close-icon" mat-mini-fab>
        <mat-icon *ngIf="type === 'error'">close</mat-icon>
        <mat-icon *ngIf="type === 'success'">done</mat-icon>
      </button>
    </div>
    <p class="mb-0">{{ error }}</p>
    <a class="close" (click)="removeToast()">&times;</a>
  </div>
</div>
