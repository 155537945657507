<a
  href="{{ donateUrl }}"
  mat-button
  [class.mat-raised-button]="button == 'raised'"
  [class.mat-stroked-button]="button == 'stroked'"
  [disabled]="disabled"
  [class.btn-xl]="size == 'large'"
  [class.btn-xl-donate]="isImpact"
  class="donate w-full"
  [class.donate-btn-disabled]="disabledText"
  [class.hidden]="isImpact && disabledText"
  [ngClass]="customClass"
  ><ng-container *ngIf="!disabledText">
    <mat-icon class="mr-2" *ngIf="showIcon">https</mat-icon>Donate</ng-container
  >
  <ng-container *ngIf="disabledText">{{ disabledText }}</ng-container>
</a>
