import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DonationCartService } from '../donation-cart.service';
import { DonationCartItem } from './../donation-cart.interface';
import {
  AddDonationCartItem,
  ClearDonationCart,
  CreateDonationCartSession,
  DeleteDonationCartItem,
  GetDonationCartItem,
  SetCartId,
  UpdateDonateCart,
  UpdateDonationCartItem,
  UpdateDonationCartTotal,
  UpdateQGDonationCartTotal,
} from './donation-cart.action';

export class DonationCartStateModel {
  items: DonationCartItem[];
  cartId: string;
  loading?: boolean;
  total: number;
  amountTotal: number;
  subtotal?: number;
  currency: string;
}

@State<DonationCartStateModel>({
  name: 'donationCart',
  defaults: {
    items: [],
    cartId: null,
    loading: false,
    total: 0,
    amountTotal: 0,
    subtotal: 0,
    currency: 'GBP',
  },
})
@Injectable()
export class DonationCartState {
  constructor(private donationCartService: DonationCartService) {}

  @Selector()
  static getDonationCart(state: DonationCartStateModel) {
    return state;
  }

  @Selector()
  static donationCartTotal(state: DonationCartStateModel) {
    return state?.total;
  }

  @Selector()
  static donationCartAmountTotal(state: DonationCartStateModel) {
    return state?.amountTotal;
  }

  @Selector()
  static getDonationItems(state: DonationCartStateModel) {
    return state?.items;
  }

  @Selector()
  static getCartId(state: DonationCartStateModel) {
    return state?.cartId;
  }

  @Selector()
  static loading(state: DonationCartStateModel) {
    return state?.loading;
  }

  @Action(UpdateDonationCartTotal)
  updateDonationCartTotal({ patchState, getState }: StateContext<DonationCartStateModel>) {
    const total = getState().items.reduce((total, item) => total + item.amount * item.qty, 0);

    const cartItems = getState().items?.filter((item) => (!item.contribution ? item : null));
    const amountTotal = cartItems.reduce((total, item) => total + item.amount * item?.qty, 0);
    patchState({
      total: total,
      amountTotal: amountTotal,
    });
  }

  @Action(ClearDonationCart)
  clearDonationCart({ setState }: StateContext<DonationCartStateModel>) {
    setState({
      items: [],
      loading: false,
      cartId: null,
      total: 0,
      amountTotal: 0,
      currency: 'GBP',
    });
  }

  @Action(SetCartId)
  setCartId({ patchState }: StateContext<DonationCartStateModel>, { cartId }) {
    patchState({
      cartId: cartId,
    });
  }

  @Action(CreateDonationCartSession)
  createDonationCartSession({ patchState }: StateContext<DonationCartStateModel>) {
    patchState({ loading: true });
    return this.donationCartService.createCartSession().pipe(
      tap(
        (result) => {
          patchState({
            cartId: result?._id,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        },
      ),
    );
  }

  @Action(AddDonationCartItem)
  addDonationCartItem({ patchState }: StateContext<DonationCartStateModel>, { cartId, payload }) {
    patchState({ loading: true });
    return this.donationCartService.addToCart(cartId, payload).pipe(
      tap(
        (result) => {
          patchState({
            // cartId: result?.item?.cart,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        },
      ),
    );
  }

  @Action(GetDonationCartItem)
  getDonationCartItem({ patchState, dispatch }: StateContext<DonationCartStateModel>, { cartId }) {
    patchState({ loading: true });
    return this.donationCartService.getCart(cartId).pipe(
      tap(
        (result) => {
          patchState({
            items: result?.items,
            loading: false,
          });
          dispatch(new UpdateDonationCartTotal());
        },
        (error) => {
          patchState({
            loading: false,
          });
          dispatch(new UpdateDonationCartTotal());
        },
      ),
    );
  }

  @Action(UpdateDonationCartItem)
  updateDonationCartItem(
    { patchState }: StateContext<DonationCartStateModel>,
    { cartId, itemId, payload },
  ) {
    patchState({ loading: true });
    return this.donationCartService.updateCart(cartId, itemId, payload).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        },
      ),
    );
  }

  @Action(DeleteDonationCartItem)
  deleteDonationCartItem({ patchState }: StateContext<DonationCartStateModel>, { cartId, itemId }) {
    patchState({ loading: true });
    return this.donationCartService.deleteCart(cartId, itemId).pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        },
      ),
    );
  }

  @Action(UpdateDonateCart)
  updateCart(ctx: StateContext<DonationCartStateModel>, { cart }) {
    const state = ctx.getState();
    ctx.patchState({
      ...cart,
    });
    ctx.dispatch(new UpdateQGDonationCartTotal());
  }

  @Action(UpdateQGDonationCartTotal)
  updateQgDonationCartTotal({ patchState, getState }: StateContext<DonationCartStateModel>) {
    const total = getState().items.reduce(
      (total, item: any) => total + item.share_price * item.shares * item.qty,
      0,
    );

    patchState({
      total: total,
      subtotal: total,
    });
  }
}
