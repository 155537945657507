<div class="text-center" fxLayout="row" fxLayoutGap="0.5rem">
  <div
    fxFlex="80px"
    fxLayout="column"
    class="phase mat-elevation-z2 text-center p-2"
    *ngFor="let phase of phases"
  >
    <h3 title>{{ phase.name }}</h3>
    <p subtitle>{{ phase.amount | currency: currency:'symbol' }}</p>
    <div fxFlex></div>
    <button mat-stroked-button class="btn-xs w-full">Edit</button>
  </div>

  <campaign-add-section-placeholder fxFlex="80px">
    <div campaign-add-actions>
      <button
        (click)="add()"
        class="btn-sm w-full"
        mat-raised-button
        color="primary"
      >
        <mat-icon>add</mat-icon> Add
      </button>
    </div>
  </campaign-add-section-placeholder>
</div>
