import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { RelativeDateTimeModule } from '../../../pipes/relative-date-time/relative-date-time.module';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { ToolbarUserProfileComponent } from './toolbar-user-profile/toolbar-user-profile.component';
import { ToolbarUserComponent } from './toolbar-user.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { UserLogoAvatarModule } from '../../../../../../core/src/lib/user/user-logo-avatar/user-logo-avatar.module';

@NgModule({
  declarations: [ToolbarUserComponent, ToolbarUserDropdownComponent, ToolbarUserProfileComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatRippleModule,
    MatMenuModule,
    MatButtonModule,
    RelativeDateTimeModule,
    RouterModule,
    MatTooltipModule,
    IconModule,
    UserLogoAvatarModule,
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: ToastrService, useClass: ToastrService }],
  exports: [ToolbarUserComponent, ToolbarUserProfileComponent],
})
export class ToolbarUserModule {}
