import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ImageCropperDialogComponent } from './image-cropper-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampaignCoverUploaderModule } from '../../campaign-cover-uploader/campaign-cover-uploader.module';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [ImageCropperDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    ImageCropperModule,
  ],
  exports: [ImageCropperDialogComponent],
})
export class ImageCropperDialogModule {}
