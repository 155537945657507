import Rollbar from 'rollbar';
import { InjectionToken, Injectable, Inject, ErrorHandler } from '@angular/core';
import { throwError } from 'rxjs';

export const rollbarConfig = {
  accessToken: 'e5a3a72bdba647b880b36d1b7951045d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false },
  server: {
    //...
    root: '/dist/apps/ramadhan-giving/browser',
    branch: 'share-my-sadaqah',
  },
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject('environment') public env: any
  ) {}

  handleError(err: any): void {
    // console.log(err);
    // this.rollbar.error(err.originalError || err);
  }
}
