<div class="donations-list-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div>
      <h1 class="mb-4">Donations</h1>
      <p class="mb-4">Share with your friends and family and help them reach new heights!</p>
    </div>

    <givebrite-close-icon
      mat-dialog-title
      fxLayout="row"
      fxLayoutAlign="end"
      (onClose)="closeDialog()"
    ></givebrite-close-icon>
  </div>
  <mat-divider></mat-divider>

  <mat-dialog-content>
    <div *ngIf="isCampaignActive">
      <givebrite-campaigns-donation page="campaign" [campaign]="campaign" appearance="list-view">
      </givebrite-campaigns-donation>
    </div>

    <div *ngIf="isFundraiserActive">
      <givebrite-campaigns-donation
        page="fundraiser"
        [fundraiser]="fundraiser"
        appearance="list-view"
      >
      </givebrite-campaigns-donation>
    </div>

    <div *ngIf="isTeamActive">
      <givebrite-campaigns-donation page="team" [team]="team" appearance="list-view">
      </givebrite-campaigns-donation>
    </div>

    <mat-divider></mat-divider>
  </mat-dialog-content>
</div>
