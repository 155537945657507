import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SadaqahJariyahList } from './sadaqah-jariyah';
@Component({
  selector: 'givebrite-sadaqah-jariyah-campaign',
  templateUrl: './sadaqah-jariyah-campaign.component.html',
  styleUrls: ['./sadaqah-jariyah-campaign.component.scss'],
})
export class SadaqahJariyahCampaignComponent implements OnInit {
  sadaqahJariyahList: {
    id: number;
    name: string;
    img: string;
    checked: boolean;
  }[];

  sadaqahJariyahForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.sadaqahJariyahForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.sadaqahJariyahList = SadaqahJariyahList;
  }

  /**
   * select list
   * @param index
   */
  selectedList(index) {
    this.sadaqahJariyahList.forEach((item, idx) => {
      if (index != idx) {
        item.checked = false;
      } else {
        item.checked = !item.checked;
      }
    });
  }
}
