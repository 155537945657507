import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { Pagination } from '../../../pagination';
import { DonationRg } from '../donation.interface';
import { DonationServiceRg } from '../donation.service';
import {
  CreateDonation,
  GetDonation,
  MarkSuccess,
  RefundDonation,
  UpdateDonation,
} from './donation.action';

export class DonationStateModelRg {
  donations: DonationRg[];
  donation: DonationRg;
  pagination: Pagination;
  loading: boolean;
}

@State<DonationStateModelRg>({
  name: 'donations',
  defaults: {
    donations: [],
    donation: null,
    pagination: null,
    loading: false,
  },
})
export class DonationStateRg {
  constructor(private donationService: DonationServiceRg) {}

  @Selector()
  static getDonationList(state: DonationStateModelRg) {
    return state.donations;
  }

  @Selector()
  static getDonation(state: DonationStateModelRg) {
    return state.donation;
  }

  @Selector()
  static getPagination(state: DonationStateModelRg) {
    return state.pagination;
  }

  @Selector()
  static loading(state: DonationStateModelRg) {
    return state.loading;
  }

  @Action(GetDonation)
  getDonation({ getState, setState, patchState }: StateContext<DonationStateModelRg>, { id }) {
    patchState({ loading: true });
    return this.donationService.get(id).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          donation: result,
          loading: false,
        });
      })
    );
  }

  @Action(MarkSuccess)
  markSuccess({ getState, setState, patchState }: StateContext<DonationStateModelRg>, { id }) {
    patchState({ loading: true });
    return this.donationService.markSuccess(id).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          donation: null,
          loading: false,
        });
      })
    );
  }

  @Action(RefundDonation)
  cancelSubscription(
    { getState, setState, patchState, dispatch }: StateContext<DonationStateModelRg>,
    { id }
  ) {
    patchState({ loading: true });
    return this.donationService.refund(id).pipe(
      map((result) => {
        const state = getState();
        setState({
          ...state,
        });

        setTimeout(() => {
          dispatch(new GetDonation(id)).subscribe((success) => {
            patchState({ loading: false });
          });
        }, 2000);
      })
    );
  }

  @Action(CreateDonation)
  createDonation(
    { getState, patchState }: StateContext<DonationStateModelRg>,
    { payload }: CreateDonation
  ) {
    patchState({ loading: true });
    return this.donationService.create(payload).pipe(
      map((result) => result),
      tap((result) => {
        const state = getState();
        patchState({
          ...state,
          donation: result,
          loading: false,
        });
      })
    );
  }

  @Action(UpdateDonation)
  updateDonation(
    { getState, patchState }: StateContext<DonationStateModelRg>,
    { payload }: CreateDonation
  ) {
    patchState({ loading: true });
    return this.donationService.update(payload).pipe(
      map((result) => result),
      tap((result) => {
        const state = getState();
        patchState({
          ...state,
          donation: result,
          loading: false,
        });
      })
    );
  }
}
