<div class="login-confirmation h-full">
  <div
    class="container text-center"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <img src="https://gb-downloads.s3.eu-west-1.amazonaws.com/ramadhan_giving_logos/email+envelope.png" class="w-40 pt-20" />

    <div class="text-2xl font-semibold my-5">Check your email</div>

    <div class="text-base opacity-75">
      We just sent an email to <br />{{ email }}
    </div>

    <div class="mt-8 opacity-75">
      It has a magic link that'll sign you in to Give Brite
    </div>
    <!-- <a mat-raised-button class="mt-20" color="primary" href="https://mail.google.com/mail/u/0/?fs=1" target="_blank">
          Open Email App
        </a> -->
    <a class="primary mt-5" routerLink="/user/email-not-receive"
      >I didn't receive my email</a
    >
  </div>
</div>
