import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-curve',
  templateUrl: './curve.component.html',
  styleUrls: ['./curve.component.scss'],
})
export class CurveComponent implements OnInit {
  @Input() fill;
  constructor() {}

  ngOnInit(): void {}
}
