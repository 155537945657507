import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressListItemComponent } from './address-list-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddEditAddressDialogModule } from '../../dialogs/add-edit-address-dialog/add-edit-address-dialog.module';

@NgModule({
  declarations: [AddressListItemComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    MatListModule,
    IconModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    AddEditAddressDialogModule,
  ],
  exports: [AddressListItemComponent],
})
export class AddressListItemModule {}
