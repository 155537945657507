import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderDropZoneComponent } from './uploader-drop-zone.component';
import { NgxFileDropModule } from 'ngx-file-drop';
// import { NgxUploaderModule } from 'ngx-uploader';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ImageCropperDialogModule } from '../image-cropper-dialog/image-cropper-dialog.module';
import { ToastModule } from 'libs/theming/src/lib/components/toast/toast.module';

@NgModule({
  declarations: [UploaderDropZoneComponent],
  imports: [
    CommonModule,
    NgxFileDropModule,
    // NgxUploaderModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    ImageCropperDialogModule,
    ToastModule,
  ],
  exports: [UploaderDropZoneComponent],
})
export class UploaderDropZoneModule {}
