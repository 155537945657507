import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { CampaignState } from './campaign.state';
import { PaginationState } from '../../pagination/pagination.state';
import { FundraiserState } from './fundraiser.state';
import { TeamState } from './team.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([CampaignState, FundraiserState, TeamState]),
  ],
})
export class CampaignStoreModule {}
