import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '@givebrite/theming';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@abhinavakhil/iconify-angular';
import { PopoverRef } from '@givebrite/theming';
import { Select, Store } from '@ngxs/store';
// import { UserState, Logout } from "@givebrite/data/store";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Logout, User, UserState } from '@givebrite/data';
// import { User } from "@givebrite/data/models";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'qb-toolbar-user-dropdown',
  templateUrl: './qurbani-giving-toolbar-user-dropdown.component.html',
  styleUrls: ['./qurbani-giving-toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QurbaniGivingToolbarUserDropdownComponent implements OnInit {
  @Select(UserState.user) user$: Observable<User>;

  items: MenuItem[] = [
    /* {
      id: "1",
      icon: icAccountCircle,
      label: "My Profile",
      description: "Personal Information",
      colorClass: "text-teal-500",
      route: "/dashboard"
    },*/
    // {
    //   id: "2",
    //   icon: icListAlt,
    //   label: "My Subscriptions",
    //   description: "Manage your subscriptions",
    //   colorClass: "text-primary-500",
    //   route: "/dashboard"
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: icCheckCircle,
      colorClass: 'text-green-500',
    },
    {
      id: 'away',
      label: 'Away',
      icon: icAccessTime,
      colorClass: 'text-orange-500',
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: icDoNotDisturb,
      colorClass: 'text-red-500',
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: icOfflineBolt,
      colorClass: 'text-gray-500',
    },
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<QurbaniGivingToolbarUserDropdownComponent>,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit() {}

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }
  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.router.navigate(['']);
    });
    this.close();
  }
}
