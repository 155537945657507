import { getCurrencySymbol } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { slugify } from '@givebrite/core';
import { GivebriteConfig, GivebriteState } from '@givebrite/data';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'gb-campaign-url',
  templateUrl: './campaign-url.component.html',
  styleUrls: ['./campaign-url.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignURLComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignURLComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignURLComponent implements ControlValueAccessor, Validator {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;
  @Input() link;
  @Input() set name(name) {
    //  only dynamically generate slug if the field hasn't been touched (change)
    if (!this.touched) {
      if (name) {
        this.url = slugify(name);
      }
    }
  }
  _url: string;
  onChange = (url: string) => {};
  onTouched = () => {};
  touched = false;
  disabled: boolean = false;

  writeValue(url: string) {
    // @TODO - when url is set (on edit), touched should be true
    // this.markAsTouched();
    this.url = url;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    // console.log(onTouched);
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const url = control.value;
    if (url == '') {
      return {
        cantBeEmpty: {
          url,
        },
      };
    }
    return null;
  }

  setDisabledState(isDisabled: boolean) {
    console.log(isDisabled);
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  constructor() {}

  set url(url: string) {
    this._url = url;
    this.onChange(this.url);
  }

  get url() {
    return this._url;
  }
}
