import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ResetErrors } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'display-message',
  templateUrl: './display-message.component.html',
  styleUrls: ['./display-message.component.scss'],
})
export class DisplayMessageComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() error;
  @Input() type = 'error';
  @Input() heading: string;
  @ViewChild('content', { static: true }) content: ElementRef;

  constructor(private store: Store) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  get class() {
    return {
      'bg-red-100': this.type == 'error',
      'border-red-400': this.type == 'error',
      'text-red-700': this.type == 'error',
      'bg-green-100': this.type == 'success',
      'border-green-400': this.type == 'success',
      'text-green-700': this.type == 'success',
      hidden: this.content.nativeElement.innerHTML.trim() == undefined || !this.error,
    };
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetErrors());
  }
}
