import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch',
})
export class FilterSearch implements PipeTransform {
  transform(item: any[], searchText: string): any[] {
    if (!item) {
      return [];
    }

    if (!searchText) {
      return item;
    }

    searchText = searchText.toLocaleLowerCase();

    return item.filter((response, i) => {
      return response.name.toLocaleLowerCase().includes(searchText);
    });
  }
}
