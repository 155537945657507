import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
// import { SidenavItemModule } from "libs/theming/src/lib/components/sidenav-item/sidenav-item.module";
import { ScrollbarModule } from 'libs/theming/src/lib/components/scrollbar/scrollbar.module';
import { SidenavItemModule } from '@givebrite/theming';

@NgModule({
  declarations: [SidenavComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    SidenavItemModule,
    ScrollbarModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
  ],
  exports: [SidenavComponent],
})
export class RamadhanGivingSidenavModule {}
