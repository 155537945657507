<div
  *ngIf="logo"
  class="charity-logo-avatar-container logo-container p-1"
  [class.color]="color"
  [style.border-radius]="radius"
  fxLayoutAlign="center center"
  [style.background-color]="color ? 'white' : logo?.white ? theme?.primary : 'white'"
  [style.width]="width + 'px'"
  [style.height]="width + 'px'"
>
  <givebrite-logo-card
    format="png"
    [width]="width"
    [height]="width"
    [logo]="color ? (logo.white ? logo.white : logo.color) : logo.color"
  ></givebrite-logo-card>
</div>
