import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { Store } from "@ngxs/store";
@Component({
  selector: 'givebrite-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent
implements OnInit, AfterViewInit,OnChanges {
  constructor(private store: Store) {}

  @Input() error;
  @Input() type: string = "error";
  @Input() heading: string;
  @Output() clearError: EventEmitter<any> = new EventEmitter();
  @ViewChild("content", { static: true }) content: ElementRef;
  errorTimeout;
  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.error.currentValue != undefined){
      this.errorTimeout = setTimeout(() => {
        if (changes.error.currentValue) {
          this.clearError.emit();
        }
      }, 4000);
    }
  }

  ngAfterViewInit() {}

  get class() { 
    
    return {
      "error": this.type == "error",
      "success": this.type == "success",
      hidden:
        this.content.nativeElement.innerHTML.trim() == undefined || !this.error
    };
  }
  removeToast(){
    clearInterval(this.errorTimeout)
    this.clearError.emit();
  }

}
