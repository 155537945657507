import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentRequestComponent } from './payment-request.component';
import { NgxStripeModule } from 'ngx-stripe';
// import { StripeModule } from '../stripe.module';

@NgModule({
  declarations: [PaymentRequestComponent],
  imports: [
    CommonModule,
    NgxStripeModule,
    // StripeModule,
  ],
  exports: [PaymentRequestComponent],
})
export class PaymentRequestModule {}
