import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetCookieRoutingModule } from './set-cookie-routing.module';
import { SetCookieComponent } from './set-cookie.component';

@NgModule({
  declarations: [SetCookieComponent],
  imports: [CommonModule, SetCookieRoutingModule],
  exports: [SetCookieComponent],
})
export class SetCookieModule {}
