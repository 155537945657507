<div class="top-team">
  <div fxLayout="column">
    <h2 class="py-5">
      {{ page == 'fundraiser' ? 'Recently Joined Team Members' : 'Top Teams' }}
    </h2>

    <div *ngIf="(teams$ | async)?.length; else noTeams">
      <div *ngIf="teams$ | async as teams">
        <mat-card
          appearance="outlined"
          class="mat-elevation-z0 mb-5 py-6 relative"
          *ngFor="let team of teams; let i = index"
        >
          <mat-card-content
            class="text-center"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutGap.xs="5px"
          >
            <div class="relative">
              <givebrite-user-logo-avatar
                [logo]="team?.user?.profile_image"
                [name]="team.user?.first_name + ' ' + team?.user.last_name"
                [border]="true"
                width="55px"
              ></givebrite-user-logo-avatar>
            </div>
            <div
              class="top-avataar"
              fxLayout="row"
              fxLayoutAlign="center center"
              *ngIf="page !== 'fundraiser'"
            >
              {{ i + 1 }}
            </div>

            <div fxLayout="column" fxLayoutAlign="center start" fxFlex="70%" class="ml-2">
              <h3 class="text-left font-medium mb-0">
                {{ team.name | titlecase | truncate: 30 }}
              </h3>

              <h4 fxLayout="row" fxLayoutAlign="center center" class="mb-2">
                {{ team.statistics.raised | currency: team.currency:'symbol':'1.0-0' }}
                |
                {{
                  page == 'fundraiser'
                    ? team.statistics.donors + ' ' + 'Donors'
                    : team.statistics.fundraisers + ' ' + 'Members'
                }}
              </h4>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- <div class="py-5" fxLayoutAlign="center center">
        <a mat-raised-button color="warn" class="btn-xl join w-full"
          >Join Team</a
        >
      </div> -->
    </div>

    <ng-template #noTeams>
      <div>No Top teams to show</div>
    </ng-template>
  </div>
</div>
