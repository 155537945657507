import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import icAdd from '@iconify/icons-ic/baseline-add';
import { AddEditAddressDialogComponent } from '../dialogs/add-edit-address-dialog/add-edit-address-dialog.component';
import { UserRg, UserAddress, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { UserState } from 'libs/data/src/lib/user/store/user.state';
import { User } from 'libs/data/src/lib/user/user.interface';

@Component({
  selector: 'address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressListComponent implements OnInit {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  @Select(UserState.user) user$: Observable<User>;
  @Select(UserStateRg.getAddresses) addresses$: Observable<UserAddress[]>;
  @Select(UserStateRg.getSelectedAddress) selectedAddress$: Observable<UserAddress>;
  addresses: UserAddress[] = [];
  @Input() addAutomatically: boolean;
  @Input() selected: string;
  @Input() options: AddressListOptions = {
    addAddress: true,
    editAddress: true,
    deleteAddress: true,
    type: 'flat',
    padding: false,
  };
  @Output() selectedAddress: EventEmitter<UserAddress> = new EventEmitter();
  icAdd = icAdd;

  constructor(private store: Store, private dialog: MatDialog) {
    // this.store.dispatch(new GetAddresses());
  }

  ngOnInit() {
    this.addresses$.subscribe((addresses) => {
      if (addresses.length) {
        this.addresses = addresses; //.slice().sort(this.orderby("is_default", "desc"));
      }

      const defaultAddress = this.addresses.find((address) => {
        return address.is_default == 1;
      });

      if (defaultAddress) {
        if (!this.selected) this.selectedAddress.emit(defaultAddress);
      }
    });
  }

  orderby(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a?.hasOwnProperty(key) || !b?.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  addressSelected(address: UserAddress) {
    this.selectedAddress.emit(address);
    this.selected = address._id;
  }

  addAddress() {
    this.dialog.open(AddEditAddressDialogComponent, { width: '600px' });
  }
}

export interface AddressListOptions {
  addAddress: boolean;
  editAddress: boolean;
  deleteAddress: boolean;
  type: string;
  padding: boolean;
}
