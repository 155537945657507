import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CircularFundraisingProgressComponent } from './circular-fundraising-progress.component';

@NgModule({
  declarations: [CircularFundraisingProgressComponent],
  imports: [CommonModule, FlexLayoutModule, MatProgressSpinnerModule],
  exports: [CircularFundraisingProgressComponent],
})
export class CircularFundraisingProgressModule {}
