<div class="share-dialog">
  <givebrite-close-icon
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="end"
    (onClose)="closeDialog()"
  ></givebrite-close-icon>

  <mat-dialog-content>
    <h1 class="mb-4">Sharing is caring</h1>
    <p class="mb-4">Share with your friends and family and help them reach new heights!</p>

    <mat-divider></mat-divider>

    <div class="w-full" fxLayout="row wrap" class="py-4">
      <div
        *ngFor="let item of socials; last as last"
        fxFlex="20%"
        fxFlex.xs="25%"
        class="m-2 sm:m-4"
      >
        <a (click)="openLink(item.url, last)">
          <div
            class="share-dialog-body p-5 cursor-pointer"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <mat-icon
              [icIcon]="item.icon"
              class="mb-2"
              [ngStyle]="{ color: item.color }"
            ></mat-icon>
            <p>{{ item.name }}</p>
          </div>
        </a>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="w-full mt-5" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <mat-form-field appearance="none" class="share-dialog-input mt-2">
        <div matPrefix class="link">
          <mat-icon [icIcon]="link"></mat-icon>
        </div>
        <input
          matInput
          placeholder="{{ data.environment.app.url }}{{ data?.url || href }}"
          value="{{ data.environment.app.url }}{{ data?.url || href }}"
          type="text"
          class="setUrl"
          [readonly]="true"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
</div>
