import { Team } from './team.interface';

export interface Fundraiser {
  statistics: {
    raised: { type: number; default: 0 };
    currency: { type: string; default: 'GBP' };
    donors: { type: number; default: 0 };
  };
  social: {
    supporters: number;
    shares: number;
    likes: number;
  };
  status: string;
  is_deleted: boolean;
  charity: any;
  currency: string;
  goal: number;
  name: string;
  slug: string;
  user: {
    _id: string;
    profile_completed: boolean;
    profile_image: string;
    first_name: string;
    last_name: string;
  };
  description: string;
  active: boolean;
  dates: {
    start_date: string;
    end_date: string;
  };
  campaign: {
    statistics: {
      raised: number;
      donors: number;
      offline: number;
      average: number;
    };
    _id: string;
    name: string;
    slug: string;
    charity: {
      logo: {
        color: string;
        white: string;
      };
      _id: string;
      slug: string;
      name: string;
      cover: [];
      description: string;
      settings?: {
        pixel_id?: string;
      };
    };
  };
  cover: [
    {
      id: string;
      type: 'image' | 'video';
      thumbnail?: string;
      video?: {
        type: 'vimeo' | 'youtube';
        thumbnail: 'string';
        id: string;
      };
      is_old: boolean;
      image?: {
        cropped: {
          url: string;
          _id: string;
        };
        original: {
          url: string;
          _id: string;
        };
        cropperPosition: {
          x1: number;
          x2: number;
          y1: number;
          y2: number;
        };
      };
    }
  ];
  theme: {
    primary: string;
    secondary: string;
  };
  created_at: string;
  _id: string;
  team: Team;
}

export interface createFundraiser {
  goal: number;
  currency: string;
  charity: string;
  campaign: string;
  team: string;
  cover: [];
  name: string;
  slug: string;
  date: {
    start_date: string;
    end_date: string;
  };
  description: string;
  theme: {
    primary: string;
    secondary: string;
  };
}

export interface FundraiseOptions {
  name: string;
  icon: any;
  linkName: string;
}

export interface OfflineDonation {
  first_name: string;
  last_name: string;
  charity: string;
  campaign: string;
  amount: number;
  currency: string;
  is_anonymous: boolean;
  is_giftaid: boolean;
  message: string;
  payment_method: string;
  billing: {
    building_name_number: number;
    address_line_1: string;
    address_line_2: string;
    postcode: number;
    state: string;
    country: string;
  };
}
