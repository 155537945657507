import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { environment } from '../../environments/environment';

@Component({
  selector: 'givebrite-login-confirmation',
  templateUrl: './login-confirmation.component.html',
  styleUrls: ['./login-confirmation.component.scss'],
})
export class LoginConfirmationComponent implements OnInit {
  email: string;
  // assetPath = environment.assetPath;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.queryParams.email;
  }
}
