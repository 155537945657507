<div class="toolbar-user-profile">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div class="relative">
      <givebrite-user-logo-avatar
        [name]="name"
        width="55px"
        class="mat-elevation-z8 rounded-full z-10"
        [profileImage]="profileImage"
      ></givebrite-user-logo-avatar>
    </div>

    <div
      class="edit-profile absolute rounded-full cursor-pointer"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="edit(user)"
    >
      <span class="material-icons"> photo_camera </span>
    </div>

    <div>
      <ng-container
        >{{ user.first_name | titlecase }} {{ user.last_name | titlecase }}
        <p>{{ user.role | titlecase }}</p>
      </ng-container>
    </div>
  </div>
</div>
