import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "givebrite-campaign-content",
  templateUrl: "./campaign-content.component.html",
  styleUrls: ["./campaign-content.component.scss"]
})
export class CampaignContentComponent implements OnInit {
  form: FormGroup = this.fb.group({
    first_name: []
  });
  constructor(private fb: FormBuilder) {}

  ngOnInit() {}
}
