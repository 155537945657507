import {
  Component,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import * as GenerateUniqueId from 'generate-unique-id';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CropperPosition } from 'ngx-image-cropper';
@Component({
  selector: 'givebrite-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageCropperDialogComponent implements OnInit {
  _imageChangedEvent: any = '';
  @Input() set imageChangedEvent(imageChangedEvent) {
    if (imageChangedEvent) this._imageChangedEvent = imageChangedEvent;
  }
  @Input() dialog?: boolean;
  _imageFileUrl: any = '';
  croppedImage: any = '';
  cropperPosition: CropperPosition = {
    x1: undefined,
    x2: undefined,
    y1: undefined,
    y2: undefined,
  };
  _file: any;
  @Input() set file(file) {
    if (file) this._file = file;
  }
  @Input() set imageFileUrl(url) {
    if (url) this._imageFileUrl = url;
  }
  @Output() cropped: EventEmitter<any> = new EventEmitter<any>();
  @Input() ratio = 16 / 9;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    private fb: FormBuilder,
  ) {
    if (this.data) {
      this._imageChangedEvent = data.imageChangedEvent;
      if (data.file) {
        this._file = data.file;
        this._imageFileUrl = data.file.image.original.url;
        // this.cropperPosition = data.file.cropperPosition;
      }
      this.dialog = this.data.dialog;
    }
  }

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.cropped.emit(event);
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  ready: boolean;
  cropperReady() {
    // cropper ready
    this.ready = true;
    if (this._file) {
      this.cropperPosition = {
        x1: this._file.image.cropperPosition.x1,
        x2: this._file.image.cropperPosition.x2,
        y1: this._file.image.cropperPosition.y1,
        y2: this._file.image.cropperPosition.y2,
      };
      console.log(this.cropperPosition, this._file.cropperPosition);
    } else {
      // this.cropperPosition = {
      //   x1: 0,
      //   x2: 450,
      //   y1: 0,
      //   y2: 250,
      // };
    }
  }

  loadImageFailed() {
    // show message
  }

  crop() {
    this.dialogRef.close({ croppedImage: this.croppedImage, file: this._file });
  }
}
