<div class="content top-padding charityshares-content" role="main">
  <div class="d-block container charityshares-inner pt-4 pt-md-5">
    <div class="country-select text-center">
      <div class="d-inline-block country-box">
        <div class="image">
          <img src="https://s3.givebrite.com/qurbanigiving/share-indian-flag.png" alt="" />
        </div>
        <div class="country-content">
          <p>India</p>
        </div>
      </div>
      <div class="d-inline-block country-box">
        <div class="image">
          <img src="https://s3.givebrite.com/qurbanigiving/share-human.svg" alt="" />
        </div>
        <div class="country-content">
          <p>Human Appeal</p>
        </div>
      </div>
    </div>
    <div class="d-block be-part text-center pt-4 pt-md-5">
      <div class="heading">Be a part of something big!</div>
      <div class="content">
        If you can’t take a full Qurbani, we’ve got you covered. Now you can take a small share and
        be a part of something bigger. Help fundraise for this cause and provide more meals for
        families living in poverty
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center w-100 raised-part pt-4 pt-md-5">
      <div class="d-inline-block">
        <div class="raised-box">
          <div class="image text-center">
            <img src="https://s3.givebrite.com/qurbanigiving/share-animal.svg" alt="" />
            <div class="raised-text">Available 2345</div>
          </div>
        </div>
      </div>
      <div class="d-inline-block">
        <div class="raised-box pledged">
          <div class="pledged-content">
            <div class="d-block pledged-text text-center">Total Sheep Pledged</div>
            <label class="d-block">£2000</label>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block total-amount text-center pt-4 pt-md-5">
      <div class="headding">Total Amount Raised</div>
      <label>£50,000</label>
    </div>
    <div class="d-block text-center pt-4 pt-md-5 charities-btn">
      <div class="d-inline-block charities-btn-inner">
        <a class="btn btn-success"
          ><span><img src="https://s3.givebrite.com/qurbanigiving/heart-icon.png" alt="" /></span
          >Give Now</a
        >
        <a class="btn btn-danger"
          ><span><i class="fab fa-whatsapp"></i></span>Share with WhatsApp</a
        >
      </div>
    </div>
  </div>
</div>
