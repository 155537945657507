import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateSubscriptionComponent } from './update-subscription.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewCardModule } from '../../view-templates/view-card/view-card.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeModule } from './../../stripe/stripe.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PaymentMethodsModule } from '../../stripe/payment-methods/payment-methods.module';
import { AddPaymentMethodModule } from '../../stripe/add-payment-method/add-payment-method.module';
import { DisplayMessageModule } from '../../display-message/display-message.module';

@NgModule({
  declarations: [UpdateSubscriptionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ViewCardModule,
    MatListModule,
    MatRadioModule,
    IconModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NgxStripeModule,
    StripeModule,
    FlexLayoutModule,
    PaymentMethodsModule,
    AddPaymentMethodModule,
    MatDialogModule,
    DisplayMessageModule,
  ],
  exports: [UpdateSubscriptionComponent],
})
export class UpdateSubscriptionModule {}
