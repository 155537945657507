<form [formGroup]="form" class="payment-methods">
  <view-card [delay]="0" [type]="options.type" [nopadding]="options.padding" [loading]="loading$">
    <mat-list role="list">
      <mat-radio-group
        *ngIf="(payment_methods$ | async)?.length"
        formControlName="payment_method_id"
        aria-label="Select a payment method"
      >
        <mat-list-item *ngIf="!(payment_methods$ | async)?.length" role="listitem" class="add-new-card last">
          <span>You do not have any saved payment methods.</span>
        </mat-list-item>

        <mat-list-item
          [ngClass]="{ active: payment_method.payment_method_id == selectedPaymentMethod, last: last }"
          role="listitem"
          *ngFor="let payment_method of payment_methods$ | async; let last = last"
        >
          <mat-radio-button (change)="paymentUpdated(payment_method)" mat-line [value]="payment_method.payment_method_id">
            <ic-icon size="30px" class="card-icon" [icon]="brandIcon(payment_method.brand)"></ic-icon>
            <span class="ml-4 card-number">•••• {{ payment_method.last4 }}</span>
            <span class="ml-4 card-expiry">{{ payment_method.exp_month | number: "2.0" }} / {{ payment_method.exp_year }}</span>
          </mat-radio-button>

          <a *ngIf="options.editCard" mat-button class="action-button"
            ><mat-icon size="16px" [icIcon]="icEdit"></mat-icon> Edit</a
          >
          <a *ngIf="options.deleteCard" (click)="detach(payment_method)" mat-button class="action-button"
            ><mat-icon size="16px" [icIcon]="icDelete"></mat-icon> Delete</a
          >
        </mat-list-item>

        <mat-list-item role="listitem" class="add-new-card last" *ngIf="options.addCard">
          <mat-radio-button (change)="paymentUpdated(null)" mat-line>
            <span>Add new card</span>
          </mat-radio-button>
        </mat-list-item>
      </mat-radio-group>

      <div class="px-4">
        <add-payment-method
          [address]="address"
          (added)="paymentAdded($event)"
          *ngIf="!form.controls.payment_method_id.value && options.addCard"
          [id]="id"
          object="subscription"
        ></add-payment-method>
      </div>

      <div><ng-content></ng-content></div>
    </mat-list>
  </view-card>
</form>