import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddSectionComponent } from './campaign-add-section-placeholder.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [CampaignAddSectionComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [CampaignAddSectionComponent],
})
export class CampaignAddSectionPlaceholderModule {}
