import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { Appeal, GetAppeals } from '@givebrite/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-appeal-selector',
  templateUrl: './campaign-appeal-selector.component.html',
  styleUrls: ['./campaign-appeal-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignAppealSelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignAppealSelectorComponent,
    },
  ],
})
export class CampaignAppealSelectorComponent implements OnInit {
  appeals: Appeal[];

  onChange = (appealValue: string) => {};
  onTouched = () => {};
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getAppeals();
  }

  writeValue(appealId: string) {
    this.selectedAppealId = appealId;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const appealId = control.value;

    if (appealId === '') {
      return {
        mustNotBeEmpty: {
          appealId,
        },
      };
    }
  }

  /**
   * @description
   * GET APPEALS
   */
  getAppeals(): void {
    this.store
      .dispatch(new GetAppeals())
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        // console.log(response?.skt?.appeals);
        const appeals = response?.skt?.appeals;
        if (appeals) {
          this.appeals = appeals;
        } else {
          throw new Error('No appeal available!');
        }
      });
  }

  get selectedAppealId() {
    return this._selectedAppealId;
  }

  set selectedAppealId(value: string) {
    this._selectedAppealId = value;
    this.onChange(this.selectedAppealId);
  }

  private _selectedAppealId = '';
}
