import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroDialogComponent } from './hero-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@abhinavakhil/iconify-angular';

@NgModule({
  declarations: [HeroDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    IconModule,
  ],
  exports: [HeroDialogComponent],
})
export class HeroDialogModule {}
