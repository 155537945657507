import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousand',
})
export class ThousandPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}
  transform(input: any, currency: any, args: any): any {
    console.log(currency);
    let exp,
      suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      // const amount = this.getCurrency(input, currency);
      return input.toFixed(args);
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    const newAmount = (input / Math.pow(1000, exp)).toFixed(args);
    // const amount = this.getCurrency(newAmount, currency);

    return newAmount + suffixes[exp - 1];
  }

  getCurrency(amount: string, currency: string): string {
    return this.currencyPipe.transform(amount, currency, 'symbol', '1.0-0');
  }
}
