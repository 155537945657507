import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignUserDashboardComponent } from './campaign-user-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: CampaignUserDashboardComponent,
    children: [
      {
        path: 'fundraisers',
        data: { page: 'Fundraisers' },
        loadChildren: () =>
          import('./fundraisers/fundraisers.module').then((m) => m.FundraisersModule),
      },
      {
        path: 'campaigns',
        data: { page: 'Campaigns' },
        loadChildren: () => import('./campaigns/campaigns.module').then((m) => m.CampaignsModule),
      },
      {
        path: 'donations',
        data: { page: 'Donations' },
        loadChildren: () => import('./donations/donations.module').then((m) => m.DonationsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignUserDashboardRoutingModule {}
