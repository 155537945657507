<ng-container>
  <div
    class="wrapper"
    [ngClass]="{ nopadding: nopadding, card: type == 'card', loading: loading$ }"
    [ngStyle]="{ height: height }"
    class="{{ type }}"
  >
    <div class="overlay" *ngIf="loading$">
      <div class="spinner-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <mat-progress-spinner diameter="30" mode="indeterminate" color="primary"></mat-progress-spinner>
      </div>
    </div>

    <div [class.blurred]="loading$" class="loaded-content" [ngClass]="{ nopadding: nopadding, 'p-4': true }">
      <ng-container>
        <h2 *ngIf="title" [class.p-6]="nopadding" class="m-0 title pb-6">{{ title }}</h2>
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>

  <!--div [ngClass]="{ 'nopadding' : nopadding, 'card': type=='card' }" class="{{ type }} mb-6">
        <div class="progress-container" fxLayout="row" fxLayoutAlign="center center" class="py-6">
            <mat-progress-spinner diameter="30" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
        
        <ng-container>
            <h2 *ngIf="title" [class.p-6]="nopadding" class="m-0 title pb-6">{{ title }}</h2>
            <ng-content></ng-content>
        </ng-container>

        

    </div-->
</ng-container>
