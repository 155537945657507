<div class="top-leaderboard">
  <div
    [ngStyle]="{
      width: !even ? '170px' : '100px',
      padding: !even ? 'auto' : '25px 0px'
    }"
    class="text-truncate"
    [class.first-item]="index === 0"
    [class.third-item]="index === 2"
  >
    <div
      class="first rounded-full"
      fxLayout="row"
      fxLayoutAlign="center center"
      [class.first]="!even"
    >
      <div class="relative">
        <img src="assets/{{ images[index] }}" class="crown" />

        <div
          [class.avatar-border]="!even"
          (click)="fundraiserPage(fundraiser?.slug)"
          class="cursor-pointer"
        >
          <givebrite-user-logo-avatar
            [logo]="fundraiser?.user?.profile_image"
            [name]="
              topFundraisers
                ? fundraiser?.user?.first_name + ' ' + fundraiser?.user?.last_name
                : 'loading...'
            "
            [border]="even ? true : false"
            [width]="even ? '80px' : '110px'"
          ></givebrite-user-logo-avatar>
        </div>

        <div
          class="avataar"
          [class.first-avataar]="!even"
          [class.second-avataar]="even"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          {{ !even ? '1' : index === 0 ? '2' : '3' }}
        </div>
      </div>
    </div>

    <div class="text-center mt-4" [class.-mt-4]="!even">
      <h4
        class="text-truncate cursor-pointer font-medium"
        (click)="fundraiserPage(fundraiser?.slug)"
      >
        {{
          topFundraisers
            ? (fundraiser?.user?.first_name + ' ' + fundraiser?.user?.last_name | titlecase)
            : 'loading...'
        }}
      </h4>
      <p class="first-color text-truncate font-bold" [class.secondary-color]="even">
        {{ fundraiser?.statistics?.raised | currency: fundraiser?.currency:'symbol':'1.0-1' }}
      </p>
    </div>
  </div>
</div>
