import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { PopoverService } from '@givebrite/theming';
import { QurbaniGivingToolbarUserDropdownComponent } from './qurbani-giving-toolbar-user-dropdown/qurbani-giving-toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { UserService } from '@givebrite/data';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { User } from '@givebrite/data';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Select } from '@ngxs/store';
import { UserState } from '@givebrite/data';
import { MatDialog } from '@angular/material/dialog';
// import { LoginDialogComponent } from "@givebrite/core";

@Component({
  selector: 'qg-toolbar-user',
  templateUrl: './qurbani-giving-toolbar-user.component.html',
  styleUrls: ['./qurbani-giving-toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QurbaniGivingToolbarUserComponent implements OnInit, OnDestroy {
  @Select(UserState.user) user$: Observable<User>;
  dropdownOpen: boolean;
  icPerson = icPerson;

  constructor(
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    public store: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: QurbaniGivingToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ],
    });

    popoverRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  login() {
    // this.dialog
    //   .open(LoginDialogComponent, {
    //     disableClose: false,
    //     closeOnNavigation: false,
    //     width: '600px',
    //     data: { type: 'login' },
    //   })
    //   .afterClosed()
    //   .subscribe((user) => {
    //     return true;
    //   });
  }

  ngOnDestroy() {}
}
