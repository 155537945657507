import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { DonationTypeRg } from '../../donation';
import { AppServiceRg } from '../app.service';
import { GetDonationTypesRg, ResetErrors, SaveFormStateRg, SetError } from './app.actions';

export class AppStateModelRg {
  loading?: boolean;
  error?: any;
  donationTypes: DonationTypeRg[];
  settings: any;
  form: any;
}

@State<AppStateModelRg>({
  name: 'app',
  defaults: {
    loading: false,
    error: null,
    donationTypes: [],
    settings: null,
    form: null,
  },
})
@Injectable()
export class AppStateRg {
  constructor(private appService: AppServiceRg) {}

  @Selector()
  static errors(state: AppStateModelRg) {
    return state.error;
  }

  @Selector()
  static settings(state: AppStateModelRg) {
    return state.settings;
  }

  @Selector()
  static donationTypes(state: AppStateModelRg) {
    return state.donationTypes;
  }

  @Selector()
  static form(state: AppStateModelRg) {
    return state.form;
  }

  @Action(SetError)
  setError({ patchState }: StateContext<AppStateModelRg>, { error }) {
    patchState({ error: error });
  }

  @Action(SaveFormStateRg)
  saveFormState({ patchState }: StateContext<AppStateModelRg>, { form }: SaveFormStateRg) {
    patchState({ form: form });
  }

  @Action(ResetErrors)
  resetErrors({ patchState }: StateContext<AppStateModelRg>) {
    patchState({ error: null });
  }

  @Action(GetDonationTypesRg)
  getCharities({ patchState }: StateContext<AppStateModelRg>, { limit }) {
    patchState({ loading: true });

    return this.appService.donationTypes().pipe(
      tap((result) => {
        patchState({
          donationTypes: result,
          loading: false,
        });
      })
    );
  }
}
