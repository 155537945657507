import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';
import { ENVIRONMENT } from '@givebrite/data';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  _tenant = this.env.x_tenant_id;

  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT) private env: any
  ) {
    if (isPlatformServer(this.platformId)) {
    } else {
    }
  }

  getTenantForHostname(hostname: string): string {
    return this.getTenantForHost(hostname.split('.')[0]);
  }

  getTenantForHost(host: string): string {
    return '';
  }

  getTenant(): string {
    if (isPlatformServer(this.platformId)) {
      return this.request.baseUrl; // host on the server
    } else {
      return this._tenant;
    }
  }

  setTentant(x_tenant_id: string) {
    this._tenant = x_tenant_id;
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append('X-Tenant-ID', this.getTenant());
  }
}