import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { LocationState, RamadhanPrayerTimes, RamadhanStartEnd, RamadhanTimes } from './location';
import { ApiService } from 'libs/api/src/lib/api.service';

@Injectable({ providedIn: 'root' })
export class RamadhanService {
  @Select(LocationState.prayerTimes) prayerTimes$: Observable<RamadhanPrayerTimes[]>;

  public MINIMUM_AMOUNT = 5;
  public DEFAULT_AMOUNT = 10;
  public DEFAULT_CURRENCY = 'GBP';
  public SUBSCRIPTION_START_TIME = '23:00:00';
  //public RAMADHAN_END = null;
  public DATE_FORMAT = 'DD-MM-YYYY';
  public DATETIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';
  public RAMADHAN_START_MOMENT;
  public RAMADHAN_END_MOMENT;
  public ADJUSTER = 0;
  public ADJUST_END = 1;
  public TODAY = moment(new Date(), this.DATE_FORMAT);
  public prayerTimes;

  constructor(private store: Store, private http: ApiService) {
    this.prayerTimes$.subscribe((prayerTimes) => {
      if (prayerTimes) {
        this.prayerTimes = prayerTimes;
        console.log(this.prayerTimes);
        this.RAMADHAN_START_MOMENT = moment(this.RAMADHAN_START, this.DATE_FORMAT);
        this.RAMADHAN_END_MOMENT = moment(this.RAMADHAN_END, this.DATE_FORMAT);
      } else {
        console.warn('no prayer times');
      }
    });
  }

  get RAMADHAN_END() {
    return this.prayerTimes
      ? moment(
          this.prayerTimes[this.prayerTimes?.length - 1].date?.gregorian?.date,
          this.DATE_FORMAT
        )
          .add(this.ADJUST_END, 'days')
          .format(this.DATE_FORMAT)
      : '13-05-2020' + this.SUBSCRIPTION_START_TIME;
  }

  get RAMADHAN_START(): any {
    if (this.prayerTimes) {
      const start = this.prayerTimes[0]?.date?.gregorian?.date;
      // start = '12-04-202 1'; // Manually set
      return this.getRamadhanDayStartEnd(start)
        .start?.add(this.ADJUSTER, 'days')
        .add(1, 'hour')
        .format(this.DATETIME_FORMAT);
    } else {
      '22-03-2023' + this.SUBSCRIPTION_START_TIME;
    }
  }

  get remainingNights(): any {
    if (this.ramadhanStarted) {
      // Add in order to include the last date in the calculation
      let addDays = 1;
      // console.log(this.todayRamadhanDate)
      if (!moment().isBetween(this.todayRamadhanDate?.suhoor, this.todayRamadhanDate?.end)) {
        addDays = 1;
        //console.log(`${moment().format(this.DATE_FORMAT)} is between iftar and suhoor`);
      }
      return Math.ceil(
        moment(this.RAMADHAN_END, this.DATE_FORMAT).diff(this.TODAY, 'days', true) + addDays
      );
    }
  }

  days(startDate, interval) {
    // Add in order to include the last date in the calculation
    const addDays = 1;

    // if (this.ramadhanStarted) {
    //   // console.log(this.todayRamadhanDate)
    //   if (!moment().isBetween(this.todayRamadhanDate.suhoor, this.todayRamadhanDate.end)) {
    //     addDays = 1;
    //     //console.log(`${moment().format(this.DATE_FORMAT)} is between iftar and suhoor`);
    //   }
    //   return Math.ceil(
    //     moment(this.RAMADHAN_END, this.DATE_FORMAT).diff(this.TODAY, "days", true) + addDays
    //   );
    // } else {
    return Math.floor(
      Math.floor(
        moment(this.endDate, this.DATE_FORMAT).diff(
          moment(startDate ? startDate : moment(), this.DATE_FORMAT),
          'days'
        )
      ) /
        (interval || 1) +
        addDays
    );
    // }
  }

  /**
   * Get prayer times for given data
   *
   * @param {*} date
   * @returns {RamadhanPrayerTimes}
   * @memberof DonationDatesComponent
   */
  getTimesForDate(date): RamadhanPrayerTimes {
    return this.prayerTimes?.find((times) => {
      return moment(times?.date?.gregorian?.date, this.DATE_FORMAT).isSame(
        moment(date, this.DATE_FORMAT)
      );
    });
  }

  /**
   * Get the iftar and suhoor times of a given date
   *
   * @param {string} date
   * @returns {RamadhanTimes}
   * @memberof DonationDatesComponent
   */
  ramadhanDateTimings(date: string): RamadhanTimes {
    const day = this.getTimesForDate(date);
    if (!day) return null;
    return {
      iftar: day?.timings?.Maghrib,
      suhoor: day?.timings?.Fajr,
      hijriDay: +day?.date?.hijri?.day,
    };
  }

  /**
   * Get the end date and time of a given Ramadhan Date i.e. the suhoor time of the next day
   *
   * @param {string} [date=RAMADHAN_START]
   * @returns {moment.Moment}
   * @memberof DonationDatesComponent
   */
  getRamadhanDayStartEnd(date: string = this.RAMADHAN_START): RamadhanStartEnd {
    // console.log(date)

    const timings = this.ramadhanDateTimings(date);
    if (!timings) return null;

    return {
      suhoor: moment(`${date} ${timings?.suhoor}`, 'DD-MM-YYYY HH:mm'),
      start: moment(`${date} ${timings?.iftar}`, 'DD-MM-YYYY HH:mm'),
      end: moment(`${date} ${timings?.suhoor}`, 'DD-MM-YYYY HH:mm (ZZ)').add(1, 'day'),
      hijriDay: timings?.hijriDay,
    };
  }

  get todayRamadhanDate() {
    console.log('today', this.TODAY?.format(this.DATE_FORMAT));
    return this.getRamadhanDayStartEnd(this.TODAY?.format(this.DATE_FORMAT));
  }

  get startSubscriptionImmediately() {
    return this.todayRamadhanDate
      ? this.todayRamadhanDate?.suhoor.isAfter(this.TODAY) ||
          this.TODAY?.isAfter(this.todayRamadhanDate?.start)
      : false;
  }

  /**
   *
   */
  get defaultSubscriptionStartTime() {
    //return this.getRamadhanDayStartEnd(moment(this.RAMADHAN_START, this.DATE_FORMAT).format(this.DATE_FORMAT)).start;
    return moment(
      `${this.TODAY?.format(this.DATE_FORMAT)} ${this.SUBSCRIPTION_START_TIME}`,
      'DD-MM-YYYY HH:mm'
    );
  }

  /**
   * Check to see if Ramadhan has started
   */
  get ramadhanStarted() {
    return this.getRamadhanDayStartEnd()?.start?.isSameOrBefore(moment());
  }

  /**
   * Check to see if Ramadhan has started
   */
  get tenDaysStarted() {
    // console.log(
    //   moment(this.RAMADHAN_START, this.DATE_FORMAT).add('19', 'days').format(this.DATE_FORMAT)
    // );
    return this.getRamadhanDayStartEnd(
      moment(this.RAMADHAN_START, this.DATE_FORMAT)?.add('19', 'days')?.format(this.DATE_FORMAT)
    )?.end?.isSameOrBefore(moment());
  }

  /**
   * Check to see if Ramadhan has ended
   */
  get ramadhanEnded() {
    return this.getRamadhanDayStartEnd(this.RAMADHAN_END)?.end?.isBefore(moment());
  }

  /**
   * Get's the start date and time for the subscription
   *
   */
  get startDate() {
    console.log(this.RAMADHAN_START);
    console.log('ramadhan started: ', this.ramadhanStarted);
    console.log('start immediately: ', this.startSubscriptionImmediately);

    if (this.ramadhanStarted) {
      // Start ten minutes from now
      if (this.startSubscriptionImmediately) {
        // Return current time + 10min
        // console.log('start 10 minutes from now', moment().add('10', 'minutes').format("DD-MM-YYYY HH:mm:ss"))
        return moment().add('10', 'minutes').format('DD-MM-YYYY HH:mm:ss');
        // return null
        // return this.todayRamadhanDate.start.format("DD-MM-YYYY HH:mm:ss");
      } else {
        if (this.todayRamadhanDate) {
          // Start today at iftar time
          return this.todayRamadhanDate?.start?.format('DD-MM-YYYY HH:mm:ss');
        } else {
          // Handle error
          return undefined;
        }
      }
    } else {
      // Start on first of Ramadhan

      return `${this.RAMADHAN_START}`;
    }
  }

  /**
   * Get's the start date and time for the subscription
   *
   */
  get endDate() {
    let startDate = this.startDate;

    if (this.startSubscriptionImmediately) {
      startDate = moment().add('10', 'minutes').format('DD-MM-YYYY HH:mm:ss');
    }

    return `${this.RAMADHAN_END} ${moment(startDate, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')}`;
  }

  tenDaysStartDate(blessedNights = false) {
    //console.log(this.lastTenDaysStart);
    if (this.lastTenDaysStart?.isSameOrBefore(this.TODAY)) {
      //console.log("is in last ten nights");
      // For blessed nights, check if odd night then start tomorrow
      if (blessedNights && this.todayRamadhanDate?.hijriDay % 1) {
        //console.log("is blessed nights");
        return moment(
          `${moment().add(1, 'day').format(this.DATE_FORMAT)} ${this.SUBSCRIPTION_START_TIME}`,
          'DD-MM-YYYY HH:mm:ss'
        ).format('DD-MM-YYYY HH:mm:ss');
      } else if (this.startSubscriptionImmediately) {
        //console.log("is not blessed nights start straight away");
        return this.TODAY.format('DD-MM-YYYY HH:mm:ss');
      } else {
        //console.log("is not blessed nights start at 11pm");
        return this.defaultSubscriptionStartTime?.format('DD-MM-YYYY HH:mm:ss');
      }
    } else {
      //console.log("is not not in last nights, start on the last 10 nights");
      return this.lastTenDaysStart?.format('DD-MM-YYYY HH:mm:ss');
    }
  }

  get lastTenDaysStart() {
    return this.getRamadhanDayStartEnd(
      moment(this.RAMADHAN_START, this.DATE_FORMAT)?.add('20', 'days').format(this.DATE_FORMAT)
    )?.start;
    //console.log(this.getRamadhanDayStartEnd(RAMADHAN_START_MOMENT.add("20", "days").format(DATE_FORMAT)));
    return null; //this.getRamadhanDayStartEnd(RAMADHAN_START_MOMENT.add("20", "days").format(DATE_FORMAT)).start;
  }

  get twentySeventhDay() {
    const date = this.getRamadhanDayStartEnd(
      moment(this.RAMADHAN_START, this.DATE_FORMAT)?.add('26', 'days')?.format(this.DATE_FORMAT)
    )?.start;
    return date.format('DD-MM-YYYY HH:mm:ss');
  }

  get remainingHoursToDonate(): number {
    // Replace with today's date
    if (!this.ramadhanStarted) return null;
    return (
      this.getRamadhanDayStartEnd(moment().format(this.DATE_FORMAT))?.end?.diff(moment(), 'hours') -
      1
    );
  }

  ramadhanGregorianCalendar(year: number, month = 10) {
    return this.http.get(`/api/hToGCalendar/${month}/${year}`).pipe(map((res) => res.data));
  }

  prayerTimesByAddressDate(longitude: number, latitude: number, date?: string, timezone?: string) {
    return this.http
      .get(
        `aladhan/timingsByAddress/${moment(date).format(
          'DD-MM-YYYY'
        )}?address=${latitude},${longitude}&method=2&school=1&timezonestring=` //${params.timezone}
      )
      .pipe(map((res) => res?.data));
  }

  prayerTimesByAddressMonth(address, month = 8, year?: number) {
    if (!year) {
      year = +new Intl.DateTimeFormat('ar-TN-u-ca-islamic', { year: 'numeric' }).format(Date.now());
    }
    return this.http
      .get(`hijriCalendarByAddress?month=${month}&year=${year}&address=${address}`)
      .pipe(map((res) => res?.data));
  }
}
