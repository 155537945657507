// export class utilities {
//   //   static responsive(
//   //     url: string,
//   //     width: number = 75,
//   //     height: number = 75,
//   //     fit: string = "crop",
//   //     quality: number = 100
//   //   ) {
//   //     return url
//   //       ? `https://cdn.filestackcontent.com/${environment.fileStackApiKey}/resize=width:${width},height:${height},fit:${fit}/quality=value:${quality}/compress/${url}`
//   //       : "";
//   //   }

export function slugify(string) {
  if (!string) return '';

  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, ''); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
}

export function cleanHTML(html) {
  html = html.replace(new RegExp('<br>', 'g'), '');
  html = html.replace(new RegExp('<p></p>', 'g'), '');
  console.log(html);
  return html;
}

export function responsive(
  image,
  width?,
  height?,
  fit = 'max',
  key = 'ATwlgNAAsQsyfsNEw94Cgz',
  format = 'jpg'
) {
  let url = `https://cdn.filestackcontent.com/${key}/output=format:${format},quality:70/resize=`;
  url += width ? `width:${width},` : '';
  url += height ? `height:${height},` : '';
  url += `fit:${fit}/${image}`;
  console.log(url);
  return url;
}
