import { Component, OnInit, Input, Output, EventEmitter, Inject } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { map } from "rxjs/operators";

@Component({
  selector: "givebrite-leaderboard-item",
  templateUrl: "./leaderboard-item.component.html",
  styleUrls: ["./leaderboard-item.component.scss"]
})
export class LeaderboardItemComponent implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, @Inject("environment") private env) {}
  @Input() currency: string;
  @Input() count: number;
  @Input() raised: number;
  @Input() description: string;
  @Input() text: string;
  _avatar;
  loaded: boolean;

  @Input() set avatar(avatar) {
    if (avatar) {
      this._avatar = `https://cdn.filestackcontent.com/${this.env.fileStackApiKey}/resize=width:80,height:80,fit:clip/${avatar}`;
    }
  }
  @Input() position: number;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  isDesktop$ = this.breakpointObserver.observe(`(min-width: 676px`).pipe(map(state => state.matches));

  ngOnInit() {}

  itemClicked() {
    console.log("clicked");
    this.clicked.emit();
  }

  removeHTML(html) {
    return html.replace(/<[^>]*>/g, "");
  }
}
