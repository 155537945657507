import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule],
})
export class ApiModule {
  // static forRoot(): ModuleWithProviders<ApiModule> {
  //   return {
  //     ngModule: ApiModule,
  //     providers: [
  //       {
  //         provide: HTTP_INTERCEPTORS,
  //         useClass: HttpErrorInterceptor,
  //         multi: true,
  //       },
  //     ],
  //   };
  // }
}
