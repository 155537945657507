import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { Donation, DonationType } from './../../donation/donation.interface';
import {
  // GetCampaignFundraisers,
  GetCampaignUpdates,
  GetCampaignPhases,
  GetCampaignAuctions,
  GetCampaignDonations,
  GetCampaignStatistics,
  GetCampaign,
  GetCampaigns,
  SubmitForm,
  CreateCampaign,
  GetCampaignTeam,
  GetCampaignBlog,
  GetCampaignImpacts,
  UpdateCampaign,
  GetCampaignsForSearch,
  UpdateCampaignImpact,
  GetUserDonations,
  UpdateUserDonations,
  GetDonationTypes,
  UpdateLikeCount,
} from './campaign.actions';

import {
  Campaign,
  CampaignBlog,
  CampaignCreateForm,
  CampaignPhases,
  CampaignStatistics,
  CampaignTeam,
  CampaignUpdates,
} from '../campaign.interface';

import { CampaignService } from '../campaign.service';
import { catchError, tap } from 'rxjs/operators';
import { PaginationState } from '../../pagination/pagination.state';
import { Pagination } from '../../pagination/pagination.interface';
import { AddPagination } from '../../pagination/pagination.actions';
import { FundraiserState } from './fundraiser.state';
import { TeamState } from './team.state';
import { Auction } from '../../auction';
import { Loading } from '../../app';
import { DashboardStatistics } from '../../dashboard';
import { patch, updateItem } from '@ngxs/store/operators';

export class CampaignStateModel {
  loading?: boolean;
  error?: any;
  campaigns: Campaign[];
  statistics: Campaign;
  campaign: Campaign;
  donations: Donation[];
  updates: CampaignUpdates[];
  phases: CampaignPhases[];
  auctions: Auction[];
  form: {
    step1: any;
    step2: any;
    step3: any;
  };
  teamForm: {
    step1: any;
    step2: any;
    step3: any;
  };
  fundraiserForm: {
    step1: any;
    step2: any;
  };
  donation: Donation;
  teams: CampaignTeam[];
  campaignCreateForm: CampaignCreateForm;
  blogs: CampaignBlog[];
  impacts: Campaign[];
  dashboardStatistics: DashboardStatistics;
  pagination: Pagination;
  userDonations: Donation[];
  donationTypes: DonationType[];
  campaignDonatePagination: Pagination;
}

@State<CampaignStateModel>({
  name: 'campaign',
  // children: [FundraiserState, TeamState],
  defaults: {
    loading: true,
    error: null,
    campaigns: [],
    statistics: null,
    campaign: null,
    donations: [],
    updates: [],
    phases: [],
    auctions: [],
    form: null,
    teamForm: null,
    fundraiserForm: null,
    donation: null,
    teams: [],
    campaignCreateForm: null,
    blogs: [],
    impacts: [],
    dashboardStatistics: null,
    pagination: null,
    userDonations: null,
    donationTypes: [],
    campaignDonatePagination: null,
  },
})
@Injectable()
export class CampaignState {
  constructor(private campaignService: CampaignService) {}

  @Selector()
  static getCampaigns(state: CampaignStateModel) {
    return state.campaigns;
  }

  @Selector()
  static getCampaignStatistics(state: CampaignStateModel) {
    return state.statistics;
  }

  @Selector()
  static pagination(state: CampaignStateModel) {
    return state.pagination;
  }
  @Selector()
  static error(state: CampaignStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: CampaignStateModel) {
    return state.loading;
  }

  @Selector()
  static getCampaign(state: CampaignStateModel) {
    return state.campaign;
  }

  // @Selector()
  // static fundraisers(state: CampaignStateModel) {
  //   return state.fundraisers;
  // }

  // @Selector()
  // static teams(state: CampaignStateModel) {
  //   return state.teams;
  // }

  @Selector()
  static donations(state: CampaignStateModel) {
    return state.donations;
  }

  @Selector()
  static getCampaignUpdates(state: CampaignStateModel) {
    return state.updates;
  }

  static cupdates(state: CampaignStateModel) {
    return state.updates;
  }

  @Selector()
  static phases(state: CampaignStateModel) {
    return state.phases;
  }

  @Selector()
  static auctions(state: CampaignStateModel) {
    return state.auctions;
  }

  // @Selector()
  // static campaignCreateForm(state: CampaignStateModel) {
  //   return state.campaignCreateForm;
  // }

  @Selector()
  static getCampaignTeams(state: CampaignStateModel) {
    return state.teams;
  }

  @Selector()
  static getCharityId(state: CampaignStateModel) {
    return state.form['model']['charity'];
  }

  @Selector()
  static blogs(state: CampaignStateModel) {
    return state.blogs;
  }

  @Selector()
  static getImpacts(state: CampaignStateModel) {
    return state.impacts;
  }

  // dashboard
  @Selector()
  static dashboardCampaignsStatistics(state: CampaignStateModel) {
    return state.dashboardStatistics.campaigns;
  }

  @Selector()
  static userDonations(state: CampaignStateModel) {
    return state.userDonations;
  }

  @Selector()
  static campaignDonatePagination(state: CampaignStateModel) {
    return state.campaignDonatePagination;
  }

  @Action(GetCampaigns)
  getCampaigns(
    { patchState, dispatch }: StateContext<CampaignStateModel>,
    { filter_by, search, page, limit, created_at, zakatable, priority, title_sort, sort_by }
  ) {
    patchState({ loading: true });
    return this.campaignService
      .list(filter_by, search, page, limit, created_at, zakatable, priority, title_sort, sort_by)
      .pipe(
        tap((result: { campaigns: Campaign[]; pagination: Pagination; error }) => {
          if (result.error == undefined) {
            patchState({
              campaigns: result.campaigns,
              loading: false,
              pagination: result.pagination,
            });
            // dispatch(new AddPagination(result.pagination));
          } else {
            patchState({
              campaigns: [],
              loading: false,
              error: result.error,
              pagination: null,
            });
          }
        })
      );
  }

  @Action(GetCampaignStatistics)
  getCampaignStatistics({ patchState }: StateContext<CampaignStateModel>, { slug }) {
    return this.campaignService.stats(slug).pipe(
      tap((result: Campaign) => {
        patchState({
          statistics: result,
        });
      })
    );
  }

  @Action(GetCampaign)
  getCampaign({ patchState, dispatch }: StateContext<CampaignStateModel>, { slug, type }) {
    // dispatch(new Loading(true));
    return this.campaignService.overview(slug, type).pipe(
      tap(
        (result: Campaign) => {
          patchState({
            campaign: result,
          });
          // dispatch(new Loading(false));
        },
        (error) => {
          patchState({
            donations: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetCampaignDonations)
  getDonations(
    { patchState, dispatch }: StateContext<CampaignStateModel>,
    { limit, id, page, from_date, to_date }
  ) {
    patchState({ loading: true });
    return this.campaignService.donations(id, 'campaign', limit, page, from_date, to_date).pipe(
      tap(
        (result: { donations: Donation[]; pagination: Pagination }) => {
          patchState({
            donations: result.donations,
            campaignDonatePagination: result.pagination,
            loading: false,
          });
        },
        (error) => {
          patchState({
            donations: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetCampaignUpdates)
  getUpdates({ patchState }: StateContext<CampaignStateModel>, { id }) {
    return this.campaignService.updates(id).pipe(
      tap((result: CampaignUpdates[]) => {
        patchState({
          updates: result,
        });
      })
    );
  }

  @Action(GetCampaignPhases)
  getPhases({ patchState }: StateContext<CampaignStateModel>, { id }) {
    return this.campaignService.phases(id).pipe(
      tap((result: CampaignPhases[]) => {
        patchState({
          phases: result,
        });
      })
    );
  }

  @Action(GetCampaignAuctions)
  getAuctions({ patchState }: StateContext<CampaignStateModel>, { id }) {
    return this.campaignService.auctions(id).pipe(
      tap((result: Auction[]) => {
        patchState({
          auctions: result,
        });
      })
    );
  }

  @Action(CreateCampaign)
  createCampaign({ patchState, dispatch }: StateContext<CampaignStateModel>, { id, payload }) {
    patchState({ loading: true });
    // dispatch(new Loading(true));
    return this.campaignService.create(id, payload).pipe(
      tap((campaign) => {
        patchState({ loading: false, campaign: campaign });
        // dispatch(new Loading(false));
      })
    );
  }

  @Action(UpdateCampaign)
  updateCampaign(
    { patchState, dispatch }: StateContext<CampaignStateModel>,
    { charityId, campaignId, payload }
  ) {
    patchState({ loading: true });
    // dispatch(new Loading(true));
    return this.campaignService.update(charityId, campaignId, payload).pipe(
      tap((campaign) => {
        patchState({ loading: false, campaign: campaign });
        // dispatch(new Loading(false));
      })
    );
  }

  @Action(GetCampaignTeam)
  campaignTeam({ patchState }: StateContext<CampaignStateModel>, { limit }) {
    patchState({ loading: true });
    return this.campaignService.getCampaignTeam(limit).pipe(
      tap(
        (result: CampaignTeam[]) => {
          patchState({
            loading: false,
            teams: result,
          });
        },
        () => {
          patchState({
            teams: [],
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetCampaignBlog)
  getCampaignBlog({ patchState }: StateContext<CampaignStateModel>) {
    return this.campaignService.blogs().pipe(
      tap((result: CampaignBlog[]) => {
        patchState({
          blogs: result,
        });
      })
    );
  }

  @Action(GetCampaignImpacts)
  campaignImpacts({ patchState }: StateContext<CampaignStateModel>, { campaignId }) {
    return this.campaignService.impacts(campaignId).pipe(
      tap((result: Campaign[]) => {
        patchState({
          impacts: result,
        });
      })
    );
  }

  @Action(GetCampaignsForSearch)
  getCampaignsForSearch(
    { patchState, dispatch, getState }: StateContext<CampaignStateModel>,
    { filter_by, search, page, limit, created_at, zakatable, priority, title_sort, sort_by }
  ) {
    patchState({ loading: true });
    return this.campaignService
      .list(filter_by, search, page, limit, created_at, zakatable, priority, title_sort, sort_by)
      .pipe(
        tap((result: { campaigns: Campaign[]; pagination: Pagination; error }) => {
          if (result.error == undefined) {
            var campaignsData: Campaign[] = result.campaigns;
            // if (page > 1) {
            //   campaignsData = getState().campaigns.concat(result.campaigns);
            // }
            patchState({
              campaigns: campaignsData,
              loading: false,
              pagination: result.pagination,
              error: null,
            });
          } else {
            patchState({
              campaigns: [],
              loading: false,
              error: result.error,
              pagination: null,
            });
          }
        })
      );
  }

  @Action(UpdateCampaignImpact)
  updateImpact(ctx: StateContext<CampaignStateModel>, { payload }: UpdateCampaignImpact) {
    ctx.setState(
      patch({
        impacts: updateItem<any>((name) => {
          return name === payload.name;
        }, payload),
      })
    );
  }

  @Action(GetUserDonations)
  getUserDonations(
    { patchState }: StateContext<CampaignStateModel>,
    { limit, page, search, title_sort, sort_by, user_id }
  ) {
    patchState({ loading: true });
    return this.campaignService
      .getUserDonations(limit, page, search, title_sort, sort_by, user_id)
      .pipe(
        tap(
          (result: { donations: Donation[]; pagination: Pagination }) => {
            patchState({
              userDonations: result.donations,
              loading: false,
              error: null,
              pagination: result.pagination,
            });
          },
          (error) => {
            patchState({
              loading: false,
              error: error,
              pagination: null,
            });
          }
        )
      );
  }

  @Action(UpdateUserDonations)
  updateUserDonations({ patchState }: StateContext<CampaignStateModel>, { donationId, payload }) {
    patchState({ loading: true });
    return this.campaignService.updateUserDonations(donationId, payload).pipe(
      tap(() => {
        patchState({
          loading: false,
          error: null,
        });
      })
    );
  }

  @Action(GetDonationTypes)
  getDonationTypes({ patchState }: StateContext<CampaignStateModel>) {
    patchState({ loading: true });
    return this.campaignService.getDonationTypes().pipe(
      tap(
        (result) => {
          patchState({
            loading: false,
            donationTypes: result,
            error: null,
          });
        },
        (error) => {
          patchState({
            loading: false,
            donationTypes: null,
            error: error,
          });
        }
      )
    );
  }

  @Action(UpdateLikeCount)
  updateLikeCount({ patchState }: StateContext<CampaignStateModel>, { donationId, like }) {
    patchState({ loading: true });
    return this.campaignService.updateLikeCount(donationId, like).pipe(
      tap((result: Donation) => {
        patchState({
          donation: result,
          loading: false,
          error: null,
        });
      })
    );
  }
}
