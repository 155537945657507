import { CreateCampaignDto } from '../campaign.interface';

export class GetCampaigns {
  static readonly type = '[Campaigns] Get list of campaign';
  constructor(
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 8,
    public title_sort?: number,
    public sort_by: string = ''
  ) {}
}

export class GetCampaignStatistics {
  static readonly type = '[Campaigns] Get Campaign Statistics';
  constructor(public slug: string) {}
}

export class GetCampaign {
  static readonly type = '[Campaigns] Get Campaign';
  constructor(public slug: string, public type = 'slug') {}
}

// export class GetCampaignFundraisers {
//   static readonly type = '[Campaigns] Get Campaign Fundraisers';
//   constructor(public campaignId: string, public limit: number = 10) {}
// }

export class GetCampaignTeams {
  static readonly type = '[Campaigns] Get Campaign Teams';
  constructor(public campaignId: string, public limit: number = 10, public search: string = '') {}
}

export class GetCampaignUpdates {
  static readonly type = '[Campaigns] Get Campaign Updates';
  constructor(public id: string) {}
}

export class GetCampaignPhases {
  static readonly type = '[Campaigns] Get Campaign Phases';
  constructor(public id: string) {}
}

export class GetCampaignAuctions {
  static readonly type = '[Campaigns] Get Campaign Auctions';
}

export class GetCampaignDonations {
  static readonly type = '[Campaigns] Get Campaign Donations';
  constructor(
    public limit: number,
    public id: string,
    public page: number,
    public from_date?,
    public to_date?
  ) {}
}

export class SubmitForm {
  static readonly type = '[Campaigns] Submit Form';
  constructor(public payload: any) {}
}

export class CreateCampaign {
  static readonly type = '[Campaigns] Create';
  constructor(public id: string, public payload: CreateCampaignDto) {}
}

export class UpdateCampaign {
  static readonly type = '[Campaigns] Update';
  constructor(
    public charityId: string,
    public campaignId: string,
    public payload: CreateCampaignDto
  ) {}
}

export class GetCampaignTeam {
  static readonly type = '[Campaigns] Get Campaign Team';
  constructor(public limit: number = 10, type = 'slug') {}
}

export class GetCampaignBlog {
  static readonly type = '[Campaigns] Get Campaign Blog';
  constructor(
    public limit: number,
    public from_date: string = '2021-09-27',
    public to_date: string = '2021-12-02'
  ) {}
}

export class GetCampaignImpacts {
  static readonly type = '[Campaigns] Get Campaign Impacts';
  constructor(public campaignId: string) {}
}

export class GetCampaignsForSearch {
  static readonly type = '[Campaigns] Get list of campaign for search';
  constructor(
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 8,
    public title_sort?: number,
    public sort_by: string = '',
    public zakatable?: boolean
  ) {}
}

export class UpdateCampaignImpact {
  static readonly type = '[Campaigns] Update Campaign Impact';
  constructor(public payload: { name: any; amount: any }, index: number) {}
}

export class GetUserDonations {
  static readonly type = '[User Dashboard] User Donation';
  constructor(
    public limit: number = 10,
    public page: number = 1,
    public search?: any,
    public title_sort: number = 1,
    public sort_by: string = '',
    public user_id: string = ''
  ) {}
}

export class UpdateUserDonations {
  static readonly type = '[User Dashboard] Update User Donation';
  constructor(public donationId: string, public payload: any) {}
}

export class GetDonationTypes {
  static readonly type = '[Campaigns] Get Donation Types';
}

export class UpdateLikeCount {
  static readonly type = '[Campaigns] Update Like Count';
  constructor(public donationId: string, public like: number) {}
}
