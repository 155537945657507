<div class="search-users-container relative">
  <form fxLayout="column" fxLayoutAlign="center center">
    <div class="text-center header mt-5 w-full px-4">
      <h2 class="font-semibold text-center mb-0">Invite followers to join your team</h2>
      <h4 class="mb-5">Search and add users who have interacted with you</h4>
    </div>

    <mat-dialog-content>
      <div class="w-full my-6">
        <p class="mb-4">Select and add users</p>
        <mat-form-field class="w-full">
          <mat-label>Search by the username or name</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            matInput
            aria-label="Number"
            [formControl]="connectionUserFormControl"
            [matAutocomplete]="auto"
            placeholder="Search by the username or name"
          />

          <mat-autocomplete #auto="matAutocomplete" class="mat-elevation-z8">
            <ng-container *ngIf="followers.length">
              <mat-option *ngFor="let item of followers">
                <mat-card appearance="outlined" class="mat-elevation-z3">
                  <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div fxLayout="row">
                        <givebrite-user-logo-avatar
                          [logo]="item.follower.profile_image"
                          [border]="true"
                          width="45px"
                        ></givebrite-user-logo-avatar>

                        <h3 class="ml-4">
                          {{ item.follower.first_name + ' ' + item.follower.last_name }}
                        </h3>
                      </div>

                      <div fxLayout="row">
                        <mat-checkbox
                          type="checkbox"
                          class="checkbox-input"
                          (change)="selectedUser($event, item.follower._id)"
                          (click)="$event.stopPropagation()"
                        >
                        </mat-checkbox>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <div *ngIf="!followers.length">You don't have any followers that you can invite</div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center" class="w-full">
      <button mat-stroked-button (click)="closeDialog()">I will do it later</button>

      <button
        mat-raised-button
        color="primary"
        (click)="invite()"
        [disabled]="selectedFollowers.length === 0"
      >
        Invite Members
        <span
          *ngIf="selectedFollowers.length"
          class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full bg-white"
          >{{ selectedFollowers.length }}</span
        >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-dialog-actions>
  </form>
</div>
