import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GbProgressBarComponent } from './gb-progress-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [GbProgressBarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatProgressBarModule,
  ],
  exports: [GbProgressBarComponent],
})
export class GbProgressBarModule {}
