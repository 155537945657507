import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeColorsComponent } from './theme-colors.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ThemeColorsComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [ThemeColorsComponent],
})
export class ThemeColorsModule {}
