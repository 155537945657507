<div
  fxLayout="column"
  class="countdown my-8"
  fxLayoutAlign="center center"
  *ngIf="(timeLeft$ | async) && (timeLeft$ | async) as timeLeft"
  [style.background]="background"
>
  <div class="wrapper">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img class="mb-8" src="assets/{{ logo }}" />
    </div>

    <h2 class="mb-8 font-bold text-center title">
      We're going live on the
      <span>{{ liveDate }}</span>
    </h2>

    <div
      class="countdown-wrapper p-6 mat-elevation-z8 mb-2"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h2 class="mb-2 font-bold">اللهم إنا نبلغ ذو الحجة</h2>
      <h4 class="text-center mb-6">Oh Allah, allow us to reach {{ appFirstName }}</h4>

      <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="relative countdown-bi">
            <div class="countdown-text">{{ timeLeft.daysToDday }}</div>
          </div>
          <h3>Days</h3>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="relative countdown-bi">
            <div class="countdown-text">{{ timeLeft.hoursToDday }}</div>
          </div>
          <h3>Hours</h3>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="relative countdown-bi">
            <div class="countdown-text">{{ timeLeft.minutesToDday }}</div>
          </div>
          <h3>Minutes</h3>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="relative countdown-bi">
            <div class="countdown-text">{{ timeLeft.secondsToDday }}</div>
          </div>
          <h3>Seconds</h3>
        </div>
      </div>

      <a
        mat-raised-button
        color="primary"
        class="mt-8"
        [href]="environment.charitySignup"
        target="_blank"
      >
        A Charity? Sign Up Here
      </a>

      <p class="text-center mt-3 sm:mt-4">
        Automate your giving with {{ appFirstName + ' ' + appLastName }}
      </p>
    </div>

    <!-- <img src="assets/eid-mubarak.jpeg" class="eid-logo" /> -->
    <a mat-raised-button color="primary" class="mt-8 mb-4" (click)="automate()">{{ ctaTitle }}</a>
  </div>
</div>
