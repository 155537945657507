import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignThemeColorsComponent } from './campaign-theme-colors.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CampaignThemeColorsComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [CampaignThemeColorsComponent],
})
export class CampaignThemeColorsModule {}
