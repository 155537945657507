import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  constructor(private http: ApiService) {}
  /**
   * donation
   */
  donation(data) {
    return this.http.post('donation', data);
  }
  /**
   * get donation
   */
  donationSummary(id) {
    return this.http.get(`donation?_id=${id}&type=campaign`);
  }

  /**
   * Get donation Complete
   */
  getDonationComplete(id) {
    return this.http.get(`donation/${id}`);
  }

  getPaymentIntent(payload) {
    return this.http.post(`donation/intent`, payload).pipe(
      map(
        (response) => {
          return response;
        },
        catchError((error) => {
          return error;
        })
      )
    );
  }

  /**
   * donation update
   */
  donationUpdate(id, data) {
    return this.http.put(`donation?_id=${id}`, data);
  }

  processDonation(payload) {
    return this.http.post(`donation/`, payload);
  }

  processRecurringDonation(payload) {
    return this.http.post(`recurring/`, payload);
  }

  completeExpressCheckout(data) {
    return this.http.post('donation/paypal/ec-payment', data);
  }

  // addComment(
  //   payload: { donation?: string; comment: string } = {
  //     donation: '',
  //     comment: '',
  //   }
  // ) {
  //   const donationQueryParam = payload.donation ? `?donation_id=${payload.donation}` : '';
  //   return this.http.post(`donation/comment${donationQueryParam}`);
  // }

  addComment(payload) {
    // const donationQueryParam = payload.donation ? `?donation_id=${payload.donation}` : '';
    return this.http.post(`donation/comment`, payload);
  }

  reCaptchaTokenVerify(token) {
    return this.http.post(`payment/re-captcha`, { captcha: token });
  }

  paypalDonation(payload) {
    return this.http.post(`donation/paypal`, payload);
  }
}
