import { CampaignRg } from '../campaign';

export interface CharityRg {
  _id: string;
  id: number;
  name: string;
  email: string;
  campaigns: CampaignRg[];
  unregistered: {
    charities: [{}];
  };
  registered: any;
  slug?: string;
  default_currency?: string;
  theme?: {
    primary: string;
    secondary: string;
  };
  address: {
    postcode?: string;
  };
  projection?: any;
  bypass_connect: number;
  logo: {
    color: string;
    white: string;
  };
  settings?: any;
  description: string;
  //connected: Payment[],
  //subscribed: Plan,
  block: string;
  currency?: string;
  regno?: number;
  step?: number;
  default_card_provider?: number;
  opted_seperate_billing: number;
  gocardless_mandate: any;
  is_ramadhan_giving: boolean;
  ramadhan_giving_stats: {
    aggregate_count: number;
    aggregate_raised: number;
    count: number;
    raised: number;
  };
  donation_types: number[];
  is_featured: number;
  partner_programme: boolean;
  partners: [];
  gateways: any;
}
