import { Authenticate } from '../user.interface';

/**
 * Decodes the JWT token and sets the user payload
 */
export class SetUserFromToken {
  static readonly type = '[User] Login';
  constructor(public payload: string) {}
}

export class Populate {
  static readonly type = '[Auth] Populate';
  constructor() {}
}
