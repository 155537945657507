import { Authenticate, CreateUser, SocialUser, TextAuthenticate } from '../user.interface';

export class LoginSuccess {
  static readonly type = '[Auth] Login Succsss';
  constructor(public payload: { accessToken: string }) {}
}
export class LoginWithCharitySuccess {
  static readonly type = '[Auth] Login With Charity Succsss';
  constructor(public payload: any) {}
}
export class BackToSuperAdmin {
  static readonly type = '[Auth] Back To SuperAdmin';
  constructor() {}
}

export class LoginFailure {
  static readonly type = '[Auth] Login Failure';
  constructor(public payload: any) {}
}

export class Login {
  static readonly type = '[User] Login';
  constructor(public payload: Authenticate) {}
}

export class LoginWithCharity {
  static readonly type = '[User] Login With Charity';
  constructor(public payload: any) {}
}
export class LoginText {
  static readonly type = '[User] Login Text';
  constructor(public payload: TextAuthenticate) {}
}

export class LoginToken {
  static readonly type = '[User] Login Token';
  constructor(public payload: Authenticate) {}
}
export class VerifyLoginToken {
  static readonly type = '[User] Verify Login Token';
  constructor(public payload: Authenticate) {}
}

export class Register {
  static readonly type = '[User] Register';
  constructor(public payload: CreateUser) {}
}

export class ResetPassword {
  static readonly type = '[User] Reset Password';
  constructor(public payload: { email: string }, public resendEmail: boolean = false) {}
}

export class ResetPasswordSuccess {
  static readonly type = '[User] Reset Password Success';
  constructor(public payload: { email: string }) {}
}

export class ResetPasswordFailure {
  static readonly type = '[User] Reset Password Failure';
  constructor(public payload: any) {}
}

export class RegisterFailure {
  static readonly type = '[User] Register Failure';
  constructor(public payload: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SocialLogin {
  static readonly type = '[User] Social Login';
  constructor(public credentials: SocialUser) {}
}
export class SocialComplete {
  static readonly type = '[User] Social Complete';
  constructor(public credentials: SocialUser) {}
}

export class SetNewError {
  static readonly type = '[User] Set New Error';
  constructor(public error: string) {}
}

export class RemoveError {
  static readonly type = '[User] Remove Error';
  constructor() {}
}

export class SetNewPassword {
  static readonly type = '[User] Set New Password';
  constructor(public payload: { password: string }) {}
}
export class SetNewPasswordSuccess {
  static readonly type = '[User] Set New Password Success';
}

export class EmailVerification {
  static readonly type = '[User] Email Verification';
  constructor(public payload: { token: string; user: string }) {}
}

export class EmailVerificationSuccess {
  static readonly type = '[User] Email Verification Success';
  constructor(public payload: { accessToken: string }) {}
}
export class EmailVerificationFailure {
  static readonly type = '[User] Email Verification Success';
  constructor(public payload: any) {}
}
export class GetUserProfile {
  static readonly type = '[User] Get User Profile';
  constructor() {}
}

export class GetFollowers {
  static readonly type = '[User] Get Followers';
}

export class UploadUserMedia {
  static readonly type = '[Auth] Upload Media';
  constructor(public file: any, public charityId: string) {}
}

export class UploadUserProfileMedia {
  static readonly type = '[Auth] Upload Profile Media';
  constructor(public file: any, public userId: string) {}
}

export class UpdateUserProfile {
  static readonly type = '[Auth] Update User Profile';
  constructor(public payload: any) {}
}

export class RemoveAuthSuccessMessage {
  static readonly type = '[Auth] Remove Success Message';
  constructor() {}
}

export class CheckEmailFound {
  static readonly type = '[Auth] Check Email Is Exist';
  constructor(public payload : {email: string}) {}
}

export class Newsletter {
  static readonly type = '[Auth] Newsletter';
  constructor(public email: string) {}
}

export class CheckMobileFound {
  static readonly type = '[Auth] Check Mobile Is Exist';
  constructor(public payload : {mobile: string}) {}
}