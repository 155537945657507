import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignCardMobileComponent } from './campaign-card-mobile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { CharityLogoAvatarModule } from '../../charity/charity-logo-avatar/charity-logo-avatar.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [CampaignCardMobileComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    ProgressBarModule,
    MatCardModule,
    CharityLogoAvatarModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [CampaignCardMobileComponent],
})
export class CampaignCardMobileModule {}
