import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignAddImpactsComponent } from './campaign-add-impacts.component';
import { CampaignAddImpactsDialogComponent } from './campaign-add-impacts-dialog.component';
import { CampaignAddSectionPlaceholderModule } from './../campaign-add-section-placeholder/campaign-add-section-placeholder.module';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [CampaignAddImpactsDialogComponent],
  imports: [
    CommonModule,
    CampaignAddSectionPlaceholderModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    TruncateStringModule,
  ],
  exports: [CampaignAddImpactsDialogComponent],
})
export class CampaignAddImpactsDialogModule {}
