import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignImpactsComponent } from './campaign-impacts.component';
import { CampaignImpactListModule } from '../../campaign-impacts-manager/campaign-impact-list/campaign-impact-list.module';
// import { CampaignImpactManagerModule } from "../../campaign-impacts-manager/campaign-impact-manager.module";

@NgModule({
  declarations: [CampaignImpactsComponent],
  imports: [
    CommonModule,
    CampaignImpactListModule,
    // CampaignImpactManagerModule
  ],
  exports: [CampaignImpactsComponent],
})
export class CampaignImpactsModule {}
