export interface Donation {
  _id?: number;
  user: {
    profile_image: string;
    profile_completed: boolean;
  };
  billing: {
    country: string;
    title: string;
    first_name: string;
    last_name: string;
    postcode: string;
    building_name_number: string;
  };
  charity: {
    name: string;
    slug: string;
    _id: string;
  };
  campaign: {
    name: string;
    slug: string;
    _id: string;
  };
  is_giftaid: boolean;
  anonymous: boolean;
  comment: string;
  currency: string;
  amount: number;
  created_at: string;
  giftAmount?: number;
  tipAmount?: number;
  totalAmount?: number;
  first_name?: string;
  last_name?: string;
  is_anonymous?: boolean;
  offline?: boolean;
  mysqlID?: number;
  fees?: any;
  frequency?: string;
  impacts?: {
    amount: number;
    name: string;
  };
  liked_by?: string[];
  cart?: any;
}

export interface DonationForm {
  amount: number;
  frequency: string;
  currency: string;
  is_giftaid: boolean;
  // gift_amount?:number;
  comment: string;
  optin: {
    charity: boolean;
    app: boolean;
  };
  fees: {
    is_fee_covered: true;
    gift_amount: number;
    fee_amount: number;
    tip: 0.6;
    fee_percentage: null;
  };
  final_amount: number;
  _id: string;
  anonymous: boolean;
  charity: {
    name: string;
    slug: string;
    _id: string;
  };
  campaign: {
    name: string;
    slug: string;
    _id: string;
  };
}

export interface Gateway {
  payment_intent_id: string;
  client_secret: string;
  status: string;
}

export interface PaymentGateway {
  account_id: string;
  _id: string;
  logo: string;
  name: string;
}

export interface DonationOptions {
  name: string;
  value: string;
}

export interface ImpactAmount {
  amount: number;
  impact: string;
}

export interface AmountConfig {
  first: ImpactAmount;
  second: ImpactAmount;
  last: ImpactAmount;
  step: number;
}

export interface DonationType {
  name: string;
  display: string;
  code: string;
  default: boolean;
}

export interface Recaptcha {
  action: string;
  challenge_ts: string;
  hostname: string;
  score: number;
  success: boolean;
}
