import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginDialogComponent implements OnInit {
  email: string;
  error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string; user?: UserRg },
    private dialog: MatDialogRef<LoginDialogComponent>
  ) {
    if (data) {
      if (data.type) {
        this.type = data.type;
      }
    }
  }

  type = 'register';

  ngOnInit() {}

  forgotPassword($event) {
    this.email = $event;
    this.move('forgot');
    console.log('forgot');
  }

  move(type) {
    console.log('move', type);

    this.type = type;
    this.error = '';
  }

  loginExistingUser($event) {
    console.log('exists');
    this.email = $event;
    this.error = 'You already have an account with us, please login.';
    this.type = 'login';
  }

  loggedIn(user) {
    this.dialog.close(user);
  }
}
