import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Select, Store } from '@ngxs/store';
import { RegisterRg, SocialRegister, UserRg, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { fadeInUp400ms } from 'libs/theming/src/lib/animations/fade-in-up.animation';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'user-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [fadeInUp400ms],
})
export class RegisterComponent implements OnInit {
  form: FormGroup = this.fb.group(
    {
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      //name: ["mansoor khan", Validators.required],
      // type: ["individual", Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // password: [""],
      // passwordConfirm: [""]
      mobile: ['', [Validators.required]],
      token: [''],
    },
    {
      // validator: MustMatch('password', 'passwordConfirm')
    }
  );
  @Input() set email(email) {
    if (email) {
      this.form.get('email').setValue(email);
    }
  }
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  @Select(UserStateRg.getUser) user$: Observable<UserRg>;

  @Output() authenticated: EventEmitter<UserRg> = new EventEmitter();
  @Output() exists: EventEmitter<string> = new EventEmitter();
  @Output() setEmail: EventEmitter<string> = new EventEmitter();
  @ViewChild('password', { static: true }) passwordInput: ElementRef;

  inputType = 'password';
  visible = false;
  error$: BehaviorSubject<string> = new BehaviorSubject('');
  needsAccount: boolean;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  constructor(
    private store: Store,
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
    this.form.patchValue({
      email: this.activatedRoute.snapshot.queryParams.email,
      token: this.activatedRoute.snapshot.queryParams.token,
    });
  }

  ngOnInit() {
    this.user$.subscribe((user) => {
      if (user) {
        this.form.patchValue({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          token: user.token,
        });
      }
    });
    /*this.form.get("name").valueChanges.subscribe(name => {
      let first_name = name
        .split(" ")
        .slice(0, -1)
        .join(" ");
      let last_name = name
        .split(" ")
        .slice(-1)
        .join(" ");
      this.form.get("first_name").setValue(first_name);
      this.form.get("last_name").setValue(last_name);
    });*/
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('passwordConfirm').value;
    return password === confirmPassword ? null : { notSame: true };
  }

  makeEmail() {
    const strValues = 'abcdefg12345';
    let strEmail = '';
    let strTmp;
    for (let i = 0; i < 10; i++) {
      strTmp = strValues.charAt(Math.round(strValues.length * Math.random()));
      strEmail = strEmail + strTmp;
    }
    strTmp = '';
    strEmail = strEmail + '@';
    for (let j = 0; j < 8; j++) {
      strTmp = strValues.charAt(Math.round(strValues.length * Math.random()));
      strEmail = strEmail + strTmp;
    }
    strEmail = strEmail + '.com';
    return strEmail;
  }

  send() {
    if (this.form.valid) {
      // call social register complete api if token set
      if (
        this.form.get('token') &&
        this.form.get('token').value &&
        !this.activatedRoute.snapshot.queryParams.email
      ) {
        this.store.dispatch(new SocialRegister(this.form.value));
      } else {
        this.store.dispatch(new RegisterRg(this.form.value));
      }
    }

    // if(this.needsAccount){
    //   this.store.dispatch(new Register(this.form.value)).subscribe(
    //     store => {
    //       this.authenticated.emit(store.user.user);
    //     },
    //     error => {
    //       this.error$.next(error);
    //     }
    //   );
    // } else {
    //   this.store.dispatch(new UserExists(this.form.get("email").value)).subscribe(
    //     () => {
    //       this.exists.emit(this.form.get("email").value);
    //     },
    //     error => {
    //       this.needsAccount = true;
    //       this.passwordInput.nativeElement.focus();
    //       this.form.get('password').setValidators([Validators.required, Validators.minLength(6)])
    //       this.form.get('passwordConfirm').setValidators([Validators.required, Validators.minLength(6)])
    //       this.form.get('password').updateValueAndValidity()
    //       this.form.get('passwordConfirm').updateValueAndValidity()
    //     }
    //   );
    // }
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  socialAuthenticated(user) {
    this.authenticated.emit(user);
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
