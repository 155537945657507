import { Component, OnInit, Input } from '@angular/core';
import { UserRg } from 'libs/data/src/lib/ramadhan-giving';
import { Observable } from 'rxjs';

@Component({
  selector: 'donor-details-card',
  templateUrl: './donor-details.component.html',
  styleUrls: ['./donor-details.component.scss'],
})
export class DonorDetailsComponent implements OnInit {
  @Input() loading: Observable<boolean>;
  @Input() user: UserRg;
  constructor() {}

  ngOnInit() {}
}
