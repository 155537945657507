import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CurrencySymbolModule } from 'libs/theming/src/lib/pipes/currency-symbol/currency-symbol.module';
import { ImpactsComponent } from './impacts.component';

@NgModule({
  declarations: [ImpactsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencySymbolModule,
  ],
  exports: [ImpactsComponent],
})
export class ImpactsModule {}
