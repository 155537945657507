import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LogoutRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store, private router: Router) {
    this.store.dispatch(new LogoutRg()).subscribe((logout) => {
      this.router.navigate(['user', 'login']);
    });
  }

  ngOnInit() {}
}
