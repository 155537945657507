import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Page } from 'libs/data/src/lib/campaign/campaign.interface';
import { GetCampaignTeams } from 'libs/data/src/lib/campaign/store/team.actions';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { Team } from 'libs/data/src/lib/campaign/team.interface';
import { Pagination } from 'libs/data/src/lib/pagination/pagination.interface';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

export type TeamAppearance = 'list-view' | 'card-view';
const DEFAULT_APPEARANCE = 'list-view';

@UntilDestroy()
@Component({
  selector: 'givebrite-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent implements OnInit {
  @Select(TeamState.pagination) teamsPagination$: Observable<Pagination>;

  @Select(TeamState.teams)
  teams$: Observable<Team[]>;

  // SET APPEARANCE

  @Input() set appearance(appearance: TeamAppearance) {
    if (appearance) {
      this._appearance = appearance;
    }
  }

  get appearance(): TeamAppearance {
    return this._appearance;
  }

  // SET ID

  @Input() set id(id: string) {
    if (id) {
      this._id = id;
    }
  }

  get id(): string {
    return this._id;
  }

  // CURRENT PAGE
  @Input() set page(page: Page) {
    if (page) {
      this._page = page;
    }
  }

  get page() {
    return this._page;
  }

  limit: number;
  total: number;
  loading = false;
  searchFundraiser = '';
  private _appearance: TeamAppearance = DEFAULT_APPEARANCE;
  private _id: string;
  private _page: Page = 'campaign';

  teamList = [
    {
      profile_image: '',
      first_name: 'John',
      last_name: 'Smith',
    },
    {
      profile_image: '',
      first_name: 'John',
      last_name: 'Smith',
    },
    {
      profile_image: '',
      first_name: 'John',
      last_name: 'Smith',
    },
    {
      profile_image: '',
      first_name: 'John',
      last_name: 'Smith',
    },
  ];

  constructor(private store: Store, private router: Router) {
    this.teamsPagination$.pipe(untilDestroyed(this)).subscribe((pagination) => {
      if (pagination) {
        this.limit = pagination.limit;
        this.total = pagination.total;
      }
    });
  }

  ngOnInit(): void {
    this.getTeams(this.page, this.searchFundraiser);
  }

  get maxTeamsLimitReached() {
    return this.total <= this.limit;
  }

  get listViewAppearance() {
    return this.appearance == 'list-view';
  }

  get cardViewAppearance() {
    return this.appearance == 'card-view';
  }

  get campaign() {
    return 'campaign';
  }

  get fundraiser() {
    return 'fundraiser';
  }

  get team() {
    return 'team';
  }

  /**
   * @description
   * GET TEAMS
   * @param page
   * @param search
   */
  getTeams(page, search) {
    this.store.dispatch(new GetCampaignTeams(this.id));
    // switch (page) {
    //   case this.campaign:
    //     this.store.dispatch(new GetCampaignTeams(this.id));
    //     break;
    //   case this.team:
    //     // this.store.dispatch(new GetFundraisers(this.team, this.id, 1, 13, '', search));
    //     break;
    //   default:
    //     // this.store.dispatch(new GetFundraisers(this.campaign, this.id, 1, 7, 'desc', search));
    //     break;
    // }
  }

  /**
   * @desciption
   * LOAD MORE TEAM
   */
  moreTeams(load = 10) {
    this.loading = true;
    if (!this.maxTeamsLimitReached) {
      this.store.dispatch(new GetCampaignTeams(this.id, this.limit + load)).subscribe(() => {
        this.loading = false;
      });
    }
  }

  /**
   * @description
   * NAVIGATE TO TEAM PAGE
   * @param slug
   */
  teamPage(slug) {
    this.router.navigate([`team`], { queryParams: { team: slug } });
  }
}
