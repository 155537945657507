import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { CampaignAvatarUploaderModule } from '../../../../../../../core/src/lib/campaigns/campaigns-avatar-uploader/avatar-uploader.module';
import { EditProfileDialogComponent } from './edit-profile-dialog.component';

@NgModule({
  declarations: [EditProfileDialogComponent],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    CampaignAvatarUploaderModule,
  ],
  exports: [EditProfileDialogComponent],
})
export class EditProfileDialogModule {}
