import { DonationCartItem } from '../donation-cart.interface';

export class GetDonationCart {
  static readonly type = '[Donation Cart] Get';
  constructor() {}
}

export class LoadDonationCart {
  static readonly type = '[Donation Cart] Load Donation Cart';
}

export class SetCartId {
  static readonly type = '[Donation Cart] Set Donation Cart Id';
  constructor(private cartId: string) {}
}

export class CreateDonationCartSession {
  static readonly type = '[Donation Cart] Create Donation Cart Session';
}

export class AddDonationCartItem {
  static readonly type = '[Donation Cart] Add Donation Cart Item';
  constructor(private cartId: string, private payload: DonationCartItem) {}
}

export class GetDonationCartItem {
  static readonly type = '[Donation Cart] Get Donation Cart Item';
  constructor(private cartId: string) {}
}

export class UpdateDonationCartItem {
  static readonly type = '[Donation Cart] Update Donation Cart Item';
  constructor(private cartId: string, private itemId: string, private payload: string) {}
}

export class RemoveDonationCartItem {
  static readonly type = '[Donation Cart] Remove Donation Cart Item';
  constructor(private item: DonationCartItem) {}
}

export class DeleteDonationCartItem {
  static readonly type = '[Donation Cart] Delete Donation Cart Item';
  constructor(private cartId: string, private itemId: string) {}
}

export class UpdateDonationCartTotal {
  static readonly type = '[Donation Cart] Update Donation Cart Total';
}

export class UpdateQGDonationCartTotal {
  static readonly type = '[Donation Cart] Update Donation Cart Total';
}

export class ClearDonationCart {
  static readonly type = '[Donation Cart] Clear Donation Cart';
}

export class UpdateDonateCart {
  static readonly type = '[Donation Cart] Update Cart';
  constructor(public cart: any) {}
}
