import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';

@Component({
  selector: 'givebrite-fundraiser',
  templateUrl: './fundraiser.component.html',
  styleUrls: ['./fundraiser.component.scss'],
})
export class FundraiserComponent implements OnInit {
  @Input() set fundraiser(fundraiser: Fundraiser) {
    this._fundraiser = fundraiser;
  }

  get fundraiser(): Fundraiser {
    return this._fundraiser;
  }

  private _fundraiser: Fundraiser;

  @Input() set view(view: any) {
    this._view = view;
  }

  get view(): any {
    return this._view;
  }

  private _view: any = 'list-view';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  fundraiserPage(slug: string) {
    this.router.navigate([`fundraiser`], {
      queryParams: { fundraiser: slug },
    });
  }
}
