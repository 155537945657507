export const campaign = 'http://campaigns.givebrite.com/';
export const givrbrite = 'http://givebrite.local/';
export const donate = 'https://donate.givebrite.com/';
export const authentication = 'http://auth.givebrite.local:2000/';
export const createCampaign = 'http://campaigns.givebrite.com/create/';
export const donationFlow = 'https://donate.givebrite.com/nim/amount';
export const charityProfile = 'https://beta.givebrite.com/charity/nim/overview';
export const explore = 'http://dashboard.givebrite.com/onboarding';
export const terms = 'https://beta.givebrite.com/c/terms';
export const privacy = 'https://beta.givebrite.com/c/privacy';
