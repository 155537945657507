import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfflineDonationDialogComponent } from '../dialogs/offline-donation-dialog/offline-donation-dialog.component';

type Page = 'fundraiser' | 'team' | 'campaign';

@Component({
  selector: 'user-action-toolbar',
  templateUrl: './user-action-toolbar.component.html',
  styleUrls: ['./user-action-toolbar.component.scss'],
})
export class UserActionToolbarComponent implements OnInit {
  @Input() page: Page;
  @Input() editLink;

  @Input() set campaign(campaign) {
    this._charityId = campaign?.charity?._id;
    this._campaignId = campaign?._id;
  }

  @Input() set fundraiser(fundraiser) {
    this._charityId = fundraiser?.charity?._id;
    this._campaignId = fundraiser.campaign?._id;
  }

  @Input() set team(team) {
    this._charityId = team?.charity;
    this._campaignId = team.campaign?._id;
  }

  showToolbar = true;
  _charityId: string;
  _campaignId: string;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    this.showToolbar = window.scrollY < 770;
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  /**
   * @description
   * ADD OFFLINE DONATION
   */
  addOfflineDonation() {
    this.dialog.open(OfflineDonationDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      data: { page: this.page, _charityId: this._charityId, _campaignId: this._campaignId },
      autoFocus: false,
      restoreFocus: false,
    });
  }
}
