import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Campaign, Charity, DonationOptions, DonationType, GivebriteState } from '@givebrite/data';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie';
import { StripeService } from 'ngx-stripe';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class CheckoutService implements OnDestroy {
  public slug: string;
  public campaignSlug: string;
  public tipDefaultPercentage = 8;
  public activePage = 'amount';

  // Init step
  _step1Form = {
    charity: ['', Validators.required],
    slug: ['', Validators.required],
    campaign: [],
    team: [],
    fundraiser: [],
    amount: [10, [Validators.required, Validators.min(0)]], // Default to 100
    // slider_amount: [20, [Validators.required, Validators.min(0)]],
    frequency: ['one-off', Validators.required],
    currency: [this.currency],
    fees: this.fb.group({
      amount: [],
      is_fee_covered: [true],
      tip: [],
      fee_percentage: [this.tipDefaultPercentage],
    }),
    impacts: [],
    step: [1],
    is_zakat: [false],
    type: ['', Validators.required],
    is_anonymous: [false],
    cart: [[]],
  };

  // Detail Step
  _step2Form = {
    title: ['Mr'],
    email: [''],
    first_name: [''],
    last_name: [''],
    mobile: [''],
    paymentIntent: [],
    billing: this.fb.group({
      address: [],
      name: [],
      building_name_number: [''],
      postcode: [''],
      country: [this.country.toUpperCase()],
      address_line_1: [],
      address_line_2: [],
      town_or_city: [],
    }),
    is_giftaid: [false],
    comment: [],
    optin: this.fb.group({
      charity: [true],
      app: [true],
    }),
    step: [2],
  };

  // Payment Step
  _step3Form = {
    payment: this.fb.group({
      method: ['card', Validators.required],
      token: [],
    }),
    gateway: ['', Validators.required],
  };

  // Confirmation step
  // _step4Form = {
  //   comment: [],
  //   optin: this.fb.group({
  //     charity: [true],
  //     app: [true],
  //   }),
  //   step: [4],
  // };

  // _step5Form = {
  //   email: ['', Validators.required],
  //   // is_anonymous: [false],
  //   title: ['Mr', Validators.required],
  //   first_name: ['', Validators.required],
  //   last_name: ['', Validators.required],
  //   mobile: [''],
  //   step: [5],
  //   paymentIntent: [],
  //   billing: this.fb.group({
  //     address: [],
  //     name: [],
  //     building_name_number: ['', Validators.required],
  //     postcode: ['', Validators.required],
  //     country: [this.country],
  //     address_line_1: [],
  //   }),
  // };

  step1Form: FormGroup = this.fb.group(this._step1Form);
  step2Form: FormGroup = this.fb.group(this._step2Form);
  step3Form: FormGroup = this.fb.group(this._step3Form);
  // step4Form: FormGroup = this.fb.group(this._step4Form);
  // step5Form: FormGroup = this.fb.group(this._step5Form);
  form: FormGroup = this.fb.group({
    ...this._step1Form,
    ...this._step2Form,
    ...this._step3Form,
    // ...this._step4Form,
    // ...this._step5Form,
  });

  _charity: Charity;
  _campaign: Campaign;
  _gateway;
  _redirectUrl: string;

  donationOptions: DonationOptions[] = [
    {
      name: 'Monthly',
      value: 'month',
    },
    {
      name: 'Daily',
      value: 'day',
    },
    {
      name: 'Jummah',
      value: 'jummah',
    },
    {
      name: 'Weekly',
      value: 'week',
    },
  ];

  donationTypes: DonationType[] = [
    {
      name: 'General',
      display: 'General',
      code: 'General',
      default: true,
    },
    {
      name: 'Sadaqah',
      display: 'Sadaqah',
      code: 'Sadaqah',
      default: false,
    },
    {
      name: 'Lillah',
      display: 'Lillah',
      code: 'Lillah',
      default: false,
    },
    {
      name: 'Zakat',
      display: 'Zakat',
      code: 'zakat',
      default: false,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private cookies: CookieService,
    private stripeService: StripeService
  ) {
    /**
     * If amount changes update the fee amount based on the default fee
     */

    this.setTipAmount(this.form.get('amount').value, this.tipDefaultPercentage);

    this.step1Form
      .get('amount')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((amount) => {
        if (amount) {
          this.setTipAmount(amount, this.tipDefaultPercentage);
        }
      });

    this.form
      .get('payment.method')
      .valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((pm) => {
        if (pm === 'card') {
          this.form.get('title').setValidators(Validators.required);
          this.form
            .get('email')
            .setValidators(
              Validators.compose([
                Validators.required,
                Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
              ])
            );
          this.form.get('first_name').setValidators(Validators.required);
          this.form.get('last_name').setValidators(Validators.required);
          this.form.get('billing.postcode').setValidators(Validators.required);
          this.form.get('billing.building_name_number').setValidators(Validators.required);
          this.form.get('billing.address_line_1').setValidators(Validators.required);
          this.form.get('billing.town_or_city').setValidators(Validators.required);
          this.updateFormValidity();
        } else if (pm === 'paypal') {
          this.form.get('title').clearValidators();
          this.form
            .get('email')
            .setValidators(
              Validators.compose([
                Validators.required,
                Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'),
              ])
            );
          this.form.get('first_name').setValidators(Validators.required);
          this.form.get('last_name').setValidators(Validators.required);
          this.form.get('billing.building_name_number').clearValidators();
          this.form.get('billing.address_line_1').clearValidators();
          this.form.get('billing.town_or_city').clearValidators();
          this.form.get('billing.postcode').clearValidators();
          this.updateFormValidity();
        } else {
          this.form.get('title').clearValidators();
          this.form.get('email').clearValidators();
          this.form.get('first_name').clearValidators();
          this.form.get('last_name').clearValidators();

          // IF GIFT AID IS ENABLED
          if (this.step2Form.get('is_giftaid').value) {
            this.form.get('billing.building_name_number').setValidators(Validators.required);
            this.form.get('billing.address_line_1').setValidators(Validators.required);
            this.form.get('billing.town_or_city').setValidators(Validators.required);
            this.form.get('billing.postcode').setValidators(Validators.required);
          } else {
            this.form.get('billing.building_name_number').clearValidators();
            this.form.get('billing.address_line_1').clearValidators();
            this.form.get('billing.town_or_city').clearValidators();
            this.form.get('billing.postcode').clearValidators();
          }
          this.updateFormValidity();
        }
      });

    this.form
      .get('is_giftaid')
      .valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((is_giftaid) => {
        if (
          (is_giftaid && this.form.get('payment.method').value === 'googlePay') ||
          this.form.get('payment.method').value === 'applePay'
        ) {
          this.form.get('title').clearValidators();
          this.form.get('email').clearValidators();
          this.form.get('first_name').clearValidators();
          this.form.get('last_name').clearValidators();

          this.form.get('billing.building_name_number').setValidators(Validators.required);
          this.form.get('billing.address_line_1').setValidators(Validators.required);
          this.form.get('billing.town_or_city').setValidators(Validators.required);
          this.form.get('billing.postcode').setValidators(Validators.required);
          this.updateFormValidity();
        }
        // else {
        //   this.form.get('title').clearValidators();
        //   this.form.get('email').clearValidators();
        //   this.form.get('first_name').clearValidators();
        //   this.form.get('last_name').clearValidators();

        //   this.form.get('billing.building_name_number').clearValidators();
        //   this.form.get('billing.address_line_1').clearValidators();
        //   this.form.get('billing.town_or_city').clearValidators();
        //   this.form.get('billing.postcode').clearValidators();

        //   this.updateFormValidity();
        // }
      });

    // this.form
    //   .get('comment')
    //   .valueChanges.pipe(debounceTime(300))
    //   .subscribe((comment) => {
    //     this.form.get('comment').setValue(comment, { emitEvent: false });
    //   });

    merge(
      this.step1Form.valueChanges,
      this.step2Form.valueChanges,
      this.step3Form.valueChanges
      // this.step4Form.valueChanges
    ).subscribe((value) => {
      // console.log(value, this.step2Form.value);
      this.form.patchValue(value);
    });
  }

  /**
   * Default Form Group to be used in each step of the checkout
   */
  // public get form(): FormGroup {
  //   return this.fb.group(this.form);
  // }

  public get currency(): string {
    return this.store.selectSnapshot(GivebriteState.currency);
  }

  public get country(): string {
    return this.store.selectSnapshot(GivebriteState.country);
  }

  public set charity(charity: Charity) {
    if (charity) {
      this._charity = charity;
      this._gateway = this.gateway;
      this.step1Form.get('charity').setValue(charity._id);
      this.step1Form.get('slug').setValue(charity.slug);
      this.step3Form.get('gateway').setValue(this.gateway?.gateway?._id);
      this.step3Form
        .get('payment.method')
        .setValue(this.gateway?.gateway?.name == 'Paypal' ? 'paypal' : 'card');

      // this.form
      //   .get('frequency')
      //   .valueChanges.pipe(untilDestroyed(this))
      //   .subscribe((frequency) => {

      if (this.frequency == 'one-off') {
        if (!charity.bypass_connect) {
          this.stripeService.setKey(environment.stripe.pk, {
            stripeAccount: this.stripeAccountId,
          });
        }
      } else {
        this.stripeService.setKey(environment.stripe.pk);
      }
      // });

      if (this.formState) {
        this.setFormState();
      }

      this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.saveFormState();
      });
    }
  }

  public set campaign(campaign: Campaign) {
    if (campaign) {
      this._campaign = campaign;
      this.step1Form.get('campaign').setValue(campaign._id);
      this.step1Form.get('charity').setValue(campaign.charity._id);
    }
  }

  public set amount(amount) {
    if (amount) {
      this.step1Form.get('amount').setValue(amount);
    }
  }

  public set fundraiser(fundraiser) {
    if (fundraiser) {
      // let fundraiserormControl = new FormControl('', Validators.required);
      // this.step1Form.addControl('fundraiser', fundraiserormControl);
      // this.form.addControl('fundraiser', fundraiserormControl);
      // this.step1Form.get('fundraiser').setValue(fundraiser);
      this.step1Form.get('fundraiser').setValue(fundraiser);
    }
  }

  public set team(team) {
    if (team) {
      // let teamFormControl = new FormControl('', Validators.required);
      // this.step1Form.addControl('team', teamFormControl);
      // this.form.addControl('team', teamFormControl);
      // this.step1Form.get('team').setValue(team);
      this.step1Form.get('team').setValue(team);
    }
  }

  public set impact(impact) {
    if (impact) {
      this.step1Form.get('impacts').setValue(impact);
    }
  }

  public set page(page) {
    if (page) {
      this.activePage = page;
      this.cookies.put('page', this.activePage);
    }
  }

  public get page() {
    return this.cookies.get('page');
  }

  public get cart() {
    return this.page == 'cart';
  }

  public get charity(): Charity {
    return this._charity;
  }

  public get campaign(): Campaign {
    return this._campaign;
  }

  get gateway() {
    return (
      this.charity.gateways.find((g) => g.gateway.name == 'Stripe') || this.charity.gateways[0]
    );
  }

  public get stripeAccountId() {
    return this._gateway.account_id;
  }

  /**
   * Removes all data from the variables
   */
  reset() {
    this.resetAllForms();
    this.slug = this.charity = null;
    this.resetFormState();
  }

  get total() {
    const totalAmount: number =
      +this.form.get('fees').get('amount')?.value + this.form.get('amount').value;
    return totalAmount > 0 ? +totalAmount?.toFixed(2) : totalAmount;
  }

  public get currentStep() {
    return this.form.get('step').value;
  }

  navigateTo(page, currentRoute = this.route, returnPage?, goBack?: boolean): Promise<boolean> {
    const returnPageQueryParams = this.route.snapshot.queryParams.return;
    // console.log(page, returnPage, returnPageQueryParams);

    if (goBack) {
      page = `${this.slug}/${page}`;
    }

    if (returnPageQueryParams) {
      page = returnPageQueryParams;
    }

    //
    // return this.router.navigate(['../test'], { relativeTo: this.route });

    return this.router.navigate([`${page}`], {
      queryParams: { return: returnPage },
      relativeTo: currentRoute,
      queryParamsHandling: 'merge',
    });
  }

  setTipAmount(amount, percentage) {
    const tip = (amount * percentage) / 100;

    this.step1Form.get('fees').patchValue({
      amount: (amount * percentage) / 100,
      tip: tip,
      fee_percentage: percentage,
    });
  }

  setStep(step) {
    if (this.currentStep < step) {
      return this.form.get('step').setValue(step);
    }
  }

  saveFormState() {
    // console.log('saving form', this.form.value);
    this.cookies.putObject('donateForm', this.form.value || {});
  }

  setFormState() {
    this.step1Form.patchValue(this.formState);
    this.step2Form.patchValue(this.formState);
    this.step3Form.patchValue(this.formState);
    // this.step4Form.patchValue(this.formState);
    this.form.patchValue({
      ...this.formState,
      ...{
        ...this.formState,
        ...{ billing: { address: '' } },
      },
    });
  }

  resetFormState() {
    this.cookies.remove('donateForm');
  }

  get formState() {
    // console.log('getting cookie', this.cookies.getObject('donateForm'));

    const donateForm = this.cookies.get('donateForm');
    // console.log('formState', typeof donateForm, donateForm);
    if (typeof donateForm === 'undefined' || donateForm === null || donateForm === 'undefined') {
      return {};
    }
    return JSON.parse(donateForm);
  }

  get allStepsValid() {
    this.setFormState();

    return (
      this.step1Form.valid && this.step2Form.valid && this.step3Form.valid
      // && this.step4Form.valid
    );
  }

  set redirectUrl(url: string) {
    if (url) {
      this._redirectUrl = url;
      this.cookies.put('redirectUrl', url);
    }
  }

  get redirectUrl(): string {
    const redirectUrl = this.cookies.get('redirectUrl');
    return redirectUrl;
  }

  resetAllForms() {
    this.step1Form = this.fb.group(this._step1Form);
    this.step2Form = this.fb.group(this._step2Form);
    this.step3Form = this.fb.group(this._step3Form);
    // this.step4Form = this.fb.group(this._step4Form);
    // this.step5Form = this.fb.group(this._step5Form);
    this.form = this.fb.group({
      ...this._step1Form,
      ...this._step2Form,
      ...this._step3Form,
      // ...this._step4Form,
      // ...this._step5Form,
    });
    // this.form.reset();
  }

  get frequency() {
    return this.form.get('frequency').value;
  }

  frequencyName(frequency) {
    if (frequency == 'day') {
      return `daily`;
    } else if (frequency == 'month' || frequency == 'week') {
      return `${frequency}ly`;
    } else {
      return frequency;
    }
  }

  /**
   * @description
   * UPDATE FORM VALIDITY
   */
  updateFormValidity() {
    this.form.get('title').updateValueAndValidity();
    this.form.get('email').updateValueAndValidity();
    this.form.get('first_name').updateValueAndValidity();
    this.form.get('last_name').updateValueAndValidity();
    this.form.get('billing.building_name_number').updateValueAndValidity();
    this.form.get('billing.postcode').updateValueAndValidity();
  }

  ngOnDestroy() {}
}
