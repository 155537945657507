import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { NotFoundComponent } from "./not-found.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule],
  exports: [NotFoundComponent]
})
export class NotFoundModule {}
