import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { take, tap } from 'rxjs/operators';
import { LocationService } from '../location.service';
import { Country, RamadhanPrayerTimes } from '../ramadhan.interface';
import {
  GeocodePosition,
  GetLocation,
  GetPrayerTimes,
  ResetLocation,
  SetCountry,
  SetPosition,
} from './location.action';

export class LocationStateModel {
  loading?: boolean;
  error?: any;
  position: {
    longitude: number;
    latitude: number;
  };
  country: Country;
  prayerTimes: RamadhanPrayerTimes[];
  currency: string;
}

@State<LocationStateModel>({
  name: 'location',
  defaults: {
    loading: false,
    error: null,
    position: null,
    country: { name: 'United Kingdom', code: 'GB', currency: 'GBP' },
    prayerTimes: null,
    currency: null,
  },
})
@Injectable()
export class LocationState {
  constructor(private locationService: LocationService) {}

  @Selector()
  static errors(state: LocationStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: LocationStateModel) {
    return state.loading;
  }

  @Selector()
  static position(state: LocationStateModel) {
    return state.position;
  }

  @Selector()
  static currency(state: LocationStateModel) {
    return state.currency;
  }

  @Selector()
  static location(state: LocationStateModel) {
    return state;
  }

  @Selector()
  static country(state: LocationStateModel) {
    return state.country;
  }

  @Selector()
  static prayerTimes(state: LocationStateModel) {
    return state.prayerTimes;
  }

  @Action(GetLocation)
  getLocation({ patchState, dispatch, getState }: StateContext<LocationStateModel>) {
    patchState({ loading: true });
    return this.locationService.getUserPosition().pipe(
      take(1),
      tap(
        (pos) => {
          patchState({
            position: {
              longitude: pos.coords.longitude,
              latitude: pos.coords.latitude,
            },
          });

          //dispatch(new SetPosition(pos.coords.longitude, pos.coords.latitude));
        },
        (error) => {
          //dispatch(new SetCountry());
          patchState({
            country: { name: 'United Kingdom', code: 'GB', currency: 'GBP' },
            currency: 'GBP',
          });
          return error;
        }
      )
    );
  }

  @Action(SetPosition)
  setPosition({ patchState, dispatch }: StateContext<LocationStateModel>, { longitude, latitude }) {
    patchState({
      loading: false,
      position: {
        longitude: longitude,
        latitude: latitude,
      },
    });

    dispatch(new GeocodePosition());
  }

  @Action(SetCountry)
  setCountry(
    { patchState, dispatch }: StateContext<LocationStateModel>,
    { country = { name: 'United Kingdom', code: 'GB', currency: 'GBP' } }: SetCountry
  ) {
    patchState({ loading: true });
    patchState({
      country: country,
      currency: country.currency,
    });
  }

  @Action(GeocodePosition)
  geocodePosition({ patchState, getState, dispatch }: StateContext<LocationStateModel>) {
    patchState({ loading: true });
    const state = getState();

    const findResult = function (results, name) {
      const result = results.address_components.find((obj) => {
        return obj.types[0] == name;
      });
      return result ? result : null;
    };

    // return this.geo.geocode({ address: `${state.position.latitude}, ${state.position.longitude}` }).pipe(
    //   take(1),
    //   tap(results => {
    //     let country = countries.find(c => c.code == findResult(results[0], "country").short_name);

    //     // Country not supported
    //     if (!country) {
    //       patchState({
    //         country: { name: "United Kingdom", code: "GB", currency: "GBP" },
    //         currency: "GBP"
    //       });
    //       //eturn dispatch(new SetCountry());
    //     } else {
    //       patchState({
    //         country: { name: country.name, code: country.code, currency: country.currency },
    //         currency: country.currency
    //       });

    //       //dispatch(new SetCountry({ name: country.name, code: country.code, currency: country.currency }));
    //     }

    //     //patchState({ country: { name: country.name, code: country.code }, loading: false, currency: country.currency });
    //     //dispatch(new GetPrayerTimes(findResult(results[0], "country"), this.ramadhan.RAMADHAN_START));
    //   })
    // );
  }

  @Action(GetPrayerTimes)
  getPrayerTimes({ patchState }: StateContext<LocationStateModel>, { lat, long, month, date }) {
    patchState({ loading: true });
    return this.locationService.prayerTimesByAddressMonth(lat, long).pipe(
      tap((result) => {
        patchState({
          loading: false,
          prayerTimes: result,
        });
      }),
      (error) => {
        patchState({
          loading: false,
        });
        return error;
      }
    );
  }

  @Action(ResetLocation)
  resetLocatioon({ getState, setState }: StateContext<LocationStateModel>) {
    const state = getState();
    setState({
      ...state,
      prayerTimes: null,
    });
  }
}
