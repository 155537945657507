<div class="home-accordion mt-4">
  <!-- <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <div class="d-flex">FAQ’s</div>
      </ng-template>
      <ng-template ngbPanelContent>
        <strong>What is Qurbani Giving?</strong>
        <p>
          Qurbani Giving is a platform where muslims can easily fulfil their Qurbani obligation with
          easy to use tech.
        </p>

        <strong>How can I give to multiple charities?</strong>
        <p>You can only select one charity at a time.</p>

        <strong>How can I order Qurbani with family and friends?</strong>
        <p>
          Select a large animal and upon checkout input names from the names field added. 7 maximum
          (names)
        </p>

        <strong>Is your platform secure?</strong>
        <p>We use Stripe.com as our payment partner, one of the world's trusted gateways.</p>

        <strong>Do I need an account to order my Qurbani? </strong>
        <p>No, easily checkout with your email address without creating an account.</p>

        <strong>Can I order multiple Qurbanis? </strong>
        <p>Yes, easily select your Qurbani from your shopping cart.</p>

        <strong>How can my charity join the Qurbani Giving platform? </strong>
        <p>Please register your charity on https://givebrite.com/charity/claim</p>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <div class="d-flex">About</div>
      </ng-template>
      <ng-template ngbPanelContent>
        <p>
          We’re Give Brite an online fundraising and donation platform offering state of the art
          tech solely for the non profit sector.
        </p>
        <p>
          We’ve been working on this amazing product for a year now and we’re excited to onboard
          charity partners who are looking to offer donors an easy way to give their Qurbani and for
          a hassle free management of their Qurbani appeals.
        </p>

        <strong>Get in touch</strong>
        <p class="phone-email">
          <strong>
            <mat-icon>email</mat-icon>
            <a href="mailto:info@givebrite.com">info@givebrite.com</a></strong
          >
        </p>
      </ng-template>
    </ngb-panel>
  </ngb-accordion> -->

  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="accordion-panel">
        <mat-panel-title> <strong class="title">FAQ’s</strong></mat-panel-title>
      </mat-expansion-panel-header>
      <strong>What is Qurbani Giving?</strong>
      <p>
        Qurbani Giving is a platform where muslims can easily fulfil their Qurbani obligation with
        easy to use tech.
      </p>

      <strong>How can I give to multiple charities?</strong>
      <p>You can only select one charity at a time.</p>

      <strong>How can I order Qurbani with family and friends?</strong>
      <p>
        Select a large animal and upon checkout input names from the names field added. 7 maximum
        (names)
      </p>

      <strong>Is your platform secure?</strong>
      <p>We use Stripe.com as our payment partner, one of the world's trusted gateways.</p>

      <strong>Do I need an account to order my Qurbani? </strong>
      <p>No, easily checkout with your email address without creating an account.</p>

      <strong>Can I order multiple Qurbanis? </strong>
      <p>Yes, easily select your Qurbani from your shopping cart.</p>

      <strong>How can my charity join the Qurbani Giving platform? </strong>
      <p>Please register your charity on https://givebrite.com/charity/claim</p>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="mt-4">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header class="accordion-panel">
          <mat-panel-title> <strong class="title">About</strong></mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          We’re Give Brite an online fundraising and donation platform offering state of the art
          tech solely for the non profit sector.
        </p>
        <p>
          We’ve been working on this amazing product for a year now and we’re excited to onboard
          charity partners who are looking to offer donors an easy way to give their Qurbani and for
          a hassle free management of their Qurbani appeals.
        </p>

        <strong>Get in touch</strong>
        <p class="phone-email">
          <strong>
            <mat-icon>email</mat-icon>
            <a href="mailto:info@givebrite.com">info@givebrite.com</a></strong
          >
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
