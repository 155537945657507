<div class="dialog-container">
  <form fxLayout="column" fxLayoutAlign="center center" class="p-6 sm:p-8">
    <h2 class="font-semibold">{{ data.title }}</h2>
    <h4>{{ data.subTitle }}</h4>
    <!-- 
    <div class="w-full" *ngIf="sponsor">
      <givebrite-campaign-cover-uploader
        (images)="images($event)"
        [image]="data.sponsorImg"
      ></givebrite-campaign-cover-uploader>

      <mat-error
        class="mt-3"
        *ngIf="
          imageList.length == 0 &&
          this.sponsorForm.touched &&
          this.sponsorForm.dirty
        "
        >Please upload an image
      </mat-error>
    </div>

    <div class="w-full">
      <p class="mb-2 mt-4">{{ data.param1 }}</p>

      <mat-form-field class="w-full">
        <mat-label>
          Enter {{ !sponsor ? 'an amount for' : '' }} the {{ data.type }}
          {{ data.type == 'sponsor' ? 'name' : '' }}</mat-label
        >
        <input
          matInput
          placeholder="{{
            !sponsor
              ? 'Enter an amount for the ' + data.type
              : 'Enter your sponsor name'
          }}"
          required
          formControlName="{{ !sponsor ? 'amount' : 'name' }}"
        />
        <span matPrefix *ngIf="!sponsor">£ &nbsp;</span>
      </mat-form-field>
    </div>

    <div class="w-full">
      <p class="mt-4">{{ data.param2 }}</p>

      <mat-form-field class="w-full">
        <mat-label
          >Enter a {{ !sponsor ? 'name ' : 'url ' }} for the
          {{ data.type }}
        </mat-label>
        <input
          matInput
          placeholder="{{
            !sponsor
              ? 'Enter a name for the ' + data.type
              : 'e.g. www.companyname.com'
          }}"
          required
          formControlName="{{ !sponsor ? 'title' : 'url' }}"
        />
      </mat-form-field>
    </div> -->

    <div fxLayout="column" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="add(data.type)">
        {{ 'Add ' + data.type }} <mat-icon>chevron_right</mat-icon>
      </button>
      <p class="mt-5 cursor-pointer" (click)="closeDialog()">
        I will do it later
      </p>
    </div>
  </form>
</div>
