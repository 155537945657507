import { Component, OnInit } from '@angular/core';
import zakatIcon from '@iconify/icons-fa-solid/percent';
import orphansIcon from '@iconify/icons-fa-solid/child';
import educationIcon from '@iconify/icons-fa-solid/graduation-cap';
import ambulanceIcon from '@iconify/icons-fa-solid/ambulance';
import shelterIcon from '@iconify-icons/ic/sharp-night-shelter';
import sadaqahIcon from '@iconify-icons/emojione-monotone/letter-s';
import { AutoPlay } from '@egjs/flicking-plugins';
import { Plugin } from '@egjs/ngx-flicking';

@Component({
  selector: 'givebrite-most-donated-causes',
  templateUrl: './most-donated-causes.component.html',
  styleUrls: ['./most-donated-causes.component.scss'],
})
export class MostDonatedCausesComponent implements OnInit {
  plugins: Plugin[] = [new AutoPlay({ duration: 7000, direction: 'NEXT', stopOnHover: false })];

  mostDonatedList = [
    { title: 'most needy', icon: ambulanceIcon },
    {
      title: 'Homeless',
      icon: shelterIcon,
    },
    { title: 'zakat', icon: zakatIcon },
    { title: 'sadaqah', icon: sadaqahIcon },
    { title: 'orphans', icon: orphansIcon },
    {
      title: 'Education',
      icon: educationIcon,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
