import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignDonationsComponent } from './campaign-donations.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { UserLogoAvatarModule } from '../../user/user-logo-avatar/user-logo-avatar.module';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DateAgoPipeModule } from 'libs/theming/src/lib/pipes/date-ago-pipe/date-ago-pipe.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DonationItemListComponent } from './donation-item-list/donation-item-list.component';
import { DonationItemCardComponent } from './donation-item-card/donation-item-card.component';
import { DonationWordsSupportComponent } from './donation-words-support/donation-words-support.component';

@NgModule({
  declarations: [
    CampaignDonationsComponent,
    DonationItemListComponent,
    DonationItemCardComponent,
    DonationWordsSupportComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    UserLogoAvatarModule,
    IconModule,
    NgxSkeletonLoaderModule,
    DateAgoPipeModule,
  ],
  exports: [CampaignDonationsComponent],
})
export class CampaignDonationModule {}
