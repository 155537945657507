export class GetAppeal {
  static readonly type = '[Skt Charities] Get Appeal';
  constructor(public id: string, public type: string = '_id') {}
}

export class GetAppeals {
  static readonly type = '[Skt Charities] Get list of Appeals';
  constructor(public filter_by?, public value?, public is_featured?) {}
}

export class GetProject {
  static readonly type = '[Skt Charities] Get Project';
  constructor(public id: string, public type: string = '_id') {}
}

export class GetProjects {
  static readonly type = '[Skt Charities] Get Projects';
  constructor(public is_featured?) {}
}

export class GetMerchantSessionKey {
  static readonly type = '[Skt Charities] Get Merchant Session Key';
  constructor(private vendorName: string = 'sagepaydemo') {}
}

export class SagePayDonation {
  static readonly type = '[Skt Charities] Create Sage Pay Donation';
  constructor(private payload: any) {}
}

export class SagePay3dDonation {
  static readonly type = '[Skt Charities] Create Sage Pay 3d Donation';
  constructor(private payload: any) {}
}

export class ResetMsk {
  static readonly type = '[Skt Charities] Reset Msk';
}

export class GetVoluntaryOptions {
  static readonly type = '[Skt Charities] Get Voluntary Options';
  constructor(public contribution: string) {}
}
