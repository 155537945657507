// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'givebrite.local',
  endpoints: {
    charity: 'https://charity-testing-v2.herokuapp.com/v1',
    campaign: 'https://campaign-testing-v2.herokuapp.com/v1',
    payment: 'https://payment-testing-v2.herokuapp.com/v1',
    donation: 'https://payment-testing-v2.herokuapp.com/v1',
    auth: 'https://authentication-testing-v2.herokuapp.com/v1',
    mock: 'https://mock-gb-api.herokuapp.com/index.php/v1',
  },
  stripe: {
    pk: 'pk_test_Ij5gXIIt3n7DTi5GKwkyIDE4',
  },
  assetPath: 'assets/',
  app: {
    url: 'http://donate.givebrite.local:4200',
  },
  apps: {
    authentication: {
      url: 'http://auth.givebrite.local:2000',
    },
    campaigns: {
      url: 'http://campaigns.givebrite.local:4200',
    },
    dashboard: {
      url: 'http://dashboard.givebrite.local:2002',
    },
    home: {
      url: 'http://beta.givebrite.local:1000',
    },
  },
  recaptcha: {
    v3SiteKey: '6LdN3bEUAAAAAG2dcfePQlsFm96TT53jbUMX_nYK',
    // v2SiteKey: '6LfrbtsiAAAAANSaFnteisdSPtAqIuavpbYXQTgi',
    threshold: 0.5,
  },
  hcaptcha: {
    sitekey: '8a16770e-3123-43be-97d9-79a24d40a6b6',
  },
  pixelId: '225434343113453',
  addressLookup: {
    key: 'mejrvVU6PEuT-Smpo7Xr6w30268',
  },
  x_tenant_id: 'givebrite',
  GTM_ID: 'GTM-WF47HNV',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
