import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Campaign } from '@givebrite/data';
import { donate } from '@givebrite/theming';
import moment from 'moment';
@Component({
  selector: 'campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'gb-campaign-card',
  },
})
export class CampaignCardComponent implements OnInit {
  cover: string;
  @Input() set campaign(campaign: Campaign) {
    if (campaign) {
      this._campaign = campaign;
      // let cover = campaign.cover[0];

      if (campaign.cover) {
        if (campaign.cover[0]?.is_old) {
          this.cover = campaign.cover[0].image.original.url;
        } else {
          this.cover = campaign.cover[0]?.image?.cropped?.url;
        }
      }
    }
  }
  _campaign: Campaign;
  @Input() width: string = '100%';
  @Input() hide: boolean;
  @Input() donatePath;
  @Input() environment;

  thumbnailLoaded: boolean; // Whether the image has been loaded or no
  donate = donate;
  constructor() {}

  ngOnInit(): void {}

  get percentage() {
    return (this._campaign.statistics.raised / this._campaign.goal) * 100;
  }

  get donateUrl() {
    return this.donatePath + '/' + this._campaign.charity.slug + '?campaign=' + this._campaign._id;
  }

  get campaignPath() {
    return this.environment.apps.campaign.url;
  }

  goToCampaign() {
    // window.location.href = this.campaignPath + '?campaign=' + this._campaign.slug;
  }

  get daysRemaining() {
    if (this._campaign.dates.end_date) {
      return moment(this._campaign.dates.end_date).diff(moment(), 'days') + ' days left';
    } else {
      return 'Ongoing';
    }
  }
}
