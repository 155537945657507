import { Component, Input, OnInit } from '@angular/core';
import { Donation } from 'libs/data/src/lib/donation/donation.interface';

@Component({
  selector: 'givebrite-donation-words-support',
  templateUrl: './donation-words-support.component.html',
  styleUrls: ['./donation-words-support.component.scss'],
})
export class DonationWordsSupportComponent implements OnInit {
  @Input() donation: Donation;
  @Input() last: boolean;

  customStyle = {
    backgroundColor: '#F2F8FD',
    color: '#2A3742',
  };
  constructor() {}

  ngOnInit(): void {}

  /**
   * reply to the comments
   */
  reply(commentId: string) {
    // this.donationService.replyToComments(commentId);
  }

  getGiftAmount(amount) {
    return ((25 / 100) * amount).toFixed(2);
  }
}
