<div class="register  w-full h-full my-12 container" fxLayout="column" fxLayoutAlign="center center">
  <div @fadeInUp class="overflow-hidden w-full">
    <!-- <div class="text-left my-4" fxLayout="row">
      <user-social-login (authenticated)="socialAuthenticated($event)" class="w-full"></user-social-login>
    </div> -->

    <div [formGroup]="form" fxLayout="column" fxLayoutGap="16px">
      <div fxFlex="auto" fxLayout="column">
        <h2>Create Account</h2>
        <display-message class="mb-2" [error]="error$ | async"></display-message>

        <div fxLayout="row">
          <mat-form-field fxFlex="49%">
            <mat-label>First Name</mat-label>

            <input formControlName="first_name" matInput placeholder="First name" />
            <mat-error>
              {{ "First Name is required" }}
            </mat-error>
          </mat-form-field>
          <div fxFlex></div>
          <mat-form-field fxFlex="49%">
            <mat-label>Last Name</mat-label>
            <input formControlName="last_name" matInput placeholder="Last name" />
            <mat-error>
              {{ "Last Name is required" }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-form-field class="w-full tel mobile">
          <mat-label>Mobile Number</mat-label>
          <input
            matInput
            placeholder="Mobile Number"
            formControlName="mobile"
            type="number"
          />
          <mat-error *ngIf="form.get('mobile')?.errors?.required"
            >Mobile number is required</mat-error
          >
          <mat-error *ngIf="form.get('mobile').errors?.validatePhoneNumber"
            >Please enter vaild mobile number</mat-error
          >
        </mat-form-field>

        <!-- <mat-form-field fxFlex="grow" *ngIf="">
          <mat-label>First Name and Last Name</mat-label>
          <input formControlName="name" matInput required />
        </mat-form-field> -->

        <!-- <mat-form-field fxFlex="grow">
          <mat-label>E-Mail</mat-label>
          <input cdkFocusInitial tabindex="1" formControlName="email" matInput required />
        </mat-form-field> -->
        <!-- 
        <mat-form-field [class.hidden]="!needsAccount" fxFlex="grow">
          <mat-label>Password</mat-label>
          <input [type]="inputType" #password formControlName="password" matInput />
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon color="primary" *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
            <mat-icon color="primary" *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
          </button>
          <mat-hint>Click the eye to toggle visibility</mat-hint>
        </mat-form-field>

        <mat-form-field [class.hidden]="!needsAccount" fxFlex="grow">
          <mat-label>Password (Confirm)</mat-label>
          <input [type]="inputType" formControlName="passwordConfirm" matInput />
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon color="primary" *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
            <mat-icon color="primary" *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
          </button>
          <mat-hint>Please repeat your password from above</mat-hint>
        </mat-form-field> -->
        <div class="caption"></div>

        <div fxLayout="column" fxLayoutGap="14px" class="my-4">
          <p class="text-sm">
            Creating an account means you’re okay with our Terms of Service, Privacy Policy, and our
            default Notification Settings.
          </p>

          <mat-checkbox color="primary" type="checkbox" class="text-xs"
            >Yes please, opt me in to emails</mat-checkbox
          >
        </div>
        <button
          [disabled]="(loading$ | async) || !form.valid"
          (click)="send()"
          class="btn-large w-full"
          color="primary"
          mat-raised-button
          type="submit"
        >
          Sign up
        </button>
      </div>

      <div class="actions">
        <!-- VALID: {{ form.valid | json }} -->

        <p class="text-secondary text-center">
          Already have an account?
          <a href="#0" [routerLink]="['user/login']">Sign in here</a>
        </p>
      </div>
    </div>
  </div>
</div>
