import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { UserState } from 'libs/data/src/lib/user/store/user.state';
import { User } from 'libs/data/src/lib/user/user.interface';
import { EditProfileDialogComponent } from 'libs/theming/src/lib/layout/toolbar/toolbar-user/edit-profile-dialog/edit-profile-dialog.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'givebrite-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.scss'],
})
export class ProfileOverviewComponent implements OnInit {
  profileName: string;
  @Select(UserState.user) user$: Observable<User>;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.user$.pipe(untilDestroyed(this)).subscribe((userData) => {
      this.profileName = userData?.charity?._id
        ? userData?.charity?.name
        : userData?.first_name + ' ' + userData?.last_name;
    });
  }

  editProfile(user) {
    this.dialog.open(EditProfileDialogComponent, {
      panelClass: ['full-window-dialog', 'transparent-container'],
      maxWidth: '800px',
      data: { user },
    });
  }
}
