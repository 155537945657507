import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddressEvent,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Subscription } from 'libs/data/src/lib/ramadhan-giving';
import { StripeService } from 'ngx-stripe';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { PaymentError } from '../add-payment-method/add-payment-method.component';

@Component({
  selector: 'payment-request-button',
  templateUrl: './payment-request.component.html',
})
export class PaymentRequestComponent implements OnDestroy, OnInit {
  elementsOptions: StripeElementsOptions = {
    locale: 'es',
  };

  paymentRequestOptions;
  options = {
    style: {
      paymentRequestButton: {
        type: 'donate',
        theme: 'light',
        height: '58px',
        borderRadius: '50px',
      },
    },
  };

  created: boolean;

  @Input() id: number;
  @Input() set subscription(subscription: Subscription) {
    if (subscription) {
      // if (!this.created) {
      // const location = this.store.selectSnapshot(LocationState.country);
      // console.log('create button', subscription, location);

      this.paymentRequestOptions = {
        country: 'GB',
        currency: subscription.currency.toLowerCase(),
        requestPayerName: true,
        requestPayerEmail: true,
        // requestShipping: true,
        total: {
          label: `Subscription for ${subscription.days} days`,
          // amount: (subscription.amount + subscription.fees.fee_amount) * 100,
          amount: Math.ceil(
            (subscription?.total + subscription?.fees?.fee_amount * subscription?.days) * 100
          ),
        },
      };

      console.log(this.paymentRequestOptions);
      // this.created = true;
      // }
      // else {
      //   // if((subscription.amount + subscription.fees.fee_amount) * 100 !== this.paymentRequestOptions.total.amount){
      //     this.paymentRequestOptions = {
      //       total: {
      //         label: `Subscription for ${subscription.days} days`,
      //         // amount: (subscription.amount + subscription.fees.fee_amount) * 100,
      //         amount: 0

      //       }
      //     }
      //   // }
      // }
      // console.log(subscription);
    }
  }
  @Input() address;
  @Output() cancel = new EventEmitter();
  @Output() added = new EventEmitter();
  error$: BehaviorSubject<PaymentError> = new BehaviorSubject(null);
  @Output() error: EventEmitter<PaymentError> = new EventEmitter();
  @Input() stripeKey;
  intent;

  constructor(
    private http: HttpClient,
    private stripeService: StripeService,
    private store: Store
  ) {
    this.error$.pipe(untilDestroyed(this)).subscribe((error) => {
      this.error.emit(error);
    });
  }

  ngOnInit() {}

  onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    // Add billing address

    if (ev.paymentMethod) {
      ev.complete('success');

      // this.notify();
      this.added.emit(ev);
      // let address = ev.paymentMethod.billing_details.address
      // let addressPayload = {
      //   first_name: ev.payerName.split(' ').slice(0, -1).join(' '),
      //   last_name: ev.payerName.split(' ').slice(-1).join(' '),
      //   //mobile: ['', this.PhoneNumberValidator()],
      //   postcode: address.postal_code,
      //   building_name_number: '',
      //   street_name: '',
      //   address_line_1: address.line1,
      //   address_line_2: '',
      //   town: address.city,
      //   county: '',
      //   country: address.country,
      //   is_default: true,
      //   title: ''
      // }
    } else {
      ev.complete('fail');
    }

    // this.store
    //   .dispatch(new AddAddress(addressPayload))
    //   .pipe(untilDestroyed(this))
    //   .subscribe(
    //     store => {

    //       this.address = store.user.selectedAddress;
    //       this.store
    // .dispatch(
    //   new CreateSetupIntent({
    //     usage: "on_session",
    //     id: this.id,
    //     object: 'subscription'
    //   })
    // )
    // .pipe(untilDestroyed(this))
    // .subscribe(
    //   store => {
    //     if (store.user.intent) {
    //       this.intent = store.user.intent;
    //       // Create a new card token
    //       if (ev.paymentMethod) {
    //         // this.capturePaymentMethod(ev.paymentMethod.id);
    //           this.store
    //           .dispatch(
    //             new ConfirmSetupIntent({
    //               payment_method_id: ev.paymentMethod.id,
    //               setupintent_id: this.intent.id,
    //               address_id: this.address._id,
    //               usage : "off-session",
    //               default : true
    //             })
    //           )
    //           .pipe(untilDestroyed(this))
    //           .subscribe(
    //             store => {
    //               console.log(store)
    //               this.intent = null
    //               this.store.dispatch(new ExpireSetupIntent());

    //               ev.complete('success');

    //               // const payment_method = store.user.payment_methods.find(
    //               //   pm => pm.ev.paymentMethod.id == result.setupIntent.payment_method
    //               // );
    //               // this.notify();
    //               this.added.emit(ev.paymentMethod.id);
    //             },
    //             error => {
    //               console.log(error);
    //               this.intent = null
    //               this.store.dispatch(new ExpireSetupIntent());
    //               // this.rollbar.error(error);
    //               this.error$.next(this.formatErrorMessage(error));
    //               ev.complete('success');
    //               return of({
    //                 error: new Error('Error Confirming the payment'),
    //               });
    //             }
    //           );

    //       }

    //     }
    //   },
    //   error => {
    //     console.log(error);
    //     ev.complete('fail');
    //     this.error$.next(this.formatErrorMessage(error));
    //     this.store.dispatch(new ExpireSetupIntent());
    //     // this.rollbar.error(error);
    //     // this.error$.next(error);
    //   }
    // );
    //     },
    //     error => {
    //       ev.complete('success');
    //       this.error$.next(this.formatErrorMessage('Billing address failed to add'));
    //     }
    //   );
  }

  onShippingAddressChange(ev: PaymentRequestShippingAddressEvent) {
    // console.log(ev.shippingAddress);
    if (ev.shippingAddress.country !== 'US') {
      ev.updateWith({ status: 'invalid_shipping_address' });
    } else {
      // Replace this with your own custom implementation if needed
      fetch('/calculateShipping', {
        data: JSON.stringify({
          shippingAddress: ev.shippingAddress,
        }),
      } as any)
        .then((response) => response.json())
        .then((result) =>
          ev.updateWith({
            status: 'success',
            shippingOptions: result.supportedShippingOptions,
          })
        );
    }
  }

  capturePaymentMethod(payment_method_id) {
    // this.actions$.pipe(ofActionSuccessful(ConfirmSetupIntent))
    // .pipe(untilDestroyed(this))
    // .subscribe((pm) => {
    //   console.log(pm)
    //   this.expireSetupIntent();
    //   this.added.emit(pm);
    // });
    // this.actions$.pipe(ofActionErrored(ConfirmSetupIntent))
    // .pipe(untilDestroyed(this))
    // .subscribe((error) => {
    //   this.expireSetupIntent();
    //   this.rollbar.error(error);
    //   this.error$.next(this.formatErrorMessage(error));
    // });
  }

  onNotAvailable() {
    // Subscribe to this event in case you want to act
    // base on availability
    // console.log('Payment Request is not Available');
  }

  // createPaymentIntent(): Observable<PaymentIntent> {
  //   // Replace this with your own custom implementation
  //   // to perform a Payment Intent Creation
  //   // You will need your own Server to do that
  //   return this.http.post<PaymentIntent>(
  //     '/create-payment-intent',
  //     { amount: this.paymentRequestOptions.total.amount }
  //   );
  // }

  ngOnDestroy() {}

  formatErrorMessage(error) {
    return {
      message: error,
    };
  }
}
