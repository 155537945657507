import { CreateDonationDto } from '../donation.interface';

export class GetDonations {
  static readonly type = '[Subscriptions] Get';
  constructor(
    public charityId: number,
    public limit: number = 10,
    public page: number = 1,
    public filter: {} = { status: 'active' },
    public sort: string = 'asc',
    public column: string = '',
    public download: number = 0
  ) {}
}

export class GetDonation {
  static readonly type = '[Donation] Get';
  constructor(public id: number) {}
}

export class CreateDonation {
  static readonly type = '[Donation] Create';
  constructor(public payload: CreateDonationDto) {}
}

export class UpdateDonation {
  static readonly type = '[Donation] Update';
  constructor(public payload: CreateDonationDto) {}
}

export class RefundDonation {
  static readonly type = '[Donation] Cancel';
  constructor(public id: number) {}
}

export class MarkSuccess {
  static readonly type = '[Donation] Mark Sucess';
  constructor(public id: number) {}
}

export class UpdateTodo {
  static readonly type = '[Todo] Update';

  constructor(public payload: any, public id: number) {}
}

export class DeleteTodo {
  static readonly type = '[Todo] Delete';

  constructor(public id: number) {}
}

export class SetSelectedTodo {
  static readonly type = '[Todo] Set';

  constructor(public payload: any) {}
}
