export * from './relative-date-time/relative-date-time.module';
export * from './currency-symbol/currency-symbol.module';
export * from './country-name/country-name.module';
export * from './filter-search/filter-search.module';
export * from './truncate-string/truncate-string.module';
export * from './date-ago-pipe/date-ago-pipe.module';
export * from './name-pipe/name-pipe.module';
export * from './ordinal/ordinal.module';
export * from './thousand-pipe/thousand-pipe.module';
export * from './moment/moment.module';
// export * from './truncate/truncate.module';
export * from './color/color-fade.module';
