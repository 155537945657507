export class GetLocation {
  static readonly type = "[Location] Get Location";
}

export class SetCountry {
  static readonly type = "[Location] Set Country";
  constructor(public country?: { name: string; code: string; currency: string }) {}
}

export class SetPosition {
  static readonly type = "[Location] Set Postion";
  constructor(public longitude: number, public latitude: number) {}
}

export class GetPrayerTimes {
  static readonly type = "[Location] Get Prayer Times";
  constructor(
    public lat: number,
    public long: number,
    public month?: number,
    public year?: number
  ) {}
}

export class GeocodePosition {
  static readonly type = "[Location] Geocodoe Position";
}

export class ResetLocation {
  static readonly type = "[Location] Reset Location";
}
