import { CreateCharity, CreateCharityUser } from '../charity.interface';

export class CharityUserSignup {
  static readonly type = '[Charity] Charity User Signup';
  constructor(public payload: CreateCharityUser) {}
}

export class CharityStateError {
  static readonly type = '[Charity] Charity State Error';
  constructor(public error: any) {}
}
export class CharitySignup {
  static readonly type = '[Charity] Charity Signup';
  constructor(public payload: CreateCharity) {}
}

export class GetCharities {
  static readonly type = '[Charities] List';
  constructor(
    public filter_by: string = '',
    public search: string = '',
    public page: number = 1, // public limit: number = 10, // public status: string = "", // public zakatable: string = "", // public priority: string = "high",
    public limit: number = 10, // public limit: number = 10, // public status: string = "", // public zakatable: string = "", // public priority: string = "high",
    public featured: number = 0
  ) {}
}

export class CharityLoginSuccess {
  static readonly type = '[Charity] Charity Login Success';
  constructor(public accessToken: string) {}
}
export class CharityLoginFailure {
  static readonly type = '[Charity] Charity Login Failure';
  constructor(public payload: string) {}
}
export class GetCharity {
  static readonly type = '[Charity] Get charity';
  constructor(public slug: string, public params = { type: '_id' }) {}
}

export class GetCampaignsByCharityId {
  static readonly type = '[Charity] Get Campaigns By Charity Id';
  constructor(
    public filter_by: string = '',
    public search: string = '',
    public page: number = 1,
    public limit: number = 10
  ) {}
}
export class GetFundraisersByCharityId {
  static readonly type = '[Charity] Get Fundraisers By Charity Id';
  constructor(public slug: string) {}
}

export class GetEventsByCharityId {
  static readonly type = '[Charity] Get Event By Charity Id';
  constructor(public id: string, public page: number = 1, public limit: number = 10) {}
}
export class ReSendCharityVerification {
  static readonly type = '[Charity] ReSend Charity verification';
  constructor() {}
}
export class GetPaymentGateways {
  static readonly type = '[Payment] Get Payment Gateways';
  constructor(public charityId: string) {}
}

export class GatewayUpdate {
  static readonly type = '[Payment] Payment Gateway Update';
  constructor(public payload: any, public gatewayId: any) {}
}

export class GetCharityList {
  static readonly type = '[Charity] Get Charity List';
  constructor(
    public params: {
      limit?: number;
      page?: number;
      filter_by?: string;
      value?: string;
      append?: boolean;
      slugs?: any;
    } = { limit: 6, page: 1, filter_by: 'name', append: true }
  ) {}
}
export class GetCharityCampaign {
  static readonly type = '[Charity] Get Charity Campaign';
  constructor(public charity_id: '1', public limit: 10) {}
}

export class GetFundraiserCampaign {
  static readonly type = '[Charity] Get Fundraiser Campaign';
  constructor(public user_id: '1', public limit: 10) {}
}

export class GetGalleryImageByCharityId {
  static readonly type = '[Charity] Get Gallery Image By Charity Id';
  constructor(public id: string) {}
}

export class UploadSingleFile {
  static readonly type = '[Charity] Upload File';
  constructor(public file, public charityId) {}
}

export class UpdateSingleFile {
  static readonly type = '[Charity] Update File';
  constructor(public file, public charityId, public fileId) {}
}
export class ConnectCharityGateway {
  static readonly type = '[Charity] Connect Charity Gateway';
  constructor(public charityId: string, public gatewayName: string, public data: any) {}
}
export class CharityUpdate {
  static readonly type = '[Charity] Charity Update';
  constructor(public payload: any) {}
}

export class GetCharitiesForSearch {
  static readonly type = '[Charities] Get Charities For Search';
  constructor(
    public filter_by: string = '',
    public search: string = '',
    public page: number = 1, // public limit: number = 10, // public status: string = "", // public zakatable: string = "", // public priority: string = "high",
    public limit: number = 8,
    public title_sort?: number,
    public sort_by?: string
  ) {}
}

export class GetCharitiesForApproval {
  static readonly type = '[Charities] Get Charities For Approval';
  constructor(public page: number = 1, public limit: number = 5) {}
}

export class GetSingleCharityForApproval {
  static readonly type = '[Charities] Get Charity For Approval';
  constructor(public charityId: string) {}
}

export class VerifyCharity {
  static readonly type = '[Charity] Verify Charity';
  constructor(public charityId: string, public type: string) {}
}

export class DownloadCharityDocument {
  static readonly type = '[Charity] Downlaod Charity Document';
  constructor(public documentLink: string) {}
}

export class RemoveCharityError {
  static readonly type = '[Charity] Remove Charity Error';
  constructor() {}
}

export class RemoveCharitySuccessMessage {
  static readonly type = '[Charity] Remove Success Message';
  constructor() {}
}

export class CreateCharityDocumentUpload {
  static readonly type = '[Charity] Create Charity Document Upload';
  constructor(public payload: any) {}
}
