<h2 mat-dialog-title>Add Phases</h2>

<mat-dialog-content class="dialog-container">
  <form fxLayout="column" [formGroup]="form" fxLayoutAlign="center center">
    <!-- <h4>{{ data.subTitle }}</h4> -->

    <div class="w-full">
      <!-- <p class="mb-2 mt-4">{{ data.param1 }}</p> -->

      <mat-form-field class="w-full">
        <mat-label> Impact Name </mat-label>
        <input
          matInput
          placeholder="Enter impact name"
          required
          formControlName="name"
        />
        <!-- <span matPrefix *ngIf="!phase">£ &nbsp;</span> -->
      </mat-form-field>
    </div>

    <div class="w-full">
      <!-- <p class="mb-2 mt-4">{{ data.param1 }}</p> -->

      <mat-form-field class="w-full">
        <mat-label> Impact amount </mat-label>
        <input
          matInput
          placeholder="Enter impact amount"
          required
          formControlName="amount"
        />
        <!-- <span matPrefix *ngIf="!phase">£ &nbsp;</span> -->
      </mat-form-field>
    </div>

    <!-- <div class="w-full">
      <p class="mt-4">{{ data.param2 }}</p>

      <mat-form-field class="w-full">
        <mat-label
          >Enter a {{ !phase ? 'name ' : 'url ' }} for the
          {{ data.type }}
        </mat-label>
        <input
          matInput
          placeholder="{{
            !phase
              ? 'Enter a name for the ' + data.type
              : 'e.g. www.companyname.com'
          }}"
          required
          formControlName="{{ !phase ? 'title' : 'url' }}"
        />
      </mat-form-field>
    </div> -->
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="column" fxLayoutAlign="center center">
  <button mat-raised-button color="primary" (click)="add()">
    Add Phases <mat-icon>chevron_right</mat-icon>
  </button>
  <button mat-button mat-dialog-close class="ml-3">I will do it later</button>
</mat-dialog-actions>
