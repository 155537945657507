export interface Team {
  statistics: {
    raised: { type: number; default: 0 };
    currency: { type: string; default: 'GBP' };
    donors: { type: number; default: 0 };
    fundraisers: number;
  };
  social: {
    supporters: number;
    shares: number;
    likes: number;
  };
  goal: number;
  description: string;
  currency: string;
  cover: any;
  name: string;
  logo: string;
  end_date: string;
  theme: {
    primary: string;
    secondary: string;
  };
  can_edit: boolean;
  user: {
    _id: string;
    profile_image: string;
    first_name: string;
    last_name: string;
  };
  dates: {
    start_date: string;
    end_date: string;
  };
  status: string;
  campaign: {
    name: string;
    status?: string;
    slug: string;
    _id: string;
    charity: {
      cover: [];
      logo: any;
      name: string;
      slug: string;
      _id: string;
    };
    dates: {
      start_date: string;
      end_date: string;
    };
  };
  _id: string;
  slug: string;
  invites: string;
}

export interface Leaderboard {
  type: string;
  data: {
    statistics: {
      raised: number;
      currency: string;
      donors: number;
    };
    _id: string;
    user: {
      _id: string;
      first_name: string;
      last_name: string;
      profile_image: string;
      profile_completed: boolean;
      verified: boolean;
    };
  };
}
