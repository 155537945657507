<mat-list-item (click)="addressSelected(address)" class="address-list-item py-3" [ngClass]="{ active: address._id == selected }">
  <div fxLayout="column" class="w-full">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-radio-button [checked]="address._id == selected"  (change)="addressSelected(address)" mat-line [value]="address._id"> </mat-radio-button>
      <div class="address" fxFlex="grow">
        <strong>{{ address.first_name }} {{ address.last_name }}</strong>
        <span mat-line class="address-display text-xs">
          {{ address.address_line_1 }}<br />
          {{ address.town }} {{ address.postcode }}, {{ address.country }}
        </span>
      </div>

      <div *ngIf="address._id == selected" class="default-container">
        <mat-icon color="primary">check_circle</mat-icon>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end" class="text-right">
      <div>
        <button *ngIf="options.editAddress" (click)="editAddress(address)" mat-menu-item color="warn">
          <span> Edit </span>
        </button>
      </div>
      <div>
        <button
          style="border-left: 1px solid #ccc; padding-left: 20px"
          *ngIf="options.deleteAddress"
          (click)="deleteAddress(address)"
          mat-menu-item
          color="warn"
        >
          <span> Delete </span>
        </button>
      </div>
      <button *ngIf="" (click)="defaultAddress(address)" mat-menu-item color="warn">
        <span> Mark as Default </span>
      </button>
    </div>
  </div>
</mat-list-item>
