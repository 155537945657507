import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from '../login/login.component';
import { ResetComponent } from '../forgot-password/reset/reset.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
import { SetCookieComponent } from '../set-cookie/set-cookie.component';

const routes: Routes = [
  {
    path: 'cookie',
    component: SetCookieComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'reset',
    component: ResetComponent,
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
  },
  {
    path: 'verify-login',
    loadChildren: () =>
      import('../verify-login/verify-login.module').then((m) => m.VerifyLoginModule),
  },
  {
    path: 'login-confirmation',
    loadChildren: () =>
      import('../login-confirmation/login-confirmation.module').then(
        (m) => m.LoginConfirmationModule
      ),
  },
  {
    path: 'email-not-receive',
    loadChildren: () =>
      import('../email-not-receive/email-not-receive.module').then((m) => m.EmailNotReceiveModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
