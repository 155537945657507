<div class="campaign-url">
  <mat-form-field appearance="outline" class="w-full">
    <input
      (change)="markAsTouched()"
      [(ngModel)]="url"
      matInput
      placeholder="Custom URL"
      required
      [disabled]="disabled"
    />
    <!-- <span class="url" matPrefix>{{ link }}</span> -->
    <mat-hint>{{ link }}{{ url }}</mat-hint>
  </mat-form-field>
</div>
