import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Charity, GetCharitiesForSearch } from '@givebrite/data';
import { CharityState, GetCharities } from '@givebrite/data';
import { Select, Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { environment } from 'apps/qurbani-giving/src/environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-morecharities',
  templateUrl: './morecharities.component.html',
  styleUrls: ['./morecharities.component.scss'],
})
export class MorecharitiesComponent implements OnInit, OnDestroy {
  charities: Charity[] = [];
  @Select(CharityState.getSearchCharity) charities$: Observable<Charity[]>;
  @Select(CharityState.loading) loading$: Observable<boolean>;
  filtercontent: Charity[] = [];
  isBrowser: boolean;

  constructor(
    private store: Store,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      const filter_by: string = '';
      const search: string = 'qurbani-giving';
      const page: number = 1;
      const limit: number = 100;

      this.store
        .dispatch(new GetCharities(filter_by, search, page, limit))
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          const charityList = res?.charity?.charities;
          if (charityList) {
            this.filtercontent = this.charities = charityList;

            this.filtercontent = this.charities = charityList
              .map((list) => {
                const qg = list?.projection.find(
                  (item) => item?.app?.slug === environment.x_tenant_id,
                );
                return { ...list, donation: qg?.statistics?.donation, statistics: qg?.statistics };
              })
              .sort((a: any, b: any) => b?.statistics?.donation - a?.statistics?.donation);
          }
        });
    }
  }

  charitieFilter(e: any) {
    this.filtercontent = this._filterCharities(e.value);
  }

  private _filterCharities(value: string) {
    const filterValue = value.toLowerCase();
    let charities = [];
    if (filterValue) {
      charities = this.charities?.filter(
        (charity: any) => charity?.name?.toLowerCase().indexOf(filterValue) === 0,
      );
    } else {
      charities = this.charities;
    }
    return charities;
  }

  // get sorted() {
  //   console.log(this.filtercontent);

  //   // let sortedList = [];

  //   const sortedList = this.filtercontent
  //     ?.map((filterItem: any) => {
  //       const qg = filterItem?.projection.find(
  //         (item) => item?.app?.slug === environment.x_tenant_id,
  //       );
  //       return qg;
  //     })
  //     .sort((a: any, b: any) => b?.statistics?.donation - a?.statistics?.donation);

  //   console.log(sortedList);
  //   return sortedList;

  //   // return this.filtercontent
  //   //   .slice()
  //   //   .sort((a: any, b: any) => b?.statistics?.donations - a?.statistics?.donations);
  // }

  // charity123 = [
  //   {
  //     id: "human-appeal",
  //     image: "https://s3.givebrite.com/qurbanigiving/charity-img1.png",
  //     name: "Human Appeal",
  //     leftGivers: "1600",
  //     rightGivers: "1400",
  //     bgcolor: "#7332AA",
  //     progressbar: "90"
  //   },
  //   {
  //     id: "islamic-help",
  //     image: "https://s3.givebrite.com/qurbanigiving/charity-img2.png",
  //     name: "Islamic Help",
  //     leftGivers: "400",
  //     rightGivers: "50",
  //     bgcolor: "#DF3144",
  //     progressbar: "60"
  //   },
  //   {
  //     id: "muslim-aid",
  //     image: "https://s3.givebrite.com/qurbanigiving/charity-img3.png",
  //     name: "Muslim Aid",
  //     leftGivers: "1024",
  //     rightGivers: "1030",
  //     bgcolor: "#93C23E",
  //     progressbar: "50"
  //   },
  //   {
  //     id: "islamic-relief",
  //     image: "https://s3.givebrite.com/qurbanigiving/charity-img4.png",
  //     name: "Islamic Relief",
  //     leftGivers: "4482",
  //     rightGivers: "3610",
  //     bgcolor: "#3C9CD9",
  //     progressbar: "25"
  //   },
  //   {
  //     id: "skt-welfare",
  //     image: "https://s3.givebrite.com/qurbanigiving/charity-img5.png",
  //     name: "SKT Welfare",
  //     leftGivers: "287",
  //     rightGivers: "123",
  //     bgcolor: "#E22929",
  //     progressbar: "40"
  //   }
  // ]

  ngOnInit(): void {}

  getTarget(raised: number) {
    return raised < 100 ? 100 : Math.ceil(raised / 10) * 10 + 20;

    // return this.intervals.find(amount => amount > this.raised);
  }

  ngOnDestroy() {}
  onSingleCharity(slug: any) {
    this.router.navigate(['/charities', slug]);
  }
}
