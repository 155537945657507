import { Injectable } from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  items: NavigationItem[] = [];
  footer: NavigationItem[] = [];

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor() {}

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  getFooter() {
    return (this.footer = [
      // {
      //   type: 'link',
      //   label: 'About us',
      //   route: '/charities/about-us',
      // },
      {
        type: 'link',
        label: 'Privacy',
        route: '/page/privacy',
      },
      {
        type: 'link',
        label: 'Contact us',
        route: '/page/contact-us',
      },
      // {
      //   type: 'link',
      //   label: 'Our Products',
      //   route: '/page/products',
      // },
      // {
      //   type: 'link',
      //   label: 'Complaints',
      //   route: '/page/complaints',
      // },
      // {
      //   type: 'link',
      //   label: 'Code of Practice',
      //   route: '/page/code-of-practice',
      // },
      {
        type: 'link',
        label: 'Cookie Policy',
        route: '/page/cookie-policy',
      },
      {
        type: 'link',
        label: 'Terms & Condition',
        route: '/page/terms',
      },
      {
        type: 'link',
        label: 'Safety & Compliance',
        route: '/page/safety-&-compliance',
      },
    ]);
  }
}
