import { Injectable } from '@angular/core';
import { ApiService } from './../../../../api/src/lib/api.service';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private http: ApiService) {}

  list(
    filter_by?,
    value?,
    page = 1,
    limit?,
    created_at = '',
    zakatable = true,
    priority = 'high',
    title_sort = '',
    sort_by = ''
  ) {
    return this.http
      .get(
        `campaign?filter_by=${filter_by}&value=${value}&created_at=${created_at}&limit=${limit}&page=${page}&zakatable=${zakatable}&priority=${priority}&sort_value=${title_sort}&sort_title=${sort_by}`
      )
      .pipe(
        map((response) => {
          return {
            campaigns: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        }),
        catchError((error) => {
          return of({ error });
        })
      );
  }

  statistics(params?) {
    return this.http.get(`campaign/statistics/home`, params).pipe(
      map((response) => {
        return response;
      })
    );
  }

  overview(slug, type) {
    return this.http.get(`campaign/${slug}?type=${type}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  stats(campaign_Id) {
    console.log(campaign_Id);
    return this.http.get(`campaign/statistics?campaign_id=${campaign_Id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  donations(id?, filter = 'campaign', limit?, page?, from_date?, to_date?) {
    return this.http
      .get(
        `donation?filter_by=${filter}&value=${id}&type=${filter}&limit=${limit}&page=${page}&from_date=${from_date}&to_date=${to_date}`
      )
      .pipe(
        map((response) => {
          return {
            donations: response.docs,

            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  teams(id?, limit?, search?) {
    return this.http
      .get(`team?filter_by=campaign&value=${id}&page=1&limit=${limit}&search=${search}`)
      .pipe(
        map((response) => {
          return {
            teams: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  fundraisers(filter_by, id?, limit?, search?) {
    return this.http
      .get(`fundraiser?&filter_by=${filter_by}&value=${id}&page=1&limit=${limit}&search=${search}`)
      .pipe(
        map((response) => {
          return {
            fundraisers: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  updates(id?) {
    return this.http.get(`campaign/updates?campaign_id=${id}`).pipe(
      map((response) => {
        return response.docs;
      })
    );
  }

  phases(id?) {
    return this.http.get(`campaign/phases?campaign_id=${id}`).pipe(map((response) => response));
  }

  auctions(id?) {
    return this.http
      .get(`campaign/auctions?campaign_id=${id}`)
      .pipe(map((response) => response.docs));
  }

  create(id?, payload?) {
    return this.http.post(`campaign?charity_id=${id}`, payload);
  }

  update(charityId, campaignId, payload?) {
    return this.http.put(`campaign?charity_id=${charityId}&campaign_id=${campaignId}`, payload);
  }

  getCampaignTeam(limit?) {
    return this.http.get(`team?limit=${limit}`).pipe(
      map((response) => {
        return response.docs;
      })
    );
  }

  blogs() {
    return this.http.get(`v2/posts`).pipe(map((response) => response.slice(0, 4)));
  }

  impacts(campaign_id) {
    return this.http
      .get(`campaign/impacts?campaign_id=${campaign_id}`)
      .pipe(map((response) => response));
  }

  //dashboard apis

  getOngoingCampaign(
    page = 1,
    limit = 10,
    from_date = '2020-09-27',
    to_date = '2022-02-01',
    search = '',
    filter_by = 'ongoing'
  ) {
    return this.http
      .get(
        `dashboard/campaigns?page=${page}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search=${search}&
    filter_by=${filter_by}`
      )
      .pipe(
        map((response) => {
          return response.docs;
        })
      );
  }

  getDashboardStatistics() {
    return this.http.get(`dashboard/overview`).pipe(
      map((response) => {
        return response[0];
      }),
      catchError((error) => {
        return error;
      })
    );
  }

  reply(commentId) {
    return this.http
      .get(`donation/reply?comment=${commentId}`)
      .pipe(map((response) => response.docs));
  }

  getUserDonations(limit, page, search, title_sort, sort_by, user_id) {
    return this.http
      .get(
        `dashboard/donations/user?&limit=${limit}&page=${page}&search=${search}&sort_value=${title_sort}&sort_title=${sort_by}&filter_by=user&value=${user_id}`
      )
      .pipe(
        map((response) => {
          return {
            donations: response.docs,
            pagination: {
              limit: response.limit,
              pages: response.pages,
              page: response.page,
              total: response.total,
            },
          };
        })
      );
  }

  // updateUserDonations(donationId, payload) {
  //   return this.http.put(`dashboard/donations/comment/edit?donation_id=${donationId}`, payload);
  // }

  updateUserDonations(donationId, payload) {
    return this.http.put(`dashboard/donations/${donationId}`, payload);
  }

  getDonationTypes() {
    return this.http.get(`campaign/donation_types`).pipe(map((response) => response));
  }

  updateLikeCount(donationId, like) {
    return this.http
      .get(`donation/like?donation_id=${donationId}&like=${like}`)
      .pipe(map((response) => response));
  }
}
