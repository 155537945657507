import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() raised = 50;
  @Input() count = 50;
  @Input() hideGivers: boolean;
  @Input() type = 'basic';
  @Input() color = 'primary';
  stats;
  intervals = [
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    5500,
    6000,
    6500,
    7000,
    7500,
    8000,
    8500,
    9000,
    9500,
    10000,
  ];

  constructor() {}

  ngOnInit() {}

  get _target() {
    return this.raised < 100 ? 100 : Math.ceil(this.raised / 10) * 10 + 20;

    // return this.intervals.find(amount => amount > this.raised);
  }
}
