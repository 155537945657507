import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'gb-campaign-section',
  templateUrl: './campaign-section.component.html',
  styleUrls: ['./campaign-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignSectionComponent implements OnInit {
  @Input() boxed = true;
  @Input() 'full-width' = true;
  @Input() center = true;
  @Input() background: string;

  constructor() {}

  ngOnInit(): void {}
}
