export class SetLocale {
  static readonly type = '[Givebrite] Set Locale';
  constructor(
    private locale: {
      language: string;
      currency: string;
      user_type: string;
      country: string;
    }
  ) {}
}

export class SetUserType {
  static readonly type = '[Givebrite] Set UserType';
  constructor(private user_type: string) {}
}
export class GetProduct {
  static readonly type = '[Givebrite] Get Product';
  constructor(public limit: number) {}
}

export class Loading {
  static readonly type = '[Givebrite] App loading';
  constructor(public loading: boolean) {}
}

export class GetStatisticsHome {
  static readonly type = '[Home] Statistics';
}
