import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidationErrors,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CharityState } from 'libs/data/src/lib/charity/store/charity.state';
import { Charity } from 'libs/data/src/lib/charity/charity.interface';
import { Pagination } from 'libs/data/src/lib/pagination/pagination.interface';
import { GetCharityList } from 'libs/data/src/lib/charity/store/charity.actions';
const CHARITIES_TO_SHOW = 5;

@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-charity-selector',
  templateUrl: './campaign-charity-selector.component.html',
  styleUrls: ['./campaign-charity-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignCharitySelectorComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignCharitySelectorComponent,
    },
  ],
})
export class CampaignCharitySelectorComponent implements ControlValueAccessor, Validator, OnInit {
  @Select(CharityState.getCharityList) charities$: Observable<Charity[]>;
  @Select(CharityState.getCharity) charity$: Observable<Charity>;
  @Select(CharityState.pagination) pagination$: Observable<Pagination>;

  charities: Charity[] = [];
  search = '';
  selectedCharity = '';
  currentPage = 1;
  touched = false;
  disabled = false;
  pagination;
  append = false;

  onChange = (charityValue: string) => {};
  onTouched = () => {};

  constructor(private store: Store) {
    this.getCharities();
    this.getCharity();

    this.pagination$.pipe(untilDestroyed(this)).subscribe((pagination) => {
      if (pagination) {
        this.pagination = pagination;
      }
    });
  }

  /**
   * @description
   * SEARCH CHARITIES
   * @param search
   */
  searchCharities(search) {
    this.selectCharity('');
    this.currentPage = 1;
    this.append = false;
    this.search = search;
    this.getCharities();
  }

  ngOnInit(): void {}

  writeValue(charity: string) {
    this.selectedCharity = charity;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const charityValue = control.value;

    if (charityValue === '') {
      return {
        mustNotBeEmpty: {
          charityValue,
        },
      };
    }
  }

  /**
   * @description
   * SHOW LOAD MORE
   * @param pagination
   * @returns
   */
  showLoadMore(pagination: Pagination) {
    this.append = true;
    return pagination.page != pagination.pages;
  }

  /**
   * @description
   * LOAD MORE
   */
  loadMore() {
    this.currentPage = this.currentPage + 1;
    this.getCharities();
  }

  /**
   * @description
   * GET CHARITIES
   */
  getCharities() {
    this.store.dispatch(
      new GetCharityList({
        limit: CHARITIES_TO_SHOW,
        page: this.currentPage,
        filter_by: 'name',
        value: this.search,
        append: this.append,
      })
    );
  }

  /**
   * @description
   * ADD NEW FIELD CHECKED INTO EACH ARRAY ITEM
   */
  getCharity() {
    this.charities$.pipe(untilDestroyed(this)).subscribe((charities) => {
      this.charities = charities;
      if (charities?.length) {
        this.charity$.pipe(untilDestroyed(this)).subscribe((charity) => {
          if (charity) {
            this.charities = this.charities.filter((c) => c._id != charity._id);
          }
        });
      }
    });
  }

  /**
   * SETS THE ACTIVE CHARITY, CALLS THE PARENT FORM CONTROL TO SET THE VALUE OF CHARITY TO SELECTED CHARITY
   * @param Charity Id
   */
  selectCharity(charityId) {
    this.selectedCharity = charityId;
    this.onChange(charityId);
  }
}
