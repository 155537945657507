import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLogoAvatarComponent } from './user-logo-avatar.component';
import { LogoCardModule } from './../../../../../theming/src/lib/components/logo-card/logo-card.module';
// import { AvatarModule } from 'ngx-avatar';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [UserLogoAvatarComponent],
  imports: [
    CommonModule,
    LogoCardModule,
    // AvatarModule,
    FlexLayoutModule,
  ],
  exports: [UserLogoAvatarComponent],
})
export class UserLogoAvatarModule {}
