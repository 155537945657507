import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignDetailsComponent } from './campaign-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { CampaignDetailsCommonModule } from './campaign-details-common/campaign-details-common.module';
import { ThemeColorsModule } from './theme-colors/theme-colors.module';
import { CampaignDetailsEditorModule } from './campaign-details-editor/campaign-details-editor.module';
import { CampaignAddImpactsModule } from './campaign-add-impacts/campaign-add-impacts.module';
import { CampaignSlideToggleModule } from './campaign-slide-toggle/campaign-slide-toggle.module';
import { CampaignAmountModule } from '../campaign-amount/campaign-amount.module';
import { CampaignAddSponsorsModule } from './campaign-add-sponsors/campaign-add-sponsors.module';
import { CampaignAddPhasesModule } from './campaign-add-phases/campaign-add-phases.module';
import { CampaignURLModule } from '../../campaign-url/campaign-url.module';

@NgModule({
  declarations: [CampaignDetailsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    // CampaignDetailsCommonModule,
    ThemeColorsModule,
    CampaignDetailsEditorModule,
    CampaignAddImpactsModule,
    CampaignAddSponsorsModule,
    CampaignSlideToggleModule,
    CampaignAmountModule,
    CampaignAddPhasesModule,
    CampaignURLModule,
  ],
  exports: [CampaignDetailsComponent],
})
export class CampaignDetailsModule {}
