import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewCardComponent } from './view-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageLayoutModule } from 'libs/theming/src/lib/components/page-layout/page-layout.module';

@NgModule({
  declarations: [ViewCardComponent],
  imports: [CommonModule, MatProgressSpinnerModule, FlexLayoutModule, PageLayoutModule],
  exports: [ViewCardComponent],
})
export class ViewCardModule {}
