import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignLaunchComponent } from './campaign-launch.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@abhinavakhil/iconify-angular';

@NgModule({
  declarations: [CampaignLaunchComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatFormFieldModule,
    FormsModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    IconModule,
    MatInputModule,
    MatButtonToggleModule,
  ],
  exports: [CampaignLaunchComponent],
})
export class CampaignLaunchModule {}
