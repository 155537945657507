export class GetCampaignFundraisers {
  static readonly type = '[Campaigns] Get Campaign Fundraisers';
  constructor(
    public filter_by: string = '',
    public campaignId: string,
    public limit: number = 10,
    public search: any = ''
  ) {}
}

export class GetFundraisers {
  static readonly type = '[Fundraisers] List';
  constructor(
    public filter_by: string = '',
    public value: string = '',
    public page: number = 1,
    public limit: number = 10,
    public orderby: string = '',
    public search: any = '',
    public title_sort: number = 1,
    public sort_by: string = ''
  ) {}
}

export class CreateFundraiser {
  static readonly type = '[Campaigns] Create Fundraiser';
  constructor(public payload: any) {}
}

export class UpdateFundraiser {
  static readonly type = '[Fundraiser] Update Fundraiser';
  constructor(public payload: any) {}
}

export class GetCampaignFundraiser {
  static readonly type = '[Campaigns] Get Campaign Fundraiser';
  constructor(public id: string, public type = 'slug') {}
}

export class GetFundraiserDonations {
  static readonly type = '[Campaigns] Get Fundraiser Donations';
  constructor(
    public limit: number,
    public fundraiserId: string,
    public page: number,
    public from_date?,
    public to_date?
  ) {}
}

export class AddOfflineDonations {
  static readonly type = '[Campaigns] Fundraiser Add Offline Donations';
  constructor(public payload: any) {}
}

export class GetFundraisersForSearch {
  static readonly type = '[Fundraisers] Get List Fundraisers For Search';
  constructor(
    public filter_by: string = '',
    public value: string = '',
    public page: number = 1,
    public limit: number = 10,
    public orderby: string = '',
    public search: any = '',
    public title_sort: number = 1,
    public sort_by: string = ''
  ) {}
}
