import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginConfirmationComponent } from './login-confirmation.component';
import { LoginConfirmationRoutingModule } from './login-confirmation-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    LoginConfirmationComponent,
  ],
  imports: [
    CommonModule,
    LoginConfirmationRoutingModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports:[
    LoginConfirmationComponent
  ]
})
export class LoginConfirmationModule { }
