import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiModule } from './api.module';

@Injectable({
  providedIn: ApiModule,
})
export class ApiService {
  public endpoint: string;

  endpoints: any;

  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    // if (error.error instanceof ErrorEvent) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   return throwError(error.error.error);
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong.
    //   return throwError(error.error.error);
    // }
    console.log(error);
    return throwError(error.error.error);
  }

  _endpoint(path: string) {
    // return this.endpoint;
    var paths: any = [];
    if (path.includes('/')) {
      paths = path.split('/');
    } else if (path.includes('?')) {
      paths = path.split('?');
    } else {
      paths = paths[0] = path;
    }

    // if (path.includes('?') && !path.includes('/')) {
    //   paths = path.split('?');
    // }

    // paths = path.split("?");
    // let paths = path.substring(path.indexOf("/") + 1, s.indexOf(")"));

    if (paths.length) {
      if (paths[0] == 'auth') {
        return this.endpoints.auth;
      } else if (paths[0] == 'charity' || paths[0] == 'gallery') {
        return this.endpoints.charity;
      } else if (paths[0] == 'inventory') {
        return this.endpoints.qurbani;
      } else if (paths[0] == 'campaign' || paths[0] == 'fundraiser' || paths[0] == 'team') {
        return this.endpoints.campaign;
      } else if (paths[0] == 'home') {
        return this.endpoints.home;
      } else if (paths[0] == 'dashboard') {
        return this.endpoints.dashboard;
      } else if (paths[0] == 'donation' || paths[0] == 'payment' || paths[0] == 'recurring') {
        return this.endpoints.donation;
      } else if (paths[0] == 'v2') {
        return this.endpoints.blogs;
      } else {
        return this.endpoints.campaign;
      }
    }
    return this.endpoint;
  }

  post(path: string, body: any = {}): Observable<any> {
    console.log(body);
    return this.http
      .post(`${this._endpoint(path)}/${path}`, body)
      .pipe(catchError(this.handleError));
  }

  upload(path: string, body: any = {}): Observable<any> {
    console.log(body);
    return this.http
      .post(`${this._endpoint(path)}/${path}`, body)
      .pipe(catchError(this.handleError));
  }

  get(path: string, params?: any): Observable<any> {
    return this.http
      .get(`${this._endpoint(path)}/${path}`, { params: params })
      .pipe(catchError(this.handleError));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${this._endpoint(path)}/${path}`, body)
      .pipe(catchError(this.handleError));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http
      .patch(`${this._endpoint(path)}/${path}`, body)
      .pipe(catchError(this.handleError));
  }

  delete(path: string, body: Object = {}): Observable<any> {
    return this.http
      .request('delete', `${this._endpoint(path)}/${path}`, { body: body })
      .pipe(catchError(this.handleError));
  }
}
