import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrendingDonationsComponent } from './trending-donations.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';
import { DateAgoPipeModule } from 'libs/theming/src/lib/pipes/date-ago-pipe/date-ago-pipe.module';
@NgModule({
  declarations: [TrendingDonationsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDividerModule,
    DateAgoPipeModule,
    UserLogoAvatarModule,
  ],
  exports: [TrendingDonationsComponent],
})
export class TrendingDonationsModule {}
