import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateUserProfile } from '@givebrite/data';
import close from '@iconify/icons-ic/close';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ToastrService } from 'ngx-toastr';

@UntilDestroy()
@Component({
  selector: 'givebrite-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss'],
})
export class EditProfileDialogComponent implements OnInit {
  user = this.data?.user;
  close = close;
  loading: boolean = false;
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<EditProfileDialogComponent>,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      first_name: [this.user?.first_name, [Validators.required]],
      last_name: [this.user?.last_name, [Validators.required]],
      profile_image: [],
    });
  }

  /**
   * close dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  updateProfile() {
    this.loading = true;
    this.store
      .dispatch(new UpdateUserProfile(this.form.value))
      .pipe(untilDestroyed(this))
      .subscribe(
        (store) => {
          this.toastr.success('Profile updated successfully!');
          this.closeDialog();
        },
        (error) => {
          this.toastr.error(error[0]);
          this.loading = false;
        }
      );
  }
}
