import { Component, Input, OnInit } from '@angular/core';
import { Donation } from 'libs/data/src/lib/donation/donation.interface';

@Component({
  selector: 'givebrite-donation-item-list',
  templateUrl: './donation-item-list.component.html',
  styleUrls: ['./donation-item-list.component.scss'],
})
export class DonationItemListComponent implements OnInit {
  @Input() donation: Donation;
  @Input() last: boolean;

  constructor() {}

  ngOnInit(): void {}

  /**
   * reply to the comments
   */
  reply(commentId: string) {
    // this.donationService.replyToComments(commentId);
  }

  getGiftAmount(amount) {
    return ((25 / 100) * amount).toFixed(2);
  }
}
