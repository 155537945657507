import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayMessageComponent } from './display-message.component';

@NgModule({
  declarations: [DisplayMessageComponent],
  imports: [CommonModule],
  exports: [DisplayMessageComponent],
})
export class DisplayMessageModule {}
