import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import close from '@iconify/icons-ic/close';

@Component({
  selector: 'givebrite-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.scss'],
})
export class CloseIconComponent implements OnInit {
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();

  closeIcon = close;

  constructor() {}

  ngOnInit(): void {}

  /**
   * CLOSE DIALOG
   */
  close() {
    this.onClose.emit('close');
  }
}
