import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-invite-cover',
  templateUrl: './invite-cover.component.html',
  styleUrls: ['./invite-cover.component.scss'],
})
export class InviteCoverComponent implements OnInit {
  @Input() cover;
  @Input() logo;
  @Input() name;

  constructor() {}

  ngOnInit(): void {}
}
