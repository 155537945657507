<ng-template #sidenavRef>
  <!-- <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav> -->
</ng-template>

<ng-template #toolbarRef>
  <!-- <qg-toolbar [mobileQuery]="!(isDesktop$ | async)" class="vex-toolbar"></qg-toolbar> -->
  <app-header></app-header>
  <vex-navigation
    *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
    [fxHide]="!(isDesktop$ | async)"
  ></vex-navigation>
</ng-template>

<ng-template #footerRef>
  <div class="footer">
    <div class="footer-banner">
      <img src="https://s3.givebrite.com/qurbanigiving/footer-banner1.png" alt="" width="100%" />
    </div>
    <app-footer></app-footer>
  </div>
</ng-template>

<vex-layout
  [footerRef]="footerRef"
  index.
  [sidenavRef]="sidenavRef"
  [toolbarRef]="toolbarRef"
></vex-layout>
