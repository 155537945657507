<div class="container">
  <mat-tab-group dynamicHeight="false" class="leaderboard-tab-group">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-3">trending_up</mat-icon>
        <div>Charities</div>
      </ng-template>
      <cdk-virtual-scroll-viewport itemSize="100" class="mat-tab-viewport">
        <givebrite-leaderboard-item
          (clicked)="itemClicked(charity)"
          *cdkVirtualFor="let charity of charities$ | async; let i = index"
          [raised]="+charity.stats?.raised"
          [avatar]="charity.logo"
          [position]="+i + 1"
          [count]="+charity.stats?.count"
          [text]="charity.name"
          [currency]="charity.stats?.currency"
          class="leaderboard-item"
        >
        </givebrite-leaderboard-item>
      </cdk-virtual-scroll-viewport>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mr-3">trending_up</mat-icon>
        <div>Campaigns</div>
      </ng-template>

      <givebrite-leaderboard-item
        (clicked)="itemClicked(campaign)"
        *ngFor="let campaign of campaigns$ | async; let i = index"
        [position]="+i + 1"
        [avatar]="campaign.cover"
        [raised]="+campaign.amount?.raised"
        [text]="campaign.name"
        [currency]="campaign?.currency"
      ></givebrite-leaderboard-item>
    </mat-tab>
    <mat-tab *ngIf="">
      <ng-template mat-tab-label>
        <mat-icon class="mr-3">trending_up</mat-icon>
        <div>Users</div>
      </ng-template>

      <givebrite-leaderboard-item
        *ngFor="let subscription of subscriptions$ | async; let i = index"
        [position]="+i + 1"
        [raised]="+subscription.donation.amount"
        [text]="subscription.first_name + ' ' + subscription.last_name"
        [currency]="subscription?.donation?.currency"
      ></givebrite-leaderboard-item>
    </mat-tab>
  </mat-tab-group>
</div>
