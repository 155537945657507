import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  CartState,
  Charity,
  CharityState,
  GetQurbaniStatistics,
  QurbaniState,
} from '@givebrite/data';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Select(CharityState.getCharities) charities$: Observable<Charity[]>;
  @Select(CharityState.loading) loading$: Observable<boolean>;
  @Select(QurbaniState.getStatistics) stats$: Observable<boolean>;
  @Select(CartState.cartTotal) total$: Observable<any>;
  title = 'QG-home';
  statTypes = ['countries', 'meals', 'qurbani'];
  charity: true;
  year = new Date().getFullYear();
  isBrowser: boolean;

  constructor(
    private store: Store,
    private titleService: Title,
    private metaService: Meta,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    // this.store.dispatch(new GetCharities()); //@TODO
    // this.store.dispatch(new GetCharitiesForSearch('apps', 'qurbani-giving'));
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.store.dispatch(new GetQurbaniStatistics());
      // const filter_by = '';
      // const search = 'qurbani-giving';
      // const page = 1;
      // const limit = 100;
      // const featured = 1;
      // this.store.dispatch(new GetCharities(filter_by, search, page, limit, featured));
    }
  }

  countriesItem = [
    {
      name: 'Countries',
      number: '24',
    },
    // {
    //   name: "Meals",
    //   number: "4000"
    // },
    {
      name: 'Qurbani',
      number: '700',
    },
  ];

  charitysItem = [
    {
      id: 'human-appeal',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img1.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img1-active.png',
    },
    {
      id: 'islamic-help',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img2.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img2-active.png',
    },
    {
      id: 'grt',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img3.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img3-active.png',
    },
    {
      id: 'muslim',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img4.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img4-active.png',
    },
    {
      id: 'skt-welfare',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img5.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img5-active.png',
    },
    {
      id: 'ummah',
      image: 'https://s3.givebrite.com/qurbanigiving/charity-img6.png',
      activeImage: 'https://s3.givebrite.com/qurbanigiving/charity-img6-active.png',
    },
  ];

  ngOnInit(): void {
    this.setMetaData();

    // if (this.isBrowser) {
    //   const filter_by = '';
    //   const search = 'qurbani-giving';
    //   const page = 1;
    //   const limit = 8;
    //   const featured = 1;
    //   this.store.dispatch(new GetCharities(filter_by, search, page, limit, featured));
    // }
  }

  OnCharityGet(slug) {
    this.charity = slug;
    // this.router.navigate(['/charities',slug]);
  }

  /**
   * set meta data
   */
  setMetaData() {
    this.titleService.setTitle(
      'Qurbani Giving - A new simple way to fulfil your qurbani obligation in a few clicks',
    );
    this.metaService.addTags([
      { name: 'description', content: `Fulfill your Udhiyyah ${this.year} with Qurbani Giving` },
      { name: 'og:type', content: 'website' },
      { name: 'og:type', content: 'en_GB' },
      {
        name: 'og:image',
        content:
          'https://gb-downloads.s3.eu-west-1.amazonaws.com/assets/WhatsApp+Image+2022-06-10+at+2.27.19+PM.jpeg',
      },
    ]);
  }
}
