import { Component, OnInit, Input } from '@angular/core';
import { CampaignRg, ImpactRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-campaign-impacts',
  templateUrl: './campaign-impacts.component.html',
  styleUrls: ['./campaign-impacts.component.scss'],
})
export class CampaignImpactsComponent implements OnInit {
  @Input() campaign: CampaignRg;
  constructor() {}

  ngOnInit() {}

  get impacts(): ImpactRg[] {
    return this.campaign ? this.campaign.impacts : [];
  }
}
