import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule, NavigationModule, NavigationItemModule } from '@givebrite/theming';
import { QurbaniGivingLayoutComponent } from './qurbani-giving-layout.component';
import { SidenavModule } from '@givebrite/theming';
import { ToolbarModule } from '@givebrite/theming';
import { QuickpanelModule } from '@givebrite/theming';
// import { ConfigPanelModule } from "@givebrite/theming";

import { SidebarModule } from '@givebrite/theming';
import { QurbaniGivingToolbarModule } from './toolbar/qurbani-giving-toolbar.module';
import { RamadhanGivingSidenavModule } from './sidenav/sidenav.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';

@NgModule({
  declarations: [QurbaniGivingLayoutComponent],
  imports: [
    QuickpanelModule,
    // ConfigPanelModule,
    CommonModule,
    LayoutModule,
    RamadhanGivingSidenavModule,
    QurbaniGivingToolbarModule,
    FooterModule,
    SidebarModule,
    NavigationModule,
    NavigationItemModule,
    FlexLayoutModule,
    HeaderModule,
  ],
})
export class QurbaniGivingLayoutModule {}
