import { State, Action, StateContext, Selector } from '@ngxs/store';
import { QurbaniAnimal, QurbaniInventoryItem, QurbaniStatistics } from '../qurbani.interface';
import { QurbaniService } from '../qurbani.service';
import {
  AddQurbaniStock,
  GetQurbaniAnimals,
  GetQurbaniCharities,
  GetQurbaniInventory,
  GetQurbaniStatistics,
  ResetInventory,
  SelectAnimal,
  UpdateQurbaniInventoryItem,
} from './qurbani.action';
import { tap, map } from 'rxjs/operators';
import { asapScheduler, Scheduler, Observable } from 'rxjs';
import { patch, append, insertItem, updateItem, iif, removeItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { Pagination } from '@givebrite/data';

export class QurbaniStateModel {
  animals?: QurbaniAnimal[];
  animal?: QurbaniAnimal;
  loading?: boolean;
  inventory?: QurbaniInventoryItem[];
  pagination?: Pagination;
  statistics?: QurbaniStatistics;
  charities?: any[];
}

@Injectable()
@State<QurbaniStateModel>({
  name: 'qurbani',
  defaults: {
    animals: [],
    animal: null,
    loading: false,
    inventory: [],
    pagination: null,
    statistics: null,
    charities: [],
  },
})
export class QurbaniState {
  constructor(private qurbaniService: QurbaniService) {}

  @Selector()
  static getInventory(state: QurbaniStateModel) {
    return state.inventory;
  }

  @Selector()
  static getAnimals(state: QurbaniStateModel) {
    return state.animals;
  }

  @Selector()
  static getPagination(state: QurbaniStateModel) {
    return state.pagination;
  }

  @Selector()
  static getStatistics(state: QurbaniStateModel) {
    return state.statistics;
  }

  @Selector()
  static loading(state: QurbaniStateModel) {
    return state.loading;
  }

  @Selector()
  static getSelectedAnimal(state: QurbaniStateModel) {
    return state.animal;
  }

  @Selector()
  static getCharities(state: QurbaniStateModel) {
    return state.charities;
  }

  @Action(GetQurbaniInventory)
  getInventory(
    { setState, patchState }: StateContext<QurbaniStateModel>,
    { charityId, page, limit }
  ) {
    patchState({ loading: true });
    return this.qurbaniService.inventory(charityId, page, limit).pipe(
      tap(
        (result: { page: number; pages: number; total: number; docs: QurbaniInventoryItem[] }) => {
          patchState({
            inventory: result.docs,
            loading: false,
            pagination: {
              pages: result.pages,
              limit: 0,
              page: result.page,
              total: result.total,
            },
          });
        }
      )
    );
  }

  @Action(UpdateQurbaniInventoryItem)
  updateInventoryItem(
    { setState, patchState }: StateContext<QurbaniStateModel>,
    { inventoryItem }
  ) {
    patchState({ loading: true });
    return this.qurbaniService.updateInventoryItem(inventoryItem).pipe(
      tap((item) => {
        setState(
          patch({
            inventory: updateItem((item) => item._id == inventoryItem._id, item),
          })
        );
        patchState({ loading: false });
      })
    );
  }

  @Action(GetQurbaniAnimals)
  getAnimals({ patchState }: StateContext<QurbaniStateModel>) {
    patchState({ loading: true });
    return this.qurbaniService.animals().pipe(
      tap((result: QurbaniAnimal[]) => {
        patchState({
          animals: result,
          loading: false,
        });
      })
    );
  }

  @Action(GetQurbaniStatistics)
  getQurbaniStatistics({ patchState }: StateContext<QurbaniStateModel>) {
    patchState({ loading: true });
    return this.qurbaniService.statistics().pipe(
      tap((result: QurbaniStatistics) => {
        patchState({
          statistics: result,
          loading: false,
        });
      })
    );
  }

  @Action(AddQurbaniStock)
  addStock({ patchState, setState }: StateContext<QurbaniStateModel>, { stock }) {
    patchState({ loading: true });
    return this.qurbaniService.addStock(stock).pipe(
      tap((stock: QurbaniInventoryItem) => {
        patchState({ loading: false });
        setState(
          // inventory: result, UPDATE
          patch({
            inventory: insertItem<QurbaniInventoryItem>(stock),
          })
        );
      })
    );
  }

  @Action(SelectAnimal)
  selectAnimal({ patchState }: StateContext<QurbaniStateModel>, { animal }) {
    patchState({ animal: animal });
  }

  @Action(ResetInventory)
  reset({ setState }: StateContext<QurbaniStateModel>) {
    setState({
      inventory: [],
      animal: null,
    });
  }

  @Action(GetQurbaniCharities)
  getCharities({ patchState }: StateContext<QurbaniStateModel>) {
    patchState({ loading: true });
    return this.qurbaniService.charities().pipe(
      tap((result: any[]) => {
        patchState({
          charities: result,
          loading: false,
        });
      })
    );
  }
}
