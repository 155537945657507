import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CurveComponent } from './curve.component';

@NgModule({
  declarations: [CurveComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule],
  exports: [CurveComponent],
})
export class CurveModule {}
