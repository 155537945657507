import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Campaigns, Team } from './campaign-selector';
import { CampaignState } from 'libs/data/src/lib/campaign/store/campaign.state';
import { Campaign, CampaignTeam } from 'libs/data/src/lib/campaign/campaign.interface';
import { GetCampaignTeam, GetCampaigns } from 'libs/data/src/lib/campaign/store/campaign.actions';

@Component({
  selector: 'givebrite-campaign-selector',
  templateUrl: './campaign-selector.component.html',
  styleUrls: ['./campaign-selector.component.scss'],
})
export class CampaignSelectorComponent implements OnInit {
  @Select(CampaignState.getCampaignTeams) teams$: Observable<CampaignTeam[]>;
  @Select(CampaignState.getCampaigns) campaigns$: Observable<Campaign[]>;
  @Select(CampaignState.getCharityId) charityId$: Observable<any>;

  @Input() set charity(charity) {
    if (charity) {
      this.store.dispatch(new GetCampaigns('charity', charity._id));
    }
  }

  @Input() name;
  @Input() type;

  campaigns: Campaign[] = [];

  campaign: {
    id: number;
    cover: string;
    checked: boolean;
    name: string;
  }[];

  team: Array<any> = [];
  search = '';

  constructor(private store: Store) {
    this.store.dispatch(new GetCampaignTeam());
  }

  ngOnInit(): void {
    this.campaign = Campaigns;
    // this.charityId$.subscribe((id) => {
    // });
    this.getTeams();
  }

  /**
   * @description
   * SELECTED CAMPAIGNS
   * @param index
   * @param data
   */
  selectedType(index, data) {
    data.forEach((item, idx) => {
      if (index != idx) {
        item.checked = false;
      } else {
        item.checked = !item.checked;
      }
    });
  }

  /**
   * @description
   * REPLACE HTML TAGS AND RETURN TEXT
   * @param content description
   * @returns text without html tag
   */
  replaceHtml(content) {
    return content
      .replace(/<[^>]*>/g, '')
      .slice(0, 40)
      .concat('...');
  }

  /**
   * @description
   * GET TEAMS
   */
  getTeams() {
    this.teams$.subscribe((res) => {
      res = res.map((item) => {
        return { ...item, checked: false };
      });
      this.team = res ? [...res] : Team;
    });
  }

  ngAfterViewInit() {
    if (this.type == 'campaign') {
      this.store.dispatch(new GetCampaigns());
      this.campaigns$.subscribe((campaigns) => {
        this.campaigns = campaigns;
      });
    }
  }
}
