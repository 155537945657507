import { CampaignCreateForm } from '../../campaign';
import { Fundraiser, Team } from '../../campaign';
import {ProjectAppealCreateForm, ProjectCreateForm} from '../../project/project.interface';
import { CreateUserForm } from '../../user';
export class GetDashboardCampaigns {
  static readonly type = '[Dashboard] Get list of campaign';
  constructor(
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 5,
    public sort_value: number = 1,
    public sort_title = '',
    // public date_sort ='',
    public from_date = '',
    public to_date = '',
    public value = ''
  ) {}
}

export class GetDashboardStatistics {
  static readonly type = '[Dashboard] Get Statistics';
  constructor() {}
}

export class CreateDashboardCampaign {
  static readonly type = '[Dashboard] Create Campaign';
  constructor(public payload: CampaignCreateForm) {}
}

export class GetDashboardCampaign {
  static readonly type = '[Dashboard] Get Campaign';
  constructor(public slug: string) {}
}

export class EditDashboardCampaign {
  static readonly type = '[Dashboard] Edit Campaign';
  constructor(public slug: string, public payload: CampaignCreateForm) {}
}

export class GetDashboardCampaignDonations {
  static readonly type = '[Dashboard] Get Campaign Donations';
  constructor(
    public id?: string,
    public filter_by?,
    public type?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    // public title_sort: number = 1,
    // public date_sort = '',
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}

export class GetDashboardCampaignSubscriptions {
  static readonly type = '[Dashboard] Get Campaign Subscriptions';
  constructor(
    public id?: string,
    public filter_by?,
    public type?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    // public title_sort: number = 1,
    // public date_sort = '',
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}

export class GetDashboardRGSubscriptions {
  static readonly type = '[Dashboard] Get RG Subscriptions';
  constructor(
    public id?: string,
    public filter_by?,
    public type?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    // public title_sort: number = 1,
    // public date_sort = '',
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}

export class GetDonationsStatistics {
  static readonly type = '[Dashboard] Get Donations Statistics';
  constructor() {}
}
export class GetUserStatistics {
  static readonly type = '[Dashboard] Get User Statistics';
  constructor() {}
}
export class GetRamadhanGivingStatistics {
  static readonly type = '[Dashboard] Get Ramadhan Giving Statistics';
  constructor() {}
}
export class GetDonationsStatisticsAll {
  static readonly type = '[Dashboard] Get Donations Statistics All';
}
export class GetUsersStatistics {
  static readonly type = '[Dashboard] Get Users Statistics';
  constructor() {}
}

export class GetDashboardCampaignTeams {
  static readonly type = '[Dashboard] Get Campaign Team';
  constructor(
    public id?: string,
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}

export class GetDashboardCampaignFundraisers {
  static readonly type = '[Dashboard] Get Campaign Fundraisers';
  constructor(
    public id?: string,
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}
export class AddOfflineDonation {
  static readonly type = '[Dashboard] Add Offline Donation';
  constructor(public payload: any) {}
}
export class MoveFundraiserToTeam {
  static readonly type = '[Dashboard] Move Fundraiser To Team';
  constructor(public payload: any) {}
}
export class MoveFundraiserToCampaign {
  static readonly type = '[Dashboard] Move Fundraiser To Campaign';
  constructor(public payload: any) {}
}
export class MoveDonationToFundraiser {
  static readonly type = '[Dashboard] Move Donation To Fundraiser';
  constructor(public payload: any) {}
}
export class MoveDonationToTeam {
  static readonly type = '[Dashboard] Move Donation To Team';
  constructor(public payload: any) {}
}
export class CheckStockFile {
  static readonly type = '[Dashboard] Check Stock File';
  constructor(public payload: any) {}
}
export class UploadStockFile {
  static readonly type = '[Dashboard] Upload Stock File';
  constructor(public payload: any) {}
}
export class UploadLogoFile {
  static readonly type = '[Dashboard] Upload Logo File';
  constructor(public id:any, public payload: any) {}
}

export class EndCampaign {
  static readonly type = '[Dashboard] End Campaign';
  constructor(public slug: string, public payload: any) {}
}

export class GetFundraiser {
  static readonly type = '[Dashboard] Get Fundraiser';
  constructor(public slug: string) {}
}

export class EndDeleteFundraiser {
  static readonly type = '[Dashboard] End Delete Fundraiser';
  constructor(public slug: string, public payload: any) {}
}

export class GetComments {
  static readonly type = '[Dashboard] Get Comments';
  constructor(
    public id: string,
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 10,
    public title_sort: number = 1,
    public date_sort = '',
    public from_date = '',
    public to_date = '',
    public comment_type = ''
  ) {}
}
export class AddReply {
  static readonly type = '[Dashboard] Add reply';
  constructor(public id: string, public message: any) {}
}
export class DeleteReply {
  static readonly type = '[Dashboard] Delete reply';
  constructor(public id: string) {}
}
export class DeleteComment {
  static readonly type = '[Dashboard] Delete Comment';
  constructor(public id: string) {}
}
export class UpdateComment {
  static readonly type = '[Dashboard] Update Comment';
  constructor(public id: string, public payload: any) {}
}
export class UpdateReply {
  static readonly type = '[Dashboard] Update reply';
  constructor(public id: string, public payload: any) {}
}

export class GetTeam {
  static readonly type = '[Dashboard] Get Team Single';
  constructor(public id: string) {}
}

export class DeleteTeam {
  static readonly type = '[Dashboard] Delete Team';
  constructor(public slug: string, public payload: any) {}
}

export class DeleteProject {
  static readonly type = '[Dashboard] Delete Project';
  constructor(public id: string) {}
}

export class DeleteProjectAppeal {
  static readonly type = '[Dashboard] Delete Project Appeal';
  constructor(public id: string) {}
}

export class AddFundraiser {
  static readonly type = '[Dashboard] Add Fundraiser';
  constructor(public payload: Fundraiser) {}
}

export class GetCharityFollowers {
  static readonly type = '[Dashboard] Get Dashboard Followers';
  constructor(public search = '') {}
}
export class EditDashboardFundraiser {
  static readonly type = '[Dashboard] Edit Dashboard Fundraiser';
  constructor(public id: string, public payload: Fundraiser) {}
}

export class AddTeam {
  static readonly type = '[Dashboard] Add Team';
  constructor(public payload: Team) {}
}
export class EditTeam {
  static readonly type = '[Dashboard] Edit Team';
  constructor(public id: string, public payload: Team) {}
}

export class ExportDonations {
  static readonly type = '[Dashboard] Export Donations';
  constructor(public payload: any) {}
}

export class ExportFundraisers {
  static readonly type = '[Dashboard] Export Fundraisers';
  constructor(public payload: any) {}
}

export class ExportStatus {
  static readonly type = '[Dashboard] Export Status';
  constructor(public id: string, public type: string) {}
}

export class RemoveDashBoardError {
  static readonly type = '[Dashboard] Remove Error';
  constructor() {}
}

export class RemoveSuccessMessage {
  static readonly type = '[Dashboard] Remove Success Message';
  constructor() {}
}

export class GetQGStatistics {
  static readonly type = '[Dashboard] Get Qurbani Giving Statistics';
  constructor() {}
}

export class GetAnimalsList {
  static readonly type = '[Dashboard] Get Animals List';
  constructor() {}
}

export class GetAnimalsByCountry {
  static readonly type = '[Dashboard] Get Animals By Country';
  constructor(public countryCode: string) {}
}

export class GetStocksList {
  static readonly type = '[Dashboard] Get Stocks List';
  constructor(
    public search?,
    public page: number = 1,
    public limit: number = 10,
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = '',
    public animal = ''
  ) {}
}

export class GetStockById {
  static readonly type = '[Dashboard] Get Specific Stock';
  constructor(
    public stockId: string ,
  ) {}
}

export class AddStock {
  static readonly type = '[Dashboard] Add Stock';
  constructor(public payload: any) {}
}

export class UpdateStock {
  static readonly type = '[Dashboard] Update Stock';
  constructor(public id: string, public payload: any) {}
}

export class GetDashboardQGDonations {
  static readonly type = '[Dashboard] Get Qurbani Donations';
  constructor(
    public search?,
    public page: number = 1,
    public limit: number = 10,
    public sort_value = -1,
    public sort_title = 'created_at',
    public from_date = '',
    public to_date = ''
  ) {}
}
export class ExportQGDonations {
  static readonly type = '[Dashboard] Export Qurbani Donations';
  constructor(public payload: any) {}
}
export class ExportProjectCSV {
  static readonly type = '[Dashboard] Export  Export Project CSV';
  constructor(public payload: any) {}
}
export class ExportAppealCSV {
  static readonly type = '[Dashboard] Export Export Appeal CSV';
  constructor(public payload: any) {}
}
export class ExportQGStock {
  static readonly type = '[Dashboard] Export Qurbani Stock';
  constructor(public payload: any) {}
}
export class ExportRGDonations {
  static readonly type = '[Dashboard] Export Ramadhan Giving Donations';
  constructor(public payload: any) {}
}
export class ExportRGSubscriptions {
  static readonly type = '[Dashboard] Export Ramadhan Giving Subscriptions';
  constructor(public payload: any) {}
}
// Project CRUD
export class CreateDashboardProject {
  static readonly type = '[Dashboard] Create Project';
  constructor(public payload: ProjectCreateForm) {}
}
export class CreateDashboardProjectAppeal {
  static readonly type = '[Dashboard] Create Project Appeal';
  constructor(public payload: ProjectAppealCreateForm) {}
}

export class GetDashboardProject {
  static readonly type = '[Dashboard] Get Project';
  constructor(public id: string) {}
}
export class SyncDashboardProject {
  static readonly type = '[Dashboard] sync Project';
  constructor(public id: string, public wp_id: string) {}
}

export class SyncDashboardAppeal {
  static readonly type = '[Dashboard] sync Appeal';
  constructor(public id: string, public wp_id: string) {}
}

export class GetDashboardAppeal {
  static readonly type = '[Dashboard] Get Appeal';
  constructor(public id: string) {}
}
export class GetDashboardProjectAppeal {
  static readonly type = '[Dashboard] Get Project Appeal';
  constructor(public id: string) {}
}
export class GetDashboardAllAppeal {
  static readonly type = '[Dashboard] Get All Appeals';
  constructor(
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 5,
    public sort_value: number = 1,
    public sort_title = '',
    // public date_sort ='',
    public from_date = '',
    public to_date = '',
    public status = ''
  ) {}
}

export class EditDashboardProject {
  static readonly type = '[Dashboard] Edit Project';
  constructor(public id: string, public payload: any) {}
}
export class EditDashboardProjectAppeal {
  static readonly type = '[Dashboard] Edit Project Appeal';
  constructor(public id: string, public payload: any) {}
}
export class GetAllDashboardProjects {
  static readonly type = '[Dashboard] Get All Dashboard Projects';
  constructor() {}
}

export class GetDashboardProjects {
  static readonly type = '[Dashboard] Get list of project';
  constructor(
    public filter_by?,
    public search?,
    public page: number = 1,
    public limit: number = 5,
    public sort_value: number = 1,
    public sort_title = '',
    // public date_sort ='',
    public from_date = '',
    public to_date = '',
    public status = ''
  ) {}
}


//Project category
export class CreateProjectCategory {
  static readonly type = '[Dashboard] Create Project Category';
  constructor(public payload: ProjectCreateForm) {}
}

export class GetProjectCategory {
  static readonly type = '[Dashboard] Get Project Category';
  constructor(public id: string) {}
}

export class EditProjectCategory {
  static readonly type = '[Dashboard] Edit Project Category';
  constructor(public id: string, public payload: ProjectCreateForm) {}
}

export class GetProjectCategories {
  static readonly type = '[Dashboard] Get list of Project Category';
  constructor( 
    public search?,
    public page: number = 1,
    public limit: number = 5,
    public sort_value: number = 1,
    public sort_title = '',
    // public date_sort ='',
    public from_date = '',
    public to_date = ''
   ) {}
}

export class GetDonationsTypes {
  static readonly type = '[Dashboard] Get Donation Types';
  constructor() {}
}
export class GetDashboardUnVerifiedCharitiesCount {
  static readonly type = '[Dashboard] Get Dashboard UnVerified Charities Count';
  constructor() {}
}

// Super Admin

export class GetDashboardCharities {
  static readonly type = '[Dashboard] Get list of charities';
  constructor(
    public page: number = 1,
    public limit: number = 5,
    public search ='',
    public from_date = '',
    public to_date = '',
    public sort_title = '',
    public sort_value?,
    public is_featured = '',
    public active = '',
    public verified = '', 
  ) {}
}
export class GetCharityCounts {
  static readonly type = '[Charity] Get charity counts';
  constructor() {}
}
export class GetDonationById{
  static readonly type = '[Dashboard] Get Donation By Id';
  constructor(public id: string) {}
}
export class GetUserById{
  static readonly type = '[Dashboard] Get User By Id';
  constructor(public id: string) {}
}
export class GetSubscriptionById{
  static readonly type = '[Dashboard] Get Subscription By Id';
  constructor(public id: string) {}
}
export class GetCampaignsStatistics {
  static readonly type = '[Charity] Get Campaign Statistics';
  constructor() {}
}

export class GetAppSettings {
  static readonly type = '[dashboard] Get App Settings';
  constructor() {}
}
export class UpdateAppSettings {
  static readonly type = '[dashboard] Update App Settings';
  constructor(public id: string, public payload: any) {}
}
export class EditCharity {
  static readonly type = '[Dashboard] edit Charity';
  constructor(public id: string, public payload: any) {}
}
export class GetDashboardUsers {
  static readonly type = '[Dashboard] Get list of Users';
  constructor( 
    public filter_by?,
    public page: number = 1,
    public limit: number = 5,
    public value?,
    public search ='',
    public from_date = '',
    public to_date = '',
    public sort_title = '',
    public sort_value?,
    public role ='',
   ) {}
}

export class CreateDashboardUser {
  static readonly type = '[Dashboard] Create User';
  constructor(public payload: CreateUserForm) {}
}
export class CreateProjectCauses {
  static readonly type = '[Dashboard] Create Project Causes';
  constructor(public payload: any) {}
}
export class RemoveProjectCauses {
  static readonly type = '[Dashboard] Remove Project Causes';
  constructor(public payload: any) {}
}
export class EditDashboardUser {
  static readonly type = '[Dashboard] edit User';
  constructor(public id: string, public payload: CreateUserForm) {}
}
export class ResetDashboardUser {
  static readonly type = '[Dashboard] reset User';
  constructor(public id: string, public payload: any) {}
}

export class ResetPasswordLinkDashboardUser {
  static readonly type = '[Dashboard] reset User password link';
  constructor(public id: string) {}
}
export class RemoveDashboardUser {
  static readonly type = '[Dashboard] remove User';
  constructor(public id: string) {}
}
export class EnableDashboardUser {
  static readonly type = '[Dashboard] enable User';
  constructor(public id: string) {}
}
export class EditDashboardDonation {
  static readonly type = '[Dashboard] edit donation';
  constructor(public id: string, public payload: any) {}
}
export class RefundDashboardDonation {
  static readonly type = '[Dashboard] refund donation';
  constructor(public id: string, public payload: any) {}
}
export class EditDashboardSubscription {
  static readonly type = '[Dashboard] edit Subscription';
  constructor(public id: string, public payload: any) {}
}