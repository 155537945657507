import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '@givebrite/theming';

import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '@givebrite/theming';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '@givebrite/theming';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SidebarComponent } from '@givebrite/theming';

@Component({
  selector: 'qurbani-giving-layout',
  templateUrl: './qurbani-giving-layout.component.html',
  styleUrls: ['./qurbani-giving-layout.component.scss'],
})
export class QurbaniGivingLayoutComponent implements OnInit, OnDestroy {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map((config) => config.footer.visible));
  // isDesktop$ = this.breakpointObserver.observe(`(min-width: 1280px`).pipe(map(state => state.matches));
  isHorizontalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit() {
    /* this.layoutService.configpanelOpen$
      .pipe(untilDestroyed(this))
      .subscribe(open =>
        open ? this.configpanel.open() : this.configpanel.close()
      );*/
  }

  ngOnDestroy(): void {}
}
