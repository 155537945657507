import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "givebrite-campaign-launch",
  templateUrl: "./campaign-launch.component.html",
  styleUrls: ["./campaign-launch.component.scss"]
})
export class CampaignLaunchComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit() {}
}
