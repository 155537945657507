import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TipDialogComponent } from './tip-dialog/tip-dialog.component';

export interface Fees {
  amount: number;
  is_fee_covered: boolean;
  tip: number;
  fee_percentage: number;
}
@UntilDestroy()
@Component({
  selector: 'givebrite-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
})
export class TipsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  @Input()
  get currency() {
    return this._currency;
  }

  set currency(currency: string) {
    if (currency) {
      this._currency = currency;
    }
  }

  private _currency: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  /**
   * @description
   * OPEN TIP DIALOG
   */
  openTipsDialog() {
    this.dialog.open(TipDialogComponent, {
      data: {
        currency: this.currency,
        form: this.form,
      },
      panelClass: 'donate-dialog',
      autoFocus: false,
      restoreFocus: false,
    });
  }

  ngOnDestroy(): void {}
}
