export const SadaqahJariyahList: {
  id: number;
  name: string;
  img: string;
  checked: boolean;
}[] = [
  {
    id: 1,
    name: 'Water Well',
    img: 'assets/images/water-well.svg',
    checked: false,
  },
  {
    id: 2,
    name: 'Food Boxes',
    img: 'assets/images/food-boxes.svg',
    checked: false,
  },
  {
    id: 3,
    name: 'Olive Trees',
    img: 'assets/images/olive-tree.svg',
    checked: false,
  },
  {
    id: 4,
    name: 'Masjid Construction',
    img: 'assets/images/masjid-construction.svg',
    checked: false,
  },
  {
    id: 5,
    name: 'Water Distribution',
    img: 'assets/images/water-distribution.svg',
    checked: false,
  },
  {
    id: 6,
    name: 'Sustainable Project',
    img: 'assets/images/sutainable-project.svg',
    checked: false,
  },
  { id: 7, name: 'Other', img: '', checked: false },
];
