import { CartItem } from "./../cart/cart.interface";

export interface QurbaniCartItem extends CartItem {
  animal: QurbaniAnimal;
}

export interface QurbaniCart {
  items: QurbaniCartItem[];
  total: number;
  subtotal: number;
  currency: string;
}

export interface QurbaniStatistics {
  countries: number;
  meals: number;
  qurbani: number;
}

export interface QurbaniAnimal {
  _id: string;
  type: "large" | "small";
  name?: string;
  size: string;
  total_shares?: number;
}

export interface QurbaniStock {
  sold: number;
  total: number;
  shares: number;
  sold_shares: number;
}

export interface QurbaniInventoryItem {
  stock: QurbaniStock;
  country: string[];
  currency: string;
  active: boolean;
  _id: string;
  animal: QurbaniAnimal;
  price: number;
  share_price: number;
  charity: string;
  enable_shares: boolean;
}
