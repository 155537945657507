import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserServiceRg } from '../user';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private user: UserServiceRg) {
    console.log('test');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // i.e. if isAuthenticated is false, then set canActivate to true
    if (this.user.authenticated) {
      //this.user.redirectUrl = state.url;
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}
