import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-circular-fundraising-progress',
  templateUrl: './circular-fundraising-progress.component.html',
  styleUrls: ['./circular-fundraising-progress.component.scss'],
})
export class CircularFundraisingProgressComponent implements OnInit {
  @Input() fundraiser;

  constructor() {}

  ngOnInit(): void {}

  /**
   * get percentage
   * @param raised raised
   * @param goal goal
   * @returns percentage
   */
  getPercentage(raised, goal) {
    const amount = (raised / goal) * 100;
    return Math.floor(amount);
  }
}
