import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from './login-dialog.component';
import { LoginModule } from '../../login/login.module';
import { RegisterModule } from '../../register/register.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DisplayMessageModule } from '../../display-message/display-message.module';
import { ForgotPasswordModule } from '../../forgot-password/forgot-password.module';

@NgModule({
    declarations: [LoginDialogComponent],
    imports: [
        CommonModule,
        LoginModule,
        RegisterModule,
        RouterModule,
        MatButtonModule,
        FlexLayoutModule,
        MatDialogModule,
        ForgotPasswordModule,
        DisplayMessageModule,
        MatIconModule,
    ],
    exports: [LoginDialogComponent]
})
export class LoginDialogModule {}
