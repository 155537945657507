import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';

@Component({
  selector: 'givebrite-top-leaderboard',
  templateUrl: './top-leaderboard.component.html',
  styleUrls: ['./top-leaderboard.component.scss'],
})
export class TopLeaderboardComponent implements OnInit {
  @Input() even: boolean;
  @Input() index: number;
  @Input() fundraiser: Fundraiser;
  @Input() topFundraisers: Fundraiser[];

  images: Array<string> = ['silver-crown.svg', 'golden-crown.svg', 'bronze-crown.svg'];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * NAVIGATE TO FUNDRAISER PAGE
   * @description
   * @param slug FUNDRAISER SLUG
   */
  fundraiserPage(slug: string): void {
    this.router.navigate([`fundraiser`], {
      queryParams: { fundraiser: slug },
    });
  }
}
