import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// import { UpdateCampaignImpact } from '@givebrite/data';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CampaignAddImpactsDialogComponent } from './campaign-add-impacts-dialog.component';

@UntilDestroy()
@Component({
  selector: 'givebrite-campaign-add-impacts',
  templateUrl: './campaign-add-impacts.component.html',
  styleUrls: ['./campaign-add-impacts.component.scss'],
})
export class CampaignAddImpactsComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit(): void {}

  add() {
    let dialog = this.dialog.open(CampaignAddImpactsDialogComponent);
    dialog.afterClosed().subscribe((response) => {
      if (response.form) {
        this.updated.emit();
        this._impacts = response.form.value;
      }
    });
  }

  edit(impact, id) {
    let dialog = this.dialog.open(CampaignAddImpactsDialogComponent, {
      data: { impact },
    });
    dialog.afterClosed().subscribe((response) => {
      if (response.form) {
        this._impacts.setValue(
          this.updateImpactByIndex(id, response.form.value)
        );
        this.updated.emit();
        // if (!response.edit) {
        //   this._impacts = response.form.value;
        // } else {
        //   // edit impacts
        //   this.store
        //     .dispatch(new UpdateCampaignImpact(response.form.value, id))
        //     .pipe(untilDestroyed(this))
        //     .subscribe((store) => {
        //       console.log(store);
        //     });
        // }
        // this.store.dispatch(
        //   new UpdateFormValue({
        //     value: form.value,
        //     path: 'campaign.form',
        //   })
        // );
      }
    });
  }

  set _impacts(impact) {
    this._impacts.setValue([...this.impacts, impact]);
  }

  updateImpactByIndex(idx, impact) {
    let copy = [...this.impacts];
    copy[idx] = impact;
    return copy;
  }

  /**
   * Returns the impacts from the form
   */
  get _impacts(): FormControl {
    return <FormControl>this.form.get('impacts');
  }

  get currency() {
    return this.form.get('currency').value;
  }

  /**
   * Returns impacts as an array
   */
  get impacts(): any[] {
    // console.log(this._impacts.value);
    return this._impacts.value;
  }
}
