import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignMediaComponent } from './campaign-media.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ProgressBarModule } from './../../../progress-bar/progress-bar.module';
// import { FileUploadModule } from '../../../file-upload/file-upload.module';

@NgModule({
  declarations: [CampaignMediaComponent],
  imports: [
    CommonModule,
    // FileUploadModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    ProgressBarModule,
  ],
  exports: [CampaignMediaComponent],
})
export class CampaignMediaModule {}
