import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Location } from '@angular/common';
import { environment } from 'apps/charities/skt/campaign/src/environments/environment';
import { Donation } from 'libs/data/src/lib/donation/donation.interface';
import { UserState } from 'libs/data/src/lib/user/store/user.state';
import { UpdateLikeCount } from 'libs/data/src/lib/campaign/store/campaign.actions';

@UntilDestroy()
@Component({
  selector: 'givebrite-donation-item-card',
  templateUrl: './donation-item-card.component.html',
  styleUrls: ['./donation-item-card.component.scss'],
})
export class DonationItemCardComponent implements OnInit {
  @Input() donation: Donation;
  @Input() last: boolean;

  constructor(private store: Store, private location: Location) {}

  ngOnInit(): void {}

  /**
   * reply to the comments
   */
  reply(commentId: string) {
    // this.donationService.replyToComments(commentId);
  }

  getGiftAmount(amount) {
    return ((25 / 100) * amount).toFixed(2);
  }

  get is_favorite() {
    const user = this.store.selectSnapshot(UserState.user);
    const isFavorite = this.donation.liked_by.find((donationId) => donationId === user?._id);
    return isFavorite ? true : false;
  }

  /**
   * @description
   * ADD OR REMOVED AS FAVORITE
   * @param donationId
   * @param like 1- FAVORITE, 0 - NOT FAVORITE
   * @returns
   */
  favorite(donationId: string, like: number) {
    if (this.checkIfAuthenticated()) {
      return this.store
        .dispatch(new UpdateLikeCount(donationId, like))
        .pipe(untilDestroyed(this))
        .subscribe((store) => {
          if (store && store?.campaign?.donation) {
            this.donation = { ...store?.campaign?.donation };
          }
        });
    } else {
      this.redirect();
    }
  }

  /**
   * @description
   * CHECK IF AUTHENTICATED
   * @returns
   */
  checkIfAuthenticated() {
    const user = this.store.selectSnapshot(UserState.user);
    return user;
  }

  /**
   * @description
   * REDIRECT TO AUTH APP
   */
  redirect() {
    window.location.href =
      environment?.apps?.authentication?.url +
      '?returnUrl=' +
      encodeURIComponent(environment.app.url + this.location.path());
  }
}
