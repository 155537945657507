import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';
import { ProfileOverviewComponent } from './profile-overview.component';

@NgModule({
  declarations: [ProfileOverviewComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    UserLogoAvatarModule,
  ],
  exports: [ProfileOverviewComponent],
})
export class ProfileOverviewModule {}
