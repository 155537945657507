import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownToolbarComponent } from './countdown-toolbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CountdownTimerModule } from './countdown-timer/countdown-timer.module';

@NgModule({
  declarations: [
    CountdownToolbarComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    CountdownTimerModule,
  ],
  exports:[
    CountdownToolbarComponent
  ]
})
export class CountdownToolbarModule { }