import { FormGroup } from '@angular/forms';

export interface DonationRg {
  id: string;
  user_id: string;
  amount: number;
  is_giftaid: boolean;
  currency: string;
  frequency: string;
  fee_amount: number;
  date: {
    date: string;
  };
  user: {
    user_id: number;
    email: string;
  };
  gateway: {
    name: string;
  };
  billing: {};
  recurring: number;
  status: number;
}

export interface DonationStep {
  step?: number;
  section?: string;
  path?: string;
  state?: string;
  errors?: string[];
  requiresLogin?: boolean;
  hideSummary?: boolean;
  hideProgress?: boolean;
  hideHeader?: boolean;
  showButtons?: boolean;
  hideTotal?: boolean;
  hideMobile?: boolean;
  valid?: boolean;
  form?: FormGroup;
  hideBack?: boolean;
  skip?: boolean;
}

export interface DonationTypeRg {
  name: string;
  id: number;
}

export class CreateDonationDto {
  readonly amount: number;
}
