import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharityLogoComponent } from './charity-logo.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogoCardModule } from '../logo-card/logo-card.module';

@NgModule({
  declarations: [CharityLogoComponent],
  imports: [CommonModule, FlexLayoutModule, LogoCardModule],
  exports: [CharityLogoComponent],
})
export class CharityLogoModule {}
