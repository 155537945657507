import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieDialogComponent } from './lottie-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LottieModule } from 'ngx-lottie';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LottieDialogComponent],
  imports: [CommonModule, MatDialogModule, LottieModule, FlexLayoutModule],
  exports: [LottieDialogComponent],
})
export class LottieDialogModule {}
