import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
// import { AnimationItem } from 'lottie-web';
// import { AnimationOptions, LottieTransferState } from 'ngx-lottie';

@Component({
  selector: 'givebrite-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
  constructor() // private lottieTransferState: LottieTransferState,
  // private ngZone: NgZone
  {
    // this.createOptions();
  }

  ngOnInit(): void {}

  // options: AnimationOptions = {
  //   path: 'assets/animations/loader.json',
  // };

  // options: AnimationOptions;

  // animationCreated(animationItem: AnimationItem): void {
  //   console.log(animationItem);
  //   NgZone.assertInAngularZone();
  //   console.log('animationCreated -> ', animationItem);
  // }

  // private createOptions(): void {
  //   const tranferredAnimationData = this.lottieTransferState.get('loader.json');

  //   if (tranferredAnimationData) {
  //     this.options = {
  //       animationData: tranferredAnimationData,
  //     };
  //   } else {
  //     this.options = {
  //       path: '/assets/animations/loader.json',
  //     };
  //   }
  // }

  // error(e) {
  //   console.log(e);
  // }
}
