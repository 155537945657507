export class SetError {
  static readonly type = '[App] Error';
  constructor(public error: any) {}
}

export class ResetErrors {
  static readonly type = '[App] Reset Errors';
}

export class GetLocale {
  static readonly type = '[App] Get Locale';
}

export class GetDonationTypesRg {
  static readonly type = '[App] Get Donation Types';
}

export class SaveFormStateRg {
  static readonly type = '[App] Save Form State';
  constructor(public form: any) {}
}
