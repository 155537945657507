<div class="powered-container">
  <div class="container">
    <p>
      Powered by <a href="https://givebrite.com/" target="_blank"><strong>Give Brite</strong></a>
    </p>
    <p>
      <a href="https://givebrite.com/page/terms" target="_blank">Terms of Use</a> •
      <a href="https://givebrite.com/page/privacy" target="_blank">Privacy Policy</a>
    </p>
  </div>
</div>
