import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HowItWorksComponent } from './how-it-works.component';
import { IconModule } from '@abhinavakhil/iconify-angular';

@NgModule({
  declarations: [HowItWorksComponent],
  imports: [
    CommonModule,
    IconModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
  ],
  exports: [HowItWorksComponent],
})
export class HowItWorksModule {}
