import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'givebrite-lottie-dialog',
  templateUrl: './lottie-dialog.component.html',
  styleUrls: ['./lottie-dialog.component.scss'],
})
export class LottieDialogComponent implements OnInit {
  successAnimationOptions: AnimationOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<LottieDialogComponent>
  ) {}

  ngOnInit(): void {
    this.successAnimationOptions = {
      path: this.data?.path,
    };
  }
}
