<div class="columns-dialog" fxLayout="column" fxLayoutAlign="center center">
  <h1 class="title" mat-dialog-title>Cancel subscription</h1>
  <div mat-dialog-content class="my-6">
    <!-- <mat-radio-group [(ngModel)]="cancel_at_period_end" fxLayout="column" aria-label="Cancel Subscription">
      <mat-radio-button class="mb-1" [checked]="true" [value]="0">Cancel Immediately</mat-radio-button>
      <mat-radio-button [value]="1">Cancel after the next donation</mat-radio-button>
    </mat-radio-group> -->
  </div>
  <div class="buttons">
    <button class="mr-3" (click)="cancel()" mat-raised-button color="warn">Yes</button>
    <button (click)="dont_cancel()" mat-raised-button color="warn">No</button>
  </div>
</div>
