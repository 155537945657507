import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignContentComponent } from './campaign-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [CampaignContentComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatFormFieldModule,
    FormsModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    IconModule,
    MatInputModule,
  ],
  exports: [CampaignContentComponent],
})
export class CampaignContentModule {}
