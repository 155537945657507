<div class="checkout-tip-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div>
      <h1 class="mb-4">Help us ensure the cost of giving is 0%. 🙏</h1>
    </div>

    <givebrite-close-icon
      mat-dialog-title
      fxLayout="row"
      fxLayoutAlign="end"
      (onClose)="closeDialog()"
    ></givebrite-close-icon>
  </div>

  <mat-divider class="mb-6"></mat-divider>

  <mat-dialog-content>
    <givebrite-tip-selector
      *ngIf="data.form"
      [currency]="data?.currency"
      [form]="data.form"
      (change)="onChange()"
    ></givebrite-tip-selector>

    <div fxLayoutAlign="center center" class="mt-6">
      <button mat-dialog-close mat-raised-button color="primary" class="w-full">Continue</button>
    </div>
  </mat-dialog-content>
</div>
