<!-- NOTE:
      DONATION TYPE HAS 2 VIEW (select-list-view OR flat-list-view),
      DEFAULT VIEW IS select-list-view, SELECT YOUR PREFERRED VIEW
      BY USING INPUT appearance="VIEW"
-->
<form class="donation-types">
  <!-- SELECT LIST VIEW -->
  <mat-form-field appearance="outline" class="w-full select-xs" *ngIf="selectListAppearance">
    <mat-label>Select donation type</mat-label>
    <mat-select placeholder="select donation type" [(ngModel)]="type" name="type">
      <mat-option [value]="type.name" *ngFor="let type of donationTypes">{{
        type.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- FLAT LIST VIEW -->
  <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-between" *ngIf="flatListAppearance">
    <givebrite-card-section
      *ngFor="let type of donationTypes; let i = index"
      [index]="i"
      [selectedIndex]="selectedType"
      (click)="onTypeChange(i, type?.name)"
      fxFlex="48%"
      class="mr-1"
    >
      <mat-card-content class="mb-0">
        <h4 class="font-bold mb-0 text-center">
          {{ type?.display }}
        </h4>
      </mat-card-content>
    </givebrite-card-section>
  </div>

  <!-- RADIO LIST VIEW -->
  <ng-container *ngIf="radioListAppearance">
    <mat-radio-group class="radio-group" fxLayout="column" [(ngModel)]="type">
      <mat-radio-button color="primary" *ngFor="let type of donationTypes" [value]="type.name"
        >{{ type.name }}
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <!-- TOGGLE VIEW -->
  <ng-container *ngIf="toggleViewAppearance">
    <div fxLayout="row" fxLayoutAlign="end center">
      <div class="mr-2 title font-semibold">ZAKAT</div>
      <mat-slide-toggle [(ngModel)]="type"></mat-slide-toggle>
    </div>
  </ng-container>
</form>
