export interface Impact {
  name: string;
  amount: number;
  currency?: string;
  _id?: string;
}

export interface Appeal {
  countries: [];
  donation_type: [];
  products: [];
  _id: string;
  created_at: string;
  is_featured: boolean;
  options: any;
  country: string;
  active: boolean;
  icon: string;
  thumbnail: string;
  image: string;
  category: {
    _id: string;
    name: string;
  };
  description: string;
  currency: string;
  amount: number;
  code: string;
  name: string;
  price_type: string;
  slug?: string;
}

export interface Project {
  _id: string;
  active: boolean;
  code: string;
  created_at: string;
  description: string;
  image: string;
  name: string;
  thumbnail: string;
  is_root: boolean;
  slug?: string;
}

export interface Projects {
  active: boolean;
  code: string;
  cover: [
    {
      id: string;
      type: 'image' | 'video';
      thumbnail?: string;
      video?: {
        type: 'vimeo' | 'youtube';
        thumbnail: 'string';
        id: string;
      };
      is_old: boolean;
      image?: {
        cropped: {
          url: string;
          _id: string;
        };
        original: {
          url: string;
          _id: string;
        };
        cropperPosition: {
          x1: number;
          x2: number;
          y1: number;
          y2: number;
        };
      };
    }
  ];
  created_at: string;
  description: string;
  display_name: string;
  is_featured: boolean;
  is_root: boolean;
  name: string;
  options: [
    {
      donation_types: {
        active: boolean;
        code: string;
        default: boolean;
        name: string;
        order: number;
        type: string;
        _id: string;
      };
    }
  ];
  statistics: {
    appeals: number;
    average: number;
    donors: number;
    offline: number;
    raised: number;
  };
  slug: string;
  _id: string;
}
