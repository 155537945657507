import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseImpactsComponent } from './choose-impacts.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { CampaignSectionModule } from '../campaign-section/campaign-section.module';
import { DonateButtonModule } from '../donate-button/donate-button.module';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [ChooseImpactsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    NgxFlickingModule,
    TruncateStringModule,
    CampaignSectionModule,
    DonateButtonModule,
  ],
  exports: [ChooseImpactsComponent],
})
export class ChooseImpactsModule {}
