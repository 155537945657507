<!-- <mat-card class="mat-elevation-z3 rounded-xl mb-6" [class.last]="last">
  <mat-card-content>
    <div fxLayout="row">
      <div class="amount-avatar rounded-full z-10" fxLayout="row" fxLayoutAlign="center center">
        <h4 class="mb-0">{{ donation?.amount | currency: donation.currency:'symbol':'1.0-0' }}</h4>
      </div>

      <givebrite-user-logo-avatar
        [logo]="donation?.is_anonymous ? null : donation?.user?.profile_image"
        [name]="
          donation?.is_anonymous
            ? 'Anonymous'
            : donation?.user?.first_name + ' ' + donation?.user?.last_name
        "
        [border]="false"
        width="50px"
      ></givebrite-user-logo-avatar>

      <div fxLayout="column" class="w-full">
        <div fxLayout="column" fxLayoutAlign="start">
          <h3 class="font-semibold title mb-1">
            {{
              donation?.is_anonymous
                ? 'Anonymous donated' +
                  ' ' +
                  (donation?.amount | currency: donation.currency:'symbol':'1.0-0')
                : (donation?.user?.first_name | titlecase) +
                  ' ' +
                  donation?.user?.last_name +
                  ' ' +
                  'donated' +
                  ' ' +
                  (donation?.amount | currency: donation.currency:'symbol':'1.0-0')
            }}
          </h3>

          <p>{{ donation.created_at | dateAgo }}</p>
        </div>
      </div>
    </div>

    <div class="my-6">
      <h3 class="comment" *ngIf="donation?.comment?.length">
        {{ donation?.comment[0] }}
      </h3>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="start center" class="pt-6">
      <h6 fxLayout="row" fxLayoutAlign="center" class="mb-0 cursor-pointer" (click)="toggle()">
        <mat-icon class="mr-2" *ngIf="!favourite">favorite_border</mat-icon>
        <mat-icon class="mr-2 primary" *ngIf="favourite">favorite</mat-icon>Love
      </h6>
    </div>
  </mat-card-content>
</mat-card> -->

<div class="donation-card">
  <mat-card appearance="outlined" class="mat-elevation-z0">
    <mat-card-content>
      <div fxLayout="row" fxLayoutGap="10px">
        <givebrite-user-logo-avatar
          [logo]="donation?.is_anonymous ? null : donation?.user?.profile_image"
          [name]="
            donation?.is_anonymous
              ? 'Anonymous'
              : donation?.user?.first_name + ' ' + donation?.user?.last_name
          "
          [border]="false"
          width="50px"
        ></givebrite-user-logo-avatar>

        <div fxLayout="column" class="w-full">
          <div fxLayout="column" fxLayoutAlign="start">
            <h3 class="font-semibold title mb-1">
              {{
                donation?.is_anonymous
                  ? 'Anonymous donated' +
                    ' ' +
                    (donation?.amount | currency: donation.currency:'symbol':'1.0-0')
                  : (donation?.user?.first_name | titlecase) +
                    ' ' +
                    donation?.user?.last_name +
                    ' ' +
                    'donated' +
                    ' ' +
                    (donation?.amount | currency: donation.currency:'symbol':'1.0-0')
              }}
            </h3>

            <p>{{ donation.created_at | dateAgo }}</p>
          </div>

          <div class="py-1" *ngIf="donation?.comment?.length">
            <h4>{{ donation?.comment[0]?.message | titlecase }}</h4>
          </div>

          <div class="py-1" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon
              *ngIf="!is_favorite"
              class="mr-2 cursor-pointer"
              (click)="favorite(donation?._id, 1)"
              >favorite_border</mat-icon
            >
            <mat-icon
              *ngIf="is_favorite"
              class="mr-2 cursor-pointer favorite"
              (click)="favorite(donation?._id, 0)"
              >favorite</mat-icon
            >
            <span *ngIf="donation?.liked_by?.length" fxLayout="row" fxLayoutAlign="start center">{{
              donation?.liked_by?.length
            }}</span>
          </div>

          <div class="divider py-2" *ngIf="!last"></div>
        </div>
      </div>

      <div class="divider py-2" *ngIf="last"></div>
    </mat-card-content>
  </mat-card>
</div>
