import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, append } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CharityService } from '../charity.service';
import {
  CharityLoginSuccess,
  CharityUserSignup,
  CharitySignup,
  GetCampaignsByCharityId,
  GetCharity,
  GetEventsByCharityId,
  GetFundraisersByCharityId,
  GetPaymentGateways,
  CharityLoginFailure,
  GetCharityList,
  GetCharityCampaign,
  GetFundraiserCampaign,
  GetCharities,
  CharityStateError,
  GetGalleryImageByCharityId,
  UploadSingleFile,
  ConnectCharityGateway,
  CharityUpdate,
  ReSendCharityVerification,
  UpdateSingleFile,
  GetCharitiesForSearch,
  GatewayUpdate,
  GetCharitiesForApproval,
  VerifyCharity,
  GetSingleCharityForApproval,
  DownloadCharityDocument,
  RemoveCharityError,
  RemoveCharitySuccessMessage,
  CreateCharityDocumentUpload,
} from './charity.actions';
import { AuthService, LoginSuccess, Populate } from '../../user';
import { Navigate } from '@ngxs/router-plugin';
import { Campaign } from '../../campaign';
import { ApproveCharity, Charity, CharityStats, UploadedFile } from '../charity.interface';
import { Fundraiser } from '../../campaign/fundraiser.interface';
import { PaymentGateway } from '../../donation/donation.interface';
import { Pagination } from '../../pagination/pagination.interface';
import { Router } from '@angular/router';
import { JwtService } from '@givebrite/api';

export interface CharityStateModel {
  error: string;
  successMessage: string;
  loading: boolean;
  charity: Charity;
  charities: Charity[];
  campaigns: Campaign[];
  fundraisers: Fundraiser[];
  events: Event[];
  list: Charity[];
  user: {};
  paymentGateways: PaymentGateway[];
  chaityCampaigns: Campaign[];
  fundraiserCampaigns: Campaign[];
  pagination: Pagination;
  gallery: [];
  file: UploadedFile;
  searchCharity: {
    charityList: Charity[];
    pagination: Pagination;
  };
  approveCharity: ApproveCharity;
  approveCharities: {
    charityList: ApproveCharity[];
    pagination: Pagination;
  };
  documentLink: string;
  statistics: CharityStats;
}

const charityStateDefaults: CharityStateModel = {
  error: null,
  successMessage: null,
  loading: false,
  charity: null,
  charities: [],
  campaigns: [],
  fundraisers: [],
  events: [],
  paymentGateways: [],
  user: null,
  list: [],
  chaityCampaigns: [],
  fundraiserCampaigns: [],
  pagination: null,
  gallery: null,
  file: null,
  searchCharity: null,
  approveCharity: null,
  approveCharities: null,
  documentLink: null,
  statistics: null,
};

@State<CharityStateModel>({
  name: 'charity',
  defaults: charityStateDefaults,
})
@Injectable()
export class CharityState {
  constructor(
    private charityService: CharityService,
    private router: Router,
    private jwtService: JwtService,
    private authService: AuthService
  ) {}

  @Selector()
  static loading(state: CharityStateModel) {
    return state.loading;
  }
  @Selector()
  static error(state: CharityStateModel) {
    return state.error;
  }
  @Selector()
  static getSearchCharity(state: CharityStateModel) {
    return state.searchCharity;
  }

  @Selector()
  static getApproveCharity(state: CharityStateModel) {
    return state.approveCharities;
  }

  @Selector()
  static getSingleApproveCharity(state: CharityStateModel) {
    return state.approveCharity;
  }

  @Selector()
  static getDocumentLink(state: CharityStateModel) {
    return state.documentLink;
  }

  @Selector()
  static campaigns(state: CharityStateModel) {
    return state.campaigns;
  }
  @Selector()
  static getCharity(state: CharityStateModel) {
    return state.charity;
  }
  @Selector()
  static getCharities(state: CharityStateModel) {
    return state.charities;
  }
  @Selector()
  static getEvents(state: CharityStateModel) {
    return state.events;
  }

  @Selector()
  static getFundraisers(state: CharityStateModel) {
    return state.fundraisers;
  }

  @Selector()
  static paymentGateways(state: CharityStateModel) {
    return state.paymentGateways;
  }

  @Selector()
  static getCharityList(state: CharityStateModel) {
    return state.list;
  }

  @Selector()
  static getCharityCampaigns(state: CharityStateModel) {
    return state.chaityCampaigns;
  }

  @Selector()
  static getFundraiserCampaigns(state: CharityStateModel) {
    return state.fundraiserCampaigns;
  }

  @Selector()
  static errors(state: CharityStateModel) {
    return state.error;
  }
  @Selector()
  static pagination(state: CharityStateModel) {
    return state.pagination;
  }

  @Selector()
  static gallery(state: CharityStateModel) {
    return state.gallery;
  }

  @Selector()
  static stats(state: CharityStateModel) {
    return state.statistics;
  }

  @Selector()
  static successMessage(state: CharityStateModel) {
    return state.successMessage;
  }

  @Action(CharityUserSignup)
  charityUserSignup({ dispatch, patchState }: StateContext<CharityStateModel>, { payload }) {
    patchState({ loading: true });
    return this.charityService.charityUserSignup(payload).pipe(
      tap(
        (result) => {
          return dispatch(new CharityLoginSuccess(result.accessToken));
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(CharityStateError)
  CharityStateError({ patchState }: StateContext<CharityStateModel>, { error }) {
    patchState({
      error: error,
      loading: false,
    });
  }

  @Action(GetCharity)
  getCharity(
    { dispatch, getState, setState, patchState }: StateContext<CharityStateModel>,
    { slug, params }
  ) {
    patchState({ loading: true });
    return this.charityService.single(slug, params).pipe(
      tap(
        (result: Charity) => {
          const state = getState();
          setState({
            ...state,
            charity: result,
            statistics: result.statistics,
            loading: false,
          });
        },
        (error) => {
          patchState({
            loading: false,
          });
        }
      )
    );
  }

  @Action(UploadSingleFile)
  upload(
    { dispatch, getState, setState, patchState }: StateContext<CharityStateModel>,
    { file, charityId }: UploadSingleFile
  ) {
    patchState({ loading: true });
    return this.charityService.singleUpload(file, charityId).pipe(
      tap(
        (result) => {
          patchState({
            file: result,
            loading: false,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(UpdateSingleFile)
  updateUpload(
    { dispatch, getState, setState, patchState }: StateContext<CharityStateModel>,
    { file, charityId, fileId }: UpdateSingleFile
  ) {
    patchState({ loading: true });
    return this.charityService.updateSingleUpload(file, charityId, fileId).pipe(
      tap(
        (result) => {
          patchState({
            file: result,
            loading: false,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(CharitySignup)
  charitySignup({ dispatch, patchState }: StateContext<CharityStateModel>, { payload }) {
    patchState({ loading: true });
    return this.charityService.charitySignup(payload).pipe(
      tap(
        (result) => {
          return dispatch(new CharityLoginSuccess(result.accessToken));
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GetCampaignsByCharityId)
  getCampaignByCharityId(
    { getState, setState, patchState }: StateContext<CharityStateModel>,
    { filter_by, search, page, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.getCampaignsByCharityId(filter_by, search, page, limit).pipe(
      tap((result: { campaigns: Campaign[]; pagination: Pagination; error: any }) => {
        if (result.error == undefined) {
          var campaigns: Campaign[] = result.campaigns;
          if (page > 1) {
            campaigns = getState().campaigns.concat(result.campaigns);
          }
          patchState({
            campaigns: campaigns,
            loading: false,
            pagination: result.pagination,
          });
        } else {
          patchState({
            campaigns: [],
            loading: false,
            pagination: null,
            error: result.error,
          });
        }
      })
    );
  }

  @Action(CharityLoginSuccess)
  charityLoginSuccess({ dispatch, patchState }: StateContext<CharityStateModel>, { accessToken }) {
    patchState({
      error: null,
      loading: false,
    });

    // Save the JWT
    this.authService.setJwt(accessToken);
    dispatch(new Populate());
    // this.jwtService.saveToken(accessToken);
    // return this.router.navigate(['onboarding/identify']);
  }

  @Action(CharityLoginFailure)
  charityLoginFailure(
    { patchState }: StateContext<CharityStateModel>,
    action: CharityLoginFailure
  ) {
    patchState({
      error: action.payload,
      loading: false,
    });
  }

  @Action(GetFundraisersByCharityId)
  getFundraisersByCharityId(
    { getState, setState, patchState }: StateContext<CharityStateModel>,
    { slug }
  ) {
    patchState({ loading: true });
    return this.charityService.getFundraisersByCharityId(slug).pipe(
      map((fundraisers) => fundraisers.docs),
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          fundraisers: result,
          loading: false,
        });
      })
    );
  }

  @Action(ReSendCharityVerification)
  reSendCharityVerification({ patchState }: StateContext<CharityStateModel>) {
    patchState({ loading: true });
    return this.charityService.reSendCharityVerification().pipe(
      tap(
        (response) => {
          patchState({ loading: false, successMessage: response?.msg });
        },
        (error) => {
          patchState({ loading: false, error: error });
        }
      )
    );
  }

  @Action(GetEventsByCharityId)
  getEventsByCharityId(
    { getState, setState, patchState }: StateContext<CharityStateModel>,
    { id, page, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.getEventsByCharityId(id, page, limit).pipe(
      tap((result: { events: Event[]; pagination: Pagination; error: any }) => {
        if (result.error == undefined) {
          var eventData: Event[] = result.events;
          if (page > 1) {
            eventData = getState().events.concat(result.events);
          }
          patchState({
            events: eventData,
            loading: false,
            pagination: result.pagination,
          });
        } else {
          patchState({
            events: [],
            loading: false,
            pagination: null,
            error: result.error,
          });
        }
      })
    );
  }

  @Action(GetPaymentGateways)
  getPaymentGateways(
    { getState, setState, patchState, dispatch }: StateContext<CharityStateModel>,
    { charityId }
  ) {
    patchState({ loading: true });

    return this.charityService.getPaymentGateways(charityId).pipe(
      tap(
        (result) => {
          const state = getState();
          setState({
            ...state,
            events: result,
            paymentGateways: result,
            loading: false,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GatewayUpdate)
  gatewayUpdate({ dispatch, patchState }: StateContext<CharityStateModel>, { payload, gatewayId }) {
    patchState({ loading: true });
    return this.charityService.updateGateway(payload, gatewayId).pipe(
      tap(
        (result) => {
          // return dispatch(new CharityLoginSuccess(result.accessToken));
          console.log('result gateway update', result);
          // patchState({
          //   charity: result,
          //   loading: false,
          // });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GetCharityList)
  getCharityList(
    { patchState, setState }: StateContext<CharityStateModel>,
    { params }: GetCharityList
  ) {
    patchState({ loading: true });

    return this.charityService.list(params).pipe(
      tap(
        (result: { charities: Charity[]; pagination: Pagination }) => {
          patchState({
            loading: false,
            pagination: result.pagination,
          });
          if (params.append) {
            setState(
              patch({
                list: append(result.charities),
              })
            );
          } else {
            patchState({
              list: result.charities,
            });
          }
        },
        (error) => {
          // dispatch(new CharityStateError(error));
          patchState({
            list: [],
          });
        }
      )
    );
  }

  @Action(GetCharityCampaign)
  getCharityCampaigns(
    { dispatch, patchState }: StateContext<CharityStateModel>,
    { charity_id, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.campaign(charity_id, limit).pipe(
      tap(
        (result: Campaign[]) => {
          patchState({
            chaityCampaigns: result,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GetFundraiserCampaign)
  getFundraiserCampaigns(
    { dispatch, patchState }: StateContext<CharityStateModel>,
    { user_id, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.fundraiser(user_id, limit).pipe(
      tap(
        (result: Campaign[]) => {
          patchState({
            fundraiserCampaigns: result,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GetCharities)
  getCharities(
    { dispatch, patchState }: StateContext<CharityStateModel>,
    { filter_by, search, page, limit, featured }
  ) {
    patchState({ loading: true });
    return this.charityService
      .getUnregistredCharities(filter_by, search, page, limit, featured)
      .pipe(
        tap(
          (result) => {
            patchState({
              charities: result.docs,
              loading: false,
            });
          },
          (error) => {
            dispatch(new CharityStateError(error));
          }
        )
      );
  }

  @Action(GetGalleryImageByCharityId)
  getGalleryImageByCharityId({ dispatch, patchState }: StateContext<CharityStateModel>, { id }) {
    patchState({ loading: true });
    return this.charityService.getGalleryImageByCharityId(id).pipe(
      tap(
        (result) => {
          patchState({
            gallery: result,
            loading: false,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(ConnectCharityGateway)
  connectCharityGateway(
    { dispatch, patchState }: StateContext<CharityStateModel>,
    { charityId, gatewayName, data }: ConnectCharityGateway
  ) {
    patchState({ loading: true });
    return this.charityService.connectCharityGateway(charityId, gatewayName, data).pipe(
      tap(
        (result) => {
          this.authService.setJwt(result.accessToken);
          patchState({
            loading: false,
            paymentGateways: result,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(CharityUpdate)
  charityUpdate({ dispatch, patchState }: StateContext<CharityStateModel>, { payload }) {
    patchState({ loading: true });
    return this.charityService.updateCharity(payload).pipe(
      tap(
        (result) => {
          // return dispatch(new CharityLoginSuccess(result.accessToken));

          patchState({
            charity: result,
            loading: false,
          });
        },
        (error) => {
          dispatch(new CharityStateError(error));
        }
      )
    );
  }

  @Action(GetCharitiesForSearch)
  getCharitiesForSearch(
    { patchState, getState }: StateContext<CharityStateModel>,
    { filter_by, search, page, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.getCharities(filter_by, search, page, limit).pipe(
      tap(
        (result) => {
          if (result.error == undefined) {
            var charityies: Charity[] = result.docs;
            if (page > 1) {
              charityies = getState().searchCharity.charityList.concat(result.docs);
            }
            patchState({
              searchCharity: {
                charityList: charityies,
                pagination: {
                  limit: result.limit,
                  total: result.total,
                  page: result.page,
                  pages: result.pages,
                },
              },
              loading: false,
              error: null,
            });
          } else {
            patchState({
              searchCharity: result.docs,
              loading: false,
            });
          }
        },
        (error) => {
          patchState({
            searchCharity: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetCharitiesForApproval)
  getCharitiesForApproval(
    { patchState, getState }: StateContext<CharityStateModel>,
    { page, limit }
  ) {
    patchState({ loading: true });
    return this.charityService.getCharitiesForApproval(page, limit).pipe(
      tap(
        (result) => {
          if (result.error == undefined) {
            let charityies: ApproveCharity[] = result.docs;
            if (page > 1) {
              charityies = getState()?.approveCharities?.charityList?.concat(result.docs);
            }
            patchState({
              approveCharities: {
                charityList: charityies,
                pagination: {
                  limit: result.limit,
                  total: result.total,
                  page: result.page,
                  pages: result.pages,
                },
              },
              loading: false,
              error: null,
            });
          } else {
            patchState({
              approveCharities: result.docs,
              loading: false,
            });
          }
        },
        (error) => {
          patchState({
            approveCharities: null,
            loading: false,
            error: error,
          });
        }
      )
    );
  }

  @Action(GetSingleCharityForApproval)
  getSingleCharityForApproval({ patchState }: StateContext<CharityStateModel>, { charityId }) {
    patchState({ loading: true });
    return this.charityService.getSingleCharityForApproval(charityId).pipe(
      tap(
        (result) => {
          patchState({
            approveCharity: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(VerifyCharity)
  verifyCharity({ patchState }: StateContext<CharityStateModel>, { charityId, type }) {
    patchState({ loading: true });
    return this.charityService.verifyCharity(charityId, type).pipe(
      tap(
        (result) => {
          console.log('result', result);
        },
        (error) => {
          patchState({
            error: error,
          });
        }
      )
    );
  }

  @Action(DownloadCharityDocument)
  downloadCharityDocument({ patchState }: StateContext<CharityStateModel>, { documentLink }) {
    patchState({ loading: true });
    return this.charityService.downloadCharityDocument(documentLink).pipe(
      tap(
        (result) => {
          patchState({ documentLink: result?.dLink, loading: false });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(RemoveCharityError)
  removeDashBoardError({ patchState }: StateContext<CharityStateModel>) {
    patchState({
      error: null,
    });
  }

  @Action(RemoveCharitySuccessMessage)
  removeSuccessMessage({ patchState }: StateContext<CharityStateModel>) {
    patchState({
      successMessage: null,
    });
  }

  @Action(CreateCharityDocumentUpload)
  createDocumentUpload({ dispatch}: StateContext<CharityStateModel>, { payload }) {
    return this.charityService.docUpload(payload).pipe(
      tap(
        (result) => {
          this.authService.setJwt(result.accessToken);
          dispatch(new Populate());
        },
        (error) => {
        }
      )
    );
  }
}
