import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '@givebrite/data';

@Component({
  selector: 'dashboard-charities-filters',
  templateUrl: './charities-filters.component.html',
  styleUrls: ['./charities-filters.component.scss'],
})
export class CharitiesFiltersComponent {

  @Input() filterBy;
  navlink;
  constructor(private route: ActivatedRoute, private dashboardService: DashboardService) {
    this.navlink = this.dashboardService.charitiesNavlink;
  }

  get active() {
    return this.filterBy;
  }

  get currentIndex() {
    return this.navlink.findIndex((step) => step.link == this.active);
  }
}
