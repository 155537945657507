<div class="avatar" [class.uploaded]="!!file">
  <div class="avatar-preview" fxLayoutAlign="cneter center">
    <button
      (click)="openMediaUpload()"
      class="mx-auto upload-button"
      mat-fab-button
    >
      <mat-icon class="material-icons-outlined">file_upload</mat-icon>
    </button>
    <img *ngIf="file" [src]="file" />
  </div>
</div>
