import { getCurrencySymbol } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { GivebriteConfig, GivebriteState } from '@givebrite/data';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'givebrite-campaign-amount',
  templateUrl: './campaign-amount.component.html',
  styleUrls: ['./campaign-amount.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CampaignAmountComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CampaignAmountComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignAmountComponent implements ControlValueAccessor, Validator {
  @Select(GivebriteState.config) config$: Observable<GivebriteConfig>;
  @Input() impacts = true;
  _goal: number;
  touched = false;
  disabled = false;
  defaultAmounts: Array<number> = [100, 250, 500, 1000, 2500, 5000];

  onChange = (goal: number) => {};
  onTouched = () => {};

  writeValue(goal: number) {
    this.goal = goal;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const goal = control.value;
    if (goal <= 0) {
      return {
        mustBePositive: {
          goal,
        },
      };
    }
  }

  constructor() {}

  set goal(goal: number) {
    this._goal = goal;
    this.onChange(+this.goal);
  }

  get goal() {
    return this._goal;
  }

  currencySymbol(code: string): string {
    return getCurrencySymbol(code, 'narrow');
  }
}
