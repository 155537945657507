import { Pagination } from '@givebrite/data';
import { Subscription } from '../subscription.interface';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CancelSubscription,
  ConfirmSubscription,
  CreateSubscription,
  CreateSubscriptionDraft,
  GetStatistics,
  GetSubscription,
  GetSubscriptionLeaderboard,
  GetSubscriptions,
  ResetSubscription,
  SaveSteps,
  SaveSubscriptionState,
  SetDefaultPaymentMethod,
  UpdateStep,
  UpdateSubscription,
  GetSettings,
} from './subscription.action';

import { Injectable } from '@angular/core';
import { patch, updateItem } from '@ngxs/store/operators';
import { map, tap } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';
import { DonationStep } from '../../donation/donation.interface';

export class SubscriptionStateModel {
  subscriptions?: Subscription[];
  subscription?: Subscription;
  pagination?: Pagination;
  form?: any;
  loading?: boolean;
  stats?: {
    currency: string;
    donations_sum: number;
    total_givers: number;
    projection_amount: number;
  };
  steps?: DonationStep[];
  settings?: any;
}

@State<SubscriptionStateModel>({
  name: 'subscriptions',
  defaults: {
    subscriptions: [],
    subscription: null,
    pagination: null,
    form: null,
    loading: false,
    steps: null,
    stats: null,
    settings: null,
  },
})
@Injectable()
export class SubscriptionState {
  constructor(private subscriptionService: SubscriptionService) {}

  @Selector()
  static getSteps(state: SubscriptionStateModel) {
    return state.steps;
  }

  @Selector()
  static getStats(state: SubscriptionStateModel) {
    return state.stats;
  }

  @Selector()
  static getSubscriptionList(state: SubscriptionStateModel) {
    return state.subscriptions;
  }

  @Selector()
  static getForm(state: SubscriptionStateModel) {
    return state.form;
  }

  @Selector()
  static getSubscription(state: SubscriptionStateModel) {
    return state.subscription;
  }

  @Selector()
  static getPagination(state: SubscriptionStateModel) {
    return state.pagination;
  }

  @Selector()
  static loading(state: SubscriptionStateModel) {
    return state.loading;
  }

  @Selector()
  static form(state: SubscriptionStateModel) {
    return state.form;
  }

  @Selector()
  static settings(state: SubscriptionStateModel) {
    return state.settings;
  }

  @Action(SaveSteps)
  saveSteps({ patchState }: StateContext<SubscriptionStateModel>, { steps }: SaveSteps) {
    patchState({
      steps: steps,
    });
  }

  @Action(UpdateStep)
  nextStep({ setState }: StateContext<SubscriptionStateModel>, { updatedStep }: UpdateStep) {
    setState(
      patch({
        steps: updateItem<DonationStep>((step) => step.step === updatedStep.step, updatedStep),
      })
    );
  }

  @Action(GetSubscriptions)
  getSubscriptions(
    { patchState, getState, setState }: StateContext<SubscriptionStateModel>,
    { charityId, limit, page, filter, sort, column, download }
  ) {
    const state = getState();
    // Add loading state
    patchState({ loading: true });
    return this.subscriptionService
      .list(charityId, limit, page, filter, sort, column, download)
      .pipe(
        tap((result) => {
          setState({
            ...state,
            loading: false,
            pagination: result,
            subscriptions: result.docs,
          });
        })
      );
    //}
  }

  @Action(GetSubscriptionLeaderboard)
  getSubscriptionLeaderboard(
    { patchState, getState, setState }: StateContext<SubscriptionStateModel>,
    { limit, page, filter, sort, column }
  ) {
    const state = getState();
    // Add loading state
    patchState({ loading: true });
    return this.subscriptionService.leaderboard(limit, page, filter, sort, column).pipe(
      tap((result) => {
        setState({
          ...state,
          loading: false,
          stats: result.stats,
          pagination: result.pagination,
          subscriptions: result.donors,
        });
      })
    );
    //}
  }

  @Action(GetSubscription)
  getSubscription(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { id }
  ) {
    patchState({ loading: true });
    return this.subscriptionService.get(id).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          subscription: result,
          loading: false,
        });
      })
    );
  }

  @Action(CreateSubscription)
  createSubscription(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { payload }
  ) {
    patchState({ loading: true });
    return this.subscriptionService.create(payload).pipe(
      tap(
        (result) => {
          console.log(result);
          const state = getState();
          setState({
            ...state,
            subscription: result,
            loading: false,
          });
        },
        (error) => {
          console.log(error);
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(CreateSubscriptionDraft)
  createSubscriptionDraft(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { payload }
  ) {
    setState({
      subscription: {
        charity: payload.charity,
        campaign: payload.campaign,
        amount: payload.amount,
        is_giftaid: false,
        currency: payload.currency,
        frequency: payload.frequency,
        total: 0,
        days: 0,
        start_date: payload.start_date,
        end_date: payload.end_date,
        interval: payload.interval,
        fees: {
          fee_percentage: payload.fees.is_fee_covered,
          fee_amount: payload.fees.fee_amount,
          // fee_percentage: payload.fee_percentage
        },
        is_zakat: 0,
        type: '',
      },
    });
  }

  @Action(UpdateSubscription)
  updateSubscription(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { payload }
  ) {
    patchState({ loading: true });
    const state = getState();
    console.log(payload);
    return this.subscriptionService.update({ _id: state.subscription._id, ...payload }).pipe(
      tap(
        (result) => {
          console.log(result);
          setState({
            ...state,
            subscription: result,
            loading: false,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(ConfirmSubscription)
  confirmSubscription(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { payload }
  ) {
    patchState({ loading: true });
    return this.subscriptionService.confirm(payload).pipe(
      tap(
        (result) => {
          const state = getState();
          setState({
            ...state,
            subscription: result,
            form: null,
            loading: false,
            steps: null,
          });
        },
        (error) => {
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(ResetSubscription)
  resetSubscription(
    { getState, setState, patchState }: StateContext<SubscriptionStateModel>,
    { clearSub }
  ) {
    const state = getState();
    setState({
      ...state,
      loading: false,
      steps: null,
      // subscription: null
    });

    if (clearSub) {
      patchState({
        subscription: null,
      });
    }
  }

  @Action(SaveSubscriptionState)
  saveFormState(
    { patchState }: StateContext<SubscriptionStateModel>,
    { subscription }: SaveSubscriptionState
  ) {
    patchState({ subscription: subscription });
  }

  @Action(CancelSubscription)
  cancelSubscription(
    { getState, setState, patchState, dispatch }: StateContext<SubscriptionStateModel>,
    { id, cancel_at_period_end }
  ) {
    patchState({ loading: true });
    console.log(id);
    return this.subscriptionService.cancel(id, cancel_at_period_end).pipe(
      tap(
        (result) => {
          console.log(result);
          setState(
            patch({
              subscriptions: updateItem<Subscription>((sub) => sub._id === result._id, result),
            })
          );
        },
        (error) => {
          console.log(error);
          patchState({ loading: false });
          return error;
        }
      )
    );
  }

  @Action(GetStatistics)
  getStatistics({ patchState }: StateContext<SubscriptionStateModel>) {
    return this.subscriptionService.statistics().pipe(
      tap((result) => {
        // const state = getState();
        patchState({
          stats: result,
        });
      })
    );
  }

  @Action(GetSettings)
  getSettings({ patchState }: StateContext<SubscriptionStateModel>) {
    return this.subscriptionService.settings().pipe(
      tap((result) => {
        patchState({
          settings: result,
        });
      })
    );
  }

  @Action(SetDefaultPaymentMethod)
  setDefaultPaymentMethod(
    { setState, patchState, dispatch }: StateContext<SubscriptionStateModel>,
    { id, payment_method_id }
  ) {
    patchState({ loading: true });
    return this.subscriptionService.setDefaultPaymentMethod(id, payment_method_id).pipe(
      map((result) => {
        /*setState({
                subscription: result
            });*/

        dispatch(new GetSubscription(id));
      })
    );
  }
}
