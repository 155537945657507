<div class="donation-words-support">
  <mat-card appearance="outlined" *ngIf="donation">
    <mat-card-content class="mb-3" fxLayout="row" fxLayoutGap="10px">
      <givebrite-user-logo-avatar
        [logo]="donation?.is_anonymous ? null : donation?.user?.profile_image"
        [name]="
          donation?.is_anonymous ? 'Anonymous' : donation?.first_name + ' ' + donation?.last_name
        "
        [border]="false"
        width="40px"
        [customstyle]="customStyle"
      ></givebrite-user-logo-avatar>
      <div fxLayout="column" class="w-full">
        <!-- Donor name and date -->
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div class="donor">
            {{
              donation?.is_anonymous
                ? 'Anonymous'
                : donation?.first_name
                ? donation?.first_name
                : '' + ' ' + donation?.last_name
                ? donation?.last_name
                : ('' | titlecase)
            }}
          </div>

          <div fxLayout="column">
            <p *ngIf="donation?.offline" class="badge text-center px-2">offline</p>
          </div>
        </div>

        <!-- Amount and comment -->
        <div [class.offline]="donation?.offline">
          <div fxLayout="row" fxLayoutAlign="start">
            <div class="donation-amount">
              <span>{{ donation.amount | currency: donation.currency:'symbol':'1.0-0' }}</span>
              <ng-container *ngIf="donation.is_giftaid">
                <div class="gift-aid">
                  +{{
                    getGiftAmount(donation.amount) | currency: donation.currency:'symbol':'1.0-0'
                  }}
                  gift aid
                </div>
              </ng-container>
            </div>
            <div class="date" fxLayoutAlign="">
              &#9679;
              <span class="pl-2">{{ donation.created_at | dateAgo }}</span>
            </div>
          </div>
          <div class="comment" *ngIf="donation?.comment">
            {{ donation?.comment[0]?.message }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="!donation" class="mb-3">
    <mat-card-content fxLayoutGap="10px">
      <div class="w-full">
        <ngx-skeleton-loader
          [theme]="{ width: '40px', height: '40px', 'border-radius': '100px' }"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
          [theme]="{ width: '80%', position: 'relative', top: '-10px', left: '10px' }"
        ></ngx-skeleton-loader>
      </div>

      <div fxLayout="column" class="w-full">
        <ngx-skeleton-loader
          class="w-full"
          [theme]="{ width: '100%', 'margin-bottom': '0px', 'margin-top': '10px' }"
        ></ngx-skeleton-loader>
      </div>
    </mat-card-content>
  </mat-card>
</div>
