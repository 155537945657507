<form [formGroup]="form" class="basic-form">
  <div fxLayout="column">
    <div>
      <mat-form-field appearance="outline" class="w-full">
        <input
          matInput
          formControlName="name"
          id="campaign-name-input"
          placeholder="Campaign name"
          focused="true"
          maxlength="100"
        />
        <mat-hint>Please use a descriptive title for your campaign</mat-hint>
        <mat-hint align="end">{{ form.controls.name.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" class="w-full">
        <textarea
          matInput
          formControlName="description"
          id="campaign-description-input"
          placeholder="Campaign description"
          focused="true"
          maxlength="50"
        ></textarea>
        <mat-hint align="end">{{ form.controls.description.value?.length || 0 }}/50</mat-hint>
      </mat-form-field>
    </div>

    <!--div class="row">
      <div class="col-12" style="margin-bottom: 20px">
        <p>How long would you like this campaign to run for?</p>
        <date-toggle [campaign]="campaign" [fg]="form"></date-toggle>
      </div>
      <mat-form-field appearance="outline" class="col-4">
            <input matInput formControlName="moment_end" (dateChange)="setEndDate($event)" [matDatepicker]="end" placeholder="Choose an end date" />
            <mat-datepicker-toggle matSuffix [for]="end"></!--mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
            <mat-error *ngIf="form.controls.end.invalid">Please enter a valid date</mat-error>
          </mat-form-field>
    </div-->

    <div class="row">
      <div class="col-12">
        <!--slug [campaign]="true" [value]="campaign?.slug" [formGroup]="form" (checkSlug)="checkSlug($event)"></!--slug-->
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 form-label">
            How much are you trying to raise?*
          </div>

          <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100px" class="mr-3">
              <mat-select
                placeholder="Select an option"
                disableRipple
                id="currency-select"
                placeholder="Currency"
                formControlName="currency"
              >
                <mat-option [value]="currency.code" *ngFor="let currency of currencies">{{ currency.code }}</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex>
              <mat-form-field appearance="outline" class="w-100">
                <span matPrefix>{{ symbol }} &nbsp;</span>
                <mat-label>Fundraising goal</mat-label>
                <input formControlName="goal" matInput />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
