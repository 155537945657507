<mat-dialog-content class="add-payment-method">
  <form [formGroup]="billing" class="mt-2">
    <!-- <stripe-card
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
      (change)="cardUpdated($event)"
      (error)="setErrorMessage($event)"
    ></stripe-card> -->

    <div ngxStripeCardGroup [elementsOptions]="elementsOptions" *ngIf="true">
      <div class="stripe-element">
        <ngx-stripe-card-number
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
          (change)="cardUpdated($event)"
          (error)="setErrorMessage($event)"
        ></ngx-stripe-card-number>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" class="pt-3">
        <div class="w-full stripe-element">
          <ngx-stripe-card-expiry
            [options]="cardOptions"
            (change)="cardUpdated($event)"
          ></ngx-stripe-card-expiry>
        </div>
        <div class="w-full stripe-element">
          <ngx-stripe-card-cvc
            [options]="cardOptions"
            (change)="cardUpdated($event)"
          ></ngx-stripe-card-cvc>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="mt-3">
      <mat-form-field appearance="outline" class="w-full rg-payment-form">
        <mat-select formControlName="title" placeholder="Select Your Title">
          <mat-option *ngFor="let title of titles" [value]="title">
            {{ title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" gdGap="2%">
        <mat-form-field appearance="outline" class="rg-payment-form">
          <input formControlName="first_name" matInput placeholder="First Name" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="rg-payment-form">
          <input formControlName="last_name" matInput placeholder="Last Name" />
        </mat-form-field>
      </div>

      <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="help mb-3">
        <div>I want to remain anonymous<mat-icon>help</mat-icon></div>
        <mat-slide-toggle formControlName="is_anonymous"></mat-slide-toggle>
      </div> -->

      <mat-form-field appearance="outline" class="w-full rg-payment-form">
        <input matInput placeholder="Email Address" formControlName="email" />
      </mat-form-field>

      <!-- POSTCODE -->
      <mat-form-field appearance="outline" class="rg-payment-form">
        <input
          type="text"
          placeholder="Enter postcode / zip"
          aria-label="Address"
          matInput
          formControlName="postcode"
          oninput="this.value = this.value.toUpperCase()"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          [displayWith]="displayFn"
          (optionSelected)="selected($event)"
          autoActiveFirstOption
          #auto="matAutocomplete"
        >
          <ng-container
            *ngIf="{
              results: (predictions | async),
              loading: (postCodeloading$ | async)
            } as postcode"
          >
            <!-- LOADER -->
            <mat-option disabled class="spinner" *ngIf="postcode.loading">
              <mat-spinner diameter="35"></mat-spinner>
            </mat-option>

            <!-- NOT FOUND -->
            <mat-option
              disabled
              class="spinner"
              *ngIf="!postcode.results && !postcode.loading && !postAddress"
            >
              postcode / zip not found, please try again
            </mat-option>

            <!-- ADDRESS LIST -->
            <ng-container *ngIf="!postcode.loading">
              <mat-option *ngFor="let option of postcode.results" [value]="option">
                {{
                  option.line_1 +
                    ' ' +
                    option.line_2 +
                    ',' +
                    option.district +
                    ',' +
                    option.county +
                    ',' +
                    option.country
                }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline" class="rg-payment-form">
        <input matInput placeholder="Address Line 1" formControlName="address_line_1" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="rg-payment-form">
        <input matInput placeholder="Town or City" formControlName="town_or_city" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full rg-payment-form">
        <input
          matInput
          placeholder="Building Name or Number"
          formControlName="building_name_number"
        />
      </mat-form-field>
      <!-- <mat-form-field class="w-full">
        <mat-label>Mobile</mat-label>
        <input matInput placeholder="Mobile" formControlName="mobile" />
      </mat-form-field> -->

      <!-- <mat-form-field appearance="outline" fxFlex>
      <mat-select placeholder="United Kingdom" formControlName="country">
        <mat-option value="GB">United Kingdom</mat-option>
      </mat-select>
    </mat-form-field> -->

      <!-- Used to display form errors -->
      <!-- <div [class.hidden]="!error" class="error px-4 py-3 rounded relative mb-3">
      {{ error }}
    </div> -->
    </div>
  </form>

  <display-message [error]="(error$ | async)?.message"></display-message>
</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="text-center w-full mb-2">
    <button
      fxFlex="80%"
      [disabled]="loading$ | async"
      color="primary"
      class="mr-2 btn-large w-full primary"
      mat-raised-button
      (click)="onPay()"
    >
      Confirm
    </button>

    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      [disabled]="loading$ | async"
      mat-button
      (click)="cancelAdd()"
      fxFlex="20%"
      class="secondary"
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
