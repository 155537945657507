<div class="fundraisers mt-8" fxLayout="column" fxFlexAlign="center">
  <h2 class="font-semibold py-6 text-center md:text-left" [class.pb-0]="carouselViewAppearance">
    Fundraisers

    <span *ngIf="(fundraisers$ | async)?.length" class="badge">{{
      (fundraisersPagination$ | async)?.total
    }}</span>
  </h2>

  <h3 *ngIf="carouselViewAppearance" class="pb-6 text-center sm:text-left">
    Sign up and join our thousands of people raising for the cause they care
  </h3>

  <!-- LIST VIEW -->

  <ng-container *ngIf="listViewAppearance">
    <div
      fxFlex
      fxLayout="row wrap"
      fxLayoutGap="1rem grid"
      class="my-6"
      *ngIf="
        (fundraisers$ | async)?.length && fundraisers$ | async as fundraisers;
        else noFundraisers
      "
    >
      <givebrite-fundraiser
        *ngFor="let fundraiser of fundraisers"
        [fundraiser]="fundraiser"
      ></givebrite-fundraiser>
    </div>
  </ng-container>

  <!-- CAROUSEL VIEW -->

  <ng-container *ngIf="carouselViewAppearance">
    <ngx-flicking
      *ngIf="
        (fundraisers$ | async)?.length && fundraisers$ | async as fundraisers;
        else noFundraisers
      "
      [options]="{ bound: true, align: 'prev' }"
      class="overflow-x-scroll"
    >
      <givebrite-fundraiser
        *ngFor="let fundraiser of fundraisers"
        [fundraiser]="fundraiser"
        class="mr-3"
        [view]="'carousel-view'"
      ></givebrite-fundraiser>
    </ngx-flicking>
  </ng-container>

  <!-- SHOW TOP LEADERBOARD & SEARCH -->

  <ng-container *ngIf="showTopLeaderboard">
    <ng-container
      *ngIf="{
        results: topFundraisers,
        loader: (topFundraiserLoader$ | async)
      } as topFundraiserList"
    >
      <!-- TOP FUNDRAISER LIST  -->

      <ng-container *ngIf="!topFundraiserList.loader && topFundraiserList.results?.length">
        <div class="mt-6 mb-20" fxLayout="row" fxLayoutAlign="center center">
          <givebrite-top-leaderboard
            *ngFor="let fundraiser of topFundraiserList.results; let index = index; let even = even"
            [even]="even"
            [index]="index"
            [fundraiser]="fundraiser"
            [topFundraisers]="topFundraiserList.results"
          ></givebrite-top-leaderboard>
        </div>
      </ng-container>

      <!-- LOADER -->

      <ng-container *ngIf="topFundraiserList?.loader">
        <div class="mt-6 mb-20" fxLayout="row" fxLayoutAlign="center center">
          <div *ngFor="let fundraiser of [].constructor(3); let index = index; let even = even">
            <givebrite-top-leaderboard
              [even]="even"
              [index]="index"
              [fundraiser]="fundraiser"
              [topFundraisers]="topFundraiserList.results"
            ></givebrite-top-leaderboard>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- CARD VIEW -->

  <ng-container *ngIf="cardViewAppearance">
    <!-- SEARCH -->
    <form fxLayoutAlign="center center" class="search-input">
      <mat-form-field fxFlex="100%">
        <mat-icon matPrefix style="color: #c9d4dc">search</mat-icon>

        <input
          matInput
          placeholder="Search for fundraisers"
          name="search"
          class="relative -top-1"
          [(ngModel)]="searchFundraiser"
          #fundraiserInput
        />

        <mat-spinner diameter="20" matSuffix *ngIf="spinner$ | async"></mat-spinner>
      </mat-form-field>
    </form>

    <div
      *ngIf="
        (fundraisers$ | async)?.length && fundraisers$ | async as fundraisers;
        else noFundraisers
      "
    >
      <div *ngFor="let fundraiser of fundraisers; let i = index">
        <leaderboard-fundraiser-card
          *ngIf="i > 2 || fundraisers.length < 4"
          class="my-1"
          [fundraiser]="fundraiser"
          [maxFundraisersLimitReached]="maxFundraisersLimitReached"
          [index]="i"
        ></leaderboard-fundraiser-card>
      </div>
    </div>
  </ng-container>

  <ng-template #noFundraisers>No Fundraisers to show</ng-template>

  <h4
    class="font-medium cursor-pointer"
    fxLayout="row"
    fxLayoutAlign="center center"
    [class.disabled]="loading"
    *ngIf="!maxFundraisersLimitReached && listViewAppearance"
    (click)="moreFundraisers()"
  >
    Load More <mat-icon>expand_more</mat-icon>
  </h4>
</div>
