<div class="hero relative overflow-x-hidden">
  <ngx-flicking
    #hero
    class="z-0"
    [options]="{
      circular: true,
      align: 'prev',
      autoResize: true,
      autoPlay: true
    }"
    [plugins]="plugins"
    [fxHide]="loading"
  >
    <div
      flicking-panel
      class="flicking-panel w-full relative"
      *ngFor="let slide of _cover; let index = index"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-full h-full">
        <div fxLayout="row" fxLayoutAlign="center center" class="w-full">
          <div>
            <img
              *ngIf="slide?.image"
              class="hero"
              src="assets/{{ slide?.image }}"
              [class.top]="slide?.type === 'qg'"
              [class.gbImg]="slide?.type === 'gb'"
            />
          </div>

          <h1 *ngIf="slide?.type === 'qg'"><span class="qg-text">Sheep</span> Qurbani Prices</h1>
          <h1 *ngIf="slide?.type === 'dhg'" class="dhg-title">
            The Best <span class="dhg-text">10 Days</span> of The Year
          </h1>

          <h1
            *ngIf="slide?.type === 'gb'"
            class="qg-title"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            Back to
            <span class="qg-text mr-2">
              <img class="w-full select-none gb-logo relative" src="assets/logo.svg" />
            </span>
          </h1>

          <h1 *ngIf="slide?.type === 'eid'" class="dhg-title">
            Eid <span class="qg-text">Mubarak</span>
          </h1>

          <a
            *ngIf="!slide?.type === 'eid'"
            mat-raised-button
            color="primary"
            class="rounded-full w-full md:w-fit cta-btn btn-title ml-2 sm:ml-6"
            href="{{ slide.link }}"
            [class]="slide.type"
          >
            {{ slide.cta1 }}
          </a>

          <ng-lottie [options]="options" *ngIf="slide?.type === 'eid'"></ng-lottie>

          <div class="sm:ml-4">
            <a
              mat-raised-button
              color="primary"
              class="rounded-full w-full md:w-fit cta-btn btn-title"
              href="{{ slide.link }}"
              [class]="slide.type"
            >
              {{ slide.cta1 }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <span in-viewport class="flicking-arrow-prev" fxHide.xs></span>
    <span in-viewport class="flicking-arrow-next" fxHide.xs></span>
    <div in-viewport class="flicking-pagination" fxHide.xs></div>
  </ngx-flicking>
</div>
