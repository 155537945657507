import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressListModule } from "./address-list/address-list.module";
import { AddEditAddressModule } from "./add-edit-address/add-edit-address.module";
import { DeleteAddressDialogModule } from "./dialogs/delete-address-dialog/delete-address-dialog.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AddressListModule,
    AddEditAddressModule,
    DeleteAddressDialogModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  exports: [AddressListModule]
})
export class BillingModule {}
