import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campaign } from 'libs/data/src/lib/campaign/campaign.interface';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
import { Team } from 'libs/data/src/lib/campaign/team.interface';

@Component({
  selector: 'givebrite-donations-list-dialog',
  templateUrl: './donations-list-dialog.component.html',
  styleUrls: ['./donations-list-dialog.component.scss'],
})
export class DonationsListDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DonationsListDialogComponent>
  ) {}

  ngOnInit(): void {}

  get campaign(): Campaign {
    return this.data.campaign;
  }

  get fundraiser(): Fundraiser {
    return this.data.fundraiser;
  }

  get team(): Team {
    return this.data.team;
  }

  get activePage(): string {
    return this.data.activePage;
  }

  get isCampaignActive(): boolean {
    return this.activePage === 'campaign';
  }

  get isFundraiserActive(): boolean {
    return this.activePage == 'fundraiser';
  }

  get isTeamActive(): boolean {
    return this.activePage == 'team';
  }

  /**
   * close dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
