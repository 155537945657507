import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignInspirationComponent } from './campaign-inspiration.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TickMarkModule } from '../../tick-mark/tick-mark.module';

@NgModule({
  declarations: [CampaignInspirationComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    TickMarkModule,
  ],
  exports: [CampaignInspirationComponent],
})
export class CampaignInspirationModule {}
