import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseCustomImpactsComponent } from './choose-custom-impacts.component';
import { DonateButtonModule } from '../donate-button/donate-button.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { ShareDialogModule } from '../dialogs/share-dialog/share-dialog.module';

@NgModule({
  declarations: [ChooseCustomImpactsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    DonateButtonModule,
    NgxFlickingModule,
    ShareDialogModule,
  ],
  exports: [ChooseCustomImpactsComponent],
})
export class ChooseCustomImpactsModule {}
