// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint: 'https://mock-gb-api.herokuapp.com/index.php/v1',
  endpoints: {
    // donation: 'https://payments-live.herokuapp.com/v1',
    // charity: 'https://charity-live.herokuapp.com/v1',
    campaign: 'https://campaigns-live.herokuapp.com/v1',
    donation: 'https://backend-v2-payment-t53pfizcs0u.herokuapp.com/v1',
    charity: 'https://charity-testing-v2.herokuapp.com/v1',
    // campaign: 'https://campaign-testing-v2.herokuapp.com/v1',
    auth: 'https://authentication-testing-v2.herokuapp.com/v1',
    home: 'https://dashboard-v2-live.herokuapp.com/v1',
    mock: '',
    blogs: '',
  },
  app: {
    url: 'http://givebrite.local:4000',
  },
  apps: {
    authentication: {
      url: 'http://auth.givebrite.local:2000',
    },
    donate: {
      url: 'http://donate.givebrite.local:2001',
    },
    campaign: {
      url: 'http://campaigns.givebrite.local:4300',
    },
    dashboard: {
      url: 'http://dashboard.givebrite.local:2002',
    },
    ramadhan: {
      url: 'https://ramadhangiving.com/',
    },
  },
  pixelId: '225434343113453',
  assetPath: 'assets',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
