import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
  UploaderOptions,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploadOutput,
} from 'ngx-uploader';
import { BehaviorSubject, Observable } from 'rxjs';
// import { cl } from '@iconify/icon

interface UploadFileWithPreview extends UploadFile {
  imagePreview?: string;
}

@Component({
  selector: 'givebrite-uploader-drop-zone',
  templateUrl: './uploader-drop-zone.component.html',
  styleUrls: ['./uploader-drop-zone.component.scss'],
})
export class UploaderDropZoneComponent implements OnInit {
  options: UploaderOptions;
  files: UploadFileWithPreview[] = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  currentFile: UploadFileWithPreview;
  @Output() image: EventEmitter<any> = new EventEmitter();
  croppedImage;
  cropperPosition;
  @Input() set events(events: Observable<string>) {
    events.subscribe((event) => {
      switch (event) {
        case 'cancel':
          console.log(event);
          this.cancel();
          break;
      }
    });
  }
  @Input() ratio;
  error$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {
    this.options = { concurrency: 100, maxUploads: 9999, maxFileSize: 2000000 }; // 2MB
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit(): void {}

  onUploadOutput(output: UploadOutput): void {
    console.log(output);
    this.files = [];
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: '/upload',
        //   method: 'POST',
        //   data: { foo: 'bar' }
        // };
        // this.uploadInput.emit(event);

        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: `${environment.endpoints.charity}/charity/documents/upload?charity_id=${this.charity}`,
        //   method: 'POST',
        //   file: output.file,
        //   headers: { Authorization: `Bearer ${this.auth.jwt}` },
        // };
        // this.uploadInput.emit(event);
        break;

      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          // this.http
          //   .post(
          //     `${environment.endpoints.charity}/charity/documents/upload?charity_id=${this.charity}`,
          //     { file: output.file.nativeFile }
          //   )
          //   .subscribe((re) => {
          //     console.log(re);
          //   });

          // this.store.dispatch(
          //   new UploadSingleFile({ file: output.file.nativeFile }, this.charity)
          // );
          this.previewImage(output.file).then((response) => {
            let file: any = Object.assign(output.file, {
              imagePreview: response,
            });
            // this._files.push(file);
            this.currentFile = file;
            this.originalFile = file.nativeFile;
          });

          // this._files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          // const index = this._files.findIndex(
          //   (file) =>
          //     typeof output.file !== 'undefined' && file.id === output.file.id
          // );
          // this._files[index] = output.file;
          this.currentFile = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed

        const removeItem = (items, i) =>
          items.slice(0, i - 1).concat(items.slice(i, items.length));

        this.files = removeItem(this.files, output);

        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'rejected':
        this.error$.next('Unsupported image or too large');
        break;
      case 'done':
        // The file is downloaded
        // this.onChange(output.file.response.url);
        // this.images.emit(['']);
        this.files = [...this.files, output.file.response.url];
        this.currentFile = undefined;
        this.onChange();
        break;
    }
  }

  previewImage(file: UploadFile) {
    const fileReader = new FileReader();
    return new Promise((resolve) => {
      fileReader.readAsDataURL(file.nativeFile);
      fileReader.onload = function (e: any) {
        resolve(e.target.result);
      };
    });
  }

  onChange() {
    // Close dialog?? Or handle
    const image = {
      cropped: this.DataToFormData(this.DataURIToBlob(this.croppedImage)),
      original: this.DataToFormData(this.originalFile),
      cropperPosition: this.cropperPosition,
    };
    this.image.emit(image);
  }

  DataToFormData(file: any) {
    let formData: FormData = new FormData();
    formData.append('file', file, 'file.png');
    return formData;
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  originalFile;
  imageChangedEvent;
  fileChangeEvent(event: any): void {
    if (event.target.files.length) {
      this.originalFile = event.target.files[0];
      console.log(event, this.originalFile);
      this.imageChangedEvent = event;
      // this.openCropper();
    }
  }

  cancel() {
    this.originalFile = undefined;
    this.currentFile = undefined;
    this.croppedImage = undefined;
    this.cropperPosition = undefined;
    this.imageChangedEvent = undefined;
  }

  imageCropped(croppedImage) {
    this.croppedImage = croppedImage.base64;
    this.cropperPosition = croppedImage.cropperPosition;

    const image = {
      file: this.currentFile,
      cropped: {
        formData: this.DataToFormData(this.DataURIToBlob(this.croppedImage)),
        base64: this.croppedImage,
      },
      original: {
        formData: this.DataToFormData(this.originalFile),
        image: this.originalFile,
      },
      cropperPosition: this.cropperPosition,
    };

    this.image.emit(image);
  }

  onError() {
    // this.error$.next('');
  }
}
