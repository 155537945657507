export interface DashboardStatistics {
  campaigns: {
    total: number;
    ongoing: number;
    expired: number;
  };
  donations: {
    total: number;
    today: number;
    current_week: number;
    current_month: number;
  };
  donors: {
    total: number;
    today: number;
    current_week: number;
    current_month: number;
  };
  charity: {
    subscriptions: number;
    donation_count: number;
    donations: number;
    campaigns: number;
    raised: number;
    canceled: number;
  };
  fundraiser: number;
  header: {
    logo: {
      white: string;
      color: string;
    };
  };
  name: string;
  team: number;
}

export interface DonationsStatistics {
  raised: number;
  donations: number;
  supporters: number;
  average_donation: number;
  avg_donation_per_supporter: number;
  subscriptions: number;
}
export interface UsersStatistics {
  raised: number;
  donations: number;
  supporters: number;
  average_donation: number;
  avg_donation_per_supporter: number;
  subscriptions: number;
}

export interface IQurbaniStatistics {
  totalSold: number;
  sharesSold: number;
  meals:number;
  projection_amount:number;
  qurbani:number;
  raised:number;
  sAnimal: number;
  lAnimal: number;
  Shares: number;
}

export interface DashboardFollowers {
  _id: string;
  follower: {
    social: {
      followers: number;
      following: number;
      likes: number;
    };
  };
  statistics: {
    campaigns: number;
    donations: number;
    raised: number;
  };
  email: string;
  profile_image?: any;
  verified: boolean;
  first_name: string;
  last_name: string;
}

export interface Stock {
  stock: {
    total: number;
    shares: number;
    sold: number;
  };
  _id: string;
  animal: {
    _id: string;
    type: string;
    size: string;
    name: string;
    total_shares: number;
  };
  enable_shares: boolean;
  country: {
    name: string;
    code: string;
    _id: string;
  };
  currency: string;
  price: number;
  share_price: number;
  band: string;
  charity: string;
  active: boolean;
  created_at: string;
  __v: number;
}

export interface Animal {
  _id: string;
  type: string;
  size: string;
  name: string;
  active: boolean;
  image: string;
  total_shares: number;
  meal_per_share: number;
  created_at: string;
  sold: number;
  total: number;
}

export interface QurbaniDonation {
  gateway_response: {
    status: string;
  };
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  currency: string;
  amount: number;
  paid: boolean;
  frequency: string;
  cart: {
    inventory:  {
      stock: Stock;
      _id: string;
      animal: string;
      enable_shares: boolean;
      country: {
        name: string;
        code: string;
        _id: string;
      };
      currency: string;
      price: number;
      share_price: number;
      band: string;
      charity: string;
      active: boolean;
      created_at: string;
      __v: number;
    };
    animal: {
      _id: string;
      type: string;
      size: string;
      name: string;
      total_shares: number;
    };
    quantity: number;
    size: string;
    shares: number;
    amount: number;
    _id: string;
  };
  charity: {
    _id: string;
    slug: string;
    name: string;
  };
  gateway: {
    _id: string;
    name: string;
  };
  created_at: string;
}