import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private http: ApiService) {}
  /**
   * Checkout
   */
  checkout(data) {
    return this.http.post('inventory/donate', data);
  }
}
