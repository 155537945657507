import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoCardComponent } from './logo-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResponsiveAssetsModule } from '../../../../../core/src/lib/directives/responsive.module';

@NgModule({
  declarations: [LogoCardComponent],
  imports: [CommonModule, MatProgressSpinnerModule, FlexLayoutModule, ResponsiveAssetsModule],
  exports: [LogoCardComponent],
})
export class LogoCardModule {}
