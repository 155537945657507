import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignSlideToggleComponent } from './campaign-slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CampaignAddSectionPlaceholderModule } from './../campaign-add-section-placeholder/campaign-add-section-placeholder.module';

@NgModule({
  declarations: [CampaignSlideToggleComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    CampaignAddSectionPlaceholderModule,
  ],
  exports: [CampaignSlideToggleComponent],
})
export class CampaignSlideToggleModule {}
