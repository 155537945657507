import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonationInfoComponent } from './donation-info.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DonationInfoComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [DonationInfoComponent],
})
export class DonationInfoModule {}
