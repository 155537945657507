import { Component, Input, OnInit } from '@angular/core';
import { CharityRg } from 'libs/data/src/lib/ramadhan-giving';

@Component({
  selector: 'givebrite-charity-logo',
  templateUrl: './charity-logo.component.html',
  styleUrls: ['./charity-logo.component.scss'],
})
export class CharityLogoComponent implements OnInit {
  @Input() charity: CharityRg;
  @Input() border: boolean;
  @Input() set size(size) {
    if (size) {
      this.styling = {
        width: size,
        height: size,
      };
    }
  }
  styling = {};

  constructor() {}

  ngOnInit() {}
}
