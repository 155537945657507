import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogoCardComponent } from "./logo-card.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [LogoCardComponent],
  imports: [CommonModule, MatProgressSpinnerModule, FlexLayoutModule],
  exports: [LogoCardComponent]
})
export class LogoCardModule {}
