import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'givebrite-logo-card',
  templateUrl: './logo-card.component.html',
  styleUrls: ['./logo-card.component.scss'],
})
export class LogoCardComponent implements OnInit {
  @Input() format: string = 'jpg';
  @Input() logo: string;
  @Input() width: number = 50;
  @Input() height: number = 50;
  loaded: boolean;
  constructor() {}

  ngOnInit() {}
}
