import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CharityShowcaseItemModule } from './charity-showcase-item/charity-showcase-item.module';
import { CharityShowcaseComponent } from './charity-showcase.component';
import { RouterModule } from '@angular/router';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { CharityLogoModule } from '../view-templates/charity-logo/charity-logo.module';
import { LogoCardModule } from '../view-templates/logo-card/logo-card.module';

@NgModule({
  declarations: [CharityShowcaseComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    CharityShowcaseItemModule,
    CoreModule,
    MatIconModule,
    IconModule,
    MatButtonModule,
    RouterModule,
    MatProgressSpinnerModule,
    LogoCardModule,
    CharityLogoModule,
  ],
  exports: [CharityShowcaseComponent],
})
export class CharityShowcaseModule {}
