<mat-dialog-content class="video-dialog">
  <mat-icon
    [icIcon]="close"
    class="absolute right-1 top-1 close cursor-pointer"
    (click)="closeDialog()"
  ></mat-icon>

  <div class="video-container mt-10" fxLayoutAlign="center">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</mat-dialog-content>
