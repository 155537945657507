import { Component, Input, OnInit } from '@angular/core';
import { Campaign } from '@givebrite/data';
@Component({
  selector: 'campaign-card-mobile',
  templateUrl: './campaign-card-mobile.component.html',
  styleUrls: ['./campaign-card-mobile.component.scss'],
})
export class CampaignCardMobileComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() width: string = '100%';
  thumbnailLoaded: boolean; // Whether the image has been loaded or no

  constructor() {}

  ngOnInit(): void {}

  get percentage() {
    return (this.campaign.statistics.raised / this.campaign.goal) * 100;
  }
}
