import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonationsListDialogComponent } from './donations-list-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CloseIconModule } from '../../../utils/close-icon/close-icon.module';
import { CampaignDonationModule } from '../campaign-donations.module';

@NgModule({
  declarations: [DonationsListDialogComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    IconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    // ToastrModule.forRoot(),
    MatDialogModule,
    // ClipboardModule,
    CloseIconModule,
    CampaignDonationModule,
  ],
  exports: [DonationsListDialogComponent],
})
export class DonationsListDialogModule {}
