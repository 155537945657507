<mat-card appearance="outlined" class="tips p-3">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="tip-text font-medium">Voluntary contribution</div>
      <div class="tip-text font-medium">
        {{ form.get('fees.amount').value | currency: currency:'symbol' }}
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
      <div fxFlex="80%" class="tip-description">
        <p class="pr-2">Helping us cover the cost of this donation 🙏</p>
      </div>
      <button class="btn-xs rounded-full" (click)="openTipsDialog()" mat-stroked-button>
        Edit
      </button>
    </div>
  </mat-card-content>
</mat-card>
