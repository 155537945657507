import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { NgxStripeModule } from 'ngx-stripe';
import { ViewCardModule } from '../../view-templates/view-card/view-card.module';
import { AddPaymentMethodModule } from '../add-payment-method/add-payment-method.module';
import { StripeModule } from './../stripe.module';
import { PaymentMethodsComponent } from './payment-methods.component';

@NgModule({
  declarations: [PaymentMethodsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ViewCardModule,
    MatListModule,
    MatRadioModule,
    IconModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    NgxStripeModule,
    StripeModule,
    FlexLayoutModule,
    AddPaymentMethodModule,
    MatToolbarModule,
  ],
  exports: [PaymentMethodsComponent],
})
export class PaymentMethodsModule {}
