import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Select, Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import icSearch from '@iconify/icons-ic/search';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import {
  AddAddress,
  Address,
  Country,
  LocationState,
  SubscriptionState,
  UpdateAddress,
  UpdateUser,
  UserRg,
  UserStateRg,
} from 'libs/data/src/lib/ramadhan-giving';
import { RollbarService } from '@givebrite/api';
import Rollbar from 'rollbar';
import { UserState } from 'libs/data/src/lib/user/store/user.state';
import { User } from 'libs/data/src/lib/user/user.interface';

@Component({
  selector: 'givebrite-add-billing-wizard',
  templateUrl: './add-billing-wizard.component.html',
  styleUrls: ['./add-billing-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBillingWizardComponent implements OnInit, OnDestroy {
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  @Select(LocationState.country) country$: Observable<Country>;
  @Select(UserState.user) user$: Observable<User>;
  @Select(SubscriptionState.getSubscription) subscription$: Observable<Subscription>;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  icSearch = icSearch;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#fff',
        color: '#000',
        fontWeight: 400,
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '15px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#ccc',
        },
        ':-webkit-autofill': {
          color: '#ccc',
        },
      },
      invalid: {
        iconColor: '#999',
        color: '#999',
      },
    },
  };

  address: Address;
  nameForm: FormGroup = this.fb.group({
    title: ['Mr', Validators.required],
    country: [],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
  });

  addressForm: FormGroup = this.fb.group({
    //mobile: ['', this.PhoneNumberValidator()],
    postcode: ['', [Validators.required]],
    building_name_number: ['', Validators.required],
    street_name: ['', Validators.required],
    address_line_1: ['', Validators.required],
    address_line_2: [],
    town: ['', Validators.required],
    county: [],
    is_default: [true],
    title: [''],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
  });
  titles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'];
  appearance = 'legacy';
  selectedAddress: Address;
  manualAddress = false;
  subscription: Subscription;
  user: UserRg;

  constructor(
    private store: Store,
    private dialog: MatDialogRef<AddBillingWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Address,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    @Inject(RollbarService) private rollbar: Rollbar // private geo: GeocodeService
  ) {
    this.subscription$.pipe(untilDestroyed(this)).subscribe((subscription) => {
      this.subscription = subscription;
    });

    this.addressForm
      .get('building_name_number')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((n) => {
        this.addressForm.get('address_line_1').setValue(this.displayAddress);
      });

    this.addressForm
      .get('street_name')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((n) => {
        this.addressForm.get('address_line_1').setValue(this.displayAddress);
      });

    this.nameForm.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.addressForm.patchValue({
        first_name: form.first_name,
        last_name: form.last_name,
      });
    });

    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.nameForm.patchValue(user);
    });
  }

  get displayAddress() {
    return (
      this.addressForm.get('building_name_number').value +
      ' ' +
      this.addressForm.get('street_name').value
    );
  }

  dont_cancel() {
    this.dialog.close({ cancel: false });
  }

  addUpdateAddress() {
    if (this.addressForm.valid) {
      const action = this.address ? new UpdateAddress(this.form) : new AddAddress(this.form);
      this.store
        .dispatch(action)
        .pipe(untilDestroyed(this))
        .subscribe(
          (store) => {
            // this.store
            //   .dispatch(new UpdateUser(this.nameForm.value))
            //   .pipe(untilDestroyed(this))
            //   .subscribe(
            //     store => {
            //       this.address = store.user.selectedAddress;
            //       this.dialog.close(this.address);
            //     },
            //     error => {
            //       this.rollbar.error(`There was an updating user information error: + ${error}`);
            //       this.snackbar.open("There was an updating user information", "Close", { duration: 3000 });
            //     }
            //   );
            //this.stepper.next();
          },
          (error) => {
            this.rollbar.error(`There was an error adding address: + ${error}`);
            this.rollbar.log(`user: + ${this.user}`);
            this.rollbar.log(`address: + ${this.addressForm.value}`);
            this.snackbar.open('There was an error adding address', 'Close', { duration: 3000 });
          }
        );
    }
  }

  updateUser() {
    this.addressForm.patchValue(this.nameForm.value);
    if (!this.user.first_name || !this.user.last_name || !this.user.title) {
      if (this.nameForm.valid) {
        this.store.dispatch(new UpdateUser(this.nameForm.value));
      }
    } else {
      this.stepper.next();
    }
  }

  enterAddressManually() {
    this.stepper.next();
  }

  backToSearch() {
    this.stepper.previous();
  }

  // onAutocompleteSelected(result: PlaceResult) {
  //   const address = this.geo.parseAddress(result);
  //   this.selectedAddress = address;
  //   if (address.town) {
  //     this.addressForm.get("town").patchValue(address.town.long);
  //   }

  //   if (address.country) {
  //     this.nameForm.get("country").patchValue(address.country.short);
  //   }

  //   if (address.county) {
  //     this.addressForm.get("county").patchValue(address.county.long);
  //   }

  //   if (address.streetName) {
  //     this.addressForm.get("street_name").patchValue(address.streetName);
  //   }

  //   if (address.displayAddress) {
  //     this.addressForm.get("address_line_1").patchValue(address.displayAddress);
  //   }

  //   if (address.streetNumber) {
  //     this.addressForm.get("building_name_number").setValue(address.streetNumber);
  //   }
  //   if (address.postalCode) {
  //     this.addressForm.get("postcode").patchValue(address.postalCode);
  //   }
  //   this.stepper.next();
  // }

  get form() {
    return { ...this.addressForm.value, ...this.nameForm.value };
  }

  onLocationSelected(e) {}

  ngOnInit() {
    this.country$.pipe(untilDestroyed(this)).subscribe((country) => {
      if (country) {
        this.nameForm.get('country').setValue(country.code);
      }
    });
  }

  ngOnDestroy() {}
}
