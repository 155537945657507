import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorNotFoundRoutingModule } from './error-not-found-routing.module';
import { ErrorNotFoundComponent } from './error-not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ErrorNotFoundComponent],
  imports: [CommonModule,
     ErrorNotFoundRoutingModule,
     FlexLayoutModule
    ],
  exports: [ErrorNotFoundComponent],
})
export class ErrorNotFoundModule {}
