import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TeamState } from 'libs/data/src/lib/campaign/store/team.state';
import { GetCampaignTeamSingle } from 'libs/data/src/lib/campaign/store/team.actions';
import { Team } from 'libs/data/src/lib/campaign/team.interface';

@Component({
  selector: 'givebrite-invite-team',
  templateUrl: './invite-team.component.html',
  styleUrls: ['./invite-team.component.scss'],
})
export class InviteTeamComponent implements OnInit {
  @Select(TeamState.campaignTeam) team$: Observable<Team>;
  teamId: string;

  constructor(private route: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.teamId = params?.teamId;
      this.store.dispatch(new GetCampaignTeamSingle(this.teamId, '_id'));
    });
  }
}
