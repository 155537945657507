import { Injectable } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from 'libs/api/src/lib/api.service';

@Injectable({
  providedIn: 'root',
})
export class AppServiceRg {
  constructor(private http: ApiService) {}

  ramadhanGregorianCalendar(year: number, month = 9) {
    // const endpoint = `https://api.aladhan.com/v1/timingsByAddress`;
    return this.http.get(`/api/hToGCalendar/${month}/${year}`).pipe(map((res) => res.data));
  }

  prayerTimesByAddressDate(latitude: number, longitude: number, date?: string, timezone?: string) {
    // const endpoint = `https://api.aladhan.com/v1/timingsByAddress`;
    return this.http
      .get(
        `aladhan/timingsByAddress/${moment(date).format(
          'DD-MM-YYYY'
        )}?address=${latitude},${longitude}&method=2&school=1&timezonestring=` //${params.timezone}
      )
      .pipe(map((res) => res.data));
  }

  prayerTimesByAddressMonth(latitude: number, longitude: number, month = 9, year?: number) {
    if (!year)
      year = +new Intl.DateTimeFormat('ar-TN-u-ca-islamic', { year: 'numeric' }).format(Date.now());

    return this.http
      .get(
        `aladhan/hijriCalendarByAddress?month=${month}&year=${year}&address=${latitude},${longitude}`
      )
      .pipe(map((res) => res.data));
  }

  getUserPosition(): Observable<any> {
    return Observable.create((observer) => {
      const onSuccess: PositionCallback = (pos: any) => {
        observer.next(pos);
      };

      const onError: PositionErrorCallback = (error) => {
        observer.error(error);
      };

      const watcher: number = navigator.geolocation.watchPosition(onSuccess, onError);
      return () => {
        navigator.geolocation.clearWatch(watcher);
      };
    });
  }

  donationTypes() {
    return this.http.get(`donation/types`).pipe(map((response) => response.types));
  }
}
