import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'givebrite-media-uploader',
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
})
export class MediaUploaderComponent implements OnInit {
  title = 'Upload Media';
  events$ = new BehaviorSubject('');
  image;
  video;
  type = 'image';
  only: boolean;
  ratio;
  types = ['image', 'video', 'avatar'];
  selectedTabIndex = 0;

  constructor(
    private dialogRef: MatDialogRef<MediaUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public options
  ) {
    if (options) {
      if (options.type) {
        this.type = options.type;
        this.selectedTabIndex = this.types.findIndex(
          (type) => type == options.type
        );
      }
      this.only = options.only;
    }
  }

  ngOnInit(): void {}

  imageAdded(image) {
    console.log(image);
    this.image = image;
  }

  videoAdded(video) {
    console.log(video);
    this.video = video;
  }

  removeImage() {
    this.image = undefined;
    this.events$.next('cancel');
  }

  insertImage() {
    this.dialogRef.close({ ...this.image, type: 'image' });
  }

  insertVideo() {
    console.log(this.video);
    this.dialogRef.close({ type: 'video', video: this.video });
  }
}
