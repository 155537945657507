import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import icSecure from '@iconify/icons-ic/https';
import icCheck from '@iconify/icons-ic/check';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordRg, UserRg, UserStateRg } from 'libs/data/src/lib/ramadhan-giving';
import { fadeInUp400ms } from 'libs/theming/src/lib/animations';

@Component({
  selector: 'givebrite-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  animations: [fadeInUp400ms],
})
export class ResetComponent implements OnInit {
  icSecure = icSecure;
  icCheck = icCheck;
  form = this.fb.group({
    password: [null, [Validators.required, Validators.minLength(6)]],
    verify_password: ['ivdml0', [Validators.required, Validators.minLength(6)]],
    token: [this.route.snapshot.queryParams.token, [Validators.required]],
  });

  @Select(UserStateRg.getUser) user$: Observable<UserRg>;
  @Select(UserStateRg.loading) loading$: Observable<boolean>;
  resetted: boolean;
  error: string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private store: Store) {}

  ngOnInit() {}

  reset() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.store
        .dispatch(
          new ResetPasswordRg({
            token: this.form.get('token').value,
            password: this.form.get('password').value,
          })
        )
        .subscribe(
          (store) => {
            this.resetted = true;
          },
          (error) => {
            this.error = error;
          }
        );
    }
  }
}
