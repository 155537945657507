import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import grade from '@iconify/icons-ic/round-grade';
import star from '@iconify/icons-ic/star-border';
import { Fundraiser } from 'libs/data/src/lib/campaign/fundraiser.interface';
// import { FundraiserService } from '../../../fundraiser/fundraiser.service';

@Component({
  selector: 'leaderboard-fundraiser-card',
  templateUrl: './fundraiser-leaderboard-card.component.html',
  styleUrls: ['./fundraiser-leaderboard-card.component.scss'],
})
export class FundraiserLeaderboardCardComponent implements OnInit {
  @Input() fundraiser: Fundraiser;
  @Input() index;

  icons = {
    grade: grade,
    star: star,
  };

  // currentFundraiser = this.fundraiserService.fundraiser;
  constructor(
    private router: Router // private fundraiserService: FundraiserService
  ) {}

  ngOnInit(): void {}

  fundraiserPage(slug: string) {
    this.router.navigate([`fundraiser`], {
      queryParams: { fundraiser: slug },
    });
  }
}
