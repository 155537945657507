<form class="tip-selector text-center" [formGroup]="form">
  <div class="mb-6">
    <p *ngIf="charity$ | async as charity">
      {{ charity?.name | titlecase }} doesnot charge a setup fee or donation charges for using this
      platform. Adding just {{ selectedFees | currency: currency:'symbol' }} means SKT can continue
      to provide more free services.
    </p>
  </div>

  <div class="tips" fxFlayout="row wrap">
    <button
      [class.active]="selectedPercentage === percentage && !otherTipAmount"
      mat-raised-button
      (click)="setTipPercentage(percentage)"
      *ngFor="let percentage of tipPercentageValues"
    >
      {{ percentage }}% ({{ amount | tip: percentage | currency: currency:'symbol' }})
    </button>

    <button
      (click)="otherTipAmount = true"
      [class.active]="otherTipAmount === true"
      mat-raised-button
    >
      Other
    </button>

    <div *ngIf="otherTipAmount" class="other" formGroupName="fees">
      <mat-form-field class="w-full">
        <div matPrefix class="amount">
          {{ currency | currencySymbol }}
        </div>
        <input matInput type="number" min="0" formControlName="amount" (input)="setOtherTip()" />
      </mat-form-field>
    </div>
  </div>
</form>
