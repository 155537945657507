<div class="campaign-inspiration">
  <h4 class="text-center my-5 font-semibold">My Inspiration</h4>

  <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <div
      *ngFor="let item of inspirationList; let i = index"
      fxFlex="20%"
      fxFlex.xs="40%"
      class="m-2"
    >
      <mat-card
        appearance="outlined"
        (click)="selectedType(i)"
        [ngClass]="item.checked ? 'card-item-shadow' : ''"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content>
          <p
            class="font-bold text-center"
            [ngClass]="item.checked ? 'card-item-text' : ''"
          >
            {{ item.text }}
          </p>

          <givebrite-tick-mark [checked]="item.checked"></givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
