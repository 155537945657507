import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss'],
})
export class ViewCardComponent implements OnInit {
  loading$ = false;
  @Input() set loading(loading: Observable<boolean>) {
    if (loading) {
      loading.pipe(debounceTime(this.delay)).subscribe((loading) => {
        this.loading$ = loading;
      });
    }
  }
  @Input() title: string;
  @Input() align: string;
  @Input() height: string;
  @Input() style: string;
  @Input() type = 'card';
  @Input() nopadding: boolean;
  @Input() delay = 0;

  constructor() {}

  ngOnInit() {
    // if (this.loading) {
    // }
  }

  center() {
    return this.align == 'center' ? 'center' : '';
  }
}
