<div class="mx-auto charity-inner">
  <div class="text-center heading pt-5">Select a charity</div>

  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div
      class="mb-3 cursor-pointer p-1 sm:p-2"
      (click)="onCharityChange(charity.slug)"
      *ngFor="let charity of charities; let i = index"
    >
      <givebrite-charity-logo
        [charity]="charity"
        width="130px"
        [showDetail]="true"
      ></givebrite-charity-logo>
    </div>

    <div style="display: contents" *ngIf="loading$ | async">
      <div class="p-4 mb-2" *ngFor="let card of [].constructor(8)">
        <givebrite-charity-logo width="130px"></givebrite-charity-logo>
      </div>
    </div>
  </div>
</div>
