import { Injectable } from '@angular/core';
import { ApiService } from '@givebrite/api';
import { map } from 'rxjs/operators';
import { QurbaniStockDto } from './qurbani.dto';

@Injectable({
  providedIn: 'root',
})
export class QurbaniService {
  constructor(private http: ApiService) {}

  inventory(charityId: string, page, limit) {
    return this.http.get(`inventory/list/${charityId}?page=${page}&limit=${limit}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  animals() {
    return this.http.get(`inventory/animals`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addStock(stock: QurbaniStockDto) {
    return this.http.post(`inventory`, stock).pipe(
      map((response) => {
        return response.inventory;
      })
    );
  }

  updateInventoryItem(item: { _id: string; stock: { total: number } }) {
    console.log(item);
    return this.http.put(`inventory/${item._id}`, item).pipe(
      map((response) => {
        return response;
      })
    );
  }

  statistics() {
    return this.http.get(`app/statistics`).pipe(
      map((response) => {
        return response.statistics;
      })
    );
  }

  charities() {
    return this.http.get(`inventory/charities`).pipe(
      map((response) => {
        return response.docs.charity;
      })
    );
  }
}
