import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import facebook from '@iconify/icons-fa-brands/facebook';
import telegram from '@iconify/icons-fa-brands/telegram';
import twitter from '@iconify/icons-fa-brands/twitter';
import whatsapp from '@iconify/icons-fa-brands/whatsapp';
import copy from '@iconify/icons-fa-solid/copy';
import email from '@iconify/icons-fa-solid/envelope';
import link from '@iconify/icons-fa-solid/link';
import { Router } from '@angular/router';
import { campaign } from '@givebrite/theming';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'givebrite-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit {
  link: unknown = link;
  campaign = campaign;
  href: string;
  socials: { icon: unknown; name: string; color: string; url: string }[] = [
    {
      icon: facebook,
      name: 'Facebook',
      color: '#3B5998',
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
    {
      icon: twitter,
      name: 'Twitter',
      color: '#1DA1F2',
      url: 'https://twitter.com/intent/tweet?url=',
    },
    {
      icon: whatsapp,
      name: 'Whatsapp',
      color: '#32D670',
      url: 'https://web.whatsapp.com/send?text=',
    },
    {
      icon: telegram,
      name: 'Telegram',
      color: '#039BE5',
      url: 'https://telegram.me/share/url?url=',
    },
    { icon: email, name: 'Email', color: '#859DA8', url: 'mailto:?body=' },
    { icon: copy, name: 'Copy', color: '#859DA8', url: '' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ShareDialogComponent>,
    private clipboardService: ClipboardService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.href = this.router.url;
  }

  ngOnInit(): void {}

  /**
   * @description
   * COPY URL TO CLIPBOARD
   */
  copy() {
    this.clipboardService.copy(`${this.data.environment.app.url}${this.data?.url || this.href}`);
    this.toastr.success('Copied!');
  }

  /**
   * @description
   * OPEN LINK IN NEW TAG
   * @param url LINK URL
   * @param last LAST ITEM
   */
  openLink(url, last) {
    if (last) {
      this.copy();
    } else {
      window.open(url + this.data.environment.app.url, '_blank');
    }
  }

  /**
   * @description
   * CLOSE DIALOG
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
