<div class="edit-profile-dialog relative" mat-dialog-content *ngIf="user">
  <mat-icon
    [icIcon]="close"
    class="absolute right-5 top-5 close cursor-pointer"
    (click)="closeDialog()"
  ></mat-icon>

  <h3 mat-dialog-title class="text-center">Edit Profile</h3>

  <div mat-dialog-content class="edit-profile-dialog-body">
    <form [formGroup]="form">
      <div fxLayout="row" fxLayoutAlign="center center">
        <givebrite-avatar-uploader
          class="avatar-container"
          formControlName="profile_image"
          [userId]="user?._id"
        ></givebrite-avatar-uploader>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between center"
        class="w-full mt-8"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="49"
          fxFlex.xs="100"
          class="w-full"
        >
          <p class="mb-2">First Name</p>

          <mat-form-field class="w-full form-outline">
            <mat-label>First Name</mat-label>
            <input
              matInput
              #first_name
              placeholder="Enter first"
              required
              formControlName="first_name"
            />

            <mat-error *ngIf="form.get('first_name').invalid">
              Please enter a valid first name
            </mat-error>
          </mat-form-field>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="49"
          fxFlex.xs="100"
          class="w-full"
        >
          <p class="mb-2">Last Name</p>

          <mat-form-field class="w-full form-outline">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              #last_name
              placeholder="Enter last name"
              required
              formControlName="last_name"
            />

            <mat-error *ngIf="form.get('last_name').invalid">
              Please enter a valid last name
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center" class="pb-8">
        <button
          mat-raised-button
          color="primary"
          class="w-full cursor-pointer"
          (click)="updateProfile()"
          [disabled]="!form.valid || loading"
          [class.disabled]="!form.valid || loading"
        >
          Update Profile
        </button>
      </div>
    </form>
  </div>
</div>
