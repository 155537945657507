import { PaymentGateway } from '../donation';

export interface Charity {
  _id: string;
  name: string;
  slug: string;
  bypass_connect: boolean;
  theme: {
    primary: string;
    secondary: string;
  };
  address: {
    building_name_number: string;
    street_name: string;
    address_line_1: string;
    address_line_2: string;
    town: string;
    state: string;
    country: string;
    postcode: string;
  };
  logo: {
    white: string;
    color: string;
  };
  social: {
    following: string;
    followers: string;
    shares: string;
    likes: string;
  };
  statistics: CharityStats;
  description: string;
  is_zakat: number;
  is_impacts: number;
  gift_aid_enabled: number;
  donation_types: Array<any>[];
  default_currency: string;
  is_featured: number;
  checked?: boolean;
  pagination?: any;
  regno: string;
  email: string;
  type: string;
  website: string;
  phone: string;
  badges?: [];
  social_links?: {
    badges?: [];
    social_links?: {
      Instagram?: string;
      facebook?: string;
      website?: string;
      youtube?: string;
    };
  };
  settings?: any;
  gateways: any[];
  step: any;
}
// export interface Fundraiser {
//   _id: string;
//   slug: string;
//   name: string;
//   currency: string;
//   user: {
//     _id: string;
//     first_name: string;
//     last_name: string;
//     country: string;
//     profile_image: string;
//     profile_completed: boolean;
//   };
// }

export interface CharityStats {
  subscriptions: number;
  donation_count: number;
  raised: number;
  canceled: number;
  campaigns: number;
  donations: number;
}
export interface Events {
  _id: string;
  name: string;
  slug: string;
  image: string;
  description: string;
  country: string;
  start_date: string;
  end_date: string;
}
export interface CreateCharityUser {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  // country: string;
  // phoneNumber: string;
}
export interface CreateCharity {
  type: string;
  registration_number: string;
  name: string;
  category: string;
  website: string;
  address: {
    building_name_number: string;
    street_name: string;
    address_line_1: string;
    address_line_2: string;
    town: string;
    state: string;
    country: string;
    postcode: string;
  };
}
export interface CharityUser {
  _id: string;
  first_name: string;
  last_name: string;
  country: string;
  profile_image: string;
  profile_completed: boolean;
  role: string;
  fullName?: string;
  address: [
    {
      postcode: string;
      country: string;
    }
  ];
}
export interface CharityPlan {
  name: string;
  splan_id: string;
  amount: number;
  currency: string;
  interval: string;
  fee: number;
  is_paid: boolean;
  features: [];
  status: boolean;
  active: boolean;
  created_at: string;
}

// export interface PaymentGateway {
//   _id: string;
//   name: string;
//   is_active: boolean;
//   transaction_fee: number;
//   fees_pcnt: number;
//   fees_fixed: number;
//   description: string;
//   plan: string;
//   recurring: boolean;
//   type: string;
//   label: string;
//   requires_split_invoice: boolean;
//   default_card_provider: boolean;
//   metadata: [];
// }

export interface UploadedFile {
  _id: string;
  url: string;
}

export interface ApproveCharity {
  _id: string;
  type: string;
  slug: string;
  name: string;
  cover: string[];
  logo: {
    white: string;
    color: string;
  };
  theme: {
    primary: string;
    secondary: string;
  };
  settings: {
    is_zakat: boolean;
    is_impacts: boolean;
    gift_aid_enabled: boolean;
    recurring_enabled: boolean;
    email_notifications: boolean;
  };
  address: {
    building_name_number: string;
    street_name: string;
    address_line_1: string;
    address_line_2: string;
    town: string;
    state: string;
    country: string;
    postcode: string;
  };
  statistics: CharityStats;
  social: {
    following: string;
    followers: string;
    shares: string;
    likes: string;
  };
  subscription: {
    is_default: boolean;
    paid: boolean;
    autodebit: boolean;
    gc_customer: string;
    gc_mandate: string;
    active: boolean;
  };
  document: Document;
  bypass_connect: boolean;
  phone: string;
  badges: any[];
  is_featured: boolean;
  active: boolean;
  verified: boolean;
  step: number;
  is_deleted: any;
  created_at: string;
  __v: number;
}

export interface Document {
  processing_history: string[];
  bank_statment: string[];
  login_details: string[];
  id_proof: string[];
  address_proof: string[];
  business_address: string[];
}
