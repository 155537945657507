import { Charity } from '../../charity';

export interface Postcode {
  result: string;
  administrative_county: string;
  building_name: string;
  building_number: string;
  country: string;
  county: string;
  delivery_point_suffix: string;
  department_name: string;
  dependant_locality: string;
  dependant_thoroughfare: string;
  district: string;
  double_dependant_locality: string;
  eastings: number;
  latitude: number;
  line_1: string;
  line_2: string;
  line_3: string;
  longitude: number;
  northings: number;
  organisation_name: string;
  po_box: string;
  post_town: string;
  postal_county: string;
  postcode: string;
  postcode_inward: string;
  postcode_outward: string;
  postcode_type: string;
  premise: string;
  su_organisation_indicator: string;
  sub_building_name: string;
  thoroughfare: string;
  traditional_county: string;
  udprn: number;
  umprn: string;
  ward: string;
  phone: string;
}

export interface UserAddress {
  _id?: string;
  first_name?: string;
  last_name?: string;
  postcode?: string;
  address_line_1?: string;
  address_line_2?: string;
  town?: string;
  is_default?: number;
  country?: string;
  id?: number;
}

export interface UserRg {
  _id?: string;
  id?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  oranisation?: {};
  charities?: [{}];
  address?: UserAddress;
  selected_charity?: Charity;
  profile_image?: string;
  email?: string;
  phone?: string;
  token?: string;
  type?: string;
  billing?: any;
  user?: any;
}

export interface UserRegistrationFields {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface SocialUserRg {
  first_name: string;
  last_name: string;
  provider_id: any;
  profile_image: string;
  email: string;
  provider: string;
}

export interface Household {
  name: string;
  adults: number;
  children: number;
}

export interface Address {
  _id?: string;
  id?: number;
  gmID: string;
  placeID: string;
  name?: string;
  icon?: string;
  displayAddress?: string;
  postalCode?: number;
  streetNumber?: number;
  streetName?: string;
  sublocality?: string;
  town?: {
    short?: string;
    long?: string;
  };
  county?: {
    short?: string;
    long?: string;
  };
  country?: {
    short?: string;
    long?: string;
  };
  vicinity?: string;
  url?: string;
  geoLocation?: Location;
}

export interface PaymentMethod {
  _id?: string;
  id: string;
  payment_method_id: string;
  last4: string;
  brand: string;
  address: UserAddress;
  exp_month: string;
  exp_year: string;
  is_default: boolean;
  status?: string;
  fingerprint?: string;
}

export interface Card {
  result: string;
  administrative_county: string;
  building_name: string;
  is_default?: boolean;
}
