<div class="amount-impact-slider relative cursor-pointer" *ngIf="_impacts.length">
  <!-- SLIDER VIEW -->
  <ng-container *ngIf="sliderView">
    <!-- Impacts -->
    <div fxLayout="row" fxLayoutAlign="space-between center" class="slider-label">
      <!-- Why use h4 if font size is overwridden @Abhinav -->
      <h4 *ngFor="let impact of _impacts" [class.active]="impact.amount === selectedImpact.amount">
        <ng-container>
          {{ impact.amount | currency: currency:'symbol':'1.0-0' }}
        </ng-container>
        <!-- Show other if impact empty -->
        <ng-container *ngIf="!impact.amount"> Other </ng-container>
      </h4>
    </div>

    <mat-slider
      class="w-full"
      color="primary"
      [step]="step"
      [min]="0"
      [max]="_impacts.length - 1"
      [(ngModel)]="stepIndex"
      thumbLabel
      [displayWith]="displayFn"
     #ngSlider><input matSliderThumb (input)="onInputChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider>

    <div class="impact-text mb-4 text-center" *ngIf="selectedImpact?.name">
      {{ selectedImpact.amount | currency: currency:'symbol':'1.0-0' }} {{ selectedImpact.name }}
    </div>
  </ng-container>

  <!-- AMOUNT LIST VIEW -->
  <ng-container *ngIf="amountListView">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="slider-label amount-list-slider"
    >
      <h4
        *ngFor="let impact of _impacts; let index = index; let last = last"
        class="text-center title font-medium mb-0"
        [class.activebg]="impact?.amount === selectedImpact?.amount"
        (click)="onInputChange({ value: index })"
        [hidden]="last && fixedPriceType"
      >
        <ng-container>
          {{ impact.amount | currency: currency:'symbol':'1.0-0' }}
        </ng-container>
        <ng-container *ngIf="!impact.amount"> Other </ng-container>
      </h4>
    </div>

    <p class="impact-text font-medium my-4 text-center" *ngIf="selectedImpact?.name">
      {{ selectedImpact?.name | titlecase }}
    </p>
  </ng-container>

  <!-- AMOUNT INPUT -->
  <div [formGroup]="form" [class.hidden]="selectedImpact?.amount" class="custom-amount my-4">
    <mat-form-field appearance="outline" class="w-full rounded-form-field" *ngIf="!fixedPriceType">
      <div matPrefix class="amount">
        {{ currency || (config$ | async)?.currency | currencySymbol }}
      </div>

      <input
        (input)="other()"
        matInput
        type="number"
        step="0.01"
        #customAmountInput
        formControlName="amount"
        label="Enter an amount or choose one from above"
        onkeypress="return event.charCode >= 48"
        min="1"
        class="ml-3"
      />
    </mat-form-field>
  </div>
</div>
