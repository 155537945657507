import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarSearchComponent } from './toolbar-search.component';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { EditProfileDialogModule } from 'libs/theming/src/lib/layout/toolbar/toolbar-user/edit-profile-dialog/edit-profile-dialog.module';

@NgModule({
  declarations: [ToolbarSearchComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    EditProfileDialogModule,
  ],
  exports: [ToolbarSearchComponent],
})
export class ToolbarSearchModule {}
