<div class="amount-impact-slider" *ngIf="_impacts.length">
  <!-- Impacts -->
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="slider-label"> -->
  <!-- Why use h4 if font size is overwridden @Abhinav -->
  <!-- <h4
      *ngFor="let impact of _impacts; let index = index"
      (click)="onInputChange({ value: index })"
      [class.active]="impact.amount === selectedImpact.amount"
    >
      <ng-container>
        {{ impact.amount | currency: _currency:'symbol':'1.0-0' }}
      </ng-container> -->
  <!-- Show other if impact empty -->
  <!-- <ng-container *ngIf="!impact.amount"> Other </ng-container>
    </h4>
  </div> -->

  <!-- <mat-slider
    class="w-full {{ !this.selectedImpact?.amount ? 'other' : '' }}"
    color="primary"
    [step]="step"
    [min]="0"
    [max]="_impacts.length - 1"
    [(ngModel)]="stepIndex"
    (input)="onInputChange($event)"
    thumbLabel
    [displayWith]="displayFn"
  ></mat-slider>

  <div class="impact-text mb-4 text-center" *ngIf="selectedImpact?.name">
    {{ selectedImpact.amount | currency: _currency:'symbol':'1.0-0' }} : {{ selectedImpact.name }}
  </div> -->

  <div fxLayout="column" class="my-8" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="buttons">
      <button
        (click)="decreaseAmount()"
        class="decrease-button w-1/5 sm:w-1/5"
        mat-raised-button
        color="primary"
        [disabled]="amount < 1"
      >
        <mat-icon>remove</mat-icon>
      </button>
      <div class="mx-4" fxLayout="row" fxLayoutAlign="center center">
        <button class="btn-large cursor-auto">
          <div
            fxLayout="column"
            class="amount-selected mx-auto overflow-hidden cursor-auto"
            fxLayoutAlign="center center"
          >
            <div
              fxFlex
              class="font-black amount-title"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              {{ amount | currency: _currency:'symbol':'0.0-1' }}
            </div>
          </div>
        </button>
      </div>
      <button
        (click)="increaseAmount()"
        class="increase-button w-1/5 sm:w-1/5"
        mat-raised-button
        color="primary"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="slider-label mb-5">
    <h4
      *ngFor="let impact of _impacts; let index = index"
      (click)="onInputChange({ value: index })"
      [class.active]="impact.amount === selectedImpact.amount"
      class="cursor-pointer uppercase"
      [class.capitalize]="!impact.amount"
    >
      <ng-container>
        {{ impact.amount | currency: _currency:'symbol':'1.0-0' }}
      </ng-container>
      <!-- Show other if impact empty -->
      <ng-container *ngIf="!impact.amount"> Other </ng-container>
    </h4>
  </div>

  <!-- AMOUNT INPUT -->
  <div [formGroup]="form" *ngIf="!selectedImpact?.amount" class="custom-amount my-4">
    <givebrite-amount
      #customAmountInput
      formControlName="amount"
      label="Enter an amount or choose one from above"
    ></givebrite-amount>
  </div>

  <div class="impact-text mb-4 text-center" *ngIf="impactTitle && impactAmount">
    {{ amount | currency: _currency:'symbol':'1.0-0' }} : {{ impactTitle }}
  </div>
</div>
