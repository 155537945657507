import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import * as GenerateUniqueId from 'generate-unique-id';
@Component({
  selector: 'givebrite-common-campaign-details-dialog',
  templateUrl: './common-campaign-details-dialog.component.html',
  styleUrls: ['./common-campaign-details-dialog.component.scss'],
})
export class CommonCampaignDetailsDialogComponent implements OnInit {
  commonForm: FormGroup;
  sponsorForm: FormGroup;
  sponsor: boolean = false;
  imageList: Array<any> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CommonCampaignDetailsDialogComponent>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.data.type == 'sponsor') {
      this.sponsor = true;
    } else {
      this.sponsor = false;
    }

    this.commonForm = this.fb.group({
      amount: ['', Validators.required],
      title: ['', Validators.required],
    });

    this.sponsorForm = this.fb.group({
      name: ['', Validators.required],
      url: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/),
        ],
      ],
    });

    this.setCampaigns();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * add campaign based upon type
   * @param type
   */
  add(type) {
    if (
      this.commonForm.controls['amount'].value &&
      this.commonForm.controls['title'].value &&
      !this.sponsor
    ) {
      this.addCampaign(
        this.commonForm.controls['amount'].value,
        this.commonForm.controls['title'].value,
        type,
      );
    } else if (
      this.sponsorForm.controls['name'].value &&
      this.sponsorForm.controls['url'].value &&
      this.sponsor
    ) {
      this.addSponsor(
        this.sponsorForm.controls['name'].value,
        this.sponsorForm.controls['url'].value,
        type,
      );
    }
  }

  /**
   * add campaign (impacts, phases)
   * @param amount
   * @param title
   * @param type
   */
  addCampaign(amount, title, type) {
    const payload = {
      amount: amount,
      title: title,
      type,
      edit: this.data.edit,
      index: '',
      // GenerateUniqueId({
      //   length: 4,
      //   useNumbers: true,
      // }),
    };
    this.dialogRef.close({
      confirmation: true,
      payload,
    });
  }

  /**
   * add sponsors
   * @param amount
   * @param url
   * @param type
   */
  addSponsor(amount, url, type) {
    const payload = {
      amount: amount,
      title: url,
      type,
      edit: this.data.edit,
      index: '',
      // GenerateUniqueId({
      //   length: 4,
      //   useNumbers: true,
      // }),
      sponsorImg: this.imageList
        ? this.imageList[0].img
        : 'https://cdn.pixabay.com/photo/2021/08/25/20/42/field-6574455__480.jpg',
    };
    this.dialogRef.close({
      confirmation: true,
      payload,
    });
  }

  /**
   * update fields if we have already created camapigns (on edit)
   */
  setCampaigns() {
    if (this.data?.amountValue && this.data?.titleValue && !this.sponsor) {
      this.commonForm.patchValue({
        amount: this.data?.amountValue,
        title: this.data?.titleValue,
      });
    } else if (this.data?.amountValue && this.data?.titleValue && this.sponsor) {
      this.sponsorForm.patchValue({
        name: this.data?.amountValue,
        url: this.data?.titleValue,
      });
    }
  }

  images(item) {
    this.imageList = [...item];
  }
}
