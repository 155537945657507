import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Charity, CharityState, GetCharities, GetCharitiesForSearch } from '@givebrite/data';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { environment } from 'apps/qurbani-giving/src/environments/environment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-charity',
  templateUrl: './charity.component.html',
  styleUrls: ['./charity.component.scss'],
})
export class CharityComponent implements OnInit, OnDestroy {
  @Select(CharityState.getSearchCharity) charities$: Observable<Charity[]>;
  @Select(CharityState.loading) loading$: Observable<boolean>;
  charities: Charity[] = [];
  loading: boolean = false;

  @Output() passCharity = new EventEmitter();
  hoverCharity = -1;
  selectedCharityItem = -1;
  isBrowser: boolean;

  constructor(
    private store: Store,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      const filter_by: string = '';
      const search: string = '';
      const page: number = 1;
      const limit: number = 100;
      const featured: number = 1;

      this.store
        .dispatch(new GetCharities(filter_by, search, page, limit, featured))
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          const charityList = res?.charity?.charities;

          if (charityList) {
            this.charities = charityList
              .map((list) => {
                const qg = list?.projection?.find(
                  (item) => item.app?.slug === environment.x_tenant_id,
                );

                return { ...list, stats: qg?.metadata?.price_starts };
              })
              .filter((charity) => charity?.is_featured);
          }
        });
    }
  }

  ngOnInit() {}

  hoverableCharity(charity) {
    this.hoverCharity = charity;
  }

  removeHoverableCharity() {
    this.hoverCharity = -1;
  }
  onCharityChange(slug) {
    this.router.navigate([slug]);
    // this.router.navigate(['/charityshares']);
    // this.selectedCharityItem = id;
    // this.passCharity.emit(slug);
  }

  ngOnDestroy() {}
}
