import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Donation, DonationForm, Gateway, Recaptcha } from '@givebrite/data';
import { DonationService } from '../donation.service';
import {
  CreatePaymentIntent,
  DonationUpdate,
  ProcessDonation,
  ProcessRecurringDonation,
  SetDonation,
  ResetDonation,
  CompleteExpressCheckout,
  AddComment,
  GetDonationComplete,
  ReCaptchaTokenVerify,
  PaypalDonation,
} from './donation.actions';
import { catchError, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
export class DonationStateModel {
  loading: boolean;
  error: any;
  donationFlowForm: {
    model?: DonationForm;
  };
  donation: Donation;
  gateway: Gateway;
  comment: any;
  recaptcha: Recaptcha;
  paypalDonation: any;
}
@State<DonationStateModel>({
  name: 'donation',
  defaults: {
    loading: false,
    donationFlowForm: null,
    donation: null,
    gateway: null,
    error: null,
    comment: null,
    recaptcha: null,
    paypalDonation: null,
  },
})
@Injectable()
export class DonationState {
  constructor(private donationService: DonationService, private router: Router) {}

  @Selector()
  static DonationFlowForm(state: DonationStateModel) {
    return state.donationFlowForm?.model;
  }

  @Selector()
  static donation(state: DonationStateModel) {
    return state.donation;
  }

  @Selector()
  static paymentIntent(state: DonationStateModel) {
    return state.gateway;
  }

  @Selector()
  static loading(state: DonationStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: DonationStateModel) {
    return state.error;
  }

  @Selector()
  static form(state: DonationStateModel) {
    return state.donationFlowForm;
  }

  @Action(SetDonation)
  donation({ patchState, dispatch, getState }: StateContext<DonationStateModel>) {
    patchState({ loading: true });
    return this.donationService.donation(getState().donationFlowForm.model).pipe(
      tap((result) => {
        patchState({
          donation: result,
          loading: false,
        });
      })
    );
  }

  @Action(CreatePaymentIntent)
  createPaymentIntent({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    // let {
    //   frequency,
    //   is_giftaid,
    //   comment,
    //   optin,
    //   anonymous,
    //   campaign,
    //   charity,
    //   ...restKey
    // }: any = getState().donationFlowForm.model;
    // let id = getState().donation._id;
    patchState({ loading: true });
    return this.donationService.getPaymentIntent(payload).pipe(
      tap(
        (result) => {
          patchState({
            gateway: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(DonationUpdate)
  donationUpdate(
    { patchState, dispatch, getState }: StateContext<DonationStateModel>,
    { payload }
  ) {
    patchState({ loading: true });
    const id = getState().donation._id;
    return this.donationService
      .donationUpdate(
        id,
        payload
        //   {
        //   is_anonymous: getState().donation.is_anonymous,
        //   comment: getState().donation.comment,
        // }
      )
      .pipe(
        tap((result) => {
          patchState({
            donation: result,
            loading: false,
          });
        })
      );
  }

  @Action(ProcessDonation)
  processDonations({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    patchState({ loading: true });

    return this.donationService.processDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            donation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(CompleteExpressCheckout)
  completeExpressCheckout({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    patchState({ loading: true });

    return this.donationService.completeExpressCheckout(payload).pipe(
      tap(
        (result) => {
          patchState({
            donation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(ProcessRecurringDonation)
  processRecurringDonations(
    { patchState, getState }: StateContext<DonationStateModel>,
    { payload }
  ) {
    patchState({ loading: true });

    return this.donationService.processRecurringDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            donation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(ResetDonation)
  resetDonations({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    patchState({
      loading: false,
      donationFlowForm: null,
      donation: null,
      gateway: null,
      error: null,
    });
  }

  @Action(AddComment)
  addComment({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    patchState({ loading: true });

    return this.donationService.addComment(payload).pipe(
      tap(
        (result) => {
          patchState({
            comment: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(GetDonationComplete)
  getDonationComplete({ patchState }: StateContext<DonationStateModel>, { id }) {
    patchState({ loading: true });
    return this.donationService.getDonationComplete(id).pipe(
      tap((result) => {
        patchState({
          donation: result,
          loading: false,
        });
      })
    );
  }

  @Action(ReCaptchaTokenVerify)
  reCaptchaTokenVerify({ patchState }: StateContext<DonationStateModel>, { token }) {
    patchState({ loading: true });
    return this.donationService.reCaptchaTokenVerify(token).pipe(
      tap(
        (result) => {
          patchState({
            error: null,
            loading: false,
            recaptcha: result,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }

  @Action(PaypalDonation)
  paypalDonation({ patchState, getState }: StateContext<DonationStateModel>, { payload }) {
    patchState({ loading: true });

    return this.donationService.paypalDonation(payload).pipe(
      tap(
        (result) => {
          patchState({
            paypalDonation: result,
            loading: false,
          });
        },
        (error) => {
          patchState({
            error: error,
            loading: false,
          });
        }
      )
    );
  }
}
