import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-giftaid-consent',
  templateUrl: './giftaid-consent.component.html',
  styleUrls: ['./giftaid-consent.component.scss'],
})
export class GiftaidConsentComponent implements OnInit {
  hideText = false;

  /** LABEL FOR CHARITIES OR CAMPAIGN */
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}

  /**
   * @description
   * TOGGLE TEXT
   */
  showMore(): void {
    this.hideText = !this.hideText;
  }
}
