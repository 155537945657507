<div class="links" fxLayout="column">
  <a class="link"
    ><mat-icon class="material-icons-outlined">file_upload</mat-icon>Upload an
    image</a
  >
  <a class="link"
    ><mat-icon class="material-icons-outlined">search</mat-icon>Search media
    library</a
  >
</div>
<div class="help text-center">
  <!-- <h2><mat-icon class="material-icons-outlined">info</mat-icon>Hero Image</h2> -->
  <div fxLayout.fx="row" fxFlayout="column">
    <div fxFlex="50">
      <strong>IMAGE SIZE</strong>
      <p class="mb-3">000x000 pixels</p>
    </div>
    <div fxFlex="50">
      <strong>ACCEPTED FORMATS</strong>
      <p>PNG</p>
    </div>
  </div>
</div>
