import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  DonateButtonModule,
  FundraiseOptionsDialogModule,
  ShareDialogModule,
  TrendingDonationsModule,
} from '../../campaigns';
import { DonationInfoModule } from '../../donate';
import { GbProgressBarModule } from '../../gb-progress-bar/gb-progress-bar.module';
import { UserLogoAvatarModule } from '../../user/user-logo-avatar/user-logo-avatar.module';
import { SummaryCardComponent } from './summary-card.component';
import { TruncateStringModule } from 'libs/theming/src/lib/pipes/truncate-string/truncate-string.module';

@NgModule({
  declarations: [SummaryCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MomentModule,
    MatDividerModule,
    NgxSkeletonLoaderModule,
    ShareDialogModule,
    UserLogoAvatarModule,
    TruncateStringModule,
    FundraiseOptionsDialogModule,
    ContentLoaderModule,
    TrendingDonationsModule,
    GbProgressBarModule,
    DonateButtonModule,
    DonationInfoModule,
  ],
  exports: [SummaryCardComponent],
})
export class SummaryCardModule {}
