import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
// import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import { FlexLayoutModule } from '@angular/flex-layout';

// export function playerFactory() {
//   return import('lottie-web');
// }

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [LoaderComponent],
})
export class LoaderModule {}
