import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundModule } from './404/not-found.module';
// import { AppStoreModule, AppState, UserStoreModule } from "@givebrite/data/store";
// import { CoreModule, LoginDialogModule } from "@givebrite/core";
import { QurbaniGivingRoutingModule } from './app-routing.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
// import { UserModule } from "@givebrite/core";
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
// import { QurbaniGivingMetaModule } from "./meta.module";
import { QurbaniGivingLayoutModule } from './layout/qurbani-giving-layout.module';
// import { RollbarService } from "@givebrite/api";
import { STRIPE_PUBLISHABLE_KEY, NgxStripeModule } from 'ngx-stripe';
import Rollbar from 'rollbar';
import { CookieModule } from 'ngx-cookie';
import {
  AppStoreModule,
  CartStoreModule,
  DonationCartStoreModule,
  DonationStoreModule,
  ENVIRONMENT,
  GivebriteStoreModule,
  UserStoreModule,
} from '@givebrite/data';
import { ApiModule, HttpErrorInterceptor } from '@givebrite/api';
import { ThemingModule } from '@givebrite/theming';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PixelModule } from 'ngx-multi-pixel';
// import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
// import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

export const rollbarConfig = {
  accessToken: 'e5a3a72bdba647b880b36d1b7951045d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false },
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export function playerFactory() {
  return player;
}

// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(environment.googleLoginProvider)
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(environment.facebookAppId)
//   }
// ]);

// export function provideConfig() {
//   return config;
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // BrowserModule.withServerTransition({ appId: 'qurbani-giving' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    NotFoundModule,
    QurbaniGivingRoutingModule,
    DonationCartStoreModule,
    AppStoreModule,
    GivebriteStoreModule,
    // NgxsStoragePluginModule.forRoot({
    //   key: ['cart'],
    // }),
    UserStoreModule,
    DonationStoreModule,
    // CoreModule,
    NgxStripeModule.forRoot(environment.stripe.pk),
    // NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    // NgxsStoragePluginModule.forRoot({
    //   key: ["user", "app", "cart"]
    // }),
    // UserModule,
    MatDialogModule,
    RouterModule,
    LottieModule.forRoot({ player: playerFactory }),
    // QurbaniGivingMetaModule,
    QurbaniGivingLayoutModule,
    CookieModule.forRoot(),
    // LoginDialogModule,
    // SocialLoginModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   apiKey: "AIzaSyB2dlP2nC3VZxniwrCzOu514wMEePjRsYc",
    //   libraries: ["places"]
    // })
    PixelModule.forRoot({ enabled: false, pixelId: [] }),
    ApiModule,
    ThemingModule,
    CartStoreModule,
  ],
  providers: [
    { provide: APP_ID, useValue: 'qurbani-giving' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // { provide: RollbarService, useFactory: rollbarFactory },
    // {
    //   provide: "app_id",
    //   useValue: environment.app_id
    // },
    // {
    //   provide: STRIPE_PUBLISHABLE_KEY,
    //   useValue: environment.stripe.pk
    // },
    {
      provide: 'environment',
      useValue: environment,
    },
    { provide: ENVIRONMENT, useValue: environment }, // For x-tenent-id
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: provideConfig
    // },
    // { provide: "googleTagManagerId", useValue: environment.googleTagManagerId },
    // GoogleMapsAPIWrapper
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
