import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharitiesFiltersComponent } from './charities-filters.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CharitiesFiltersComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    FlexLayoutModule,
    IconModule,
    MatTabsModule,
    RouterModule,
  ],
  exports: [CharitiesFiltersComponent],
})
export class CharitiesFiltersModule {}
