import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givebrite-tick-mark',
  templateUrl: './tick-mark.component.html',
  styleUrls: ['./tick-mark.component.scss'],
})
export class TickMarkComponent implements OnInit {
  @Input() checked;

  constructor() {}

  ngOnInit(): void {}
}
