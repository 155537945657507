import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarUploaderComponent } from './avatar-uploader.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
// import { NgxUploaderModule } from 'ngx-uploader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ImageCropperDialogModule } from '../campaign-cover-uploader/image-cropper-dialog/image-cropper-dialog.module';
import { MediaUploaderModule } from '../campaign-cover-uploader/media-uploader/media-uploader.module';

@NgModule({
  declarations: [AvatarUploaderComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    NgxFileDropModule,
    MatIconModule,
    MatButtonModule,
    // NgxUploaderModule,
    ImageCropperModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ImageCropperDialogModule,
    MediaUploaderModule,
    YouTubePlayerModule,
  ],
  exports: [AvatarUploaderComponent],
})
export class CampaignAvatarUploaderModule {}
