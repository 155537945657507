export interface Projects_ {
  _id: string;
  name: string;
  options: donation_types[];
  active: boolean;
  amount: number;
  category: string;
  code: string;
  countries: [];
  created_at: string;
  donation_type: [];
  icon: string;
  image: string;
  thumbnail: string;
}

export interface ProjectCreateForm {
  name: string;
  code: string;
  display_name: string;
  amount: number;
  currency: string;
  description: string;
  category: string;
  image: string;
  thumbnail: string;
  is_featured: boolean;
  icon: string;
  active: boolean;
  country: string;
  options: donation_types[];
  products: any[];
}

export interface ProjectAppealCreateForm {
  name: string;
  code: string;
  display_name: string;
  amount: number;
  price_type: string;
  currency: string;
  description: string;
  category: string;
  image: string;
  thumbnail: string;
  is_featured: boolean;
  icon: string;
  active: boolean;
  country: string;
  options: donation_types[];
  products: any[];
  project: any;
  impacts: any[];
}

export interface donation_types {
  donation_types: string[];
}
