import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'givebrite-charity-logo-avatar',
  templateUrl: './charity-logo-avatar.component.html',
  styleUrls: ['./charity-logo-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'gb-charity-avatar',
  },
})
export class CharityLogoAvatarComponent implements OnInit {
  @Input() logo;

  @Input() set charity(charity) {
    if (charity) {
      this.logo = charity.logo;
      this.theme = charity.theme;
    }
  }
  @Input() set campaign(campaign) {
    if (campaign) {
      this.logo = campaign.cover[0].thumbnail;
      console.log(this.logo);
    }
  }
  @Input() border;
  @Input() width = 60;
  @Input() radius = '9999px';
  @Input() profile;
  @Input() color: boolean = false;
  theme;

  constructor() {}

  ngOnInit(): void {}

  get entity() {
    return this.charity ? this.charity : this.campaign ? this.campaign : null;
  }

  // get logo() {
  //   return this.charity ? this.charity.logo : this.campaign.cover[0];
  // }

  // get theme() {
  //   return this.charity ? this.charity.theme : undefined;
  // }
}
