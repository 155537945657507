import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardSectionComponent } from './card-section.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CardSectionComponent],
  imports: [CommonModule, MatIconModule, MatCardModule],
  exports: [CardSectionComponent],
})
export class CardSectionModule {}
