import { QurbaniStockDto } from '../qurbani.dto';
import { QurbaniAnimal, QurbaniInventoryItem } from '../qurbani.interface';

export class GetQurbaniAnimals {
  static readonly type = '[Qurbani] Get Qurbani Animals';
}

export class AddQurbaniStock {
  static readonly type = '[Qurbani] Add Qurbani Stock';
  constructor(private stock: QurbaniStockDto) {}
}

export class GetQurbaniInventory {
  static readonly type = '[Qurbani] Get Qurbani Inventory';
  constructor(public charityId: string, public page = 1, public limit = 10) {}
}

export class UpdateQurbaniInventoryItem {
  static readonly type = '[Qurbani] Update Qurbani Inventory Itemm';
  constructor(private inventoryItem: QurbaniInventoryItem) {}
}

export class GetQurbaniStatistics {
  static readonly type = '[Qurbani] Get Qurbani Statistics';
  constructor() {}
}

export class SelectAnimal {
  static readonly type = '[Qurbani] Select Aniaml';
  constructor(private animal: QurbaniAnimal) {}
}

export class ResetInventory {
  static readonly type = '[Qurbani] Reset Inventory';
}

export class GetQurbaniCharities {
  static readonly type = '[Qurbani] Get Qurbani Charities';
}
