export interface Auction {
  _id: number;
  title: string;
  slide: string[];
  description: string;
  statistics: {
    bids: number;
    watching: number;
    amount: number;
  };
  user: {
    _id: string;
    first_name: string;
    last_name: string;
    address: {
      country: string;
    };
  };
  created_at: string;
}
