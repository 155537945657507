import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { map } from 'rxjs/operators';
import { CampaignRg, ImpactRg } from '../campaign';

@Injectable({
  providedIn: 'root',
})
export class ImpactService {
  constructor(private http: ApiService) {}

  /**
   * @param  {any} params
   */
  list(slug: any) {
    console.log(slug);
    return this.http.get(`charity/campaigns?slug=${slug}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  get(slug: string) {
    return this.http.get(`campaign/${slug}`).pipe(
      map((response: CampaignRg) => {
        return response;
      })
    );
  }

  update(impact: ImpactRg) {
    return this.http.put('campaign/impact', impact).pipe(
      map((response: ImpactRg) => {
        return response;
      })
    );
  }

  create(campaign: CampaignRg) {
    return this.http.post('campaign/impact', campaign).pipe(
      map((response): ImpactRg[] => {
        return response.impacts;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`campaign/impact?impact_id=${id}`).pipe(
      map((response): ImpactRg[] => {
        return response.impacts;
      })
    );
  }
}
