import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { UserLogoAvatarModule } from 'libs/core/src/lib/user/user-logo-avatar/user-logo-avatar.module';
import { TeamLeaderboardComponent } from './team-leaderboard.component';

@NgModule({
  declarations: [TeamLeaderboardComponent],
  imports: [CommonModule, FlexLayoutModule, UserLogoAvatarModule, NgxFlickingModule, MatCardModule],
  exports: [TeamLeaderboardComponent],
})
export class TeamLeaderboardModule {}
