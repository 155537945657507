import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharityShowcaseItemComponent } from './charity-showcase-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CharityLogoModule } from '../../view-templates/charity-logo/charity-logo.module';
import { CoreModule } from '../../core.module';

@NgModule({
  declarations: [CharityShowcaseItemComponent],
  imports: [
    CommonModule,
    MatCardModule,
    CoreModule,
    RouterModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatButtonModule,
    CharityLogoModule,
  ],
  exports: [CharityShowcaseItemComponent],
})
export class CharityShowcaseItemModule {}
