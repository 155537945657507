<div class="charity-selector">
  <h4 class="text-center my-5 font-semibold">Select a Charity</h4>

  <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-form-field class="w-full">
      <input
        matInput
        (ngModelChange)="searchCharities($event)"
        placeholder="Search for charities"
        [(ngModel)]="search"
        name="search"
      />
      <mat-icon matSuffix style="color: #c9d4dc">search</mat-icon>
    </mat-form-field>
  </div>

  <!-- Loops through the default charities and displays them as clickable boxes -->
  <div class="w-full" fxLayout="row wrap" fxLayoutAlign="center center">
    <div *ngIf="!charities?.length">
      No charities found
      <span *ngIf="search">matching your search "{{ search }}"</span>
    </div>
    <div *ngIf="charity$ | async as charity" fxFlex="28%" class="m-2">
      <mat-card
        appearance="outlined"
        (click)="selectCharity(charity._id)"
        [class.cart-item-shadow]="charity._id == selectedCharity"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content class="h-full">
          <givebrite-charity-logo-avatar [charity]="charity"></givebrite-charity-logo-avatar>
          {{ charity.name }}

          <givebrite-tick-mark [checked]="charity._id == selectedCharity"> </givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngFor="let charity of charities; let i = index" fxFlex="28%" class="m-2 text-center">
      <mat-card
        appearance="outlined"
        (click)="selectCharity(charity._id)"
        [class.cart-item-shadow]="charity._id == selectedCharity"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content class="h-full">
          <givebrite-charity-logo-avatar
            [charity]="charity"
            class="mb-2"
          ></givebrite-charity-logo-avatar>
          <p>{{ charity.name }}</p>

          <givebrite-tick-mark [checked]="charity._id == selectedCharity"> </givebrite-tick-mark>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="pagination$ | async as pagination" fxLayoutAlign="center" fxLayout="row">
      <mat-card
        appearance="outlined"
        *ngIf="showLoadMore(pagination)"
        (click)="loadMore()"
        class="mat-elevation-z3 cursor-pointer"
        fxLayoutAlign="center center"
      >
        <mat-card-content class="text-center">
          <mat-icon>refresh</mat-icon>
          <div>Load More</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
