import { Injectable } from '@angular/core';
import { ApiService } from 'libs/api/src/lib/api.service';
import { map } from 'rxjs/operators';
import { CampaignRg } from './campaign.interface';

@Injectable({
  providedIn: 'root',
})
export class CampaignServiceRg {
  constructor(private http: ApiService) {}

  /**
   * @param  {any} params
   */
  list(slug: string = null) {
    return this.http.get(`charity/campaigns/${slug}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  /**
   * @param  {any} params
   */
  search(search) {
    return this.http.get(`campaign`, search).pipe(
      map((response) => {
        return response;
      })
    );
  }

  get(slug: string) {
    return this.http.get(`campaign/${slug}`).pipe(
      map((response: CampaignRg) => {
        return response;
      })
    );
  }

  update(campaign: CampaignRg) {
    return this.http.put('campaign/' + campaign.id, campaign).pipe(
      map((response: CampaignRg) => {
        return response;
      })
    );
  }

  create(campaign: CampaignRg) {
    return this.http.post('campaign', campaign).pipe(
      map((response: CampaignRg) => {
        return response;
      })
    );
  }

  getAllCampaigns(limit, page, sort, filterBy, value) {
    return this.http
      .get(
        `project?limit=${limit}&page=${page}&search=&sort=${sort}&column=&filter_by=${filterBy}&value=${value}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
