export class SetDonation {
  static readonly type = '[Donation] Set Donation';
  constructor() {}
}
export class CreatePaymentIntent {
  static readonly type = '[Payment] Create Payment Intent';
  constructor(private payload) {}
}
export class DonationUpdate {
  static readonly type = '[Payment] Update Donation';
  constructor(private payload) {}
}
export class ProcessDonation {
  static readonly type = '[Payment] Process Donation';
  constructor(private payload) {}
}

export class ProcessRecurringDonation {
  static readonly type = '[Payment] Process Recurring Donation';
  constructor(private payload) {}
}
export class ResetDonation {
  static readonly type = '[Payment] Reset Donation';
}

export class CompleteExpressCheckout {
  static readonly type = '[PayPal] Complete Express Checkout';
  constructor(private payload) {}
}

export class AddComment {
  static readonly type = '[Donation] Add Comment';
  constructor(private payload) {}
}

export class GetDonationComplete {
  static readonly type = '[Donation] Get Donation Complete';
  constructor(private id) {}
}
export class ReCaptchaTokenVerify {
  static readonly type = '[Recaptcha] Recaptcha Token Verify';
  constructor(private token) {}
}

export class PaypalDonation {
  static readonly type = '[Donation] Paypal Donation';
  constructor(private payload) {}
}
