import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundraisersComponent } from './fundraisers.component';
import { UserLogoAvatarModule } from '../../../user/user-logo-avatar/user-logo-avatar.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { FundraiserComponent } from './fundraiser/fundraiser.component';
import { NgxFlickingModule } from '@egjs/ngx-flicking';
import { FundraiserLeaderboardCardModule } from './fundraiser-leaderboard-card/fundraiser-leaderboard-card.module';
import { TopLeaderboardModule } from './top-leaderboard/top-leaderboard.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FundraisersComponent, FundraiserComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    FlexLayoutModule,
    MatFormFieldModule,
    NgxFlickingModule,
    UserLogoAvatarModule,
    TopLeaderboardModule,
    MatProgressSpinnerModule,
    FundraiserLeaderboardCardModule,
  ],
  exports: [FundraisersComponent],
})
export class FundraisersModule {}
