<div class="circular-fundraising" *ngIf="fundraiser">
  <div class="relative top" fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner
      class="spinner-mobile"
      [color]="red"
      [value]="getPercentage(fundraiser.statistics.raised, fundraiser.goal)"
      [diameter]="55"
      [strokeWidth]="5"
      fxShow.xs
      fxHide
    >
    </mat-progress-spinner>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="fundraiser-percent rounded-full font-bold p-4 sm:p-8"
    >
      <!-- {{
        fundraiser.statistics.raised / 1000
          | currency: fundraiser.currency:'symbol':'1.0-1'
      }}k -->
      {{ getPercentage(fundraiser.statistics.raised, fundraiser.goal) + '%' }}
    </div>
  </div>
</div>
