import {
  Component,
  OnInit,
  Input,
  Inject,
  ɵɵtrustConstantResourceUrl,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'campaign-add-impacts-dialod',
  templateUrl: './campaign-add-impacts-dialog.component.html',
  styleUrls: ['./campaign-add-impacts.component.scss'],
})
export class CampaignAddImpactsDialogComponent implements OnInit {
  editing: boolean;
  form: FormGroup = this.fb.group({
    name: [],
    amount: [],
  });
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CampaignAddImpactsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    // edit

    if (this.data?.impact) {
      this.editing = true;
      this.form.patchValue(this.data?.impact);
    }
  }

  add() {
    if (this.form.valid) {
      this.dialogRef.close({ form: this.form });
    }
  }
}
