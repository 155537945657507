import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseIconComponent } from './close-icon.component';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [CloseIconComponent],
  imports: [CommonModule, IconModule, MatButtonModule],
  exports: [CloseIconComponent],
})
export class CloseIconModule {}
