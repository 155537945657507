import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CampaignDetailsEditorComponent } from './campaign-details-editor.component';

@NgModule({
  declarations: [CampaignDetailsEditorComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, EditorModule],
  exports: [CampaignDetailsEditorComponent],
})
export class CampaignDetailsEditorModule {}
