import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineDonationDialogComponent } from './offline-donation-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconModule } from '@abhinavakhil/iconify-angular';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { CloseIconModule } from '../../../utils/close-icon/close-icon.module';
import { CurrencySymbolModule } from 'libs/theming/src/lib/pipes/currency-symbol/currency-symbol.module';

@NgModule({
  declarations: [OfflineDonationDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    IconModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    CurrencySymbolModule,
    MatSlideToggleModule,
    MatDividerModule,
    CloseIconModule,
  ],
  exports: [OfflineDonationDialogComponent],
})
export class OfflineDonationDialogModule {}
